import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from "react";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { useAppDispatch } from "store/storeHooks";
import { getTagsThunk, selectTags } from "store/tagsStore/tagsReducer";
import { createExamThunk, selectExams } from "store/examsStore/examsReducer";
import { CreateLocation } from "shared/interfaces/Location.interface";
import { CreateExam } from "shared/interfaces/Exam.interface";
import { visibilityOptions } from "shared/interfaces/Visibility.interface";
import DisplayFiles from "shared/components/UI/DisplayFiles";
import LocationSearch from "shared/components/UI/LocationSearch";
import MultipleImageDropzone from "shared/components/UI/MultipleImageDropzone";
import { ExamFormType } from "./types";
import CustomButton from "shared/components/UI/CustomButton";
import Spinner from "shared/components/UI/Spinner";
import Overlay from "shared/components/UI/Overlay";
import GoBack from "shared/components/UI/GoBack";
import DisplayInputError from "shared/components/UI/DisplayInputError";
import {
  addStyles,
  getInputStyles,
  getReactSelectStyles,
  stringToFile,
  toISOString,
} from "utils/utils";
import { toast } from "react-toastify";
import Message from "shared/components/UI/Message";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "shared/styles/datePicker.css";
import EditImage from "shared/components/UI/EditImage";
import Creatable from "react-select/creatable";
import { CreateButton } from "shared/interfaces/Button.interface";
import EmailEditor from "react-email-editor";
import sample from "shared/assets/sample/sample.json";
import axios from "axios";
import { CMS_BE_URL } from "api/urls";
import SimpleImageDropzone from "shared/components/UI/SimpleImageDropzone";
import { selectTranslationLanguage } from "store/translationStore/translationReducer";
import { useNavigate } from "react-router";
import { filter } from "ramda";
import CropImage from "shared/components/UI/CropImage";

export default function CreateExamPage() {
  const navigate = useNavigate();
  const lang = selectTranslationLanguage();
  const { t } = useTranslation("common");
  const tabs: { name: string }[] = useMemo(
    () => [{ name: t("exam.details") }, { name: t("exam.other") }],
    [t]
  );
  const [selectedTab, setSelectedTab] = useState(0);
  const [tagsOptions, setTagsOptions] = useState<any[]>([]);
  const [editImageOn, setEditImageOn] = useState(false);
  const [richtext, setRichtext] = useState("");
  const [json, setJson] = useState<any>("");
  const form = useForm<ExamFormType>({
    mode: "onChange",
    defaultValues: {
      title: "",
      richtext: "",
      styles_json: "",
      start_date: undefined,
      end_date: undefined,
      tags: [],
      location: {
        country: undefined,
        city: undefined,
        province: undefined,
        region: undefined,
        postal_code: undefined,
        street_name: undefined,
        street_number: undefined,
        longitude: undefined,
        latitude: undefined,
      },
      info: "",
      schedule: "",
      faq: "",
      media: null,
      thumbnail: null,
      fileType: "exam_doc",
      is_visible: visibilityOptions[0],
      buttons: [],
    },
  });
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    control,
    watch,
    setError,
    clearErrors,
    trigger,
    getValues,
  } = form;

  //EMAIL EDITOR
  const emailEditorRef: any = useRef(null);

  const onLoad = useCallback(() => {
    const timer = setInterval(() => {
      if (
        emailEditorRef &&
        emailEditorRef.current &&
        emailEditorRef.current.editor
      ) {
        emailEditorRef.current.editor.loadDesign(sample);
        // emailEditorRef.current.editor.loadBlank({
        //   backgroundColor: "#fff",
        // });
        emailEditorRef.current.editor.addEventListener(
          "design:updated",
          function () {
            // Design is updated by the user

            emailEditorRef.current.editor.exportHtml(function (data: any) {
              const json = data.design; // design json
              const html = data.html; // design html
              setRichtext(html);
              setJson(json);

              // Save the json, or html here
            });
          }
        );
        emailEditorRef.current.editor.registerCallback(
          "image",
          async (file: any, done: any) => {
            const formD = new FormData();
            formD.append("fileType", "document_doc");
            formD.append("files", file.attachments[0]);

            const res = await axios.post(
              `${CMS_BE_URL}/files/uploadFile`,
              formD,
              {
                headers: {
                  "Content-Type":
                    "multipart/form-data; boundary=<calculated when request is sent>",
                },
              }
            );
            const url = res.data.urls[0];

            done({ progress: 100, url });
          }
        );

        clearInterval(timer);
      }
    }, 500);
  }, [emailEditorRef]);

  function constructCreateExam(data: ExamFormType) {
    const exam: CreateExam = {} as CreateExam;
    exam.fileType = data.fileType;
    if (data.title !== "") exam.title = data.title;
    if (data.start_date) exam.start_date = toISOString(data.start_date);
    if (data.end_date) exam.end_date = toISOString(data.end_date);
    if (data.tags.length) {
      exam.tags = JSON.stringify(data.tags.map((tag) => tag.value));
    }

    exam.richtext = stringToFile(richtext);
    exam.styles_json = JSON.stringify(json);
    const tempLocation: CreateLocation = {
      street_name: data.location.street_name,
      street_number: data.location.street_number,
      postal_code: data.location.postal_code,
      city: data.location.city,
      province: data.location.province,
      region: data.location.region,
      country: data.location.country,
    } as CreateLocation;
    if (data.location.longitude)
      tempLocation["longitude"] = data.location.longitude;
    if (data.location.latitude)
      tempLocation["latitude"] = data.location.latitude;
    exam.location = JSON.stringify(tempLocation);
    if (data.info !== "") exam.info = data.info;
    if (data.schedule !== "") exam.schedule = data.schedule;
    if (data.faq !== "") exam.faq = data.faq;
    if (data.media) exam.media = data.media as File[];
    if (data.thumbnail) exam.thumbnail = data.thumbnail as File;
    if (data.is_visible.value !== "") exam.is_visible = data.is_visible.value;
    if (data.buttons.length) {
      const tempButtons: CreateButton[] = [];
      data.buttons.forEach((button, i) => {
        if (button.title && button.link && button.is_visible) {
          tempButtons[i] = button;
        }
      });
      exam.buttons = JSON.stringify(
        tempButtons.map((button) => ({ ...button }))
      );
    }
    return exam;
  }

  const onSubmit: SubmitHandler<ExamFormType> = function (data) {
    dispatch(createExamThunk(constructCreateExam(data))).then((res) => {
      if (res.meta.requestStatus === "rejected") {
        toast.error(
          Message({
            action: "create",
            entity: t("exam.exam"),
            error: t("genericError"),
            lang,
            gender: "male",
          })
        );
      } else {
        toast.success(
          Message({
            action: "create",
            entity: t("exam.exam"),
            lang,
            gender: "male",
          })
        );
        navigate("/exams");
      }
      return res;
    });
  };

  const exams = selectExams();
  const dispatch = useAppDispatch();
  const tags = selectTags();
  useEffect(() => {
    dispatch(getTagsThunk()).then((res) => {
      if (res.meta.requestStatus === "rejected") {
        toast.error(
          Message({
            action: "read",
            entity: t("exam.tags"),
            error: t("genericError"),
            lang,
            gender: "female",
          })
        );
      }
    });
  }, []);
  useEffect(() => {
    const tagsMap = tags.data.tags.map((tag) => {
      return {
        value: tag.pk_tag,
        label: tag.pk_tag,
      };
    });
    setTagsOptions(tagsMap);
  }, [tags]);

  return (
    <Overlay active={exams.loading} spinner={<Spinner />}>
      <FormProvider {...form}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col flex-grow gap-5 p-10 h-full bg-gray-100"
        >
          <GoBack uri="exams" />
          <div className="flex items-center justify-between w-full">
            <div className="flex gap-5 items-center">
              <h1 className="text-3xl font-bold">{t("exams.exams")}</h1>
              <p className="text-global-subHeader text-xl"></p>
            </div>
            <div className="flex gap-5 items-center">
              <label
                htmlFor="is_visible"
                className="block whitespace-nowrap text-lg font-medium"
              >
                {t("exam.visible")} *
              </label>
              <Controller
                name="is_visible"
                control={control}
                render={({ field }) => (
                  <Select
                    defaultValue={visibilityOptions[0]}
                    styles={getReactSelectStyles(false)}
                    inputId="is_visible"
                    options={visibilityOptions}
                    value={field.value}
                    onChange={(e) => field.onChange(e)}
                    isSearchable={false}
                  />
                )}
              />
              <button
                type="submit"
                className="px-4 py-2 text-white bg-global-save border rounded-sm"
                onClick={async () => {
                  const canContinue = await trigger([
                    "title",
                    "start_date",
                    "end_date",
                    "tags",
                    "thumbnail",
                    "location",
                  ]);

                  if (!canContinue) {
                    setSelectedTab(0);
                    return;
                  }
                }}
              >
                {t("exam.save")}
              </button>
            </div>
          </div>
          <nav className="flex gap-5" aria-label="Tabs">
            {tabs.map((tab: any, i) => (
              <button
                type="button"
                onClick={() => setSelectedTab(i)}
                key={i}
                className={`${
                  i == selectedTab
                    ? "border-users-create text-users-create"
                    : "border-transparent text-gray-500 hover:border-gray-300"
                } px-1 py-2 whitespace-nowrap text-lg font-medium border-b-2`}
              >
                {tab.name}
              </button>
            ))}
          </nav>
          <div className="flex flex-col flex-grow">
            <div
              className={`flex flex-col gap-2 ${
                selectedTab === 0 ? "block" : "hidden"
              }`}
            >
              <div className="grid flex-grow gap-10 grid-cols-2">
                <div className="flex flex-col flex-grow gap-2">
                  <label htmlFor="title" className="block text-lg font-medium">
                    {t("exam.exam")} {t("exam.title")} *
                  </label>
                  <input
                    id="title"
                    type="text"
                    placeholder={`${t("exam.exam")} ${t("exam.title")}`}
                    {...register("title", {
                      required: `${t("exam.exam")} ${t("exam.title")} ${t(
                        "exam.required"
                      )}.`,
                      maxLength: {
                        value: 50,
                        message: `${t("exam.post")} ${t("exam.title")} ${t(
                          "exam.shouldBe"
                        )} ${50} ${t("exam.characters")}.`,
                      },
                    })}
                    className={getInputStyles(!!errors.title)}
                  />
                  <DisplayInputError message={errors.title?.message} />
                  <label
                    htmlFor="start_date"
                    className="block text-lg font-medium"
                  >
                    {t("exam.startDate")} *
                  </label>
                  <Controller
                    name="start_date"
                    control={control}
                    rules={{
                      required: `${t("exam.startDate")} ${t("exam.required")}.`,
                    }}
                    render={({ field }) => {
                      return (
                        <div>
                          <DatePicker
                            id="start_date"
                            selected={field.value}
                            onChange={(e) => field.onChange(e)}
                            showTimeSelect={true}
                            dateFormat="dd/MM/yyyy h:mm aa"
                            className={getInputStyles(!!errors.start_date)}
                            placeholderText={t("exam.dateFormat")}
                            showPopperArrow={false}
                            autoComplete="off"
                          />
                        </div>
                      );
                    }}
                  />
                  <DisplayInputError message={errors.start_date?.message} />
                  <label
                    htmlFor="end_date"
                    className="block text-lg font-medium"
                  >
                    {t("exam.endDate")} *
                  </label>
                  <Controller
                    name="end_date"
                    control={control}
                    rules={{
                      required: `${t("exam.endDate")} ${t("exam.required")}.`,
                      validate: (value) => {
                        const start_date = getValues("start_date");
                        if (start_date && value && start_date > value) {
                          return String(t("exam.dateError"));
                        }
                        return true;
                      },
                    }}
                    render={({ field }) => {
                      return (
                        <div>
                          <DatePicker
                            id="end_date"
                            selected={field.value}
                            onChange={(e) => field.onChange(e)}
                            showTimeSelect={true}
                            dateFormat="dd/MM/yyyy h:mm aa"
                            className={getInputStyles(!!errors.end_date)}
                            placeholderText={t("exam.dateFormat")}
                            showPopperArrow={false}
                            autoComplete="off"
                          />
                        </div>
                      );
                    }}
                  />
                  <DisplayInputError message={errors.end_date?.message} />
                  <label htmlFor="tags" className="block text-lg font-medium">
                    {t("exam.tags")} *
                  </label>
                  <Controller
                    name="tags"
                    control={control}
                    rules={{
                      required: true,
                      validate: {
                        maxLength(e) {
                          if (filter((tag) => tag.value.length > 50, e).length)
                            return `${t("new.tags")} ${t(
                              "new.shouldBe"
                            )} ${50} ${t("new.characters")}.`;
                          return true;
                        },
                      },
                    }}
                    render={({ field }) => (
                      <Creatable
                        placeholder={t("exam.tags")}
                        styles={getReactSelectStyles(!!errors.tags)}
                        isLoading={tags.loading}
                        inputId="tags"
                        isMulti={true}
                        value={field.value}
                        options={tagsOptions}
                        onChange={(e) => field.onChange(e)}
                      />
                    )}
                  />
                  <DisplayInputError
                    message={
                      errors.tags && `${t("exam.tags")} ${t("exam.required")}.`
                    }
                  />
                </div>
                <div className="flex flex-col gap-2">
                  <div className="flex flex-col gap-2">
                    <label htmlFor="info" className="block text-lg font-medium">
                      {t("exam.otherInfo")}
                    </label>
                    <textarea
                      id="info"
                      placeholder={t("exam.otherInfo")}
                      className={getInputStyles(false)}
                      {...register("info", {
                        maxLength: {
                          value: 1300,
                          message: `${t("exam.otherInfo")} ${t(
                            "exam.shouldBe"
                          )} ${1300} ${t("exam.characters")}.`,
                        },
                      })}
                    ></textarea>
                    <DisplayInputError message={errors.info?.message} />
                  </div>
                  <div className="flex flex-col gap-2">
                    <label
                      htmlFor="thumbnail"
                      className="block text-xl font-medium"
                    >
                      {t("exam.coverPhoto")} *
                    </label>
                    <Controller
                      name="thumbnail"
                      control={control}
                      rules={{
                        required: `${t("exam.coverPhoto")} ${t(
                          "exam.required"
                        )}`,
                        validate: {
                          checkSize: (file) => {
                            if (file && file.size > 2 * 1048576)
                              return `${t("singleDropzone.size")}: 2MB`;
                            return true;
                          },
                        },
                      }}
                      render={() => (
                        <SimpleImageDropzone
                          clearErrors={clearErrors}
                          setError={setError}
                          trigger={trigger}
                          setValue={setValue}
                          watch={watch}
                          reactHookFormName="thumbnail"
                          setEditImageOn={setEditImageOn}
                          aspect={16 / 9}
                        />
                      )}
                    />
                    {editImageOn ? (
                      <CropImage
                        editImageOn={editImageOn}
                        setEditImageOn={setEditImageOn}
                        reactHookFormName="thumbnail"
                        aspect={16 / 9}
                        canForce={true}
                        canClose={true}
                      />
                    ) : null}
                  </div>
                  <DisplayInputError message={errors.thumbnail?.message} />
                </div>
              </div>
              <LocationSearch
                getValues={getValues}
                errors={errors}
                watch={watch}
                register={register}
                setValue={setValue}
                clearErrors={clearErrors}
                reactHookFormName="location"
                displayStar={true}
                isExamAddress={true}
              />
              <div id="editor-container" className="flex flex-col gap-2">
                <label className="block text-lg font-medium">
                  {t("exam.mainBody")} *
                </label>
                <EmailEditor
                  minHeight={1000}
                  ref={emailEditorRef}
                  onLoad={onLoad}
                />
              </div>
            </div>
            <div className={selectedTab === 1 ? "block" : "hidden"}>
              <div className="grid flex-grow gap-5 grid-cols-2">
                <div className="flex flex-col flex-grow gap-2">
                  <label htmlFor="title" className="block text-lg font-medium">
                    {t("exam.schedule")}
                  </label>
                  <textarea
                    id="schedule"
                    placeholder={t("exam.schedule")}
                    className={addStyles(getInputStyles(false), "flex-grow")}
                    {...register("schedule", {
                      maxLength: {
                        value: 1300,
                        message: `${t("exam.schedule")} ${t(
                          "exam.shouldBe"
                        )} ${1300} ${t("exam.characters")}.`,
                      },
                    })}
                  ></textarea>
                  <label htmlFor="faq" className="block text-lg font-medium">
                    {t("exam.faq")}
                  </label>
                  <textarea
                    id="faq"
                    placeholder={t("exam.faq")}
                    className={addStyles(getInputStyles(false), "flex-grow")}
                    {...register("faq", {
                      maxLength: {
                        value: 1300,
                        message: `${t("exam.faq")} ${t(
                          "exam.shouldBe"
                        )} ${1300} ${t("exam.characters")}.`,
                      },
                    })}
                  ></textarea>
                  <DisplayInputError message={errors.faq?.message} />
                </div>
                <div className="flex flex-col flex-grow gap-2">
                  <div className="grid gap-5 grid-cols-2">
                    <CustomButton
                      register={register}
                      errors={errors}
                      index="0"
                    />
                    <CustomButton
                      register={register}
                      errors={errors}
                      index="1"
                    />
                  </div>
                  <div className="flex flex-col gap-2">
                    <label
                      htmlFor="media"
                      className="block text-xl font-medium"
                    >
                      {t("exam.media")}
                    </label>
                    <Controller
                      name="media"
                      control={control}
                      rules={{
                        validate: {
                          checkSize: (files) => {
                            if (files) {
                              const totalSize = files.reduce((acc, file) => {
                                return acc + file.size;
                              }, 0);
                              if (totalSize > 10 * 1048576) {
                                return `${t("multipleDropzone.size")}: 10MB`;
                              }
                            }
                            return true;
                          },
                        },
                      }}
                      render={() => (
                        <MultipleImageDropzone
                          reactHookFormName="media"
                          aspect={0}
                        />
                      )}
                    />
                    <DisplayFiles reactHookFormName="media" />

                    <DisplayInputError
                      message={(errors.media as any)?.message}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
    </Overlay>
  );
}
