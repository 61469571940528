import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { permissionsManagementInitialState } from "./permissionsManagementState";
import {
  createRolePermission,
  getRolePermissions,
  getRolesPermissions,
  updateRolePermission,
} from "api/PermissionsManagement/rolesPermissions.api";
import { deleteRole, getRoles } from "api/PermissionsManagement/roles.api";
import { getPermissions } from "api/PermissionsManagement/permissions.api";
import { useAppSelector } from "store/storeHooks";
import {
  CreatePermissionAccess,
  CreateRolePermission,
  RolePermission,
} from "shared/interfaces/RolePermission.interface";
import { Role } from "shared/interfaces/Role.interface";
import { Permission } from "shared/interfaces/Permission.interface";

type CRUD = "CREATE" | "READ" | "UPDATE" | "DELETE";
type crud = "create" | "read" | "update" | "delete";
type CrudType = "ANY" | "OWN" | "NONE";
type strOrNull = string | null;

export const getRolesThunk = createAsyncThunk(
  "permissionsManagementStore/getRoles",
  async () => {
    const response = await getRoles();
    return response.data.roles;
  }
);

export const deleteRoleThunk = createAsyncThunk(
  "permissionsManagementStore/deleteRole",
  async (roleID: number, thunkApi) => {
    await deleteRole(roleID);
  }
);

export const getPermissionsThunk = createAsyncThunk(
  "permissionsManagementStore/getPermissions",
  async () => {
    const response = await getPermissions();
    return response.data.permissions;
  }
);

export const getRolePermissionsThunk = createAsyncThunk(
  "permissionsManagementStore/getRolePermissions",
  async (roleID: number) => {
    const response = await getRolePermissions(roleID);
    return response.data.rolePermission;
  }
);

export const updateRolePermissionsThunk = createAsyncThunk(
  "permissionsManagementStore/updateRolePermissions",
  async ({ roleID, data }: { roleID: number; data: CreateRolePermission }) => {
    await updateRolePermission(roleID, data);
    return;
  }
);

export const getRolesPermissionsThunk = createAsyncThunk(
  "permissionsManagementStore/getRolesPermissions",
  async () => {
    const response = await getRolesPermissions();
    return response.data.RolesPermissions;
  }
);

export const createRolePermissionThunk = createAsyncThunk(
  "permissionsManagementStore/createRolePermission",
  async (data: CreateRolePermission) => {
    await createRolePermission(data);
  }
);

const permissionsManagementSlice = createSlice({
  name: "permissionsManagementStore",
  initialState: permissionsManagementInitialState,
  reducers: {
    changeUpdateRolePermissionsRoleName(
      state,
      {
        payload: { roleName },
      }: {
        payload: {
          roleName: string;
        };
      }
    ) {
      state.updateRolePermissions.roleName = roleName;
    },
    changeUpdateRolePermissionsDescription(
      state,
      {
        payload: { description },
      }: {
        payload: {
          description: string;
        };
      }
    ) {
      state.updateRolePermissions.description = description;
    },
    changeUpdateRolePermissionEnabledAccess(
      state,
      { payload: { i, bool } }: { payload: { i: number; bool: boolean } }
    ) {
      state.updateRolePermissions.permissionsAccess[i]["enabled"] = bool;
    },
    changeUpdateRolePermissionsPermissionsAccess(
      state,
      {
        payload: { permissionsAccess },
      }: {
        payload: {
          permissionsAccess: CreatePermissionAccess[];
        };
      }
    ) {
      state.updateRolePermissions.permissionsAccess = permissionsAccess;
    },
    changeUpdateRolePermissionsPermissionAccess(
      state,
      {
        payload: { i, crud, crudType },
      }: {
        payload: {
          i: number;
          crud: crud;
          crudType: CrudType;
        };
      }
    ) {
      state.updateRolePermissions.permissionsAccess[i][crud] = crudType;
    },
    // changeUpdateAllRolePermissions(
    //   state,
    //   {
    //     payload: { crud, crudType },
    //   }: { payload: { crud: crud; crudType: CrudType } }
    // ) {
    //   state.updateRolePermissions.permissionsAccess.map(
    //     (permissionsAccess) => (permissionsAccess[crud] = crudType)
    //   );
    // },
    createRolePermissions(
      state,
      {
        payload: { roleName, description, permissionsAccess },
      }: {
        payload: {
          roleName: string;
          description: strOrNull;
          permissionsAccess: CreatePermissionAccess[];
        };
      }
    ) {
      state.createRolePermissions.roleName = roleName;
      state.createRolePermissions.description = description;
      state.createRolePermissions.permissionsAccess = permissionsAccess;
    },
    changeCreateRolePermissionsRolename(
      state,
      { payload: { roleName } }: { payload: { roleName: string } }
    ) {
      state.createRolePermissions.roleName = roleName;
    },
    changeCreateRolePermissionsDescription(
      state,
      { payload: { description } }: { payload: { description: strOrNull } }
    ) {
      state.createRolePermissions.description = description;
    },
    changeCreateRolePermissionEnabledAccess(
      state,
      { payload: { i, bool } }: { payload: { i: number; bool: boolean } }
    ) {
      state.createRolePermissions.permissionsAccess[i]["enabled"] = bool;
    },
    changeCreateRolePermissionsPermissionsAccess(
      state,
      {
        payload: { i, crud, crudType },
      }: { payload: { i: number; crud: crud; crudType: CrudType } }
    ) {
      state.createRolePermissions.permissionsAccess[i][crud] = crudType;
    },
    changeCreateAllRolePermissions(
      state,
      {
        payload: { crud, crudType },
      }: { payload: { crud: crud; crudType: CrudType } }
    ) {
      state.createRolePermissions.permissionsAccess.map(
        (rolePermission) => (rolePermission[crud] = crudType)
      );
    },
    updateRolePermissions(
      state,
      {
        payload: { i, crud, crudType },
      }: { payload: { i: number; crud: CRUD; crudType: CrudType } }
    ) {
      state.rolePermissions[i][crud] = crudType;
    },
    // updateAllRolePermissions(
    //   state,
    //   {
    //     payload: { crud, crudType },
    //   }: { payload: { crud: CRUD; crudType: CrudType } }
    // ) {
    //   state.rolePermissions.map(
    //     (rolePermission) => (rolePermission[crud] = crudType)
    //   );
    // },
  },
  extraReducers: (builder) => {
    // roles
    builder.addCase(getRolesThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getRolesThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.roles = action.payload;
    });
    builder.addCase(getRolesThunk.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(deleteRoleThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteRoleThunk.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteRoleThunk.rejected, (state) => {
      state.loading = false;
    });

    // permissions
    builder.addCase(getPermissionsThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPermissionsThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.permissions = action.payload;
    });
    builder.addCase(getPermissionsThunk.rejected, (state) => {
      state.loading = false;
    });

    // rolesPermissions
    builder.addCase(getRolePermissionsThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getRolePermissionsThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.rolePermissions = action.payload;
    });
    builder.addCase(getRolePermissionsThunk.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(updateRolePermissionsThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateRolePermissionsThunk.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(updateRolePermissionsThunk.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getRolesPermissionsThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getRolesPermissionsThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.rolesPermissions = action.payload;
    });
    builder.addCase(getRolesPermissionsThunk.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(createRolePermissionThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createRolePermissionThunk.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createRolePermissionThunk.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const selectPermissionsManagementLoading: () => boolean = () =>
  useAppSelector((state) => state.permissionsManagement.loading);
export const selectRoles: () => Role[] = () =>
  useAppSelector((state) => state.permissionsManagement.roles);
export const selectPermissions: () => Permission[] = () =>
  useAppSelector((state) => state.permissionsManagement.permissions);
export const selectRolePermissions: () => RolePermission[] = () =>
  useAppSelector((state) => state.permissionsManagement.rolePermissions);
export const selectUpdateRolePermissions: () => {
  roleName: string;
  description: string;
  permissionsAccess: CreatePermissionAccess[];
} = () =>
  useAppSelector((state) => state.permissionsManagement.updateRolePermissions);
export const selectCreateRolePermissions: () => CreateRolePermission = () =>
  useAppSelector((state) => state.permissionsManagement.createRolePermissions);
export const selectRolesPermissions: () => RolePermission[] = () =>
  useAppSelector((state) => state.permissionsManagement.rolesPermissions);

export default permissionsManagementSlice;
