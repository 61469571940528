import { authActions } from "store/globalStore";

import { useNavigate } from "react-router";
import { useAppDispatch } from "store/storeHooks";
import { loginUserThunk } from "store/authStore/authReducer";

const useAuth = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  
  const login = (username: string, password: string) => {
    dispatch(loginUserThunk({ username, password }));
  };

  function isTimeout() {
    const tokenDateString = localStorage.getItem("expiration");
    if (tokenDateString) {
      const tokenDate = new Date(tokenDateString.toString()).getTime();
      const now = new Date().getTime();
      return tokenDate < now;
    }
    return true;
  }

  const logout = () => {
    dispatch(authActions.logout());
    localStorage.removeItem("username");
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    localStorage.removeItem("status");
    localStorage.removeItem("expiration");
    navigate("/");
  };

  const tokenExpired = () => {
    const tokenDateString = localStorage.getItem("expiration");
    if (tokenDateString) {
      const tokenDate = new Date(tokenDateString.toString()).getTime();
      const now = new Date().getTime();
      if (tokenDate < now) {
        logout();
      }
    }
  };

  return {
    login,
    logout,
    tokenExpired,
    isTimeout,
  };
};

export default useAuth;
