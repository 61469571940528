import { ExclamationIcon, XIcon, PencilIcon } from "@heroicons/react/outline";
import { ifElse, when, equals, clone } from "ramda";
import React, { useState, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import Modal from "shared/components/UI/Modal";
import ScreenModal from "shared/components/UI/ScreenModal";
import Message from "shared/components/UI/Message";
import { RolePermissionCrudAction } from "shared/interfaces/RolePermission.interface";
import { selectLoggedInUser } from "store/authStore/authReducer";
import { useAppDispatch } from "store/storeHooks";
import { selectTranslationLanguage } from "store/translationStore/translationReducer";
import {
  createUserRankThunk,
  deleteUserRankThunk,
  getUserRanksThunk,
  selectUserRanks,
  updateUserRankThunk,
} from "store/userRanksStore/userRanksReducer";
import { FormProvider, useForm } from "react-hook-form";
import {
  CreateUserRank,
  UpdateUserRank,
  UserRank,
} from "shared/interfaces/Rank.interface";
import DisplayInputError from "shared/components/UI/DisplayInputError";

export default function UserRanks() {
  const { t } = useTranslation("common");
  const lang = selectTranslationLanguage();
  const loggedInUser = selectLoggedInUser();
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getUserRanksThunk({ pageSize: 99 }));
  }, []);
  const reduxUserRanks = selectUserRanks();
  const userRanks = clone(reduxUserRanks.data.userRanks);

  const form = useForm<CreateUserRank>({
    mode: "onChange",
    defaultValues: {
      rank_eng: "",
      rank_it: "",
    },
  });

  const onSubmit = form.handleSubmit(async (data) => {
    try {
      const res: any = await dispatch(
        createUserRankThunk({
          data: {
            rank_eng: data.rank_eng,
            rank_it: data.rank_it,
          },
        })
      );
      res.error && toast.error(t("userRanks.errorMsg"));
      if (res.meta.requestStatus === "fulfilled") {
        toast.success(
          Message({
            action: "create",
            entity: t("userRanks.userRank"),
            lang,
            gender: "male",
          })
        );
        await dispatch(getUserRanksThunk({ pageSize: 99 }));
      }
    } catch (err: any) {
      if (err.response?.data?.errors?.[0]?.message) {
        toast.error(err.response?.data?.errors?.[0]?.message);
      } else {
        toast.error(err.response?.data?.message);
      }
    }
  });

  const canAccess = useMemo(
    () => (crud: RolePermissionCrudAction) => {
      if (loggedInUser.permissions["*"]) return true;
      const isEnalbed = loggedInUser.permissions["ranks"]["ENABLED"];
      if (isEnalbed) {
        const perm = loggedInUser.permissions["ranks"][crud];
        if (perm === "OWN" || perm === "ANY") return true;
      }
      return false;
    },
    []
  );
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [rankToDelete, setRankToDelete] = useState("");
  const handleDeleteRank = useMemo(
    () => (rank: string) => {
      dispatch(deleteUserRankThunk({ rankId: rank })).then((res: any) => {
        if (res.meta.requestStatus === "rejected") {
          toast.error(
            Message({
              action: "delete",
              entity: t("userRanks.userRank"),
              error: t("genericError"),
              lang,
              gender: "male",
            })
          );
        } else {
          toast.success(
            Message({
              action: "delete",
              entity: t("userRanks.userRank"),
              lang,
              gender: "male",
            })
          );
          dispatch(getUserRanksThunk({ pageSize: 99 })).then((res) =>
            when(
              () => equals(res.meta.requestStatus, "fulfilled"),
              () =>
                toast.error({
                  action: "create",
                  entity: t("userRanks.userRank"),
                  error: t("genericError"),
                  lang,
                  gender: "male",
                })
            )
          );
        }
      });
      setOpenDeleteModal(false);
    },
    []
  );
  const [openEditModal, setOpenEditModal] = useState(false);
  const rankRef = useRef<UserRank | null>(null);
  const updateForm = useForm<UpdateUserRank>({
    mode: "onChange",
    defaultValues: {
      rank_eng: "",
      rank_it: "",
    },
  });

  const onUpdate = updateForm.handleSubmit((data) =>
    dispatch(
      updateUserRankThunk({
        rankId: String(rankRef.current?.id),
        data: {
          rank_eng: data.rank_eng,
          rank_it: data.rank_it,
        },
      })
    )
      .then((res) =>
        ifElse(
          () => equals(res.meta.requestStatus, "rejected"),
          () =>
            toast.error(
              Message({
                action: "update",
                entity: t("userRanks.userRank"),
                error: t("genericError"),
                lang,
                gender: "male",
              })
            ),
          () => (
            toast.success(
              Message({
                action: "update",
                entity: t("userRanks.userRank"),
                lang,
                gender: "male",
              })
            ),
            dispatch(getUserRanksThunk({ pageSize: 99 })).then((res) =>
              when(
                () => equals(res.meta.requestStatus, "rejected"),
                () =>
                  toast.error(
                    Message({
                      action: "read",
                      entity: t("userRanks.userRank"),
                      error: t("genericError"),
                      lang,
                      gender: "male",
                    })
                  )
              )(null)
            )
          )
        )()
      )
      .then(() => {
        rankRef.current = null;
        setOpenEditModal(false);
      })
  );

  return (
    <div className="flex flex-col gap-5">
      <FormProvider {...form}>
        <div className="flex gap-2">
          <label className="flex flex-col gap-2">
            <span className="text-lg font-semibold">
              {t("userRanks.rank")} ({t("languages.english")})
            </span>
            <input
              type="text"
              {...form.register("rank_eng", {
                required: `${t("userRanks.englishRankRequired")}`,
              })}
              placeholder={t("userRanks.userRank")}
              className="text-gray-600 bg-white focus:border-global-input-focus border-gray-600 rounded-sm focus:ring-0"
              autoComplete="off"
            />
            <DisplayInputError
              message={form.formState.errors.rank_eng?.message}
            />
          </label>
          <label className="flex flex-col gap-2">
            <span className="text-lg font-semibold">
              {t("userRanks.rank")} ({t("languages.italian")})
            </span>
            <input
              type="text"
              {...form.register("rank_it", {
                required: `${t("userRanks.italianRankRequired")}`,
              })}
              placeholder={t("userRanks.userRank")}
              className="text-gray-600 bg-white focus:border-global-input-focus border-gray-600 rounded-sm focus:ring-0"
              autoComplete="off"
            />
            <DisplayInputError
              message={form.formState.errors.rank_it?.message}
            />
          </label>
          <div className="flex flex-col gap-2 justify-end">
            <button
              type="button"
              onClick={onSubmit}
              className={
                "px-4 py-2 bg-primary border border-primary text-white"
              }
            >
              {t("userRanks.add")}
            </button>
            {Object.keys(form.formState.errors).length > 0 ? (
              <div className="h-4"></div>
            ) : null}
          </div>
        </div>
        <div className="flex flex-wrap gap-2">
          {userRanks?.map((userRank) => (
            <div key={userRank.id} className="relative flex">
              <div className="flex gap-2 items-center p-2 w-full h-full max-h-full text-gray-600 bg-gray-100 border border-gray-600 rounded-sm">
                <span className="flex w-full bg-gray-100 border-0 focus:ring-0">
                  {lang === "en" ? userRank.rank_eng : userRank.rank_it}
                </span>
                {canAccess("UPDATE") ? (
                  <button
                    type="button"
                    onClick={() => {
                      rankRef.current = userRank;
                      setOpenEditModal(true);
                    }}
                    className="flex items-center h-full text-black"
                  >
                    <PencilIcon className="w-4 h-4" />
                  </button>
                ) : null}
                {canAccess("DELETE") ? (
                  <button
                    type="button"
                    onClick={() => {
                      setRankToDelete(String(userRank.id));
                      setOpenDeleteModal(true);
                    }}
                    className="flex items-center h-full text-black"
                  >
                    <XIcon className="w-4 h-4" />
                  </button>
                ) : null}
              </div>
            </div>
          ))}
        </div>
        <ScreenModal isOpen={openEditModal} setIsOpen={setOpenEditModal}>
          <div className="flex flex-col gap-10 p-10 bg-white rounded-md">
            <div className="flex justify-between">
              <h1 className="text-2xl font-bold">{t("userRanks.update")}</h1>
              <button
                type="button"
                className="text-white bg-red-500 rounded-md"
                onClick={() => {
                  rankRef.current = null;
                  updateForm.clearErrors();
                  setOpenEditModal(false);
                }}
              >
                <XIcon className="w-8 h-8" />
              </button>
            </div>
            <div className="flex flex-col gap-5">
              <label className="flex flex-col gap-2">
                <span className="text-lg font-semibold">
                  {t("userRanks.rank")} ({t("languages.english")})
                </span>
                <input
                  type="text"
                  {...updateForm.register("rank_eng", {
                    required: `${t("userRanks.englishRankRequired")}`,
                  })}
                  placeholder={rankRef.current?.rank_eng}
                  className="text-gray-600 bg-white focus:border-global-input-focus border-gray-600 rounded-sm focus:ring-0"
                  autoComplete="off"
                />
                <DisplayInputError
                  message={updateForm.formState.errors.rank_eng?.message}
                />
              </label>
              <label className="flex flex-col gap-2">
                <span className="text-lg font-semibold">
                  {t("userRanks.rank")} ({t("languages.italian")})
                </span>
                <input
                  type="text"
                  {...updateForm.register("rank_it", {
                    required: `${t("userRanks.italianRankRequired")}`,
                  })}
                  placeholder={rankRef.current?.rank_it}
                  className="text-gray-600 bg-white focus:border-global-input-focus border-gray-600 rounded-sm focus:ring-0"
                  autoComplete="off"
                />
                <DisplayInputError
                  message={updateForm.formState.errors.rank_it?.message}
                />
              </label>
              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={onUpdate}
                  className="px-4 py-2 text-white bg-primary border border-primary rounded-sm"
                >
                  {t("userRanks.done")}
                </button>
              </div>
            </div>
          </div>
        </ScreenModal>
        <Modal
          openStatus={openDeleteModal}
          setOpen={setOpenDeleteModal}
          className=""
          icon={
            <ExclamationIcon
              className="w-6 h-6 text-red-600 bg-transparent"
              aria-hidden="true"
            />
          }
          header={
            <p>
              {t("deleteModal.delete")} {t("userRanks.userRank")}
            </p>
          }
          title={
            <p>
              {t("deleteModal.sure")} {t("userRanks.userRank")}?{" "}
              {t("deleteModal.undone")}
            </p>
          }
          footer={
            <div className="mt-5 sm:flex sm:flex-row-reverse sm:mt-4">
              <button
                type="button"
                className="inline-flex justify-center px-4 py-1 w-full text-white text-base font-medium bg-red-600 hover:bg-red-700 border border-transparent focus:outline-none shadow-sm focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                onClick={async () => await handleDeleteRank(rankToDelete)}
              >
                {t("deleteModal.delete")}
              </button>
              <button
                type="button"
                className="inline-flex justify-center mt-3 px-4 py-1 w-full hover:text-gray-500 text-gray-700 text-base font-medium bg-white border border-gray-300 focus:outline-none shadow-sm focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                onClick={() => setOpenDeleteModal(false)}
              >
                {t("deleteModal.cancel")}
              </button>
            </div>
          }
        />
      </FormProvider>
    </div>
  );
}
