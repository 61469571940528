import { New } from "shared/interfaces/New.interface";

export interface NewsState {
  data: {
    post: New | null;
    message: string;
    newsTags: string[];
    total: number;
    pageIndex: number;
    data: New[];
  };
  loading: boolean;
}

export const newsInitialState: NewsState = {
  data: {
    post: null,
    message: "",
    newsTags: [],
    total: 0,
    pageIndex: 0,
    data: [],
  },
  loading: false,
};
