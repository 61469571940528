import React from "react";
import { XIcon } from "@heroicons/react/outline";
import { UseFormSetValue, UseFormWatch } from "react-hook-form";

export default function DisplayDocument({
  setValue,
  watch,
  reactHookFormName,
}: {
  setValue: UseFormSetValue<any>;
  watch: UseFormWatch<any>;
  reactHookFormName: string;
}) {
  const file: File = watch(reactHookFormName);
  if (file) {
    return (
      <div className="flex gap-1 items-center w-min border rounded-sm">
        <div className="p-1">{file.name}</div>
        <button
          type="button"
          onClick={() => {
            setValue(reactHookFormName, null);
          }}
          className="p-1 bg-red-500"
        >
          <XIcon className="w-6 h-6 text-white" />
        </button>
      </div>
    );
  }
  return null;
}
