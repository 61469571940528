import React, { useState, useEffect, useRef, useCallback } from "react";
import { useForm, Controller } from "react-hook-form";
import Overlay from "shared/components/UI/Overlay";
import { useAppDispatch, useAppSelector } from "../../store/storeHooks";
import { useNavigate } from "react-router";
import Spinner from "shared/components/UI/Spinner";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { toast } from "react-toastify";
import { CMS_BE_URL } from "api/urls";
import { getTagsThunk, selectTags } from "store/tagsStore/tagsReducer";
import Creatable from "react-select/creatable";
import EmailEditor from "react-email-editor";
import GoBack from "shared/components/UI/GoBack";
import {
  getInputStyles,
  getReactSelectStyles,
  stringToFile,
} from "utils/utils";
import sample from "shared/assets/sample/sample.json";
import { PermissionDomain } from "shared/interfaces/Permission.interface";
import { RolePermissionCrudAction } from "shared/interfaces/RolePermission.interface";
import { selectLoggedInUser } from "store/authStore/authReducer";
import DisplayInputError from "shared/components/UI/DisplayInputError";
import DocumentDropzone from "shared/components/UI/DocumentDropzone";
import { DevTool } from "@hookform/devtools";
import DisplayDocument from "shared/components/UI/DisplayDocument";

const CreateDocument = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const lang = useAppSelector((state) => state.translation.language);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation("common");
  const [richtext, setRichtext] = useState("");
  const [json, setJson] = useState<any>("");

  const loggedInUser = selectLoggedInUser();

  // const handleFileChange = (e: any) => {
  //   setValue("documentFile", e.target.files);
  // };

  function canAccess(
    domain: PermissionDomain,
    action: RolePermissionCrudAction
  ): boolean {
    if (loggedInUser.permissions["*"]) {
      return true;
    } else {
      switch (loggedInUser.permissions[domain][action]) {
        case "NONE": {
          return false;
        }
        case "OWN": {
          if (action === "CREATE") return true;
          return false;
        }
        case "ANY": {
          return true;
        }
        default: {
          return false;
        }
      }
    }
  }

  //GET TAGS
  const [tagsOptions, setTagsOptions] = useState<any>([]);
  const tags = selectTags();
  useEffect(() => {
    dispatch(getTagsThunk());
  }, []);
  useEffect(() => {
    const tagsMap = tags.data.tags.map((tag) => {
      return {
        value: tag.pk_tag,
        label: tag.pk_tag,
      };
    });
    setTagsOptions(tagsMap);
  }, [tags]);

  //

  //EMAIL EDITOR
  const emailEditorRef: any = useRef(null);

  const onLoad = useCallback(() => {
    const timer = setInterval(() => {
      if (
        emailEditorRef &&
        emailEditorRef.current &&
        emailEditorRef.current.editor
      ) {
        emailEditorRef.current.editor.loadDesign(sample);
        emailEditorRef.current.editor.addEventListener(
          "design:updated",
          function () {
            // Design is updated by the user

            emailEditorRef.current.editor.exportHtml(function (data: any) {
              const json = data.design; // design json
              const html = data.html; // design html
              setRichtext(html);
              setJson(json);
              // Save the json, or html here
            });
          }
        );
        emailEditorRef.current.editor.registerCallback(
          "image",
          async (file: any, done: any) => {
            const formD = new FormData();
            formD.append("fileType", "document_doc");
            formD.append("files", file.attachments[0]);

            const res = await axios.post(
              `${CMS_BE_URL}/files/uploadFile`,
              formD,
              {
                headers: {
                  "Content-Type":
                    "multipart/form-data; boundary=<calculated when request is sent>",
                },
              }
            );
            const url = res.data.urls[0];

            done({ progress: 100, url });
          }
        );

        clearInterval(timer);
      }
    }, 500);
  }, [emailEditorRef]);

  const onReady = () => {
    emailEditorRef.current.editor.setBodyValues({
      text: {
        fontSize: "50px",
      },
    });
  };

  const {
    register,
    handleSubmit,
    getValues,
    control,
    formState: { errors },
    setValue,
    trigger,
    setError,
    watch,
  } = useForm<{
    title: string;
    description: string;
    richtext: any;
    styles_json: any;
    documentFile: any;
    tags: any;
    fileType: string;
    is_visible: number;
  }>({
    mode: "onChange",
    defaultValues: {
      fileType: "document_doc",
      title: "",
      description: "",
      styles_json: null,
      documentFile: null,
      is_visible: 1,
      richtext: null,
      tags: [],
    },
  });

  //TOASTIFY
  const DocumentCreatedMsg = () => (
    <div className="flex flex-col gap-1">
      <p className="text-xs font-semibold">{t("Documents.msgHeader")}</p>
      <p className="text-xs">{t("Documents.documentCreated")}</p>
    </div>
  );

  const displayDocumentCreatedMsg = () => {
    toast.success(DocumentCreatedMsg);
  };

  const DocumentErrorMsg = () => (
    <div className="flex flex-col gap-1">
      <p className="text-xs font-semibold">{t("Documents.msgHeaderError")}</p>
      <p className="text-xs">{t("Documents.documentCreatedError")}</p>
    </div>
  );

  const displayDocumentErrorMsg = () => {
    toast.error(DocumentErrorMsg);
  };

  const onSubmit = async (data: any) => {
    if (data.documentFile === null) {
      delete data.documentFile;
    }
    if (data.tags.length) {
      data.tags = JSON.stringify(data.tags.map((tag: any) => tag.value));
    }

    data.richtext = stringToFile(richtext);
    data.styles_json = JSON.stringify(json);
    setLoading(true);
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]: any) => {
      if (Array.isArray(value)) {
        value = JSON.stringify(value);
      }
      formData.append(key, value);
    });

    try {
      const response = await axios.post(`${CMS_BE_URL}/documents`, formData);
      setLoading(false);
      displayDocumentCreatedMsg();
      navigate("/documents");
      return response;
    } catch (err: any) {
      setLoading(false);
      displayDocumentErrorMsg();
      return err;
    }
  };

  return (
    <Overlay active={loading} spinner={<Spinner />}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col gap-5 p-10 h-full"
      >
        <GoBack uri="documents" />
        <div className="flex gap-10 items-center justify-between w-full">
          <div className="flex flex-col gap-5">
            <h1 className="text-3xl font-bold">{t("UserCRUD.documents")}</h1>
            <p className="text-global-subHeader text-xl">
              {t("UserCRUD.documentsP")}
            </p>
          </div>
          <div className="flex gap-5">
            <div className="flex gap-5 items-center">
              <span className="text-lg font-medium">
                {" "}
                {t("document.status")}:{" "}
              </span>
              <select {...register("is_visible")}>
                <option value={1}>Published</option>
                <option value={0}>Draft</option>
              </select>
            </div>
            {canAccess("documents", "CREATE") && (
              <input
                className="px-4 py-2 text-white bg-users-create cursor-pointer"
                type="submit"
                value={t("UserCRUD.publish").toString()}
              />
            )}
          </div>
        </div>

        {/* MAIN FORM */}
        <div className="flex gap-5 justify-center">
          <div className="flex flex-col gap-2 w-full">
            <div className="flex flex-col gap-2 w-full">
              <label htmlFor="title" className="block text-lg font-medium">
                {t("UserCRUD.title")} *
              </label>
              <input
                type="text"
                className={getInputStyles(!!errors.title)}
                defaultValue=""
                {...register("title", { required: "Title is required." })}
              />
              <DisplayInputError message={errors.title?.message} />
            </div>
            <div className="flex flex-col gap-2 w-full">
              <label
                htmlFor="shortDescription"
                className="block text-lg font-medium"
              >
                {t("UserCRUD.shortDescription")}*
              </label>
              <input
                type="text"
                className={getInputStyles(!!errors.description)}
                defaultValue=""
                {...register("description", {
                  required: "Short description is required.",
                  maxLength: 1000,
                })}
              />
              <DisplayInputError message={errors.description?.message} />
            </div>
            <div className="flex flex-col gap-2">
              <label htmlFor="tags" className="block text-lg font-medium">
                Tags *
              </label>
              <Controller
                name="tags"
                control={control}
                rules={{
                  required: "Tags are required.",
                }}
                render={({ field }) => (
                  <Creatable
                    placeholder={
                      lang === "en"
                        ? "Select or create tags"
                        : "Seleziona o crea tag"
                    }
                    styles={getReactSelectStyles(!!errors.tags)}
                    isLoading={tags.loading}
                    inputId="tags"
                    isMulti={true}
                    options={tagsOptions}
                    value={field.value}
                    onChange={(e) => field.onChange(e)}
                  />
                )}
              />
              <DisplayInputError message={errors.tags?.message} />
            </div>
            <label className="text-lg font-medium">
              {t("Documents.upload")}
            </label>
            <DocumentDropzone
              trigger={trigger}
              setValue={setValue}
              errors={errors}
              reactHookFormName={"documentFile"}
              setError={setError}
              register={register}
              required={true}
            />
            <DisplayInputError message={errors.documentFile?.message} />
            <DisplayDocument
              setValue={setValue}
              watch={watch}
              reactHookFormName={"documentFile"}
            />
            {/* <label className="flex flex-col gap-2" htmlFor="file">
              <p className="text-gray-700 text-lg font-medium">
                {t("Documents.upload")}
              </p>
              <input
                {...(register("documentFile"), { required: true })}
                type="file"
                accept="application/pdf,application/msword,application/docx"
                onChange={handleFileChange}
              />
            </label> */}
            <div className="pb-10">
              <label
                htmlFor="richText"
                className="flex flex-col gap-2 text-lg font-medium"
              >
                {t("UserCRUD.richText")}
                <div className={errors.richtext ? "border border-red-500" : ""}>
                  <EmailEditor
                    // onReady={onReady}
                    minHeight={1000}
                    ref={emailEditorRef}
                    onLoad={onLoad}
                  />
                </div>
              </label>
            </div>
          </div>
        </div>
        <DevTool control={control} />
      </form>
    </Overlay>
  );
};

export default CreateDocument;
