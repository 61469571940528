import axios, { AxiosResponse } from "axios";
import {
  getRolesURL,
  createRoleURL,
  updateRoleURL,
  deleteRoleURL,
} from "./urls";
import { CreateRole } from "shared/interfaces/Role.interface";

export const createRole: (
  data: CreateRole
) => Promise<AxiosResponse<any, any>> = (data: CreateRole) => {
  return axios.post(createRoleURL(), data);
};

export const getRoles: () => Promise<AxiosResponse<any, any>> = () => {
  return axios.get(getRolesURL());
};

export const updateRole: (
  roleID: number,
  data: CreateRole
) => Promise<AxiosResponse<any, any>> = (roleID: number, data: CreateRole) => {
  return axios.put(updateRoleURL(roleID), data);
};

export const deleteRole: (
  roleID: number
) => Promise<AxiosResponse<any, any>> = (roleID: number) => {
  return axios.delete(deleteRoleURL(roleID));
};

export const getRoleColumns: (lang: string) => {
  Header: string;
  accessor: string;
}[] = (lang: string) => {
  return [
    {
      Header: lang === "en" ? "role" : "ruolo",
      accessor: "col1",
      width: "200",
    },
    {
      Header: lang === "en" ? "description" : "descrizione",
      accessor: "col2",
      width: "250",
    },
    {
      Header: lang === "en" ? "users" : "utenti",
      accessor: "col3",
      width: "150",
    },
    {
      Header: lang === "en" ? "actions" : "azioni",
      accessor: "col4",
      width: "150",
    },
  ];
};
