import { GroupBase, OptionsOrGroups } from "react-select";
import { Lang } from "shared/components/UI/Message";

export type ProductClassEnum = "product" | "exam" | "subscription";

export type Product = {
  id: string;
  class: ProductClassEnum;
  slug: string;
  title: string;
  short_description: string;
  description: string;
  thumbnail: string;
  vat_percentage: number;
  creator_id: string;
  last_modifier: string;
  only_subs: boolean;
  published: boolean;
  created_at: string;
  updated_at: string;
  tags: {
    pk_tag: string;
    last_modifier: string;
    total_views: number;
    created_at: string;
    updated_at: string;
  }[];
  categories: {
    id: number;
    name: string;
    slug: string;
    description: string;
    created_at: string;
    updated_at: string;
  }[];
};

export type Variant = {
  id: string;
  product_id: string;
  title: string;
  sku: string;
  size: string;
  color: string;
  inventory_quantity: number;
  price: number;
  created_at: string;
  updated_at: string;
  media: string[];
};

export type EnchancedProduct = Product & {
  variants: Variant[];
};

export const productsClassesOptions: (lang: Lang) =>
  | OptionsOrGroups<
      {
        label: string;
        value: ProductClassEnum;
      },
      GroupBase<{
        label: string;
        value: ProductClassEnum;
      }>
    >
  | undefined = function (lang: Lang) {
  return [
    { label: lang === "en" ? "Product" : "Prodotto", value: "product" },
    { label: lang === "en" ? "Exam" : "Esame", value: "exam" },
    {
      label: lang === "en" ? "Subscription" : "sottoscrizione",
      value: "subscription",
    },
  ];
};
