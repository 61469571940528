import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import AsyncSelect from "react-select/async";
import {
  getDisabledInputStyles,
  getInputStyles,
  getReactSelectStyles,
} from "utils/utils";
import { OptionsOrGroups } from "react-select";
import { useAppDispatch } from "store/storeHooks";
import { debounce } from "lodash";
import {
  EnchancedProduct,
  Variant,
} from "shared/interfaces/Product.new.interface";
import Select from "react-select";
import {
  Controller,
  useForm,
  useFormContext,
  UseFormReturn,
} from "react-hook-form";
import DisplayInputError from "../../shared/components/UI/DisplayInputError";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ProductWithMedia } from "shared/interfaces/Orders.interface";
import { getProducts } from "api/Products/products.api";
import { CreateOrder, OrderProduct } from "./CreateOrder";

export function productLabel(product: EnchancedProduct | ProductWithMedia) {
  const temp = [];
  if (!product) return "";
  if (product.title) {
    temp.push(product.title);
  }
  if (product.short_description) {
    temp.push(product.short_description);
  }
  if (product.slug) {
    temp.push(product.slug);
  }
  return temp.join(" - ");
}

export function variantLabel(variant: Variant) {
  const temp = [];
  if (!variant) return "";
  if (variant.title) {
    temp.push(variant.title);
  }
  if (variant.inventory_quantity) {
    temp.push(variant.inventory_quantity + " in stock");
  }
  if (variant.price) {
    temp.push(variant.price + "€");
  }
  return temp.join(" - ");
}

export function quantityPlaceholder(
  inventory_quantity: number,
  isForce: boolean
) {
  if (isForce) return "Infinity";
  if (inventory_quantity) {
    return `Max: ${inventory_quantity}`;
  } else {
    return `0`;
  }
}

export function maxQuantity(inventory_quantity: number, isForce: boolean) {
  if (isForce) return Infinity;
  if (inventory_quantity) {
    return inventory_quantity;
  } else {
    return 0;
  }
}

export function productToOrder({
  product,
  variant,
  quantity,
  price,
  tax,
  forcePrice,
  forceTax,
}: {
  product: EnchancedProduct;
  variant: {
    label: string;
    value: Variant;
  };
  quantity: string;
  price: string;
  tax: string;
  forcePrice: boolean;
  forceTax: boolean;
}): OrderProduct {
  return {
    ...product,
    selection: {
      variant: variant.value,
      price: forcePrice ? price : String(variant.value.price),
      quantity,
      vat_percentage: forceTax ? tax : String(product.vat_percentage),
    },
  };
}

function receiptProductToEnchanced(product: OrderProduct): EnchancedProduct {
  const temp: EnchancedProduct & {
    selection?: {
      variant: Variant;
      price: string;
      quantity: string;
      vat_percentage: string;
    };
  } = {
    ...product,
  };
  delete temp.selection;
  return temp;
}

export function insertOrReplaceProduct(
  products: OrderProduct[],
  product: OrderProduct
) {
  const index = products.findIndex(
    (p) => p.selection.variant.id === product.selection.variant.id
  );
  if (index === -1) {
    return [...products, product];
  } else {
    const temp = [...products];
    temp[index] = { ...product };
    return temp;
  }
}

export function productVatPercentageToInteger(product: EnchancedProduct) {
  return {
    ...product,
    vat_percentage: product.vat_percentage * 100,
  };
}

export function calcProductQtyNetPrice(variant: Variant, quantity: number) {
  if (
    yup.object().required().isValidSync(variant) &&
    yup.number().integer().min(1).required().isValidSync(quantity)
  ) {
    return variant.price * quantity;
  } else {
    return 0;
  }
}

export type SelectProductForm = {
  product: EnchancedProduct;
  variant: {
    label: string;
    value: Variant;
  };
  quantity: string;
  price: string;
  tax: string;
};

export default function SelectProduct({
  isOpen,
  setIsOpen,
  editProduct,
}: {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  editProduct: OrderProduct | null;
}) {
  const { t } = useTranslation("common");

  const [variantsOptions, setVariantsOptions] = useState<any>();
  const [forcePrice, setForcePrice] = useState(false);
  const [forceQuantity, setForceQuantity] = useState(false);
  const [forceTax, setForceTax] = useState(false);
  const orderForm = useFormContext<CreateOrder>();
  const formRef = useRef<null | UseFormReturn<SelectProductForm>>(null);
  const schema = yup.object({
    product: yup.object().required("Product is required"),
    variant: yup.object().required("Variant is required."),
    quantity: yup.lazy(() => {
      const quantitySchema = yup
        .number()
        .integer(t("receipt.quantityInteger"))
        .min(1, t("quantityLtOne"))
        .required(t("quantityRequired"));
      if (formRef.current) {
        return quantitySchema.max(
          maxQuantity(
            formRef.current.watch("variant.value.inventory_quantity"),
            forceQuantity
          )
        );
      } else {
        return quantitySchema;
      }
    }),
    price: yup.lazy(() => {
      if (forcePrice) {
        return yup
          .number()
          .positive(t("receipt.priceGtZero"))
          .required(t("receipt.priceRequired"));
      }
      return yup.number().optional();
    }),
    tax: yup
      .number()
      .integer(t("receipt.taxInteger"))
      .min(0, t("receipt.taxGteZero"))
      .max(100, t("receipt.taxLte"))
      .required(),
  });
  const form = useForm<SelectProductForm>({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      quantity: "0",
      price: "0",
      tax: "0",
    },
  });

  const _loadOptions = (
    e: string,
    callback: (options: OptionsOrGroups<any, any>) => void
  ) => {
    getProducts({
      include: "variants",
      searchString: e,
    })
      .then((res) => {
        const productOptions =
          res.data.data.map((product: EnchancedProduct) => {
            product = productVatPercentageToInteger(product);
            return {
              label: productLabel(product),
              value: product,
            };
          }) || [];
        callback(productOptions);
      })
      .catch(() => callback([]));
  };
  const loadOptions = useCallback(debounce(_loadOptions, 400), []);

  const onSubmit = form.handleSubmit((data) => {
    const orderProduct = productToOrder({
      ...data,
      forcePrice,
      forceTax,
    });
    orderForm.setValue(
      "products",
      insertOrReplaceProduct(orderForm.getValues("products"), orderProduct)
    );
    setIsOpen(false);
  });

  useEffect(() => {
    const p = form.getValues("product");
    const tempVariantsOptions = p?.variants.map((variant) => {
      return {
        value: variant,
        label: variantLabel(variant),
      };
    });
    if (tempVariantsOptions) {
      setVariantsOptions(tempVariantsOptions);
    }
  }, [form.watch("product")]);

  const isQtyDisabled = !form.watch("product") || !form.watch("variant");

  useEffect(() => {
    if (editProduct) {
      const enchancedEditProduct = receiptProductToEnchanced(editProduct);
      form.setValue("product", enchancedEditProduct);
      form.setValue("variant", {
        value: editProduct.selection.variant,
        label: variantLabel(editProduct.selection.variant),
      });
      if (
        Number(editProduct.selection.quantity) >
        editProduct.selection.variant.inventory_quantity
      ) {
        setForceQuantity(true);
      }
      form.setValue("quantity", editProduct.selection.quantity);
      if (
        Number(editProduct.selection.price) !==
        editProduct.selection.variant.price
      ) {
        setForcePrice(true);
      }
      form.setValue("price", String(editProduct.selection.price));
      if (
        editProduct.vat_percentage !==
        Number(editProduct.selection.vat_percentage) / 100
      ) {
        setForceTax(true);
      }
      form.setValue("tax", editProduct.selection.vat_percentage);
    }
  }, []);

  return (
    <div className="flex flex-col flex-grow gap-5">
      <div className="flex flex-grow gap-5 justify-between">
        <form
          className="flex flex-col flex-grow gap-5 items-center justify-between w-full"
          onSubmit={onSubmit}
        >
          <div className="flex flex-col gap-2 w-full">
            <label htmlFor="searchUser" className="text-lg font-medium">
              {t("product.search")}
            </label>
            <AsyncSelect
              cacheOptions
              defaultOptions
              placeholder={t("product.searchPlaceholder")}
              styles={getReactSelectStyles(false)}
              inputId="searchUser"
              isClearable={true}
              loadOptions={loadOptions}
              value={
                editProduct
                  ? {
                      label: productLabel(form.watch("product")),
                      value: form.watch("product"),
                    }
                  : undefined
              }
              onChange={(e: any) => {
                if (e) {
                  form.setValue("product", e.value);
                }
              }}
            />
            <DisplayInputError
              message={(form.formState.errors.product as any)?.message}
            />
          </div>
          <div className="flex gap-2 w-full">
            <div className="flex flex-col gap-2 w-2/3">
              <label htmlFor="searchUser" className="text-lg font-medium">
                {t("product.variant")}
              </label>
              <Controller
                name="variant"
                control={form.control}
                rules={{
                  required: "Variant is required.",
                }}
                render={({ field }) => (
                  <Select
                    placeholder={t("product.variant")}
                    styles={getReactSelectStyles(false)}
                    inputId="variant"
                    isMulti={false}
                    isClearable={true}
                    options={variantsOptions}
                    value={form.watch("variant")}
                    isDisabled={!form.watch("product")}
                    onChange={(e) => field.onChange(e)}
                  />
                )}
              />
              <DisplayInputError
                message={(form.formState.errors.variant as any)?.message}
              />
            </div>
            <div className="flex flex-col gap-2 w-1/3">
              <label htmlFor="rank" className="text-lg font-medium">
                {t("product.quantity")}
              </label>
              <input
                type="number"
                placeholder={quantityPlaceholder(
                  form.watch("variant.value.inventory_quantity"),
                  forceQuantity
                )}
                {...form.register("quantity")}
                className={
                  isQtyDisabled ? getInputStyles(false) : getInputStyles(false)
                }
              />
              <div className="flex gap-2 items-center">
                <input
                  id="forceQuantity"
                  type="checkbox"
                  checked={forceQuantity}
                  onChange={() => {
                    if (forceQuantity) {
                      form.setValue("quantity", "1");
                    }
                    form.trigger("quantity");
                    setForceQuantity(!forceQuantity);
                  }}
                  className="text-primary"
                />
                <label htmlFor="forceQuantity" className="select-none">
                  {t("product.forceQuantity")}
                </label>
              </div>
              <DisplayInputError
                message={(form.formState.errors.quantity as any)?.message}
              />
            </div>
          </div>
          <div className="flex items-center justify-between w-full">
            <div className="flex flex-col gap-2">
              <label htmlFor="price" className="text-lg font-semibold">
                {t("product.price")}
              </label>
              <div className="flex gap-2 items-center">
                <input
                  id="price"
                  type="text"
                  disabled={!forcePrice}
                  value={
                    forcePrice
                      ? form.watch("price")
                      : form.watch("variant")?.value.price
                  }
                  onChange={(e) => {
                    form.setValue("price", e.target.value);
                    form.trigger("price");
                  }}
                  className={
                    forcePrice
                      ? getInputStyles(false)
                      : getDisabledInputStyles()
                  }
                />
                <span className="text-lg font-semibold">&euro;</span>
              </div>
              <div className="flex gap-2 items-center">
                <input
                  id="forcePrice"
                  type="checkbox"
                  checked={forcePrice}
                  onChange={() => {
                    if (forcePrice) {
                      form.setValue(
                        "price",
                        String(
                          calcProductQtyNetPrice(
                            form.getValues("variant")?.value,
                            Number(form.getValues("quantity"))
                          )
                        )
                      );
                    }
                    form.trigger("price");
                    setForcePrice(!forcePrice);
                  }}
                  className="text-primary"
                />
                <label htmlFor="forcePrice" className="select-none">
                  {t("product.forcePrice")}
                </label>
              </div>
              <DisplayInputError
                message={form.formState.errors.price?.message}
              />
            </div>
            <div className="flex flex-col gap-2">
              <label htmlFor="price" className="text-lg font-semibold">
                {t("product.tax")}
              </label>
              <div className="flex gap-2 items-center">
                <input
                  id="tax"
                  type="text"
                  disabled={!forceTax}
                  value={
                    forceTax
                      ? form.watch("tax")
                      : String(form.watch("product")?.vat_percentage) || 0
                  }
                  onChange={(e) => {
                    form.setValue("tax", String(e.target.value));
                    form.trigger("tax");
                  }}
                  className={
                    forceTax ? getInputStyles(false) : getDisabledInputStyles()
                  }
                />
              </div>
              <div className="flex gap-2 items-center">
                <input
                  id="forceTax"
                  type="checkbox"
                  checked={forceTax}
                  onChange={() => {
                    if (forceTax) {
                      form.setValue(
                        "tax",
                        String(form.watch("product")?.vat_percentage)
                      );
                    }
                    form.trigger("tax");
                    setForceTax(!forceTax);
                  }}
                  className="text-primary"
                />
                <label htmlFor="forceTax" className="select-none">
                  {t("product.forceTax")}
                </label>
              </div>
              <DisplayInputError message={form.formState.errors.tax?.message} />
            </div>
            <div className="flex gap-2 self-end">
              <button
                type="button"
                onClick={() => {
                  setIsOpen(false);
                }}
                className="px-4 py-2 border rounded-sm"
              >
                {t("product.cancel")}
              </button>
              <button
                type="submit"
                className="px-4 py-2 text-white bg-global-save rounded-sm"
              >
                {t("product.done")}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
