import React, { Fragment } from "react";
import { CreatePermissionAccess } from "shared/interfaces/RolePermission.interface";
import { permissionsManagementActions } from "store/globalStore";
import { selectUpdateRolePermissions } from "store/permissionsManagement/permissionsManagementReducer";
import { useAppDispatch } from "store/storeHooks";
import UpdatePermissionChild from "./UpdatePermissionChild";
import UpdatePermissionOnlyCheckBox from "./UpdatePermissionOnlyCheckbox";

// ** type definitions **
type crud = "create" | "read" | "update" | "delete";
type CrudType = "ANY" | "OWN" | "NONE";

const UpdatePermissionParent = ({
  permission,
  childrenRowsWithButtons,
  childrenRowsSimple,
  buttonsParent,
}: {
  permission?: CreatePermissionAccess;
  childrenRowsWithButtons?: (CreatePermissionAccess | undefined)[];
  childrenRowsSimple?: (CreatePermissionAccess | undefined)[];
  buttonsParent: boolean;
}) => {
  const dispatch = useAppDispatch();
  const rolePermissions = selectUpdateRolePermissions();

  function isChecked(i: number, crud: crud, crudType: CrudType): boolean {
    if (rolePermissions?.permissionsAccess[i]?.[crud] === crudType) return true;
    return false;
  }

  const onEnabledChangeHandler = (i: number, bool: boolean) => {
    const cruds: crud[] = ["create", "read", "update", "delete"];
    const cuds: crud[] = ["create", "update", "delete"];
    dispatch(
      permissionsManagementActions.changeUpdateRolePermissionEnabledAccess({
        i,
        bool,
      })
    );
    dispatch(
      permissionsManagementActions.changeUpdateRolePermissionsPermissionAccess({
        i,
        crud: "read",
        crudType: bool ? "ANY" : "NONE",
      })
    );
    if (!buttonsParent) {
      cruds.map((crud) => {
        dispatch(
          permissionsManagementActions.changeUpdateRolePermissionsPermissionAccess(
            {
              i,
              crud,
              crudType: bool ? "ANY" : "NONE",
            }
          )
        );
      });
    }
    if (!bool) {
      cuds.map((cud) => {
        dispatch(
          permissionsManagementActions.changeUpdateRolePermissionsPermissionAccess(
            {
              i,
              crud: cud,
              crudType: "NONE",
            }
          )
        );
      });
      if (childrenRowsWithButtons) {
        childrenRowsWithButtons?.map((row) => {
          cruds.map((crud) => {
            dispatch(
              permissionsManagementActions.changeUpdateRolePermissionsPermissionAccess(
                {
                  i: (row as CreatePermissionAccess).index,
                  crud,
                  crudType: "NONE",
                }
              )
            );
          });
          dispatch(
            permissionsManagementActions.changeUpdateRolePermissionEnabledAccess(
              {
                i: (row as CreatePermissionAccess).index,
                bool,
              }
            )
          );
        });
      }
      if (typeof childrenRowsSimple !== "undefined" && childrenRowsSimple) {
        childrenRowsSimple.map((row) => {
          cruds.map((crud) => {
            dispatch(
              permissionsManagementActions.changeUpdateRolePermissionsPermissionAccess(
                {
                  i: (row as CreatePermissionAccess).index,
                  crud,
                  crudType: "NONE",
                }
              )
            );
          });
          dispatch(
            permissionsManagementActions.changeUpdateRolePermissionEnabledAccess(
              {
                i: (row as CreatePermissionAccess).index,
                bool,
              }
            )
          );
        });
      }
    }
  };

  const onPermissionsChangeHandler = (
    i: number,
    crud: crud,
    crudType: CrudType
  ) => {
    dispatch(
      permissionsManagementActions.changeUpdateRolePermissionsPermissionAccess({
        i,
        crud,
        crudType,
      })
    );
  };

  const onPermissionsCudChangeHandler = (i: number, crudType: CrudType) => {
    const cuds: crud[] = ["create", "update", "delete"];
    cuds.map((cud) => {
      dispatch(
        permissionsManagementActions.changeUpdateRolePermissionsPermissionAccess(
          {
            i,
            crud: cud,
            crudType,
          }
        )
      );
    });
  };
  if (permission) {
    return (
      <Fragment>
        <tr key={permission.permissionName}>
          <td className="flex flex-row justify-start px-6 py-4 text-gray-900 whitespace-nowrap text-sm font-medium">
            <div className="flex flex-row gap-3 w-full">
              <span className="ml-5 w-3/4 truncate">{permission?.title}</span>
            </div>
          </td>
          <td className="px-6 py-4 text-gray-500 whitespace-nowrap text-sm">
            <div className="flex gap-7 justify-center mt-2">
              <input
                type="checkbox"
                className="text-users-create"
                name="permissions-checkbox"
                id={`checkbox-${permission.permissionName}-1`}
                checked={
                  rolePermissions.permissionsAccess[permission.index]?.enabled
                }
                onChange={() =>
                  onEnabledChangeHandler(
                    permission.index,
                    !rolePermissions.permissionsAccess[permission.index]
                      ?.enabled
                  )
                }
              />
            </div>
          </td>
          <td className="px-6 py-4 text-gray-500 whitespace-nowrap text-sm">
            <div className={`${buttonsParent ? "block" : "hidden"}`}>
              <div
                className={`read-radio-container flex gap-7 justify-center ${
                  rolePermissions.permissionsAccess[permission.index]?.enabled
                    ? "flex"
                    : "hidden"
                }`}
              >
                <input
                  type="radio"
                  name={`read-${permission.permissionName}`}
                  value="ANY"
                  id={`read-${permission.permissionName}-1`}
                  checked={isChecked(permission.index, "read", "ANY")}
                  disabled={
                    !rolePermissions.permissionsAccess[permission.index]
                      ?.enabled
                  }
                  onChange={() =>
                    onPermissionsChangeHandler(permission.index, "read", "ANY")
                  }
                />
                <input
                  type="radio"
                  name={`read-${permission.permissionName}`}
                  value="own"
                  id={`read-${permission.permissionName}-2`}
                  checked={isChecked(permission.index, "read", "OWN")}
                  disabled={
                    !rolePermissions.permissionsAccess[permission.index]
                      ?.enabled
                  }
                  onChange={() =>
                    onPermissionsChangeHandler(permission.index, "read", "OWN")
                  }
                />
              </div>
            </div>
          </td>
          <td className="px-6 py-4 text-gray-500 whitespace-nowrap text-sm">
            <div className={`${buttonsParent ? "block" : "hidden"}`}>
              <div
                className={`create-radio-container flex gap-7 justify-center ${
                  rolePermissions.permissionsAccess[permission.index]?.enabled
                    ? "flex"
                    : "hidden"
                }`}
              >
                <input
                  type="radio"
                  name={`create-${permission.permissionName}`}
                  value="any"
                  id={`create-${permission.permissionName}-1`}
                  checked={isChecked(permission.index, "create", "ANY")}
                  disabled={
                    !rolePermissions.permissionsAccess[permission.index]
                      ?.enabled
                  }
                  onChange={() =>
                    onPermissionsCudChangeHandler(permission.index, "ANY")
                  }
                />
                <input
                  type="radio"
                  name={`create-${permission.permissionName}`}
                  value="own"
                  id={`create-${permission.permissionName}-2`}
                  checked={isChecked(permission.index, "create", "OWN")}
                  disabled={
                    !rolePermissions.permissionsAccess[permission.index]
                      ?.enabled
                  }
                  onChange={() =>
                    onPermissionsCudChangeHandler(permission.index, "OWN")
                  }
                />
                <input
                  type="radio"
                  name={`create-${permission.permissionName}`}
                  value="none"
                  id={`create-${permission.permissionName}-3`}
                  checked={isChecked(permission.index, "create", "NONE")}
                  disabled={
                    !rolePermissions.permissionsAccess[permission.index]
                      ?.enabled
                  }
                  onChange={() =>
                    onPermissionsCudChangeHandler(permission.index, "NONE")
                  }
                />
              </div>
            </div>
          </td>
        </tr>
        {!!childrenRowsWithButtons &&
          childrenRowsWithButtons.map((row) => {
            if (row) {
              return (
                <UpdatePermissionChild
                  permission={row}
                  enabled={
                    rolePermissions.permissionsAccess[permission.index]?.enabled
                  }
                  key={row?.permissionName}
                />
              );
            }
          })}
        {!!childrenRowsSimple &&
          childrenRowsSimple.map((row) => {
            if (row) {
              return (
                <UpdatePermissionOnlyCheckBox
                  permission={row}
                  enabled={
                    rolePermissions.permissionsAccess[permission.index]?.enabled
                  }
                  key={row?.permissionName}
                  isItChild={true}
                />
              );
            }
          })}
      </Fragment>
    );
  } else {
    return null;
  }
};

export default UpdatePermissionParent;
