import { Role } from "shared/interfaces/Role.interface";
import { Permission } from "shared/interfaces/Permission.interface";
import {
  CreatePermissionAccess,
  CreateRolePermission,
  RolePermission,
} from "shared/interfaces/RolePermission.interface";

export interface permissionsManagementState {
  roles: Role[];
  permissions: Permission[];
  rolePermissions: RolePermission[];
  updateRolePermissions: {
    roleName: string;
    description: string;
    permissionsAccess: CreatePermissionAccess[];
  };
  createRolePermissions: CreateRolePermission;
  rolesPermissions: RolePermission[];
  loading: boolean;
}

export const permissionsManagementInitialState: permissionsManagementState = {
  roles: [],
  permissions: [],
  rolePermissions: [],
  updateRolePermissions: {
    roleName: "",
    description: "",
    permissionsAccess: [],
  },
  createRolePermissions: {
    roleName: "",
    description: "",
    permissionsAccess: [],
  },
  rolesPermissions: [],
  loading: false,
};
