import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createExam,
  getExams,
  updateExam,
  deleteExam,
  getExam,
} from "api/Exams/exams.api";
import { CreateExam } from "shared/interfaces/Exam.interface";
import { useAppSelector } from "store/storeHooks";
import { examsInitialState } from "./examsState";
import { FileAWS } from "shared/interfaces/File.interface";
import { deleteFile } from "api/Files/files.api";

export const createExamThunk = createAsyncThunk(
  "examsStore/createExam",
  async (exam: CreateExam) => {
    await createExam(exam);
  }
);

export const getExamsThunk = createAsyncThunk(
  "examsStore/getExams",
  async ({
    from,
    to,
    tags,
    region,
    visible,
    pageSize,
    pageIndex,
    sortCol,
    sortOrder,
    searchString,
  }: {
    from: string;
    to: string;
    tags: string;
    region: string;
    visible: string;
    pageSize: number;
    pageIndex: number;
    sortCol: string;
    sortOrder: string;
    searchString: string;
  }) => {
    const response = await getExams({
      from,
      to,
      tags,
      region,
      visible,
      pageSize,
      pageIndex,
      sortCol,
      sortOrder,
      searchString,
    });
    return response.data;
  }
);

export const updateExamThunk = createAsyncThunk(
  "examsStore/updateExam",
  async ({
    examId,
    exam,
    filesToDelete,
  }: {
    examId: string;
    exam: CreateExam;
    filesToDelete?: FileAWS[];
  }) => {
    if (filesToDelete) {
      await Promise.all(
        filesToDelete.map((file) =>
          deleteFile({
            fileId: file.file_id,
            location: file.url,
          })
        )
      );
    }
    await updateExam(examId, exam);
  }
);

export const deleteExamThunk = createAsyncThunk(
  "examsStore/deleteExam",
  async ({ examId }: { examId: string }) => {
    await deleteExam(examId);
  }
);

export const getExamThunk = createAsyncThunk(
  "examsStore/getExam",
  async (examId: string) => {
    const response = await getExam(examId);
    return response.data.data;
  }
);

const examsSlice = createSlice({
  name: "examsStore",
  initialState: examsInitialState,
  reducers: {
    removeFileFromExam(
      state,
      { payload: { fileId } }: { payload: { fileId: string } }
    ) {
      if (state.data.event) {
        const index = state.data.event.files.findIndex(
          (file) => file.file_id === fileId
        );
        state.data.event.files.splice(index, 1);
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createExamThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createExamThunk.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createExamThunk.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getExamsThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getExamsThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(getExamsThunk.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(updateExamThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateExamThunk.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(updateExamThunk.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(deleteExamThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteExamThunk.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteExamThunk.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getExamThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getExamThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.data.event = action.payload;
    });
    builder.addCase(getExamThunk.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const selectExams = () => useAppSelector((state) => state.exams);

export default examsSlice;
