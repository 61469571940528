import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Plan } from "api/Plans/plans.api";
import { CMS_BE_URL } from "api/urls";
import axios from "axios";
import { useAppSelector } from "store/storeHooks";
import { plansInitialState } from "./plansState";

export const getPlansThunk = createAsyncThunk(
  "plansStore/getPlans",
  async () => {
    const res = await axios.get(`${CMS_BE_URL}/subscriptionPlans`);
    const teacherPlans = res.data?.data?.filter(
      (plan: Plan) => plan.type === "teacher"
    );
    const patreonPlans = res.data?.data?.filter(
      (plan: Plan) => plan.type === "patreon"
    );
    return { teacherPlans, patreonPlans };
  }
);

const plansSlice = createSlice({
  name: "plansStore",
  initialState: plansInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPlansThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPlansThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.plans.teacherPlans = action.payload.teacherPlans;
      state.plans.patreonPlans = action.payload.patreonPlans;
    });
    builder.addCase(getPlansThunk.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const selectPlans = () => useAppSelector((state) => state.plans);

export default plansSlice;
