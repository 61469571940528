import React from "react";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

interface Props {
  children: JSX.Element;
  permissions: string;
  destination: string;
}

const PermissionsCheck = ({
  children,
  permissions,
  destination,
}: Props): JSX.Element => {
  const { t } = useTranslation("common");

  const RestrictedAccess = () => (
    <div className="flex flex-col gap-1">
      <p className="text-xs font-semibold">{t("Restrictions.error")}</p>
      <p className="text-xs">{t("Restrictions.restriction")}</p>
    </div>
  );

  const errorMsg = () => {
    toast.error(RestrictedAccess, {
      toastId: "customId",
    });
  };

  if (permissions !== "NONE") {
    return children;
  } else {
    errorMsg();
    return <Navigate to={destination} replace={true} />;
  }
};

export default PermissionsCheck;
