import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createNew,
  getNews,
  updateNew,
  deleteNew,
  getNew,
} from "api/News/news.api";
import { CreateNew } from "shared/interfaces/New.interface";
import { useAppSelector } from "store/storeHooks";
import { newsInitialState } from "./newsState";

export const createNewThunk = createAsyncThunk(
  "newsStore/createNew",
  async (post: CreateNew) => {
    await createNew(post);
  }
);

export const getNewsThunk = createAsyncThunk(
  "newsStore/getNews",
  async (filters: {
    user?: string;
    featured?: string;
    tags?: string;
    visible?: string;
    region?: string;
    pageSize?: number;
    pageIndex?: number;
    sortCol?: string;
    sortOrder?: string;
    searchString?: string;
  }) => {
    const response = await getNews(filters);
    return response.data;
  }
);

export const updateNewThunk = createAsyncThunk(
  "newsStore/updateNew",
  async ({ postId, post }: { postId: string; post: CreateNew }) => {
    await updateNew(postId, post);
  }
);

export const deleteNewThunk = createAsyncThunk(
  "newsStore/deleteNew",
  async ({ postId }: { postId: string }) => {
    await deleteNew(postId);
  }
);

export const getNewThunk = createAsyncThunk(
  "newsStore/getNew",
  async (postId: string) => {
    const response = await getNew(postId);
    return response.data.data;
  }
);

const newsSlice = createSlice({
  name: "newsStore",
  initialState: newsInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createNewThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createNewThunk.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createNewThunk.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getNewsThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getNewsThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(getNewsThunk.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(updateNewThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateNewThunk.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(updateNewThunk.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(deleteNewThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteNewThunk.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteNewThunk.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getNewThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getNewThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.data.post = action.payload;
    });
    builder.addCase(getNewThunk.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const selectNews = () => useAppSelector((state) => state.news);

export default newsSlice;
