import React, { useState, useEffect, useMemo } from "react";
import useUser from "shared/hooks/useUser";
import Spinner from "shared/components/UI/Spinner";
import Overlay from "shared/components/UI/Overlay";
import { useForm, Controller, FormProvider } from "react-hook-form";
import axios from "axios";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { getUserURL } from "../../api/Auth/urls";
import { selectLookups } from "store/lookups/lookupsReducer";
import SubscriptionTab from "shared/components/Users/SubscriptionTab";
import GoBack from "shared/components/UI/GoBack";
import DatePicker from "react-datepicker";
import InformationTab from "shared/components/Users/InformationTab";
import CertificatesTab from "shared/components/Users/CertificatesTab";
import { CMS_BE_URL } from "api/urls";
import DisplayInputError from "shared/components/UI/DisplayInputError";
import {
  addStyles,
  checkFiscal,
  getInputStyles,
  getReactSelectStyles,
  validateEmail,
} from "utils/utils";
import Select from "react-select";
import { getGenderOptions } from "shared/interfaces/Gender.interface";
import LocationSearch from "shared/components/UI/LocationSearch";
import Message from "shared/components/UI/Message";
import { constructCreateUser } from "utils/utils";
import Modal from "shared/components/UI/Modal";
import { selectLoggedInUser } from "store/authStore/authReducer";
import { toast } from "react-toastify";
import SimpleImageDropzone from "shared/components/UI/SimpleImageDropzone";
import { selectTranslationLanguage } from "store/translationStore/translationReducer";
import { PermissionDomain } from "shared/interfaces/Permission.interface";
import { RolePermissionCrudAction } from "shared/interfaces/RolePermission.interface";
import {
  regionRestrictionsSelect,
  User,
} from "shared/interfaces/User.interface";
import Orders from "shared/components/Users/Orders";
import { useSearchParams } from "react-router-dom";
import CropImage from "shared/components/UI/CropImage";
import Logs from "./Logs";
import { useNavigate } from "react-router-dom";
import Receipts from "shared/components/Users/Receipts";
import RegionRestrictions from "./RegionRestrictions";

const EditUser: () => JSX.Element = () => {
  const lang = selectTranslationLanguage();
  const { t } = useTranslation("common");
  const loggedInUser = selectLoggedInUser();
  const navigate = useNavigate();

  function canAccessTab(domain: string) {
    if (!loggedInUser) return false;
    if (loggedInUser.permissions["*"]) return true;
    const isEnalbed = loggedInUser.permissions[domain]["ENABLED"];
    if (isEnalbed) {
      const perm = loggedInUser.permissions[domain]["READ"];
      if (perm === "OWN" || perm === "ANY") return true;
    }
    return false;
  }

  type tabkeys =
    | "profileDetails"
    | "information"
    | "addressDetails"
    | "permissions"
    | "subscriptions"
    | "certificates"
    | "logs"
    | "orders"
    | "receipts";
  const tabItems: Record<tabkeys, { name: string; domain: string }> = useMemo(
    () => ({
      profileDetails: {
        name: t("user.profileDetails"),
        domain: "users",
      },
      information: {
        name: t("user.information"),
        domain: "users",
      },
      addressDetails: {
        name: t("user.addressDetails"),
        domain: "users",
      },
      permissions: {
        name: t("user.permissions"),
        domain: "permissions",
      },
      subscriptions: {
        name: t("user.subscriptions"),
        domain: "subscriptions",
      },
      certificates: {
        name: t("user.certificates"),
        domain: "users",
      },
      logs: {
        name: t("user.logs"),
        domain: "users",
      },
      orders: {
        name: t("user.orders"),
        domain: "orders",
      },
      receipts: {
        name: t("user.receipts"),
        domain: "receipts",
      },
    }),
    [lang, t]
  );

  useEffect(() => {
    function createTabs() {
      Object.entries(tabItems).forEach(([key, value]) => {
        if (canAccessTab(value.domain)) return;
        delete tabItems[key as tabkeys];
      });
      return tabItems;
    }
    if (loggedInUser) {
      setTabs(createTabs() as any);
    }
  }, [t, lang, loggedInUser]);

  const [tabs, setTabs] = useState({});
  const { editUser } = useUser();
  const [subscription, setSubscription] = useState(null);
  const [filesToDelete, setFilesToDelete] = useState<any>([]);
  const [editImageOn, setEditImageOn] = useState(false);
  const [current, setCurrent] = useState<tabkeys>("profileDetails");
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState<any>(null);
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  useEffect(() => {
    if (searchParams.get("current") === "orders") {
      setCurrent("orders");
    } else if (searchParams.get("current") === "receipts") {
      setCurrent("receipts");
    }
  }, []);
  const lookups = selectLookups();
  const [rolesOptions, setRolesOptions] = useState<any>([]);
  const [regionsOptions, setRegionsOptions] = useState<any>([]);
  const [provincesOptions, setProvincesOptions] = useState<any>([]);
  useEffect(() => {
    const rolesOptions = lookups.lookupList.roles.map((role) => ({
      value: role.ID,
      label: role.ROLE_NAME,
    }));
    setRolesOptions(rolesOptions);
  }, [lookups.lookupList.roles]);

  useEffect(() => {
    const regionsMap = lookups.lookupList.regions.map((region) => ({
      value: region.PK_REGION,
      label: region.PK_REGION,
    }));
    setRegionsOptions(regionsMap);
  }, [lookups.lookupList.regions]);
  useEffect(() => {
    const provinceOptions = lookups.lookupList.provinces.map((province) => ({
      value: province.PK_PROVINCE,
      label: province.NAME,
    }));
    setProvincesOptions(provinceOptions);
  }, [lookups.lookupList.provinces]);

  async function fetchUser(username = id) {
    return axios
      .get(getUserURL() + username)
      .catch((err: any) => {
        if (err.response?.data?.errors?.[0]?.message) {
          toast.error(err.response?.data?.errors?.[0]?.message);
        } else {
          toast.error(err.response?.data?.message);
        }
      })
      .then((res: any) => {
        setUser(res.data);
        return res;
      })
      .then((res: any) =>
        axios.get(
          `${process.env.REACT_APP_CMS_BE_URL}/users/${res.data.PK_USER}/subscription?active=true`
        )
      )
      .then(({ data }) => {
        if (data) {
          setSubscription(data);
        } else {
          toast.error(
            Message({
              action: "read",
              entity: t("user.subscription"),
              error: t("genericError"),
              lang,
              gender: "male",
            })
          );
        }
      });
  }

  //GET USER
  useEffect(() => {
    setLoading(true);

    fetchUser().then(() => setLoading(false));
  }, []);

  const form = useForm<any>({
    mode: "onChange",
    defaultValues: {
      fileType: "profile_pic",
      username: "",
      email: undefined,
      firstName: "",
      promotionBody: "",
      title: "",
      role: undefined,
      lastName: "",
      rank: undefined,
      gallery: undefined,
      newsletter: false,
      bounced: false,
      note: "",
      visibleSite: "0",
      visiblePicture: "0",
      isVisibleTeacher: "0",
      fiscaleCode: "",
      birthPlace: "",
      birthDate: null,
      password: "",
      posta: "0",
      votePrivilege: "0",
      gender: undefined,
      memberStatus: undefined,
      contactInfo: [
        { type: "PHONE", detail: "" },
        { type: "HOME", detail: "" },
      ],
      addressInfo: [
        {
          roadName: "",
          homeNumber: "",
          type: "HOME",
          city: "",
          postalCode: "",
          region: undefined,
          province: undefined,
          country: "",
          lon: "",
          lat: "",
        },
        {
          roadName: "",
          homeNumber: "",
          type: "OFFICE",
          city: "",
          postalCode: "",
          region: undefined,
          province: undefined,
          country: "",
          lon: "",
          lat: "",
        },
        {
          roadName: "",
          homeNumber: "",
          type: "ALTERNATIVE",
          city: "",
          postalCode: "",
          region: undefined,
          province: undefined,
          country: "",
          lon: "",
          lat: "",
        },
      ],
      profilePic: null,
      regionRestrictions: [],
    },
  });
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
    getValues,
    setError,
    setValue,
    trigger,
    clearErrors,
  } = form;

  useEffect(() => {
    if (user) {
      setValue("firstName", user?.NAME);
      setValue("username", user?.PK_USER);
      setValue("lastName", user?.SURNAME);
      if (user.EMAIL) setValue("email", user.EMAIL);
      setValue("birthDate", new Date(user?.BIRTH_DATE));
      if (user?.RANK)
        setValue("rank", {
          value: user?.rank.ID,
          label: lang === "en" ? user?.rank.RANK_ENG : user?.rank.RANK_IT,
        });
      if (user?.DESCRIPTION) setValue("description", user?.DESCRIPTION);
      if (user?.SCHOOL) setValue("school", user?.SCHOOL);
      if (user?.NOTE) setValue("note", user?.NOTE);
      if (user?.TITLES) setValue("titles", user?.TITLES);
      if (user?.PROMOTION_BOARD)
        setValue("promotionBody", user?.PROMOTION_BOARD);
      if (user?.TITLE) setValue("title", user?.TITLE);
      if (user?.FISCALE_CODE) setValue("fiscaleCode", user?.FISCALE_CODE);
      if (user?.BIRTH_PLACE) setValue("birthPlace", user?.BIRTH_PLACE);
      if (user?.VOTE_PRIVILEGE)
        setValue("votePrivilege", user?.VOTE_PRIVILEGE == true ? "1" : "0");
      if (user?.POSTA) setValue("posta", user?.POSTA == true ? "1" : "0");
      if (user?.VISIBLE_SITE)
        setValue("visibleSite", user?.VISIBLE_SITE === true ? "1" : "0");
      if (user?.VISIBLE_PICTURE)
        setValue("visiblePicture", user?.VISIBLE_PICTURE === true ? "1" : "0");
      if (user?.IS_VISIBLE_TEACHER)
        setValue(
          "isVisibleTeacher",
          user?.IS_VISIBLE_TEACHER === true ? "1" : "0"
        );
      if (user?.GENDER)
        setValue("gender", {
          value: user?.GENDER.toLowerCase(),
          label: t(`user.${user?.GENDER.toLowerCase()}`),
        });
      if (user?.CONTACT_DETAILS?.[0]?.CONTACT_DETAILS)
        setValue(
          `contactInfo.${0}.detail`,
          user?.CONTACT_DETAILS?.[0]?.CONTACT_DETAILS
        );
      if (user?.CONTACT_DETAILS?.[1]?.CONTACT_DETAILS)
        setValue(
          `contactInfo.${1}.detail`,
          user?.CONTACT_DETAILS?.[1]?.CONTACT_DETAILS
        );
      setValue(
        `addressInfo.${0}.roadName`,
        user?.ADDRESS_DETAILS?.[0]?.ADDRESS_NAME
      );
      setValue(
        `addressInfo.${0}.homeNumber`,
        user?.ADDRESS_DETAILS?.[0]?.ADDRESS_NUMBER
      );
      setValue(
        `addressInfo.${0}.postalCode`,
        user?.ADDRESS_DETAILS?.[0]?.POSTAL_CODE
      );
      if (user?.ADDRESS_DETAILS?.[0]?.PROVINCE)
        setValue(`addressInfo.${0}.province`, {
          value: user?.ADDRESS_DETAILS?.[0]?.PROVINCE,
          label: user?.ADDRESS_DETAILS?.[0]?.PROVINCE,
        });
      setValue(`addressInfo.${0}.region`, {
        value: user?.ADDRESS_DETAILS?.[0]?.FK_REGION,
        label: user?.ADDRESS_DETAILS?.[0]?.FK_REGION,
      });
      setValue(`addressInfo.${0}.country`, user?.ADDRESS_DETAILS?.[0]?.COUNTRY);
      setValue(`addressInfo.${0}.city`, user?.ADDRESS_DETAILS?.[0]?.CITY);
      setValue(
        `addressInfo.${0}.province`,
        user?.ADDRESS_DETAILS?.[0]?.PROVINCE
      );
      if (user?.ADDRESS_DETAILS?.[0]?.LONGITUDE)
        setValue(`addressInfo.${0}.lon`, user?.ADDRESS_DETAILS?.[0]?.LONGITUDE);
      if (user?.ADDRESS_DETAILS?.[0]?.LATITUDE)
        setValue(`addressInfo.${0}.lat`, user?.ADDRESS_DETAILS?.[0]?.LATITUDE);
      if (user?.ADDRESS_DETAILS?.[1]?.ADDRESS_NAME)
        setValue(
          `addressInfo.${1}.roadName`,
          user?.ADDRESS_DETAILS?.[1]?.ADDRESS_NAME
        );
      if (user?.ADDRESS_DETAILS?.[1]?.ADDRESS_NUMBER)
        setValue(
          `addressInfo.${1}.homeNumber`,
          user?.ADDRESS_DETAILS?.[1]?.ADDRESS_NUMBER
        );
      if (user?.ADDRESS_DETAILS?.[1]?.POSTAL_CODE)
        setValue(
          `addressInfo.${1}.postalCode`,
          user?.ADDRESS_DETAILS?.[1]?.POSTAL_CODE
        );
      if (user?.ADDRESS_DETAILS?.[1]?.PROVINCE)
        setValue(`addressInfo.${1}.province`, {
          value: user?.ADDRESS_DETAILS?.[1]?.PROVINCE,
          label: user?.ADDRESS_DETAILS?.[1]?.PROVINCE,
        });
      if (user?.ADDRESS_DETAILS?.[1]?.FK_REGION)
        setValue(`addressInfo.${1}.region`, {
          value: user?.ADDRESS_DETAILS?.[1]?.FK_REGION,
          label: user?.ADDRESS_DETAILS?.[1]?.FK_REGION,
        });
      if (user?.ADDRESS_DETAILS?.[1]?.COUNTRY)
        setValue(
          `addressInfo.${1}.country`,
          user?.ADDRESS_DETAILS?.[1]?.COUNTRY
        );
      if (user?.ADDRESS_DETAILS?.[1]?.CITY)
        setValue(`addressInfo.${1}.city`, user?.ADDRESS_DETAILS?.[1]?.CITY);
      if (user?.ADDRESS_DETAILS?.[1]?.LONGITUDE)
        setValue(`addressInfo.${1}.lon`, user?.ADDRESS_DETAILS?.[1]?.LONGITUDE);
      if (user?.ADDRESS_DETAILS?.[1]?.LATITUDE)
        setValue(`addressInfo.${1}.lat`, user?.ADDRESS_DETAILS?.[1]?.LATITUDE);
      if (user?.ADDRESS_DETAILS?.[2]?.ADDRESS_NAME)
        setValue(
          `addressInfo.${2}.roadName`,
          user?.ADDRESS_DETAILS?.[2]?.ADDRESS_NAME
        );
      if (user?.ADDRESS_DETAILS?.[2]?.ADDRESS_NUMBER)
        setValue(
          `addressInfo.${2}.homeNumber`,
          user?.ADDRESS_DETAILS?.[2]?.ADDRESS_NUMBER
        );
      if (user?.ADDRESS_DETAILS?.[2]?.POSTAL_CODE)
        setValue(
          `addressInfo.${2}.postalCode`,
          user?.ADDRESS_DETAILS?.[2]?.POSTAL_CODE
        );
      if (user?.ADDRESS_DETAILS?.[2]?.PROVINCE)
        setValue(`addressInfo.${2}.province`, {
          value: user?.ADDRESS_DETAILS?.[2]?.PROVINCE,
          label: user?.ADDRESS_DETAILS?.[2]?.PROVINCE,
        });
      if (user?.ADDRESS_DETAILS?.[2]?.FK_REGION)
        setValue(`addressInfo.${2}.region`, {
          value: user?.ADDRESS_DETAILS?.[2]?.FK_REGION,
          label: user?.ADDRESS_DETAILS?.[2]?.FK_REGION,
        });
      if (user?.ADDRESS_DETAILS?.[2]?.COUNTRY)
        setValue(
          `addressInfo.${2}.country`,
          user?.ADDRESS_DETAILS?.[2]?.COUNTRY
        );
      if (user?.ADDRESS_DETAILS?.[2]?.CITY)
        setValue(`addressInfo.${2}.city`, user?.ADDRESS_DETAILS?.[2]?.CITY);
      if (user?.ADDRESS_DETAILS?.[2]?.LONGITUDE)
        setValue(`addressInfo.${2}.lon`, user?.ADDRESS_DETAILS?.[2]?.LONGITUDE);
      if (user?.ADDRESS_DETAILS?.[2]?.LATITUDE)
        setValue(`addressInfo.${2}.lat`, user?.ADDRESS_DETAILS?.[2]?.LATITUDE);
      setValue("role", { value: user?.role?.ID, label: user?.role?.ROLE_NAME });
      if (user?.PROFILE_PIC_URL) setValue("profilePic", user.PROFILE_PIC_URL);
      if (user?.MEMBER_STATUS)
        setValue("memberStatus", {
          value: user.MEMBER_STATUS,
          label: user.MEMBER_STATUS,
        });
      if (user?.NEWSLETTER) setValue("newsletter", user.NEWSLETTER);
      if (user?.BOUNCED) setValue("bounced", user?.BOUNCED);
      if (user?.REGION_FILTER)
        setValue(
          "regionRestrictions",
          JSON.parse(user?.REGION_FILTER).map((region: string) => ({
            value: region,
            label: region,
          }))
        );
    }
  }, [user]);

  const onSubmit = handleSubmit(async (data: any) => {
    setLoading(true);
    try {
      const res = await editUser(
        `${id}`,
        constructCreateUser(data),
        filesToDelete
      );
      if (res.status === 200) {
        setValue("bounced", res.data.user.BOUNCED);
        setValue("gallery", null);
        navigate(`/users/${res.data.user.PK_USER}`);
        toast.success(
          Message({
            action: "update",
            entity: t("user.user"),
            lang,
          })
        );
      }
    } catch (err: any) {
      if (err.response?.data?.errors?.[0]?.message) {
        toast.error(err.response?.data?.errors?.[0]?.message);
      } else {
        toast.error(err.response?.data?.message);
      }
    } finally {
      setLoading(false);
    }
  });

  //BAN-UNBAN MODALS

  const [openBan, setOpenBan] = useState<boolean>(false);
  const [openUnban, setOpenUnban] = useState<boolean>(false);

  const banHandler = async () => {
    const data = {
      username: user.PK_USER,
      lastModifier: loggedInUser?.user?.PK_USER,
    };

    try {
      await axios.put(`${CMS_BE_URL}/users/disableUser`, data);
      user.STATUS = "DISABLED";
    } catch (err: any) {
      if (err.response?.data?.errors?.[0]?.message) {
        toast.error(err.response?.data?.errors?.[0]?.message);
      } else {
        toast.error(err.response?.data?.message);
      }
    }

    setOpenBan(false);
  };

  const unbanHandler = async () => {
    const data = {
      username: user.PK_USER,
      lastModifier: loggedInUser?.user?.PK_USER,
    };

    try {
      await axios.put(`${CMS_BE_URL}/users/enableUser`, data);
      user.STATUS = "ACTIVE";
    } catch (err: any) {
      if (err.response?.data?.errors?.[0]?.message) {
        toast.error(err.response?.data?.errors?.[0]?.message);
      } else {
        toast.error(err.response?.data?.message);
      }
    }

    setOpenUnban(false);
  };

  function canAccess(
    domain: PermissionDomain,
    action: RolePermissionCrudAction,
    user: User
  ): boolean {
    if (loggedInUser.permissions["*"]) {
      return true;
    } else {
      switch (loggedInUser.permissions[domain][action]) {
        case "NONE": {
          return false;
        }
        case "OWN": {
          return user.PK_USER === loggedInUser.user?.PK_USER;
        }
        case "ANY": {
          return true;
        }
        default: {
          return false;
        }
      }
    }
  }

  return (
    <Overlay active={loading} spinner={<Spinner />}>
      <div className="flex flex-col gap-5 p-10 h-full overflow-y-auto">
        <GoBack uri="users" />
        <div className="flex flex-col gap-2">
          <h1 className="text-3xl font-bold">{t("user.users")}</h1>
          <p className="text-global-subHeader text-lg"></p>
        </div>
        <FormProvider {...form}>
          <form onSubmit={onSubmit} className="flex flex-col flex-grow gap-10">
            <div className="flex flex-col gap-5 items-end justify-between w-full xl:flex-row xl:gap-10 xl:items-start">
              <nav
                className="flex gap-5 w-full border-b border-gray-300"
                aria-label="Tabs"
              >
                {Object.entries(tabs).map(([key, value]: any, i) => (
                  <button
                    key={i}
                    type="button"
                    onClick={() => setCurrent(key)}
                    className={`${
                      key == current
                        ? "border-users-create text-users-create"
                        : "border-transparent text-gray-500 hover:border-gray-300"
                    } px-1 py-2 whitespace-nowrap text-lg font-medium border-b-2`}
                  >
                    {value.name}
                  </button>
                ))}
              </nav>
              <button
                type="submit"
                disabled={!canAccess("users", "UPDATE", user)}
                onClick={() => {
                  Object.entries(tabs).reduce((acc, [key]) => {
                    return acc.then((res) => {
                      if (!res) return Promise.resolve(false);
                      switch (key) {
                        case "profileDetails": {
                          return trigger([
                            "firstName",
                            "lastName",
                            "username",
                            "email",
                            "birthDate",
                            "fiscaleCode",
                            "profilePic",
                          ]).then((res) =>
                            res
                              ? Promise.resolve(true)
                              : (setCurrent("profileDetails"),
                                Promise.resolve(false))
                          );
                        }
                        case "addressDetails": {
                          return trigger([
                            "addressInfo.0.roadName",
                            "addressInfo.0.homeHumber",
                            "addressInfo.0.city",
                            "addressInfo.0.postalCode",
                            "addressInfo.0.region",
                            "addressInfo.0.province",
                            "addressInfo.0.country",
                          ]).then((res) => {
                            return res
                              ? Promise.resolve(true)
                              : (setCurrent("addressDetails"),
                                Promise.resolve(false));
                          });
                        }
                        case "permissions": {
                          return trigger(["role"]).then(() =>
                            res
                              ? Promise.resolve(true)
                              : (setCurrent("permissions"),
                                Promise.resolve(false))
                          );
                        }
                        default: {
                          return Promise.resolve(true);
                        }
                      }
                    });
                  }, Promise.resolve(true));
                }}
                className={`px-4 py-2 text-white bg-users-create w-min ${
                  canAccess("users", "UPDATE", user) ? "" : "cursor-not-allowed"
                }`}
              >
                {t("user.save")}
              </button>
            </div>

            <div className="flex flex-col flex-grow">
              <div
                className={current === "profileDetails" ? "block" : "hidden"}
              >
                <div className="grid gap-5 grid-cols-3">
                  <div className="flex flex-col gap-5 col-span-2">
                    <div className="flex gap-5 w-full">
                      <div className="flex flex-col gap-2 w-full">
                        <label
                          htmlFor="firstName"
                          className="block whitespace-nowrap text-lg font-medium"
                        >
                          {t("user.firstName")} *
                        </label>
                        <input
                          type="text"
                          className={getInputStyles(!!errors.firstName)}
                          defaultValue=""
                          placeholder="First Name"
                          {...register("firstName", {
                            required: `${t("user.firstName")} ${t(
                              "user.required"
                            )}.`,
                            maxLength: {
                              value: 50,
                              message: `${t("user.firstName")} ${t(
                                "user.shouldBe"
                              )} ${50} ${t("user.characters")}.`,
                            },
                          })}
                        />
                        <DisplayInputError
                          message={errors.firstName?.message}
                        />
                      </div>
                      <div className="flex flex-col gap-2 w-full">
                        <label
                          htmlFor="lastName"
                          className="block whitespace-nowrap text-lg font-medium"
                        >
                          {t("user.lastName")} *
                        </label>
                        <input
                          type="text"
                          className={getInputStyles(!!errors.lastName)}
                          defaultValue=""
                          placeholder="Last Name"
                          {...register("lastName", {
                            required: `${t("user.lastName")} ${t(
                              "user.required"
                            )}.`,
                            maxLength: {
                              value: 50,
                              message: `${t("user.lastName")} ${t(
                                "user.shouldBe"
                              )} ${50} ${t("user.characters")}.`,
                            },
                          })}
                        />
                        <DisplayInputError message={errors.lastName?.message} />
                      </div>
                    </div>

                    <div className="flex gap-5 w-full">
                      <div className="flex flex-col gap-2 w-full">
                        <label
                          htmlFor="username"
                          className="block whitespace-nowrap text-lg font-medium"
                        >
                          {t("user.username")} *
                        </label>
                        <input
                          type="text"
                          // disabled={true}
                          placeholder={t("user.username")}
                          className={addStyles(
                            getInputStyles(!!errors.username)
                          )}
                          {...register("username", {
                            required: `${t("user.username")} ${t(
                              "user.required"
                            )}.`,
                            maxLength: {
                              value: 50,
                              message: `${t("user.username")} ${t(
                                "user.shouldBe"
                              )} ${50} ${t("user.characters")}.`,
                            },
                          })}
                        />
                        <DisplayInputError message={errors.username?.message} />
                      </div>
                      <div className="flex flex-col gap-2 w-full">
                        <label
                          htmlFor="email"
                          className="block whitespace-nowrap text-lg font-medium"
                        >
                          {t("user.email")}
                        </label>
                        <input
                          type="email"
                          className={getInputStyles(!!errors.email)}
                          placeholder="Email"
                          {...register(`email`, {
                            pattern: {
                              value:
                                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                              message: t("UserCRUD.invalidEmail"),
                            },
                            maxLength: {
                              value: 50,
                              message: `${t("user.email")} ${t(
                                "user.shouldBe"
                              )} ${50} ${t("user.characters")}.`,
                            },
                          })}
                        />
                        <DisplayInputError message={errors.email?.message} />
                      </div>
                    </div>
                    <div className="grid gap-5 grid-cols-2 w-full">
                      <div className="flex flex-col gap-2">
                        <label
                          htmlFor="promotionBody"
                          className="block w-full text-lg font-medium"
                        >
                          {t("user.promotionBoard")}
                        </label>

                        <input
                          type="text"
                          placeholder={t("user.promotionBoard")}
                          className={getInputStyles(false)}
                          defaultValue=""
                          {...register("promotionBody", {
                            maxLength: {
                              value: 50,
                              message: `${t("user.promotionBoard")} ${t(
                                "user.shouldBe"
                              )} ${50} ${t("user.characters")}.`,
                            },
                          })}
                        />
                        <DisplayInputError
                          message={errors.promotionBody?.message}
                        />
                      </div>
                      <div className="flex flex-col gap-2 w-full">
                        <label
                          htmlFor="title"
                          className="block w-full text-lg font-medium"
                        >
                          {t("user.title")}
                        </label>
                        <input
                          type="text"
                          placeholder={t("user.title")}
                          className={getInputStyles(false)}
                          defaultValue=""
                          {...register("title", {
                            maxLength: {
                              value: 50,
                              message: `${t("user.title")} ${t(
                                "user.shouldBe"
                              )} ${50} ${t("user.characters")}.`,
                            },
                          })}
                        />
                        <DisplayInputError message={errors.title?.message} />
                      </div>
                    </div>

                    <div className="grid gap-5 grid-cols-2 w-full">
                      <div className="flex flex-col gap-2">
                        <label
                          htmlFor="birthPlace"
                          className="block w-full text-lg font-medium"
                        >
                          {t("user.birthPlace")}
                        </label>
                        <input
                          placeholder={t("user.birthPlace")}
                          className={getInputStyles(false)}
                          type="text"
                          defaultValue=""
                          {...register("birthPlace", {
                            maxLength: {
                              value: 50,
                              message: `${t("user.birthPlace")} ${t(
                                "user.shouldBe"
                              )} ${50} ${t("user.characters")}.`,
                            },
                          })}
                        />
                        <DisplayInputError
                          message={errors.birthPlace?.message}
                        />
                      </div>
                      <div className="flex flex-col gap-2">
                        <label
                          className="block w-full whitespace-nowrap text-lg font-medium"
                          htmlFor="birthDate"
                        >
                          {t("user.birthDate")} *
                        </label>
                        <Controller
                          name="birthDate"
                          control={control}
                          rules={{
                            required: true,
                          }}
                          render={({ field }) => {
                            return (
                              <div>
                                <DatePicker
                                  id="birthDate"
                                  selected={field.value}
                                  onChange={(e) => field.onChange(e)}
                                  dateFormat="dd/MM/yyyy"
                                  className={getInputStyles(!!errors.birthDate)}
                                  placeholderText={t("user.dateFormat")}
                                  showPopperArrow={false}
                                  autoComplete="off"
                                />
                              </div>
                            );
                          }}
                        />
                        <DisplayInputError
                          message={
                            errors.birthDate &&
                            `${t("user.birthDate")} ${t("user.required")}.`
                          }
                        />
                      </div>
                    </div>

                    <div className="grid gap-5 grid-cols-2 items-center justify-between">
                      <div className="grid gap-5 grid-cols-2">
                        <div className="flex flex-col gap-2">
                          <span className="flex text-lg font-medium">
                            {t("user.voting")}
                          </span>
                          <div className="flex gap-2 items-center">
                            <label
                              htmlFor="voting"
                              className="flex text-lg font-medium"
                            >
                              {t("user.yes")}
                            </label>
                            <input
                              type="radio"
                              className="border-gray-300 focus:border-users-create focus:ring-users-create"
                              value="1"
                              {...register("votePrivilege")}
                            />
                            <label
                              htmlFor="voting"
                              className="flex text-lg font-medium"
                            >
                              {t("user.no")}
                            </label>
                            <input
                              type="radio"
                              className="border-gray-300 focus:border-users-create focus:ring-users-create"
                              value="0"
                              {...register("votePrivilege")}
                            />
                          </div>
                        </div>
                        <div className="flex flex-col gap-2">
                          <span className="flex text-lg font-medium">
                            {t("user.mail")}
                          </span>
                          <div className="flex gap-2 items-center">
                            <label
                              htmlFor="posta"
                              className="flex text-lg font-medium"
                            >
                              {t("user.yes")}
                            </label>
                            <input
                              type="radio"
                              className="border-gray-300 focus:border-users-create focus:ring-users-create"
                              value="1"
                              {...register("posta")}
                            />
                            <label
                              htmlFor="posta"
                              className="flex text-lg font-medium"
                            >
                              {t("user.no")}
                            </label>
                            <input
                              type="radio"
                              className="border-gray-300 focus:border-users-create focus:ring-users-create"
                              value="0"
                              {...register("posta")}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col gap-2">
                        <label
                          htmlFor="fiscaleCode"
                          className="block text-lg font-medium"
                        >
                          {t("user.fiscal")}
                        </label>
                        <input
                          type="text"
                          className={getInputStyles(!!errors.fiscaleCode)}
                          placeholder={t("user.fiscal")}
                          {...register(`fiscaleCode`, {
                            validate: {
                              checkFiscal,
                            },
                          })}
                        />
                        <DisplayInputError
                          message={errors.fiscaleCode?.message}
                        />
                      </div>
                    </div>

                    <div className="flex gap-5 w-full">
                      <div className="flex flex-col gap-2 w-full">
                        <label
                          htmlFor="home-number"
                          className="block text-lg font-medium"
                        >
                          {t("user.homeNumber")}
                        </label>
                        <input
                          type="tel"
                          className={getInputStyles(false)}
                          placeholder={t("user.homeNumber")}
                          {...register(`contactInfo.${1}.detail`, {
                            // maxLength: {
                            //   value: 15,
                            //   message: `${t("user.post")} ${t(
                            //     "user.homeNumber"
                            //   )} ${t("user.shouldBe")} ${15} ${t(
                            //     "user.characters"
                            //   )}.`,
                            // },
                          })}
                        />
                      </div>
                      <div className="flex flex-col gap-2 w-full">
                        <label
                          htmlFor="phone-number"
                          className="block text-lg font-medium"
                        >
                          {t("user.phoneNumber")}
                        </label>
                        <input
                          type="tel"
                          className={getInputStyles(false)}
                          placeholder={t("user.phoneNumber")}
                          {...register(`contactInfo.${0}.detail`, {
                            maxLength: {
                              value: 15,
                              message: `${t("user.phoneNumber")} ${t(
                                "user.homeNumber"
                              )} ${t("user.shouldBe")} ${15} ${t(
                                "user.characters"
                              )}.`,
                            },
                          })}
                        />
                      </div>
                    </div>
                    <div className="grid gap-5 grid-cols-2 items-center w-full">
                      <div className="flex flex-col gap-2">
                        <label
                          htmlFor="gender"
                          className="block text-lg font-medium"
                        >
                          {t("user.gender")}
                        </label>

                        <Controller
                          name="gender"
                          control={control}
                          render={({ field }) => (
                            <Select
                              placeholder={t("user.gender")}
                              styles={getReactSelectStyles(false)}
                              inputId="gender"
                              options={getGenderOptions(lang)}
                              isClearable={true}
                              value={field.value}
                              onChange={(e) => field.onChange(e)}
                            />
                          )}
                        />
                      </div>
                      <div className="flex flex-col gap-2">
                        <label className="block text-lg font-medium">
                          {t("user.status")}
                        </label>
                        {user?.STATUS !== "DISABLED" ? (
                          <button
                            type="button"
                            onClick={() => setOpenBan(true)}
                            className="px-4 py-2 text-white font-semibold bg-red-700 rounded-sm"
                          >
                            {t("user.ban")} {t("user.user")}
                          </button>
                        ) : (
                          <button
                            type="button"
                            onClick={() => setOpenUnban(true)}
                            className="px-4 py-2 text-white font-semibold bg-users-create rounded-sm"
                          >
                            {t("user.unban")} {t("user.user")}
                          </button>
                        )}
                      </div>
                    </div>
                    <div className="">
                      {loggedInUser.permissions["*"] ? (
                        <div className="block mt-5">
                          <p className="mb-4 text-lg font-bold">Newsletter</p>
                          <div className="flex gap-2 items-center md:justify-start">
                            <input
                              {...register("newsletter")}
                              onChange={() =>
                                setValue("newsletter", !getValues("newsletter"))
                              }
                              type="checkbox"
                            />
                            {t("newsletter.sub")}
                          </div>
                        </div>
                      ) : (
                        loggedInUser?.permissions.newsletter.ENABLED && (
                          <div className="block mt-5">
                            <p className="mb-4 text-lg font-bold">Newsletter</p>
                            <div className="flex gap-2 items-center md:justify-start">
                              <input
                                {...register("newsletter")}
                                onChange={() =>
                                  setValue(
                                    "newsletter",
                                    !getValues("newsletter")
                                  )
                                }
                                type="checkbox"
                              />
                              {t("newsletter.sub")}
                            </div>
                          </div>
                        )
                      )}
                    </div>
                    <div className="">
                      {loggedInUser.permissions["*"] ? (
                        <div className="block">
                          <div className="flex gap-2 items-center md:justify-start">
                            <input
                              {...register("bounced")}
                              onChange={() =>
                                setValue("bounced", !getValues("bounced"))
                              }
                              type="checkbox"
                            />
                            {t("newsletter.bouncedMsg")}
                          </div>
                        </div>
                      ) : (
                        loggedInUser?.permissions.newsletter.ENABLED && (
                          <div className="block">
                            <div className="flex gap-2 items-center md:justify-start">
                              <input
                                {...register("bounced")}
                                onChange={() =>
                                  setValue("bounced", !getValues("bounced"))
                                }
                                type="checkbox"
                              />
                              {t("newsletter.bouncedMsg")}
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                  <div className="flex flex-col justify-between w-full h-full">
                    <div>
                      <label
                        htmlFor="profilePic"
                        className="block text-lg font-medium"
                      >
                        {t("user.coverPhoto")}
                      </label>
                      <SimpleImageDropzone
                        clearErrors={clearErrors}
                        trigger={trigger}
                        setValue={setValue}
                        watch={watch}
                        setError={setError}
                        reactHookFormName="profilePic"
                        setEditImageOn={setEditImageOn}
                        aspect={1}
                        required={false}
                        register={register}
                        canDelete={true}
                      />
                      {editImageOn ? (
                        <CropImage
                          editImageOn={editImageOn}
                          setEditImageOn={setEditImageOn}
                          reactHookFormName="profilePic"
                          aspect={1}
                          canForce={true}
                          canClose={true}
                        />
                      ) : null}
                      <DisplayInputError message={errors.profilePic?.message} />
                    </div>
                  </div>
                </div>
                <Modal
                  className=""
                  openStatus={openBan}
                  setOpen={setOpenBan}
                  header={
                    <p>
                      {t("user.ban")} {t("user.user")}
                    </p>
                  }
                  title={<p>{t("user.banMessage")}</p>}
                  footer={
                    <div className="mt-5 sm:flex sm:flex-row-reverse sm:mt-4">
                      <button
                        type="button"
                        className="inline-flex justify-center px-4 py-1 w-full text-white text-base font-medium bg-red-600 hover:bg-red-700 border border-transparent focus:outline-none shadow-sm focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={async () => await banHandler()}
                      >
                        {t("user.ban")}
                      </button>
                      <button
                        type="button"
                        className="inline-flex justify-center mt-3 px-4 py-1 w-full hover:text-gray-500 text-gray-700 text-base font-medium bg-white border border-gray-300 focus:outline-none shadow-sm focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                        onClick={() => setOpenBan(false)}
                      >
                        {t("user.cancel")}
                      </button>
                    </div>
                  }
                />
                <Modal
                  className=""
                  openStatus={openUnban}
                  setOpen={setOpenUnban}
                  header={
                    <p>
                      {t("user.unban")} {t("user.user")}
                    </p>
                  }
                  title={<p>{t("user.unbanMessage")}</p>}
                  footer={
                    <div className="mt-5 sm:flex sm:flex-row-reverse sm:mt-4">
                      <button
                        type="button"
                        className="inline-flex justify-center px-4 py-1 w-full text-white text-base font-medium bg-users-create border border-transparent focus:outline-none shadow-sm focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={async () => await unbanHandler()}
                      >
                        {t("user.unban")}
                      </button>
                      <button
                        type="button"
                        className="inline-flex justify-center mt-3 px-4 py-1 w-full hover:text-gray-500 text-gray-700 text-base font-medium bg-white border border-gray-300 focus:outline-none shadow-sm focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                        onClick={() => setOpenUnban(false)}
                      >
                        {t("user.cancel")}
                      </button>
                    </div>
                  }
                />
              </div>

              <div
                className={`${
                  current === "information" ? "" : "hidden"
                } flex flex-col flex-grow`}
              >
                <InformationTab
                  setFilesToDelete={setFilesToDelete}
                  user={user}
                  setUser={setUser}
                />
              </div>

              <div
                className={`flex flex-col gap-5 ${
                  current === "addressDetails" ? "" : " hidden"
                }`}
              >
                <div className="flex flex-col gap-5">
                  <div className="flex flex-col gap-5 w-full">
                    <h1 className="text-2xl font-bold">
                      {t("user.homeAddress")} *
                    </h1>
                    <LocationSearch
                      getValues={getValues}
                      register={register}
                      setValue={setValue}
                      clearErrors={clearErrors}
                      watch={watch}
                      errors={errors}
                      reactHookFormName="addressInfo.0"
                      isUserAddress={true}
                      displayMap={false}
                      displayDetails={false}
                      required={false}
                    />
                  </div>
                  <div className="grid gap-5 grid-cols-3">
                    <div className="flex flex-col gap-2">
                      <label className="text-lg font-medium">
                        {t("user.address")} *
                      </label>
                      <input
                        type="text"
                        placeholder={t("user.address")}
                        className={getInputStyles(
                          !!errors.addressInfo?.[0]?.roadName
                        )}
                        {...register(`addressInfo.${0}.roadName`, {
                          required: `${t("user.address")} ${t(
                            "user.required"
                          )}.`,
                          maxLength: {
                            value: 50,
                            message: `${t("user.address")} ${t(
                              "user.shouldBe"
                            )} ${50} ${t("user.characters")}.`,
                          },
                        })}
                      />
                      <DisplayInputError
                        message={errors.addressInfo?.[0]?.roadName?.message}
                      />
                    </div>
                    <div className="flex flex-col gap-2">
                      <label className="text-lg font-medium">
                        {t("user.streetNumber")} *
                      </label>
                      <input
                        type="text"
                        placeholder={t(`user.streetNumber`)}
                        className={getInputStyles(
                          !!errors.addressInfo?.[0]?.homeNumber
                        )}
                        {...register(`addressInfo.${0}.homeNumber`, {
                          required: `${t("user.streetNumber")} ${t(
                            "user.required"
                          )}.`,
                          maxLength: {
                            value: 50,
                            message: `${t("user.streetNumber")} ${t(
                              "user.shouldBe"
                            )} ${50} ${t("user.characters")}.`,
                          },
                        })}
                      />
                      <DisplayInputError
                        message={errors.addressInfo?.[0]?.homeNumber?.message}
                      />
                    </div>
                    <div className="flex flex-col gap-2">
                      <label className="text-lg font-medium">
                        {t("user.postalCode")} *
                      </label>
                      <input
                        type="text"
                        placeholder={t("user.postalCode")}
                        className={getInputStyles(
                          !!errors.addressInfo?.[0]?.postalCode
                        )}
                        {...register(`addressInfo.${0}.postalCode`, {
                          required: `${t("user.postalCode")} ${t(
                            "user.required"
                          )}.`,
                          maxLength: {
                            value: 50,
                            message: `${t("user.postalCode")} ${t(
                              "user.shouldBe"
                            )} ${50} ${t("user.characters")}.`,
                          },
                        })}
                      />
                      <DisplayInputError
                        message={errors.addressInfo?.[0]?.postalCode?.message}
                      />
                    </div>
                    <div className="flex flex-col gap-2">
                      <label className="text-lg font-medium">
                        {t("user.city")} *
                      </label>
                      <input
                        type="text"
                        placeholder={t("user.city")}
                        className={getInputStyles(
                          !!errors.addressInfo?.[0]?.city
                        )}
                        {...register(`addressInfo.${0}.city`, {
                          required: `${t("user.city")} ${t("user.required")}`,
                          maxLength: {
                            value: 50,
                            message: `${t("user.city")} ${t(
                              "user.shouldBe"
                            )} ${50} ${t("user.characters")}.`,
                          },
                        })}
                      />
                      <DisplayInputError
                        message={errors.addressInfo?.[0]?.city?.message}
                      />
                    </div>
                    <div className="flex flex-col gap-2">
                      <label className="text-lg font-medium">
                        {t("user.province")} *
                      </label>

                      <Controller
                        name={`addressInfo.${0}.province`}
                        control={control}
                        rules={{
                          required: true,
                        }}
                        render={({ field }) => (
                          <Select
                            placeholder={t("user.province")}
                            styles={getReactSelectStyles(
                              !!errors.addressInfo?.[0]?.province
                            )}
                            isLoading={lookups.loading}
                            inputId="province"
                            isMulti={false}
                            isClearable={true}
                            options={provincesOptions}
                            value={field.value}
                            onChange={(e) => field.onChange(e)}
                          />
                        )}
                      />
                      <DisplayInputError
                        message={
                          errors.addressInfo?.[0]?.province &&
                          `${t("user.province")} ${t("user.required")}.`
                        }
                      />
                    </div>
                    <div className="flex flex-col gap-2">
                      <label className="text-lg font-medium">
                        {t("user.region")} *
                      </label>
                      <Controller
                        name={`addressInfo.${0}.region`}
                        control={control}
                        rules={{
                          required: true,
                        }}
                        render={({ field }) => (
                          <Select
                            placeholder={`Region`}
                            styles={getReactSelectStyles(
                              !!errors.addressInfo?.[0]?.region
                            )}
                            isLoading={lookups.loading}
                            inputId="region"
                            isMulti={false}
                            isClearable={true}
                            options={regionsOptions}
                            value={field.value}
                            onChange={(e) => field.onChange(e)}
                          />
                        )}
                      />
                      <DisplayInputError
                        message={
                          errors.addressInfo?.[0]?.region &&
                          `${t("user.region")} ${t("user.required")}i.`
                        }
                      />
                    </div>
                    <div className="flex flex-col gap-2">
                      <label className="text-lg font-medium">
                        {t("user.country")} *
                      </label>
                      <input
                        type="text"
                        placeholder={t("user.country")}
                        className={getInputStyles(
                          !!errors.addressInfo?.[0]?.country
                        )}
                        {...register(`addressInfo.${0}.country`, {
                          required: `${t("user.country")} ${t(
                            "user.required"
                          )}.`,
                          maxLength: {
                            value: 50,
                            message: `${t("user.country")} ${t(
                              "user.shouldBe"
                            )} ${50} ${t("user.characters")}.`,
                          },
                        })}
                      />
                      <DisplayInputError
                        message={errors.addressInfo?.[0]?.country?.message}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-5">
                  <div className="flex flex-col gap-5">
                    <p className="text-2xl font-bold">
                      {t("user.officeAddress")}
                    </p>
                    <LocationSearch
                      getValues={getValues}
                      register={register}
                      setValue={setValue}
                      clearErrors={clearErrors}
                      watch={watch}
                      errors={errors}
                      reactHookFormName="addressInfo.1"
                      isUserAddress={true}
                      displayMap={false}
                      displayDetails={false}
                      required={false}
                    />
                  </div>
                  <div className="grid gap-5 grid-cols-3">
                    <div className="flex flex-col gap-2">
                      <label className="text-lg font-medium">
                        {t("user.address")}
                      </label>
                      <input
                        type="text"
                        placeholder={t("user.address")}
                        className={getInputStyles(false)}
                        {...register(`addressInfo.${1}.roadName`, {
                          maxLength: {
                            value: 50,
                            message: `${t("user.address")} ${t(
                              "user.shouldBe"
                            )} ${50} ${t("user.characters")}.`,
                          },
                        })}
                      />
                    </div>
                    <div className="flex flex-col gap-2">
                      <label className="text-lg font-medium">
                        {t("user.streetNumber")}
                      </label>
                      <input
                        type="text"
                        placeholder={t("user.streetNumber")}
                        className={getInputStyles(false)}
                        {...register(`addressInfo.${1}.homeNumber`, {
                          maxLength: {
                            value: 50,
                            message: `${t("user.streetNumber")} ${t(
                              "user.shouldBe"
                            )} ${50} ${t("user.characters")}.`,
                          },
                        })}
                      />
                    </div>
                    <div className="flex flex-col gap-2">
                      <label className="text-lg font-medium">
                        {t("user.postalCode")}
                      </label>
                      <input
                        type="text"
                        placeholder={t("user.postalCode")}
                        className={getInputStyles(false)}
                        {...register(`addressInfo.${1}.postalCode`, {
                          maxLength: {
                            value: 50,
                            message: `${t("user.postalCode")} ${t(
                              "user.shouldBe"
                            )} ${50} ${t("user.characters")}.`,
                          },
                        })}
                      />
                    </div>
                    <div className="flex flex-col gap-2">
                      <label className="text-lg font-medium">
                        {t("user.city")}
                      </label>
                      <input
                        type="text"
                        placeholder={t("user.city")}
                        className={getInputStyles(false)}
                        {...register(`addressInfo.${1}.city`, {
                          maxLength: {
                            value: 50,
                            message: `${t("user.city")} ${t(
                              "user.shouldBe"
                            )} ${50} ${t("user.characters")}.`,
                          },
                        })}
                      />
                    </div>
                    <div className="flex flex-col gap-2">
                      <label className="text-lg font-medium">
                        {t("user.province")}
                      </label>
                      {/* <input
                        type="text"
                        placeholder={t("user.province")}
                        className={getInputStyles(false)}
                        {...register(`addressInfo.${1}.province`, {
                          maxLength: {
                            value: 50,
                            message: `${t("user.province")} ${t(
                              "user.shouldBe"
                            )} ${50} ${t("user.characters")}.`,
                          },
                        })}
                      /> */}
                      <Controller
                        name={`addressInfo.${1}.province`}
                        control={control}
                        render={({ field }) => (
                          <Select
                            placeholder={t("user.province")}
                            styles={getReactSelectStyles(false)}
                            isLoading={lookups.loading}
                            inputId="province"
                            isMulti={false}
                            isClearable={true}
                            options={provincesOptions}
                            value={field.value}
                            onChange={(e) => field.onChange(e)}
                          />
                        )}
                      />
                    </div>
                    <div className="flex flex-col gap-2">
                      <label className="text-lg font-medium">
                        {t("user.region")}
                      </label>
                      <Controller
                        name={`addressInfo.${1}.region`}
                        control={control}
                        render={({ field }) => (
                          <Select
                            placeholder={t("user.region")}
                            styles={getReactSelectStyles(false)}
                            isLoading={lookups.loading}
                            inputId="region"
                            isMulti={false}
                            isClearable={true}
                            options={regionsOptions}
                            value={field.value}
                            onChange={(e) => field.onChange(e)}
                          />
                        )}
                      />
                    </div>
                    <div className="flex flex-col gap-2">
                      <label className="text-lg font-medium">
                        {t("user.country")}
                      </label>
                      <input
                        type="text"
                        placeholder={t("user.country")}
                        className={getInputStyles(false)}
                        {...register(`addressInfo.${1}.country`)}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-5">
                  <div className="flex flex-col gap-5">
                    <h1 className="text-2xl font-bold">
                      {t("user.altAddress")}
                    </h1>
                    <LocationSearch
                      getValues={getValues}
                      register={register}
                      setValue={setValue}
                      clearErrors={clearErrors}
                      watch={watch}
                      errors={errors}
                      reactHookFormName="addressInfo.2"
                      isUserAddress={true}
                      displayMap={false}
                      displayDetails={false}
                      required={false}
                    />
                    <div className="grid gap-5 grid-cols-3">
                      <div className="flex flex-col gap-2">
                        <label className="text-lg font-medium">
                          {t("user.address")}
                        </label>
                        <input
                          type="text"
                          placeholder={t("user.address")}
                          className={getInputStyles(false)}
                          {...register(`addressInfo.${2}.roadName`, {
                            maxLength: {
                              value: 50,
                              message: `${t("user.address")} ${t(
                                "user.shouldBe"
                              )} ${50} ${t("user.characters")}.`,
                            },
                          })}
                        />
                      </div>
                      <div className="flex flex-col gap-2">
                        <label className="text-lg font-medium">
                          {t("user.streetNumber")}
                        </label>
                        <input
                          type="text"
                          placeholder={t("user.streetNumber")}
                          className={getInputStyles(false)}
                          {...register(`addressInfo.${2}.homeNumber`, {
                            maxLength: {
                              value: 50,
                              message: `${t("user.streetNumber")} ${t(
                                "user.shouldBe"
                              )} ${50} ${t("user.characters")}.`,
                            },
                          })}
                        />
                      </div>
                      <div className="flex flex-col gap-2">
                        <label className="text-lg font-medium">
                          {t("user.postalCode")}
                        </label>
                        <input
                          type="text"
                          placeholder={t("user.postalCode")}
                          className={getInputStyles(false)}
                          {...register(`addressInfo.${2}.postalCode`, {
                            maxLength: {
                              value: 50,
                              message: `${t("user.postalCode")} ${t(
                                "user.shouldBe"
                              )} ${50} ${t("user.characters")}.`,
                            },
                          })}
                        />
                      </div>
                      <div className="flex flex-col gap-2">
                        <label className="text-lg font-medium">
                          {t("user.city")}
                        </label>
                        <input
                          type="text"
                          placeholder={t("user.city")}
                          className={getInputStyles(false)}
                          {...register(`addressInfo.${2}.city`, {
                            maxLength: {
                              value: 50,
                              message: `${t("user.city")} ${t(
                                "user.shouldBe"
                              )} ${50} ${t("user.characters")}.`,
                            },
                          })}
                        />
                      </div>
                      <div className="flex flex-col gap-2">
                        <label className="text-lg font-medium">
                          {t("user.province")}
                        </label>
                        {/* <input
                          type="text"
                          placeholder={t("user.province")}
                          className={getInputStyles(false)}
                          {...register(`addressInfo.${2}.province`, {
                            maxLength: {
                              value: 50,
                              message: `${t("user.province")} ${t(
                                "user.shouldBe"
                              )} ${50} ${t("user.characters")}.`,
                            },
                          })}
                        /> */}
                        <Controller
                          name={`addressInfo.${2}.province`}
                          control={control}
                          render={({ field }) => (
                            <Select
                              placeholder={t("user.province")}
                              styles={getReactSelectStyles(false)}
                              isLoading={lookups.loading}
                              inputId="province"
                              isMulti={false}
                              isClearable={true}
                              options={provincesOptions}
                              value={field.value}
                              onChange={(e) => field.onChange(e)}
                            />
                          )}
                        />
                      </div>
                      <div className="flex flex-col gap-2">
                        <label className="text-lg font-medium">
                          {t("user.region")}
                        </label>
                        <Controller
                          name={`addressInfo.${2}.region`}
                          control={control}
                          render={({ field }) => (
                            <Select
                              placeholder={t("user.region")}
                              styles={getReactSelectStyles(false)}
                              isLoading={lookups.loading}
                              inputId="region"
                              isMulti={false}
                              isClearable={true}
                              options={regionsOptions}
                              value={field.value}
                              onChange={(e) => field.onChange(e)}
                            />
                          )}
                        />
                      </div>
                      <div className="flex flex-col gap-2">
                        <label className="text-lg font-medium">
                          {t("user.country")}
                        </label>
                        <input
                          type="text"
                          placeholder={t("user.country")}
                          className={getInputStyles(false)}
                          {...register(`addressInfo.${2}.country`, {
                            maxLength: {
                              value: 50,
                              message: `${t("user.country")} ${t(
                                "user.shouldBe"
                              )} ${50} ${t("user.characters")}.`,
                            },
                          })}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/*PERMISSIONS*/}

              {canAccess("permissions", "UPDATE", user) && (
                <div
                  className={`flex flex-col gap-5 ${
                    current === "permissions" ? "" : "hidden"
                  }`}
                >
                  <div className="flex flex-col gap-2">
                    <label htmlFor="role" className="text-lg font-medium">
                      {t("user.role")} *
                    </label>
                    <Controller
                      name="role"
                      control={control}
                      rules={{
                        required: true,
                      }}
                      render={({ field }) => (
                        <Select
                          placeholder={t("user.role")}
                          styles={getReactSelectStyles(!!errors.role)}
                          isLoading={lookups.loading}
                          inputId="role"
                          isClearable={true}
                          options={rolesOptions}
                          value={field.value}
                          onChange={(e) => field.onChange(e)}
                        />
                      )}
                    />
                    <DisplayInputError
                      message={
                        errors.role &&
                        `${t("user.role")} ${t("user.required")}.`
                      }
                    />
                    <RegionRestrictions
                      regionRestrictions={form.watch("regionRestrictions")}
                      setRegionRestrictions={(
                        regions: regionRestrictionsSelect
                      ) => form.setValue("regionRestrictions", regions)}
                    />
                  </div>
                </div>
              )}
              {/* SUBSCRIPTIONS */}
              {current === "subscriptions" && (
                <div className="flex flex-col">
                  <SubscriptionTab
                    user={user}
                    fetchUser={fetchUser}
                    setLoading={setLoading}
                    subscription={subscription}
                    setSubscription={setSubscription}
                  />
                </div>
              )}
              {/* CERTIFICATIONS */}

              {current === "certificates" && (
                <div className="block">
                  {user?.IS_TEACHER ? (
                    <CertificatesTab
                      setLoading={setLoading}
                      userId={user.PK_USER}
                    />
                  ) : (
                    <p>
                      This user is not subscribed therefore his certificates
                      can&apos;t be updated.
                    </p>
                  )}
                </div>
              )}

              {current === "logs" && <Logs />}

              {current === "orders" && <Orders />}

              {current === "receipts" && <Receipts />}
            </div>
          </form>
        </FormProvider>
      </div>
    </Overlay>
  );
};

export default EditUser;
