import React, { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import DisplayInputError from "shared/components/UI/DisplayInputError";
import { CreateCouponDto } from "shared/interfaces/Coupon.interface";
import { selectTranslationLanguage } from "store/translationStore/translationReducer";
import {
  getInputStyles,
  getReactSelectStyles,
  mapCategories,
  toISOString,
} from "utils/utils";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "shared/styles/datePicker.css";
import Spinner from "shared/components/UI/Spinner";
import Overlay from "shared/components/UI/Overlay";
import { getCoupon, updateCoupon } from "api/Coupons/coupons.api";
import GoBack from "shared/components/UI/GoBack";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import Message from "shared/components/UI/Message";
import AsyncSelect from "react-select/async";
import { debounce } from "lodash";
import { OptionsOrGroups } from "react-select";
import { CMS_BE_URL } from "api/urls";
import axios from "axios";

export default function EditCoupon() {
  const { t } = useTranslation("common");
  const lang = selectTranslationLanguage();

  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const form = useForm<CreateCouponDto & { expiry_date: Date }>({
    mode: "onChange",
  });

  useEffect(() => {
    if (id) {
      setLoading(false);
      getCoupon(id)
        .then((res) => {
          form.setValue("code", res.data.coupon.code);
          form.setValue("percentage", res.data.coupon.percentage);
          form.setValue("amount", res.data.coupon.amount);
          form.setValue("uses", res.data.coupon.uses);
          form.setValue(
            "expiry_date",
            new Date(res.data.coupon.expiry_date.slice(0, -1)) as any
          );
          if (res.data.coupon.categories) {
            form.setValue(
              "categories",
              res.data.coupon.categories.map((category: any) => ({
                value: category,
                label: category.name || category.slug,
              })) as any
            );
          }
        })
        .catch((err: any) => {
          if (err.response?.data?.errors?.[0]?.message) {
            toast.error(err.response?.data?.errors?.[0]?.message);
          } else {
            toast.error(err.response?.data?.message);
          }
        })
        .finally(() => setLoading(false));
    }
  }, []);

  const onSubmit = form.handleSubmit((data) => {
    if (id) {
      setLoading(true);
      updateCoupon(id, {
        code: data.code,
        percentage: data.percentage,
        amount: Number(data.amount),
        uses: Number(data.uses),
        expiry_date: toISOString(data.expiry_date),
        categories: mapCategories(data.categories),
      })
        .then(() =>
          toast.success(
            Message({
              action: "update",
              entity: t("coupon.coupon"),
              lang,
              gender: "male",
            })
          )
        )
        .catch(
          (err: any) => {
            if (err.response?.data?.errors?.[0]?.message) {
              toast.error(err.response?.data?.errors?.[0]?.message);
            } else {
              toast.error(err.response?.data?.message);
            }
          }
          // toast.error(
          //   Message({
          //     action: "update",
          //     entity: t("coupon.coupon"),
          //     error: t("genericError"),
          //     lang,
          //     gender: "male",
          //   })
          // )
        )
        .finally(() => setLoading(false));
    }
  });

  const _loadOptions = (
    e: string,
    callback: (options: OptionsOrGroups<any, any>) => void
  ) => {
    axios
      .get(`${CMS_BE_URL}/s/categories`)
      .then((res) => {
        const categoriesOptions = res.data.data
          .filter((category: any) => {
            return (
              category.slug !== "patreon-subscriptions" &&
              category.slug !== "member-subscriptions" &&
              category.slug !== "exam-certificates"
            );
          })
          .filter(
            (category: any) =>
              form
                .watch("categories")
                ?.findIndex(
                  (c) => ((c as any).value.id as string) === category.id
                ) === -1
          )
          .map((category: any) => {
            return {
              label: `${category.name}`,
              value: category,
            };
          });
        callback(categoriesOptions);
      })
      .catch(() => {
        toast.error(
          Message({
            action: "create",
            entity: t("coupon.categories"),
            error: t("genericError"),
            lang,
            gender: "male",
          })
        );
        callback([]);
      });
  };
  const loadOptions = useCallback(debounce(_loadOptions, 400), []);

  return (
    <Overlay active={loading} spinner={<Spinner />}>
      <form
        onSubmit={onSubmit}
        className="flex flex-col flex-grow gap-5 p-10 h-full bg-gray-100"
      >
        <GoBack uri="coupons" />
        <div className="flex gap-5 justify-between">
          <h1 className="text-3xl font-bold">{t("coupon.updateCoupon")}</h1>
          <button
            type="submit"
            className="px-4 py-2 text-white bg-primary border border-primary"
          >
            {t("coupon.save")}
          </button>
        </div>

        <div className="grid gap-5 grid-cols-2 w-full">
          <div className="flex flex-col gap-2 w-full">
            <label htmlFor="code" className="text-lg font-medium">
              {t("coupon.code")} *
            </label>
            <input
              id="code"
              type="text"
              {...form.register("code", {
                required: {
                  value: true,
                  message: t("coupon.codeRequired"),
                },
              })}
              className={getInputStyles(!!form.formState.errors.code)}
            />
            <DisplayInputError message={form.formState.errors.code?.message} />
          </div>
          <div className="flex flex-col gap-2">
            <div className="flex flex-col gap-2 w-full">
              <label htmlFor="amount" className="text-lg font-medium">
                {t("coupon.value")} *
              </label>
              <input
                id="amount"
                type="text"
                {...form.register("amount", {
                  required: {
                    value: true,
                    message: t("coupon.valueRequired"),
                  },
                  validate: {
                    isNumber(i) {
                      if (isNaN(Number(i))) {
                        return t("coupon.valueNumber") as string;
                      }
                      return true;
                    },
                  },
                })}
                className={getInputStyles(!!form.formState.errors.amount)}
              />
              <DisplayInputError
                message={form.formState.errors.amount?.message}
              />
            </div>
            <div className="flex gap-2 items-center">
              <input
                id="percentage"
                type="checkbox"
                {...form.register("percentage")}
                className="text-primary"
              />
              <label htmlFor="percentage" className="select-none">
                {t("coupon.percentage")}
              </label>
            </div>
          </div>
          <div className="flex flex-col gap-2 w-full">
            <label htmlFor="uses" className="text-lg font-medium">
              {t("coupon.amount")} *
            </label>
            <input
              id="uses"
              type="text"
              {...form.register("uses", {
                required: {
                  value: true,
                  message: t("coupon.amountRequired"),
                },
                validate: {
                  isNumber(i) {
                    if (isNaN(Number(i))) {
                      return t("coupon.amountNumber") as string;
                    }
                    return true;
                  },
                },
              })}
              className={getInputStyles(!!form.formState.errors.uses)}
            />
            <DisplayInputError message={form.formState.errors.uses?.message} />
          </div>
          <div className="flex flex-col gap-2 w-full">
            <label htmlFor="expiry_date" className="text-lg font-medium">
              {t("coupon.expiryDate")} *
            </label>
            <Controller
              name="expiry_date"
              control={form.control}
              rules={{
                required: `${t("coupon.expiryDateRequired")}`,
              }}
              render={({ field }) => {
                return (
                  <div>
                    <DatePicker
                      id="expiry_date"
                      selected={field.value}
                      onChange={(e) => field.onChange(e)}
                      showTimeSelect={true}
                      dateFormat="dd/MM/yyyy h:mm aa"
                      className={getInputStyles(
                        !!form.formState.errors.expiry_date
                      )}
                      placeholderText={t("coupon.expiryDate")}
                      showPopperArrow={false}
                      autoComplete="off"
                    />
                  </div>
                );
              }}
            />
            <DisplayInputError
              message={form.formState.errors.expiry_date?.message}
            />
          </div>
          <div className="flex flex-col gap-2 w-full">
            <label htmlFor="categories" className="text-lg font-medium">
              {t("coupon.exclude")} {t("coupon.categories")}
            </label>
            <Controller
              name="categories"
              control={form.control}
              render={({ field }) => (
                <AsyncSelect
                  cacheOptions
                  defaultOptions
                  placeholder={t("coupon.categories")}
                  styles={getReactSelectStyles(false)}
                  isMulti={true}
                  inputId="categories"
                  isClearable={true}
                  value={field.value}
                  loadOptions={loadOptions}
                  onChange={(e: any) => {
                    field.onChange(e);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") e.preventDefault();
                  }}
                />
              )}
            />
          </div>
        </div>
      </form>
    </Overlay>
  );
}
