import { GroupBase, OptionsOrGroups } from "react-select";
import { Lang } from "shared/components/UI/Message";
import { PaymentTypeEnum } from "./Orders.interface";

export type PaymentStatus = "pending" | "paid" | "refunded";
export type PaymentType =
  | "stripe"
  | "unicredit"
  | "cash"
  | "bank_transfer"
  | "postal"
  | "gift";

export type Payment = {
  id: string;
  customer_id: null;
  order_id: null;
  status: PaymentStatus;
  fees: number;
  amount: number;
  payment_type: string;
  payment_date: string;
  payment_location: null;
  created_at: string;
  updated_at: string;
};

export type SelectPaymentStatusType = {
  value: string;
  label: string;
};

export const paymentStatusOptions: any[] = [
  { label: "Pending", value: "pending" },
  { label: "Paid", value: "paid" },
  { label: "Refunded", value: "refunded" },
];

export type SelectPaymentTypeType = {
  value: string;
  label: string;
};

export const paymentTypeOptions: (lang: Lang) =>
  | OptionsOrGroups<
      {
        label: string;
        value: PaymentTypeEnum;
      },
      GroupBase<{
        label: string;
        value: PaymentTypeEnum;
      }>
    >
  | undefined = (lang: Lang) => [
  { label: "Stripe", value: "stripe" },
  { label: lang === "en" ? "Cash" : "Contati", value: "cash" },
  {
    label: lang === "en" ? "Bank Transfer" : "Trasferimento Bancario",
    value: "bank_transfer",
  },
  { label: lang === "en" ? "Postal" : "Postale", value: "postal" },
  { label: lang === "en" ? "Gift" : "Regalo", value: "gift" },
];
