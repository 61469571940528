import React from "react";

const BadgeIcon = ({ badge }: { badge: any }) => {
  if (badge === "silver") {
    return (
      <span>
        <img src="/icons/certificates/Silver.svg" alt="Bronze medal" />
      </span>
    );
  }
  if (badge === "bronze") {
    return (
      <span>
        <img src="/icons/certificates/Bronze.svg" alt="Bronze medal" />
      </span>
    );
  }
  if (badge === "gold") {
    return (
      <span>
        <img src="/icons/certificates/Gold.svg" alt="Bronze medal" />
      </span>
    );
  }
  if (badge === "platinum") {
    return (
      <span>
        <img src="/icons/certificates/Platinum.svg" alt="Bronze medal" />
      </span>
    );
  }

  return <div></div>;
};

export default BadgeIcon;
