import { Exam } from "shared/interfaces/Exam.interface";

export interface ExamsState {
  data: {
    event:
      | (Exam & {
          users_going: {
            name: string;
            surname: string;
            email: string;
            contactdetails: {
              contact_type: string;
              contact_details: string;
            }[];
          }[];
        })
      | null;
    message: string;
    eventTags: string[];
    total: number;
    pageIndex: number;
    data: Exam[];
  };
  loading: boolean;
}

export const examsInitialState: ExamsState = {
  data: {
    event: null,
    message: "",
    eventTags: [],
    total: 0,
    pageIndex: 0,
    data: [],
  },
  loading: false,
};
