import axios from "axios";
import {
  CreateCouncilRank,
  UpdateCouncilRank,
} from "shared/interfaces/Rank.interface";
import {
  createCouncilRankURL,
  deleteCouncilRankURL,
  getCouncilRanksURL,
  getCouncilRankURL,
  updateCouncilRankURL,
} from "./urls";

export function createCouncilRank({ data }: { data: CreateCouncilRank }) {
  return axios.post(createCouncilRankURL(), data);
}

export function getCouncilRanks(filters: {
  pageSize?: number;
  pageIndex?: number;
  sortCol?: string;
  sortOrder?: string;
}) {
  return axios.get(getCouncilRanksURL(filters));
}

export function deleteCouncilRank({ rankId }: { rankId: string }) {
  return axios.delete(deleteCouncilRankURL({ rankId }));
}

export function getCouncilRank({ rankId }: { rankId: string }) {
  return axios.get(getCouncilRankURL({ rankId }));
}

export function updateCouncilRank({
  rankId,
  data,
}: {
  rankId: string;
  data: UpdateCouncilRank;
}) {
  return axios.put(updateCouncilRankURL({ rankId }), data);
}
