import { Payment } from "shared/interfaces/Payment.interface";

export interface PaymentsState {
  data: {
    message: string;
    total: number;
    pageIndex: number;
    data: Payment[];
    payment?: Payment;
  };
  loading: boolean;
}

export const PaymentsInitialState: PaymentsState = {
  data: {
    message: "",
    total: 0,
    pageIndex: 0,
    data: [],
    payment: undefined,
  },
  loading: false,
};
