import React, { useCallback } from "react";
import { BillingAddress } from "api/Orders/orders.api";
import { useEffect, useState } from "react";
import {
  useFormContext,
  UseFormRegister,
  UseFormUnregister,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { PlaceDetailsType } from "shared/interfaces/Location.interface";
import { locationActions } from "store/globalStore";
import {
  placeAutocompleteThunk,
  placeDetailsThunk,
} from "store/locationStore/locationReducer";
import { useAppDispatch } from "store/storeHooks";
import { selectTranslationLanguage } from "store/translationStore/translationReducer";
import { debounce } from "lodash";
import DisplayInputError from "shared/components/UI/DisplayInputError";
import { getReactSelectStyles } from "utils/utils";
import AsyncSelect from "react-select/async";
import { toast } from "react-toastify";
import Message from "shared/components/UI/Message";

export function registerLocationRequired(
  register: UseFormRegister<any>,
  reactHookFormName: string
) {
  register(`${reactHookFormName}.street_name`, {
    required: true,
  });
  register(`${reactHookFormName}.street_number`, {
    required: true,
  });
  register(`${reactHookFormName}.postal_code`, {
    required: true,
  });
  register(`${reactHookFormName}.city`, {
    required: true,
  });
  register(`${reactHookFormName}.province`, {
    required: true,
  });
  register(`${reactHookFormName}.region`, {
    required: true,
  });
  register(`${reactHookFormName}.country`, {
    required: true,
  });
  register(`${reactHookFormName}.longitude`);
  register(`${reactHookFormName}.latitude`);
}

export function unregisterLocation(
  unregister: UseFormUnregister<any>,
  reactHookFormName: string
) {
  unregister(`${reactHookFormName}.street_name`);
  unregister(`${reactHookFormName}.street_number`);
  unregister(`${reactHookFormName}.postal_code`);
  unregister(`${reactHookFormName}.city`);
  unregister(`${reactHookFormName}.province`);
  unregister(`${reactHookFormName}.region`);
  unregister(`${reactHookFormName}.country`);
  unregister(`${reactHookFormName}.longitude`);
  unregister(`${reactHookFormName}.latitude`);
}

export function registerLocation(
  register: UseFormRegister<any>,
  reactHookFormName: string
) {
  register(`${reactHookFormName}.street_name`);
  register(`${reactHookFormName}.street_number`);
  register(`${reactHookFormName}.postal_code`);
  register(`${reactHookFormName}.city`);
  register(`${reactHookFormName}.province`);
  register(`${reactHookFormName}.region`);
  register(`${reactHookFormName}.country`);
  register(`${reactHookFormName}.longitude`);
  register(`${reactHookFormName}.latitude`);
}

function extractAddressDetails(placeDetails: PlaceDetailsType): BillingAddress {
  const addressDetails: BillingAddress = {
    street_number: undefined,
    street_name: "",
    city: "",
    province: "",
    region: "",
    country: "",
    postal_code: undefined,
  } as BillingAddress;
  placeDetails.result.address_components.forEach((c) => {
    switch (c.types[0]) {
      case "street_number": {
        addressDetails.street_number = Number(c.long_name);
        break;
      }
      case "route": {
        addressDetails.street_name = c.long_name;
        break;
      }
      case "administrative_area_level_3": {
        addressDetails.city = c.long_name;
        break;
      }
      case "administrative_area_level_2": {
        addressDetails.province = c.short_name;
        break;
      }
      case "administrative_area_level_1": {
        addressDetails.region = c.long_name;
        break;
      }
      case "country": {
        addressDetails.country = c.long_name;
        break;
      }
      case "postal_code": {
        addressDetails.postal_code = Number(c.long_name);
        break;
      }
      default:
        break;
    }
  });
  addressDetails.longitude = placeDetails.result.geometry.location.lng;
  addressDetails.latitude = placeDetails.result.geometry.location.lat;
  return addressDetails;
}

export default function LocationSearch({
  reactHookFormName,
}: {
  reactHookFormName: string;
}) {
  const form = useFormContext();
  const lang = selectTranslationLanguage();
  const { t } = useTranslation("common");
  const dispatch = useAppDispatch();
  const [placeDetails, setPlaceDetails] = useState("");
  const [placeDetailsResult, setPlaceDetailsResult] = useState();

  useEffect(() => {
    if (placeDetails) {
      dispatch(placeDetailsThunk({ placeid: placeDetails })).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          setPlaceDetailsResult(res.payload);
        }
      });
    }
  }, [placeDetails]);

  useEffect(() => {
    return () => {
      dispatch(locationActions.clearPlaceAutocomplete());
      dispatch(locationActions.clearPlaceDetails());
    };
  }, []);

  useEffect(() => {
    return () => {
      dispatch(locationActions.clearPlaceAutocomplete());
      dispatch(locationActions.clearPlaceDetails());
    };
  }, []);

  useEffect(() => {
    if (placeDetailsResult) {
      const addressDetails = extractAddressDetails(placeDetailsResult);
      Object.entries(addressDetails).forEach(([key, value]) => {
        if (key === "region" || key === "province") {
          form.setValue(`${reactHookFormName}.${key}`, { value, label: value });
          form.clearErrors([`${reactHookFormName}.${key}`]);
        } else {
          form.setValue(`${reactHookFormName}.${key}`, value);
          form.clearErrors([`${reactHookFormName}.${key}`]);
        }
      });
    }
  }, [placeDetailsResult]);

  const _loadOptions = (e: string, callback: any): any => {
    if (e === "") return callback([]);
    dispatch(
      placeAutocompleteThunk({
        input: e,
      })
    ).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        callback(
          res.payload?.predictions.map((location: any) => {
            return {
              value: location.place_id,
              label: String(location.description),
            };
          })
        );
      } else {
        toast.error(
          Message({
            entity: "location",
            action: "read",
            error: t("genericError"),
            lang,
          })
        );
      }
    });
  };

  const loadOptions = useCallback(debounce(_loadOptions, 400), []);

  return (
    <div className="flex flex-col gap-2">
      <label
        htmlFor={reactHookFormName}
        className="block text-lg font-medium sr-only"
      >
        {t("location.search")}
      </label>
      <AsyncSelect
        cacheOptions
        defaultOptions
        placeholder={t("location.search")}
        styles={getReactSelectStyles(
          !!form.formState.errors[reactHookFormName]
        )}
        inputId={reactHookFormName}
        loadOptions={loadOptions}
        onChange={(e: any) => {
          setPlaceDetails(e.value);
        }}
      />
      <DisplayInputError
        message={
          form.formState.errors[reactHookFormName] && t("location.error")
        }
      />
    </div>
  );
}
