import axios, { AxiosResponse } from "axios";
import {
  createTagURL,
  deleteTagURL,
  getTagsURL,
  getTagURL,
  updateTagURL,
} from "./urls";
import { TagCreate, TagUpdate } from "shared/interfaces/Tag.interface";

export const createTag: (
  data: TagCreate
) => Promise<AxiosResponse<any, any>> = (data: TagCreate) =>
  axios.post(createTagURL(), data);

export const getTags = () => axios.get(getTagsURL());

export const getTag: (tagId: string) => Promise<AxiosResponse<any, any>> = (
  tagId: string
) => axios.get(getTagURL(tagId));

export const updateTag: (
  tagId: string,
  data: TagUpdate
) => Promise<AxiosResponse<any, any>> = (tagId: string, data: TagUpdate) =>
  axios.put(updateTagURL(tagId), data);

export const deleteTag = (tagId: string) => axios.delete(deleteTagURL(tagId));
