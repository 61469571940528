import React, { useState } from "react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { getInputStyles, getReactSelectStyles } from "utils/utils";
import axios from "axios";
import { CMS_BE_URL } from "api/urls";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { format } from "date-fns";

const NewValues = ({
  statusesOptions,
  ranksOptions,
  selectedRegions,
  selectedRanks,
  setLoading,
  selectedStatuses,
  excludeCounselors,
  subStartDateFrom,
  subStartDateTo,
  subEndDateFrom,
  subEndDateTo,
}: {
  selectedRegions: any;
  selectedRanks: any;
  selectedStatuses: any;
  statusesOptions: any;
  setLoading: any;
  ranksOptions: any;
  excludeCounselors: boolean;
  subStartDateFrom: any;
  subStartDateTo: any;
  subEndDateFrom: any;
  subEndDateTo: any;
}) => {
  const [statusFilter, setStatusFilter] = useState<any>("");
  const [rankFilter, setRankFilter] = useState<any>("");
  const [subStartDate, setSubStartDate] = useState<Date | null>();
  const [subEndDate, setSubEndDate] = useState<Date | null>();

  const { t } = useTranslation("common");

  //Control if the new values fields are shown
  const [showStatus, setShowStatus] = useState(false);
  const [showRank, setShowRank] = useState(false);
  const [showSubStart, setShowSubStart] = useState(false);
  const [showSubEnd, setShowSubEnd] = useState(false);

  const [showNewsletter, setShowNewsletter] = useState(false);
  const [showBounced, setShowBounced] = useState(false);
  const [newsletter, setNewsletter] = useState<string | null>(null);
  const [bounced, setBounced] = useState<boolean | string | null>(null);

  const toggleNewsletterOption = () => {
    showNewsletter === true && setNewsletter(null);
    showNewsletter === false && setNewsletter("0");
    setShowNewsletter((prev) => !prev);
  };

  const toggleBouncedOption = () => {
    showBounced === true && setBounced(null);
    showBounced === false && setBounced("0");
    setShowBounced((prev) => !prev);
  };

  const massUpdateObject: any = {
    filters: {
      region: "",
      rank: "",
      memberStatus: "",
      excludingCounselors: excludeCounselors,
    },
    values: {
      memberStatus: "",
      rank: "",
      subStartDate: null || undefined,
      subEndDate: "",
      newsletter: null,
      bounced: null,
    },
  };

  const massUpdate = async () => {
    setLoading(true);
    const selectedRegionsMap = selectedRegions.map((r: any) => r.value);
    const selectedRanksMap = selectedRanks.map((r: any) => r.value);
    const selectedStatusesMap = selectedStatuses.map((r: any) => r.value);
    massUpdateObject.filters.region = selectedRegionsMap.join();
    massUpdateObject.filters.rank = selectedRanksMap.join();
    massUpdateObject.filters.memberStatus = selectedStatusesMap.join();

    massUpdateObject.filters.subStartDateFrom = subStartDateFrom
      ? format(subStartDateFrom, "yyyy/M/d")
      : "";
    massUpdateObject.filters.subStartDateTo = subStartDateTo
      ? format(subStartDateTo, "yyyy/M/d")
      : "";
    massUpdateObject.filters.subEndDateFrom = subEndDateFrom
      ? format(subEndDateFrom, "yyyy/M/d")
      : "";
    massUpdateObject.filters.subEndDateTo = subEndDateTo
      ? format(subEndDateTo, "yyyy/M/d")
      : "";

    massUpdateObject.values.memberStatus = showStatus
      ? statusFilter.value
        ? statusFilter.value
        : ""
      : "";
    massUpdateObject.values.rank = showRank
      ? rankFilter.value
        ? rankFilter.value
        : ""
      : "";
    massUpdateObject.values.subStartDate =
      showSubStart && subStartDate ? format(subStartDate, "yyyy-MM-dd") : "";
    massUpdateObject.values.subEndDate =
      showSubEnd && subEndDate ? format(subEndDate, "yyyy-MM-dd") : "";
    massUpdateObject.values.newsletter = newsletter
      ? newsletter === "1"
        ? true
        : false
      : null;
    massUpdateObject.values.bounced = bounced
      ? bounced === "1"
        ? true
        : false
      : null;
    try {
      await axios.put(`${CMS_BE_URL}/users/massUpdateUsers`, massUpdateObject, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      toast.success("Successfully updated users.");
    } catch (err: any) {
      if (err.response?.data?.errors?.[0]?.message) {
        toast.error(err.response?.data?.errors?.[0]?.message);
      } else {
        toast.error(err.response?.data?.message);
      }
    }
    setLoading(false);
  };

  return (
    <div>
      <h3 className="mb-5 font-semibold">{t("massUpdate.newValues")}:</h3>
      <div className="grid gap-5 grid-cols-3 mb-5">
        <div className="w-1/3">
          <div className="flex gap-2 items-center mb-2">
            <input
              onChange={() => setShowStatus(!showStatus)}
              type="checkbox"
            />
            {t("massUpdate.selectStatus")}
          </div>
          {showStatus && (
            <Select
              styles={getReactSelectStyles(false)}
              closeMenuOnSelect={false}
              placeholder="Status"
              value={statusFilter}
              options={statusesOptions}
              onChange={(value: any) => setStatusFilter(value)}
            />
          )}
        </div>
        <div className="w-1/3">
          <div className="flex gap-2 items-center mb-2">
            <input onChange={() => setShowRank(!showRank)} type="checkbox" />
            {t("massUpdate.selectRanks")}
          </div>
          {showRank && (
            <Select
              styles={getReactSelectStyles(false)}
              closeMenuOnSelect={false}
              placeholder="Ranks"
              value={rankFilter}
              options={ranksOptions}
              onChange={(value: any) => setRankFilter(value)}
            />
          )}
        </div>

        <div className="w-1/3">
          <div className="flex gap-2 items-center mb-2 w-full whitespace-nowrap">
            <input
              onChange={() => setShowSubStart(!showSubStart)}
              type="checkbox"
            />
            {t("massUpdate.selectSubStart")}
          </div>
          {showSubStart && (
            <DatePicker
              id="birthDate"
              selected={subStartDate}
              onChange={(e) => setSubStartDate(e)}
              dateFormat="dd/MM/yyyy"
              className="border border-gray-300"
              placeholderText="New Registration Date"
              showPopperArrow={false}
              autoComplete="off"
            />
          )}
        </div>
        <div className="w-1/3">
          <div className="flex gap-2 items-center mb-2 whitespace-nowrap">
            <input
              onChange={() => setShowSubEnd(!showSubEnd)}
              type="checkbox"
            />
            {t("massUpdate.selectSubEnd")}
          </div>
          {showSubEnd && (
            <DatePicker
              id="birthDate"
              selected={subEndDate}
              onChange={(e) => setSubEndDate(e)}
              dateFormat="dd/MM/yyyy"
              className="border border-gray-300"
              placeholderText="New Dimission Date"
              showPopperArrow={false}
              autoComplete="off"
            />
          )}
        </div>
        <div className="w-1/3">
          <div className="flex gap-2 items-center mb-2 whitespace-nowrap">
            <input onChange={() => toggleNewsletterOption()} type="checkbox" />
            Newsletter Flag
          </div>
          {showNewsletter && (
            <div className="w-max space-y-2">
              <input
                style={{ width: "8rem" }}
                type="range"
                id="bounced"
                name="bounced"
                min="0"
                max="1"
                defaultValue={"0"}
                onChange={(e) => setNewsletter(e.target.value)}
              />
              <div className="flex gap-10 justify-between">
                <label htmlFor="false">False</label>
                <label htmlFor="true">True</label>
              </div>
            </div>
          )}
        </div>
        <div className="w-1/3">
          <div className="flex gap-2 items-center mb-2 whitespace-nowrap">
            <input onChange={() => toggleBouncedOption()} type="checkbox" />
            Bounced Flag
          </div>
          {showBounced && (
            <div className="w-max space-y-2">
              <input
                style={{
                  width: "8rem",
                }}
                type="range"
                id="bounced"
                name="bounced"
                min="0"
                max="1"
                defaultValue={"0"}
                onChange={(e) => setBounced(e.target.value)}
              />
              <div className="flex gap-10 justify-between">
                <label htmlFor="false">False</label>
                <label htmlFor="true">True</label>
              </div>
            </div>
          )}
        </div>
      </div>
      <button
        className="mt-5 px-4 py-2 text-white bg-users-create"
        onClick={massUpdate}
        type="button"
      >
        {t("massUpdate.h1")}
      </button>
    </div>
  );
};

export default NewValues;
