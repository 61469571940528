import {
  PlaceAutocompleteType,
  PlaceDetailsType,
} from "shared/interfaces/Location.interface";

export interface LocationState {
  data: {
    placeAutocomplete: PlaceAutocompleteType | null;
    placeDetails: PlaceDetailsType | null;
  };
  loading: boolean;
}

export const locationInitialState: LocationState = {
  data: {
    placeAutocomplete: null,
    placeDetails: null,
  },
  loading: false,
};
