import { DocumentDownloadIcon } from "@heroicons/react/solid";
import { CMS_BE_URL } from "api/urls";
import axios from "axios";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import CreateModal from "shared/components/UI/CreateModal";

const ExportUsers = ({
  total,
  loading,
  role,
  setLoading,
  location,
  rank,
  status,
  memberStatus,
}: {
  total: any;
  location: string;
  rank: string;
  status: string;
  memberStatus: string;
  role: string;
  loading: boolean;
  setLoading: any;
}) => {
  const { t } = useTranslation("common");
  const [open, setOpen] = useState(false);
  const [fields, setFields] = useState<any>({
    userDetails: false,
    addressDetails: false,
    certificates: false,
    subscription: false,
    orders: false,
  });

  const exportData = async () => {
    let hasSelectedValue = false;
    Object.keys(fields).map((k: any) => {
      if (fields[k] === true) {
        hasSelectedValue = true;
      }
    });
    if (!hasSelectedValue) {
      alert("WRONG");
      return;
    }
    Object.keys;
    setLoading(true);
    const arr: any[] = [];
    Object.keys(fields).map((k: any) => {
      if (fields[k] === true) {
        arr.push(k);
      }
    });

    try {
      axios
        .get(
          `${CMS_BE_URL}/users?exporting=true&including=${arr.join(
            ","
          )}&pageSize=${total}${role ? `&role=${role}` : ""}${
            rank ? `&rank=${rank}` : ""
          }${location ? `&location=${location}` : ""}${
            memberStatus ? `&memberStatus=${memberStatus}` : ""
          }${status ? `&status=${status}` : ""}`,
          {
            responseType: "blob",
          }
        )
        .then((res: any) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "file.xlsx"); //or any other extension
          document.body.appendChild(link);
          link.click();
          setLoading(false);
        })
        .catch((err: any) => {
          if (err.response?.data?.errors?.[0]?.message) {
            toast.error(err.response?.data?.errors?.[0]?.message);
          } else {
            toast.error(err.response?.data?.message);
          }
          setLoading(false);
        })
        .finally(() => setOpen(false));
    } catch (err: any) {
      if (err.response?.data?.errors?.[0]?.message) {
        toast.error(err.response?.data?.errors?.[0]?.message);
      } else {
        toast.error(err.response?.data?.message);
      }
      setLoading(false);
    }
  };

  return (
    <div className="">
      <button
        onClick={() => setOpen(true)}
        className="px-4 py-2 text-white bg-primary"
      >
        Export Users
      </button>
      <CreateModal
        openStatus={open}
        setOpen={setOpen}
        className=""
        // icon={
        //   <ExclamationIcon
        //     className="w-6 h-6 text-red-600 bg-transparent"
        //     aria-hidden="true"
        //   />
        // }
        header={<p className="mb-10">{t("exportUsers.exportUsers")}</p>}
        title={
          <>
            <div className="w-full">
              <p className="mb-4 text-gray-800 text-lg">
                {t("exportUsers.chooseFields")}
              </p>
              <label className="flex gap-2 items-center mb-2">
                <input
                  onChange={() =>
                    setFields((prevFields: any) => ({
                      ...prevFields,
                      userDetails: !prevFields.userDetails,
                    }))
                  }
                  className="text-primary"
                  type="checkbox"
                />{" "}
                {t("exportUsers.userDetails")}
              </label>
              <label className="flex gap-2 items-center mb-2">
                <input
                  onChange={() =>
                    setFields((prevFields: any) => ({
                      ...prevFields,
                      addressDetails: !prevFields.addressDetails,
                    }))
                  }
                  className="text-primary"
                  type="checkbox"
                />{" "}
                {t("exportUsers.userAddress")}
              </label>
              <label className="flex gap-2 items-center mb-2">
                <input
                  onChange={() =>
                    setFields((prevFields: any) => ({
                      ...prevFields,
                      certificates: !prevFields.certificates,
                    }))
                  }
                  className="text-primary"
                  type="checkbox"
                />{" "}
                {t("exportUsers.userCertification")}
              </label>
              <label className="flex gap-2 items-center mb-2">
                <input
                  onChange={() =>
                    setFields((prevFields: any) => ({
                      ...prevFields,
                      subscription: !prevFields.subscription,
                    }))
                  }
                  className="text-primary"
                  type="checkbox"
                />{" "}
                {t("exportUsers.userSubHistory")}
              </label>
              <label className="flex gap-2 items-center mb-2">
                <input
                  onChange={() =>
                    setFields((prevFields: any) => ({
                      ...prevFields,
                      orders: !prevFields.orders,
                    }))
                  }
                  className="text-primary"
                  type="checkbox"
                />{" "}
                {t("exportUsers.userOrderHistory")}
              </label>
            </div>
          </>
        }
        footer={
          <div className="mt-5 sm:flex sm:flex-row-reverse sm:mt-4">
            <button
              type="button"
              className="flex gap-2 items-center justify-center px-4 py-2 text-white bg-global-createEntity border border-global-createEntity"
              onClick={() => exportData()}
              disabled={loading}
            >
              <DocumentDownloadIcon className="w-5 h-5" />
              <span className="whitespace-nowrap">
                {loading ? "Loading..." : t("exportUsers.export")}
              </span>
            </button>
          </div>
        }
      />
    </div>
  );
};

export default ExportUsers;
