import React from "react";
import axios from "axios";
import { CreateNew } from "shared/interfaces/New.interface";
import {
  constructDeleteNewsURL,
  constructUpdateNewsURL,
  createNewURL,
  deleteNewURL,
  getNewsURL,
  getNewURL,
  UpdateNewsFilters,
  updateNewURL,
} from "./urls";
import { NewsFiltersType } from "pages/News/types";

export type MassUpdateNewsDtoBase = {
  filters: NewsFiltersType;
  values: { visible: boolean };
};

export type MassUpdateNewsDtoInclude = MassUpdateNewsDtoBase & {
  include: string[];
};

export type MassUpdateNewsDtoExclude = MassUpdateNewsDtoBase & {
  exclude: string[];
};

export type MassNewsFiltersDto = {
  filters: UpdateNewsFilters;
};

export type MassNewsFiltersDtoInclude = MassNewsFiltersDto & {
  include: string[];
};

export type MassNewsFiltersDtoExclude = MassNewsFiltersDto & {
  exclude: string[];
};

function isMassUpdateNewsDtoInclude(
  data: any
): data is MassUpdateNewsDtoInclude {
  return !!data.include;
}

function isMassUpdateNewsDtoExclude(
  data: any
): data is MassUpdateNewsDtoExclude {
  return !!data.exclude;
}

export const createNew = (post: CreateNew) => {
  const formData = new FormData();
  Object.entries(post).map(([key, value]: [any, any]) => {
    if (key === "media" && post.media) {
      post.media.forEach((file) => {
        formData.append(key, file);
      });
    } else formData.append(key, value);
  });
  return axios.post(createNewURL(), formData);
};
export const getNews = (filters: {
  user?: string;
  featured?: string;
  tags?: string;
  visible?: string;
  region?: string;
  pageSize?: number;
  pageIndex?: number;
  sortCol?: string;
  sortOrder?: string;
  searchString?: string;
}) => {
  return axios.get(getNewsURL(filters));
};
export const updateNew = (postId: string, post: CreateNew) => {
  const formData = new FormData();
  Object.entries(post).map(([key, value]: [any, any]) => {
    formData.append(key, value);
  });
  return axios.put(updateNewURL(postId), formData);
};
export const deleteNew = (postId: string) => {
  return axios.delete(deleteNewURL(postId));
};
export const getNew = (postId: string) => {
  return axios.get(getNewURL(postId));
};

export const updateNews = (
  data: MassUpdateNewsDtoInclude | MassUpdateNewsDtoExclude
) => {
  if (isMassUpdateNewsDtoInclude(data)) {
    return axios.put(constructUpdateNewsURL(data.filters), {
      values: data.values,
      include: data.include,
    });
  } else {
    return axios.put(constructUpdateNewsURL(data.filters), {
      values: data.values,
      exclude: data.exclude,
    });
  }
};

export const deleteNews = (
  data: MassNewsFiltersDtoInclude | MassNewsFiltersDtoExclude
) => {
  if (isMassUpdateNewsDtoInclude(data)) {
    return axios.put(constructDeleteNewsURL(data.filters), {
      include: data.include,
    } as any);
  }
  if (isMassUpdateNewsDtoExclude(data)) {
    return axios.put(constructDeleteNewsURL(data.filters), {
      exclude: data.exclude,
    } as any);
  }
  return Promise.reject(undefined);
};

export const getNewsColumns = (
  {
    allSelected,
    setAllSelected,
    excludeNews,
    setExcludeNews,
    includeNews,
    setIncludeNews,
  }: any,
  lang: string
) => {
  return [
    {
      Header: (
        <input
          type="checkbox"
          checked={allSelected}
          onClick={() => {
            setAllSelected((state: any) => !state);
            setExcludeNews([]);
            setIncludeNews([]);
          }}
          className="text-primary"
        />
      ),
      accessor: "checkbox",
      width: "30",
      disableSortBy: true,
    },
    {
      Header: lang === "en" ? "title" : "titolo",
      accessor: "col1",
      width: "250", // 250
      id: "title",
    },
    {
      Header: lang === "en" ? "region" : "regione",
      accessor: "col2",
      width: "200", // 200
      id: "location",
    },
    {
      Header: lang === "en" ? "author" : "autore",
      accessor: "col3",
      width: "150", // 200
      id: "creator_id",
    },
    {
      Header: lang === "en" ? "date created" : "data di creazione",
      accessor: "col4",
      width: "250", // 250
      id: "created_at",
    },
    {
      Header: lang === "en" ? "status" : "stato",
      accessor: "col5",
      width: "150", // 150
      id: "status",
    },
    {
      Header: lang === "en" ? "actions" : "azioni",
      accessor: "col6",
      width: "150", // 150
      disableSortBy: true,
    },
  ];
};
