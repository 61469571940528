import React from "react";
import axios, { Axios, AxiosResponse } from "axios";
import { EventsFiltersType } from "pages/Events/types";
import { Certificate } from "shared/interfaces/Certificate.interface";
import { CreateEvent, Event } from "shared/interfaces/Event.interface";
import {
  createEventURL,
  getEventsURL,
  updateEventURL,
  deleteEventURL,
  getEventURL,
  constructUpdateEventsURL,
  constructDeleteEventsURL,
  UpdateEventsFilters,
} from "./urls";

export type MassUpdateEventsDtoBase = {
  filters: EventsFiltersType;
  values: { visible: boolean };
};

export type MassUpdateEventsDtoInclude = MassUpdateEventsDtoBase & {
  include: string[];
};

export type MassUpdateEventsDtoExclude = MassUpdateEventsDtoBase & {
  exclude: string[];
};

export type MassEventsFiltersDto = {
  filters: UpdateEventsFilters;
};

export type MassEventsFiltersDtoInclude = MassEventsFiltersDto & {
  include: string[];
};

export type MassEventsFiltersDtoExclude = MassEventsFiltersDto & {
  exclude: string[];
};

function isMassUpdateEventsDtoInclude(
  data: any
): data is MassUpdateEventsDtoInclude {
  return !!data.include;
}

function isMassUpdateEventsDtoExclude(
  data: any
): data is MassUpdateEventsDtoExclude {
  return !!data.exclude;
}

export const createEvent = (event: CreateEvent) => {
  const formData = new FormData();
  Object.entries(event).map(([key, value]) => {
    if (key === "media" && event.media) {
      event.media.forEach((file) => {
        formData.append(key, file);
      });
    } else if (key === "sponsors" && event.sponsors) {
      event.sponsors.forEach((file) => {
        formData.append(key, file);
      });
    } else formData.append(key, value);
  });
  return axios.post(createEventURL(), formData);
};

export const getEvents = ({
  from,
  to,
  region,
  tags,
  visible,
  pageSize,
  pageIndex,
  sortCol,
  sortOrder,
  searchString,
}: {
  from: string;
  to: string;
  region: string;
  tags: string;
  visible: string;
  pageSize: number;
  pageIndex: number;
  sortCol: string;
  sortOrder: string;
  searchString: string;
}): Promise<AxiosResponse<any, any>> => {
  return axios.get(
    getEventsURL({
      from,
      to,
      region,
      tags,
      visible,
      pageSize,
      pageIndex,
      sortCol,
      sortOrder,
      searchString,
    })
  );
};

export const updateEvent = (eventId: string, event: CreateEvent) => {
  const formData = new FormData();
  Object.entries(event).map(([key, value]) => {
    if (key === "media" && event.media) {
      event.media.forEach((file) => {
        formData.append(key, file);
      });
    } else if (key === "sponsors" && event.sponsors) {
      event.sponsors.forEach((file) => {
        formData.append(key, file);
      });
    } else formData.append(key, value);
  });
  return axios.put(updateEventURL(eventId), formData);
};

export const deleteEvent = (eventId: string) => {
  return axios.delete(deleteEventURL(eventId));
};

export const getEvent = (eventId: string) => {
  return axios.get(getEventURL(eventId));
};

export const updateEvents = (
  data: MassUpdateEventsDtoInclude | MassUpdateEventsDtoExclude
) => {
  if (isMassUpdateEventsDtoInclude(data)) {
    return axios.put(constructUpdateEventsURL(data.filters), {
      values: data.values,
      include: data.include,
    });
  } else {
    return axios.put(constructUpdateEventsURL(data.filters), {
      values: data.values,
      exclude: data.exclude,
    });
  }
};

export const deleteEvents = (
  data: MassEventsFiltersDtoInclude | MassEventsFiltersDtoExclude
) => {
  if (isMassUpdateEventsDtoInclude(data)) {
    return axios.put(constructDeleteEventsURL(data.filters), {
      include: data.include,
    } as any);
  }
  if (isMassUpdateEventsDtoExclude(data)) {
    return axios.put(constructDeleteEventsURL(data.filters), {
      exclude: data.exclude,
    } as any);
  }
  return Promise.reject(undefined);
};

export const getEventsColumns = (
  {
    allSelected,
    setAllSelected,
    excludeEvents,
    setExcludeEvents,
    includeEvents,
    setIncludeEvents,
  }: any,
  lang: string
) => {
  return [
    {
      Header: (
        <input
          type="checkbox"
          checked={allSelected}
          onClick={() => {
            setAllSelected((state: any) => !state);
            setExcludeEvents([]);
            setIncludeEvents([]);
          }}
          className="text-primary"
        />
      ),
      accessor: "checkbox",
      width: "30",
      disableSortBy: true,
    },
    {
      Header: lang === "en" ? "title" : "titolo",
      accessor: "col1",
      width: "250",
      id: "title",
    },
    {
      Header: lang === "en" ? "region" : "regione",
      accessor: "col2",
      width: "150",
      id: "location",
    },
    {
      Header: lang === "en" ? "start date" : "data d'inizio",
      accessor: "col3",
      width: "250",
      id: "start_date",
    },
    {
      Header: lang === "en" ? "end date" : "data di fine",
      accessor: "col4",
      width: "250",
      id: "end_date",
    },
    {
      Header: lang === "en" ? "status" : "stato",
      accessor: "col5",
      width: "150",
      id: "is_visible",
    },
    {
      Header: lang === "en" ? "actions" : "azioni",
      accessor: "col6",
      width: "150",
      id: "actions",
      disableSortBy: true,
    },
  ];
};
