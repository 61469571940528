import { CMS_BE_URL } from "api/urls";
import { getCertsColumns } from "api/Users/users.api";
import axios from "axios";
import React, { useState, useEffect, useMemo, useRef } from "react";
import Select from "react-select";
import { Column, useFlexLayout, useTable } from "react-table";
import Table from "shared/components/Table/Table";
import { useAppSelector } from "store/storeHooks";
import {
  DotsVerticalIcon,
  ExclamationIcon,
  TrashIcon,
  PencilIcon,
} from "@heroicons/react/solid";
import TablePopup, { PortalType } from "../UI/TablePopup";
import Modal from "../UI/Modal";
import CreateModal from "../UI/CreateModal";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { toast } from "react-toastify";
import {
  getDisabledInputStyles,
  getInputStyles,
  getReactSelectStyles,
} from "utils/utils";

import DatePicker from "react-datepicker";

const CertificatesTab = ({
  userId,
  setLoading,
}: {
  userId: string;
  setLoading: (value: boolean) => void;
}) => {
  const [certificateId, setCertificateId] = useState<any>("");
  const [certificates, setCertificates] = useState<any>([]);
  const [userCerts, setUserCerts] = useState<any>([]);
  const [options, setOptions] = useState([]);
  const [certificateError, setCertificateError] = useState(false);
  const [dateError, setDateError] = useState(false);
  const [regionError, setRegionError] = useState(false);
  const [date, setDate] = useState<any>("");
  const lang = useAppSelector((state) => state.translation.language);
  const [openModal, setOpenModal] = useState(false);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [certToDelete, setCertToDelete] = useState();
  const [certToEdit, setCertToEdit] = useState<any>();
  const locationRef = useRef<HTMLInputElement>(null);
  const notesRef = useRef<HTMLTextAreaElement>(null);
  const { t } = useTranslation("common");

  const [portal, setPortal] = useState<PortalType<any>>({
    open: false,
    top: 0,
    left: 0,
    entity: null,
  });

  const getUserCerts = async () => {
    const { data } = await axios.get(`${CMS_BE_URL}/teachers/${userId}`);

    setUserCerts(data.certificates);
  };

  const getCertificates = async () => {
    const { data } = await axios.get(`${CMS_BE_URL}/certificates`);

    let certTypes: any = [];
    data.certificates.map((c: any) => {
      c.variations.map((v: any) => {
        certTypes.push({ title: c.title, ...v });
      });
    });

    setCertificates(certTypes);

    //remove certificates that the user already has from the options
    certTypes = certTypes.filter(
      (c: any) => !userCerts.find((r: any) => r.id === c.id)
    );

    setOptions(
      certTypes.map((m: any) => ({
        value: m.id,
        label: `${m.title} - ${m.badge} - ${m.code}`,
      }))
    );
  };

  useEffect(() => {
    getUserCerts();
  }, []);

  useEffect(() => {
    getCertificates();
  }, [userCerts]);

  const addCertificate = async () => {
    if (
      certificateId === "" ||
      locationRef.current?.value === "" ||
      date === ""
    ) {
      if (certificateId === "") setCertificateError(true);
      if (locationRef.current?.value === "") setRegionError(true);
      if (date === "") setDateError(true);
      return;
    }

    const data = {
      certificateVariationId: certificateId,
      place: locationRef.current?.value,
      note: notesRef.current?.value,
      obtained_at: `${date.getUTCFullYear()}/${date.getUTCMonth() + 1}/${
        date.getUTCDate() + 1
      }`,
    };
    setLoading(true);
    try {
      const res = await axios.post(
        `${CMS_BE_URL}/teachers/${userId}/accreditation`,
        data
      );
      setOpenCreateModal(false);

      getUserCerts();
      setCertificateError(false);
      setRegionError(false);
      setDateError(false);

      setDate("");
    } catch (err: any) {
      if (err.response?.data?.errors?.[0]?.message) {
        toast.error(err.response?.data?.errors?.[0]?.message);
      } else {
        toast.error(err.response?.data?.message);
      }
    }
    setLoading(false);
  };

  const editCertificate = async () => {
    if (locationRef.current?.value === "" || date === "") {
      if (locationRef.current?.value === "") setRegionError(true);
      if (date === "") setDateError(true);
      return;
    }

    const data = {
      certificateVariationId: certificateId,
      place: locationRef.current?.value,
      note: notesRef.current?.value,
      obtained_at: `${date.getUTCFullYear()}/${date.getUTCMonth() + 1}/${
        date.getUTCDate() + 1
      }`,
    };
    setLoading(true);
    try {
      const res = await axios.put(
        `${CMS_BE_URL}/teachers/${userId}/accreditation/${certToEdit.id}`,
        data
      );
      setOpenEditModal(false);

      getUserCerts();
      setCertificateError(false);
      setRegionError(false);
      setDateError(false);

      setDate("");
    } catch (err: any) {
      if (err.response?.data?.errors?.[0]?.message) {
        toast.error(err.response?.data?.errors?.[0]?.message);
      } else {
        toast.error(err.response?.data?.message);
      }
    }
    setLoading(false);
  };

  const columns: Array<Column<Record<string, any>>> = useMemo(
    () => getCertsColumns(lang),
    [lang]
  );
  const data: Array<any> = useMemo(() => {
    return userCerts.map((cert: any, i: number) => {
      return {
        col1: (
          <div className="flex items-center">
            <div className="w-6 2xl:w-8 h-6 2xl:h-8 rounded-full">
              <p>{cert.code}</p>
            </div>
          </div>
        ),
        col2: (
          <div className="flex items-center h-full truncate">{cert.title}</div>
        ),
        col3: (
          <div className="flex items-center h-full truncate">
            {format(new Date(cert.accreditation.obtained_at), "dd/MM/yyyy")}
          </div>
        ),
        col4: (
          <div className="flex items-center h-full">
            <span>{cert.badge}</span>
          </div>
        ),
        col5: (
          <div className="flex items-center h-full">
            <span>{cert.accreditation.place}</span>
          </div>
        ),
        col6: (
          <div className="flex items-center w-full h-full truncate">
            <span>{cert.accreditation.note}</span>
          </div>
        ),

        col7: (
          <div className="relative flex items-center h-full">
            <button
              className="ml-5"
              id={`portal-button-${i}`}
              type="button"
              onClick={(e) => {
                return setPortal((state: any) => {
                  const thisButton = document.getElementById(
                    `portal-button-${i}`
                  );
                  const position = thisButton?.getBoundingClientRect();
                  return {
                    open: !state.open,
                    top: Number(position?.bottom),
                    left: Number(position?.left),
                    entity: cert,
                  };
                });
              }}
            >
              <DotsVerticalIcon className="w-5 h-5" />
            </button>
          </div>
        ),
      };
    });
  }, [userCerts, lang]);

  const tableInstance = useTable(
    {
      columns,
      data,
      manualSortBy: true,
    },
    useFlexLayout
  );

  const handleModal = (c: any) => {
    setCertToDelete(c);
    setOpenModal(true);
  };

  const handleEditModal = (c: any) => {
    setDate(new Date(c?.accreditation.obtained_at));
    setCertToEdit(c);
    setOpenEditModal(true);
  };

  function portalContent() {
    if (portal.entity === null) return;
    return (
      <div
        className="fixed z-50 flex flex-col gap-2 p-2 bg-white rounded-sm shadow-md"
        style={{
          top: portal.top,
          left: portal.left,
        }}
      >
        <button
          className="flex gap-2 items-center text-table-edit"
          onClick={() => handleEditModal(portal.entity)}
        >
          <PencilIcon className="w-5 h-5" />
          {lang === "en" ? "Edit" : "Modificare"}
        </button>

        <button
          onClick={() => handleModal(String(portal.entity?.id))}
          className="flex gap-2 items-center text-users-delete"
        >
          <TrashIcon className="w-5 h-5" />
          {lang === "en" ? "Delete" : "Eliminare"}
        </button>
      </div>
    );
  }

  const handleRemoveCert = async (cert: any) => {
    setLoading(true);
    try {
      const res = await axios.delete(
        `${CMS_BE_URL}/teachers/${userId}/accreditation/${cert}`
      );
      getUserCerts();
      getCertificates();
    } catch (err: any) {
      if (err.response?.data?.errors?.[0]?.message) {
        toast.error(err.response?.data?.errors?.[0]?.message);
      } else {
        toast.error(err.response?.data?.message);
      }
    }
    setOpenModal(false);
    setLoading(false);
  };

  return (
    <div className="h-full">
      <h3 className="mb-2 text-lg font-bold">{t("user.certificates")}</h3>
      <p className="text-mgray-300 mb-4">{t("user.certificatesDescription")}</p>

      <button
        onClick={() => setOpenCreateModal(true)}
        className="my-4 px-4 py-2 text-white text-sm bg-users-create"
        type="button"
      >
        {t("user.add")} {t("user.certificates")}
      </button>

      <div className="my-10">
        {userCerts.length === 0 ? (
          <div>{t("user.noCertificates")}</div>
        ) : (
          <>
            <Table {...tableInstance} />
            <TablePopup
              isOpen={portal.open}
              close={() => setPortal((state) => ({ ...state, open: false }))}
            >
              {portalContent()}
            </TablePopup>
            <Modal
              openStatus={openModal}
              setOpen={setOpenModal}
              className=""
              icon={
                <ExclamationIcon
                  className="w-6 h-6 text-red-600 bg-transparent"
                  aria-hidden="true"
                />
              }
              header={<p>{t("CertificateCRUD.certRemove")}</p>}
              title={<p>{t("user.removeCertificate")}</p>}
              footer={
                <div className="mt-5 sm:flex sm:flex-row-reverse sm:mt-4">
                  <button
                    type="button"
                    className="inline-flex justify-center px-4 py-1 w-full text-white text-base font-medium bg-red-600 hover:bg-red-700 border border-transparent focus:outline-none shadow-sm focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={async () => await handleRemoveCert(certToDelete)}
                  >
                    {t("user.remove")} {t("user.certificate")}
                  </button>
                  <button
                    type="button"
                    className="inline-flex justify-center mt-3 px-4 py-1 w-full hover:text-gray-500 text-gray-700 text-base font-medium bg-white border border-gray-300 focus:outline-none shadow-sm focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={() => setOpenModal(false)}
                  >
                    {t("user.cancel")}
                  </button>
                </div>
              }
            />
          </>
        )}
        <CreateModal
          openStatus={openCreateModal}
          setOpen={setOpenCreateModal}
          className=""
          header={
            <p className="mt-4 text-xl">{t("CertificateCRUD.addCert")}</p>
          }
          title={
            <div className="flex flex-col gap-4">
              <div className="flex gap-4">
                <div className="w-1/2">
                  <label
                    className={`text-gray-900 text-base font-medium
                      ${certificateError ? "block text-red-500" : "block"}
                    `}
                  >
                    Certificate*
                  </label>
                  <Select
                    styles={getReactSelectStyles(false)}
                    placeholder="Select certificate.."
                    onChange={(s: any) => setCertificateId(s?.value)}
                    options={options}
                  />
                </div>
                <div className="w-1/2">
                  <label
                    className={`text-gray-900 text-base font-medium
                      ${dateError ? "block text-red-500" : "block"}
                    `}
                  >
                    Date of acquisition*
                  </label>
                  <DatePicker
                    selected={date}
                    onChange={(date: Date) => setDate(date)}
                    dateFormat="dd/MM/yyyy"
                    className="mt-1 py-2 w-full text-gray-500 border focus:border-global-input-focus border-gray-300 focus:ring-0"
                    placeholderText="Obtained at.."
                    showPopperArrow={false}
                    autoComplete="off"
                  />
                </div>
              </div>
              <div className="flex gap-2">
                <div className="w-full">
                  <label
                    className={`text-gray-900 text-base font-medium
                      ${regionError ? "block text-red-500" : "block"}
                    `}
                  >
                    Region*
                  </label>
                  <input
                    ref={locationRef}
                    placeholder="Add region..."
                    className="mt-1 py-2 w-full text-gray-500 border focus:border-global-input-focus border-gray-300 focus:ring-0"
                    type="text"
                  />
                </div>
              </div>
              <div className="w-full">
                <label
                  className={`text-gray-900 text-base font-medium
                    
                    `}
                >
                  Notes
                </label>
                <textarea
                  defaultValue={certToEdit?.accreditation.note}
                  ref={notesRef}
                  placeholder="Notes"
                  className="mt-1 py-2 w-full text-gray-500 border focus:border-global-input-focus border-gray-300 focus:ring-0"
                />
              </div>
            </div>
          }
          footer={
            <div className="mt-5 sm:flex sm:flex-row-reverse sm:mt-4">
              <button
                type="button"
                className="inline-flex justify-center px-4 py-1 w-full text-white text-base font-medium bg-users-create border border-transparent focus:outline-none shadow-sm focus:ring-2 focus:ring-users-create focus:ring-offset-2 sm:ml-3 sm:w-auto"
                onClick={addCertificate}
              >
                {t("CertificateCRUD.addCert")}
              </button>
              <button
                type="button"
                className="inline-flex justify-center mt-3 px-4 py-1 w-full hover:text-gray-500 text-gray-700 text-base font-medium bg-white border border-gray-300 focus:outline-none shadow-sm focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto"
                onClick={() => {
                  setOpenCreateModal(false);
                  setCertificateError(false);
                  setRegionError(false);
                  setDateError(false);
                }}
              >
                {t("UserCRUD.cancelButton")}
              </button>
            </div>
          }
        />
        <CreateModal
          openStatus={openEditModal}
          setOpen={setOpenEditModal}
          className=""
          header={
            <p className="mt-4 text-xl">{t("CertificateCRUD.editCert")}</p>
          }
          title={
            <div className="flex flex-col gap-4">
              <div className="flex gap-4">
                <div className="w-1/2">
                  <label
                    className={`text-gray-900 text-base font-medium
                      ${certificateError ? "block text-red-500" : "block"}
                    `}
                  >
                    Certificate{" "}
                  </label>
                  <input
                    className="mt-1 py-2 w-full text-gray-500 bg-gray-300 border focus:border-global-input-focus border-gray-300 focus:ring-0"
                    type="text"
                    disabled={true}
                    defaultValue={
                      certToEdit?.title +
                      ", " +
                      certToEdit?.code +
                      ", " +
                      certToEdit?.badge
                    }
                  />
                </div>
                <div className="w-1/2">
                  <label
                    className={`text-gray-900 text-base font-medium
                      ${dateError ? "block text-red-500" : "block"}
                    `}
                  >
                    Date of acquisition
                  </label>
                  <DatePicker
                    selected={date}
                    onChange={(date: Date) => setDate(date)}
                    dateFormat="dd/MM/yyyy"
                    className="mt-1 py-2 w-full text-gray-500 border focus:border-global-input-focus border-gray-300 focus:ring-0"
                    placeholderText="Obtained at.."
                    showPopperArrow={false}
                    autoComplete="off"
                  />
                </div>
              </div>
              <div className="flex gap-4">
                <div className="w-full">
                  <label
                    className={`text-gray-900 text-base font-medium
                      ${regionError ? "block text-red-500" : "block"}
                    `}
                  >
                    Region*
                  </label>

                  <input
                    ref={locationRef}
                    defaultValue={certToEdit?.accreditation.place}
                    placeholder="Add region..."
                    className="mt-1 py-2 w-full text-gray-500 border focus:border-global-input-focus border-gray-300 focus:ring-0"
                    type="text"
                  />
                </div>
              </div>
              <div className="w-full">
                <label
                  className={`text-gray-900 text-base font-medium
                    
                    `}
                >
                  Notes
                </label>
                <textarea
                  defaultValue={certToEdit?.accreditation.note}
                  ref={notesRef}
                  placeholder="Notes"
                  className="mt-1 py-2 w-full text-gray-500 border focus:border-global-input-focus border-gray-300 focus:ring-0"
                />
              </div>
            </div>
          }
          footer={
            <div className="mt-5 sm:flex sm:flex-row-reverse sm:mt-4">
              <button
                type="button"
                className="inline-flex justify-center px-4 py-1 w-full text-white text-base font-medium bg-users-create border border-transparent focus:outline-none shadow-sm focus:ring-2 focus:ring-users-create focus:ring-offset-2 sm:ml-3 sm:w-auto"
                onClick={editCertificate}
              >
                {t("CertificateCRUD.editCert")}
              </button>
              <button
                type="button"
                className="inline-flex justify-center mt-3 px-4 py-1 w-full hover:text-gray-500 text-gray-700 text-base font-medium bg-white border border-gray-300 focus:outline-none shadow-sm focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto"
                onClick={() => {
                  setOpenEditModal(false);
                  setCertificateError(false);
                  setRegionError(false);
                  setDateError(false);
                  setDate("");
                }}
              >
                {t("UserCRUD.cancelButton")}
              </button>
            </div>
          }
        />
      </div>
    </div>
  );
};

export default CertificatesTab;
