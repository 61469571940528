import { CMS_BE_URL } from "api/urls";
import { NewsFiltersType } from "pages/News/types";
import { MassUpdateNewsDtoExclude, MassUpdateNewsDtoInclude } from "./news.api";

export type UpdateNewsFilters = Omit<
  NewsFiltersType,
  "pageIndex" | "pageSize" | "sortCol" | "sortOrder"
>;

type LogicalQueryParameter = Partial<{
  in: string[];
  notIn: string[];
}>;

function handleLogicalQueryParameter(
  name: string,
  filters: LogicalQueryParameter
) {
  if (filters["in"]) return `${name}[in]=${JSON.stringify(filters["in"])}`;
  else if (filters["notIn"])
    return `${name}[notIn]=${JSON.stringify(filters["notIn"])}`;
  else return "";
}

function constructNewsEnchancedQuery(
  filters: UpdateNewsFilters | NewsFiltersType
) {
  return Object.entries(filters)
    .filter(([, value]) => !!value)
    .map(([key, value]) => {
      if (key == "from")
        return handleLogicalQueryParameter("from", value as any);
      else if (key == "to")
        return handleLogicalQueryParameter("to", value as any);
      else if (value) return `${key}=${value}`;
    })
    .join("&");
}

export const createNewURL = (): string => `${CMS_BE_URL}/news`;
export function getNewsURL(filters: {
  user?: string;
  featured?: string;
  tags?: string;
  visible?: string;
  pageSize?: number;
  pageIndex?: number;
  sortCol?: string;
  sortOrder?: string;
  searchString?: string;
}): string {
  const q: string[] = [];
  Object.entries(filters).forEach(([key, value]) => {
    if (value) q.push(`${key}=${value}`);
  });
  return `${CMS_BE_URL}/news?`.concat(q.join("&"));
}
export const updateNewURL = (newId: string): string =>
  `${CMS_BE_URL}/news/${newId}`;
export const deleteNewURL = (newId: string): string =>
  `${CMS_BE_URL}/news/${newId}`;
export const getNewURL = (newId: string): string =>
  `${CMS_BE_URL}/news/${newId}`;

export const constructUpdateNewsURL = (
  filters:
    | MassUpdateNewsDtoInclude["filters"]
    | MassUpdateNewsDtoExclude["filters"]
) => {
  return `${CMS_BE_URL}/news?${constructNewsEnchancedQuery(filters)}`;
};

export const constructDeleteNewsURL = (
  filters:
    | MassUpdateNewsDtoInclude["filters"]
    | MassUpdateNewsDtoExclude["filters"]
) => {
  return `${CMS_BE_URL}/news/massDelete?${constructNewsEnchancedQuery(
    filters
  )}`;
};
