import React from "react";
import axios from "axios";
import { Lang } from "shared/components/UI/Message";
import { OrderStatusEnum } from "shared/interfaces/Orders.interface";
import {
  constructDeleteOrdersURL,
  constructDeleteOrderURL,
  constructExportOrdersURL,
  constructExportOrderUrl,
  constructGetOrdersURL,
  constructGetOrderURL,
  constructUpdateOrdersURL,
  constructUpdateOrderURL,
  createOrderURL,
  ExportOrdersFilters,
  GetOrdersFilters,
} from "./urls";

export type BillingAddress = {
  country?: string;
  region?: string;
  province?: string;
  city?: string;
  postal_code?: number;
  street_name?: string;
  street_number?: number;
  longitude?: number;
  latitude?: number;
};

export type ShippingAddress = {
  country?: string;
  region?: string;
  province?: string;
  city?: string;
  postal_code?: number;
  street_name?: string;
  street_number?: number;
  longitude?: number;
  latitude?: number;
};

export type Product = {
  productId: string;
  quantity: number;
};

export type CreateOrderDto = {
  customer_id?: string;
  status?: OrderStatusEnum;
  billing_address?: BillingAddress;
  shipping_address?: ShippingAddress;
  products?: {
    variant_id: string;
    quantity: number;
    price: number;
    vat_percentage: number;
  }[];
  coupon?: string;
  shipping?: number;
  requestType?: "CMS";
  stripeFees?: number;
  notes: string | null;
};

export type UpdateOrderDto = {
  status?: OrderStatusEnum;
  billing_address?: BillingAddress;
  shipping_address?: ShippingAddress;
  products?: {
    variant_id: string;
    quantity: number;
  }[];
  coupon?: string;
  shipping?: number;
  requestType?: "CMS";
  notes: string | null;
};

export type MassUpdateOrdersDtoBase = {
  filters: GetOrdersFilters;
  values: Pick<UpdateOrderDto, "status">;
};

export type MassUpdateOrdersDtoInclude = MassUpdateOrdersDtoBase & {
  include: string[];
};

export type MassUpdateOrdersDtoExclude = MassUpdateOrdersDtoBase & {
  exclude: string[];
};

export type MassOrdersFiltersDto = {
  filters: ExportOrdersFilters;
};

export type MassOrdersFiltersDtoInclude = MassOrdersFiltersDto & {
  include: string[];
};

export type MassOrdersFiltersDtoExclude = MassOrdersFiltersDto & {
  exclude: string[];
};

export const createOrder = (createOrderDto: CreateOrderDto) =>
  axios.post(createOrderURL, createOrderDto);

export const getOrders = (filters: GetOrdersFilters) =>
  axios.get(constructGetOrdersURL(filters));

export const getOrder = (orderId: string) =>
  axios.get(constructGetOrderURL(orderId));

export const exportOrder = (orderId: string) =>
  axios.get(constructExportOrderUrl(orderId));

export const updateOrder = (orderId: string, updateOrderDto: UpdateOrderDto) =>
  axios.put(constructUpdateOrderURL(orderId), updateOrderDto);

function isMassUpdateOrdersDtoInclude(
  data: any
): data is MassUpdateOrdersDtoInclude {
  return !!data.include;
}

function isMassUpdateOrdersDtoExclude(
  data: any
): data is MassUpdateOrdersDtoExclude {
  return !!data.exclude;
}

export const updateOrders = (
  data: MassUpdateOrdersDtoInclude | MassUpdateOrdersDtoExclude
) => {
  if (isMassUpdateOrdersDtoInclude(data)) {
    return axios.put(constructUpdateOrdersURL(data.filters), {
      values: data.values,
      include: data.include,
    });
  } else {
    return axios.put(constructUpdateOrdersURL(data.filters), {
      values: data.values,
      exclude: data.exclude,
    });
  }
};

export const deleteOrder = (orderId: string) =>
  axios.delete(constructDeleteOrderURL(orderId));

export const deleteOrders = (
  data: MassOrdersFiltersDtoInclude | MassOrdersFiltersDtoExclude
) => {
  if (isMassUpdateOrdersDtoInclude(data)) {
    return axios.put(constructDeleteOrdersURL(data.filters), {
      include: data.include,
    } as any);
  }
  if (isMassUpdateOrdersDtoExclude(data)) {
    return axios.put(constructDeleteOrdersURL(data.filters), {
      exclude: data.exclude,
    } as any);
  }
  return Promise.reject(undefined);
};

export const exportOrders = (
  data: MassOrdersFiltersDtoInclude | MassOrdersFiltersDtoExclude
) => {
  if (isMassUpdateOrdersDtoInclude(data)) {
    return axios.put(constructExportOrdersURL(data.filters), {
      include: data.include,
    } as any);
  }
  if (isMassUpdateOrdersDtoExclude(data)) {
    return axios.put(constructExportOrdersURL(data.filters), {
      exclude: data.exclude,
    } as any);
  }
  return Promise.reject(undefined);
};

export const getOrdersColumns = (
  {
    allSelected,
    setAllSelected,
    excludeOrders,
    setExcludeOrders,
    includeOrders,
    setIncludeOrders,
  }: any,
  lang: Lang
) => {
  return [
    {
      Header: (
        <input
          type="checkbox"
          checked={allSelected}
          onClick={() => {
            setAllSelected((state: any) => !state);
            setExcludeOrders([]);
            setIncludeOrders([]);
          }}
          className="text-primary"
        />
      ),
      accessor: "checkbox",
      width: "30",
      disableSortBy: true,
    },
    {
      Header: lang === "en" ? "Date" : "Data",
      accessor: "col1",
      width: "150",
      id: "updated_at",
    },
    {
      Header: lang === "en" ? "Order Number" : "Ordini Numero",
      accessor: "col2",
      width: "200",
      id: "yearly_id",
    },
    {
      Header: lang === "en" ? "User" : "Utente",
      accessor: "col3",
      width: "200",
      id: "fullname",
    },
    {
      Header: lang === "en" ? "class" : "classe",
      accessor: "col4",
      width: "200",
      disableSortBy: true,
    },
    {
      Header: lang === "en" ? "status" : "stato",
      accessor: "col5",
      width: "250",
      id: "status",
    },
    {
      Header: lang === "en" ? "total price" : "prezzo totale",
      accessor: "col6",
      width: "250",
      id: "subtotal",
    },
    {
      Header: lang === "en" ? "payment type" : "tipo di pagamento",
      accessor: "col7",
      width: "150",
      id: "payment_type",
    },
    {
      Header: lang === "en" ? "origin" : "origine",
      accessor: "col8",
      width: "100",
      id: "origin",
    },
    {
      Header: lang === "en" ? "actions" : "azioni",
      accessor: "col9",
      width: "150",
      id: "actions",
      disableSortBy: true,
    },
  ];
};
