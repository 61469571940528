import { CMS_BE_URL } from "api/urls";

// Roles URLs
export const createRoleURL = (): string =>
  `${CMS_BE_URL}/permissionsManagement/roles`;
export const getRolesURL = (): string =>
  `${CMS_BE_URL}/permissionsManagement/roles`;
export const updateRoleURL = (roleID: number): string =>
  `${CMS_BE_URL}/permissionsManagement/roles/${roleID}`;
export const deleteRoleURL = (roleID: number): string =>
  `${CMS_BE_URL}/permissionsManagement/roles/${roleID}`;

// Permissions URLs
export const createPermissionURL = (): string =>
  `${CMS_BE_URL}/permissionsManagement/permissions`;
export const getPermissionsURL = (): string =>
  `${CMS_BE_URL}/permissionsManagement/permissions`;
export const updatePermissionURL = (permissionName: string): string =>
  `${CMS_BE_URL}/permissionsManagement/permissions/${permissionName}`;
export const deletePermissionURL = (permissionName: string): string =>
  `${CMS_BE_URL}/permissionsManagement/permissions/${permissionName}`;

// RolesPermissions URLs
export const createRolePermissionURL = (): string =>
  `${CMS_BE_URL}/permissionsManagement`;
export const getRolePermissionsURL = (roleID: number): string =>
  `${CMS_BE_URL}/permissionsManagement/${roleID}`;
export const updateRolePermissionURL = (roleID: number): string =>
  `${CMS_BE_URL}/permissionsManagement/${roleID}`;
export const deleteRolePermissionURL = (
  roleName: string,
  permissionName: string
): string =>
  `${CMS_BE_URL}/permissionsManagement/${roleName}/${permissionName}`;
export const getRolesPermissionsURL = (): string =>
  `${CMS_BE_URL}/permissionsManagement`;
export const deleteRolePermissionsURL = (roleID: number): string =>
  `${CMS_BE_URL}/permissionsManagement/${roleID}`;
