import axios, { AxiosResponse } from "axios";
import { placeAutocompleteURL, placeDetailsURL } from "./urls";

export const placeAutocomplete = (
  input: string,
  types?: string
): Promise<AxiosResponse<any, any>> => {
  return axios.get(placeAutocompleteURL(input, types));
};

export const placeDetails = (
  placeid: string
): Promise<AxiosResponse<any, any>> => {
  return axios.get(placeDetailsURL(placeid));
};
