import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { DisplayIf } from "shared/components/UI/DisplayError";
import DisplayInputError from "shared/components/UI/DisplayInputError";
import SimpleImageDropzone from "shared/components/UI/SimpleImageDropzone";
import Message from "shared/components/UI/Message";
import {
  getAboutUsThunk,
  selectAboutUs,
  updateAboutUsThunk,
} from "store/aboutUsStore/aboutUsReducer";
import { useAppDispatch } from "store/storeHooks";
import { selectTranslationLanguage } from "store/translationStore/translationReducer";
import {
  addStyles,
  constructUpdateInfo,
  getInputStyles,
  getSingleImageConstraintsConfig,
} from "utils/utils";
import { Info } from "./types";
import { useTranslation } from "react-i18next";

export default function InfoPage() {
  const lang = selectTranslationLanguage();
  const { t } = useTranslation("common");
  const dispatch = useAppDispatch();
  const {
    trigger,
    watch,
    setValue,
    getValues,
    clearErrors,
    setError,
    register,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm<Info>({
    mode: "onChange",
    defaultValues: {
      fileType: "about_pic",
      historyImage: null,
      membershipImage: null,
      milestonesImage: null,
    },
  });

  useEffect(function getCoreCommitments() {
    register("historyImage", getSingleImageConstraintsConfig());
    register("membershipImage", getSingleImageConstraintsConfig());
    register("milestonesImage", getSingleImageConstraintsConfig());
    dispatch(getAboutUsThunk({ aboutId: "1" }));
  }, []);

  const aboutUs = selectAboutUs();

  useEffect(
    function setCoreCommitments() {
      if (aboutUs.data.data?.history)
        setValue("history", aboutUs.data.data.history);
      if (aboutUs.data.data?.membership)
        setValue("membership", aboutUs.data.data.membership);
      if (aboutUs.data.data?.milestones)
        setValue("milestones", aboutUs.data.data.milestones);
      if (aboutUs.data.data?.historyImage)
        setValue("historyImage", aboutUs.data.data.historyImage);
      if (aboutUs.data.data?.membershipImage)
        setValue("membershipImage", aboutUs.data.data.membershipImage);
      if (aboutUs.data.data?.milestonesImage)
        setValue("milestonesImage", aboutUs.data.data.milestonesImage);
    },
    [aboutUs.data.data]
  );

  const onSubmit = handleSubmit(function submit(data) {
    dispatch(
      updateAboutUsThunk({ aboutId: "1", data: constructUpdateInfo(data) })
    ).then(function handleRequest(res: any) {
      if (res.meta.requestStatus === "fulfilled") {
        toast.success(
          Message({
            action: "update",
            entity: t("information.information"),
            lang,
          })
        );
      } else {
        toast.error(
          Message({
            action: "update",
            entity: t("information.information"),
            error: t("genericError"),
            lang,
          })
        );
      }
    });
  });

  return (
    <div className="flex flex-col h-full">
      <form onSubmit={onSubmit} className="flex flex-col flex-grow gap-5">
        <div className="flex gap-10 justify-between">
          <h1 className="text-2xl font-bold">{t("information.title")}</h1>
          <button type="submit" className="px-4 py-2 text-white bg-global-save">
            {t("information.save")}
          </button>
        </div>
        <div className="flex flex-col flex-grow gap-5">
          <div className="grid flex-grow gap-5 grid-cols-4">
            <div className="flex flex-col gap-2 col-span-3">
              <label htmlFor="history" className="text-lg font-medium">
                {t("information.historyLabel")}
              </label>
              <textarea
                id="history"
                {...register("history", {
                  validate: {
                    positive: (s) =>
                      s.length <= 1000 ||
                      (t("information.historyLength") as string),
                  },
                })}
                placeholder={t("information.history")}
                className={addStyles(getInputStyles(false), "flex-grow")}
              />
              <DisplayInputError message={errors.history?.message} />
            </div>
            <div className="flex flex-col gap-2 justify-center">
              <h1 className="text-lg font-medium">
                {t("information.historyCover")}
              </h1>
              <SimpleImageDropzone
                clearErrors={clearErrors}
                setError={setError}
                trigger={trigger}
                setValue={setValue}
                watch={watch}
                reactHookFormName="historyImage"
                aspect={16 / 9}
                resize={true}
              />
            </div>
          </div>
          <div className="grid flex-grow gap-5 grid-cols-4">
            <div className="flex flex-col gap-2 col-span-3">
              <label htmlFor="membership" className="text-lg font-medium">
                {t("information.membershipLabel")}
              </label>
              <textarea
                id="membership"
                {...register("membership", {
                  validate: {
                    positive: (s) =>
                      s.length <= 1000 ||
                      (t("information.membershipLength") as string),
                  },
                })}
                placeholder={t("information.membership")}
                className={addStyles(getInputStyles(false), "flex-grow")}
              />
              <DisplayInputError message={errors.membership?.message} />
            </div>
            <div className="flex flex-col gap-2 justify-center">
              <h1 className="text-lg font-medium">
                {t("information.membershipCover")}
              </h1>
              <SimpleImageDropzone
                clearErrors={clearErrors}
                setError={setError}
                trigger={trigger}
                setValue={setValue}
                watch={watch}
                reactHookFormName="membershipImage"
                aspect={16 / 9}
                resize={true}
              />
            </div>
          </div>
          <div className="grid flex-grow gap-5 grid-cols-4">
            <div className="flex flex-col gap-2 col-span-3">
              <label htmlFor="milestones" className="text-lg font-medium">
                {t("information.milestonesLabel")}
              </label>
              <textarea
                id="milestones"
                {...register("milestones", {
                  validate: {
                    positive: (s) =>
                      s.length <= 1000 ||
                      (t("information.milestonesLength") as string),
                  },
                })}
                placeholder={t("information.milestones")}
                className={addStyles(getInputStyles(false), "flex-grow")}
              />
              <DisplayInputError message={errors.milestones?.message} />
            </div>
            <div className="flex flex-col gap-2 justify-center">
              <h1 className="text-lg font-medium">
                {t("information.milestonesCover")}
              </h1>
              <SimpleImageDropzone
                clearErrors={clearErrors}
                setError={setError}
                trigger={trigger}
                setValue={setValue}
                watch={watch}
                reactHookFormName="milestonesImage"
                aspect={16 / 9}
                resize={true}
              />
              <DisplayIf condition={!!errors?.milestonesImage}>
                <div className="text-center text-red-500">
                  {errors?.milestonesImage?.message}
                </div>
              </DisplayIf>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
