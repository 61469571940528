import axios from "axios";
import { deleteFileURL } from "./urls";

export type DeleteFileType = {
  fileId: string;
  location: string;
};

export const deleteFile = (file: DeleteFileType) => {
  return axios.post(deleteFileURL(), file);
};
