import React, { useState } from "react";
import Overlay from "shared/components/UI/Overlay";
import Spinner from "shared/components/UI/Spinner";
import { selectAboutUs } from "store/aboutUsStore/aboutUsReducer";
import { selectCouncils } from "store/councilsStore/councilsReducer";
import CoreCommitments from "./CoreCommitments";
import BoardOfDirectorsPage from "./BoardOfDirectors";
import Info from "./Info";
import NationalCouncil from "./NationalCouncil";
import RegionalCouncil from "./RegionalCouncilMap";
import { useSearchParams } from "react-router-dom";
import { selectTranslationLanguage } from "store/translationStore/translationReducer";
import { useTranslation } from "react-i18next";

import NationalPresident from "./NationalPresident";

function AboutUs() {
  const [searchParams] = useSearchParams();
  const lang = selectTranslationLanguage();
  const { t } = useTranslation("common");
  const sections = [
    {
      title: t("aboutus.coreCommitments"),
      component: CoreCommitments,
    },
    {
      title: t("aboutus.information"),
      component: Info,
    },
    {
      title: t("aboutus.nationalPresident"),
      component: NationalPresident,
    },
    {
      title: t("aboutus.boardOfDirectors"),
      component: BoardOfDirectorsPage,
    },
    {
      title: t("aboutus.nationalCouncil"),
      component: NationalCouncil,
    },
    {
      title: t("aboutus.regionalCouncil"),
      component: RegionalCouncil,
    },
  ];
  const [section, setSection] = useState(
    String(searchParams.get("redirect")) === "regionalCouncil"
      ? sections[5]
      : sections[0]
  );
  const councils = selectCouncils();
  const aboutUs = selectAboutUs();
  return (
    <Overlay active={councils.loading || aboutUs.loading} spinner={<Spinner />}>
      <div className="flex flex-col flex-grow gap-5 p-10 h-full">
        <h1 className="text-3xl font-bold">{t("aboutus.aboutus")}</h1>
        <p className="text-global-subHeader text-xl">
          {t("aboutus.description")}
        </p>
        <div className="flex gap-10">
          {sections.map((s, i) => (
            <button
              key={i}
              type="button"
              className={`font-xl pb-2 font-medium ${
                s.title === section.title
                  ? "border-b-2 border-global-border"
                  : ""
              }`}
              onClick={() => {
                setSection(s);
              }}
            >
              {s.title}
            </button>
          ))}
        </div>
        <div className="flex-grow h-full">
          <section.component />
        </div>
      </div>
    </Overlay>
  );
}

export default AboutUs;
