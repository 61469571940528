import LoadingOverlay from "react-loading-overlay";
import React from "react";

const Overlay = ({
  active,
  children,
  spinner,
  overlay = true,
}: {
  active: boolean;
  children: JSX.Element;
  spinner: JSX.Element;
  overlay?: boolean;
}) => {
  return (
    <LoadingOverlay
      active={active}
      className="w-full h-screen overflow-x-auto overflow-y-auto"
      styles={{
        overlay: (base) =>
          overlay
            ? {
                ...base,
                background: "rgba(255, 255, 255, 0.75)",
              }
            : { ...base, background: "rgba(255, 255, 255, 0)" },
        content: (base) => ({
          ...base,
        }),
      }}
      spinner={spinner}
    >
      {children}
    </LoadingOverlay>
  );
};

export default Overlay;
