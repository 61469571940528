import React, { useEffect, useState, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "store/storeHooks";
import {
  Column,
  useFlexLayout,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import Pagination from "shared/components/Table/Pagination";
import Table from "shared/components/Table/Table";
import { User, UserLowercase } from "shared/interfaces/User.interface";
import { useTranslation } from "react-i18next";
import { selectLoggedInUser } from "store/authStore/authReducer";
import Spinner from "shared/components/UI/Spinner";
import Overlay from "shared/components/UI/Overlay";
import { format, setYear } from "date-fns";
import { useForm } from "react-hook-form";
import Select from "react-select";
import {
  calculateDeletionDate,
  capitalizeFirst,
  getInputStyles,
  getReactSelectStyles,
} from "utils/utils";
import "react-datepicker/dist/react-datepicker.css";
import "shared/styles/datePicker.css";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";
import Message from "shared/components/UI/Message";
import { DocumentDownloadIcon, UserCircleIcon } from "@heroicons/react/outline";
import { getUsersThunk, selectUsers } from "store/usersStore/usersReducer";
import { getExportUsersColumns, getUsers } from "api/Users/users.api";
import { selectTranslationLanguage } from "store/translationStore/translationReducer";
import Datepicker from "react-datepicker";
import axios from "axios";
import { CMS_BE_URL } from "api/urls";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import Modal from "shared/components/UI/Modal";
import CreateModal from "shared/components/UI/CreateModal";

const queriesOptions: { value: [string, number, string][]; label: string }[] = [
  {
    value: [["role", 4, "Super-Admins"]],
    label: "Super Admins",
  },
  {
    value: [["role", 1, "Admins"]],
    label: "Admins",
  },
  {
    value: [["role", 2, "Editors"]],
    label: "Editors",
  },
  {
    value: [["role", 5, "Users"]],
    label: "Users",
  },
  {
    value: [["rank", 1, "Teachers"]],
    label: "Teachers",
  },
];

export type ExportDataFilters = {
  filters: [string, number, string][];
  pageSize: number;
  pageIndex: number;
};

const ExportData: () => JSX.Element = () => {
  const {
    register,
    setValue,
    getValues,
    formState: { errors },
    control,
    watch,
  } = useForm<ExportDataFilters>({
    defaultValues: {
      filters: [],
      pageSize: 10,
      pageIndex: 0,
    },
  });
  const { t } = useTranslation("common");
  const users = selectUsers();
  const dispatch = useAppDispatch();
  const loggedInUser = selectLoggedInUser();
  const lang = selectTranslationLanguage();
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [year, setYear] = useState<any>(new Date());

  const columns: Array<Column<Record<string, any>>> = useMemo(
    () => getExportUsersColumns(lang),
    [lang]
  );

  const data: Array<any> = useMemo(() => {
    return users.data.users.map((user: UserLowercase, i) => {
      return {
        col1: (
          <div className="flex items-center">
            <div className="w-6 2xl:w-8 h-6 2xl:h-8 rounded-full">
              {user.profile_pic_url ? (
                <img
                  src={user.profile_pic_url}
                  alt=""
                  className="w-6 2xl:w-8 h-6 2xl:h-8 rounded-full object-cover"
                />
              ) : (
                <UserCircleIcon className="w-6 2xl:w-8 2xl:h-8 text-users-icon rounded-full" />
              )}
            </div>
            <div
              className="flex flex-col pl-4 text-xs"
              style={{ width: "calc(100% - 3rem)" }}
            >
              <span className="truncate">
                {user.name} {user.surname}
              </span>
              <span className="truncate">{user.email}</span>
            </div>
          </div>
        ),
        col2: (
          <div className="flex items-center h-full">
            {user.address_details?.map(
              (det: any) => det.address_type === "HOME" && det.fk_region
            )}
          </div>
        ),
        col3: (
          <div className="flex items-center h-full">
            <span className="truncate">
              {lang === "en" ? user.rank.rank_eng : user.rank.rank_it}
            </span>
          </div>
        ),
        col4: (
          <div className="flex items-center h-full">
            <span
              className={`truncate rounded-full px-2 py-1 ${
                user.status?.toUpperCase() === "ACTIVE"
                  ? "text-users-active bg-users-active-bg"
                  : "text-users-inactive bg-users-inactive-bg"
              }`}
            >
              {user.status?.toUpperCase() === "DELETED"
                ? `Deletion at ${calculateDeletionDate(
                    new Date(user.deleted_at)
                  )}`
                : capitalizeFirst(user.status)}
            </span>
          </div>
        ),
        col5: (
          <div className="flex items-center h-full">
            {user.membership === null || Array.isArray(user.membership) ? (
              <p className="truncate">No active subscription</p>
            ) : (
              <p>
                {format(new Date(user.membership.end_date), "MMMM dd, yyyy")}
              </p>
            )}
          </div>
        ),
        col6: (
          <div className="flex items-center h-full">
            <span className="truncate">{user.role.role_name}</span>
          </div>
        ),
        col7: (
          <div className="flex items-center h-full">
            <span className="truncate">{user.member_status}</span>
          </div>
        ),
        col8: (
          <div className="flex items-center h-full">
            <span className="truncate">{user.email}</span>
          </div>
        ),
        col9: (
          <div className="flex items-center h-full">
            <span className="truncate">
              {format(new Date(user.birth_date as any), "MMMM dd, yyyy")}
            </span>
          </div>
        ),
        col10: (
          <div className="flex items-center h-full">
            <span className="truncate">{user.card_number}</span>
          </div>
        ),
        col11: (
          <div className="flex items-center h-full">
            {user.first_sub_date === null ? (
              <p className="truncate">No active subscription</p>
            ) : (
              <p>{format(new Date(user.first_sub_date), "MMMM dd, yyyy")}</p>
            )}
          </div>
        ),
      };
    });
  }, [users.data.users, lang]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: getValues("pageSize") },
      pageCount: Math.ceil(users.data.total / getValues("pageSize")),
      manualSortBy: true,
      manualPagination: true,
    },
    useSortBy,
    usePagination,
    useFlexLayout
  );

  const {
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, sortBy },
  } = tableInstance;

  function classNames(...classes: any) {
    return classes.filter(Boolean).join(" ");
  }

  function constructGetUsersThunkAction(index: number, pageSize?: number) {
    const filters: any = {
      role: "",
      location: "",
      rank: "",
      status: "",
      from: "",
      to: "",
      pageIndex: index,
      pageSize: pageSize ? pageSize : getValues("pageSize"),
      searchString: "",
      sortCol: sortBy[0] ? sortBy[0].id : "",
      sortOrder: sortBy[0] ? (sortBy[0].desc ? "DESC" : "ASC") : "",
      including: "subscription",
    };
    getValues("filters").forEach(function (filter) {
      filters[filter[0]] = filter[1];
    });
    return filters;
  }

  useEffect(() => {
    dispatch(getUsersThunk(constructGetUsersThunkAction(0)));
  }, [sortBy, watch("filters")]);

  const dispatchNextPage = () => {
    if (canNextPage) {
      dispatch(getUsersThunk(constructGetUsersThunkAction(pageIndex + 1)));
      nextPage();
    }
  };

  const dispatchPreviousPage = () => {
    if (canPreviousPage) {
      dispatch(getUsersThunk(constructGetUsersThunkAction(pageIndex - 1)));
      previousPage();
    }
  };

  const dispatchGotoPage = (pageIndex: number) => {
    dispatch(getUsersThunk(constructGetUsersThunkAction(pageIndex)));
    gotoPage(pageIndex);
  };

  function exportData() {
    setLoading(true);
    getUsers(constructGetUsersThunkAction(0, users.data.total))
      .then((res) => {
        const users = res.data.users;
        const header = columns.map((column) =>
          String(column.Header).toUpperCase()
        );
        const csvData: any = users.map((user: UserLowercase) => [
          `${user.name} ${user.surname}`,
          user.address_details?.[0]?.fk_region
            ? user.address_details[0]?.fk_region
            : "",
          user.rank.rank_it,
          user.status,
          user?.membership?.end_date
            ? format(new Date(user.membership.end_date), "dd/MM/yyyy")
            : "",
          user.role,
          user.member_status,
          user.email,
          user?.birth_date?.slice(0, 10),
          user.card_number,
          user?.membership?.created_at?.slice(0, 10),
        ]);
        const data: any = {
          data: [header, ...csvData],
        };
        const worksheet = XLSX.utils.aoa_to_sheet(data.data);
        const new_workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(new_workbook, worksheet, "SheetJS");
        const filters = getValues("filters");
        XLSX.writeFile(
          new_workbook,
          `${filters.length ? filters[0][2] : "All-Users"}-${format(
            Date.now(),
            "dd/MM/yyyy"
          )}.xlsx`
        );
      })
      .catch((err: any) => {
        if (err.response?.data?.errors?.[0]?.message) {
          toast.error(err.response?.data?.errors?.[0]?.message);
        } else {
          toast.error(err.response?.data?.message);
        }
      })
      .finally(() => setLoading(false));
  }

  function exportYearlyReport() {
    if (year) setLoading2(true);
    axios
      .get(`${CMS_BE_URL}/files/exportReport?year=${year.getFullYear()}`)
      .then((res) => {
        const new_workbook = XLSX.utils.book_new();
        res.data.sheets.map((sheet: any) => {
          const header = Object.keys(sheet.data[0] || []);
          const users = sheet.data || [];
          const csvData: any = users.map((user: any) => Object.values(user));
          const data: any = {
            data: [header, ...csvData],
          };
          const worksheet = XLSX.utils.aoa_to_sheet(data.data);
          XLSX.utils.book_append_sheet(new_workbook, worksheet, sheet.name);
        });
        XLSX.writeFile(new_workbook, `${year.getFullYear()}.xlsx`);
      })
      .catch(
        (err: any) => {
          if (err.response?.data?.errors?.[0]?.message) {
            toast.error(err.response?.data?.errors?.[0]?.message);
          } else {
            toast.error(err.response?.data?.message);
          }
        }
        // toast.error(
        //   Message({
        //     action: "read",
        //     entity: "Data",
        //     error: t("genericError"),
        //     lang,
        //   })
        // )
      )
      .finally(() => setLoading2(false));
  }

  //Modals state
  const [expModal, setExpModal] = useState(false);
  const [yearModal, setYearModal] = useState(false);

  return (
    <Overlay active={users.loading || loading2} spinner={<Spinner />}>
      <div className="users flex flex-col gap-5 p-10 h-full">
        <div className="text-3xl font-bold">{t("sidebar.exportData")}</div>

        <div className="relative flex gap-5 justify-end">
          <div>
            <Menu as="div" className="relative inline-block text-left">
              <div>
                <Menu.Button className="inline-flex justify-center px-4 py-2 w-full text-white font-medium bg-users-create border border-gray-300 rounded-sm focus:outline-none shadow-sm focus:ring-0 focus:ring-offset-0">
                  <DocumentDownloadIcon className="mr-2 w-5 h-5" />{" "}
                  {lang === "en" ? "Export" : "Esportare"}
                  {/* <ChevronDownIcon
                    className="-mr-1 ml-2 w-5 h-5"
                    aria-hidden="true"
                  /> */}
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute z-50 right-0 mt-2 w-56 bg-white rounded-md focus:outline-none shadow-lg origin-top-right ring-1 ring-black ring-opacity-5">
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <div
                          onClick={() => setExpModal(true)}
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block px-4 py-2 hover:text-blue-800 text-sm hover:bg-blue-50 cursor-pointer"
                          )}
                        >
                          {t("exportData.asRole")}
                        </div>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <div
                          onClick={() => setYearModal(true)}
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block px-4 py-2 hover:text-blue-800 text-sm hover:bg-blue-50 cursor-pointer"
                          )}
                        >
                          {t("massUpdate.exy")}
                        </div>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
        <div className="flex gap-5 justify-end"></div>

        <Table {...tableInstance} />
        <Pagination
          rowsCount={users.data.total}
          pageIndex={pageIndex}
          pageSize={getValues("pageSize")}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          pageOptions={pageOptions}
          pageCount={pageCount}
          gotoPage={dispatchGotoPage}
          nextPage={dispatchNextPage}
          previousPage={dispatchPreviousPage}
          setPageSize={setPageSize}
        />
        <CreateModal
          openStatus={expModal}
          setOpen={setExpModal}
          className=""
          // icon={
          //   <ExclamationIcon
          //     className="w-6 h-6 text-red-600 bg-transparent"
          //     aria-hidden="true"
          //   />
          // }
          header={<p className="mb-10">{t("exportData.asRole")}</p>}
          title={
            <span className="relative z-20 w-full">
              <label>{lang === "en" ? "Role" : "Ruolo"}</label>
              <Select
                placeholder={
                  lang === "en" ? "Please select a role" : "Seleziona un ruolo"
                }
                styles={getReactSelectStyles(false)}
                inputId="region"
                isClearable={true}
                options={queriesOptions}
                onChange={(e) => {
                  if (e) setValue("filters", e.value);
                  else setValue("filters", []);
                }}
              />
            </span>
          }
          footer={
            <div className="mt-5 sm:flex sm:flex-row-reverse sm:mt-4">
              {(loggedInUser.permissions["*"] ||
                loggedInUser.permissions?.users?.CREATE === "ANY") && (
                <button
                  type="button"
                  className="flex gap-2 items-center justify-center px-4 py-2 text-white bg-global-createEntity border border-global-createEntity"
                  onClick={() => exportData()}
                  disabled={loading}
                >
                  <DocumentDownloadIcon className="w-5 h-5" />
                  <span className="whitespace-nowrap">
                    {loading ? "Exporting..." : "Export"}
                  </span>
                </button>
              )}
            </div>
          }
        />
        <CreateModal
          openStatus={yearModal}
          setOpen={setYearModal}
          className=""
          // icon={
          //   <ExclamationIcon
          //     className="w-6 h-6 text-red-600 bg-transparent"
          //     aria-hidden="true"
          //   />
          // }
          header={<p className="mb-10">{t("massUpdate.exy")}</p>}
          title={
            <>
              <div className="w-full">
                <label>Year</label>
                <Datepicker
                  placeholderText="Seleziona anno.."
                  selected={year}
                  className={getInputStyles(year > new Date())}
                  onChange={(date) => setYear(date)}
                  showYearPicker
                  dateFormat="yyyy"
                />
              </div>
            </>
          }
          footer={
            <div className="mt-5 sm:flex sm:flex-row-reverse sm:mt-4">
              {(loggedInUser.permissions["*"] ||
                loggedInUser.permissions?.users?.CREATE === "ANY") && (
                <button
                  type="button"
                  className="flex gap-2 items-center justify-center px-4 py-2 text-white bg-global-createEntity border border-global-createEntity"
                  onClick={() => exportYearlyReport()}
                  disabled={loading2 || year > new Date() || !year}
                >
                  <DocumentDownloadIcon className="w-5 h-5" />
                  <span className="whitespace-nowrap">
                    {loading2 ? "Loading..." : t("massUpdate.exy")}
                  </span>
                </button>
              )}
            </div>
          }
        />
      </div>
    </Overlay>
  );
};

export default ExportData;
