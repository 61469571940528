import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createEvent,
  deleteEvent,
  getEvent,
  getEvents,
  updateEvent,
} from "api/Events/events.api";
import { CreateEvent } from "shared/interfaces/Event.interface";
import { useAppSelector } from "store/storeHooks";
import { eventsInitialState } from "./eventsState";
import { FileAWS } from "shared/interfaces/File.interface";
import { deleteFile } from "api/Files/files.api";

export const createEventThunk = createAsyncThunk(
  "eventsStore/createEvent",
  async (event: CreateEvent) => {
    await createEvent(event);
  }
);

export const getEventsThunk = createAsyncThunk(
  "eventsStore/getEvents",
  async ({
    from,
    to,
    region,
    tags,
    visible,
    pageSize,
    pageIndex,
    sortCol,
    sortOrder,
    searchString,
  }: {
    from: string;
    to: string;
    region: string;
    tags: string;
    visible: string;
    pageSize: number;
    pageIndex: number;
    sortCol: string;
    sortOrder: string;
    searchString: string;
  }) => {
    const response = await getEvents({
      from,
      to,
      region,
      tags,
      visible,
      pageSize,
      pageIndex,
      sortCol,
      sortOrder,
      searchString,
    });
    return response.data;
  }
);

export const updateEventThunk = createAsyncThunk(
  "eventsStore/updateEvent",
  async ({
    eventId,
    event,
    filesToDelete,
  }: {
    eventId: string;
    event: CreateEvent;
    filesToDelete?: FileAWS[];
  }) => {
    if (filesToDelete) {
      await Promise.all(
        filesToDelete.map((file) =>
          deleteFile({
            fileId: file.file_id,
            location: file.url,
          })
        )
      );
    }
    await updateEvent(eventId, event);
  }
);

export const deleteEventThunk = createAsyncThunk(
  "eventsStore/deleteEvent",
  async ({ eventId }: { eventId: string }) => {
    await deleteEvent(eventId);
  }
);

export const getEventThunk = createAsyncThunk(
  "eventsStore/getEvent",
  async (eventId: string) => {
    const response = await getEvent(eventId);
    return response.data.data;
  }
);

const eventsSlice = createSlice({
  name: "eventsStore",
  initialState: eventsInitialState,
  reducers: {
    removeSponsorFromEvent(
      state,
      {
        payload: { fileId },
      }: {
        payload: {
          fileId: string;
        };
      }
    ) {
      if (state.data.event) {
        const index = state.data.event.sponsors_logo.findIndex(
          (sponsor) => sponsor.file_id === fileId
        );
        state.data.event.sponsors_logo.splice(index, 1);
      }
    },
    removeFileFromEvent(
      state,
      {
        payload: { fileId },
      }: {
        payload: {
          fileId: string;
        };
      }
    ) {
      if (state.data.event) {
        const index = state.data.event.files.findIndex(
          (file) => file.file_id === fileId
        );
        state.data.event.files.splice(index, 1);
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createEventThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createEventThunk.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createEventThunk.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getEventsThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getEventsThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(getEventsThunk.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(updateEventThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateEventThunk.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(updateEventThunk.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(deleteEventThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteEventThunk.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteEventThunk.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getEventThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getEventThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.data.event = action.payload;
    });
    builder.addCase(getEventThunk.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const selectEvents = () => useAppSelector((state) => state.events);

export default eventsSlice;
