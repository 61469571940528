import { getUser } from "api/Users/users.api";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Message from "shared/components/UI/Message";
import { User } from "shared/interfaces/User.interface";
import { selectTranslationLanguage } from "store/translationStore/translationReducer";

export default function Logs() {
  const { id } = useParams();
  const lang = selectTranslationLanguage();
  const { t } = useTranslation("common");
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (id) {
      getUser(id)
        .then((res) => {
          setUser(res.data);
        })
        .catch((err: any) => {
          if (err.response?.data?.errors?.[0]?.message) {
            toast.error(err.response?.data?.errors?.[0]?.message);
          } else {
            toast.error(err.response?.data?.message);
          }
        })
        .finally(() => setLoading(false));
    }
  }, []);

  return (
    <>
      {user ? (
        <div className="flex flex-col gap-2">
          <>
            <div>
              <span className="font-semibold">{t("user.creationDate")}</span>:{" "}
              {format(
                new Date(user.CREATED_AT as string),
                "dd MMMM yyyy, HH:mm aaa"
              )}
            </div>
            <div>
              <span className="font-semibold">{t("user.editedAt")} </span>
              {format(
                new Date(user.UPDATED_AT as string),
                "dd MMMM yyyy, HH:mm aaa"
              )}{" "}
              {user.LAST_MODIFIER ? (
                <>
                  {t("user.by")}{" "}
                  <span className="font-medium">{user.LAST_MODIFIER}</span>
                </>
              ) : (
                ""
              )}
            </div>
            <div>
              <span className="font-semibold">{t("user.lastLogin")}:</span>{" "}
              {user.LAST_LOGIN_FE
                ? format(
                    new Date(user.LAST_LOGIN_FE.replace(" ", "T")),
                    "dd MMMM yyyy, HH:mm aaa"
                  )
                : "Unkwown"}
            </div>
            <div>
              <span className="font-semibold">{t("user.lastLoginCMS")}</span>{" "}
              {user.LAST_LOGIN_CMS
                ? format(
                    new Date(user.LAST_LOGIN_CMS.replace(" ", "T")),
                    "dd MMMM yyyy, HH:mm aaa"
                  )
                : "Unkwown"}
            </div>
          </>
        </div>
      ) : null}
    </>
  );
}
