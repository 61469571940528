import React from "react";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { useAppSelector } from "store/storeHooks";
import { sponsorsInitialState } from "./sponsorsState";
import { CMS_BE_URL } from "api/urls";
import { toast } from "react-toastify";

export const getSponsorsThunk = createAsyncThunk(
  "sponsorsStore/getSponsors",
  async (filters: {
    user?: string;
    featured?: string;
    tags?: string;
    visible?: string;
    region?: string;
    pageSize?: number;
    pageIndex?: number;
    sortCol?: string;
    sortOrder?: string;
    searchString?: string;
  }) => {
    try {
      //get sponsors

      const q: string[] = [];
      Object.entries(filters).forEach(([key, value]) => {
        if (value) q.push(`${key}=${value}`);
      });
      const res = await axios.get(
        `${CMS_BE_URL}/sponsors?`.concat(q.join("&"))
      );
      return res.data;
    } catch (err: any) {
      if (err.response?.data?.errors?.[0]?.message) {
        toast.error(err.response?.data?.errors?.[0]?.message);
      } else {
        toast.error(err.response?.data?.message);
      }
    }
  }
);

const sponsorsSlice = createSlice({
  name: "sponsorsStore",
  initialState: sponsorsInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSponsorsThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getSponsorsThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(getSponsorsThunk.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const selectSponsors = () => useAppSelector((state) => state.sponsors);

export default sponsorsSlice;
