import axios from "axios";
import {
  constructDeleteProductURL,
  constructGetProductsURL,
  constructGetProductURL,
  constructUpdateProductURL,
  createProductURL,
  GetProductFilters,
} from "./urls";

export type CreateProductDto = {
  sku?: string;
  slug?: string;
  title?: string;
  short_description?: string;
  description?: string;
  variants?: string; // JSON.stringify([])
  fileType?: "product_image";
  media?: File[];
  thumbnail?: string;
  tags?: string; // JSON.stringify([])
};

export type UpdateProductDto = {
  sku?: string;
  slug?: string;
  title?: string;
  short_description?: string;
  description?: string;
  variants?: string; // JSON.stringify([])
  fileType?: string;
  media?: File[];
  thumbnail?: string;
  tags?: string; // JSON.stringify([])
};

export const createProduct = (createProductDto: CreateProductDto) =>
  axios.post(createProductURL, createProductDto);

export const getProducts = (filters: GetProductFilters) =>
  axios.get(constructGetProductsURL(filters));

export const getProduct = (productId: string) =>
  axios.get(constructGetProductURL(productId));

export const updateProduct = (
  productId: string,
  updateProductDto: UpdateProductDto
) => axios.put(constructUpdateProductURL(productId), updateProductDto);

export const deleteProduct = (productId: string) =>
  axios.delete(constructDeleteProductURL(productId));

export const getOrderProductsColumns: (lang: string) => {
  Header: string;
  accessor: string;
  width: string;
  disableSortBy?: boolean;
  id?: string;
}[] = (lang: string) => {
  return [
    {
      Header: lang === "en" ? "Title" : "Titolo",
      accessor: "col1",
      width: "200",
      disableSortBy: true,
    },
    {
      Header: lang === "en" ? "Description" : "Descrizione",
      accessor: "col2",
      width: "150",
      disableSortBy: true,
    },
    {
      Header: lang === "en" ? "Price" : "Prezzo",
      accessor: "col3",
      width: "150",
      disableSortBy: true,
    },
    {
      Header: lang === "en" ? "Vat" : "Iva",
      accessor: "col4",
      width: "150",
      disableSortBy: true,
    },
    {
      Header: lang === "en" ? "Quantity" : "Quantità",
      accessor: "col5",
      width: "150",
      disableSortBy: true,
    },

    {
      Header: lang === "en" ? "Actions" : "Azioni",
      accessor: "col6",
      width: "150",
      disableSortBy: true,
    },
  ];
};

export const getProductsColumns: (lang: string) => {
  Header: string;
  accessor: string;
  width: string;
  disableSortBy?: boolean;
  id?: string;
}[] = (lang: string) => {
  return [
    {
      Header: "",
      accessor: "col0",
      width: "50",
      id: "img",
    },
    {
      Header: lang === "en" ? "Title" : "Titolo",
      accessor: "col1",
      width: "250",
      id: "title",
    },
    {
      Header: lang === "en" ? "Description" : "Descrizione",
      accessor: "col2",
      disableSortBy: true,
      width: "200",
      id: "file",
    },
    {
      Header: lang === "en" ? "Class" : "Classe",
      accessor: "col3",
      disableSortBy: true,
      width: "150",
      id: "class",
    },
    {
      Header: lang === "en" ? "Only subs" : "Solo sub",
      accessor: "col4",
      disableSortBy: true,
      width: "150",
      id: "only_subs",
    },
    {
      Header: lang === "en" ? "Quantity" : "Quantità",
      accessor: "col5",
      disableSortBy: true,
      width: "150",
      id: "quantity",
    },
    {
      Header: lang === "en" ? "Published" : "Pubblicato",
      accessor: "col6",
      disableSortBy: true,
      width: "150",
      id: "published",
    },

    {
      Header: lang === "en" ? "Actions" : "Azioni",
      accessor: "col7",
      width: "150",
      disableSortBy: true,
    },
  ];
};

export const classOptionsInProducts = [
  { label: "Product", value: "product" },
  { label: "Exam", value: "exam" },
];
