import React from "react";
import { format } from "date-fns";
import { useFormContext } from "react-hook-form";
import { selectLoggedInUser } from "store/authStore/authReducer";
import { UpdateReceiptDto } from "shared/interfaces/Receipt.interface";
import { useTranslation } from "react-i18next";

function Information({ isCreate }: { isCreate: boolean }) {
  const form = useFormContext<UpdateReceiptDto>();
  const { t } = useTranslation("common");
  const loggedInUser = selectLoggedInUser();
  if (isCreate) {
    return (
      <div className="flex flex-col gap-5">
        <h3 className="text-xl font-semibold">{t("receipt.information")}</h3>
        <div className="flex flex-col gap-5">
          <div>
            {t("receipt.creationDate")}: {format(new Date(), "dd/MM/yyyy")}
          </div>
          <div>{t("receipt.modifyDate")}: -</div>
          <div>
            {t("receipt.creationUser")}: {loggedInUser.user?.PK_USER}
          </div>
          <div>{t("receipt.modifyUser")}: -</div>
          <div>{t("receipt.lastPrintDate")}: -</div>
        </div>
      </div>
    );
  } else {
    const createdAt = form.watch("created_at")
      ? format(new Date(form.watch("created_at")), "dd/MM/yyyy")
      : "-";
    const modifyDate = form.watch("updated_at")
      ? format(new Date(form.watch("updated_at")), "dd/MM/yyyy")
      : "-";
    const lastPrintDate = form.watch("last_print_date")
      ? format(new Date(form.watch("last_print_date")), "dd/MM/yyyy")
      : "-";
    return (
      <div className="flex flex-col gap-5">
        <h3 className="text-xl font-semibold">Information</h3>
        <div className="flex flex-col gap-5">
          <div>
            {t("receipt.creationDate")}: {createdAt}
          </div>
          <div>
            {t("receipt.modifyDate")}: {modifyDate}
          </div>
          <div>
            {t("receipt.creationUser")}: {loggedInUser.user?.PK_USER}
          </div>
          <div>
            {t("receipt.modifyUser")}: {form.watch("last_modifier")}
          </div>
          <div>
            {t("receipt.lastPrintDate")}: {lastPrintDate}
          </div>
        </div>
      </div>
    );
  }
}
export default Information;
