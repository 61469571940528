import { CMS_BE_URL } from "api/urls";

export const getUserPermissionsURL = ({ userId }: { userId: string }) =>
  `${CMS_BE_URL}/users/getUserPermissions/${userId}`;
export const loginURL: () => string = () => `${CMS_BE_URL}/users/login`;
export const createUserURL: () => string = () => `${CMS_BE_URL}/users`;
export const getUserURL: () => string = () => `${CMS_BE_URL}/users/`;
export const editUserURL: () => string = () => `${CMS_BE_URL}/users/`;
export const forgotPasswordURL: () => string = () =>
  `${CMS_BE_URL}/users/forgotPassword`;
export const resetPasswordURL: () => string = () =>
  `${CMS_BE_URL}/users/resetPassword`;
export const activateAccountURL: () => string = () =>
  `${CMS_BE_URL}/users/activateAccount`;
