import axios from "axios";
import {
  constructDeletePaymentURL,
  constructGetPaymentsURL,
  constructGetPaymentURL,
  constructUpdatePaymentURL,
  createPaymentURL,
  GetPaymentFilters as GetPaymentsFilters,
} from "./urls";

export type CreatePaymentDto = {
  customer_id?: string;
  order_id?: string;
  payment_status?: string;
  fees?: number;
  amount?: number;
  payment_type?: string;
  payment_date?: string;
  payment_location?: string;
};

export type UpdatePaymentDto = {
  order_id?: string;
  status?: string;
  fees?: number;
  amount?: number;
  payment_type?: string;
  payment_date?: string;
};

export const createPayment = (createPaymentDto: CreatePaymentDto) =>
  axios.post(createPaymentURL, createPaymentDto);

export const getPayments = (filters: GetPaymentsFilters) =>
  axios.get(constructGetPaymentsURL(filters));

export const getPayment = (paymentId: string) =>
  axios.get(constructGetPaymentURL(paymentId));

export const updatePayment = (
  paymentId: string,
  updatePaymentDto: UpdatePaymentDto
) => axios.put(constructUpdatePaymentURL(paymentId), updatePaymentDto);

export const deletePayment = (paymentId: string) =>
  axios.delete(constructDeletePaymentURL(paymentId));

export const getPaymentsColumns: (lang: string) => {
  Header: string;
  accessor: string;
  width: string;
  id?: string;
  disableSortBy?: boolean;
}[] = (lang: string) => {
  return [
    {
      Header: lang === "en" ? "ID" : "ID",
      accessor: "col1",
      width: "150",
      id: "id",
    },
    {
      Header: lang === "en" ? "Purchase Date" : "Data di acquisto",
      accessor: "col2",
      width: "250",
      id: "purchaseDate", // FIXME
    },
    {
      Header: lang === "en" ? "User" : "Utente",
      accessor: "col3",
      width: "250",
      id: "start_date",
    },
    {
      Header: lang === "en" ? "Price" : "Prezzo",
      accessor: "col4",
      width: "250",
      id: "end_date",
    },
    {
      Header: lang === "en" ? "actions" : "azioni",
      accessor: "col5",
      width: "150",
      disableSortBy: true,
    },
  ];
};
