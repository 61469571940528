import { CMS_BE_URL } from "api/urls";

export function createCouncilURL() {
  return `${CMS_BE_URL}/councils`;
}

export function getCouncilsURL(filters: {
  pageSize: number;
  pageIndex: number;
  sortCol: string;
  sortOrder: string;
  searchString: string;
}) {
  return `${CMS_BE_URL}/councils${Object.entries(filters)
    .filter(([, value]) => !!value)
    .map(([key, value]) => `${key}=${value}`)
    .join("&")}`;
}

export function deleteCouncilURL({ council }: { council: string }) {
  return `${CMS_BE_URL}/councils/${council}`;
}

export function updateCouncilURL({ council }: { council: string }) {
  return `${CMS_BE_URL}/councils/${council}`;
}

export function getCouncilURL({ council }: { council: string }) {
  return `${CMS_BE_URL}/councils/${council}`;
}

export function deleteMemberFromCouncilURL({
  council,
  userId,
}: {
  council: string;
  userId: string;
}) {
  return `${CMS_BE_URL}/councils/${council}/users/${userId}`;
}
