import { Plan } from "api/Plans/plans.api";

export interface PlansState {
  plans: {
    teacherPlans: Plan[];
    patreonPlans: Plan[];
  };
  loading: boolean;
}

export const plansInitialState: PlansState = {
  plans: {
    teacherPlans: [],
    patreonPlans: [],
  },
  loading: false,
};
