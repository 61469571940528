export type SelectGender = {
  value: string;
  label: string;
};

export const getGenderOptions: (lang: string) => SelectGender[] = (
  lang: string
) => {
  if (lang === "en") {
    return [
      { label: "Male", value: "male" },
      { label: "Female", value: "female" },
      { label: "Other", value: "other" },
    ];
  } else {
    return [
      { label: "Uomo", value: "male" },
      { label: "Donna", value: "female" },
      { label: "Nessuna Preferenza", value: "other" },
    ];
  }
};

export const getGenderFilterOptions: (lang: string) => SelectGender[] = (
  lang: string
) => {
  if (lang === "en") {
    return [
      { label: "Male", value: "male" },
      { label: "Female", value: "female" },
      { label: "Other", value: "other" },
    ];
  } else {
    return [
      { label: "Uomo", value: "male" },
      { label: "Donna", value: "female" },
      { label: "Nessuna Preferenza", value: "other" },
    ];
  }
};
