import { Province } from "shared/interfaces/Province.interface";
import { Region } from "shared/interfaces/Region.interface";
import { Role } from "shared/interfaces/Role.interface";

export interface LookupsState {
  lookupList: {
    regions: Array<Region>;
    roles: Array<Role>;
    provinces: Array<Province>;
    categories: any;
  };
  loading: boolean;
}

export const lookupsInitialState: LookupsState = {
  lookupList: {
    regions: [],
    roles: [],
    provinces: [],
    categories: [],
  },
  loading: false,
};
