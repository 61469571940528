import { User } from "shared/interfaces/User.interface";

export interface AuthState {
  user: User | null;
  loading: boolean;
  remember: boolean;
  forgotPassword: {
    username: string;
    email: string;
  };
  newPassword: {
    newPassword: string;
    confirmNewPassword: string;
  };
  permissions: any;
}

export const authInitialState: AuthState = {
  user: null,
  remember: false,
  loading: false,
  forgotPassword: {
    username: "",
    email: "",
  },
  newPassword: {
    newPassword: "",
    confirmNewPassword: "",
  },
  permissions: {},
};
