import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { KeyIcon, ArrowLeftIcon } from "@heroicons/react/outline";
import {
  forgotPasswordThunk,
  selectForgotPassword,
} from "store/authStore/authReducer";
import { authActions } from "store/globalStore";
import { useAppDispatch } from "store/storeHooks";
import { useTranslation } from "react-i18next";

function ForgotPassword() {
  const { t } = useTranslation("common");
  const forgotPassword = selectForgotPassword();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  function forgotPasswordHandler() {
    dispatch(
      forgotPasswordThunk({
        email: forgotPassword.email,
      })
    );
    navigate("/checkEmail");
  }

  return (
    <div className="flex flex-1 flex-col items-center justify-center px-4 py-12 h-screen sm:px-6 lg:flex-none lg:px-20 xl:px-24">
      <div className="mx-auto w-full max-w-sm text-center space-y-6 lg:w-96">
        <div className="flex items-center justify-center p-4">
          <div className="flex items-center justify-center w-16 h-16 bg-auth-icon-bg rounded-full ring-10 ring-auth-icon-ring ring-offset-10 ring-offset-auth-icon-ring-offset">
            <div className="text-auth-icon-color">
              <KeyIcon className="p-4 w-full h-full" />
            </div>
          </div>
        </div>
        <h2 className="mt-6 text-gray-900 text-3xl font-semibold">
          {t("forgotPassword.forgotPassword")}
        </h2>
        <p>{t("forgotPassword.forgotPasswordDescription")}</p>
        <form className="space-y-6" onSubmit={forgotPasswordHandler}>
          <div className="space-y-1">
            <label
              htmlFor="email"
              className="block text-left text-gray-700 text-sm font-semibold"
            >
              {t("forgotPassword.email")}
            </label>
            <div className="mt-1">
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="off"
                placeholder={t("forgotPassword.email")}
                required
                value={forgotPassword.email}
                onChange={(e) =>
                  dispatch(
                    authActions.changeforgotPasswordEmail({
                      email: e.target.value,
                    })
                  )
                }
                className="placeholder-gray-400 block px-3 py-3 w-full border border-gray-300 focus:border-gray-300 rounded-sm focus:outline-none shadow-sm appearance-none focus:ring-0 sm:text-sm"
              />
            </div>
          </div>

          <div>
            <button
              type="submit"
              className="flex justify-center px-4 py-3 w-full text-white text-sm font-medium bg-login-color border border-transparent rounded-md focus:outline-none shadow-sm focus:ring-0"
            >
              {t("forgotPassword.resetPassword")}
            </button>
          </div>
        </form>

        <div>
          <Link to="/" className="flex items-center justify-center">
            <ArrowLeftIcon className="h-4" />
            <span className="pl-2">{t("forgotPassword.back")}</span>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
