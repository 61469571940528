import { PDFDocument, PDFFont, PDFImage, rgb } from "pdf-lib";
import JSZip from "jszip";
import fontkit from "@pdf-lib/fontkit";
import {
  EnchancedOrder,
  OrderAddress,
  ProductWithMedia,
} from "shared/interfaces/Orders.interface";
import { format } from "date-fns";
import { Round } from "utils/utils";
import { saveAs } from "file-saver";
import {
  calcCouponDiscount,
  calcFinalPrice,
  calcPrice,
  calcPriceQty,
  calcSripeFees,
  calcTotalNetPrice,
  calcTotalPrice,
  ExportOrder,
  trimEmpty,
} from "./generateOrderPdf";
import { CMS_BE_URL } from "api/urls";
import { trimString } from "utils/utils";

export function generateOrdersPdfs(orders: ExportOrder[]) {
  // ZIP
  const zip = new JSZip();
  const folder = zip.folder("orders") as JSZip;

  const assets = [];
  // fonts
  assets.push(
    fetch(`${CMS_BE_URL}/Montserrat-Regular.ttf`).then((res) =>
      res.arrayBuffer()
    )
  );
  assets.push(
    fetch(`${CMS_BE_URL}/Montserrat-Bold.ttf`).then((res) => res.arrayBuffer())
  );
  // logo
  assets.push(
    fetch(`${CMS_BE_URL}/anmbReceiptLogo.png`).then((res) => res.arrayBuffer())
  );
  return Promise.all(assets)
    .then((assets) => {
      return Promise.all(orders.map(() => PDFDocument.create())).then(
        (PDFDocs) => {
          return {
            PDFDocs,
            assets,
          };
        }
      );
    })
    .then(({ PDFDocs, assets }) => {
      return Promise.all(
        PDFDocs.map((PDFDoc) => {
          const tempAssets = [];
          // fonts
          PDFDoc.registerFontkit(fontkit);
          tempAssets.push(PDFDoc.embedFont(assets[0]));
          tempAssets.push(PDFDoc.embedFont(assets[1]));
          // logo
          tempAssets.push(PDFDoc.embedPng(assets[2]));
          return Promise.all(tempAssets as any); // TODO fixme
        })
      ).then((assets) => {
        return {
          PDFDocs,
          assets, // FIXME
        };
      });
    })
    .then(({ PDFDocs, assets }) => {
      // consts
      const startX = 30;
      const endY = 30;
      const logoWidth = 150;
      const logoHeight = 44;
      const bigTextSize = 14;
      const bigTextPadding = 3.5;
      const normalTextSize = 10;
      const normalTextPadding = 2.5;
      const smallTextSize = 8;
      const smallTextPadding = 2;
      const extraSmallTextSize = 7;
      const extraSmallPadding = 1.75;
      return Promise.all(
        orders.map((order, i) => {
          // fonts
          const regularFont = assets[i][0] as PDFFont;
          const boldFont = assets[i][1] as PDFFont;
          // logo
          const logo = assets[i][2] as PDFImage;
          // PDF init
          let page = PDFDocs[i].addPage();
          const endX = page.getWidth() - 30;
          const startY = page.getHeight() - 30;
          page.moveTo(startX, startY - logoHeight);
          page.drawImage(logo, {
            width: logoWidth,
            height: logoHeight,
          });
          // Subheader
          {
            const spaceY = 22;
            page.moveDown(normalTextSize + spaceY);
            page.drawText(`${order.user.name} ${order.user.surname}`, {
              size: normalTextSize,
              font: regularFont,
            });
            page.moveDown(normalTextSize);
            page.drawText(
              trimEmpty([
                order.billing_address.street_name,
                order.billing_address.street_number,
              ]),
              {
                size: normalTextSize,
                font: regularFont,
              }
            );
            page.moveDown(normalTextSize);
            page.drawText(
              trimEmpty([
                order.billing_address.province,
                order.billing_address.region,
                order.billing_address.postal_code,
              ]),
              {
                size: normalTextSize,
                font: regularFont,
              }
            );
            page.moveDown(normalTextSize);
            page.drawText("IT", {
              size: normalTextSize,
              font: regularFont,
            });
            page.moveDown(extraSmallTextSize + spaceY);
            page.drawText(
              "Per domande relative al tuo ordine, ti preghiamo di visitare il sito:",
              {
                size: extraSmallTextSize,
                font: regularFont,
              }
            );
            page.moveDown(smallTextSize);
            page.drawText("www.anmb.org/contact", {
              size: smallTextSize,
              font: regularFont,
            });
            page.moveDown(smallTextSize);
          }
          // Ricevuta
          {
            const spaceY = 20;
            page.moveTo(page.getWidth() / 2, startY - normalTextSize);
            page.drawText("Ricevuta", {
              size: normalTextSize,
              font: boldFont,
              color: rgb(0.44, 0.44, 0.44),
            });
            page.moveDown(spaceY + 120);
            page.drawRectangle({
              width: page.getWidth() / 2 - startX,
              height: 120,
              borderWidth: 1,
              borderColor: rgb(0.02, 0.247, 0.475),
              color: rgb(0.89, 0.92, 0.94),
            });
            page.moveUp(120 - 10 - smallTextSize);
            page.moveRight(10);
            page.drawText("Pagato", {
              size: normalTextSize,
              font: boldFont,
            });
            page.moveDown(smallTextSize + normalTextPadding);
            page.drawText("Numero di riferimento del pagamento", {
              size: smallTextSize,
              font: regularFont,
            });
            page.moveDown(smallTextSize + smallTextPadding);
            page.drawText("0c97e75c-9cac-4b7c-8df8-717654242fe8", {
              size: smallTextSize,
              font: regularFont,
            });
            page.moveDown(smallTextSize + smallTextPadding);
            page.drawText("Venduto da ANMB EU, Succursale Italiana", {
              size: smallTextSize,
              font: regularFont,
            });
            page.moveDown(smallTextSize + smallTextPadding);
            page.drawText("P. IVA 02093050363", {
              size: smallTextSize,
              font: regularFont,
            });
            page.moveDown(10);
            page.drawLine({
              start: { x: page.getX(), y: page.getY() },
              end: { x: page.getWidth() - 40, y: page.getY() },
              thickness: 1,
              color: rgb(0.02, 0.247, 0.475),
              opacity: 1,
            });
            page.moveDown(10 + smallTextSize);
            page.drawText("Data di ricevuta", {
              size: smallTextSize,
              font: regularFont,
            });
            const createdAt = format(new Date(order.created_at), "dd/MM/yyyy");
            page.drawText(createdAt, {
              x:
                page.getWidth() -
                40 -
                regularFont.widthOfTextAtSize(createdAt, smallTextSize),
              size: smallTextSize,
              font: regularFont,
            });
            page.moveDown(smallTextSize + smallTextPadding);
            page.drawText("Numero ricevuta ", {
              size: smallTextSize,
              font: regularFont,
            });
            const yearlyId = String(order.payment.yearly_id);
            page.drawText(yearlyId, {
              x:
                page.getWidth() -
                40 -
                regularFont.widthOfTextAtSize(yearlyId, smallTextSize),
              size: smallTextSize,
              font: regularFont,
            });
            page.moveDown(smallTextSize + smallTextPadding);
            page.drawText("Totale da pagare ", {
              size: smallTextSize,
              font: regularFont,
            });
            const total = String(calcFinalPrice(order).toFixed(2)) + "€";
            page.drawText(total, {
              x:
                page.getWidth() -
                40 -
                regularFont.widthOfTextAtSize(total, smallTextSize),
              size: smallTextSize,
              font: regularFont,
            });
            page.moveDown(smallTextSize + smallTextPadding);
            page.moveTo(startX, page.getY() - 20);
          }
          page.drawLine({
            start: { x: page.getX(), y: page.getY() },
            end: { x: page.getWidth() - startX, y: page.getY() },
            thickness: 1,
            color: rgb(0.02, 0.247, 0.475),
            opacity: 1,
          });
          page.moveDown(20);
          // locations
          {
            const offset = (page.getWidth() - 2 * startX) / 3;
            const grid = [startX, startX + offset, startX + 2 * offset];
            page.drawText("Indirizzo sede legale", {
              x: grid[0],
              size: normalTextSize,
              font: boldFont,
            });
            page.drawText("Indirizzo di spedizione", {
              x: grid[1],
              size: normalTextSize,
              font: boldFont,
            });
            // page.drawText("Venduto da", {
            //   x: grid[2],
            //   size: normalTextSize,
            //   font: boldFont,
            // });
            page.moveDown(normalTextSize + normalTextPadding);
            page.drawText(`${order.user.name} ${order.user.surname}`, {
              x: grid[0],
              size: smallTextSize,
              font: regularFont,
            });
            page.drawText(`${order.user.name} ${order.user.surname}`, {
              x: grid[1],
              size: smallTextSize,
              font: regularFont,
            });
            // page.drawText("Venduto da ANMB EU, Succursale Italiana", {
            //   x: grid[2],
            //   size: smallTextSize,
            //   font: regularFont,
            // });
            page.moveDown(smallTextSize + normalTextPadding);
            page.drawText(
              trimEmpty([
                order.billing_address.street_name,
                order.billing_address.street_number,
              ]),
              {
                x: grid[0],
                size: smallTextSize,
                font: regularFont,
              }
            );
            if (order.shipping_address) {
              page.drawText(
                trimEmpty([
                  order.shipping_address.street_name,
                  order.shipping_address.street_number,
                ]),
                {
                  x: grid[1],
                  size: smallTextSize,
                  font: regularFont,
                }
              );
            } else {
              page.drawText(
                trimEmpty([
                  order.billing_address.street_name,
                  order.billing_address.street_number,
                ]),
                {
                  x: grid[1],
                  size: smallTextSize,
                  font: regularFont,
                }
              );
            }
            // page.drawText("Via P.Ascani", {
            //   x: grid[2],
            //   size: smallTextSize,
            //   font: regularFont,
            // });
            page.moveDown(smallTextSize + normalTextPadding);
            page.drawText(
              trimEmpty([
                order.billing_address.province,
                order.billing_address.region,
                order.billing_address.postal_code,
              ]),
              {
                x: grid[0],
                size: smallTextSize,
                font: regularFont,
              }
            );
            if (orders[i].shipping_address) {
              page.drawText(
                trimEmpty([
                  order.shipping_address.province,
                  order.shipping_address.region,
                  order.shipping_address.postal_code,
                ]),
                {
                  x: grid[1],
                  size: smallTextSize,
                  font: regularFont,
                }
              );
            } else {
              page.drawText(
                trimEmpty([
                  order.billing_address.province,
                  order.billing_address.region,
                  order.billing_address.postal_code,
                ]),
                {
                  x: grid[1],
                  size: smallTextSize,
                  font: regularFont,
                }
              );
            }
            // page.drawText("4441126 Modena - Italy", {
            //   x: grid[2],
            //   size: smallTextSize,
            //   font: regularFont,
            // });
            page.moveDown(smallTextSize + normalTextPadding);
            page.drawText("IT", {
              x: grid[0],
              size: smallTextSize,
              font: regularFont,
            });
            page.drawText("IT", {
              x: grid[1],
              size: smallTextSize,
              font: regularFont,
            });
            // page.drawText("IT", {
            //   x: grid[2],
            //   size: smallTextSize,
            //   font: regularFont,
            // });
            page.moveDown(smallTextSize + normalTextPadding);
            // if (order?.user?.fiscale_code) {
            //   page.drawText("Codice fiscale persona fisica", {
            //     x: grid[0],
            //     size: smallTextSize,
            //     font: regularFont,
            //   });
            //   page.moveDown(smallTextSize + normalTextPadding);
            //   page.drawText(`${order.user.fiscale_code}`, {
            //     x: grid[0],
            //     size: smallTextSize,
            //     font: regularFont,
            //   });
            //   page.moveDown(smallTextSize + normalTextPadding);
            // } else {
            //   page.drawText("Codice fiscale non disponibile", {
            //     x: grid[0],
            //     size: smallTextSize,
            //     font: regularFont,
            //   });
            //   page.moveDown(smallTextSize + normalTextPadding);
            // }
          }
          page.moveDown(10);
          page.drawLine({
            start: { x: page.getX(), y: page.getY() },
            end: { x: page.getWidth() - 30, y: page.getY() },
            thickness: 1,
            color: rgb(0.02, 0.247, 0.475),
            opacity: 1,
          });
          page.moveDown(10 + normalTextSize);
          // order created at and id
          {
            page.drawText("Informazioni sull'ordine", {
              size: normalTextSize,
              font: boldFont,
            });
            page.moveDown(normalTextSize + normalTextPadding);
            page.drawText(
              `Data Dordine ${format(
                new Date(order.created_at),
                "dd/MM/yyyy"
              )}`,
              {
                size: smallTextSize,
                font: regularFont,
              }
            );
            page.moveDown(smallTextSize + smallTextPadding);
            page.drawText(`Contratto ${order.id}`, {
              size: smallTextSize,
              font: regularFont,
            });
            page.moveDown(smallTextSize + smallTextPadding);
          }
          page.moveDown(10);
          page.drawLine({
            start: { x: page.getX(), y: page.getY() },
            end: { x: page.getWidth() - 30, y: page.getY() },
            thickness: 1,
            color: rgb(0.02, 0.247, 0.475),
            opacity: 1,
          });
          page.moveDown(10 + normalTextSize);
          // variants
          {
            const bigOffset = ((page.getWidth() - 60) * 2) / 5;
            const offset = (page.getWidth() - 60 - bigOffset) / 5;
            const grid = [
              startX,
              bigOffset,
              bigOffset + offset,
              bigOffset + 2 * offset,
              bigOffset + 3 * offset,
              bigOffset + 4 * offset,
            ];
            page.drawText("Descrizione", {
              x: grid[0],
              size: normalTextSize,
              font: boldFont,
            });
            page.drawText("Qty", {
              x: grid[1],
              size: normalTextSize,
              font: boldFont,
            });
            page.drawText("P.Unitario", {
              x: grid[2],
              size: normalTextSize,
              font: boldFont,
            });
            page.drawText("IVA%", {
              x: grid[3],
              size: normalTextSize,
              font: boldFont,
            });
            page.drawText("P.Unitario", {
              x: grid[4],
              size: normalTextSize,
              font: boldFont,
            });
            page.drawText("Prezzo Totale", {
              x: grid[5],
              size: normalTextSize,
              font: boldFont,
            });
            page.moveDown(normalTextSize + normalTextPadding);
            page.drawText("(IVA esclusa)", {
              x: grid[2],
              size: smallTextSize,
              font: regularFont,
            });
            page.drawText("(IVA inclusa)", {
              x: grid[4],
              size: smallTextSize,
              font: regularFont,
            });
            page.drawText("(IVA inclusa)", {
              x: grid[5],
              size: smallTextSize,
              font: regularFont,
            });
            page.moveDown(smallTextSize + smallTextPadding);
            if (order?.products && order.products?.length > 0) {
              for (let j = 0; j < order.products.length; ++j) {
                if (
                  page.getY() - 2 * (smallTextSize + 2 * smallTextPadding) <
                  endY
                ) {
                  page = PDFDocs[i].addPage();
                  page.moveTo(startX, startY);
                }
                page.moveDown(2 * (smallTextSize + 2 * smallTextPadding));
                const rectColor =
                  j % 2 === 0 ? rgb(0.89, 0.92, 0.94) : rgb(0.97, 0.92, 0.98);
                page.drawRectangle({
                  width: page.getWidth() - 2 * startX,
                  height: 2 * (smallTextSize + 2 * smallTextPadding),
                  borderWidth: 1,
                  borderColor: rectColor,
                  color: rectColor,
                });
                page.moveUp(smallTextSize + 3 * smallTextPadding);
                page.drawText(
                  `${trimString(order.products[j].variant.title, 50)}`,
                  {
                    x: grid[0],
                    size: smallTextSize,
                    font: regularFont,
                  }
                );
                page.drawText(`${order.products[j].selection.quantity}`, {
                  x: grid[1],
                  size: smallTextSize,
                  font: regularFont,
                });
                page.drawText(
                  `${order.products[j].selection.price?.toFixed(2)}€`,
                  {
                    x: grid[2],
                    size: smallTextSize,
                    font: regularFont,
                  }
                );
                page.drawText(
                  `${order.products[j].selection.vat_percentage}%`,
                  {
                    x: grid[3],
                    size: smallTextSize,
                    font: regularFont,
                  }
                );
                page.drawText(`${calcPrice(order.products[j]).toFixed(2)}€`, {
                  x: grid[4],
                  size: smallTextSize,
                  font: regularFont,
                });
                page.drawText(
                  `${calcPriceQty(order.products[j]).toFixed(2)}€`,
                  {
                    x: grid[5],
                    size: smallTextSize,
                    font: regularFont,
                  }
                );
                page.moveDown(smallTextSize + 2 * smallTextPadding);
                page.drawText(`ASIN: ${order.products[j].id}`, {
                  x: grid[0],
                  size: extraSmallTextSize,
                  font: regularFont,
                });
                page.moveDown(smallTextPadding);
              }
              page.moveDown(1);
            }
          }
          page.drawLine({
            start: { x: page.getX(), y: page.getY() },
            end: { x: page.getWidth() - 30, y: page.getY() },
            thickness: 1,
            color: rgb(0.02, 0.247, 0.475),
            opacity: 1,
          });
          // sum
          {
            const offset = (page.getWidth() - 2 * startX) / 5;
            const spaceX = 5;
            const bigSpaceY = 10;
            const spaceY = 5;
            const grid = [
              startX + spaceX,
              startX + spaceX + offset,
              startX + spaceX + 2 * offset,
              startX + spaceX + 3 * offset,
              startX + spaceX + 4 * offset,
            ];
            if (
              page.getY() - 2 * (smallTextSize + 2 * smallTextPadding) <
              endY
            ) {
              page = PDFDocs[i].addPage();
              page.moveTo(startX, startY);
            } else if (
              page.getY() -
                10 -
                (bigTextSize +
                  2 * (bigTextPadding + bigSpaceY) +
                  1 +
                  2 * (smallTextSize + 2 * (smallTextPadding + spaceY)) +
                  1 +
                  (smallTextSize + 2 * (smallTextPadding + spaceY))) <
              endY
            ) {
              page = PDFDocs[i].addPage();
              page.moveTo(startX, startY);
            }
            page.moveDown(10 + normalTextSize);
            page.moveDown(
              bigTextSize +
                2 * (bigTextPadding + bigSpaceY) +
                1 +
                2 * (smallTextSize + 2 * (smallTextPadding + spaceY)) +
                1 +
                (smallTextSize + 2 * (smallTextPadding + spaceY))
            );
            page.drawRectangle({
              width: page.getWidth() - 60,
              height:
                2 * (bigTextPadding + bigSpaceY) +
                1 +
                2 * (smallTextSize + 2 * (smallTextPadding + spaceY)) +
                1 +
                (smallTextSize + 2 * (smallTextPadding + spaceY)),
              borderWidth: 1,
              borderColor: rgb(0.97, 0.98, 0.98),
              color: rgb(0.97, 0.98, 0.98),
            });
            page.moveUp(
              2 * (bigTextPadding + bigSpaceY) +
                1 +
                2 * (smallTextSize + 2 * (smallTextPadding + spaceY)) +
                1 +
                (smallTextSize + 2 * (smallTextPadding + spaceY))
            );
            page.moveDown(bigTextPadding + bigSpaceY + bigTextSize);
            page.drawText("Totale Ricevuta", {
              x: page.getX() + spaceX,
              size: bigTextSize,
              font: boldFont,
            });
            const finalPrice = String(calcFinalPrice(order).toFixed(2)) + "€";
            page.drawText(finalPrice, {
              x:
                endX -
                spaceX -
                boldFont.widthOfTextAtSize(finalPrice, bigTextSize),
              size: bigTextSize,
              font: boldFont,
            });
            page.moveDown(bigTextPadding + bigSpaceY + 1);
            page.drawLine({
              start: { x: page.getX(), y: page.getY() },
              end: { x: page.getWidth() - startX, y: page.getY() },
              thickness: 1,
              color: rgb(0.02, 0.247, 0.475),
              opacity: 1,
            });
            page.moveDown(smallTextPadding + smallTextSize + spaceY);
            page.drawText("Prezzo Totale", {
              x: grid[0],
              size: smallTextSize,
              font: regularFont,
            });
            page.drawText("Subtotale IVA", {
              x: grid[1],
              size: smallTextSize,
              font: regularFont,
            });
            page.drawText("Coupon", {
              x: grid[2],
              size: smallTextSize,
              font: regularFont,
            });
            page.drawText("Spese di spedizione", {
              x: grid[3],
              size: smallTextSize,
              font: regularFont,
            });
            page.drawText("Commissioni", {
              x: grid[4],
              size: smallTextSize,
              font: regularFont,
            });
            page.moveDown(smallTextSize + smallTextPadding);
            page.drawText("(IVA esclusa)", {
              x: grid[1],
              size: smallTextSize,
              font: regularFont,
            });
            page.drawText("(Sconto)", {
              x: grid[2],
              size: smallTextSize,
              font: regularFont,
            });
            page.drawText("(Stripe)", {
              x: grid[4],
              size: smallTextSize,
              font: regularFont,
            });
            page.moveDown(smallTextPadding + 1 + spaceY);
            page.drawLine({
              start: { x: page.getX(), y: page.getY() },
              end: { x: page.getWidth() - startX, y: page.getY() },
              thickness: 1,
              color: rgb(0.02, 0.247, 0.475),
              opacity: 1,
            });
            page.moveDown(smallTextPadding + smallTextSize + spaceY);
            page.drawText(calcTotalNetPrice(order.products).toFixed(2) + "€", {
              x: grid[0],
              size: smallTextSize,
              font: regularFont,
            });
            page.drawText(calcTotalPrice(order.products).toFixed(2) + "€", {
              x: grid[1],
              size: smallTextSize,
              font: regularFont,
            });
            page.drawText(calcCouponDiscount(order).toFixed(2) + "€", {
              x: grid[2],
              size: smallTextSize,
              font: regularFont,
            });
            page.drawText(order.shipping + "€", {
              x: grid[3],
              size: smallTextSize,
              font: regularFont,
            });
            page.drawText(calcSripeFees(order).toFixed(2) + "€", {
              x: grid[4],
              size: smallTextSize,
              font: regularFont,
            });
            page.moveDown(smallTextPadding);
            return {
              PDFDoc: PDFDocs[i].save(),
              name: `(${i + 1})-${order.user.name}-${
                order.user.surname
              }-${format(new Date(order.created_at), "dd-MM-yyyy")}.pdf`,
            };
          }
        })
      ); // end-for
    })
    .then((PDFDocs) => {
      return Promise.resolve(
        PDFDocs.forEach((_, i) =>
          folder.file(PDFDocs[i].name, PDFDocs[i].PDFDoc)
        )
      );
    })
    .then(() => {
      return new Promise((resolve, reject) => {
        if (!folder) {
          reject(new Error(undefined));
        } else {
          folder
            .generateAsync({ type: "blob" })
            .then((blob) => {
              saveAs(blob, "orders");
              resolve(undefined);
            })
            .catch((err) => reject(err));
        }
      });
    });
}
