import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createUserRank,
  deleteUserRank,
  getUserRank,
  getUserRanks,
  updateUserRank,
} from "api/UserRanks/userRanks.api";
import {
  CreateUserRank,
  UpdateUserRank,
} from "shared/interfaces/Rank.interface";
import { useAppSelector } from "store/storeHooks";
import { userRanksInitialState } from "./userRanksState";

export const createUserRankThunk = createAsyncThunk(
  "userRanks/createUserRank",
  async ({ data }: { data: CreateUserRank }) => {
    await createUserRank({ data });
  }
);

export const getUserRanksThunk = createAsyncThunk(
  "userRanks/getUserRanks",
  async (filters: {
    pageSize?: number;
    pageIndex?: number;
    sortCol?: string;
    sortOrder?: string;
    searchString?: string;
  }) => {
    const res = await getUserRanks(filters);
    return res.data;
  }
);

export const deleteUserRankThunk = createAsyncThunk(
  "userRanks/deleteUserRank",
  async ({ rankId }: { rankId: string }) => {
    await deleteUserRank({ rankId });
  }
);

export const getUserRankThunk = createAsyncThunk(
  "userRanks/getUserRank",
  async ({ rankId }: { rankId: string }) => {
    const res = await getUserRank({ rankId });
    return res.data;
  }
);

export const updateUserRankThunk = createAsyncThunk(
  "userRanks/updateUserRank",
  async ({ rankId, data }: { rankId: string; data: UpdateUserRank }) => {
    await updateUserRank({
      rankId,
      data,
    });
  }
);

const userRanksSlice = createSlice({
  name: "userRanks",
  initialState: userRanksInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createUserRankThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(createUserRankThunk.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(createUserRankThunk.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getUserRanksThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUserRanksThunk.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(getUserRanksThunk.rejected, (state) => {
        state.loading = false;
      })
      .addCase(deleteUserRankThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteUserRankThunk.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(deleteUserRankThunk.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getUserRankThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUserRankThunk.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(getUserRankThunk.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateUserRankThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateUserRankThunk.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updateUserRankThunk.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const selectUserRanks = () => useAppSelector((state) => state.userRanks);

export default userRanksSlice;
