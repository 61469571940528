import React from "react";
import axios, { AxiosResponse } from "axios";
import {
  getUsersURL,
  getUserURL,
  deleteUserURL,
  resendEmailCreationURL,
  resendEmailRegisterURL,
  resetPasswordURL,
  usersBulkUpdateURL,
} from "./urls";

export const getUsers = (filters: {
  role?: string;
  location?: string;
  rank?: string;
  status?: string;
  pageSize?: number;
  pageIndex?: number;
  searchString?: string;
  sortCol?: string;
  sortOrder?: string;
  including?: string;
}) => {
  return axios.get(getUsersURL(filters));
};

export const getUser: (username: string) => Promise<AxiosResponse<any, any>> = (
  username: string
) => {
  return axios.get(getUserURL(username));
};

export const deleteUser: (
  username: string,
  isHard: boolean
) => Promise<AxiosResponse<any, any>> = (username: string, isHard: boolean) => {
  return axios.delete(deleteUserURL(username, isHard));
};

export const resendEmailCreation = (username: string) =>
  axios.post(resendEmailCreationURL(username));

export const resendEmailRegister = (username: string) =>
  axios.post(resendEmailRegisterURL(username));

export const resetPassword = (email: string) =>
  axios.put(resetPasswordURL(), { email });

export const usersBulkUpdate = (payloadObject: any) =>
  axios.put(usersBulkUpdateURL(), payloadObject);

export const getUsersColumns: (
  { allSelected, setAllSelected, setExcludeUsers, setIncludeUsers }: any,
  lang: string
) => {
  Header: string | JSX.Element;
  accessor: string;
  width: string;
  disableSortBy?: boolean;
  id?: string;
}[] = (
  { allSelected, setAllSelected, setExcludeUsers, setIncludeUsers }: any,
  lang: string
) => {
  return [
    {
      Header: (
        <input
          type="checkbox"
          checked={allSelected}
          onClick={() => {
            setAllSelected((state: boolean) => !state);
            setExcludeUsers([]);
            setIncludeUsers([]);
          }}
          className="text-primary"
        />
      ),
      accessor: "checkbox",
      width: "30",
      disableSortBy: true,
    },
    {
      Header: lang === "en" ? "name" : "nome",
      accessor: "col1",
      width: "200",
      id: "name",
    },
    {
      Header: lang === "en" ? "region" : "regione",
      accessor: "col2",
      width: "150",
      id: "region",
    },
    {
      Header: lang === "en" ? "rank" : "rango",
      accessor: "col3",
      width: "150",
      id: "rank",
    },
    {
      Header: lang === "en" ? "status" : "stato",
      accessor: "col4",
      width: "100",
      id: "status",
    },
    {
      Header: lang === "en" ? "Membership" : "Appartenenza",
      accessor: "col5",
      width: "100",
      id: "member_status",
    },
    {
      Header: lang === "en" ? "END OF SUBSCRIPTION" : "data di scadenza",
      accessor: "col6",
      width: "200",
      id: "expired_date",
    },
    {
      Header: lang === "en" ? "role" : "ruolo",
      accessor: "col7",
      width: "100",
      id: "role",
    },
    {
      Header: lang === "en" ? "last login" : "ultimo accesso",
      accessor: "col8",
      width: "150",
      id: "last_login_fe",
    },
    {
      Header: lang === "en" ? "actions" : "azioni",
      accessor: "col9",
      width: "100",
      disableSortBy: true,
    },
  ];
};

export const getCertsColumns: (lang: string) => {
  Header: string;
  accessor: string;
  width: string;
  disableSortBy?: boolean;
  id?: string;
}[] = (lang: string) => {
  return [
    {
      Header: lang === "en" ? "code" : "codice",
      accessor: "col1",
      width: "100",
      id: "code",
    },
    {
      Header: lang === "en" ? "type of diploma" : "tipo di diploma",
      accessor: "col2",
      width: "120",
      id: "title",
    },
    {
      Header: lang === "en" ? "acquirement date" : "data di acquisizione",
      accessor: "col3",
      width: "130",
      id: "description",
    },
    {
      Header: lang === "en" ? "dance rank" : "Grado di danza",
      accessor: "col4",
      width: "100",
      id: "badge",
    },
    {
      Header: lang === "en" ? "location" : "location",
      accessor: "col5",
      width: "100",
      id: "location",
    },
    {
      Header: lang === "en" ? "note" : "note",
      accessor: "col6",
      width: "100",
      id: "note",
    },
    {
      Header: lang === "en" ? "actions" : "azioni",
      accessor: "col7",
      width: "100",
      id: "actions",
    },
  ];
};

export const getExportUsersColumns: (lang: string) => {
  Header: string;
  accessor: string;
  width: string;
  disableSortBy?: boolean;
  id?: string;
}[] = (lang: string) => {
  return [
    {
      Header: lang === "en" ? "name" : "nome",
      accessor: "col1",
      width: "200",
      id: "name",
    },
    {
      Header: lang === "en" ? "region" : "regione",
      accessor: "col2",
      width: "150",
      id: "fk_region",
    },
    {
      Header: lang === "en" ? "rank" : "rango",
      accessor: "col3",
      width: "150",
      id: "rank",
    },
    {
      Header: lang === "en" ? "status" : "stato",
      accessor: "col4",
      width: "100",
      id: "status",
    },
    {
      Header: lang === "en" ? "END OF SUBSCRIPTION" : "data di scadenza",
      accessor: "col5",
      width: "150",
      id: "expired_date",
    },
    {
      Header: lang === "en" ? "role" : "ruolo",
      accessor: "col6",
      width: "150",
      id: "role",
    },
    {
      Header: lang === "en" ? "Member Status" : "Stato membro",
      accessor: "col7",
      width: "150",
      id: "member_status",
    },
    {
      Header: lang === "en" ? "Email" : "Email",
      accessor: "col8",
      width: "150",
      id: "email",
    },
    {
      Header: lang === "en" ? "Birth date" : "Data di nascita",
      accessor: "col9",
      width: "150",
      id: "birthDate",
    },
    {
      Header: lang === "en" ? "Card Number" : "Numero di carta",
      accessor: "col10",
      width: "150",
      id: "card_number",
    },
    {
      Header: lang === "en" ? "Inscribed at" : "Iscritto a",
      accessor: "col11",
      width: "150",
      id: "inscribed_at",
    },
  ];
};

export function getSubscriptionEndDate() {
  const isDecember = new Date().getMonth() === 11;
  const yearEnd = isDecember
    ? new Date().getFullYear() + 2
    : new Date().getFullYear() + 1;
  const subEndDateSeconds = new Date(yearEnd, 0, 1).getTime();
  return subEndDateSeconds;
}
