import { CMS_BE_URL } from "api/urls";

export const createReceiptUrl = `${CMS_BE_URL}/s/receipts`;

export const getReceiptsUrl = `${CMS_BE_URL}/s/receipts`;

export const getReceiptUrl = (receiptId: string) =>
  `${CMS_BE_URL}/s/receipts/${receiptId}?include=details,address`;

export const exportReceiptUrl = (receiptId: string) =>
  `${CMS_BE_URL}/s/receipts/${receiptId}/export?include=details,address`;

export const updateReceiptUrl = (receiptId: string) =>
  `${CMS_BE_URL}/s/receipts/${receiptId}`;

export const updateReceiptsUrl = `${CMS_BE_URL}/s/receipts`;

export const updateStateReceiptUrl = (receiptId: string) =>
 `${CMS_BE_URL}/s/receipts/${receiptId}/revertDraft`;

export const deleteReceiptUrl = (receiptId: string) =>
  `${CMS_BE_URL}/s/receipts/${receiptId}`;

export const massExportReceiptsUrl = () => `${CMS_BE_URL}/s/receipts/export`;
export const singleExportReceiptUrl = (id: string) =>
  `${CMS_BE_URL}/s/receipts/export/${id}`;
