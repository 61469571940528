import { CMS_BE_URL } from "api/urls";

export function createAboutUsURL() {
  return `${CMS_BE_URL}/aboutus`;
}
export function deleteAboutUsURL({ aboutId }: { aboutId: string }) {
  return `${CMS_BE_URL}/aboutus/${aboutId}`;
}
export function getAboutUsURL({ aboutId }: { aboutId: string }) {
  return `${CMS_BE_URL}/aboutus/${aboutId}`;
}
export function updateAboutUsURL({ aboutId }: { aboutId: string }) {
  return `${CMS_BE_URL}/aboutus/${aboutId}`;
}
