import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { User } from "shared/interfaces/User.interface";
import { LowercaseKeys } from "utils/utils";
import { UpdateReceiptForm } from "./UpdateReceipt";

function ShortUserReceiptDetails() {
  const { t } = useTranslation("common");
  const receiptContext = useFormContext<UpdateReceiptForm>();
  const receipt = receiptContext.watch();
  return (
    <div className="flex flex-col gap-2">
      {receipt?.name && receipt?.surname && (
        <div>
          {receipt.name} {receipt.surname}
        </div>
      )}
      {receipt?.card_number && (
        <div>
          {t("receipt.card")} No. {receipt.card_number}
        </div>
      )}
      {receipt.user_address &&
        receipt.user_address?.street_name &&
        receipt.user_address?.street_number && (
          <div>
            {receipt.user_address?.street_name}{" "}
            {receipt.user_address?.street_number}
          </div>
        )}
      {receipt.user_address &&
        receipt.user_address?.postal_code &&
        receipt.user_address?.region && (
          <div>
            {receipt.user_address?.postal_code}
            {", "}
            {receipt.user_address?.region}
          </div>
        )}
    </div>
  );
}

export default ShortUserReceiptDetails;
