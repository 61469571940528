import React, { useEffect, useState } from "react";
import axios from "axios";
import { CMS_BE_URL } from "api/urls";
import ReactHtmlParser from "react-html-parser";
import { useParams } from "react-router";
import GoBack from "shared/components/UI/GoBack";
import Overlay from "shared/components/UI/Overlay";
import Spinner from "shared/components/UI/Spinner";
import { useTranslation } from "react-i18next";

const ViewNewsletter = () => {
  const { t } = useTranslation("common");
  const [newsletter, setNewsletter] = useState<any>(null);
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  //get Newsletter
  useEffect(() => {
    const get = async () => {
      setLoading(true);
      const res = await axios.get(`${CMS_BE_URL}/newsletter/${id}`);
      setNewsletter(res.data.data);
      setLoading(false);
    };
    get();
  }, []);

  return (
    <Overlay active={loading} spinner={<Spinner />}>
      <div className="flex flex-col gap-5 p-10 h-full">
        {newsletter ? (
          <>
            <GoBack uri="newsletter" />
            <div className="flex gap-2 items-center justify-between">
              <h1 className="text-2xl font-bold">{newsletter.title}</h1>
            </div>
            {ReactHtmlParser(newsletter?.emailcontent)}
          </>
        ) : null}
      </div>
    </Overlay>
  );
};

export default ViewNewsletter;
