import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { getInputStyles } from "utils/utils";
import DisplayInputError from "../UI/DisplayInputError";
import { useTranslation } from "react-i18next";
import { XIcon } from "@heroicons/react/solid";
import DisplayFiles from "../UI/DisplayFiles";
import MultipleImageDropzone from "../UI/MultipleImageDropzone";

const InformationTab = ({
  user,
  setUser,
  setFilesToDelete,
}: {
  user?: any;
  setUser?: any;
  setFilesToDelete?: any;
}) => {
  const { t } = useTranslation("common");

  const {
    register,
    formState: { errors },
    control,
  } = useFormContext();

  const deleteFilesFromTeacher = (id: any) => {
    const tempUser = {
      ...user,
    };
    const index = tempUser.GALLERY_IMAGES.findIndex(
      (file: any) => file.FILE_ID === id
    );
    tempUser.GALLERY_IMAGES.splice(index, 1);
    setUser(tempUser);
  };

  return (
    <div className="grid flex-grow gap-5 grid-cols-2">
      {user?.IS_TEACHER && (
        <div className="flex flex-col flex-grow gap-2 w-full">
          <label htmlFor="description" className="block text-lg font-medium">
            {t("user.bio")}
          </label>
          <textarea
            placeholder={t("user.bio")}
            className={`${getInputStyles(!!errors.description)} flex-grow`}
            {...register("description", {
              maxLength: {
                value: 1300,
                message: `${t("user.bio")} ${t("user.shouldBe")} ${1300} ${t(
                  "user.characters"
                )}.`,
              },
            })}
          />
          <DisplayInputError message={errors.description?.message} />
        </div>
      )}

      {user?.IS_TEACHER && (
        <div className="flex flex-col flex-grow gap-2">
          <label htmlFor="school" className="block text-lg font-medium">
            {t("user.academicTitles")}
          </label>
          <textarea
            placeholder={t("user.academicTitles")}
            className={`${getInputStyles(!!errors.school)} flex-grow`}
            {...register("school", {
              maxLength: {
                value: 1300,
                message: `${t("user.academicTitles")} ${t(
                  "user.shouldBe"
                )} ${1300} ${t("user.characters")}.`,
              },
            })}
          />
          <DisplayInputError message={errors.school?.message} />
        </div>
      )}

      <div className="flex flex-col flex-grow gap-2">
        <label htmlFor="note" className="block text-lg font-medium">
          {t("user.notes")}
        </label>
        <textarea
          placeholder={t("user.notes")}
          className={`${getInputStyles(!!errors.school)} flex-grow`}
          {...register("note", {
            maxLength: {
              value: 1300,
              message: `${t("user.notes")} ${t("user.shouldBe")} ${1300} ${t(
                "user.characters"
              )}.`,
            },
          })}
        />
        <DisplayInputError message={errors.school?.message} />
        <div className="flex justify-between my-5">
          <div className="flex flex-col gap-2">
            <span className="flex text-lg font-medium">
              {t("UserCRUD.scd")}
            </span>
            <div className="flex gap-2 items-center">
              <label htmlFor="visibleSite" className="flex text-lg font-medium">
                {t("user.yes")}
              </label>
              <input
                type="radio"
                className="border-gray-300 focus:border-users-create focus:ring-users-create"
                value="1"
                {...register("visibleSite")}
              />
              <label htmlFor="visibleSite" className="flex text-lg font-medium">
                {t("user.no")}
              </label>
              <input
                type="radio"
                className="border-gray-300 focus:border-users-create focus:ring-users-create"
                value="0"
                {...register("visibleSite")}
              />
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <span className="flex text-lg font-medium">
              {t("UserCRUD.spp")}
            </span>
            <div className="flex gap-2 items-center">
              <label
                htmlFor="visiblePicture"
                className="flex text-lg font-medium"
              >
                {t("user.yes")}
              </label>
              <input
                type="radio"
                className="border-gray-300 focus:border-users-create focus:ring-users-create"
                value="1"
                {...register("visiblePicture")}
              />
              <label
                htmlFor="visiblePicture"
                className="flex text-lg font-medium"
              >
                {t("user.no")}
              </label>
              <input
                type="radio"
                className="border-gray-300 focus:border-users-create focus:ring-users-create"
                value="0"
                {...register("visiblePicture")}
              />
            </div>
          </div>
        </div>
        {(user?.MEMBER_STATUS === "ACTIVE" ||
          user?.MEMBER_STATUS === "EXPIRED") && (
          <div className="flex flex-col gap-2 mb-5">
            <span className="flex text-lg font-medium">
              {t("UserCRUD.ppfh")}
            </span>
            <div className="flex gap-2 items-center">
              <label
                htmlFor="isVisibleTeacher"
                className="flex text-lg font-medium"
              >
                {t("user.yes")}
              </label>
              <input
                type="radio"
                className="border-gray-300 focus:border-users-create focus:ring-users-create"
                value="1"
                {...register("isVisibleTeacher")}
              />
              <label
                htmlFor="isVisibleTeacher"
                className="flex text-lg font-medium"
              >
                {t("user.no")}
              </label>
              <input
                type="radio"
                className="border-gray-300 focus:border-users-create focus:ring-users-create"
                value="0"
                {...register("isVisibleTeacher")}
              />
            </div>
          </div>
        )}

        {user?.IS_TEACHER && user?.GALLERY_IMAGES ? (
          <div className="flex flex-col flex-grow gap-2">
            <label className="block text-lg font-medium">Gallery Images</label>
            <Controller
              name="gallery"
              control={control}
              rules={{
                validate: {
                  checkSize: (files) => {
                    if (files) {
                      const totalSize = files.reduce((acc: any, file: any) => {
                        return acc + file.size;
                      }, 0);
                      if (totalSize > 10 * 1048576) {
                        return `${t("multipleDropzone.size")}: 10MB`;
                      }
                    }
                    return true;
                  },
                },
              }}
              render={() => (
                <MultipleImageDropzone reactHookFormName="gallery" aspect={0} />
              )}
            />
            <div className="flex flex-wrap gap-5">
              {user?.GALLERY_IMAGES.map((file: any, i: number) => (
                <div
                  key={i}
                  className="flex gap-0 items-center bg-red-500 rounded-sm"
                >
                  <div className="w-16 h-9">
                    <img
                      src={file.IMAGE_URL}
                      alt={`${t("user.teacher")} ${t("user.image")}.`}
                      className="w-16 h-9 object-cover"
                    />
                  </div>
                  <button
                    type="button"
                    className="p-1"
                    onClick={() => {
                      setFilesToDelete((state: any) => [...state, file]);
                      deleteFilesFromTeacher(file.FILE_ID);
                    }}
                  >
                    <XIcon className="w-6 h-6 text-white" />
                  </button>
                </div>
              ))}
            </div>
            <DisplayFiles reactHookFormName="gallery" />
            <DisplayInputError message={(errors.gallery as any)?.message} />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default InformationTab;
