import React, { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import DisplayInputError from "shared/components/UI/DisplayInputError";
import { getInputStyles, getReactSelectStyles } from "utils/utils";
import LocationSearch from "./LocationSearch";
import Select from "react-select";
import { selectLookups } from "store/lookups/lookupsReducer";

export default function Location({
  reactHookFormName,
}: {
  reactHookFormName: string;
}) {
  const { t } = useTranslation("common");
  const form = useFormContext();
  const [regionsOptions, setRegionsOptions] = useState<any>([]);
  const [provincesOptions, setProvincesOptions] = useState<any>([]);

  const lookups = selectLookups();
  useEffect(() => {
    const regionsMap = lookups.lookupList.regions.map((region) => ({
      value: region.PK_REGION,
      label: region.PK_REGION,
    }));
    setRegionsOptions(regionsMap);
  }, [lookups.lookupList.regions]);
  useEffect(() => {
    const provinceOptions = lookups.lookupList.provinces.map((province) => ({
      value: province.PK_PROVINCE,
      label: province.NAME,
    }));
    setProvincesOptions(provinceOptions);
  }, [lookups.lookupList.provinces]);

  return (
    <div className="flex flex-col gap-5 w-full">
      <LocationSearch reactHookFormName={reactHookFormName} />
      <DisplayInputError
        message={(form.formState.errors[reactHookFormName] as any)?.message}
      />
      <div className="grid gap-2 grid-cols-3">
        <div className="flex flex-col gap-2">
          <label className="text-lg font-medium">{t("user.address")} *</label>
          <input
            type="text"
            placeholder={t("user.address")}
            className={getInputStyles(
              !!form.formState.errors[reactHookFormName]?.street_name
            )}
            {...form.register(`${reactHookFormName}.street_name`, {
              required: `${t("user.address")} ${t("user.required")}.`,
              maxLength: {
                value: 50,
                message: `${t("user.address")} ${t("user.shouldBe")} ${50} ${t(
                  "user.characters"
                )}.`,
              },
            })}
          />
          <DisplayInputError
            message={
              form.formState.errors[reactHookFormName]?.street_name?.message
            }
          />
        </div>
        <div className="flex flex-col gap-2">
          <label className="text-lg font-medium">
            {t("user.streetNumber")} *
          </label>
          <input
            type="text"
            placeholder={t("user.streetNumber")}
            className={getInputStyles(
              !!form.formState.errors[reactHookFormName]?.street_number
            )}
            {...form.register(`${reactHookFormName}.street_number`, {
              required: `${t("user.streetNumber")} ${t("user.required")}.`,
              maxLength: {
                value: 50,
                message: `${t("user.streetNumber")} ${t(
                  "user.shouldBe"
                )} ${50} ${t("user.characters")}.`,
              },
            })}
          />
          <DisplayInputError
            message={
              form.formState.errors[reactHookFormName]?.street_number?.message
            }
          />
        </div>
        <div className="flex flex-col gap-2">
          <label className="text-lg font-medium">
            {t("user.postalCode")} *
          </label>
          <input
            type="text"
            placeholder={t("user.postalCode")}
            className={getInputStyles(
              !!form.formState.errors[reactHookFormName]?.postal_code
            )}
            {...form.register(`${reactHookFormName}.postal_code`, {
              required: `${t("user.postalCode")} ${t("user.required")}.`,
              maxLength: {
                value: 50,
                message: `${t("user.postalCode")} ${t(
                  "user.shouldBe"
                )} ${50} ${t("user.characters")}.`,
              },
            })}
          />
          <DisplayInputError
            message={
              form.formState.errors[reactHookFormName]?.postal_code?.message
            }
          />
        </div>
        <div className="flex flex-col gap-2">
          <label className="text-lg font-medium">{t("user.city")} *</label>
          <input
            type="text"
            placeholder={t("user.city")}
            className={getInputStyles(
              !!form.formState.errors[reactHookFormName]?.city
            )}
            {...form.register(`${reactHookFormName}.city`, {
              required: `${t("user.city")} ${t("user.required")}`,
              maxLength: {
                value: 50,
                message: `${t("user.city")} ${t("user.shouldBe")} ${50} ${t(
                  "user.characters"
                )}.`,
              },
            })}
          />
          <DisplayInputError
            message={form.formState.errors[reactHookFormName]?.city?.message}
          />
        </div>
        <div className="flex flex-col gap-2">
          <label className="text-lg font-medium">{t("user.province")} *</label>
          <Controller
            name={`${reactHookFormName}.province`}
            control={form.control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <Select
                placeholder={t("user.province")}
                styles={getReactSelectStyles(
                  !!form.formState.errors[reactHookFormName]?.province
                )}
                isLoading={lookups.loading}
                inputId="province"
                isMulti={false}
                isClearable={true}
                options={provincesOptions}
                value={field.value}
                onChange={(e) => field.onChange(e)}
              />
            )}
          />
          {/* <input
            type="text"
            placeholder={t("user.province")}
            className={getInputStyles(
              !!form.formState.errors[reactHookFormName]?.province
            )}
            {...form.register(`${reactHookFormName}.province`, {
              required: `${t("user.province")} ${t("user.required")}.`,
              maxLength: {
                value: 50,
                message: `${t("user.province")} ${t("user.shouldBe")} ${50} ${t(
                  "user.characters"
                )}.`,
              },
            })}
          /> */}
          <DisplayInputError
            message={
              form.formState.errors[reactHookFormName]?.province?.message
            }
          />
        </div>
        <div className="flex flex-col gap-2">
          <label htmlFor="region" className="text-lg font-medium">
            {t("user.region")} *
          </label>
          <Controller
            name={`${reactHookFormName}.region`}
            control={form.control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <Select
                placeholder={t("user.region")}
                styles={getReactSelectStyles(
                  !!form.formState.errors[reactHookFormName]?.region
                )}
                isLoading={lookups.loading}
                inputId="region"
                isMulti={false}
                isClearable={true}
                options={regionsOptions}
                value={field.value}
                onChange={(e) => field.onChange(e)}
              />
            )}
          />
          <DisplayInputError
            message={
              form.formState.errors[reactHookFormName]?.region &&
              `${t("user.region")} ${t("user.required")}.`
            }
          />
        </div>
        <div className="flex flex-col gap-2">
          <label className="text-lg font-medium">{t("user.country")} *</label>
          <input
            type="text"
            placeholder={t("user.country")}
            className={getInputStyles(
              !!form.formState.errors[reactHookFormName]?.country
            )}
            {...form.register(`${reactHookFormName}.country`, {
              required: `${t("user.country")} ${t("user.required")}.`,
              maxLength: {
                value: 50,
                message: `${t("user.country")} ${t("user.shouldBe")} ${50} ${t(
                  "user.characters"
                )}.`,
              },
            })}
          />
          <DisplayInputError
            message={form.formState.errors[reactHookFormName]?.country?.message}
          />
        </div>
      </div>
    </div>
  );
}
