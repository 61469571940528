export const getNewsletterColumns: (lang: string) => {
  Header: string;
  accessor: string;
  width: string;
  disableSortBy?: boolean;
  id?: string;
}[] = (lang: string) => {
  return [
    {
      Header: lang === "en" ? "title" : "titolo",
      accessor: "col1",
      width: "250",
      id: "name",
    },
    {
      Header: lang === "en" ? "Created by" : "Creato da",
      accessor: "col2",
      width: "150",
      id: "creator_id",
    },
    {
      Header: lang === "en" ? "Created at" : "Creato il",
      accessor: "col3",
      width: "150",
      id: "created_at",
    },
    {
      Header: lang === "en" ? "Status" : "Stato",
      accessor: "col4",
      width: "150",
      id: "status",
    },
    {
      Header: lang === "en" ? "Sent at" : "Inviato il",
      accessor: "col5",
      width: "150",
      id: "sent_at",
    },
    {
      Header: lang === "en" ? "actions" : "azioni",
      accessor: "col6",
      width: "150",
      disableSortBy: true,
    },
  ];
};

export const getExportNewsletterUsersColumns: (lang: string) => {
  Header: string;
  accessor: string;
  width: string;
  disableSortBy?: boolean;
  id?: string;
}[] = (lang: string) => {
  return [
    {
      Header: lang === "en" ? "name" : "nome",
      accessor: "col1",
      width: "200",
      id: "name",
    },
    {
      Header: lang === "en" ? "Surname" : "Cognome",
      accessor: "col8",
      width: "150",
      id: "surname",
    },
    {
      Header: lang === "en" ? "Email" : "Email",
      accessor: "col8",
      width: "150",
      id: "email",
    },
    {
      Header: lang === "en" ? "region" : "regione",
      accessor: "col2",
      width: "150",
      id: "fk_region",
    },
    {
      Header: lang === "en" ? "Phone" : "Telefono",
      accessor: "col8",
      width: "150",
      id: "phone",
    },
  ];
};

export const getNewsletterDashboardColumns: (lang: string) => {
  Header: string;
  accessor: string;
  width: string;
  disableSortBy?: boolean;
  id?: string;
}[] = (lang: string) => {
  return [
    {
      Header: lang === "en" ? "Email" : "Email",
      accessor: "col0",
      width: "200",
      id: "EMAIL",
    },
    {
      Header: lang === "en" ? "name" : "nome",
      accessor: "col1",
      width: "150",
      id: "NAME",
    },
    {
      Header: lang === "en" ? "Surname" : "Cognome",
      accessor: "col2",
      width: "150",
      id: "SURNAME",
    },
    {
      Header: lang === "en" ? "region" : "regione",
      accessor: "col3",
      width: "150",
      id: "REGION",
    },
    {
      Header: lang === "en" ? "Phone" : "Telefono",
      accessor: "col4",
      width: "150",
      id: "TELEPHONE",
    },
    {
      Header: lang === "en" ? "Member status" : "Stato membro",
      accessor: "col5",
      width: "150",
      id: "member_status",
      disableSortBy: true,
    },
    {
      Header: lang === "en" ? "Sent status" : "Stato invio",
      accessor: "col6",
      width: "100",
      id: "SENT_STATUS",
    },
    {
      Header: lang === "en" ? "Opened?" : "Aperto",
      accessor: "col7",
      width: "100",
      id: "OPENED",
    },
    {
      Header: lang === "en" ? "Date opened" : "Data apertura",
      accessor: "col8",
      width: "150",
      id: "OPEN_DATE",
    },
  ];
};

export interface SelectOption {
  label: string;
  value: any;
}

export const createEntityRecipientFilters = (
  permissions: any,
  lang: string
) => {
  return [
    (permissions["*"] || permissions?.newsletterActiveMembers.ENABLED) && {
      value: "teachers",
      label:
        lang === "en"
          ? "Teachers/Patreons with active sub"
          : "Insegnanti/Patreon con sub attivo",
    },
    (permissions["*"] || permissions?.newsletterResignedMembers.ENABLED) && {
      value: "resignedTeachers",
      label: lang === "en" ? "Resigned teachers" : "Docenti rassegnati",
    },
    (permissions["*"] || permissions?.newsletterResignedMembers.ENABLED) && {
      value: "nonActiveTeachers",
      label: lang === "en" ? "Expired teachers" : "Insegnanti scaduti",
    },
    (permissions["*"] || permissions?.newsletterSimpleUsers.ENABLED) && {
      value: "users",
      label: lang === "en" ? "All users" : "Tutti gli utenti",
    },
    (permissions["*"] || permissions?.newsletterSimpleUsers.ENABLED) && {
      value: "all",
      label:
        lang === "en"
          ? "All users subscribed to newsletter"
          : "Tutti gli utenti sono iscritti alla newsletter",
    },
  ].filter((f) => f !== false);
};

export const setRecipientFilters = (
  entityOptions: {
    all: boolean;
    nonActiveTeachers: boolean;
    teachers: boolean;
    users: boolean;
    resignedTeachers: boolean;
  },
  lang: string
) => {
  const filterOptions = [];
  if (entityOptions.all)
    filterOptions.push({
      value: "all",
      label:
        lang === "en"
          ? "All users subscribed to newsletter"
          : "Tutti gli utenti sono iscritti alla newsletter",
    });
  if (entityOptions.nonActiveTeachers)
    filterOptions.push({
      value: "nonActiveTeachers",
      label: lang === "en" ? "Expired teachers" : "Insegnanti scaduti",
    });
  if (entityOptions.teachers)
    filterOptions.push({
      value: "teachers",
      label:
        lang === "en"
          ? "Teachers/Patreons with active sub"
          : "Insegnanti/Patreon con sub attivo",
    });
  if (entityOptions.users)
    filterOptions.push({
      value: "users",
      label: lang === "en" ? "All users" : "Tutti gli utenti",
    });
  if (entityOptions.resignedTeachers)
    filterOptions.push({
      value: "resignedTeachers",
      label: lang === "en" ? "Resigned teachers" : "Docenti rassegnati",
    });

  return filterOptions;
};

export const componentsOptions = {
  DropdownIndicator: null,
};

export interface Option {
  readonly label: string;
  readonly value: string;
}

export const createOption = (label: string) => ({
  label,
  value: label,
});

export const setCustomMailRecipients: (emails: SelectOption[]) => string[] = (
  emails
) => emails.map((mail) => mail.value);

export const sentStatusOptions = [
  { label: "Sent", value: "SENT" },
  { label: "Bounced", value: "BOUNCED" },
  { label: "Pending", value: "PENDING" },
];

export const openedStatusOptions = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];

// export const constructGetNewsletterTrackingQuery = () => {
//   const url =
// }
