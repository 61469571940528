import React, { useState } from "react";
import { Plan } from "api/Plans/plans.api";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { getReactSelectStyles } from "utils/utils";
import DisplayInputError from "../UI/DisplayInputError";
import SubscriptionCard from "./SubscriptionCard";
import { toast } from "react-toastify";
import { CMS_BE_URL } from "api/urls";
import axios from "axios";
import { useAppDispatch } from "store/storeHooks";
import { getPlansThunk } from "store/plansStore/plansReducer";

const PatreonPlans = ({
  userRanks,
  patreons,
}: {
  userRanks: any;
  patreons: Plan[];
}) => {
  const { t } = useTranslation("common");
  const [patreonView, setPatreonView] = useState(1);
  const dispatch = useAppDispatch();

  // TOASTIFY
  const SubUpdated = () => (
    <div className="flex flex-col gap-1">
      <p className="text-xs font-semibold">{t("subs.msgHeader")}</p>
      <p className="text-xs">{t("subs.subCreated")}</p>
    </div>
  );

  const displaySubUpdated = () => {
    toast.success(SubUpdated);
  };

  const SubErrorMsg = () => (
    <div className="flex flex-col gap-1">
      <p className="text-xs font-semibold">{t("subs.msgHeaderError")}</p>
      <p className="text-xs">{t("subs.subCreatedError")}</p>
    </div>
  );

  const displaySubErrorMsg = () => {
    toast.error(SubErrorMsg);
  };

  // OBJECT WITH THE TRANSLATION PROPERTY FOR EACH PLAN'S HEADER
  const headerTranslation: string[] = [
    "supPartnerAmatNot",
    "supPartnerAmat",
    "sTeacher",
    "supPartnerSchool",
  ];

  // CREATING THE DEFAULT VALUES OBJECT
  // WE PASS TO REACT HOOK FORM FROM THE
  // PATREON PLANS WE RECEIVE AS A PROP
  const defaultValues: any = {};
  patreons?.forEach((plan: Plan) => {
    defaultValues[`type${plan.id}`] = "patreon";
    defaultValues[`title${plan.id}`] = plan.title ?? "";
    defaultValues[`price${plan.id}`] = plan.price ?? 0;
    defaultValues[`rank${plan.id}`] = {
      label: plan.rank?.rank_eng ?? "",
      value: plan.rank?.id ?? 0,
    };
    defaultValues[`descENG${plan.id}`] = plan.description_eng ?? "";
    defaultValues[`descITA${plan.id}`] = plan.description_it ?? "";
    defaultValues[`fee${plan.id}`] = !plan.including_fees;
    defaultValues[`visibility${plan.id}`] = plan.is_visible ?? false;
  });

  // INITIALIZING FORM
  const {
    register,
    handleSubmit,
    getValues,
    control,
    formState: { errors, dirtyFields },
    setValue,
  } = useForm({
    mode: "onChange",
    defaultValues,
  });

  // GETTING THE INDEXES FROM THE PLANS THAT HAVE DIRTY FIELDS
  const dirtyPlans: string[] = [];
  Object.keys(dirtyFields)?.forEach((field: string) =>
    dirtyPlans.push(field.slice(-1))
  );

  const selectRankHandler = (e: any, i: string) => {
    dirtyPlans.push(i);
    setValue(`rank${i}`, {
      label: e.label,
      value: e.value,
    });
  };

  const onSubmit = async () => {
    const dirtyPlansUnique = [...new Set(dirtyPlans)];

    // CREATING AN OBJECT FOR EACH PLAN WITH DIRTY FIELDS
    // TO UPDATE ONLY THEM
    const updateObject: any[] = [];
    dirtyPlansUnique.forEach((plan: string) =>
      updateObject.push({
        url: plan,
        updateData: {
          type: "patreon",
          title: getValues(`title${plan}`),
          rank: getValues(`rank${plan}.value`),
          price: getValues(`price${plan}`),
          description_eng: getValues(`descENG${plan}`),
          description_it: getValues(`descITA${plan}`),
          including_fees: !getValues(`fee${plan}`),
          is_visible: getValues(`visibility${plan}`),
        },
      })
    );

    if (updateObject.length === 0) return setPatreonView(1);

    try {
      // SENDING UPDATE CALL FOR EACH PLAN DIRTY
      await Promise.all([
        updateObject.forEach((obj) =>
          axios.put(
            `${CMS_BE_URL}/subscriptionPlans/${obj.url}`,
            obj.updateData
          )
        ),
      ]).then(() => {
        displaySubUpdated();
        dispatch(getPlansThunk);
        setPatreonView(1);
      });
    } catch (err) {
      displaySubErrorMsg();
    }
  };

  return (
    <>
      {patreonView === 1 ? (
        <div>
          <div className="flex gap-5 items-center mb-8">
            <h2 className="text-2xl font-bold leading-9">
              {t("subs.patreons")}
            </h2>
            <button
              type="button"
              className="px-5 py-1 text-white text-sm bg-users-create"
              onClick={() => setPatreonView(2)}
            >
              {t("RolesCRUD.editButton")}
            </button>
          </div>
          <div className="flex flex-wrap gap-4">
            {patreons &&
              patreons.map((plan: Plan) => (
                <div key={plan.id}>
                  <SubscriptionCard plan={plan} />
                </div>
              ))}
          </div>
        </div>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex gap-2 items-center justify-end w-full">
            <div
              onClick={() => setPatreonView(1)}
              className="mb-5 px-5 py-1 text-sm bg-white border border-black cursor-pointer"
            >
              {t("RolesCRUD.cancelButton")}
            </div>
            <input
              className="mb-5 px-5 py-1 text-white text-sm bg-users-create border border-users-create cursor-pointer"
              type="submit"
              value={t("plans.save") as string}
            />
          </div>
          <div className="flex gap-2 items-center justify-end w-full">
            {/* CREATING FORM INPUT FIELDS DYNAMICALLY FOR EACH PATREON PLAN */}
            {patreons.map((plan: Plan, i: number) => {
              return (
                <div className="flex-1" key={`patreon-plan-${plan.id}`}>
                  <p className="mb-1 text-lg font-semibold">
                    {t(`UserCRUD.${headerTranslation[i]}`)}
                  </p>
                  <div className="mb-1">
                    <label className="block">
                      {t("CertificateCRUD.title")}
                    </label>
                    <input
                      className="block w-full focus:border-black border-gray-300 shadow-sm focus:ring-indigo-500 sm:text-sm"
                      defaultValue={plan.title}
                      type="text"
                      {...register(`title${plan.id}`)}
                    />
                  </div>
                  <div className="mb-1">
                    <label className="block">{t("subs.py")}</label>
                    <input
                      className="block w-full focus:border-black border-gray-300 shadow-sm focus:ring-indigo-500 sm:text-sm"
                      min={1}
                      defaultValue={plan.price}
                      type="number"
                      {...register(`price${plan.id}`)}
                    />
                  </div>
                  <div>
                    <label htmlFor="tags" className="block text-lg font-medium">
                      Rank *
                    </label>
                    <Controller
                      name={`rank${plan.id}`}
                      control={control}
                      rules={{
                        required: `Categories ${t("new.areRequired")}.`,
                      }}
                      render={() => (
                        <Select
                          placeholder="userRanks"
                          value={getValues(`rank${plan.id}`)}
                          styles={getReactSelectStyles(
                            !!errors[`rank${plan.id}`]
                          )}
                          options={userRanks}
                          onChange={(e: any) =>
                            selectRankHandler(e, plan.id.toString())
                          }
                        />
                      )}
                    />
                    <DisplayInputError
                      message={(errors[`rank${plan.id}`] as any)?.message}
                    />
                  </div>
                  <div className="mt-2">
                    <div>
                      <label className="block">{t("subs.itaI")}</label>
                      <textarea
                        className="w-full border border-gray-300"
                        {...register(`descITA${plan.id}`)}
                      />
                    </div>
                    <div>
                      <label className="block">{t("subs.engI")}</label>
                      <textarea
                        className="w-full border border-gray-300"
                        {...register(`descENG${plan.id}`)}
                      />
                    </div>
                  </div>
                  <div className="flex gap-5 items-center">
                    <label>{t("subs.inclFees")}</label>
                    <input type="checkbox" {...register(`fee${plan.id}`)} />
                  </div>
                  <div className="flex gap-5 items-center">
                    <label>{t("plans.visibleSite")}</label>
                    <input
                      type="checkbox"
                      {...register(`visibility${plan.id}`)}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </form>
      )}
    </>
  );
};

export default PatreonPlans;
