import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  deleteUser,
  getUser,
  getUsers,
  resendEmailCreation,
  resendEmailRegister,
  resetPassword,
  usersBulkUpdate,
} from "api/Users/users.api";
import { useAppSelector } from "store/storeHooks";
import { usersInitialState } from "./usersState";
import { toast } from "react-toastify";

export const getUsersThunk = createAsyncThunk(
  "usersStore/getUsers",
  (filters: {
    role?: string;
    location?: string;
    rank?: string;
    status?: string;
    pageSize?: number;
    pageIndex?: number;
    searchString?: string;
    sortCol?: string;
    sortOrder?: string;
    including?: string;
  }) => getUsers(filters).then((res) => res.data)
);

export const getUserThunk = createAsyncThunk(
  "usersStore/getUser",
  async ({ username }: { username: string }) => {
    const res = await getUser(username);
    return res.data;
  }
);

export const deleteUserThunk = createAsyncThunk(
  "usersStore/deleteUser",
  async ({ username, isHard }: { username: string; isHard: boolean }) => {
    const res = await deleteUser(username, isHard);
    return res.data;
  }
);

export const resendEmailCreationThunk = createAsyncThunk(
  "usersStore/resendEmailCreation",
  async ({ email }: { email: string }) => {
    const res = await resendEmailCreation(email);
    return res.data;
  }
);

export const resendEmailRegisterThunk = createAsyncThunk(
  "usersStore/resendEmailRegister",
  async ({ email }: { email: string }) => {
    const res = await resendEmailRegister(email);
    return res.data;
  }
);

export const resetPasswordThunk = createAsyncThunk(
  "usersStore/resetPassword",
  async ({ email }: { email: string }) => {
    const res = await resetPassword(email);
    return res.data;
  }
);

export const usersBulkUpdateThunk = createAsyncThunk(
  "usersStore/usersBulkUpdate",
  async (payloadObject: any) => {
    try {
      const res = await usersBulkUpdate(payloadObject);
      return res.data;
    } catch (err: any) {
      if (err.response?.data?.errors?.[0]?.message) {
        toast.error(err.response?.data?.errors?.[0]?.message);
      } else {
        toast.error(err.response?.data?.message);
      }
    }
  }
);

const usersSlice = createSlice({
  name: "usersStore",
  initialState: usersInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUsersThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUsersThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(getUsersThunk.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getUserThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUserThunk.fulfilled, (state, action) => {
      state.loading = false;
      // state.data.user = action.payload;
    });
    builder.addCase(getUserThunk.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(deleteUserThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteUserThunk.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteUserThunk.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(resendEmailCreationThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(resendEmailCreationThunk.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(resendEmailCreationThunk.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(resendEmailRegisterThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(resendEmailRegisterThunk.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(resendEmailRegisterThunk.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(resetPasswordThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(resetPasswordThunk.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(resetPasswordThunk.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(usersBulkUpdateThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(usersBulkUpdateThunk.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(usersBulkUpdateThunk.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const selectUsers = () => useAppSelector((state) => state.users);
export const selectRanks = () => useAppSelector((state) => state.users.ranks);
export const selectStatuses = () =>
  useAppSelector((state) => state.users.statuses);
export const selectMembershipStatuses = () =>
  useAppSelector((state) => state.users.membershipStatuses);

export default usersSlice;
