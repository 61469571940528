import React from "react";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { useAppSelector } from "store/storeHooks";
import { newsletterInitialState } from "./newsletterState";
import { CMS_BE_URL } from "api/urls";
import { toast } from "react-toastify";

export const getNewsletterThunk = createAsyncThunk(
  "newsletterStore/getNewsletter",
  async (filters: {
    user?: string;
    tags?: string;
    pageSize?: number;
    pageIndex?: number;
    sortCol?: string;
    sortOrder?: string;
    searchString?: string;
  }) => {
    try {
      const q: string[] = [];
      Object.entries(filters).forEach(([key, value]) => {
        if (value) q.push(`${key}=${value}`);
      });
      const res = await axios.get(
        `${CMS_BE_URL}/newsletter?`.concat(q.join("&"))
      );
      return res.data;
    } catch (err: any) {
      if (err.response?.data?.errors?.[0]?.message) {
        toast.error(err.response?.data?.errors?.[0]?.message);
      } else {
        toast.error(err.response?.data?.message);
      }
    }
  }
);

export const deleteNewsletterThunk = createAsyncThunk(
  "newsletterStore/deleteNewsletter",
  async (newsletter_id: string) => {
    const res = await axios.delete(`${CMS_BE_URL}/newsletter/${newsletter_id}`);
    return res.data;
  }
);

export const getNewsletterUsersThunk = createAsyncThunk(
  "newsletterStore/getNewsletterUsers",
  async (object: any) => {
    const res = await axios.post(`${CMS_BE_URL}/newsletter/export`, object);
    return res.data;
  }
);

const newsletterSlice = createSlice({
  name: "newsletterSlice",
  initialState: newsletterInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getNewsletterThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getNewsletterThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(getNewsletterThunk.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getNewsletterUsersThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getNewsletterUsersThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.data.users = action.payload.users?.length;
    });
    builder.addCase(getNewsletterUsersThunk.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const selectNewsletter = () =>
  useAppSelector((state) => state.newsletter);

export default newsletterSlice;
