import React from "react";
// import "./ANMBLogo.scss";

function ANMBLogo(): JSX.Element {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 464.06 80.2"
    >
      <defs>
        <style>
          {`.cls-1 {
          fill: #ffffff;
          }`}
        </style>
      </defs>
      <path
        className="cls-1"
        d="M102.61,86,62.08,21.45,20.89,86H4.37L56.61,5.84H67.44L119.35,86Z"
        transform="translate(-4.37 -5.84)"
      />
      <path
        className="cls-1"
        d="M223.39,86,139.56,23.61V86h-13V5.84h12.83L217.7,65.42V5.84h13V86Z"
        transform="translate(-4.37 -5.84)"
      />
      <path
        className="cls-1"
        d="M351.1,86V29.88L311.14,86H299L258.89,30V86H245.16V5.84H259l46.67,65.5L352.1,5.84h12.73V86Z"
        transform="translate(-4.37 -5.84)"
      />
      <path
        className="cls-1"
        d="M379.34,86V5.84h64A44.85,44.85,0,0,1,454.42,7a20.27,20.27,0,0,1,7.48,3.41,13.31,13.31,0,0,1,4.18,5.47,18.9,18.9,0,0,1,1.34,7.24v7.4a15.05,15.05,0,0,1-2.51,8.83,10.75,10.75,0,0,1-6.75,4.5,16,16,0,0,1,7.36,4.27c1.94,2,2.91,5,2.91,8.83V68.72a18.31,18.31,0,0,1-1.18,6.55,13.32,13.32,0,0,1-4,5.53,21.22,21.22,0,0,1-7.65,3.82A43.13,43.13,0,0,1,443.53,86ZM452.57,24a5.54,5.54,0,0,0-2-4.78c-1.34-1-3.75-1.48-7.25-1.48H394.19V38.53h49.12c3.5,0,5.91-.49,7.25-1.48s2-2.58,2-4.78Zm1,33.27c0-2.51-.93-4.27-2.79-5.3a15,15,0,0,0-7.26-1.54H394.19V74.19h49.34a13.74,13.74,0,0,0,7.26-1.71q2.79-1.71,2.79-6.6Z"
        transform="translate(-4.37 -5.84)"
      />
    </svg>
  );
}

export default ANMBLogo;
