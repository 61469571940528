import { Lang } from "shared/components/UI/Message";
import { CreateLocation, Location } from "./Location.interface";
import { Tag } from "./Tag.interface";

export type New = {
  news_id: string;
  is_visible: boolean;
  creator_id: string;
  styles_json: string;
  title: string;
  richtext: string;
  thumbnail: string;
  location: Location;
  short_description: string;
  author: string;
  featured: boolean;
  created_at: string;
  updated_at: string;
  last_modified: string;
  tags: Tag[];
  files: string[];
};

export type CreateNew = {
  is_visible?: string;
  title?: string;
  styles_json?: string;
  richtext?: any;
  tags?: string;
  location?: string;
  short_description?: string;
  author?: string;
  featured?: string;
  fileType?: string;
  thumbnail?: File;
  media?: File[];
};

export type SelectFeaturedType = {
  value: string;
  label: string;
};

export function getFeaturedOptions(lang: Lang): SelectFeaturedType[] {
  if (lang === "en") {
    return [
      { label: "Normal", value: "false" },
      { label: "Featured", value: "true" },
      { label: "All", value: "" },
    ];
  } else {
    return [
      { label: "Normale", value: "false" },
      { label: "In evidenza", value: "true" },
      { label: "Tutti", value: "" },
    ];
  }
}

export const featuredOptions: SelectFeaturedType[] = [
  { label: "Normal", value: "false" },
  { label: "Featured", value: "true" },
  { label: "All", value: "" },
];
