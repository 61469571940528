import { CMS_BE_URL } from "api/urls";

export function createCouncilRankURL() {
  return `${CMS_BE_URL}/ranks/cr`;
}

export function getCouncilRanksURL(filters: {
  pageSize?: number;
  pageIndex?: number;
  sortCol?: string;
  sortOrder?: string;
}) {
  return `${CMS_BE_URL}/ranks/cr${Object.entries(filters)
    .filter(([, value]) => !!value)
    .map(([key, value]) => `${key}=${value}`)
    .join("&")}`;
}

export function deleteCouncilRankURL({ rankId }: { rankId: string }) {
  return `${CMS_BE_URL}/ranks/cr/${rankId}`;
}

export function getCouncilRankURL({ rankId }: { rankId: string }) {
  return `${CMS_BE_URL}/ranks/cr/${rankId}`;
}

export function updateCouncilRankURL({ rankId }: { rankId: string }) {
  return `${CMS_BE_URL}/ranks/cr/${rankId}`;
}
