import axios from "axios";
import { CreateCouncilDetails } from "shared/interfaces/Council.interface";
import {
  createCouncilURL,
  deleteCouncilURL,
  deleteMemberFromCouncilURL,
  getCouncilURL,
  getCouncilsURL,
  updateCouncilURL,
} from "./urls";

export function createCouncil({ data }: { data: CreateCouncilDetails }) {
  const formData = new FormData();
  Object.entries(data).map(([key, value]: any) => {
    formData.append(key, value);
  });
  return axios.post(createCouncilURL(), formData);
}

export function getCouncils(filters: {
  pageSize: number;
  pageIndex: number;
  sortCol: string;
  sortOrder: string;
  searchString: string;
}) {
  return axios.get(getCouncilsURL(filters));
}

export function deleteCouncil({ council }: { council: string }) {
  return axios.delete(deleteCouncilURL({ council }));
}

export function updateCouncil({
  council,
  data,
}: {
  council: string;
  data: CreateCouncilDetails;
}) {
  const formData = new FormData();
  Object.entries(data).map(([key, value]: any) => {
    formData.append(key, value);
  });
  return axios.put(updateCouncilURL({ council }), formData);
}

export function getCouncil({ council }: { council: string }) {
  return axios.get(getCouncilURL({ council }));
}

export function deleteMemberFromCouncil({
  council,
  userId,
}: {
  council: string;
  userId: string;
}) {
  return axios.delete(deleteMemberFromCouncilURL({ council, userId }));
}

export const getCouncilsColumns: (lang: string) => {
  Header: string;
  accessor: string;
  width: string;
  disableSortBy?: boolean;
  id?: string;
}[] = (lang: string) => {
  return [
    {
      Header: lang === "en" ? "rank" : "rango",
      accessor: "col1",
      width: "150",
      id: "RANK",
    },
    {
      Header: lang === "en" ? "name" : "nome",
      accessor: "col2",
      width: "200",
      id: "NAME",
    },
    {
      Header: lang === "en" ? "phone" : "telefono",
      accessor: "col3",
      width: "150",
      disableSortBy: true,
    },
    {
      Header: lang === "en" ? "actions" : "azioni",
      accessor: "col4",
      width: "100",
      id: "actions",
    },
  ];
};
