import React, { useEffect, useCallback } from "react";
import { useAppDispatch } from "store/storeHooks";
import { getUsersThunk } from "store/usersStore/usersReducer";
import { useTranslation } from "react-i18next";
import {
  FieldError,
  useForm,
  UseFormGetValues,
  UseFormSetValue,
} from "react-hook-form";
import Select, { GroupBase, OptionsOrGroups } from "react-select";
import { fillRankLabel, getReactSelectStyles } from "utils/utils";
import "react-datepicker/dist/react-datepicker.css";
import "shared/styles/datePicker.css";
import {
  getCouncilRanksThunk,
  selectCouncilRanks,
} from "store/councilRanks/councilRanksReducer";
import * as R from "ramda";
import { User, UserLowercase } from "shared/interfaces/User.interface";
import AsyncSelect from "react-select/async";
import { debounce } from "lodash";
import { selectTranslationLanguage } from "store/translationStore/translationReducer";
import {
  getPresidentialRanksThunk,
  selectPresidentialRanks,
} from "store/presidentialRanks/presidentialRanksReducer";
import {
  CouncilRank,
  PresidentialRank,
} from "shared/interfaces/Rank.interface";
import DisplayInputError from "shared/components/UI/DisplayInputError";
import { DevTool } from "@hookform/devtools";

const SelectUser = ({
  defaultUser,
  isOpen,
  setIsOpen,
  reactHookFormName,
  propGetValues,
  propSetValue,
  disabledRank = false,
  rankToDisplay,
  disabledPriority = false,
  onComplete,
  excludeUsers,
  onClose,
  usePresidentialRanks = false,
}: {
  defaultUser?: any;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  reactHookFormName: string;
  propGetValues: UseFormGetValues<any>;
  propSetValue: UseFormSetValue<any>;
  disabledRank?: boolean;
  rankToDisplay?: CouncilRank | PresidentialRank;
  disabledPriority?: boolean;
  onComplete?: (user: any, rank: any, priority: any) => any;
  excludeUsers?: any;
  onClose?: any;
  usePresidentialRanks?: boolean;
}) => {
  const { t } = useTranslation("common");
  const lang = selectTranslationLanguage();
  const dispatch = useAppDispatch();
  const form = useForm<{
    user: UserLowercase;
    rank: CouncilRank | PresidentialRank;
    priority: number;
  }>({
    mode: "onChange",
    defaultValues: {},
  });

  useEffect(() => {
    if (defaultUser) {
      form.setValue("rank", defaultUser.rank);
      form.setValue("priority", defaultUser.rank.priority);
    }
  }, [defaultUser]);

  useEffect(() => {
    if (usePresidentialRanks) {
      dispatch(getPresidentialRanksThunk({}));
    } else {
      dispatch(getCouncilRanksThunk({}));
    }
  }, []);

  useEffect(() => {
    form.register("user", {
      required: "User is required",
    });
    if (!disabledRank) {
      form.register("rank", {
        required: "Rank is required.",
      });
    }
    if (!disabledPriority) {
      form.register("priority", {
        required: "Priority is required",
      });
    }
  }, []);

  const reduxRanks = usePresidentialRanks
    ? selectPresidentialRanks()
    : selectCouncilRanks();
  const ranks = R.clone(reduxRanks.data.councilRanks);
  const councilRanksOptions: OptionsOrGroups<any, GroupBase<any>> | undefined =
    ranks?.map(function setOptions(rank) {
      return {
        value: rank,
        label: usePresidentialRanks
          ? lang === "en"
            ? (rank as PresidentialRank).presidential_rank_eng
            : (rank as PresidentialRank).presidential_rank_it
          : lang === "en"
          ? (rank as CouncilRank).council_rank_eng
          : (rank as CouncilRank).council_rank_it,
      };
    }) || [];

  useEffect(() => {
    if (defaultUser) {
      dispatch(
        getUsersThunk(constructGetUsersThunkAction(defaultUser.pk_user))
      ).then((res) => {
        form.setValue("user", res.payload.users[0]);
      });
    }
  }, []);

  function constructGetUsersThunkAction(e: string) {
    return {
      pageSize: 10,
      searchString: e,
    };
  }

  const _loadOptions = (e: string, callback: any): any => {
    if (e === "") return callback();
    dispatch(getUsersThunk(constructGetUsersThunkAction(e))).then(
      (res: any) => {
        let filterOutExistingBoardMembers;
        if (defaultUser) {
          filterOutExistingBoardMembers = (user: UserLowercase) => {
            if (user.pk_user === defaultUser.pk_user) return true;
            else
              return R.equals(
                R.findIndex(R.propEq("pk_user", user.pk_user))(excludeUsers),
                -1
              );
          };
        } else {
          filterOutExistingBoardMembers = (user: UserLowercase) => {
            return R.equals(
              R.findIndex(R.propEq("pk_user", user.pk_user))(excludeUsers),
              -1
            );
          };
        }
        const filteredUsers = R.filter(
          filterOutExistingBoardMembers,
          res.payload.users
        );
        const usersOptions = filteredUsers.map((user: UserLowercase) => {
          return {
            value: user,
            label: `${user.name} - ${user.surname} - ${user.email}${
              user.address_details[0]
                ? ` - ${user.address_details[0].fk_region}`
                : ""
            }`,
          };
        });
        callback(usersOptions);
      }
    );
  };
  const loadOptions = useCallback(debounce(_loadOptions, 400), []);

  const onSubmit = form.handleSubmit((data) => {
    if (onComplete) onComplete(data.user, data.rank, data.priority);
    if (onClose) onClose();
    propSetValue(`${reactHookFormName}pk_user`, data.user.pk_user);
    if (!disabledRank) {
      propSetValue(`${reactHookFormName}rank`, data.rank);
    }
    if (!disabledPriority) {
      propSetValue(`${reactHookFormName}priority`, data.priority);
    }
    setIsOpen(false);
  });

  return (
    <div className="flex flex-col flex-grow gap-5">
      <div className="flex flex-grow gap-5 justify-between">
        <div className="flex flex-col flex-grow gap-5 items-center justify-between w-full">
          <div className="flex flex-col gap-2 w-full">
            <label htmlFor="searchUser" className="text-lg font-medium">
              {t("selectUser.searchUser")}
            </label>
            <AsyncSelect
              cacheOptions
              defaultOptions
              value={
                form.watch("user")
                  ? {
                      value: form.watch("user"),
                      label: `${form.watch("user")?.name} - ${
                        form.watch("user")?.surname
                      } - ${form.watch("user")?.email}${
                        form.watch("user")?.address_details[0]
                          ? ` - ${
                              form.watch("user")?.address_details[0]?.fk_region
                            }`
                          : ""
                      }`,
                    }
                  : undefined
              }
              placeholder={t("selectUser.selectUser")}
              styles={getReactSelectStyles(false)}
              inputId="searchUser"
              isClearable={true}
              loadOptions={loadOptions}
              onChange={(e: any) => {
                if (e) {
                  form.setValue("user", e.value);
                  form.trigger("user");
                }
              }}
            />
            <DisplayInputError
              message={
                (form.formState.errors.user as FieldError | undefined)?.message
              }
            />
          </div>
          <div className="flex flex-col gap-2 w-full">
            <label htmlFor="rank" className="text-lg font-medium">
              {t("selectUser.assignRank")}
            </label>
            <Select
              placeholder={t("selectUser.selectRank")}
              styles={getReactSelectStyles(false)}
              inputId="rank"
              isMulti={false}
              isClearable={true}
              isDisabled={disabledRank}
              value={
                disabledRank
                  ? {
                      label: rankToDisplay,
                      value: rankToDisplay,
                    }
                  : {
                      label: fillRankLabel(form.watch("rank"), lang),
                      value: form.watch("rank"),
                    }
              }
              onChange={
                disabledRank
                  ? undefined
                  : function (e: any) {
                      if (e) {
                        form.setValue("rank", e.value);
                        form.setValue("priority", e.value.priority);
                        form.trigger(["rank", "priority"]);
                      }
                    }
              }
              options={councilRanksOptions}
              isLoading={reduxRanks.loading}
            />
            <DisplayInputError
              message={
                (form.formState.errors.rank as FieldError | undefined)?.message
              }
            />
          </div>
          <button
            type="button"
            onClick={onSubmit}
            className="self-end px-4 py-2 text-white bg-global-save rounded-sm"
          >
            {t("selectUser.done")}
          </button>
        </div>
      </div>
      <DevTool control={form.control} />
    </div>
  );
};

export default SelectUser;
