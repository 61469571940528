import React from "react";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { tagsInitialState } from "./tagsState";
import { getTags, deleteTag, updateTag, createTag } from "api/Tags/tags.api";
import { useAppSelector } from "store/storeHooks";
import { TagCreate, TagUpdate } from "shared/interfaces/Tag.interface";

export const createTagThunk = createAsyncThunk(
  "tagsStore/createTag",
  async ({ tag_name }: { tag_name: string }) => {
    const data: TagCreate = { tag_name };
    const res = await createTag(data);
    return {
      message: `${tag_name}${res.data.mesasge}`,
    };
  }
);

export const getTagsThunk = createAsyncThunk("tagsStore/getTags", async () => {
  const response = await getTags();
  return response.data;
});

export const updateTagThunk = createAsyncThunk(
  "tagsStore/updateTag",
  async ({ tagName, data }: { tagName: string; data: TagUpdate }) => {
    const res = await updateTag(tagName, data);
    return `${tagName} ${res?.data.message.toLowerCase()}`;
  }
);

export const deleteTagThunk = createAsyncThunk(
  "tagsStore/deleteTag",
  async (tagName: string) => {
    const res = await deleteTag(tagName);
    return `${tagName} ${res?.data.message.toLowerCase()}`;
  }
);

const tagsSlice = createSlice({
  name: "tagsStore",
  initialState: tagsInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createTagThunk.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(createTagThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.message = action.payload?.message;
    });

    builder.addCase(createTagThunk.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getTagsThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTagsThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.data = {
        total: action.payload.total,
        pageIndex: action.payload.pageIndex,
        tags: action.payload.tags,
      };
    });
    builder.addCase(getTagsThunk.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(updateTagThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateTagThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.message = action.payload;
    });
    builder.addCase(updateTagThunk.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(deleteTagThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteTagThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.message = action.payload;
    });
    builder.addCase(deleteTagThunk.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const selectTags = () => useAppSelector((state) => state.tags);

export default tagsSlice;
