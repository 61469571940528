import React, { useEffect, useState, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "store/storeHooks";
import {
  Column,
  useFlexLayout,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { SearchIcon, PlusIcon } from "@heroicons/react/solid";
import {
  BanIcon,
  DotsVerticalIcon,
  ExclamationIcon,
  FilterIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/outline";
import { Link } from "react-router-dom";
import { useDebounce } from "shared/hooks/useDebounce";
import Pagination from "shared/components/Table/Pagination";
import Table from "shared/components/Table/Table";
import Modal from "shared/components/UI/Modal";
import { useTranslation } from "react-i18next";
import { selectLoggedInUser } from "store/authStore/authReducer";
import { getCertificationsColumns } from "api/Certificates/certificates.api";
import {
  deleteCertificateThunk,
  getCertificatesThunk,
  selectBadges,
  selectCodes,
} from "store/certificatesStore/certificationsReducer";
import { Certificate } from "shared/interfaces/Certificate.interface";
import Spinner from "shared/components/UI/Spinner";
import Overlay from "shared/components/UI/Overlay";
import TablePopup, { PortalType } from "shared/components/UI/TablePopup";
import { CertificatesFiltersType } from "./types";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Message from "shared/components/UI/Message";
import { PermissionDomain } from "shared/interfaces/Permission.interface";
import { RolePermissionCrudAction } from "shared/interfaces/RolePermission.interface";
import {
  badgeToQuery,
  capitalizeFirst,
  codeToQuery,
  getReactSelectStyles,
} from "utils/utils";
import Select from "react-select";
import { selectTranslationLanguage } from "store/translationStore/translationReducer";

const Certificates: () => JSX.Element = () => {
  const dispatch = useAppDispatch();
  const [filtersOpen, setFiltersOpen] = useState(false);
  const certificates = useAppSelector((state) => state.certificates);
  const loggedInUser = selectLoggedInUser();
  const { t } = useTranslation("common");
  const lang = selectTranslationLanguage();
  const {
    register,
    setValue,
    getValues,
    formState: { errors },
    control,
    watch,
  } = useForm<CertificatesFiltersType>({
    defaultValues: {
      badge: undefined,
      code: undefined,
      pageSize: 10,
      pageIndex: 0,
      searchString: "",
    },
  });
  const debouncedSearchText = useDebounce(watch("searchString"), 300);
  const [certificateToDelete, setCertificateToDelete] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const handleModal = (certificateToDelete: string) => {
    setCertificateToDelete(certificateToDelete);
    setOpenModal(true);
  };
  const [portal, setPortal] = useState<PortalType<Certificate>>({
    open: false,
    top: 0,
    left: 0,
    entity: null,
  });

  function canAccess(
    domain: PermissionDomain,
    action: RolePermissionCrudAction
  ): boolean {
    if (loggedInUser.permissions["*"]) {
      return true;
    } else {
      switch (loggedInUser.permissions[domain][action]) {
        case "NONE": {
          return false;
        }
        case "OWN": {
          return true;
        }
        case "ANY": {
          return true;
        }
        default: {
          return false;
        }
      }
    }
  }

  function displayActions(certificate: Certificate, i: number) {
    const canUpdate = canAccess("certificates", "UPDATE");
    const canDelete = canAccess("certificates", "DELETE");
    if (!canUpdate && !canDelete) {
      return (
        <div className="flex items-center h-full">
          <BanIcon className="w-5 h-5" />
        </div>
      );
    }
    return (
      <div className="relative flex items-center h-full">
        <button
          id={`portal-button-${i}`}
          type="button"
          onClick={() => {
            return setPortal((state) => {
              const thisButton = document.getElementById(`portal-button-${i}`);
              const position = thisButton?.getBoundingClientRect();
              return {
                open: !state.open,
                top: Number(position?.bottom),
                left: Number(position?.left),
                entity: certificate,
              };
            });
          }}
        >
          <DotsVerticalIcon className="w-5 h-5" />
        </button>
      </div>
    );
  }

  const columns: Array<Column<Record<string, any>>> = useMemo(
    () => getCertificationsColumns(lang),
    [lang]
  );

  const data: Array<any> = useMemo(() => {
    return certificates.data.certificates.map((certificate: Certificate, i) => {
      return {
        col1: (
          <div className="flex items-center h-full">{certificate.title}</div>
        ),
        col2: (
          <div className="flex items-center h-full">
            {certificate.description}
          </div>
        ),

        col3: displayActions(certificate, i),
      };
    });
  }, [certificates.data.certificates, lang]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: getValues("pageSize") },
      pageCount: Math.ceil(certificates.data.total / getValues("pageSize")),
      manualSortBy: true,
      manualPagination: true,
    },
    useSortBy,
    usePagination,
    useFlexLayout
  );

  function constructGetCertificatesThunkAction(pageIndex: number) {
    return {
      pageIndex,
      pageSize: getValues("pageSize"),
      badge: badgeToQuery(getValues("badge")),
      code: codeToQuery(getValues("code")),
      searchString: debouncedSearchText,
      sortCol: sortBy[0] ? sortBy[0].id : "",
      sortOrder: sortBy[0] ? (sortBy[0].desc ? "DESC" : "ASC") : "",
    };
  }

  const {
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, sortBy },
  } = tableInstance;

  useEffect(() => {
    dispatch(getCertificatesThunk(constructGetCertificatesThunkAction(0)));
  }, [debouncedSearchText, sortBy, watch("badge"), watch("code")]);

  const handleDeleteCertificate = async (certificateToDelete: string) => {
    dispatch(deleteCertificateThunk(certificateToDelete)).then((res: any) => {
      if (res.meta.requestStatus === "rejected") {
        toast.error(
          Message({
            action: "delete",
            entity: t("certificates.certificates"),
            error: t("genericError"),
            lang,
            gender: "male",
          })
        );
        return;
      }
      dispatch(getCertificatesThunk(constructGetCertificatesThunkAction(0)));
      toast.success(
        Message({
          action: "delete",
          entity: "certificate",
          lang,
        })
      );
    });
    setOpenModal(false);
  };

  const dispatchNextPage = () => {
    if (canNextPage) {
      dispatch(
        getCertificatesThunk(constructGetCertificatesThunkAction(pageIndex + 1))
      );
      nextPage();
    }
  };

  const dispatchPreviousPage = () => {
    if (canPreviousPage) {
      dispatch(
        getCertificatesThunk(constructGetCertificatesThunkAction(pageIndex - 1))
      );
      previousPage();
    }
  };

  const dispatchGotoPage = (pageIndex: number) => {
    dispatch(
      getCertificatesThunk(constructGetCertificatesThunkAction(pageIndex))
    );
    gotoPage(pageIndex);
  };

  function portalContent(portal: PortalType<Certificate>) {
    if (portal.entity === null) return;
    return (
      <div
        className="fixed z-50 flex flex-col gap-2 p-2 bg-white rounded-sm shadow-md"
        style={{
          top: portal.top,
          left: portal.left,
        }}
      >
        {canAccess("certificates", "UPDATE") ? (
          <Link
            to={`/certificates/${portal.entity.id}`}
            className="flex gap-2 items-center text-table-edit"
          >
            <PencilIcon className="w-5 h-5" />
            {lang === "en" ? "Edit" : "Aggiorna"}
          </Link>
        ) : null}
        {canAccess("certificates", "DELETE") ? (
          <button
            onClick={() => handleModal(String(portal.entity?.id))}
            className="flex gap-2 items-center text-users-delete"
          >
            <TrashIcon className="w-5 h-5" />
            {lang === "en" ? "Delete" : "Elimina"}
          </button>
        ) : null}
      </div>
    );
  }

  const badges = selectBadges();
  const badgesOptions: any = badges.map((badge) => ({
    value: badge,
    label: capitalizeFirst(badge),
  }));
  const codes = selectCodes();
  const codesOptions: any = codes.map((code) => ({
    value: code,
    label: capitalizeFirst(code),
  }));

  return (
    <Overlay active={certificates.loading} spinner={<Spinner />}>
      <div className="flex flex-col gap-5 p-10 h-full">
        <div className="text-3xl font-bold">
          {t("certificates.certificates")}
        </div>
        <div className="flex flex-col gap-5">
          {filtersOpen ? (
            <div className="flex flex-wrap gap-5">
              <div className="w-72">
                <label htmlFor="badge" className="sr-only">
                  {t("certificates.badge")}
                </label>
                <Controller
                  name="badge"
                  control={control}
                  render={({ field }) => {
                    return (
                      <Select
                        placeholder={t("certificates.badge")}
                        styles={getReactSelectStyles(false)}
                        inputId="tags"
                        value={field.value}
                        options={badgesOptions}
                        isClearable={true}
                        onChange={(e) => field.onChange(e)}
                      />
                    );
                  }}
                />
              </div>
              <div className="w-72">
                <label htmlFor="code" className="sr-only">
                  {t("certificates.code")}
                </label>
                <Controller
                  name="code"
                  control={control}
                  render={({ field }) => {
                    return (
                      <Select
                        placeholder={t("certificates.code")}
                        styles={getReactSelectStyles(false)}
                        inputId="tags"
                        value={field.value}
                        options={codesOptions}
                        isClearable={true}
                        onChange={(e) => field.onChange(e)}
                      />
                    );
                  }}
                />
              </div>
              <div>
                <button
                  type="button"
                  className="px-4 py-2 text-white bg-primary border border-primary"
                  onClick={() => {
                    setValue("badge", null as any);
                    setValue("code", null as any);
                  }}
                >
                  {t("certificates.clearAll")}
                </button>
              </div>
            </div>
          ) : null}
          <div className="flex flex-grow gap-5">
            <button onClick={() => setFiltersOpen((state) => !state)}>
              <FilterIcon className="w-6 h-6" />
            </button>
            <div className="flex flex-grow flex-wrap gap-5 justify-end">
              <div className="flex items-center justify-start border focus-within:border-global-input-focus border-users-search">
                <div className="flex items-center justify-center p-2 h-full bg-white">
                  <SearchIcon className="w-5 h-5 text-gray-400" />
                </div>
                <input
                  type="text"
                  placeholder={`${t("CertificateCRUD.searchPlaceholder")}`}
                  {...register("searchString")}
                  className="placeholder-gray-400 px-4 py-2 border-0 focus:ring-0"
                />
              </div>
              {canAccess("certificates", "CREATE") && (
                <Link to="/certificates/create" className="h-full">
                  <button className="flex items-center justify-center px-4 py-2 text-white bg-global-createEntity">
                    <PlusIcon className="w-5 h-5" />
                    <span className="whitespace-nowrap">
                      {t("CertificateCRUD.createCertificateButton")}
                    </span>
                  </button>
                </Link>
              )}
            </div>
          </div>
        </div>
        <Table {...tableInstance} className="users__table" />
        <Pagination
          rowsCount={certificates.data.total}
          pageIndex={pageIndex}
          pageSize={getValues("pageSize")}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          pageOptions={pageOptions}
          pageCount={pageCount}
          gotoPage={dispatchGotoPage}
          nextPage={dispatchNextPage}
          previousPage={dispatchPreviousPage}
          setPageSize={setPageSize}
        />
        <TablePopup
          isOpen={portal.open}
          close={() => setPortal((state) => ({ ...state, open: false }))}
        >
          {portalContent(portal)}
        </TablePopup>
        <Modal
          openStatus={openModal}
          setOpen={setOpenModal}
          className=""
          icon={
            <ExclamationIcon
              className="w-6 h-6 text-red-600 bg-transparent"
              aria-hidden="true"
            />
          }
          header={<p>{t("CertificateCRUD.deleteCertificateModalHeader")}</p>}
          title={<p>{t("CertificateCRUD.deleteCertificateModalMessage")}</p>}
          footer={
            <div className="mt-5 sm:flex sm:flex-row-reverse sm:mt-4">
              <button
                type="button"
                className="inline-flex justify-center px-4 py-1 w-full text-white text-base font-medium bg-red-600 hover:bg-red-700 border border-transparent focus:outline-none shadow-sm focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                onClick={async () =>
                  await handleDeleteCertificate(certificateToDelete)
                }
              >
                {t("CertificateCRUD.deleteButton")}
              </button>
              <button
                type="button"
                className="inline-flex justify-center mt-3 px-4 py-1 w-full hover:text-gray-500 text-gray-700 text-base font-medium bg-white border border-gray-300 focus:outline-none shadow-sm focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                onClick={() => setOpenModal(false)}
              >
                {t("CertificateCRUD.cancelButton")}
              </button>
            </div>
          }
        />
      </div>
    </Overlay>
  );
};

export default Certificates;
