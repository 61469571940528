import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { ArrowNarrowRightIcon } from "@heroicons/react/outline";
import { selectLoggedInUser } from "store/authStore/authReducer";
import { useTranslation } from "react-i18next";

type DashboardItem = {
  header: string;
  subheader: string;
  browseURL: string;
  addNewURL: string;
  buttonTitle: string;
  domain: string;
};

function canAccess(domain: string, action: string) {
  const user = selectLoggedInUser();
  if (user.permissions["*"]) return true;
  const isEnalbed = user.permissions[domain]["ENABLED"];
  if (isEnalbed) {
    const perm = user.permissions[domain][action];
    return (perm === "OWN" && domain !== "users") || perm === "ANY";
  }
  return false;
}

function DashboardCard({ item }: { item: DashboardItem }) {
  const { t } = useTranslation("common");
  return (
    <div className="grid gap-2 grid-cols-1 grid-rows-3 items-center p-5 h-full bg-white rounded-sm shadow-md">
      <div className="text-3xl font-bold">{item.header}</div>
      <div className="text-dashboard-color">{item.subheader}</div>
      <div className="flex items-center justify-between">
        {canAccess(item.domain, "READ") ? (
          <Link
            to={item.browseURL}
            className="flex gap-2 items-center text-dashboard-color"
          >
            {t("dashboard.browse")} <ArrowNarrowRightIcon className="w-5 h-5" />
          </Link>
        ) : (
          <div></div>
        )}
        {canAccess(item.domain, "CREATE") ? (
          <div>
            <Link
              to={item.addNewURL}
              type="button"
              className="p-2 text-dashboard-color border border-dashboard-color rounded-sm"
            >
              {item.buttonTitle}
            </Link>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
}

const data = {
  header: {
    title: "Thank You!",
    description: "We have received your order.",
  },
  customerDetails: {
    title: "Order Receipt",
    date: {
      title: "ORDER DATE",
      mock: "Friday, May 10, 2019",
    },
    id: {
      title: "ORDER ID",
      mock: "22320638",
    },
    number: {
      title: "RECEIPT NUMBER",
      mock: "#00000001",
    },
    bill: {
      title: "BILLED TO",
      mock: {
        name: "Giovani Giorgio",
        addressName: "Street",
        addressNumber: "22",
        postalCode: "Postal Code",
        city: "City",
        region: "Region",
        province: "Province",
        country: "Country",
      },
    },
    ship: {
      title: "SHIPPED TO",
      mock: {
        name: "Giovani Giorgio",
        addressName: "Street",
        addressNumber: "22",
        postalCode: "Postal Code",
        city: "City",
        region: "Region",
        province: "Province",
        country: "Country",
      },
    },
    customer: {
      title: "CUSTOMER DETAILS",
      phone: "+39 6989697142",
      email: "john@doe.com",
    },
    paid: {
      title: "PAID WITH",
      method: "VISA",
      number: "**** **** **** 9999",
    },
  },
  orderDetails: {},
  cost: {},
  footer: {},
};

const template = {
  fonts: {
    size: {
      small: 6,
      medium: 8,
      large: 10,
      extraLarge: 12,
    },
    style: {
      light: "light",
      regular: "regular",
      medium: "medium",
      bold: "bold",
    },
  },
};

const Home = (): JSX.Element => {
  const { t } = useTranslation("common");
  const dashboardItems: DashboardItem[] = useMemo(
    () => [
      {
        header: t("dashboard.news"),
        subheader: `${t("dashboard.manage")} ${t("dashboard.news")}`,
        browseURL: "/news",
        addNewURL: "/news/create",
        buttonTitle: `${t("dashboard.add")} ${t("dashboard.posts")}`,
        domain: "news",
      },
      {
        header: t("dashboard.events"),
        subheader: `${t("dashboard.manage")} ${t("dashboard.events")}`,
        browseURL: "/events",
        addNewURL: "/events/create",
        buttonTitle: `${t("dashboard.add")} ${t("dashboard.events")}`,
        domain: "events",
      },
      {
        header: t("dashboard.exams"),
        subheader: `${t("dashboard.manage")} ${t("dashboard.exams")}`,
        browseURL: "/exams",
        addNewURL: "/exams/create",
        buttonTitle: `${t("dashboard.add")} ${t("dashboard.exams")}`,
        domain: "exams",
      },
      {
        header: t("dashboard.certificates"),
        subheader: `${t("dashboard.manage")} ${t("dashboard.certificates")}`,
        browseURL: "/certificates",
        addNewURL: "/certificates/create",
        buttonTitle: `${t("dashboard.add")} ${t("dashboard.certificates")}`,
        domain: "certificates",
      },
      {
        header: t("dashboard.users"),
        subheader: `${t("dashboard.manage")} ${t("dashboard.users")}`,
        browseURL: "/users",
        addNewURL: "/users/create",
        buttonTitle: `${t("dashboard.add")} ${t("dashboard.users")}`,
        domain: "users",
      },
      {
        header: t("dashboard.roles"),
        subheader: `${t("dashboard.manage")} ${t("dashboard.roles")}`,
        browseURL: "/roles",
        addNewURL: "/roles/create",
        buttonTitle: `${t("dashboard.add")} ${t("dashboard.roles")}`,
        domain: "permissionsManagement",
      },
    ],
    [t]
  );

  return (
    <div className="flex flex-col flex-grow gap-10 p-10 h-full">
      <div className="text-3xl font-bold">{t("dashboard.dashboard")}</div>
      <div className="grid gap-10 grid-cols-3">
        {dashboardItems
          .filter((item) => canAccess(item.domain, "READ"))
          .map((item, i) => {
            return (
              <div key={i}>
                <DashboardCard item={item} />
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default Home;
