import React, { useEffect, useState, KeyboardEventHandler } from "react";
import CreatableSelect from "react-select/creatable";
import { selectRegions } from "store/lookups/lookupsReducer";
import Select from "react-select";
import axios from "axios";
import { CMS_BE_URL } from "api/urls";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "store/storeHooks";
import { getReactSelectStyles } from "utils/utils";
import { validateEmail } from "utils/utils";
import { toast } from "react-toastify";
import {
  createEntityRecipientFilters,
  createOption,
  SelectOption,
  componentsOptions,
} from "api/Newsletter/newsletter";
const RecipientsTab = ({
  object,
  setObject,
  setMailList,
  entitiesReg,
  setEntitiesReg,
  selectReg,
  setSelectReg,
  loading,
  setLoading,
  customMail,
  excludeMail,
  setExcludeMail,
  setCustomMail,
  setBouncedNr,
}: {
  object: any;
  setObject: (value: any) => void;
  setMailList: (value: any) => void;
  entitiesReg: any;
  customMail: any;
  excludeMail: any;
  selectReg: any;
  loading: boolean;
  setLoading: any;
  setEntitiesReg: (value: any) => void;
  setCustomMail: (value: any) => void;
  setExcludeMail: (value: any) => void;
  setSelectReg: (value: any) => void;
  setBouncedNr: (value: any) => void;
}) => {
  const [selectedRegions, setSelectedRegions] = useState<any>();
  const regions = selectRegions();
  const { t } = useTranslation("common");

  const lang = useAppSelector((state) => state.translation.language);

  const permissions = useAppSelector((state) => state.auth.permissions);

  useEffect(() => {
    const regionsToFilter = [...regions];

    setSelectedRegions(
      regionsToFilter.map((r) => {
        return {
          value: r.PK_REGION,
          label: r.PK_REGION,
        };
      })
    );
  }, [regions]);

  const changeRegionHandler = (value: any) => {
    setSelectReg(value);
    setObject((prev: any) => ({
      ...prev,
      filteringOptions: {
        region: value.map((v: any) => v.value),
      },
    }));
  };

  const [inputValue, setInputValue] = useState("");

  const customMailHandler = (value: any) => {
    const arrayOfMails = value.map((v: any) => v.value);
    setCustomMail(value);
    setObject((prev: any) => ({
      ...prev,
      entityOptions: {
        ...prev.entityOptions,
        recipients: [...arrayOfMails],
      },
    }));
  };

  const excludeMailHandler = (value: any) => {
    const arrayOfMails = value.map((v: any) => v.value);
    setExcludeMail(value);
    setObject((prev: any) => ({
      ...prev,
      entityOptions: {
        ...prev.entityOptions,
        excludes: [...arrayOfMails],
      },
    }));
    setInputValue2("");
  };

  const changeCustomMailHander: KeyboardEventHandler = (event) => {
    if (!inputValue) return;
    const arrayOfMails = customMail.map((v: any) => v.value);
    const arrayOfInputs = inputValue.split(";").map((inp) => inp.trim());

    switch (event.key) {
      case "Enter":
      case "Tab":
        setCustomMail((prev: any) => [
          ...prev,
          ...arrayOfInputs.map((inputValue) => createOption(inputValue)),
        ]);
        setObject((prev: any) => ({
          ...prev,
          entityOptions: {
            ...prev.entityOptions,
            recipients: [...arrayOfMails, ...arrayOfInputs],
          },
        }));
        setInputValue("");
        event.preventDefault();
    }
  };

  const [inputValue2, setInputValue2] = useState("");

  const changeExcludeMailHandler: KeyboardEventHandler = (event) => {
    if (!inputValue2) return;
    const arrayOfMails = excludeMail.map((v: any) => v.value);
    const arrayOfInputs = inputValue2.split(";").map((inp) => inp.trim());

    switch (event.key) {
      case "Enter":
      case "Tab":
        setExcludeMail((prev: any) => [
          ...prev,
          ...arrayOfInputs.map((inputValue) => createOption(inputValue)),
        ]);
        setObject((prev: any) => ({
          ...prev,
          entityOptions: {
            ...prev.entityOptions,
            excludes: [...arrayOfMails, ...arrayOfInputs],
          },
        }));
        setInputValue2("");
        event.preventDefault();
    }
  };

  const entities = createEntityRecipientFilters(permissions, lang);

  const changeEntityHandler = (value: any) => {
    setEntitiesReg(value);
    const manipulate = object.entityOptions;

    Object.keys(manipulate).forEach((key) => {
      if (key !== "recipients" && key !== "excludes") manipulate[key] = false;
    });

    if (value.length > 0) {
      value.forEach((val: SelectOption) => {
        if (Object.prototype.hasOwnProperty.call(manipulate, val.value))
          manipulate[val.value] = true;
      });
    }

    setObject((prev: any) => ({
      ...prev,
      entityOptions: manipulate,
    }));
  };

  useEffect(() => {
    let hasNonEmail = false;
    if (object.entityOptions.recipients.length > 0) {
      object.entityOptions.recipients.forEach((rec: string) => {
        if (!validateEmail(rec)) {
          hasNonEmail = true;
        }
      });
    }

    if (hasNonEmail) {
      toast.error("Please provide valid emails");
      return;
    }
    const get = async () => {
      const res = await axios.post(
        `${CMS_BE_URL}/newsletter/mailingList/recipients`,
        object
      );
      setBouncedNr(res.data.bounced);
      setMailList(res.data.data);
    };
    setLoading(true);
    try {
      get();
    } catch (err: any) {
      if (err.response?.data?.errors?.[0]?.message) {
        toast.error(err.response?.data?.errors?.[0]?.message);
      } else {
        toast.error(err.response?.data?.message);
      }
      setMailList([]);
    }
    setLoading(false);
  }, [object]);

  return (
    <div className="flex flex-col gap-5">
      <h3 className="text-global-subHeader text-lg">{t("newsletter.rtp")}</h3>

      <div className="flex flex-col gap-2 w-full">
        <label className="flex flex-col gap-2">
          <span className="text-lg font-medium">
            {t("newsletter.recipients")}
          </span>
          <Select
            styles={getReactSelectStyles(false)}
            closeMenuOnSelect={false}
            isMulti
            placeholder="Recipients"
            value={entitiesReg}
            options={entities}
            onChange={changeEntityHandler}
          />
        </label>
        <div className="w-full">
          <label className="flex flex-col gap-2">
            <span className="text-lg font-medium">
              {t("newsletter.regions")}
            </span>
            <Select
              styles={getReactSelectStyles(false)}
              value={selectReg}
              closeMenuOnSelect={false}
              placeholder="Regions"
              isMulti
              options={selectedRegions}
              onChange={changeRegionHandler}
            />
          </label>
        </div>
        <div className="w-full">
          <label className="flex flex-col gap-2">
            <span className="text-lg font-medium">{t("newsletter.cm")}</span>
            <CreatableSelect
              styles={getReactSelectStyles(false)}
              components={componentsOptions}
              inputValue={inputValue}
              isDisabled={loading}
              isClearable
              isMulti
              menuIsOpen={false}
              // onChange={(newValue) => setCustomMail(newValue)}
              onChange={(value) => customMailHandler(value)}
              onInputChange={(newValue) => {
                setInputValue(newValue);
              }}
              onKeyDown={changeCustomMailHander}
              placeholder={
                lang === "en"
                  ? "Add emails to recieve the newsletter"
                  : "Aggiungi manualmente le email da inviare"
              }
              value={customMail}
            />
          </label>
        </div>
        <div className="w-full">
          <label className="flex flex-col gap-2">
            <span className="text-lg font-medium">{t("newsletter.em")}</span>
            <CreatableSelect
              styles={getReactSelectStyles(false)}
              components={componentsOptions}
              inputValue={inputValue2}
              isDisabled={loading}
              isClearable
              isMulti
              menuIsOpen={false}
              // onChange={(newValue) => setExcludeMail(newValue)}
              onChange={(newValue) => excludeMailHandler(newValue)}
              onInputChange={(newValue) => setInputValue2(newValue)}
              onKeyDown={changeExcludeMailHandler}
              placeholder={
                lang === "en"
                  ? "Add emails to exclude from the recipients list"
                  : "Aggiungi email da escludere dall'elenco dei destinatari"
              }
              value={excludeMail}
            />
          </label>
        </div>
      </div>
    </div>
  );
};

export default RecipientsTab;
