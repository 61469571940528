import React from "react";
import { XIcon } from "@heroicons/react/outline";
import { useFormContext } from "react-hook-form";

export default function DisplayFiles({
  reactHookFormName,
}: {
  reactHookFormName: string;
}) {
  const { watch, setValue, getValues } = useFormContext();
  const files: File[] = watch(reactHookFormName);
  if (files) {
    return (
      <div className="flex flex-wrap gap-5">
        {Array.from(files).map((file, i: number) => (
          <div
            key={i}
            className="flex gap-0 items-center bg-red-500 rounded-sm"
          >
            <div className="w-16 h-9">
              <img
                src={URL.createObjectURL(file)}
                alt={file.name}
                className="w-16 h-9 object-cover"
              />
            </div>
            {/* <div className="flex gap-3"> */}
            {/* <span>{file.name}</span> */}
            <button
              type="button"
              className="p-1"
              onClick={() => {
                const files: File[] = [...getValues(reactHookFormName)];
                files.splice(i, 1);
                // = Array.from(files);
                // temp = temp.filter((f) => f.name !== file.name);
                setValue(reactHookFormName, files);
              }}
            >
              <XIcon className="w-6 h-6 text-white" />
            </button>
            {/* </div> */}
          </div>
        ))}
      </div>
    );
  }
  return null;
}
