import React from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { regionRestrictionsSelect } from "shared/interfaces/User.interface";
import { selectTranslationLanguage } from "store/translationStore/translationReducer";
import { getReactSelectStyles } from "utils/utils";
const regionRestrictions = [
  "Abruzzo",
  "Basilicata",
  "Calabria",
  "Campania",
  "Emilia-Romagna",
  "Friuli-Venezia Giulia",
  "Lazio",
  "Liguria",
  "Lombardia",
  "Marche",
  "Molise",
  "Piemonte",
  "Puglia",
  "Sardegna",
  "Sicilia",
  "Toscana",
  "Trentino-Alto Adige",
  "Umbria",
  "Valle d'Aosta",
  "Veneto",
];

export const regionRestrictionsOptions = regionRestrictions.map((region) => ({
  value: region,
  label: region,
}));

function RegionRestrictions({
  regionRestrictions,
  setRegionRestrictions,
}: {
  regionRestrictions: regionRestrictionsSelect;
  setRegionRestrictions: any;
}) {
  const lang = selectTranslationLanguage();
  const { t } = useTranslation("common");
  return (
    <>
      <div>
        <label htmlFor="regionRestrictions" className="text-lg font-medium">
          {t("user.regionRestrictions")}
        </label>
        <div>{t("user.regionRestrictionsDescription")}</div>
      </div>

      <Select
        id="regionRestrictions"
        placeholder={t("user.selectRegions")}
        styles={getReactSelectStyles(false)}
        isMulti={true}
        isClearable={true}
        value={regionRestrictions}
        onChange={function (e) {
          setRegionRestrictions(e);
        }}
        options={regionRestrictionsOptions}
      />
    </>
  );
}

export default RegionRestrictions;
