import { Badge } from "shared/interfaces/Badge.interface";
import { Certificate } from "shared/interfaces/Certificate.interface";
import { Code } from "shared/interfaces/Code.interface";

export interface CertificatesState {
  staticData: {
    badges: Badge[];
    codes: Code[];
  };
  data: {
    certificate: Certificate | null;
    total: number;
    pageIndex: number;
    certificates: Certificate[];
  };
  loading: boolean;
}

export const certificatesInitialState: CertificatesState = {
  staticData: {
    badges: ["single", "bronze", "silver", "gold", "platinum"],
    codes: [
      "ST",
      "STa",
      "STb",
      "LA",
      "LAa",
      "LAb",
      "FK",
      "FKa",
      "FKb",
      "JZ",
      "JZa",
      "CB",
      "CBa",
      "CBb",
      "TA",
      "TAa",
      "TAb",
      "FZ",
      "FZa",
      "FX",
      "FXa",
      "LUBS",
      "E_CO",
      "DM",
      "LP",
      "CT",
      "E_ST",
      "E_LA",
      "E_FK",
      "E_JZ",
      "E_CB",
      "E_TA",
      "E_FZ",
      "E_FX",
      "E_LUBS",
      "E_DM",
      "E_LP",
      "E_CT",
      "D_ST",
      "D_LA",
      "D_FK",
      "D_JZ",
      "D_CB",
      "D_TA",
      "D_FZ",
      "D_FX",
      "D_LUBS",
      "P_CO",
      "D_DM",
      "D_LP",
      "D_CT",
      "VB",
      "SC",
      "DD",
      "HH",
      "CL",
      "OR",
      "FL",
      "CY",
      "RG",
      "D_SC",
      "D_DD",
      "D_HH",
      "D_CL",
      "D_OR",
      "D_FL",
      "D_CY",
      "D_RG",
      "E_SC",
      "E_DD",
      "E_HH",
      "E_CL",
      "E_OR",
      "E_FL",
      "E_CY",
      "E_RG",
      "P_ST",
      "P_LA",
      "P_FK",
      "P_JZ",
      "P_CB",
      "P_TA",
      "P_FZ",
      "P_FX",
      "P_LUBS",
      "CO",
      "P_DM",
      "P_LP",
      "P_CT",
      "CBp",
      "LL",
      "E_LL",
      "D_LL",
      "P_LL",
      "TC",
      "TCa",
      "TCb",
      "P_TC",
      "D_TC",
      "E_TC",
      "P_HH",
      "P_DD",
      "P_CY",
      "P_FL",
      "P_OR",
      "P_CL",
      "P_SC",
      "P_RG",
      "LUBSa",
      "D_CO",
      "CLa",
      "TT",
      "TTa",
      "TTb",
      "P_TT",
      "D_TT",
      "E_TT",
      "SG",
      "E_SG",
      "RU",
      "E_RU",
      "D_RU",
      "P_RU",
      "BWF",
      "E_BWF",
      "D_BWF",
      "P_BWF",
      "MRD",
      "E_MRD",
      "D_MRD",
      "P_MRD",
      "ORa",
      "CTba",
      "PS",
      "FS",
      "KZ",
      "TS",
      "E_PS",
      "E_FS",
      "E_KZ",
      "E_TS",
      "P_PS",
      "P_FS",
      "P_KZ",
      "P_TS",
      "E_NS",
      "D_NS",
      "P_NS",
      "KD",
      "E_KD",
      "D_KD",
      "DJ",
      "ZK",
      "E_ZK",
      "D_ZK",
      "P_ZK",
      "NS",
      "REG",
      "E_REG",
      "D_REG",
      "P_REG",
      "NAM",
      "E_NAM",
      "D_NAM",
      "P_NAM",
      "CLD",
      "E_CLD",
      "D_CLD",
      "P_CLD",
      "PD",
      "E_PD",
      "D_PD",
      "P_PD",
      "SLH",
      "E_SLH",
      "D_SLH",
      "P_SLH",
      "ASAR",
      "E_ASAR",
      "D_ASAR",
      "P_ASAR",
      "D_KZ",
    ],
  },
  data: {
    certificate: null,
    total: 0,
    pageIndex: 0,
    certificates: [],
  },
  loading: false,
};
