import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createPayment,
  CreatePaymentDto,
  deletePayment,
  getPayment,
  getPayments,
  updatePayment,
  UpdatePaymentDto,
} from "api/Payments/payments.api";
import { GetPaymentFilters } from "api/Payments/urls";
import { useAppSelector } from "store/storeHooks";
import { PaymentsInitialState } from "./paymentsState";

export const getPaymentsThunk = createAsyncThunk(
  "paymentsStore/getPayments",
  async (filters: GetPaymentFilters) => {
    const res = await getPayments(filters);
    return res.data;
  }
);

export const getPaymentThunk = createAsyncThunk(
  "paymentsStore/getPayment",
  async (paymentId: string) => {
    const res = await getPayment(paymentId);
    return res.data;
  }
);

export const createPaymentThunk = createAsyncThunk(
  "paymentsStore/createPayment",
  async (createPaymentDto: CreatePaymentDto) => {
    const res = await createPayment(createPaymentDto);
    return res.data;
  }
);

export const updatePaymentThunk = createAsyncThunk(
  "paymentsStore/updatePayment",
  async ({
    paymentId,
    updatePaymentDto,
  }: {
    paymentId: string;
    updatePaymentDto: UpdatePaymentDto;
  }) => {
    const res = await updatePayment(paymentId, updatePaymentDto);
    return res.data;
  }
);

export const deletePaymentThunk = createAsyncThunk(
  "paymentsStore/deletePayment",
  async (paymentId: string) => {
    const res = await deletePayment(paymentId);
    return res.data;
  }
);

const paymentsSlice = createSlice({
  name: "paymentsStore",
  initialState: PaymentsInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createPaymentThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createPaymentThunk.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(createPaymentThunk.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getPaymentsThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPaymentsThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(getPaymentsThunk.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getPaymentThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPaymentThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.data.payment = action.payload;
    });
    builder.addCase(getPaymentThunk.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(updatePaymentThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updatePaymentThunk.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(updatePaymentThunk.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(deletePaymentThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deletePaymentThunk.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deletePaymentThunk.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const selectPayments = () => useAppSelector((state) => state.payments);

export default paymentsSlice;
