// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useCallback, useEffect } from "react";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import { useTable } from "react-table";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import update from "immutability-helper";
import { MdDragHandle } from "react-icons/md";

const DND_ITEM_TYPE = "row";

// eslint-disable-next-line react/prop-types
const DraggableRow = ({ row, index, moveRow }) => {
  const dropRef = React.useRef(null);
  const dragRef = React.useRef(null);

  const [, drop] = useDrop({
    accept: DND_ITEM_TYPE,
    hover(item, monitor) {
      if (!dropRef.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = dropRef.current.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveRow(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: DND_ITEM_TYPE,
    item: {
      id: index,
      type: DND_ITEM_TYPE,
      index,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;

  preview(drop(dropRef));
  drag(dragRef);

  return (
    <tr ref={dropRef} style={{ opacity }}>
      <td ref={dragRef} className="px-4 py-2">
        <MdDragHandle className="w-5 h-5" />
      </td>
      {
        // eslint-disable-next-line react/prop-types
        row.cells.map((cell) => {
          return (
            // eslint-disable-next-line react/jsx-key
            <td {...cell.getCellProps()} className="px-4 py-2">
              {cell.render("Cell")}
            </td>
          );
        })
      }
    </tr>
  );
};

// eslint-disable-next-line react/prop-types
function DraggableTable({ columns, data, className = "", state, setState }) {
  const [records, setRecords] = React.useState([]);
  const getRowId = useCallback((row) => {
    return row.id;
  }, []);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data: records,
      getRowId,
    });
  useEffect(() => {
    setRecords(data);
  }, [data]);

  const moveRow = (dragIndex, hoverIndex) => {
    // const dragRecord = records[dragIndex];
    // setRecords(
    //   update(records, {
    //     $splice: [
    //       [dragIndex, 1],
    //       [hoverIndex, 0, dragRecord],
    //     ],
    //   })
    // );
    const dragRecord = state[dragIndex];
    setState(
      update(state, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragRecord],
        ],
      })
    );
  };

  return (
    <div className="relative overflow-x-auto">
      <DndProvider backend={HTML5Backend}>
        <table
          {...getTableProps()}
          className="w-full border border-table-border"
        >
          <thead className="sticky z-10 left-0 top-0 bg-gray-100">
            {headerGroups.map((headerGroup) => (
              // eslint-disable-next-line react/jsx-key
              <tr {...headerGroup.getHeaderGroupProps()}>
                <th className="sticky top-0 px-4 py-2 text-left text-table-color whitespace-nowrap text-sm font-medium tracking-wider border-0 border-black uppercase"></th>
                {headerGroup.headers.map((column) => (
                  // eslint-disable-next-line react/jsx-key
                  <th
                    {...column.getHeaderProps()}
                    className="sticky top-0 px-4 py-2 text-left text-table-color whitespace-nowrap text-sm font-medium tracking-wider border-0 border-black uppercase"
                  >
                    <div
                      className={`flex items-center select-none ${
                        column.canSort ? "cursor-pointer" : ""
                      }`}
                    >
                      {column.render("Header")}
                      <span className="inline-block pl-2">
                        {column.canSort ? (
                          column.isSorted ? (
                            column.isSortedDesc ? (
                              <AiFillCaretDown />
                            ) : (
                              <AiFillCaretUp />
                            )
                          ) : null
                        ) : null}
                      </span>
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} className={`bg-white ${className}`}>
            {rows.map(
              (row, index) =>
                prepareRow(row) || (
                  <DraggableRow
                    index={index}
                    row={row}
                    moveRow={moveRow}
                    {...row.getRowProps()}
                  />
                )
            )}
          </tbody>
        </table>
      </DndProvider>
    </div>
  );
}

export default DraggableTable;
