import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createCertificate,
  deleteCertificate,
  getCertificate,
  getCertificates,
  updateCertificate,
} from "api/Certificates/certificates.api";
import {
  Certificate,
  CreateCertificate,
} from "shared/interfaces/Certificate.interface";
import { useAppSelector } from "store/storeHooks";
import { certificatesInitialState } from "./certificationsState";

export const getCertificatesThunk = createAsyncThunk(
  "certificatesStore/getCertificates",
  async (filters: {
    badge: string;
    code: string;
    pageSize: number;
    pageIndex: number;
    sortCol: string;
    sortOrder: string;
    searchString: string;
  }) => {
    const response = await getCertificates(filters);
    return response.data;
  }
);

export const getCertificateThunk = createAsyncThunk(
  "certificatesStore/getCertificate",
  async (id: string) => {
    const response = await getCertificate(id);
    return response.data;
  }
);

export const createCertificateThunk = createAsyncThunk(
  "certificatesStore/createCertificate",
  async (certificate: CreateCertificate, thunkAPI) => {
    try {
      return await createCertificate(certificate);
    } catch (err: any) {
      if (err.response.data.statusCode === 409) {
        return thunkAPI.rejectWithValue(err.response.data.parameters);
      }
      return thunkAPI.rejectWithValue(null);
    }
  }
);

export const updateCertificateThunk = createAsyncThunk(
  "certificatesStore/updateCertificate",
  async (
    {
      certificateId,
      certificate,
    }: {
      certificateId: string;
      certificate: {
        title: string;
        description: string;
        variations: [];
      };
    },
    thunkAPI
  ) => {
    try {
      return await updateCertificate(certificateId, certificate);
    } catch (err: any) {
      if (err.response.data.statusCode === 409) {
        return thunkAPI.rejectWithValue(err.response.data.parameters);
      }
      return thunkAPI.rejectWithValue(null);
    }
  }
);

export const deleteCertificateThunk = createAsyncThunk(
  "certificatesStore/deleteCertificate",
  async (certificateId: string) => {
    await deleteCertificate(certificateId);
  }
);

const certificatesSlice = createSlice({
  name: "certificatesStore",
  initialState: certificatesInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createCertificateThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createCertificateThunk.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createCertificateThunk.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getCertificatesThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCertificatesThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(getCertificatesThunk.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getCertificateThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCertificateThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.data.certificate = action.payload;
    });
    builder.addCase(getCertificateThunk.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(updateCertificateThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateCertificateThunk.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(updateCertificateThunk.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(deleteCertificateThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteCertificateThunk.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteCertificateThunk.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const selectCertificates = () =>
  useAppSelector((state) => state.certificates);
export const selectBadges = () =>
  useAppSelector((state) => state.certificates.staticData.badges);
export const selectCodes = () =>
  useAppSelector((state) => state.certificates.staticData.codes);

export default certificatesSlice;
