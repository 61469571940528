import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createCouncil,
  deleteCouncil,
  deleteMemberFromCouncil,
  getCouncil,
  getCouncils,
  updateCouncil,
} from "api/Councils/counsils.api";
import { CreateCouncilDetails } from "shared/interfaces/Council.interface";
import { useAppSelector } from "store/storeHooks";
import { councilsInitialState } from "./councilsState";

export const createCouncilThunk = createAsyncThunk(
  "councilsStore/createCouncil",
  async ({ data }: { data: CreateCouncilDetails }) => {
    await createCouncil({ data });
  }
);

export const getCouncilsThunk = createAsyncThunk(
  "councilsStore/getCouncils",
  async (filters: {
    pageSize: number;
    pageIndex: number;
    sortCol: string;
    sortOrder: string;
    searchString: string;
  }) => {
    const res = await getCouncils(filters);
    return res.data;
  }
);

export const deleteCouncilThunk = createAsyncThunk(
  "councilsStore/deleteCouncil",
  async ({ council }: { council: string }) => {
    await deleteCouncil({ council });
  }
);

export const getCouncilThunk = createAsyncThunk(
  "councilsStore/getCouncil",
  async ({ council }: { council: string }) => {
    const res = await getCouncil({ council });
    return res.data;
  }
);

export const updateCouncilThunk = createAsyncThunk(
  "councilsStore/updateCouncil",
  async ({
    council,
    data,
  }: {
    council: string;
    data: CreateCouncilDetails;
  }) => {
    await updateCouncil({
      council,
      data,
    });
  }
);

export const deleteMemberFromCouncilThunk = createAsyncThunk(
  "councilsStore/deleteMemberFromCouncil",
  async ({ userId, council }: { userId: string; council: string }) => {
    await deleteMemberFromCouncil({ userId, council });
  }
);

const councilsSlice = createSlice({
  name: "councilsStore",
  initialState: councilsInitialState,
  reducers: {
    setCouncils(state, action) {
      state.data.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createCouncilThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(createCouncilThunk.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(createCouncilThunk.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getCouncilsThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCouncilsThunk.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(getCouncilsThunk.rejected, (state) => {
        state.loading = false;
      })
      .addCase(deleteCouncilThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteCouncilThunk.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(deleteCouncilThunk.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getCouncilThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCouncilThunk.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(getCouncilThunk.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateCouncilThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateCouncilThunk.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updateCouncilThunk.rejected, (state) => {
        state.loading = false;
      })
      .addCase(deleteMemberFromCouncilThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteMemberFromCouncilThunk.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(deleteMemberFromCouncilThunk.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const selectCouncils = () => useAppSelector((state) => state.councils);

export default councilsSlice;
