import { PresidentialRank, Rank } from "shared/interfaces/Rank.interface";

export interface PresidentialRanksState {
  data: {
    message: string;
    total?: number;
    pageIndex?: number;
    councilRanks: PresidentialRank[] | null;
  };
  loading: boolean;
}

export const presidentialRanksInitialState: PresidentialRanksState = {
  data: {
    message: "",
    total: 0,
    pageIndex: 0,
    councilRanks: null,
  },
  loading: false,
};
