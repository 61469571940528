export const getSponsorsColumns: (lang: string) => (
  | {
      Header: string;
      accessor: string;
      width: string;
      id: string;
      disableSortBy?: boolean;
    }
  | {
      Header: string;
      accessor: string;
      width: string;
      disableSortBy: boolean;
      id?: string;
    }
)[] = (lang: string) => {
  return [
    {
      Header: lang === "en" ? "name" : "nome",
      accessor: "col1",
      width: "150",
      id: "name",
    },
    {
      Header: lang === "en" ? "URL" : "URL",
      accessor: "col2",
      width: "150",
      id: "url",
      disableSortBy: true,
    },
    {
      Header: lang === "en" ? "Created at" : "Data Creazione",
      accessor: "col3",
      width: "150",
      id: "created_at",
    },
    {
      Header: lang === "en" ? "actions" : "azioni",
      accessor: "col4",
      width: "150",
      disableSortBy: true,
    },
  ];
};
