/* eslint-disable react/react-in-jsx-scope */
import { useEffect, useMemo, useState } from "react";
import Overlay from "shared/components/UI/Overlay";
import Spinner from "shared/components/UI/Spinner";
import Table from "shared/components/Table/Table";
import { BiMailSend, BiEnvelopeOpen, BiErrorAlt } from "react-icons/bi";
import { FilterIcon } from "@heroicons/react/outline";
import {
  getNewsletterDashboardColumns,
  sentStatusOptions,
  openedStatusOptions,
  getExportNewsletterUsersColumns,
} from "api/Newsletter/newsletter";
import { format } from "date-fns";
import {
  Column,
  useTable,
  useSortBy,
  usePagination,
  useFlexLayout,
} from "react-table";
import { CMS_BE_URL } from "api/urls";
import axios from "axios";
import { useParams } from "react-router";
import { selectTranslationLanguage } from "store/translationStore/translationReducer";
import GoBack from "shared/components/UI/GoBack";
import Pagination from "shared/components/Table/Pagination";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { getReactSelectStyles } from "utils/utils";
import { selectLookups } from "store/lookups/lookupsReducer";
import { useTranslation } from "react-i18next";
import { NewsletterExportUser, User } from "shared/interfaces/User.interface";
import { useDebounce } from "shared/hooks/useDebounce";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";

interface TrackingData {
  data: User[];
  message: string;
  pageIndex: number;
  sent_date: string;
  subject: string;
  title: string;
  total: number;
  total_bounced: number;
  total_opened: number;
  total_sent: number;
}

const currentPageSize = 10;

const NewsletterDashboard = () => {
  const { id } = useParams();
  const { t } = useTranslation("common");
  const lang = selectTranslationLanguage();
  const lookups = selectLookups();

  const [regionsOptions, setRegionsOptions] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [filtersOpen, setFiltersOpen] = useState(false);

  const [newsletter, setNewsletter] = useState<any>(null);
  const [mailTracking, setMailTracking] = useState<TrackingData>({
    data: [],
    message: "",
    pageIndex: 0,
    sent_date: "",
    subject: "",
    title: "",
    total: 0,
    total_bounced: 0,
    total_opened: 0,
    total_sent: 0,
  });

  // INITIAL NEWSLETTER DATA FETCH
  useEffect(() => {
    const getNewsletter = async () => {
      setLoading(true);
      const res = await axios.get(`${CMS_BE_URL}/newsletter/${id}`);
      setNewsletter(res.data.data);
      setLoading(false);
    };
    getNewsletter();
  }, []);

  // MAPPING FOR REGIONS OPTIONS
  useEffect(() => {
    const regionsMap = lookups.lookupList.regions.map((region) => ({
      value: region.PK_REGION,
      label: region.PK_REGION,
    }));
    setRegionsOptions(regionsMap);
  }, [lookups.lookupList.regions]);

  // INITIAL NEWSLETTER TRACKING INFO FETCHING
  useEffect(() => {
    const get = async () => {
      setLoading(true);
      const res = await axios.get(
        `${CMS_BE_URL}/newsletter/email-tracking/${id}`
      );
      setMailTracking(res.data);
      setLoading(false);
    };
    get();
  }, []);

  // FORM INITILIAZATION
  const { register, control, watch } = useForm<any>({
    defaultValues: {
      email: "",
      name: "",
      surname: "",
      region: undefined,
      sent_status: undefined,
      opened_status: undefined,
    },
  });

  // TABLE DATA
  const columns: Array<Column<Record<string, any>>> = useMemo(
    () => getNewsletterDashboardColumns(lang),
    [lang]
  );

  const data: any = useMemo(
    () =>
      mailTracking.data.map((user: any) => {
        return {
          col0: (
            <div className="flex items-center truncate" key={user.id}>
              {user.email}
            </div>
          ),
          col1: (
            <div className="flex items-center truncate" key={user.id}>
              {user.name}
            </div>
          ),
          col2: (
            <div className="flex items-center truncate" key={user.id}>
              {user.surname}
            </div>
          ),
          col3: (
            <div className="flex items-center truncate" key={user.id}>
              {user.region}
            </div>
          ),
          col4: (
            <div className="flex items-center truncate" key={user.id}>
              {user.telephone}
            </div>
          ),
          col5: (
            <div className="flex items-center truncate" key={user.id}>
              {user.member_status}
            </div>
          ),
          col6: (
            <div className="flex items-center truncate" key={user.id}>
              {user.sent_status}
            </div>
          ),
          col7: (
            <div
              className="flex items-center justify-start truncate"
              key={user.id}
            >
              {user.opened ? (lang === "en" ? "Yes" : "Si") : "No"}
            </div>
          ),
          col8: (
            <div className="flex items-center truncate" key={user.id}>
              {user.open_date
                ? format(new Date(user.open_date), "dd/MM/yyyy - HH:mm:ss")
                : null}
            </div>
          ),
        };
      }),
    [mailTracking.data]
  );

  // TABLE INITIALIZATION
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: currentPageSize },
      pageCount: Math.ceil(mailTracking.total / currentPageSize),
      manualSortBy: true,
      manualPagination: true,
    },
    useSortBy,
    usePagination,
    useFlexLayout
  );

  const {
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, sortBy },
  } = tableInstance;

  // FILTER VALUES
  const debouncedName = useDebounce(watch("name"), 300);
  const debouncedSurname = useDebounce(watch("surname"), 300);
  const debouncedEmail = useDebounce(watch("email"), 300);
  const region = watch("region");
  const sent_status = watch("sent_status");
  const opened_status = watch("opened_status");

  // ENDPOINT QUERY CONSTRUCTOR
  function constructGetNewsletterTrackingQuery(
    index: number,
    pageSize: number | null = null
  ) {
    const url = `${CMS_BE_URL}/newsletter/email-tracking/${id}`;
    const queryArray = [`?pageIndex=${index}`];

    debouncedEmail &&
      queryArray.push(`email=${encodeURIComponent(debouncedEmail.trim())}`);
    debouncedName &&
      queryArray.push(`name=${encodeURIComponent(debouncedName.trim())}`);
    debouncedSurname &&
      queryArray.push(`surname=${encodeURIComponent(debouncedSurname.trim())}`);
    region &&
      queryArray.push(`region=${encodeURIComponent(region.value.trim())}`);
    opened_status &&
      queryArray.push(`opened=${encodeURIComponent(opened_status.value)}`);
    sent_status &&
      queryArray.push(
        `sentStatus=${encodeURIComponent(sent_status.value.trim())}`
      );
    sortBy.length === 1 &&
      queryArray.push(
        `sortCol=${sortBy[0].id}&sortOrder=${sortBy[0].desc ? "DESC" : "ASC"}`
      );

    pageSize && queryArray.push(`pageSize=${pageSize}`);

    const query = `${url}${queryArray.join("&")}`;
    return query;
  }

  // DATA FETCHING FUNCTION
  const getNewsletterTrackingInfo = async (query: string) => {
    setLoading(true);
    try {
      const res = await axios.get(query);
      res.data && setMailTracking(res.data);
      return res;
    } catch (err: any) {
      if (err.response?.data?.errors?.[0]?.message) {
        toast.error(err.response?.data?.errors?.[0]?.message);
      } else {
        toast.error(err.response?.data?.message);
      }
    } finally {
      setLoading(false);
    }
  };

  // EFFECT FOR TRIGGERING DATA FETCHING
  // ON FILTER, QUERY, VALUES CHANGES
  useEffect(() => {
    gotoPage(0);
    getNewsletterTrackingInfo(constructGetNewsletterTrackingQuery(0));
  }, [
    debouncedEmail,
    debouncedName,
    debouncedSurname,
    region,
    sent_status,
    opened_status,
    sortBy,
  ]);

  // TABLE PAGINATION ACTIONS
  const dispatchNextPage = () => {
    if (canNextPage) {
      getNewsletterTrackingInfo(
        constructGetNewsletterTrackingQuery(pageIndex + 1)
      ).then((res: any) => {
        res.data.total > 0 && nextPage();
      });
    }
  };

  const dispatchPreviousPage = () => {
    if (canPreviousPage) {
      getNewsletterTrackingInfo(
        constructGetNewsletterTrackingQuery(pageIndex - 1)
      ).then((res: any) => {
        res.data?.total > 0 && previousPage();
      });
    }
  };

  const dispatchGotoPage = (pageIndex: number) => {
    getNewsletterTrackingInfo(
      constructGetNewsletterTrackingQuery(pageIndex)
    ).then((res: any) => {
      res.data?.total > 0 && gotoPage(pageIndex);
    });
  };

  // EXPORT FUNCTIONALITY
  const onDownloadUsers = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        constructGetNewsletterTrackingQuery(0, 99999)
      );
      const users = res.data.data;
      if (!users) return;

      const header = columns.map((column) =>
        String(column.Header).toUpperCase()
      );
      const csvData: any = users.map((user: NewsletterExportUser) => [
        user.email,
        user.name,
        user.surname,
        user.region,
        user.telephone,
        user.member_status,
        user.sent_status,
        user.opened,
        user.open_date
          ? format(new Date(user.open_date), "MMMM dd, yyyy")
          : null,
      ]);
      const data: any = {
        data: [header, ...csvData],
      };
      const worksheet = XLSX.utils.aoa_to_sheet(data.data);
      const new_workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(new_workbook, worksheet, "SheetJS");
      XLSX.writeFile(
        new_workbook,
        `${newsletter.title}-Users-${format(Date.now(), "dd/MM/yyyy")}.xlsx`
      );
    } catch (err: any) {
      if (err.response?.data?.errors?.[0]?.message) {
        toast.error(err.response?.data?.errors?.[0]?.message);
      } else {
        toast.error(err.response?.data?.message);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Overlay active={loading} spinner={<Spinner />}>
      <div className="flex flex-col gap-5 p-10 h-full">
        <GoBack uri="newsletter" />
        {newsletter ? (
          <div className="flex flex-col gap-5">
            <div className="w-ffull flex gap-5">
              <div className="flex flex-col gap-2 px-8 py-6 text-xl bg-white shadow-md">
                <header>
                  <b>{t("newsletter.titleDashboard")}</b> {newsletter.title}
                </header>
                <section className="flex flex-col gap-2">
                  <p>
                    <b>{t("newsletter.subjectDashboard")}</b>{" "}
                    {newsletter.subject}
                  </p>
                  <p>
                    <b>{t("newsletter.sendDateDashboard")}</b>
                    {format(new Date(newsletter.send_date), "dd-MM-yyyy") ??
                      "Not sent yet."}
                  </p>
                </section>
              </div>
              <div className="flex flex-col gap-2 items-center justify-center px-8 py-6 bg-white shadow-md">
                <BiMailSend className="text-center text-5xl" />
                <div>
                  <b>{mailTracking.total_sent}</b>{" "}
                  {t("newsletter.sentDashboard")}
                </div>
              </div>
              <div className="flex flex-col gap-2 items-center justify-center px-8 py-6 bg-white shadow-md">
                <BiEnvelopeOpen className="text-center text-5xl" />
                <div>
                  <b>{mailTracking.total_opened}</b>{" "}
                  {t("newsletter.openedDashboard")}
                </div>
              </div>
              <div className="flex flex-col gap-2 items-center justify-center px-8 py-6 bg-white shadow-md">
                <BiErrorAlt className="text-center text-5xl" />
                <div>
                  <b>{mailTracking.total_bounced}</b>{" "}
                  {t("newsletter.bouncedDashboard")}
                </div>
              </div>
            </div>
            <div className="flex flex-wrap gap-5">
              <button onClick={() => setFiltersOpen((state) => !state)}>
                <FilterIcon className="w-6 h-6" />
              </button>
            </div>
            {filtersOpen && (
              <div
                id="newsletter-filter-inputs-container"
                className="flex flex-wrap gap-5"
              >
                <div className="w-72">
                  <input
                    {...register("email")}
                    type="email"
                    id="email"
                    className="w-72 placeholder:text-selectStyles-gray border-disabledBorder focus:border-primary"
                    placeholder="Email"
                  />
                </div>
                <div className="w-72">
                  <input
                    {...register("name")}
                    type="text"
                    id="name"
                    className="w-72 placeholder:text-selectStyles-gray border-disabledBorder focus:border-primary"
                    placeholder="Nome"
                  />
                </div>
                <div className="w-72">
                  <input
                    {...register("surname")}
                    type="text"
                    id="surname"
                    className="w-72 placeholder:text-selectStyles-gray border-disabledBorder focus:border-primary"
                    placeholder="Surname"
                  />
                </div>
                <div className="w-72">
                  <Controller
                    name="region"
                    control={control}
                    render={({ field }) => (
                      <Select
                        placeholder={t("events.region")}
                        styles={getReactSelectStyles(false)}
                        isLoading={lookups.loading}
                        inputId="region"
                        isClearable={true}
                        value={field.value}
                        options={regionsOptions}
                        onChange={(e) => field.onChange(e)}
                      />
                    )}
                  />
                </div>
                <div className="w-72">
                  <Controller
                    name="sent_status"
                    control={control}
                    render={({ field }) => (
                      <Select
                        placeholder={`sent status`}
                        styles={getReactSelectStyles(false)}
                        isLoading={loading}
                        inputId="sent_status"
                        isClearable={true}
                        value={field.value}
                        options={sentStatusOptions}
                        onChange={(e) => field.onChange(e)}
                      />
                    )}
                  />
                </div>
                <div className="w-72">
                  <Controller
                    name="opened_status"
                    control={control}
                    render={({ field }) => (
                      <Select
                        placeholder={`opened status`}
                        styles={getReactSelectStyles(false)}
                        isLoading={loading}
                        inputId="opened_status"
                        isClearable={true}
                        value={field.value}
                        options={openedStatusOptions}
                        onChange={(e) => field.onChange(e)}
                      />
                    )}
                  />
                </div>
              </div>
            )}
            <Table {...tableInstance} className="users__table" />
            <Pagination
              rowsCount={mailTracking.total}
              pageIndex={pageIndex}
              pageSize={currentPageSize}
              canPreviousPage={canPreviousPage}
              canNextPage={canNextPage}
              pageOptions={pageOptions}
              pageCount={pageCount}
              gotoPage={dispatchGotoPage}
              nextPage={dispatchNextPage}
              previousPage={dispatchPreviousPage}
              setPageSize={setPageSize}
            />
          </div>
        ) : (
          <h2>No newsletter found!</h2>
        )}
        <div>
          <button
            className="px-4 py-2 text-users-create bg-white border border-users-create"
            type="button"
            onClick={() => onDownloadUsers()}
          >
            {t("massUpdate.exportUsers")}
          </button>
        </div>
      </div>
    </Overlay>
  );
};

export default NewsletterDashboard;
