import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
// import "./Demo.css";
import ScreenModal from "./ScreenModal";
import { useFormContext } from "react-hook-form";
import { XIcon } from "@heroicons/react/outline";
import { getCropBoxOffsets, resizeFile } from "utils/utils";

export default function CropImage({
  canClose = false,
  canForce = false,
  aspect = 16 / 9,
  reactHookFormName,
  editImageOn,
  setEditImageOn,
  maxHeight = -1,
  maxWidth = -1,
}: {
  canClose?: boolean;
  canForce?: boolean;
  aspect: number;
  reactHookFormName: string;
  editImageOn: boolean;
  setEditImageOn: Dispatch<SetStateAction<boolean>>;
  maxHeight?: number;
  maxWidth?: number;
}) {
  const { trigger, setValue, getValues } = useFormContext();
  const [image, setImage] = useState();
  const [cropData, setCropData] = useState();
  const [cropper, setCropper] = useState<any>();
  const scaleIsDirty = useRef<boolean>(false);
  const [scale, setScale] = useState(0.5);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const file = getValues(reactHookFormName);
    if (!file) return;
    resizeFile(file, aspect, maxWidth, maxHeight).then((f: any) => {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result as any);
      };
      reader.readAsDataURL(f);
    });
  }, []);

  const getCropData = () => {
    if (typeof cropper !== "undefined") {
      setCropData(cropper.getCroppedCanvas().toDataURL("image/png", 5 / 100));
    }
  };

  useEffect(() => {
    if (cropData) {
      const blob = fetch(cropData)
        .then((cropData) => cropData.blob())
        .then((blob) => {
          setValue(
            reactHookFormName,
            new File([blob], "croppedImage.png", { type: "image/png" })
          );
          trigger(reactHookFormName);
          setLoading(false);
          setEditImageOn(false);
        });
    }
  }, [cropData]);

  useEffect(() => {
    if (loading === true) {
      getCropData();
    }
  }, [loading]);

  return (
    <ScreenModal isOpen={editImageOn} setIsOpen={setEditImageOn}>
      <div className="flex flex-col gap-10 p-10 bg-white rounded-sm">
        <div className="flex items-center justify-between">
          <h1 className="text-3xl font-bold">Resize Image</h1>
          {canClose && (
            <button
              className="text-white bg-red-500 rounded-md"
              onClick={() => {
                const file = getValues(reactHookFormName);
                if (!file) return;
                resizeFile(file, aspect).then((f: any) => {
                  setValue(reactHookFormName, f);
                  trigger(reactHookFormName);
                });
                setEditImageOn(false);
              }}
            >
              <XIcon className="w-8 h-8" />
            </button>
          )}
        </div>
        <div
          style={{
            width: "100%",
            height: "100%",
            maxWidth: "calc(100vw - 10rem)",
            maxHeight: "calc(100vh - 20rem)",
          }}
        >
          <Cropper
            style={{
              width: "100%",
              height: "100%",
              maxWidth: "calc(100vw - 10rem)",
              maxHeight: "calc(100vh - 20rem)",
            }}
            zoomTo={scaleIsDirty ? scale : 1}
            initialAspectRatio={aspect}
            aspectRatio={aspect}
            src={image}
            viewMode={0}
            minCropBoxHeight={10}
            minCropBoxWidth={10}
            background={false}
            responsive={true}
            autoCropArea={1}
            checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
            onInitialized={(instance) => {
              setCropper(instance);
            }}
            guides={true}
          />
        </div>
        <div className="flex gap-5 justify-between">
          {canForce && (
            <div className="flex gap-5">
              <button
                type="button"
                className="p-2 text-white bg-global-createEntity rounded-sm"
                onClick={() => {
                  if (!cropper) return;
                  scaleIsDirty.current = true;
                  const { naturalHeight, naturalWidth } =
                    cropper.initialImageData;
                  const isHeightGreater = naturalWidth < naturalHeight;
                  const { width: containerWidth, height: containerHeight } =
                    cropper.containerData;
                  const { top, left } = getCropBoxOffsets({
                    containerHeight,
                    containerWidth,
                    isHeightGreater,
                    aspect,
                  });
                  cropper.setCropBoxData({
                    left,
                    top,
                    height: containerHeight,
                    width: containerWidth,
                  });
                  const { height: cropHeight, width: cropWidth } =
                    cropper.getCropBoxData();
                  let tempScale;
                  if (isHeightGreater) {
                    if (aspect === 1) {
                      tempScale = cropHeight / naturalHeight;
                    } else {
                      tempScale = cropHeight / naturalHeight;
                    }
                  } else {
                    if (aspect === 1) {
                      tempScale = cropWidth / naturalWidth;
                    } else {
                      tempScale = cropHeight / naturalHeight;
                    }
                  }
                  setScale(tempScale);
                }}
              >
                Force
              </button>
              <div className="flex flex-col gap-2">
                <label htmlFor="scale" className="text-lg font-medium">
                  Zoom
                </label>
                <input
                  type="range"
                  min="0.1"
                  max="2"
                  step="0.1"
                  name="scale"
                  id="scale"
                  value={scale}
                  onChange={(e) => {
                    scaleIsDirty.current = true;
                    setScale(Number(e.target.value));
                  }}
                  className="text-primary bg-primary border-primary"
                />
              </div>
            </div>
          )}
          <button
            className="px-4 py-2 text-white bg-global-createEntity rounded-sm"
            onClick={() => {
              setLoading(true);
            }}
          >
            {loading ? "Cropping..." : "Done"}
          </button>
        </div>
      </div>
    </ScreenModal>
  );
}
