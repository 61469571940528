import { CouncilRank, Rank } from "shared/interfaces/Rank.interface";

export interface CouncilRanksState {
  data: {
    message: string;
    total?: number;
    pageIndex?: number;
    councilRanks: CouncilRank[] | null;
  };
  loading: boolean;
}

export const councilRanksInitialState: CouncilRanksState = {
  data: {
    message: "",
    total: 0,
    pageIndex: 0,
    councilRanks: null,
  },
  loading: false,
};
