import {
  DotsVerticalIcon,
  ExclamationIcon,
  PencilIcon,
  SearchIcon,
  TrashIcon,
  ClipboardCopyIcon,
} from "@heroicons/react/solid";
import { getProductsColumns } from "api/Products/products.api";
import { CMS_BE_URL } from "api/urls";
import axios from "axios";
import Select from "react-select";
import React, { useEffect, useMemo, useState } from "react";
import {
  Column,
  useFlexLayout,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { Product } from "shared/interfaces/Product.interface";
import { useAppSelector } from "store/storeHooks";
import Pagination from "../Table/Pagination";
import Table from "../Table/Table";
import Modal from "../UI/Modal";
import TablePopup, { PortalType } from "../UI/TablePopup";
import { useTranslation } from "react-i18next";
import { PermissionDomain } from "shared/interfaces/Permission.interface";
import { RolePermissionCrudAction } from "shared/interfaces/RolePermission.interface";
import { selectLoggedInUser } from "store/authStore/authReducer";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getReactSelectStyles } from "utils/utils";

const ProductsDisplay = () => {
  const lang = useAppSelector((state) => state.translation.language);
  const [products, setProducts] = useState([]);
  const [productToDelete, setProductToDelete] = useState("");
  const [total, setTotal] = useState(0);
  const [currentPageSize, setCurrentPageSize] = useState(10);
  const [openModal, setOpenModal] = useState(false);
  const [search, setSearch] = useState("");
  const [productClass, setProductClass] = useState<any>("");
  const { t } = useTranslation("common");

  const [classOptions, setClassOptions] = useState<any>([
    { value: "product", label: "Product" },
    { value: "exam", label: "Exam" },
  ]);

  const loggedInUser = selectLoggedInUser();

  const [portal, setPortal] = useState<PortalType<any>>({
    open: false,
    top: 0,
    left: 0,
    entity: null,
  });

  const columns: Array<Column<Record<string, any>>> = useMemo(
    () => getProductsColumns(lang),
    [lang]
  );

  const getProducts = async ({
    pageSize,
    pageIndex,
    searchString,
  }: {
    pageSize: number;
    pageIndex: number;
    searchString: string;
  }) => {
    const { data } = await axios.get(
      `${CMS_BE_URL}/s/products?pageSize=${pageSize}&pageIndex=${pageIndex}&searchString=${searchString}&classType=${
        productClass && productClass.value ? productClass.value : ""
      }`
    );
    setProducts(data.data);
    setTotal(data.total);
  };

  function canAccess(
    domain: PermissionDomain,
    action: RolePermissionCrudAction,
    product?: Product
  ): boolean {
    if (loggedInUser.permissions["*"]) {
      return true;
    } else {
      switch (loggedInUser.permissions[domain][action]) {
        case "NONE": {
          return false;
        }
        case "OWN": {
          if (action === "CREATE") return true;
          if (product?.creator_id === loggedInUser.user?.PK_USER) return true;
          return false;
        }
        case "ANY": {
          return true;
        }
        default: {
          return false;
        }
      }
    }
  }

  const data: Array<any> = useMemo(() => {
    return products.map((prod: Product, i: number) => {
      let q = 0;
      prod.variants.forEach((v) => (q = q + v.inventory_quantity));
      return {
        col0: (
          <div className="flex items-center justify-center">
            {prod.thumbnail ? (
              <img
                src={prod.thumbnail}
                className="2xl:w-14 w-8 rounded-full object-cover"
              />
            ) : (
              <div className="2xl:w-14 w-8 2xl:h-14 h-8 border border-gray-800 rounded-full object-cover"></div>
            )}
          </div>
        ),
        col1: (
          <div className="flex gap-4 items-center truncate">
            <div className="flex flex-col gap-1">
              <div className="line-clamp-1 truncate">{prod.title}</div>
              <div>
                {prod.tags.length === 0 ? (
                  "No Tags Associated"
                ) : (
                  <div className="flex gap-2 truncate">
                    {prod.tags.map((t: any, i: any) => (
                      <span
                        key={i}
                        className="p-1 text-white bg-events-tags rounded-sm truncate"
                      >
                        {t.pk_tag}
                      </span>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        ),
        col2: (
          <div className="flex items-center h-full">
            <span className="truncate">{prod.description}</span>
          </div>
        ),
        col3: (
          <div className="flex items-center h-full">
            <span className="truncate">{prod.class}</span>
          </div>
        ),
        col4: (
          <div className="flex items-center h-full">
            <span className="truncate">
              {prod.only_subs ? "True" : "False"}
            </span>
          </div>
        ),
        col5: (
          <div className="flex items-center h-full">
            <span className="truncate">{q}</span>
          </div>
        ),
        col6: (
          <div className="flex items-center h-full">
            <span className="truncate">
              {prod.published ? "True" : "False"}
            </span>
          </div>
        ),

        col7: (
          <div className="relative flex items-center h-full">
            <button
              id={`portal-button-${i}`}
              type="button"
              onClick={(e) => {
                return setPortal((state: any) => {
                  const thisButton = document.getElementById(
                    `portal-button-${i}`
                  );
                  const position = thisButton?.getBoundingClientRect();
                  return {
                    open: !state.open,
                    top: Number(position?.bottom),
                    left: Number(
                      window.innerWidth - Number(position?.x) <= 150
                        ? Number(position?.left) - 150
                        : Number(position?.left)
                    ),
                    entity: prod,
                  };
                });
              }}
            >
              <DotsVerticalIcon className="w-5 h-5" />
            </button>
          </div>
        ),
      };
    });
  }, [products, lang]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
      pageCount: Math.ceil(total / currentPageSize),
      manualSortBy: true,
      manualPagination: true,
    },
    useSortBy,
    usePagination,
    useFlexLayout
  );

  const {
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, sortBy },
  } = tableInstance;

  useEffect(() => {
    getProducts({ pageSize: 10, pageIndex: 0, searchString: search });
  }, [search, productClass]);

  const dispatchNextPage = () => {
    if (canNextPage) {
      getProducts({
        pageIndex: pageIndex + 1,
        pageSize: currentPageSize,
        searchString: "",
      });

      nextPage();
    }
  };

  const dispatchPreviousPage = () => {
    if (canPreviousPage) {
      getProducts({
        pageIndex: pageIndex - 1,
        pageSize: currentPageSize,
        searchString: "",
      });
      previousPage();
    }
  };

  const dispatchGotoPage = (pageIndex: number) => {
    getProducts({
      pageIndex: pageIndex,
      pageSize: currentPageSize,
      searchString: "",
    });
    gotoPage(pageIndex);
  };

  const handleModal = (id: string) => {
    setOpenModal(true);
    setProductToDelete(id);
  };

  const handleDeleteProduct = async (doc: string) => {
    try {
      await axios.delete(`${CMS_BE_URL}/s/products/${doc}`);
      getProducts({ pageSize, pageIndex, searchString: "" });
      setOpenModal(false);
    } catch (err: any) {
      if (err.response?.data?.errors?.[0]?.message) {
        toast.error(err.response?.data?.errors?.[0]?.message);
      } else {
        toast.error(err.response?.data?.message);
      }
      // toast.error(
      //   Message({
      //     action: "delete",
      //     entity: "Product",
      //     error: t("genericError"),
      //     lang,
      //   })
      // );
    }
  };

  function portalContent() {
    if (portal.entity === null) return;
    return (
      <div
        className="fixed z-50 flex flex-col gap-2 p-2 bg-white rounded-sm shadow-md"
        style={{
          width: "150px",
          top: portal.top,
          left: portal.left,
        }}
      >
        {canAccess("s", "UPDATE", portal.entity) ? (
          <Link
            to={`/products/${portal.entity.id}`}
            className="flex gap-2 items-center text-table-edit"
          >
            <PencilIcon className="w-5 h-5" />
            {lang === "en" ? "Edit" : "Modificare"}
          </Link>
        ) : null}
        {canAccess("s", "UPDATE", portal.entity) ? (
          <Link
            to={`/products/create/${portal.entity.id}`}
            className="flex gap-2 items-center text-users-create"
          >
            <ClipboardCopyIcon className="w-5 h-5" />
            Clone
          </Link>
        ) : null}
        {canAccess("s", "DELETE", portal.entity) ? (
          <button
            onClick={() => handleModal(String(portal.entity?.id))}
            className="flex gap-2 items-center text-users-delete"
          >
            <TrashIcon className="w-5 h-5" />
            {lang === "en" ? "Delete" : "Eliminare"}
          </button>
        ) : null}
        {/* {canAccess("documents", "UPDATE", portal.entity) ? (
          <button
            className="flex gap-2 items-center text-users-edit"
            onClick={editVisibility}
          >
            {portal.entity.is_visible ? (
              <EyeOffIcon className="w-5 h-5" />
            ) : (
              <EyeIcon className="w-5 h-5" />
            )}
            {lang === "en"
              ? portal.entity.is_visible
                ? "Invisible"
                : "Visible"
              : portal.entity.is_visible
              ? "Invisible"
              : "Visible"}
          </button>
        ) : null} */}
      </div>
    );
  }
  return (
    <div>
      <div className="flex items-center justify-between">
        <Link to="/products/create">
          <div className="inline-block mb-4 px-4 py-2 text-white bg-users-create">
            {t("products.aProduct")}
          </div>
        </Link>
        <div className="flex gap-5 items-center">
          <div>
            <Select
              placeholder={t("products.class")}
              styles={getReactSelectStyles(false)}
              inputId="classType"
              // isLoading={ranks.loading}
              isClearable={true}
              value={productClass}
              options={classOptions}
              onChange={(e: any) => setProductClass(e)}
            />
          </div>
          <div className="flex items-center justify-start w-72 border focus-within:border-global-input-focus border-gray-300">
            <div className="flex items-center justify-center pl-2 h-full bg-white">
              <SearchIcon className="w-5 h-5 text-gray-400" />
            </div>
            <input
              type="text"
              placeholder={`${t("users.search")}...`}
              onChange={(e) => setSearch(e.target.value)}
              className="placeholder-gray-400 w-full border-0 focus:ring-0"
            />
          </div>
        </div>
      </div>
      <Table {...tableInstance} />
      <Pagination
        rowsCount={total}
        pageIndex={pageIndex}
        pageSize={currentPageSize}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        pageOptions={pageOptions}
        pageCount={pageCount}
        gotoPage={dispatchGotoPage}
        nextPage={dispatchNextPage}
        previousPage={dispatchPreviousPage}
        setPageSize={setPageSize}
      />
      <TablePopup
        isOpen={portal.open}
        close={() => setPortal((state) => ({ ...state, open: false }))}
      >
        {portalContent()}
      </TablePopup>
      <Modal
        openStatus={openModal}
        setOpen={setOpenModal}
        className=""
        icon={
          <ExclamationIcon
            className="w-6 h-6 text-red-600 bg-transparent"
            aria-hidden="true"
          />
        }
        header={<p>{t("products.deleteProductModalHeader")}</p>}
        title={<p>{t("products.deleteProductModalMessage")}</p>}
        footer={
          <div className="mt-5 sm:flex sm:flex-row-reverse sm:mt-4">
            <button
              type="button"
              className="inline-flex justify-center px-4 py-1 w-full text-white text-base font-medium bg-red-600 hover:bg-red-700 border border-transparent focus:outline-none shadow-sm focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={async () => await handleDeleteProduct(productToDelete)}
            >
              {t("UserCRUD.deleteButton")}
            </button>
            <button
              type="button"
              className="inline-flex justify-center mt-3 px-4 py-1 w-full hover:text-gray-500 text-gray-700 text-base font-medium bg-white border border-gray-300 focus:outline-none shadow-sm focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
              onClick={() => setOpenModal(false)}
            >
              {t("UserCRUD.cancelButton")}
            </button>
          </div>
        }
      />
    </div>
  );
};

export default ProductsDisplay;
