import { Lang } from "shared/components/UI/Message";

export type SelectVisibilityType = {
  value: string;
  label: string;
};

export const visibilityOptions: SelectVisibilityType[] = [
  { label: "Visible", value: "true" },
  { label: "Invisible", value: "false" },
];

export function getVisibilityFilterOptions(lang: Lang): SelectVisibilityType[] {
  if (lang === "en") {
    return [
      { label: "Visible", value: "true" },
      { label: "Invisible", value: "false" },
      { label: "All", value: "all" },
    ];
  } else {
    return [
      { label: "Visibile", value: "true" },
      { label: "Invisibile", value: "false" },
      { label: "Tutti", value: "all" },
    ];
  }
}

export const visibilityFilterOptions: SelectVisibilityType[] = [
  { label: "Visible", value: "true" },
  { label: "Invisible", value: "false" },
  { label: "All", value: "all" },
];
