import { CMS_BE_URL } from "api/urls";

export function createUserRankURL() {
  return `${CMS_BE_URL}/ranks/ur`;
}

export function getUserRanksURL(filters: {
  pageSize?: number;
  pageIndex?: number;
  sortCol?: string;
  sortOrder?: string;
}) {
  return `${CMS_BE_URL}/ranks/ur?${Object.entries(filters)
    .filter(([, value]) => !!value)
    .map(([key, value]) => `${key}=${value}`)
    .join("&")}`;
}

export function deleteUserRankURL({ rankId }: { rankId: string }) {
  return `${CMS_BE_URL}/ranks/ur/${rankId}`;
}

export function getUserRankURL({ rankId }: { rankId: string }) {
  return `${CMS_BE_URL}/ranks/ur/${rankId}`;
}

export function updateUserRankURL({ rankId }: { rankId: string }) {
  return `${CMS_BE_URL}/ranks/ur/${rankId}`;
}
