import { combineReducers, configureStore } from "@reduxjs/toolkit";
import sponsorsSlice from "./sponsorsStore/sponsorsReducer";
import userSlice from "./usersStore/usersReducer";
import authSlice from "./authStore/authReducer";
import documentSlice from "./documentsStore/documentsReducer";
import permissionsManagementSlice from "./permissionsManagement/permissionsManagementReducer";
import translationSlice from "./translationStore/translationReducer";
import lookupsSlice from "./lookups/lookupsReducer";
import { UsersState } from "./usersStore/usersState";
import { AuthState } from "./authStore/authState";
import { DocumentsState } from "./documentsStore/documentsState";
import { permissionsManagementState } from "./permissionsManagement/permissionsManagementState";
import { TranslationState } from "./translationStore/translationState";
import { LookupsState } from "./lookups/lookupsState";
import tagsSlice from "./tagsStore/tagsReducer";
import { CertificatesState } from "./certificatesStore/certificationsState";
import { NewsletterState } from "./newsletterStore/newsletterState";
import certificatesSlice from "./certificatesStore/certificationsReducer";
import { EventsState } from "./eventsStore/eventsState";
import eventsSlice from "./eventsStore/eventsReducer";
import newsletterSlice from "./newsletterStore/newsletterReducer";
import newsSlice from "./newsStore/newsReducer";
import { NewsState } from "./newsStore/newsState";
import { SponsorsState } from "./sponsorsStore/sponsorsState";
import examsSlice from "./examsStore/examsReducer";
import { ExamsState } from "./examsStore/examsState";
import { LocationState } from "./locationStore/locationState";
import locationSlice from "./locationStore/locationReducer";
import { CouncilsState } from "./councilsStore/councilsState";
import councilsSlice from "./councilsStore/councilsReducer";
import { AboutUsState } from "./aboutUsStore/aboutUsState";
import aboutUsSlice from "./aboutUsStore/aboutUsReducer";
import { CouncilRanksState } from "./councilRanks/councilRanksState";
import councilRanksSlice from "./councilRanks/councilRanksReducer";
import { UserRanksState } from "./userRanksStore/userRanksState";
import userRanksSlice from "./userRanksStore/userRanksReducer";
import ordersSlice from "./ordersStore/ordersReducer";
import paymentsSlice from "./paymentsStore/paymentsReducer";
import { OrderState } from "./ordersStore/ordersState";
import { PaymentsState } from "./paymentsStore/paymentsState";
import { ProductsState } from "./productsStore/productsState";
import productsSlice from "./productsStore/productsReducer";
import presidentialRanksSlice from "./presidentialRanks/presidentialRanksReducer";
import { PresidentialRanksState } from "./presidentialRanks/presidentialRanksState";
import { PlansState } from "./plansStore/plansState";
import plansSlice from "./plansStore/plansReducer";

export interface GlobalState {
  users: UsersState;
  certificate: CertificatesState;
  auth: AuthState;
  documents: DocumentsState;
  permissionsManagement: permissionsManagementState;
  translation: TranslationState;
  lookups: LookupsState;
  events: EventsState;
  news: NewsState;
  exams: ExamsState;
  sponsors: SponsorsState;
  location: LocationState;
  newsletter: NewsletterState;
  councils: CouncilsState;
  aboutUs: AboutUsState;
  councilRanks: CouncilRanksState;
  userRanks: UserRanksState;
  orders: OrderState;
  payments: PaymentsState;
  products: ProductsState;
  presidentialRanks: PresidentialRanksState;
  plans: PlansState;
}

const rootReducer = combineReducers({
  users: userSlice.reducer,
  certificates: certificatesSlice.reducer,
  auth: authSlice.reducer,
  documents: documentSlice.reducer,
  permissionsManagement: permissionsManagementSlice.reducer,
  translation: translationSlice.reducer,
  lookups: lookupsSlice.reducer,
  tags: tagsSlice.reducer,
  sponsors: sponsorsSlice.reducer,
  events: eventsSlice.reducer,
  news: newsSlice.reducer,
  exams: examsSlice.reducer,
  location: locationSlice.reducer,
  newsletter: newsletterSlice.reducer,
  councils: councilsSlice.reducer,
  aboutUs: aboutUsSlice.reducer,
  councilRanks: councilRanksSlice.reducer,
  userRanks: userRanksSlice.reducer,
  orders: ordersSlice.reducer,
  payments: paymentsSlice.reducer,
  products: productsSlice.reducer,
  presidentialRanks: presidentialRanksSlice.reducer,
  plans: plansSlice.reducer,
});

const globalStore = configureStore({
  devTools: true,
  reducer: rootReducer,
});

export const permissionsManagementActions = permissionsManagementSlice.actions;
export const userActions = userSlice.actions;
export const certificateActions = certificatesSlice.actions;
export const authActions = authSlice.actions;
export const documentsActions = documentSlice.actions;
export const translationActions = translationSlice.actions;
export const newsletterActions = newsletterSlice.actions;
export const lookupsActions = lookupsSlice.actions;
export const sponsorsActions = sponsorsSlice.actions;
export const tagsActions = tagsSlice.actions;
export const eventsActions = eventsSlice.actions;
export const newsActions = newsSlice.actions;
export const examsActions = examsSlice.actions;
export const locationActions = locationSlice.actions;
export const councilsActions = councilsSlice.actions;
export const aboutUsActions = aboutUsSlice.actions;
export const councilRanksActions = councilRanksSlice.actions;
export const userRanksActions = userRanksSlice.actions;
export const ordersActions = ordersSlice.actions;
export const paymentsActions = paymentsSlice.actions;
export const productsActions = productsSlice.actions;
export const presidentialRanksActions = presidentialRanksSlice.actions;
export const plansActions = plansSlice.actions;
export type RootState = ReturnType<typeof globalStore.getState>;
export type AppDispatch = typeof globalStore.dispatch;
export default globalStore;
