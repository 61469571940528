import { CMS_BE_URL } from "api/urls";

export const createPaymentURL = `${CMS_BE_URL}/s/payments`;

type TypeQueryParameter = {
  in?: string[];
  notIn?: string[];
};

function isTypeQueryParameter(par: string) {
  return par === "type";
}

function handleTypeQueryParameter(type: TypeQueryParameter) {
  if (type["in"]) return `type[in]=${JSON.stringify(type["in"])}`;
  else if (type["notIn"]) return `type[notIn]=${JSON.stringify(type["notIn"])}`;
  else return "";
}

type DateQueryParameter = {
  gt?: string;
  gte?: string;
  lt?: string;
  lte?: string;
  between?: string;
  notBetween?: string;
};

function isDateQueryParameter(par: string) {
  return par === "date";
}

function handleDateQueryParameter(date: DateQueryParameter) {
  if (date)
    return Object.entries(date)
      .map(([key, value]) => `date[${key}]=${JSON.stringify(value)}`)
      .join("&");
  return "";
}

export type GetPaymentFilters = {
  customerId?: string;
  include?: string;
  type?: TypeQueryParameter;
  date?: DateQueryParameter;
  pageSize?: number;
  pageIndex?: number;
  sortCol?: string;
  sortOrder?: "ASC" | "DESC";
  searchString?: string;
};

export const constructGetPaymentsURL = (filters: GetPaymentFilters) => {
  return `${CMS_BE_URL}/s/payments?${Object.entries(filters)
    .map(([key, value]) => {
      if (isTypeQueryParameter(key))
        return handleTypeQueryParameter(value as TypeQueryParameter);
      else if (isDateQueryParameter(key))
        return handleDateQueryParameter(value as DateQueryParameter);
      else return `${key}=${value}`;
    })
    .join("&")}`;
};

export const constructGetPaymentURL = (paymentId: string) =>
  `${CMS_BE_URL}/s/payments/${paymentId}`;

export const constructUpdatePaymentURL = (paymentId: string) =>
  `${CMS_BE_URL}/s/payments/${paymentId}`;

export const constructDeletePaymentURL = (paymentId: string) =>
  `${CMS_BE_URL}/s/payments/${paymentId}`;
