import axios, { AxiosResponse } from "axios";
import {
  getPermissionsURL,
  createPermissionURL,
  updatePermissionURL,
  deletePermissionURL,
} from "./urls";
import { CreatePermission } from "shared/interfaces/Permission.interface";

export const createPermission: (
  data: CreatePermission
) => Promise<AxiosResponse<any, any>> = (data: CreatePermission) => {
  return axios.post(createPermissionURL(), data);
};

export const getPermissions: () => Promise<AxiosResponse<any, any>> = () => {
  return axios.get(getPermissionsURL());
};

export const updatePermission: (
  permissionName: string,
  data: CreatePermission
) => Promise<AxiosResponse<any, any>> = (
  permissionName: string,
  data: CreatePermission
) => {
  return axios.put(updatePermissionURL(permissionName), data);
};

export const deletePermission: (
  permissionName: string
) => Promise<AxiosResponse<any, any>> = (permissionName: string) => {
  return axios.delete(deletePermissionURL(permissionName));
};
