import { CMS_BE_URL } from "api/urls";
import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";

import LocationSearch from "shared/components/UI/LocationSearch";
import Overlay from "shared/components/UI/Overlay";
import Spinner from "shared/components/UI/Spinner";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "store/storeHooks";
import { XIcon, PencilIcon } from "@heroicons/react/outline";
import Modal from "shared/components/UI/ModalC";
import { selectLoggedInUser } from "store/authStore/authReducer";
import { getInputStyles } from "utils/utils";
import Message from "shared/components/UI/Message";

const ContactUs = () => {
  const lang = useAppSelector((state) => state.translation.language);
  const [telModal, setTelModal] = useState(false);
  const [mailModal, setMailModal] = useState(false);
  const [editTelModal, setEditTelModal] = useState(false);
  const [editMailModal, setEditMailModal] = useState(false);
  const tabs: { name: string }[] = [
    { name: lang === "en" ? "Basic Info" : "Informazioni basilari" },
    { name: lang === "en" ? "Contact Info" : "Informazioni di contatto" },
  ];
  const [selectedTab, setSelectedTab] = useState<string>(tabs[0].name);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation("common");
  const [phoneInfo, setPhoneInfo] = useState<any>([]);
  const [mailInfo, setMailInfo] = useState<any>([]);
  const [item, setItem] = useState<any>({
    value: "",
    type: "",
    label: "",
  });

  const telLabel = useRef<any>();
  const telNumber = useRef<any>();
  const mailLabel = useRef<any>();
  const mailRef = useRef<any>();
  const editLabelRef = useRef<any>();
  const editValueRef = useRef<any>();

  const getContactUsData = async () => {
    setPhoneInfo([]);
    setMailInfo([]);
    setLoading(true);
    try {
      const res = await axios.get(`${CMS_BE_URL}/contactus/1`);
      setValue("description", res.data.data.description);
      setValue("instructions", res.data.data.instructions);
      if (res.data.data.info) {
        res.data.data.info.map((inf: any) => {
          if (inf.type === "TELEPHONE") {
            setPhoneInfo((prev: any) => [...prev, inf]);
          } else if (inf.type === "MAIL") {
            setMailInfo((prev: any) => [...prev, inf]);
          }
          // else if (inf.type === "OFFICE" && inf.label === "Secretary") {
          //   setValue("secretary", inf.value);
          // } else if (inf.type === "OFFICE" && inf.label === "Legal office") {
          //   setValue("legal_office", inf.value);
          // }
        });
      }

      setValue("location.city", res.data.data.location.city);
      setValue("location.country", res.data.data.location.country);
      setValue("location.latitude", res.data.data.location.latitude);
      setValue("location.longitude", res.data.data.location.longitude);
      setValue("location.postal_code", res.data.data.location.postal_code);
      setValue("location.region", res.data.data.location.region);
      setValue("location.province", res.data.data.location.province);
      setValue("location.street_name", res.data.data.location.street_name);
      setValue("location.street_number", res.data.data.location.street_number);
    } catch (err: any) {
      if (err.response?.data?.errors?.[0]?.message) {
        toast.error(err.response?.data?.errors?.[0]?.message);
      } else {
        toast.error(err.response?.data?.message);
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    getContactUsData();
  }, []);

  function classNames(...classes: any) {
    return classes.filter(Boolean).join(" ");
  }

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
    clearErrors,
    setError,
    trigger,
    getValues,
  } = useForm({
    defaultValues: {
      title: "Get in touch with ANMB",
      description: "",
      instructions: "",
      info: [],
      // secretary: "",
      // legal_office: "",
      location: {
        country: undefined,
        city: undefined,
        province: undefined,
        region: undefined,
        postal_code: undefined,
        street_name: undefined,
        street_number: undefined,
        longitude: undefined,
        latitude: undefined,
      },
    },
  });

  const removeItem = (i: string, t: string) => {
    if (t === "TELEPHONE") {
      setPhoneInfo(phoneInfo.filter((inf: any) => inf.label !== i));
    } else if (t === "MAIL") {
      setMailInfo(mailInfo.filter((inf: any) => inf.label !== i));
    }
  };

  const addTell = () => {
    const isThere = (i: { label: any; type: string }) =>
      i.type === "TELEPHONE" && i.label === telLabel.current.value;
    if (phoneInfo.some(isThere)) {
      toast.error(t("contact.le"));
      return;
    }

    const newInfo = {
      type: "TELEPHONE",
      label: telLabel.current.value,
      value: telNumber.current.value,
    };
    setPhoneInfo((prev: any) => [...prev, newInfo]);
    setTelModal(false);
    telLabel.current.value = "";
    telNumber.current.value = "";
  };

  const editTel = (label: any, type: any) => {
    const newArr = phoneInfo.map((info: { label: any }) => {
      if (info.label === label) {
        return {
          ...info,
          label: editLabelRef.current.value,
          value: editValueRef.current.value,
        };
      } else {
        return {
          ...info,
        };
      }
    });
    setPhoneInfo(newArr);
    setItem({
      value: "",
      type: "",
      label: "",
    });
    setEditTelModal(false);
  };

  const addMail = () => {
    if (!validateEmail(mailRef.current.value)) {
      toast.error(t("contact.vm"));
      return;
    }
    const isThere = (i: { label: any; type: string }) =>
      i.type === "MAIL" && i.label === mailLabel.current.value;
    if (mailInfo.some(isThere)) {
      toast.error(t("contact.le"));
      return;
    }

    const newInfo = {
      type: "MAIL",
      label: mailLabel.current.value,
      value: mailRef.current.value,
    };
    setMailInfo((prev: any) => [...prev, newInfo]);
    setMailModal(false);
    mailLabel.current.value = "";
    mailRef.current.value = "";
  };

  const editMail = (label: any, type: any) => {
    const newArr = mailInfo.map((info: { label: any }) => {
      if (info.label === label) {
        return {
          ...info,
          label: editLabelRef.current.value,
          value: editValueRef.current.value,
        };
      } else {
        return {
          ...info,
        };
      }
    });
    setMailInfo(newArr);
    setItem({
      value: "",
      type: "",
      label: "",
    });
    setEditMailModal(false);
  };

  const validateEmail = (email: string) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const onSubmit = async (data: any) => {
    setLoading(true);

    data.info = [...mailInfo, ...phoneInfo];
    if (data.secretary !== "")
      data.info = [
        ...data.info,
        {
          type: "OFFICE",
          label: t("contact.secretary"),
          value: data.secretary,
        },
      ];

    if (data.legal_office !== "")
      data.info = [
        ...data.info,
        {
          type: "OFFICE",
          label: t("contact.legalOffice"),
          value: data.legal_office,
        },
      ];
    try {
      setMailInfo([]);
      setPhoneInfo([]);
      const res = await axios.put(`${CMS_BE_URL}/contactus/1`, data);
      getContactUsData();
      toast.success(
        Message({
          action: "update",
          entity: t("contact.information"),
          lang,
          gender: "female",
        })
      );
    } catch (err: any) {
      if (err.response?.data?.errors?.[0]?.message) {
        toast.error(err.response?.data?.errors?.[0]?.message);
      } else {
        toast.error(err.response?.data?.message);
      }
    }
    setLoading(false);
  };

  return (
    <Overlay active={loading} spinner={<Spinner />}>
      <div className="p-10">
        {/* <GoBack uri="" /> */}
        <div className="">
          <h1 className="mb-2 text-3xl font-bold">{t("contact.h1")}</h1>
          <p className="text-global-subHeader">{t("contact.p")}</p>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex items-center justify-between w-full">
            <nav className="flex gap-5" aria-label="Tabs">
              {tabs.map((tab: any, i) => (
                <button
                  type="button"
                  onClick={() => setSelectedTab(tab.name)}
                  key={i}
                  className={classNames(
                    tab.name == selectedTab
                      ? "border-users-create text-users-create"
                      : "border-transparent text-gray-500 hover:border-gray-300",
                    "px-1 py-2 whitespace-nowrap text-lg font-medium border-b-2"
                  )}
                  aria-current={tab.current ? "page" : undefined}
                >
                  {tab.name}
                </button>
              ))}
            </nav>
            <div>
              <button
                type="submit"
                className="px-4 py-2 text-white bg-users-create"
              >
                {t("new.save")}
              </button>
            </div>
          </div>
          <div>
            <div className={selectedTab === tabs[0].name ? "block" : "hidden"}>
              <h2 className="my-5 text-2xl font-bold tracking-wide">
                {t("contact.h2")}
              </h2>
              <div className="flex gap-10 mb-10 w-full">
                <div className="flex-1">
                  <label className="text-lg font-medium">
                    {t("UserCRUD.information")}
                  </label>
                  <br />
                  <textarea
                    className={`${getInputStyles(
                      !!errors.description
                    )} flex-grow h-full`}
                    {...register("description", { required: true })}
                  />
                </div>
                <div className="flex-1">
                  <LocationSearch
                    getValues={getValues}
                    register={register}
                    setValue={setValue}
                    clearErrors={clearErrors}
                    watch={watch}
                    errors={errors}
                    reactHookFormName="location"
                  />
                </div>
              </div>
              <div>
                <label className="text-lg font-medium">
                  {t("contact.directions")}
                </label>{" "}
                <br />
                <textarea
                  {...register("instructions", { required: true })}
                  className={`${getInputStyles(
                    !!errors.instructions
                  )} flex-grow h-full`}
                />
              </div>
            </div>
            <div
              className={selectedTab === tabs[1].name ? "block my-5" : "hidden"}
            >
              <div className="mb-5">
                <div className="flex gap-5 mb-4">
                  <label className="block mb-1 text-lg font-medium">
                    {t("contact.tel")}
                  </label>
                  <button
                    className="px-2 py-1 text-white bg-users-create"
                    type="button"
                    onClick={() => setTelModal(true)}
                  >
                    {t("userRanks.add")} tel
                  </button>
                </div>

                <div className="flex gap-5 mb-10">
                  {phoneInfo.map((info: any) => (
                    <div
                      key={info.label}
                      className="px-2 py-1 border border-gray-300 shadow-sm"
                    >
                      <div className="flex gap-10 items-center justify-center">
                        <p className="mb-2 font-medium"> {info.label}</p>
                        <div className="flex gap-2">
                          <button
                            type="button"
                            className="text-gray-800 text-xs"
                            onClick={() => {
                              setEditTelModal(true);
                              setItem({
                                label: info.label,
                                type: info.type,
                                value: info.value,
                              });
                            }}
                          >
                            <PencilIcon className="w-5 h-5" />
                          </button>
                          <button
                            type="button"
                            className="text-gray-800 text-xs"
                            onClick={() => removeItem(info.label, info.type)}
                          >
                            <XIcon className="w-5 h-5" />
                          </button>
                        </div>
                      </div>
                      <p>{info.value}</p>
                    </div>
                  ))}
                </div>
                <div className="mb-5">
                  <div className="flex gap-5 mb-4">
                    <label className="block mb-1 text-lg font-medium">
                      Mail
                    </label>
                    <button
                      className="px-2 py-1 text-white bg-users-create"
                      type="button"
                      onClick={() => setMailModal(true)}
                    >
                      {t("userRanks.add")} mail
                    </button>
                  </div>

                  <div className="flex gap-5 mb-10">
                    {mailInfo.map((info: any, i: any) => (
                      <div
                        key={i}
                        className="px-2 py-1 border border-gray-300 shadow-sm"
                      >
                        <div className="flex items-center justify-between">
                          <p className="mb-2 font-medium"> {info.label}</p>
                          <div className="flex gap-2">
                            <button
                              type="button"
                              className="text-gray-800 text-xs"
                              onClick={() => {
                                setEditMailModal(true);
                                setItem({
                                  label: info.label,
                                  type: info.type,
                                  value: info.value,
                                });
                              }}
                            >
                              <PencilIcon className="w-5 h-5" />
                            </button>
                            <button
                              type="button"
                              className="text-xs"
                              onClick={() => removeItem(info.label, info.type)}
                              key={i}
                            >
                              <XIcon className="w-5 h-5" />
                            </button>
                          </div>
                        </div>
                        <p>{info.value}</p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
        <Modal
          openStatus={telModal}
          setOpen={setTelModal}
          className=""
          header={<p>{t("contact.addTel")}</p>}
          title={
            <div className="my-4 w-full">
              <div className="flex flex-col gap-5">
                <div>
                  <label className="block mb-1 text-gray-900">
                    {t("contact.label")}
                  </label>
                  <input
                    className={getInputStyles(false)}
                    autoFocus
                    ref={telLabel}
                    type="text"
                  />
                </div>
                <div>
                  <label className="block mb-1 text-gray-900">
                    {t("contact.number")}
                  </label>
                  <input
                    className={getInputStyles(false)}
                    ref={telNumber}
                    type="text"
                  />
                </div>
              </div>
            </div>
          }
          footer={
            <div className="mt-5 sm:flex sm:flex-row-reverse sm:mt-4">
              <button
                type="button"
                className="inline-flex justify-center px-4 py-1 w-full text-white text-base font-medium bg-users-create border border-transparent focus:outline-none shadow-sm sm:ml-3 sm:w-auto sm:text-sm"
                onClick={() => addTell()}
              >
                {t("userRanks.add")}
              </button>
              <button
                type="button"
                className="inline-flex justify-center mt-3 px-4 py-1 w-full hover:text-gray-500 text-gray-700 text-base font-medium bg-white border border-gray-300 focus:outline-none shadow-sm focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                onClick={() => setTelModal(false)}
              >
                {t("UserCRUD.cancelButton")}
              </button>
            </div>
          }
        />
        <Modal
          openStatus={mailModal}
          setOpen={setMailModal}
          className=""
          header={<p>{t("userRanks.add")} mail</p>}
          title={
            <div className="my-4 w-full">
              <div className="flex flex-col gap-5">
                <div>
                  <label className="block mb-1 text-gray-900">
                    {t("contact.label")}
                  </label>
                  <input
                    autoFocus
                    ref={mailLabel}
                    className={getInputStyles(false)}
                    type="text"
                  />
                </div>
                <div>
                  <label className="block mb-1 text-gray-900">Mail</label>
                  <input
                    ref={mailRef}
                    className={getInputStyles(false)}
                    type="email"
                  />
                </div>
              </div>
            </div>
          }
          footer={
            <div className="mt-5 sm:flex sm:flex-row-reverse sm:mt-4">
              <button
                type="button"
                className="inline-flex justify-center px-4 py-1 w-full text-white text-base font-medium bg-users-create border border-transparent focus:outline-none shadow-sm sm:ml-3 sm:w-auto sm:text-sm"
                onClick={() => addMail()}
              >
                {t("userRanks.add")}
              </button>
              <button
                type="button"
                className="inline-flex justify-center mt-3 px-4 py-1 w-full hover:text-gray-500 text-gray-700 text-base font-medium bg-white border border-gray-300 focus:outline-none shadow-sm focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                onClick={() => setMailModal(false)}
              >
                {t("UserCRUD.cancelButton")}
              </button>
            </div>
          }
        />
        <Modal
          openStatus={editTelModal}
          setOpen={setEditTelModal}
          className=""
          header={<p>{t("contact.et")}</p>}
          title={
            <div className="my-4 w-full">
              <div className="flex flex-col gap-5">
                <div>
                  <label className="block mb-1 text-gray-900">
                    {t("contact.label")}
                  </label>
                  <input
                    autoFocus
                    defaultValue={item.label}
                    ref={editLabelRef}
                    className="border border-gray-300"
                    type="text"
                  />
                </div>
                <div>
                  <label className="block mb-1 text-gray-900">
                    {t("contact.tel")}
                  </label>
                  <input
                    defaultValue={item.value}
                    ref={editValueRef}
                    className="border border-gray-300"
                    type="text"
                  />
                </div>
              </div>
            </div>
          }
          footer={
            <div className="mt-5 sm:flex sm:flex-row-reverse sm:mt-4">
              <button
                type="button"
                className="inline-flex justify-center px-4 py-1 w-full text-white text-base font-medium bg-users-create border border-transparent focus:outline-none shadow-sm sm:ml-3 sm:w-auto sm:text-sm"
                onClick={() => editTel(item.label, item.type)}
              >
                {t("nationalCouncil.edit")}
              </button>
              <button
                type="button"
                className="inline-flex justify-center mt-3 px-4 py-1 w-full hover:text-gray-500 text-gray-700 text-base font-medium bg-white border border-gray-300 focus:outline-none shadow-sm focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                onClick={() => setEditTelModal(false)}
              >
                {t("UserCRUD.cancelButton")}
              </button>
            </div>
          }
        />
        <Modal
          openStatus={editMailModal}
          setOpen={setEditMailModal}
          className=""
          header={<p>{t("contact.ev")}</p>}
          title={
            <div className="my-4 w-full">
              <div className="flex flex-col gap-5">
                <div>
                  <label className="block mb-1 text-gray-900">
                    {" "}
                    {t("contact.label")}
                  </label>
                  <input
                    autoFocus
                    defaultValue={item.label}
                    ref={editLabelRef}
                    className="border border-gray-300"
                    type="text"
                  />
                </div>
                <div>
                  <label className="block mb-1 text-gray-900">Mail</label>
                  <input
                    defaultValue={item.value}
                    ref={editValueRef}
                    className="border border-gray-300"
                    type="text"
                  />
                </div>
              </div>
            </div>
          }
          footer={
            <div className="mt-5 sm:flex sm:flex-row-reverse sm:mt-4">
              <button
                type="button"
                className="inline-flex justify-center px-4 py-1 w-full text-white text-base font-medium bg-users-create border border-transparent focus:outline-none shadow-sm sm:ml-3 sm:w-auto sm:text-sm"
                onClick={() => editMail(item.label, item.type)}
              >
                {t("user.edit")}
              </button>
              <button
                type="button"
                className="inline-flex justify-center mt-3 px-4 py-1 w-full hover:text-gray-500 text-gray-700 text-base font-medium bg-white border border-gray-300 focus:outline-none shadow-sm focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                onClick={() => setEditMailModal(false)}
              >
                {t("UserCRUD.cancelButton")}
              </button>
            </div>
          }
        />
      </div>
    </Overlay>
  );
};

export default ContactUs;
