import { PlusIcon, XIcon } from "@heroicons/react/outline";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Message from "shared/components/UI/Message";
import {
  CouncilMember,
  CreateCouncilDetails,
  CreateCouncilUser,
} from "shared/interfaces/Council.interface";
import { User, UserLowercase } from "shared/interfaces/User.interface";
import {
  getCouncilThunk,
  selectCouncils,
  updateCouncilThunk,
} from "store/councilsStore/councilsReducer";
import { useAppDispatch } from "store/storeHooks";
import SelectUser from "./SelectUser";
import UserModal from "../../shared/components/UI/ScreenModal";
import { selectTranslationLanguage } from "store/translationStore/translationReducer";
import { useTranslation } from "react-i18next";
import { findPhone } from "utils/utils";
import { PresidentialRank } from "shared/interfaces/Rank.interface";

type NationalPresident = {
  name: "nationalPresident";
  usernames: [CreateCouncilUser];
};

export default function NationalPresident() {
  const { t } = useTranslation("common");
  const lang = selectTranslationLanguage();
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [reactHookFormName, setReactHookFormName] = useState<string>("");
  const [onComplete, setOnComplete] = useState<any>(null);
  const [boardOfDirectorsMembers, setBoardOfDirectorsMembers] =
    useState<any>(null);
  const [defaultUser, setDefaultUser] = useState<CouncilMember | null>(null);

  const { setValue, getValues, handleSubmit } = useForm<NationalPresident>({
    mode: "onChange",
    defaultValues: {
      name: "nationalPresident",
      usernames: [
        {
          pk_user: "",
          rank: undefined,
          priority: 0,
        },
      ],
    },
  });

  useEffect(function getDirectorsBoard() {
    dispatch(getCouncilThunk({ council: "nationalPresident" })).then((res) => {
      if (res.meta.requestStatus === "rejected") {
        toast.error(
          Message({
            action: "read",
            entity: t("nationalPresident.nationalPresident"),
            error: t("genericError"),
            lang,
          })
        );
      }
    });
  }, []);

  const councils = selectCouncils();

  useEffect(() => {
    if (councils.data.data?.users) {
      setValue(`usernames.0.pk_user`, councils.data.data?.users[0].pk_user);
      setValue(`usernames.0.rank`, councils.data.data?.users[0].rank);
      setValue(`usernames.0.priority`, councils.data.data?.users[0].priority);
      setBoardOfDirectorsMembers(councils.data.data?.users);
    }
  }, [councils.data.data?.users]);

  function constructUpdateCouncil(data: NationalPresident) {
    const boardOfDirectors: CreateCouncilDetails = {} as CreateCouncilDetails;
    boardOfDirectors.name = data.name;
    data.usernames[0] = {
      pk_user: data.usernames[0].pk_user,
      rank: "1",
      priority: 1,
    };
    boardOfDirectors.usernames = JSON.stringify(data.usernames);
    return boardOfDirectors;
  }

  const onSubmit = handleSubmit(function onSubmit(data) {
    dispatch(
      updateCouncilThunk({
        council: "nationalPresident",
        data: constructUpdateCouncil(data),
      })
    ).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        toast.success(
          Message({
            action: "update",
            entity: t("nationalPresident.nationalPresident"),
            lang,
          })
        );
        dispatch(getCouncilThunk({ council: "nationalPresident" })).then(
          (res) => {
            if (res.meta.requestStatus === "rejected") {
              toast.error(
                Message({
                  action: "read",
                  entity: t("nationalPresident.nationalPresident"),
                  error: t("genericError"),
                  lang,
                })
              );
            }
          }
        );
      } else {
        toast.error(
          Message({
            action: "update",
            entity: t("nationalPresident.nationalPresident"),
            error: t("genericError"),
            lang,
          })
        );
      }
    });
  });

  function displayBoardOfDirectorMember(rank: string, index: number) {
    if (boardOfDirectorsMembers && boardOfDirectorsMembers[index]) {
      return (
        <div className="flex flex-col gap-2">
          <h1 className="text-lg font-medium">{rank}</h1>
          <div className="flex flex-col gap-2 p-4 bg-white border">
            <h1 className="font-medium">
              {boardOfDirectorsMembers[index].name}{" "}
              {boardOfDirectorsMembers[index].surname}
            </h1>
            <p>
              {boardOfDirectorsMembers[index].addressname}{" "}
              {boardOfDirectorsMembers[index].addressnumber} -{" "}
              {boardOfDirectorsMembers[index].postalcode}
            </p>
            <p>{boardOfDirectorsMembers[index].province}</p>
            <p>{boardOfDirectorsMembers[index].phone}</p>
            <div className="flex gap-5 justify-between">
              <p>{boardOfDirectorsMembers[index].email}</p>
              <button
                type="button"
                className="text-green-600"
                onClick={function onClick() {
                  setDefaultUser(() => boardOfDirectorsMembers[index]);
                  setReactHookFormName(function () {
                    return `usernames.${index}.`;
                  });
                  setOnComplete(function setOnComplete() {
                    return function (
                      user: UserLowercase,
                      rank: PresidentialRank,
                      priority: number
                    ) {
                      setBoardOfDirectorsMembers(function setBoardOfDirectors(
                        boardOfDirectorsMembers: any
                      ) {
                        const tempBoardOfDirectorsMembers =
                          boardOfDirectorsMembers.slice();
                        tempBoardOfDirectorsMembers[index] = {
                          pk_user: user.pk_user,
                          name: user.name,
                          surname: user.surname,
                          email: user.email,
                          addressname: user.address_details[0]?.address_name,
                          addressnumber:
                            user.address_details[0]?.address_number,
                          postalcode: user.address_details[0]?.postal_code,
                          city: user.address_details[0]?.city,
                          province: user.address_details[0]?.province,
                          rank: rank,
                          priority,
                          phone: findPhone(user.contact_details)?.details,
                        };
                        return tempBoardOfDirectorsMembers;
                      });
                      setDefaultUser(null);
                    };
                  });
                  setIsOpen(function (isOpen) {
                    return !isOpen;
                  });
                }}
              >
                {t("nationalPresident.edit")}
              </button>
            </div>
          </div>
        </div>
      );
    }
    return (
      <button
        type="button"
        className="flex flex-col gap-2 items-center p-4 border"
        onClick={function onClick() {
          setReactHookFormName(function () {
            return `usernames.${index}.`;
          });
          setOnComplete(function setOnComplete() {
            return function (
              user: UserLowercase,
              rank: PresidentialRank,
              priority: number
            ) {
              setBoardOfDirectorsMembers(function setBoardOfDirectors(
                boardOfDirectorsMembers: any
              ) {
                const tempBoardOfDirectorsMembers =
                  boardOfDirectorsMembers.slice();
                tempBoardOfDirectorsMembers[index] = {
                  pk_user: user.pk_user,
                  name: user.name,
                  surname: user.surname,
                  email: user.email,
                  addressname: user.address_details[0]?.address_name,
                  addressnumber: user.address_details[0]?.address_number,
                  postalcode: user.address_details[0]?.postal_code,
                  city: user.address_details[0]?.city,
                  province: user.address_details[0]?.province,
                  rank: rank,
                  priority: priority,
                  phone: findPhone(user.contact_details)?.details,
                };
                return tempBoardOfDirectorsMembers;
              });
            };
          });
          setIsOpen(function (isOpen) {
            return !isOpen;
          });
        }}
      >
        <h1 className="text-lg font-medium">{rank}</h1>
        <PlusIcon className="w-10 h-10" />
        <p>Add {rank}</p>
      </button>
    );
  }

  return (
    <div className="flex flex-col h-full">
      <form onSubmit={onSubmit} className="flex flex-col flex-grow gap-5">
        <div className="flex gap-10 justify-between">
          <h1 className="text-2xl font-bold">{t("nationalPresident.title")}</h1>
          <button type="submit" className="px-4 py-2 text-white bg-global-save">
            {t("nationalPresident.save")}
          </button>
        </div>
        <UserModal isOpen={isOpen} setIsOpen={setIsOpen}>
          <div
            style={{ width: "50rem" }}
            className="flex flex-col gap-5 p-10 bg-white rounded-md"
          >
            <div className="flex gap-5 justify-between">
              <div className="text-2xl font-bold">
                {t("nationalPresident.update")}
              </div>
              <button
                type="button"
                onClick={() => {
                  setIsOpen(false);
                  setDefaultUser(null);
                }}
                className="p-0.5 text-white bg-red-600 rounded-md"
              >
                <XIcon className="w-8 h-8" />
              </button>
            </div>
            <SelectUser
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              reactHookFormName={reactHookFormName}
              propGetValues={getValues}
              propSetValue={setValue}
              disabledRank={true}
              rankToDisplay={t("boardOfDirectors.president")}
              disabledPriority={true}
              onComplete={onComplete}
              excludeUsers={boardOfDirectorsMembers}
              defaultUser={defaultUser}
            />
          </div>
        </UserModal>
        <div className="grid gap-5 grid-cols-3">
          {displayBoardOfDirectorMember(t("boardOfDirectors.president"), 0)}
        </div>
      </form>
    </div>
  );
}
