import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createProduct,
  CreateProductDto,
  deleteProduct,
  getProduct,
  getProducts,
  updateProduct,
  UpdateProductDto,
} from "api/Products/products.api";
import { GetProductFilters } from "api/Products/urls";
import { useAppSelector } from "store/storeHooks";
import { ProductsInitialState } from "./productsState";

export const getProductsThunk = createAsyncThunk(
  "productStore/getProducts",
  async (filters: GetProductFilters) => {
    const res = await getProducts(filters);
    return res.data;
  }
);

export const getProductThunk = createAsyncThunk(
  "productStore/getProduct",
  async (productId: string) => {
    const res = await getProduct(productId);
    return res.data;
  }
);

export const createProductThunk = createAsyncThunk(
  "productStore/createProduct",
  async (createOrderDto: CreateProductDto) => {
    const res = await createProduct(createOrderDto);
    return res.data;
  }
);

export const updateProductThunk = createAsyncThunk(
  "productStore/updateProduct",
  async ({
    orderId,
    updateOrderDto,
  }: {
    orderId: string;
    updateOrderDto: UpdateProductDto;
  }) => {
    const res = await updateProduct(orderId, updateOrderDto);
    return res.data;
  }
);

export const deleteProductThunk = createAsyncThunk(
  "productStore/deleteProduct",
  async (orderId: string) => {
    const res = await deleteProduct(orderId);
    return res.data;
  }
);

const productsSlice = createSlice({
  name: "productStore",
  initialState: ProductsInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createProductThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createProductThunk.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createProductThunk.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getProductsThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getProductsThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(getProductsThunk.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getProductThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getProductThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.data.product = action.payload;
    });
    builder.addCase(getProductThunk.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(updateProductThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateProductThunk.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(updateProductThunk.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(deleteProductThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteProductThunk.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteProductThunk.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const selectProducts = () => useAppSelector((state) => state.products);

export default productsSlice;
