import { CMS_BE_URL } from "api/urls";

export const getCertificatesURL = (filters: {
  badge: string;
  code: string;
  pageSize: number;
  pageIndex: number;
  searchString: string;
  sortCol: string;
  sortOrder: string;
}): string => {
  const { badge, code, pageSize, pageIndex, searchString, sortCol, sortOrder } =
    filters;
  const q = [];
  if (badge) q.push(`badge=${badge}`);
  if (code) q.push(`code=${code}`);
  if (pageSize) q.push(`pageSize=${pageSize}`);
  if (pageIndex) q.push(`pageIndex=${pageIndex}`);
  if (searchString) q.push(`searchString=${searchString}`);
  if (sortCol) q.push(`sortCol=${sortCol}`);
  if (sortOrder) q.push(`sortOrder=${sortOrder}`);
  return `${CMS_BE_URL}/certificates?`.concat(q.join("&"));
};

export const getCertificateURL = (certificateId: string): string => {
  return `${CMS_BE_URL}/certificates/${certificateId}`;
};

export const deleteCertificateURL = (certificateId: string): string =>
  `${CMS_BE_URL}/certificates/${certificateId}`;

export const createCertificateURL: () => string = () =>
  `${CMS_BE_URL}/certificates`;

export const updateCertificateURL = (certificateId: string): string =>
  `${CMS_BE_URL}/certificates/${certificateId}`;
