import { createSlice } from "@reduxjs/toolkit";
import { Lang } from "shared/components/UI/Message";
import { useAppSelector } from "store/storeHooks";
import { translationInitialState } from "./translationState";

const translationSlice = createSlice({
  name: "translationState",
  initialState: translationInitialState,
  reducers: {
    changeLanguage: (state, action: { payload: { language: Lang } }) => {
      state.language = action.payload.language;
      localStorage.setItem("i18nextLng", action.payload.language);
    },
  },
});

export const selectTranslationLanguage = () =>
  useAppSelector((state) => state.translation.language);

export default translationSlice;
