import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";

type TimeLeft =
  | {
      minutes: number;
      seconds: number;
    }
  | -1;

export default function Timer({ date, ...props }: { date: number }) {
  const navigate = useNavigate();
  const { t } = useTranslation("common");

  function calculateTimeLeft(): TimeLeft {
    const difference = +new Date(date) - +new Date();
    let timeLeft = {};
    if (difference > 0) {
      timeLeft = {
        // days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        // hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
      return timeLeft as TimeLeft;
    } else return -1;
  }

  // TODO: Use translation
  const timerExpiredMsg = () => (
    <div className="flex flex-col gap-1">
      <p className="text-xs font-semibold">Failed to reset password</p>
      <p className="text-xs">Session expired!</p>
    </div>
  );

  const [timeLeft, setTimeLeft] = useState<TimeLeft>(calculateTimeLeft());
  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    return () => clearTimeout(timer);
  });

  useEffect(() => {
    if (timeLeft === -1) {
      toast.error(timerExpiredMsg);
      navigate("/");
    }
  }, [timeLeft]);

  function renderTime() {
    if (timeLeft === -1) return null;
    return timeLeft.minutes + ":" + timeLeft.seconds;
  }

  return <div {...props}>{renderTime()}</div>;
}
