import {
  deleteReceipt,
  getReceipt,
  getReceipts,
  massExportReceipts,
  receiptsColumns,
  updateReceipts,
} from "api/Receipts/receipts.api";
import { getUser, getUsers } from "api/Users/users.api";
import { debounce } from "lodash";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ReactDatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import {
  useFlexLayout,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import { toast } from "react-toastify";
import Pagination from "shared/components/Table/Pagination";
import DisplayInputError from "shared/components/UI/DisplayInputError";
import Message, { StrMessage } from "shared/components/UI/Message";
import Overlay from "shared/components/UI/Overlay";
import Spinner from "shared/components/UI/Spinner";
import { User } from "shared/interfaces/User.interface";
import { selectTranslationLanguage } from "store/translationStore/translationReducer";
import { b64toBlob, getInputStyles, getReactSelectStyles } from "utils/utils";
import { Link, useParams } from "react-router-dom";
import Table from "shared/components/Table/Table";
import {
  EnchancedReceipt,
  MassExportReceiptsDto,
  MassExportReceiptsDtoExclude,
  MassExportReceiptsDtoInclude,
  MassUpdateReceiptDtoBase,
  MassUpdateReceiptsDtoExclude,
  MassUpdateReceiptsDtoInclude,
  Receipt,
} from "shared/interfaces/Receipt.interface";
import { format } from "date-fns";
import { usePopper } from "react-popper";
import {
  BanIcon,
  DotsVerticalIcon,
  ExclamationIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/solid";
import { selectLoggedInUser } from "store/authStore/authReducer";
import { PermissionDomain } from "shared/interfaces/Permission.interface";
import { RolePermissionCrudAction } from "shared/interfaces/RolePermission.interface";
import Modal from "shared/components/UI/Modal";
import { BsFilePdf } from "react-icons/bs";
import { Popover } from "@headlessui/react";
import saveAs from "file-saver";
import { generateReceiptPdf } from "pages/Receipts/generateReceiptPdf";
import { generateReceiptsPdfs } from "pages/Receipts/generateReceiptsPdfs";

type Receipts = {
  data: EnchancedReceipt[];
  pageIndex: number;
  total: number;
  message: string;
};

type ReceiptsFilters = Partial<{
  include: string;
  receipt_year: Date;
  receipt_date: Date;
  progressive_id: string;
  user_id: {
    label: string;
    value: User;
  };
  creator: {
    label: string;
    value: User;
  };
  status: {
    label: string;
    value: string;
  };
  printing: string;
  pageSize: number;
  pageIndex: number;
  sortCol: string;
  sortOrder: "ASC" | "DESC" | "";
  searchString: string;
}>;

const Receipts = () => {
  const lang = selectTranslationLanguage();
  const { t } = useTranslation("common");
  const loggedInUser = selectLoggedInUser();
  const { id } = useParams();

  function canAccess(
    domain: PermissionDomain,
    action: RolePermissionCrudAction,
    receipt?: Receipt
  ): boolean {
    if (loggedInUser.permissions["*"]) {
      return true;
    } else {
      switch (loggedInUser.permissions[domain][action]) {
        case "NONE": {
          return false;
        }
        case "OWN": {
          if (action === "CREATE") return true;
          if (receipt && receipt.creator === loggedInUser.user?.PK_USER)
            return true;
          return false;
        }
        case "ANY": {
          return true;
        }
        default: {
          return false;
        }
      }
    }
  }
  const [updateStatusOpen, setUpdateStatusOpen] = useState(false);
  const [allSelected, setAllSelected] = useState(false);
  const [excludeReceipts, setExcludeReceipts] = useState<string[]>([]);
  const [includeReceipts, setIncludeReceipts] = useState<string[]>([]);
  const [exportLoading, setExportLoading] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const referenceReceipt = useRef<Receipt | null>(null);
  const [referenceElement, setReferenceElement] = useState<any>(null);
  const [popperElement, setPopperElement] = useState<any>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    strategy: "fixed",
    placement: "bottom-start",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [10, 10],
        },
      },
    ],
  });
  const [loading, setLoading] = useState(false);
  const [receipts, setReceipts] = useState<Receipts>({
    data: [],
    pageIndex: 0,
    total: 0,
    message: "",
  });
  function getReceiptsHelper(pageIndex: number) {
    return getReceipts({
      include: form.getValues("include"),
      receipt_year: form.getValues("receipt_year")
        ? format(form.getValues("receipt_year") as Date, "yyyy")
        : "",
      receipt_date: form.getValues("receipt_date")
        ? format(form.getValues("receipt_date") as Date, "yyyy-MM-dd")
        : "",
      progressive_id: form.getValues("progressive_id"),
      user_id: id,
      creator: form.getValues("creator")?.value.PK_USER,
      status: form.getValues("status")?.value,
      pageIndex: pageIndex,
      pageSize: form.getValues("pageSize"),
      sortCol: sortBy[0] ? sortBy[0].id : "",
      sortOrder: sortBy[0] ? (sortBy[0].desc ? "DESC" : "ASC") : "",
    })
      .then((res) => setReceipts(res.data))
      .catch((err: any) => {
        if (err.response?.data?.errors?.[0]?.message) {
          toast.error(err.response?.data?.errors?.[0]?.message);
        } else {
          toast.error(err.response?.data?.message);
        }
      })
      .finally(() => setLoading(false));
  }
  function massQueryBuilder(status?: "draft" | "final") {
    let builder:
      | MassUpdateReceiptDtoBase
      | MassUpdateReceiptsDtoInclude
      | MassUpdateReceiptsDtoExclude
      | MassExportReceiptsDto
      | MassExportReceiptsDtoInclude
      | MassExportReceiptsDtoExclude = {
      filters: {
        //   progressive_id: form.getValues("progressive_id"),
        user_id: id,
        //   creator: form.getValues("creator")?.value.PK_USER,
      },
    };
    if (status) {
      (builder as MassUpdateReceiptDtoBase).values = { status };
    }
    if (allSelected) {
      builder = {
        ...builder,
        exclude: excludeReceipts,
      };
    } else {
      builder = {
        ...builder,
        include: includeReceipts,
      };
    }
    return builder;
  }
  function updateReceiptsHelper(status: "draft" | "final") {
    const buildQuery = massQueryBuilder(status) as any;
    return updateReceipts(buildQuery).then(() => getReceiptsHelper(0));
  }
  useEffect(() => {
    getReceiptsHelper(0);
  }, []);

  const form = useForm<ReceiptsFilters>({
    mode: "onChange",
    defaultValues: {
      receipt_year: new Date(),
      pageSize: 10,
      include: "details,address",
    },
  });

  const _billingUserOptions = (input: string, callback: any) => {
    if (input === "") {
      return callback([]);
    } else {
      return getUsers({
        pageSize: 10,
        searchString: input,
      })
        .then((res) => {
          callback(
            res.data.users.map((user: User) => ({
              label: `${user.NAME} ${user.SURNAME}`,
              value: user,
            }))
          );
        })
        .catch((err: any) => {
          if (err.response?.data?.errors?.[0]?.message) {
            toast.error(err.response?.data?.errors?.[0]?.message);
          } else {
            toast.error(err.response?.data?.message);
          }
          callback([]);
        });
    }
  };
  const billingUserOptions = useCallback(
    debounce(_billingUserOptions, 400),
    []
  );

  const _createdFromOptions = (input: string, callback: any) => {
    if (input === "") {
      return callback([]);
    } else {
      return getUsers({
        pageSize: 10,
        searchString: input,
      })
        .then((res) => {
          callback(
            res.data.users.map((user: User) => ({
              label: `${user.NAME} ${user.SURNAME}`,
              value: user,
            }))
          );
        })
        .catch((err: any) => {
          if (err.response?.data?.errors?.[0]?.message) {
            toast.error(err.response?.data?.errors?.[0]?.message);
          } else {
            toast.error(err.response?.data?.message);
          }
          callback([]);
        });
    }
  };
  const createdFromOptions = useCallback(
    debounce(_createdFromOptions, 400),
    []
  );

  const columns: any = useMemo(
    () =>
      receiptsColumns(
        {
          allSelected,
          setAllSelected,
          includeReceipts,
          setIncludeReceipts,
          excludeReceipts,
          setExcludeReceipts,
        },
        lang
      ),
    [
      lang,
      allSelected,
      setAllSelected,
      includeReceipts,
      setIncludeReceipts,
      excludeReceipts,
      setExcludeReceipts,
    ]
  );
  const data: any = useMemo(() => {
    return receipts.data.map((receipt, i) => ({
      col1: (
        <div className="flex items-center h-full">
          <input
            type="checkbox"
            checked={(function () {
              if (allSelected) {
                const index = excludeReceipts.indexOf(receipt.id);
                if (index === -1) {
                  return true;
                } else {
                  return false;
                }
              } else {
                const index = includeReceipts.indexOf(receipt.id);
                if (index === -1) {
                  return false;
                } else {
                  return true;
                }
              }
            })()}
            onClick={() => {
              if (allSelected) {
                const index = excludeReceipts.indexOf(receipt.id);
                if (index !== -1) {
                  setExcludeReceipts((excludedReceipts) => {
                    const temp = [...excludedReceipts];
                    temp.splice(index, 1);
                    return temp;
                  });
                } else {
                  setExcludeReceipts((excludedReceipts) => [
                    ...excludedReceipts,
                    receipt.id,
                  ]);
                }
              } else {
                const index = includeReceipts.indexOf(receipt.id);
                if (index !== -1) {
                  setIncludeReceipts((includedReceipts) => {
                    const temp = [...includedReceipts];
                    temp.splice(index, 1);
                    return temp;
                  });
                } else {
                  setIncludeReceipts((includedReceipts) => [
                    ...includedReceipts,
                    receipt.id,
                  ]);
                }
              }
            }}
            className="text-primary"
          />
        </div>
      ),
      col2: (
        <div className="flex items-center h-full">
          <span className="truncate">{receipt.receipt_year}</span>
        </div>
      ),
      col3: (
        <div className="flex items-center h-full">
          <span className="truncate">
            {format(new Date(receipt.receipt_date.slice(0, -1)), "dd-MM-yyyy")}
          </span>
        </div>
      ),
      col4: (
        <div className="flex items-center h-full">
          <span className="truncate">{receipt.progressive_id}</span>
        </div>
      ),
      col5: (
        <div className="flex items-center h-full">
          <span className="truncate">{receipt.user_id}</span>
        </div>
      ),
      col6: (
        <div className="flex items-center h-full">
          <span className="truncate">{receipt.total_amount}</span>
        </div>
      ),
      col7: (
        <div className="flex items-center h-full">
          <span className="truncate">{receipt.creator}</span>
        </div>
      ),
      col8: (
        <div className="flex items-center h-full">
          <span className="truncate">
            {format(new Date(receipt.updated_at.slice(0, -1)), "dd-MM-yyyy")}
          </span>
        </div>
      ),
      col9: (
        <div className="flex items-center h-full">
          <span className="truncate">{receipt.last_modifier}</span>
        </div>
      ),
      col10: (
        <div className="flex items-center h-full">
          <span className="truncate">{receipt.status}</span>
        </div>
      ),
      col11: (
        <div className="flex items-center h-full">
          <button
            type="button"
            onClick={(e) => {
              referenceReceipt.current = receipt;
              setReferenceElement(e.target);
            }}
          >
            <DotsVerticalIcon className="w-5 h-5" />
          </button>
        </div>
      ),
    }));
  }, [
    receipts.data,
    allSelected,
    setAllSelected,
    excludeReceipts,
    setExcludeReceipts,
    includeReceipts,
    setIncludeReceipts,
    lang,
  ]);
  const table = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: form.getValues("pageSize") },
      pageCount: Math.ceil(
        receipts.total / (form.getValues("pageSize") as number)
      ),
      manualSortBy: true,
      manualPagination: true,
    },
    useSortBy,
    usePagination,
    useFlexLayout,
    useRowSelect
  );
  const {
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, sortBy, selectedRowIds },
  } = table;
  const dispatchNextPage = () => {
    if (canNextPage) {
      getReceiptsHelper(pageIndex + 1).then(() => {
        nextPage();
      });
    }
  };
  const dispatchPreviousPage = () => {
    if (canPreviousPage) {
      getReceiptsHelper(pageIndex - 1).then(() => {
        previousPage();
      });
    }
  };
  const dispatchGotoPage = (pageIndex: number) => {
    getReceiptsHelper(pageIndex).then(() => {
      gotoPage(pageIndex);
    });
  };

  useEffect(() => {
    if (form.formState.isDirty) {
      getReceiptsHelper(0);
    }
  }, [
    sortBy,
    form.watch("receipt_year"),
    form.watch("receipt_date"),
    form.watch("progressive_id"),
    form.watch("user_id"),
    form.watch("creator"),
    form.watch("status"),
  ]);

  useEffect(() => {
    const outsideClickListener = (event: any) => {
      if (
        referenceElement &&
        referenceElement != event.target &&
        !popperElement.contains(event.target)
      ) {
        setReferenceElement(null);
      }
    };
    document.addEventListener("click", outsideClickListener);
    return () => {
      if (outsideClickListener) {
        document.removeEventListener("click", outsideClickListener);
      }
    };
  }, [popperElement, referenceElement]);

  function showExtraButtons() {
    if (allSelected && excludeReceipts.length < receipts.total) {
      return true;
    }
    if (!allSelected && includeReceipts.length) {
      return true;
    }
    return false;
  }

  return (
    <div className="flex flex-col gap-5 h-full">
      <div className="flex flex-wrap gap-5 justify-between">
        <div className="flex flex-wrap gap-5 justify-start">
          {showExtraButtons() && (
            <div>
              <button
                type="button"
                disabled={exportLoading}
                className="px-4 py-2 text-white bg-primary border border-primary rounded-sm"
                onClick={() => {
                  setExportLoading(true);
                  const exportReceiptsPromise = massExportReceipts(
                    massQueryBuilder()
                  )
                    .then((res) => generateReceiptsPdfs(res.data.receipts))
                    .catch((err: any) => {
                      if (err.response?.data?.errors?.[0]?.message) {
                        toast.error(err.response?.data?.errors?.[0]?.message);
                      } else {
                        toast.error(err.response?.data?.message);
                      }
                    })
                    .finally(() => setExportLoading(false));
                  toast.promise(exportReceiptsPromise, {
                    pending: t("receipt.exporting"),
                    success: t("receipt.exportFinished"),
                    error: StrMessage({
                      action: "read",
                      entity: t("receipt.receipts"),
                      error: t("genericError"),
                      lang,
                    }),
                  });
                }}
              >
                {t("receipts.export")}
              </button>
            </div>
          )}
          {showExtraButtons() && (
            <Popover className="relative">
              <Popover.Button>
                <button
                  type="button"
                  className="px-4 py-2 text-white bg-primary border border-primary rounded-sm"
                  onClick={() => setUpdateStatusOpen((state) => !state)}
                >
                  {t("receipts.updateStatus")}
                </button>
              </Popover.Button>
              <Popover.Panel
                className="absolute left-0 top-0 flex flex-col gap-2 mt-16 p-2 w-full bg-white rounded-sm shadow-md"
                style={{ zIndex: 9990 }}
              >
                <button
                  onClick={() => {
                    updateReceiptsHelper("draft");
                  }}
                >
                  {t("receipts.draft")}
                </button>
                <button
                  onClick={() => {
                    updateReceiptsHelper("final");
                  }}
                >
                  {t("receipts.final")}
                </button>
              </Popover.Panel>
            </Popover>
          )}
        </div>
        <div className="flex flex-grow justify-end">
          <Link
            to={`/receipts/create?from=${id}`}
            className="block px-4 py-2 text-white bg-primary border border-primary rounded-sm"
          >
            {t("receipts.createReceipt")}
          </Link>
        </div>
      </div>
      <Table {...(table as any)} />
      <Pagination
        rowsCount={receipts.total}
        pageIndex={pageIndex}
        pageSize={form.getValues("pageSize") as number}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        pageOptions={pageOptions}
        pageCount={pageCount}
        gotoPage={dispatchGotoPage}
        nextPage={dispatchNextPage}
        previousPage={dispatchPreviousPage}
        setPageSize={setPageSize}
      />
      {referenceElement && (
        <div
          ref={setPopperElement}
          style={{
            ...styles.popper,
          }}
          className="flex flex-col gap-2 p-2 bg-white shadow-md"
          {...attributes.popper}
        >
          {(function () {
            if (!referenceReceipt.current) return null;
            const canRead = canAccess("s", "READ", referenceReceipt.current);
            const canUpdate = canAccess(
              "s",
              "UPDATE",
              referenceReceipt.current
            );
            const canDelete = canAccess(
              "s",
              "DELETE",
              referenceReceipt.current
            );
            if (!canRead && !canUpdate && !canDelete) {
              return (
                <div className="flex items-center h-full">
                  <BanIcon className="w-5 h-5" />
                </div>
              );
            }
            return (
              <>
                {canRead ? (
                  <Link
                    to={`/receipts/${referenceReceipt.current?.id}?from=${id}`}
                    className="flex gap-2 items-center text-table-edit"
                  >
                    <PencilIcon className="w-5 h-5" />
                    {canAccess("s", "UPDATE", referenceReceipt.current)
                      ? t("crud.update")
                      : t("crud.read")}
                  </Link>
                ) : null}
                {canDelete ? (
                  <button
                    onClick={() => setOpenDeleteModal(true)}
                    className="flex gap-2 items-center text-users-delete"
                  >
                    <TrashIcon className="w-5 h-5" />
                    {t("crud.delete")}
                  </button>
                ) : null}
                {canRead ? (
                  <button
                    disabled={exportLoading}
                    onClick={() => {
                      if (!referenceReceipt.current) return;
                      setExportLoading(true);
                      const exportReceiptPromise = getReceipt(
                        referenceReceipt.current.id
                      )
                        .then((res) => generateReceiptPdf(res.data.receipt))
                        .catch((err: any) => {
                          if (err.response?.data?.errors?.[0]?.message) {
                            toast.error(
                              err.response?.data?.errors?.[0]?.message
                            );
                          } else {
                            toast.error(err.response?.data?.message);
                          }
                        })
                        .finally(() => setExportLoading(false));
                      toast.promise(exportReceiptPromise, {
                        pending: t("receipt.exporting"),
                        success: t("receipt.exportFinished"),
                        error: StrMessage({
                          action: "read",
                          entity: t("receipt.receipts"),
                          error: t("genericError"),
                          lang,
                        }),
                      });
                    }}
                    style={{ color: "#8f130e" }}
                    className="flex gap-2 items-center"
                  >
                    <BsFilePdf className="w-5 h-5" />
                    {lang === "en" ? "Export" : "Esportare"}
                  </button>
                ) : null}
              </>
            );
          })()}
        </div>
      )}
      <Modal
        openStatus={openDeleteModal}
        setOpen={setOpenDeleteModal}
        icon={
          <ExclamationIcon
            className="w-6 h-6 text-red-600 bg-transparent"
            aria-hidden="true"
          />
        }
        header={<p>{t("deleteModal.delete")} receipt</p>}
        title={
          <p>
            {t("deleteModal.sure")} {t("receipts.receipt")} ?{" "}
            {t("deleteModal.undone")}
          </p>
        }
        footer={
          <div className="flex justify-end mt-5 sm:mt-4">
            <button
              type="button"
              className="inline-flex justify-center mt-3 px-4 py-1 w-full text-black text-base font-medium bg-white border border-black focus:outline-none shadow-sm focus:ring-0 sm:mt-0 sm:w-auto"
              onClick={() => setOpenDeleteModal(false)}
            >
              {t("deleteModal.cancel")}
            </button>
            <button
              type="button"
              className="inline-flex justify-center px-4 py-1 w-full text-white text-base font-medium bg-red-600 border border-transparent focus:outline-none shadow-sm focus:ring-0 sm:ml-3 sm:w-auto"
              onClick={() => {
                if (!referenceReceipt.current) return;
                setLoading(true);
                deleteReceipt(referenceReceipt.current.id)
                  .then(() => getReceiptsHelper(0))
                  .catch(
                    (err: any) => {
                      if (err.response?.data?.errors?.[0]?.message) {
                        toast.error(err.response?.data?.errors?.[0]?.message);
                      } else {
                        toast.error(err.response?.data?.message);
                      }
                    }
                    // toast.error(
                    //   Message({
                    //     action: "delete",
                    //     entity: t("receipts.receipt"),
                    //     error: t("genericError"),
                    //     lang,
                    //   })
                    // )
                  )
                  .finally(() => {
                    setLoading(false);
                    setOpenDeleteModal(false);
                  });
              }}
            >
              {t("deleteModal.delete")}
            </button>
          </div>
        }
      />
    </div>
  );
};

export default Receipts;
