import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  UseFormClearErrors,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import AsyncSelect from "react-select/async";
import {
  CreateLocation,
  PlaceDetailsType,
} from "shared/interfaces/Location.interface";
import {
  placeAutocompleteThunk,
  placeDetailsThunk,
} from "store/locationStore/locationReducer";
import { getReactSelectStyles } from "utils/utils";
import DisplayInputError from "./DisplayInputError";
import Map from "./Map";
import { LatLngExpression } from "leaflet";
import { locationActions } from "store/globalStore";
import { useAppDispatch } from "store/storeHooks";
import { debounce } from "lodash";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import Message from "./Message";
import { selectTranslationLanguage } from "store/translationStore/translationReducer";

export function FormatLocation({ location }: { location?: CreateLocation }) {
  if (location) {
    const address = Object.entries(location)
      .filter(([, value]) => !!value)
      .map(([, value]) => value);
    return <address>{address.join(", ")}</address>;
  }
  return null;
}

export default function LocationSearch({
  setValue,
  clearErrors,
  register,
  watch,
  errors,
  types,
  getValues,
  displayMap = true,
  displayDetails = true,
  required = true,
  reactHookFormName,
  isUserAddress = false,
  displayStar = false,
  isExamAddress = false,
}: {
  setValue: UseFormSetValue<any>;
  clearErrors: UseFormClearErrors<any>;
  register: UseFormRegister<any>;
  watch: UseFormWatch<any>;
  errors: any;
  types?: string;
  getValues: UseFormGetValues<any>;
  displayMap?: boolean;
  displayDetails?: boolean;
  required?: boolean;
  reactHookFormName: string;
  isUserAddress?: boolean;
  displayStar?: boolean;
  isExamAddress?: boolean;
}) {
  const lang = selectTranslationLanguage();
  const { t } = useTranslation("common");
  const dispatch = useAppDispatch();
  const [placeDetails, setPlaceDetails] = useState("");
  const [placeDetailsResult, setPlaceDetailsResult] = useState();

  useEffect(() => {
    if (placeDetails) {
      dispatch(placeDetailsThunk({ placeid: placeDetails })).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          setPlaceDetailsResult(res.payload);
        }
      });
    }
  }, [placeDetails]);
  useEffect(() => {
    return () => {
      dispatch(locationActions.clearPlaceAutocomplete());
      dispatch(locationActions.clearPlaceDetails());
    };
  }, []);

  useEffect(() => {
    if (isUserAddress) {
      if (required) {
        register(`${reactHookFormName}.lon`, {
          required: true,
        });
        register(`${reactHookFormName}.lat`, {
          required: true,
        });
      }
    } else if (isExamAddress) {
      register(`${reactHookFormName}.city`, {
        required: true,
      });
      register(`${reactHookFormName}.province`, {
        required: true,
      });
      register(`${reactHookFormName}.region`, {
        required: true,
      });
      register(`${reactHookFormName}.country`, {
        required: true,
      });
      register(`${reactHookFormName}.longitude`, {
        required: true,
      });
      register(`${reactHookFormName}.latitude`, {
        required: true,
      });
    } else {
      if (required) {
        register(`${reactHookFormName}.street_name`, {
          required: true,
        });
        register(`${reactHookFormName}.street_number`, {
          required: true,
        });
        register(`${reactHookFormName}.postal_code`, {
          required: true,
        });
        register(`${reactHookFormName}.city`, {
          required: true,
        });
        register(`${reactHookFormName}.province`, {
          required: true,
        });
        register(`${reactHookFormName}.region`, {
          required: true,
        });
        register(`${reactHookFormName}.country`, {
          required: true,
        });
      }
      register(`${reactHookFormName}.longitude`, {
        required: true,
      });
      register(`${reactHookFormName}.latitude`, {
        required: true,
      });
    }
  }, []);

  useEffect(() => {
    return () => {
      dispatch(locationActions.clearPlaceAutocomplete());
      dispatch(locationActions.clearPlaceDetails());
    };
  }, []);

  useEffect(() => {
    if (placeDetailsResult) {
      const addressDetails = isUserAddress
        ? extractUserAddressDetails(placeDetailsResult)
        : extractAddressDetails(placeDetailsResult);
      Object.entries(addressDetails).forEach(([key, value]) => {
        if (value) {
          if (isUserAddress && (key === "region" || key === "province")) {
            setValue(`${reactHookFormName}.${key}`, { value, label: value });
            clearErrors([`${reactHookFormName}.${key}`]);
          } else {
            setValue(`${reactHookFormName}.${key}`, value);
            clearErrors([`${reactHookFormName}.${key}`]);
          }
        } else {
          if (isUserAddress && (key === "region" || key === "province")) {
            setValue(`${reactHookFormName}.${key}`, { value, label: value });
          } else {
            setValue(`${reactHookFormName}.${key}`, undefined);
          }
        }
      });
    }
  }, [placeDetailsResult]);

  const locationDetails = watch(`${reactHookFormName}`);
  let position: LatLngExpression;
  if (isUserAddress) {
    position = useMemo(
      () => [locationDetails.lat, locationDetails.lon],
      [locationDetails.lat, locationDetails.lon]
    );
  } else {
    position = useMemo(
      () => [locationDetails.latitude, locationDetails.longitude],
      [locationDetails.latitude, locationDetails.longitude]
    );
  }

  const _loadOptions = (e: string, callback: any): any => {
    if (e === "") return callback();
    dispatch(
      placeAutocompleteThunk({
        input: e,
        types,
      })
    ).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        callback(
          res.payload?.predictions.map((location: any) => {
            return {
              value: location.place_id,
              label: String(location.description),
            };
          })
        );
      } else {
        toast.error(
          Message({
            entity: "location",
            action: "read",
            error: t("genericError"),
            lang,
          })
        );
        callback();
      }
    });
  };
  const loadOptions = useCallback(debounce(_loadOptions, 400), []);

  return (
    <div className="flex flex-col gap-2">
      <label htmlFor={reactHookFormName} className="block text-lg font-medium">
        {t("location.search")}
        {displayStar ? " *" : ""}
      </label>
      <AsyncSelect
        inputId={reactHookFormName}
        isMulti={false}
        placeholder={t("location.search")}
        styles={getReactSelectStyles(!!errors[reactHookFormName])}
        defaultOptions
        cacheOptions
        loadOptions={loadOptions}
        onChange={(e: any) => {
          setPlaceDetails(e.value);
        }}
      />
      {displayDetails ? <FormatLocation location={locationDetails} /> : null}
      <DisplayInputError
        message={errors[reactHookFormName] && t("location.error")}
      />
      {displayMap ? (
        <div className="h-60">
          <Map position={position} />
        </div>
      ) : null}
    </div>
  );
}

function extractUserAddressDetails(placeDetails: PlaceDetailsType) {
  const addressDetails: any = {
    homeNumber: undefined,
    roadName: "",
    city: "",
    province: "",
    region: "",
    country: "",
    postalCode: undefined,
    lon: "",
    lat: "",
  };
  placeDetails.result.address_components.forEach((c) => {
    switch (c.types[0]) {
      case "street_number": {
        addressDetails.homeNumber = Number(c.long_name);
        break;
      }
      case "route": {
        addressDetails.roadName = c.long_name;
        break;
      }
      case "administrative_area_level_3": {
        addressDetails.city = c.long_name;
        break;
      }
      case "administrative_area_level_2": {
        // const province = c.long_name.split(" ");
        // addressDetails.province = province[province.length - 1];
        addressDetails.province = c.short_name;
        break;
      }
      case "administrative_area_level_1": {
        addressDetails.region = c.long_name;
        break;
      }
      case "country": {
        addressDetails.country = c.long_name;
        break;
      }
      case "postal_code": {
        addressDetails.postalCode = Number(c.long_name);
        break;
      }
      default:
        break;
    }
  });
  addressDetails.lon = placeDetails.result.geometry.location.lng;
  addressDetails.lat = placeDetails.result.geometry.location.lat;
  return addressDetails;
}

function extractAddressDetails(placeDetails: PlaceDetailsType): CreateLocation {
  const addressDetails: CreateLocation = {
    street_number: undefined,
    street_name: "",
    city: "",
    province: "",
    region: "",
    country: "",
    postal_code: undefined,
  } as CreateLocation;
  placeDetails.result.address_components.forEach((c) => {
    switch (c.types[0]) {
      case "street_number": {
        addressDetails.street_number = Number(c.long_name);
        break;
      }
      case "route": {
        addressDetails.street_name = c.long_name;
        break;
      }
      case "administrative_area_level_3": {
        addressDetails.city = c.long_name;
        break;
      }
      case "administrative_area_level_2": {
        // addressDetails.province = c.long_name;
        addressDetails.province = c.short_name;
        break;
      }
      case "administrative_area_level_1": {
        addressDetails.region = c.long_name;
        break;
      }
      case "country": {
        addressDetails.country = c.long_name;
        break;
      }
      case "postal_code": {
        addressDetails.postal_code = Number(c.long_name);
        break;
      }
      default:
        break;
    }
  });
  addressDetails.longitude = placeDetails.result.geometry.location.lng;
  addressDetails.latitude = placeDetails.result.geometry.location.lat;
  return addressDetails;
}
