import axios from "axios";
import {
  CreatePresidentialRank,
  UpdatePresidentialRank,
} from "shared/interfaces/Rank.interface";
import {
  createPresidentialRankURL,
  deletePresidentialRankURL,
  getPresidentialRanksURL,
  getPresidentialRankURL,
  updatePresidentialRankURL,
} from "./urls";

export function createPresidentialRank({
  data,
}: {
  data: CreatePresidentialRank;
}) {
  return axios.post(createPresidentialRankURL(), data);
}

export function getPresidentialRanks(filters: {
  pageSize?: number;
  pageIndex?: number;
  sortCol?: string;
  sortOrder?: string;
}) {
  return axios.get(getPresidentialRanksURL(filters));
}

export function deletePresidentialRank({ rankId }: { rankId: string }) {
  return axios.delete(deletePresidentialRankURL({ rankId }));
}

export function getPresidentialRank({ rankId }: { rankId: string }) {
  return axios.get(getPresidentialRankURL({ rankId }));
}

export function updatePresidentialRank({
  rankId,
  data,
}: {
  rankId: string;
  data: UpdatePresidentialRank;
}) {
  return axios.put(updatePresidentialRankURL({ rankId }), data);
}
