import { CMS_BE_URL } from "api/urls";

export type GetProductFilters = {
  tags?: string;
  categories?: string;
  include?: string;
  searchString?: string;
};

export const createProductURL = `${CMS_BE_URL}/s/products`;

export const constructGetProductsURL = (filters: GetProductFilters) => {
  return `${CMS_BE_URL}/s/products?${Object.entries(filters)
    .map(([key, value]) => `${key}=${value}`)
    .join("&")}`;
};

export const constructGetProductURL = (productId: string) =>
  `${CMS_BE_URL}/s/products/${productId}`;
//?include=tags,categories

export const constructUpdateProductURL = (productId: string) =>
  `${CMS_BE_URL}/s/products/${productId}`;

export const constructDeleteProductURL = (productId: string) =>
  `${CMS_BE_URL}/s/products/${productId}`;
