import React, { useEffect, useMemo } from "react";
import "./App.scss";
import { Routes, Route, useNavigate } from "react-router-dom";
import Sidebar from "./shared/components/Dashboard/Sidebar";
import Home from "./pages/Home/Home";
import News from "./pages/News/News";
import Events from "./pages/Events/Events";
import Users from "./pages/Users/Users";
import Exams from "./pages/Exams/Exams";
import Sponsors from "./pages/Sponsors/Sponsors";
import Products from "./pages/Products/Products";
import Documents from "./pages/Documents/Documents";
import LogIn from "pages/Auth/Login";
import CreateUserPage from "pages/Users/CreateUser";
import EditUser from "pages/Users/EditUser";
import { useAppDispatch, useAppSelector } from "store/storeHooks";
import "./App.scss";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import CreateDocument from "pages/Documents/CreateDocument";
import EditDocument from "pages/Documents/EditDocument";
import PermissionsCheck from "shared/components/PermissionsCheck";
import { getLookupsThunk } from "store/lookups/lookupsReducer";
import Roles from "pages/Roles/Roles";
import CreateRole from "pages/Roles/CreateRole";
import EditRole from "pages/Roles/EditRole";
import ForgotPassword from "pages/Auth/ForgotPassword";
import CheckEmail from "pages/Auth/CheckEmail";
import SetNewPassword from "pages/Auth/SetNewPassword";
import Certificates from "pages/Certificates/Certificates";
import CreateCertificate from "pages/Certificates/CreateCertificate";
import CreateSponsor from "pages/Sponsors/CreateSponsor";
import EditSponsor from "pages/Sponsors/EditSponsor";
import EditCertificate from "pages/Certificates/EditCertificate";
import CreateEventPage from "pages/Events/CreateEvent";
import EditEventPage from "pages/Events/EditEvent";
import CreateNew from "pages/News/CreateNew";
import EditNew from "pages/News/EditNew";
import CreateExam from "pages/Exams/CreateExam";
import EditExam from "pages/Exams/EditExam";
import CreateNewsletter from "pages/Newsletter/CreateNewsletter";
import ViewNewsletter from "pages/Newsletter/ViewNewsletter";
import Newsletter from "pages/Newsletter/Newsletter";
import MassUpdate from "pages/MassUpdate/MassUpdate";
import {
  getUserPermissionsThunk,
  selectLoggedInUser,
} from "store/authStore/authReducer";
import { authActions, translationActions } from "store/globalStore";
import ExportData from "pages/ExportData/Exportdata";
import AboutUs from "pages/Councils/AboutUs";
import RegionalCouncil from "pages/Councils/RegionalCouncil";
import ContactUs from "pages/ContactUs/ContactUs";
import FieldsDomain from "pages/FieldsDomain/FieldsDomain";
import { includes, reduce } from "ramda";
import Message, { Lang } from "shared/components/UI/Message";
import { selectTranslationLanguage } from "store/translationStore/translationReducer";
import CreateProduct from "pages/Products/CreateProduct";
import EditProduct from "pages/Products/EditProduct";
import { getLanguageFromLocalStorage } from "utils/utils";
import Orders from "pages/Orders/Orders";
import EditOrder from "pages/Orders/EditOrder";
import CreateOrder from "pages/Orders/CreateOrder";
import Plans from "pages/Plans/Plans";
import Coupons from "pages/Coupons/coupons";
import CreateCoupon from "pages/Coupons/createCoupon";
import EditCoupon from "pages/Coupons/editCoupon";
import Press from "pages/Press/Press";
import CreatePress from "pages/Press/CreatePress";
import EditPress from "pages/Press/EditPress";
import Receipts from "pages/Receipts/Receipts";
import CreateReceipt from "pages/Receipts/CreateReceipt";
import UpdateReceipt from "pages/Receipts/UpdateReceipt";
import EditNewsletter from "pages/Newsletter/EditNewsletter";
import NewsletterDashboard from "pages/Newsletter/NewsletterDashboard";

function App(): JSX.Element {
  const [t, i18n] = useTranslation("common");
  const lang = selectTranslationLanguage();
  const language = useAppSelector((state) => state.translation.language);
  const navigate = useNavigate();

  const permissions = useAppSelector((state) => state.auth.permissions);
  const loggedInUser = selectLoggedInUser();

  const dispatch = useAppDispatch();

  useEffect(() => {
    const lang = getLanguageFromLocalStorage();
    if (lang) {
      i18n.changeLanguage(lang);
      dispatch(translationActions.changeLanguage({ language: lang as Lang }));
    } else {
      i18n.changeLanguage(language);
      dispatch(translationActions.changeLanguage({ language }));
    }
  }, [language]);

  useEffect(() => {
    let remember: any = localStorage.getItem("remember");
    let user;
    if (remember && JSON.parse(remember)) {
      user = localStorage.getItem("user");
    } else if (remember && !JSON.parse(remember)) {
      user = sessionStorage.getItem("user");
    }
    if (user) user = JSON.parse(user);
    if (remember) remember = JSON.parse(remember);

    if (user && !loggedInUser.user) {
      dispatch(getLookupsThunk()).then((res) => {
        if (res.meta.requestStatus === "rejected") {
          toast.error(
            Message({
              action: "read",
              entity: t("lookups.lookups"),
              error: t("genericError"),
              lang,
            })
          );
        }
      });
      dispatch(authActions.setUser({ user }));
      dispatch(getUserPermissionsThunk({ userId: user.PK_USER })).then(
        (res) => {
          if (res.meta.requestStatus === "rejected") {
            toast.error(
              Message({
                action: "read",
                entity: t("login.permissions"),
                error: t("genericError"),
                lang,
              })
            );
          }
        }
      );
      dispatch(authActions.setRemember({ remember }));
    } else {
      navigate("/");
    }
  }, []);

  const canAccess = useMemo(
    () =>
      (...args: any[]) => {
        const haveAccess = (acc: any) => includes(acc, ["NONE", false]);
        return reduce(
          (acc, cur) => {
            if (haveAccess(acc)) {
              return "NONE";
            } else if (acc === "OWN") {
              if (haveAccess(acc)) return "NONE";
              else acc;
            } else {
              if (cur === "NONE" || cur === false) return "NONE";
              else if (cur === "OWN") return cur;
              else return "ANY";
            }
          },
          "ANY",
          args
        );
      },
    []
  );

  function checkPermissions() {
    if (!loggedInUser.permissions) return true;
    if (
      !loggedInUser.permissions["*"] &&
      !loggedInUser.permissions.accessCMS?.ENABLED
    )
      return true;
    else Object.keys(loggedInUser.permissions).length <= 0;
  }

  return (
    <>
      {loggedInUser.loading ? null : !loggedInUser ||
        !loggedInUser.user ||
        checkPermissions() ? (
        <Routes>
          <Route path="/" element={<LogIn />} />
          <Route path="/forgotPassword" element={<ForgotPassword />} />
          <Route path="/checkEmail" element={<CheckEmail />} />
          <Route path="/setNewPassword" element={<SetNewPassword />} />
        </Routes>
      ) : (
        <div
          style={{
            maxWidth: "100vw",
          }}
          className="relative flex w-screen h-full min-h-screen overflow-hidden"
        >
          <Sidebar />
          <div className="flex flex-grow overflow-x-auto">
            {/* <div className="flex flex-col flex-grow"> */}
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/products/create" element={<CreateProduct />} />
              <Route path="/products/create/:id" element={<EditProduct />} />
              <Route path="/products/:id" element={<EditProduct />} />
              <Route
                path="news"
                element={
                  <PermissionsCheck
                    destination="/"
                    permissions={
                      permissions["*"]
                        ? permissions["*"].READ
                        : canAccess(
                            permissions.news.ENABLED,
                            permissions.news.READ
                          )
                    }
                  >
                    <News />
                  </PermissionsCheck>
                }
              />
              <Route
                path="massupdate"
                element={
                  // <PermissionsCheck
                  //   destination="/"
                  //   permissions={
                  //     permissions["*"]
                  //       ? permissions["*"].READ
                  //       : canAccess(
                  //           permissions.news.ENABLED,
                  //           permissions.news.READ
                  //         )
                  //   }
                  // >
                  <MassUpdate />
                  // </PermissionsCheck>
                }
              />

              <Route
                path="news/create"
                element={
                  <PermissionsCheck
                    destination="/"
                    permissions={
                      permissions["*"]
                        ? permissions["*"].CREATE
                        : canAccess(
                            permissions.news.ENABLED,
                            permissions.news.CREATE
                          )
                    }
                  >
                    <CreateNew />
                  </PermissionsCheck>
                }
              />
              <Route
                path="news/:id"
                element={
                  <PermissionsCheck
                    destination="/"
                    permissions={
                      permissions["*"]
                        ? permissions["*"].READ
                        : canAccess(
                            permissions.news.ENABLED,
                            permissions.news.READ
                          )
                    }
                  >
                    <EditNew />
                  </PermissionsCheck>
                }
              />
              <Route
                path="events"
                element={
                  <PermissionsCheck
                    destination="/"
                    permissions={
                      permissions["*"]
                        ? permissions["*"].READ
                        : canAccess(
                            permissions.events.ENABLED,
                            permissions.events.READ
                          )
                    }
                  >
                    <Events />
                  </PermissionsCheck>
                }
              />
              <Route
                path="events/create"
                element={
                  <PermissionsCheck
                    destination="/"
                    permissions={
                      permissions["*"]
                        ? permissions["*"].CREATE
                        : canAccess(
                            permissions.events.ENABLED,
                            permissions.events.CREATE
                          )
                    }
                  >
                    <CreateEventPage />
                  </PermissionsCheck>
                }
              />
              <Route
                path="events/:id"
                element={
                  <PermissionsCheck
                    destination="/"
                    permissions={
                      permissions["*"]
                        ? permissions["*"].READ
                        : canAccess(
                            permissions.events.ENABLED,
                            permissions.events.READ
                          )
                    }
                  >
                    <EditEventPage />
                  </PermissionsCheck>
                }
              />
              <Route
                path="exams"
                element={
                  <PermissionsCheck
                    destination="/"
                    permissions={
                      permissions["*"]
                        ? permissions["*"].READ
                        : canAccess(
                            permissions.exams.ENABLED,
                            permissions.exams.READ
                          )
                    }
                  >
                    <Exams />
                  </PermissionsCheck>
                }
              />
              <Route
                path="exams/create"
                element={
                  <PermissionsCheck
                    destination="/"
                    permissions={
                      permissions["*"]
                        ? permissions["*"].CREATE
                        : canAccess(
                            permissions.exams.ENABLED,
                            permissions.exams.CREATE
                          )
                    }
                  >
                    <CreateExam />
                  </PermissionsCheck>
                }
              />
              <Route
                path="exams/:id"
                element={
                  <PermissionsCheck
                    destination="/"
                    permissions={
                      permissions["*"]
                        ? permissions["*"].READ
                        : canAccess(
                            permissions.exams.ENABLED,
                            permissions.exams.READ
                          )
                    }
                  >
                    <EditExam />
                  </PermissionsCheck>
                }
              />
              <Route
                path="users"
                element={
                  <PermissionsCheck
                    destination="/"
                    permissions={
                      permissions["*"]
                        ? permissions["*"].READ
                        : canAccess(
                            permissions.users.ENABLED,
                            permissions.users.READ
                          )
                    }
                  >
                    <Users />
                  </PermissionsCheck>
                }
              />
              <Route
                path="users/create"
                element={
                  <PermissionsCheck
                    destination="/"
                    permissions={
                      permissions["*"]
                        ? permissions["*"].CREATE
                        : canAccess(
                            permissions.users.ENABLED,
                            permissions.users.CREATE
                          )
                    }
                  >
                    <CreateUserPage />
                  </PermissionsCheck>
                }
              />
              <Route
                path="users/:id"
                element={
                  <PermissionsCheck
                    destination="/"
                    permissions={
                      permissions["*"]
                        ? permissions["*"].READ
                        : canAccess(
                            permissions.users.ENABLED,
                            permissions.users.READ
                          )
                    }
                  >
                    <EditUser />
                  </PermissionsCheck>
                }
              />
              <Route
                path="documents"
                element={
                  <PermissionsCheck
                    destination="/"
                    permissions={
                      permissions["*"]
                        ? permissions["*"].READ
                        : canAccess(
                            permissions.documents.ENABLED,
                            permissions.documents.READ
                          )
                    }
                  >
                    <Documents />
                  </PermissionsCheck>
                }
              />
              <Route
                path="documents/create"
                element={
                  <PermissionsCheck
                    destination="/"
                    permissions={
                      permissions["*"]
                        ? permissions["*"].CREATE
                        : canAccess(
                            permissions.documents.ENABLED,
                            permissions.documents.CREATE
                          )
                    }
                  >
                    <CreateDocument />
                  </PermissionsCheck>
                }
              />
              <Route
                path="documents/:id"
                element={
                  <PermissionsCheck
                    destination="/"
                    permissions={
                      permissions["*"]
                        ? permissions["*"].READ
                        : permissions.administrative.ENABLED &&
                          canAccess(
                            permissions.documents.ENABLED,
                            permissions.documents.READ
                          )
                    }
                  >
                    <EditDocument />
                  </PermissionsCheck>
                }
              />
              <Route
                path="newsletter"
                element={
                  <PermissionsCheck
                    destination="/"
                    permissions={
                      permissions["*"]
                        ? permissions["*"].READ
                        : canAccess(
                            permissions.newsletter.ENABLED,
                            permissions.newsletter.READ
                          )
                    }
                  >
                    <Newsletter />
                  </PermissionsCheck>
                }
              />
              <Route
                path="newsletter/create"
                element={
                  <PermissionsCheck
                    destination="/"
                    permissions={
                      permissions["*"]
                        ? permissions["*"].CREATE
                        : canAccess(
                            permissions.newsletter.ENABLED,
                            permissions.newsletter.CREATE
                          )
                    }
                  >
                    <CreateNewsletter />
                  </PermissionsCheck>
                }
              />
              <Route
                path="newsletter/edit/:id"
                element={
                  <PermissionsCheck
                    destination="/"
                    permissions={
                      permissions["*"]
                        ? permissions["*"].CREATE
                        : canAccess(
                            permissions.newsletter.ENABLED,
                            permissions.newsletter.UPDATE
                          )
                    }
                  >
                    <EditNewsletter />
                  </PermissionsCheck>
                }
              />
              <Route
                path="newsletter/dashboard/:id"
                element={
                  <PermissionsCheck
                    destination="/"
                    permissions={
                      permissions["*"]
                        ? permissions["*"].CREATE
                        : canAccess(
                            permissions.newsletter.ENABLED,
                            permissions.newsletter.READ
                          )
                    }
                  >
                    <NewsletterDashboard />
                  </PermissionsCheck>
                }
              />
              <Route
                path="newsletter/:id"
                element={
                  <PermissionsCheck
                    destination="/"
                    permissions={
                      permissions["*"]
                        ? permissions["*"].READ
                        : canAccess(
                            permissions.newsletter.ENABLED,
                            permissions.newsletter.READ
                          )
                    }
                  >
                    <ViewNewsletter />
                  </PermissionsCheck>
                }
              />
              <Route
                path="certificates"
                element={
                  <PermissionsCheck
                    destination="/"
                    permissions={
                      permissions["*"]
                        ? permissions["*"].READ
                        : canAccess(
                            permissions.certificates.READ,
                            permissions.administrative.ENABLED,
                            permissions.certificates.ENABLED
                          )
                    }
                  >
                    <Certificates />
                  </PermissionsCheck>
                }
              />
              <Route
                path="certificates/create"
                element={
                  <PermissionsCheck
                    destination="/"
                    permissions={
                      permissions["*"]
                        ? permissions["*"].CREATE
                        : canAccess(
                            permissions.certificates.CREATE,
                            permissions.administrative.ENABLED,
                            permissions.certificates.ENABLED
                          )
                    }
                  >
                    <CreateCertificate />
                  </PermissionsCheck>
                }
              />
              <Route
                path="certificates/:id"
                element={
                  <PermissionsCheck
                    destination="/"
                    permissions={
                      permissions["*"]
                        ? permissions["*"].READ
                        : canAccess(
                            permissions.certificates.READ,
                            permissions.administrative.ENABLED,
                            permissions.certificates.ENABLED
                          )
                    }
                  >
                    <EditCertificate />
                  </PermissionsCheck>
                }
              />
              <Route
                path="products"
                element={
                  // <PermissionsCheck
                  //   destination="/"
                  //   permissions={
                  //     permissions["*"]
                  //       ? permissions["*"].READ
                  //       : canAccess(
                  //           permissions.products.READ,
                  //           permissions.administrative.ENABLED,
                  //           permissions.products.ENABLED
                  //         )
                  //   }
                  // >
                  <Products />
                  // </PermissionsCheck>
                }
              />
              <Route
                path="plans"
                element={
                  // <PermissionsCheck
                  //   destination="/"
                  //   permissions={
                  //     permissions["*"]
                  //       ? permissions["*"].READ
                  //       : canAccess(
                  //           permissions.products.READ,
                  //           permissions.administrative.ENABLED,
                  //           permissions.products.ENABLED
                  //         )
                  //   }
                  // >
                  <Plans />
                  // </PermissionsCheck>
                }
              />
              <Route
                path="about-us"
                element={
                  <PermissionsCheck
                    destination="/"
                    permissions={
                      permissions["*"]
                        ? permissions["*"].READ
                        : canAccess(
                            permissions.councils.READ ||
                              permissions.aboutus.READ,
                            permissions.administrative.ENABLED,
                            permissions.councils.ENABLED ||
                              permissions.aboutus.ENABLED
                          )
                    }
                  >
                    <AboutUs />
                  </PermissionsCheck>
                }
              />
              <Route
                path="about-us/:region"
                element={
                  <PermissionsCheck
                    destination="/"
                    permissions={
                      permissions["*"]
                        ? permissions["*"].READ
                        : canAccess(
                            permissions.councils.READ,
                            permissions.administrative.ENABLED,
                            permissions.councils.ENABLED
                          )
                    }
                  >
                    <RegionalCouncil />
                  </PermissionsCheck>
                }
              />
              <Route
                path="/contact-us"
                element={
                  <PermissionsCheck
                    destination="/"
                    permissions={
                      permissions["*"]
                        ? permissions["*"].READ
                        : canAccess(
                            permissions.contactus.READ,
                            permissions.administrative.ENABLED,
                            permissions.contactus.ENABLED
                          )
                    }
                  >
                    <ContactUs />
                  </PermissionsCheck>
                }
              />
              <Route
                path="roles"
                element={
                  <PermissionsCheck
                    destination="/"
                    permissions={
                      permissions["*"]
                        ? permissions["*"].READ
                        : canAccess(
                            permissions.permissionsManagement.READ,
                            permissions.administrative.ENABLED,
                            permissions.permissionsManagement.ENABLED
                          )
                    }
                  >
                    <Roles />
                  </PermissionsCheck>
                }
              />
              <Route
                path="roles/create"
                element={
                  <PermissionsCheck
                    destination="/"
                    permissions={
                      permissions["*"]
                        ? permissions["*"].CREATE
                        : canAccess(
                            permissions.permissionsManagement.CREATE,
                            permissions.administrative.ENABLED,
                            permissions.permissionsManagement.ENABLED
                          )
                    }
                  >
                    <CreateRole />
                  </PermissionsCheck>
                }
              />
              <Route
                path="roles/:roleName"
                element={
                  <PermissionsCheck
                    destination="/"
                    permissions={
                      permissions["*"]
                        ? permissions["*"].READ
                        : canAccess(
                            permissions.permissionsManagement.READ,
                            permissions.administrative.ENABLED,
                            permissions.permissionsManagement.ENABLED
                          )
                    }
                  >
                    <EditRole />
                  </PermissionsCheck>
                }
              />
              <Route
                path="fieldsDomain"
                element={
                  <PermissionsCheck
                    destination="/"
                    permissions={
                      permissions["*"]
                        ? permissions["*"].READ
                        : canAccess(
                            permissions.fieldsDomain.READ,
                            permissions.administrative.ENABLED,
                            permissions.fieldsDomain.ENABLED
                          )
                    }
                  >
                    <FieldsDomain />
                  </PermissionsCheck>
                }
              />
              <Route
                path="sponsors"
                element={
                  <PermissionsCheck
                    destination="/"
                    permissions={
                      permissions["*"]
                        ? permissions["*"].READ
                        : canAccess(
                            permissions.administrative.ENABLED,
                            permissions.sponsors.READ,
                            permissions.sponsors.ENABLED
                          )
                    }
                  >
                    <Sponsors />
                  </PermissionsCheck>
                }
              />
              <Route
                path="sponsors/create"
                element={
                  <PermissionsCheck
                    destination="/"
                    permissions={
                      permissions["*"]
                        ? permissions["*"].CREATE
                        : canAccess(
                            permissions.sponsors.CREATE,
                            permissions.administrative.ENABLED,
                            permissions.sponsors.ENABLED
                          )
                    }
                  >
                    <CreateSponsor />
                  </PermissionsCheck>
                }
              />
              <Route
                path="sponsors/:id"
                element={
                  <PermissionsCheck
                    destination="/"
                    permissions={
                      permissions["*"]
                        ? permissions["*"].CREATE
                        : canAccess(
                            permissions.sponsors.ENABLED,
                            permissions.administrative.ENABLED,
                            permissions.sponsors.ENABLED
                          )
                    }
                  >
                    <EditSponsor />
                  </PermissionsCheck>
                }
              />
              <Route
                path="orders"
                element={
                  <PermissionsCheck
                    destination="/"
                    permissions={
                      permissions["*"]
                        ? permissions["*"].READ
                        : canAccess(
                            permissions.orders.ENABLED,
                            permissions.orders.READ
                          )
                    }
                  >
                    <Orders />
                  </PermissionsCheck>
                }
              />
              <Route
                path="orders/create"
                element={
                  <PermissionsCheck
                    destination="/"
                    permissions={
                      permissions["*"]
                        ? permissions["*"].READ
                        : canAccess(
                            permissions.orders.ENABLED,
                            permissions.orders.CREATE
                          )
                    }
                  >
                    <CreateOrder />
                  </PermissionsCheck>
                }
              />
              <Route
                path="exportData"
                element={
                  <PermissionsCheck
                    destination="/"
                    permissions={
                      permissions["*"]
                        ? permissions["*"].READ
                        : canAccess(
                            permissions.exportData.ENABLED,
                            permissions.administrative.ENABLED,
                            permissions.exportData.ENABLED
                          )
                    }
                  >
                    <ExportData />
                  </PermissionsCheck>
                }
              />
              <Route
                path="orders/:id"
                element={
                  <PermissionsCheck
                    destination="/"
                    permissions={
                      permissions["*"]
                        ? permissions["*"].READ
                        : canAccess(
                            permissions.orders.ENABLED,
                            permissions.orders.UPDATE
                          )
                    }
                  >
                    <EditOrder />
                  </PermissionsCheck>
                }
              />
              <Route
                path="coupons"
                element={
                  <PermissionsCheck
                    destination="/"
                    permissions={
                      permissions["*"]
                        ? permissions["*"].READ
                        : canAccess(
                            permissions.coupons.ENABLED,
                            permissions.coupons.READ
                          )
                    }
                  >
                    <Coupons />
                  </PermissionsCheck>
                }
              />
              <Route
                path="coupons/create"
                element={
                  <PermissionsCheck
                    destination="/"
                    permissions={
                      permissions["*"]
                        ? permissions["*"].CREATE
                        : canAccess(
                            permissions.coupons.ENABLED,
                            permissions.coupons.CREATE
                          )
                    }
                  >
                    <CreateCoupon />
                  </PermissionsCheck>
                }
              />
              <Route
                path="coupons/:id"
                element={
                  <PermissionsCheck
                    destination="/"
                    permissions={
                      permissions["*"]
                        ? permissions["*"].UPDATE
                        : canAccess(
                            permissions.coupons.ENABLED,
                            permissions.coupons.UPDATE
                          )
                    }
                  >
                    <EditCoupon />
                  </PermissionsCheck>
                }
              />
              <Route
                path="press"
                element={
                  <PermissionsCheck
                    destination="/"
                    permissions={
                      permissions["*"]
                        ? permissions["*"].READ
                        : canAccess(
                            permissions.press.ENABLED,
                            permissions.press.READ
                          )
                    }
                  >
                    <Press />
                  </PermissionsCheck>
                }
              />
              <Route
                path="press/create"
                element={
                  <PermissionsCheck
                    destination="/"
                    permissions={
                      permissions["*"]
                        ? permissions["*"].CREATE
                        : canAccess(
                            permissions.press.ENABLED,
                            permissions.press.CREATE
                          )
                    }
                  >
                    <CreatePress />
                  </PermissionsCheck>
                }
              />
              <Route
                path="press/:id"
                element={
                  <PermissionsCheck
                    destination="/"
                    permissions={
                      permissions["*"]
                        ? permissions["*"].UPDATE
                        : canAccess(
                            permissions.press.ENABLED,
                            permissions.press.UPDATE
                          )
                    }
                  >
                    <EditPress />
                  </PermissionsCheck>
                }
              />
              <Route
                path="receipts"
                element={
                  <PermissionsCheck
                    destination="/"
                    permissions={
                      permissions["*"]
                        ? permissions["*"].READ
                        : canAccess(
                            permissions.receipts.ENABLED,
                            permissions.receipts.READ
                          )
                    }
                  >
                    <Receipts />
                  </PermissionsCheck>
                }
              />
              <Route
                path="receipts/create"
                element={
                  <PermissionsCheck
                    destination="/"
                    permissions={
                      permissions["*"]
                        ? permissions["*"].CREATE
                        : canAccess(
                            permissions.receipts.ENABLED,
                            permissions.receipts.CREATE
                          )
                    }
                  >
                    <CreateReceipt />
                  </PermissionsCheck>
                }
              />
              <Route
                path="receipts/:id"
                element={
                  <PermissionsCheck
                    destination="/"
                    permissions={
                      permissions["*"]
                        ? permissions["*"].UPDATE
                        : canAccess(
                            permissions.receipts.ENABLED,
                            permissions.receipts.UPDATE
                          )
                    }
                  >
                    <UpdateReceipt />
                  </PermissionsCheck>
                }
              />
            </Routes>
            {/* <div className="flex justify-end w-full text-white text-2xs font-thin bg-sidebar-bg">
              <span className="px-4 whitespace-nowrap">version 1.0.0</span>
              <span className="pr-4 whitespace-nowrap">
                &copy; ANMB - Associazione Nationale Maestri di Ballo
              </span>
            </div> */}
          </div>
          {/* </div> */}
        </div>
      )}
      <ToastContainer />
    </>
  );
}

export default App;
