import Loader from "react-loader-spinner";
import React from "react";

const Spinner: () => JSX.Element = () => {
  return (
    <div className="flex justify-center items-center">
      <Loader type="TailSpin" color="#111727" height={150} width={150} />
    </div>
  );
};

export default Spinner;
