import React, { useEffect, useState, useMemo } from "react";
import { useAppDispatch } from "store/storeHooks";
import {
  Column,
  useFlexLayout,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { SearchIcon, PlusIcon } from "@heroicons/react/solid";
import {
  DotsVerticalIcon,
  ExclamationIcon,
  EyeIcon,
  EyeOffIcon,
  FilterIcon,
  PencilIcon,
  TrashIcon,
  XIcon,
  BanIcon,
} from "@heroicons/react/outline";
import { Link } from "react-router-dom";
import { useDebounce } from "shared/hooks/useDebounce";
import Pagination from "shared/components/Table/Pagination";
import Table from "shared/components/Table/Table";
import Modal from "shared/components/UI/Modal";
import { useTranslation } from "react-i18next";
import { selectLoggedInUser } from "store/authStore/authReducer";
import {
  deleteEventThunk,
  getEventsThunk,
  selectEvents,
  updateEventThunk,
} from "store/eventsStore/eventsReducer";
import { CreateEvent, Event } from "shared/interfaces/Event.interface";
import { format } from "date-fns";
import Spinner from "shared/components/UI/Spinner";
import Overlay from "shared/components/UI/Overlay";
import {
  deleteEvents,
  getEventsColumns,
  updateEvents,
} from "api/Events/events.api";
import { Controller, useForm } from "react-hook-form";
import { Popover } from "@headlessui/react";
import Select from "react-select";
import {
  getVisibilityFilterOptions,
  visibilityFilterOptions,
} from "shared/interfaces/Visibility.interface";
import { getTagsThunk, selectTags } from "store/tagsStore/tagsReducer";
import { selectLookups } from "store/lookups/lookupsReducer";
import TablePopup, { PortalType } from "shared/components/UI/TablePopup";
import {
  dateToQuery,
  getInputStyles,
  getReactSelectStyles,
  regionToQuery,
  tagsToQuery,
} from "utils/utils";
import { EventsFiltersType } from "./types";
import { toast } from "react-toastify";
import Message from "shared/components/UI/Message";
import { PermissionDomain } from "shared/interfaces/Permission.interface";
import { RolePermissionCrudAction } from "shared/interfaces/RolePermission.interface";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "shared/styles/datePicker.css";
import { selectTranslationLanguage } from "store/translationStore/translationReducer";

const EventsPage: () => JSX.Element = () => {
  const lang = selectTranslationLanguage();
  const { t } = useTranslation("common");
  const {
    register,
    setValue,
    getValues,
    formState: { errors, isDirty },
    control,
    watch,
  } = useForm<EventsFiltersType>({
    defaultValues: {
      from: undefined,
      to: undefined,
      tags: [],
      region: undefined,
      visible: { value: "all", label: lang === "en" ? "All " : "Tutti " },
      pageSize: 10,
      pageIndex: 0,
      searchString: "",
    },
  });
  const debouncedSearchText = useDebounce(watch("searchString"), 300);
  const events = selectEvents();
  const [currentPageSize, setCurrentPageSize] = useState(10);
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [tagsOptions, setTagsOptions] = useState<any>([]);
  const [regionsOptions, setRegionsOptions] = useState<any>([]);
  const dispatch = useAppDispatch();
  const tags = selectTags();
  const lookups = selectLookups();
  const [openModal, setOpenModal] = useState(false);
  const [eventToDelete, setEventToDelete] = useState("");
  const [updateLoading, setUpdateLoading] = useState(false);
  const [allSelected, setAllSelected] = useState(false);
  const [excludeEvents, setExcludeEvents] = useState<string[]>([]);
  const [includeEvents, setIncludeEvents] = useState<string[]>([]);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const handleDeleteModal = () => {
    setOpenDeleteModal(true);
  };

  const [portal, setPortal] = useState<PortalType<Event>>({
    open: false,
    top: 0,
    left: 0,
    entity: null,
  });

  const handleModal = (eventToDelete: string) => {
    setEventToDelete(eventToDelete);
    setOpenModal(true);
  };

  function deleteEventsHelper() {
    setUpdateLoading(true);
    if (allSelected) {
      deleteEvents({
        filters: constructGetEventsThunkAction(0),
        exclude: excludeEvents,
      })
        .catch(
          (err: any) => {
            if (err.response?.data?.errors?.[0]?.message) {
              toast.error(err.response?.data?.errors?.[0]?.message);
            } else {
              toast.error(err.response?.data?.message);
            }
          }
          // toast.error(
          //   Message({
          //     action: "delete",
          //     entity: t("events.event"),
          //     error: t("genericError"),
          //     lang,
          //   })
          // )
        )
        .then((res: any) => {
          if (res["request"].status === 200) {
            toast.success(
              Message({
                action: "delete",
                entity: t("events.events"),
                lang,
              })
            );
          }
        })
        .then(() => getEvents(0))
        .finally(() => {
          setUpdateLoading(false);
        });
    } else {
      deleteEvents({
        filters: constructGetEventsThunkAction(0),
        include: includeEvents,
      })
        .catch(
          (err: any) => {
            if (err.response?.data?.errors?.[0]?.message) {
              toast.error(err.response?.data?.errors?.[0]?.message);
            } else {
              toast.error(err.response?.data?.message);
            }
          }
          // toast.error(
          //   Message({
          //     action: "delete",
          //     entity: t("events.event"),
          //     error: t("genericError"),
          //     lang,
          //   })
          // )
        )
        .then((res: any) => {
          if (res["request"].status === 200) {
            toast.success(
              Message({
                action: "delete",
                entity: t("event.events"),
                lang,
              })
            );
          }
        })
        .then(() => getEvents(0))
        .finally(() => {
          setUpdateLoading(false);
        });
    }
    setAllSelected(false);
    setIncludeEvents([]);
    setExcludeEvents([]);

    setOpenDeleteModal(false);
  }

  function updateEventsHelper(visible: boolean) {
    setUpdateLoading(true);
    if (allSelected) {
      updateEvents({
        filters: constructGetEventsThunkAction(0),
        values: {
          visible,
        },
        exclude: excludeEvents,
      })
        .catch(
          (err: any) => {
            if (err.response?.data?.errors?.[0]?.message) {
              toast.error(err.response?.data?.errors?.[0]?.message);
            } else {
              toast.error(err.response?.data?.message);
            }
          }
          // toast.error(
          //   Message({
          //     action: "update",
          //     entity: "Events",
          //     error: t("genericError"),
          //     lang,
          //   })
          // )
        )
        .then((res: any) => {
          if (res["request"].status === 200) {
            toast.success(
              Message({
                action: "update",
                entity: t("event.events"),
                lang,
              })
            );
          }
        })
        .then(() => getEvents(0))
        .finally(() => {
          setUpdateLoading(false);
        });
    } else {
      updateEvents({
        filters: constructGetEventsThunkAction(0),
        values: {
          visible,
        },
        include: includeEvents,
      })
        .catch(
          (err: any) => {
            if (err.response?.data?.errors?.[0]?.message) {
              toast.error(err.response?.data?.errors?.[0]?.message);
            } else {
              toast.error(err.response?.data?.message);
            }
          }
          // toast.error(
          //   Message({
          //     action: "update",
          //     entity: "Events",
          //     error: t("genericError"),
          //     lang,
          //   })
          // )
        )
        .then((res: any) => {
          if (res["request"].status === 200) {
            toast.success(
              Message({
                action: "update",
                entity: t("event.events"),
                lang,
              })
            );
          }
        })
        .then(() => getEvents(0))
        .finally(() => {
          setUpdateLoading(false);
        });
    }
    setAllSelected(false);
    setIncludeEvents([]);
    setExcludeEvents([]);
  }

  const loggedInUser = selectLoggedInUser();
  function canAccess(
    domain: PermissionDomain,
    action: RolePermissionCrudAction,
    event?: Event
  ): boolean {
    if (loggedInUser.permissions["*"]) {
      return true;
    } else {
      switch (loggedInUser.permissions[domain][action]) {
        case "NONE": {
          return false;
        }
        case "OWN": {
          if (action === "CREATE") return true;
          if (event && event.creator_id === loggedInUser.user?.PK_USER)
            return true;
          return false;
        }
        case "ANY": {
          return true;
        }
        default: {
          return false;
        }
      }
    }
  }

  function toggleVisibility(event: Event): CreateEvent {
    const temp: CreateEvent = {
      is_visible: event.is_visible ? "false" : "true",
    };
    return temp;
  }

  function portalContent() {
    if (portal.entity === null) return;
    const canRead = canAccess("events", "READ", portal.entity);
    const canUpdate = canAccess("events", "UPDATE", portal.entity);
    const canDelete = canAccess("events", "DELETE", portal.entity);
    return (
      <div
        className="fixed z-50 flex flex-col gap-2 p-2 bg-white rounded-sm shadow-md"
        style={{
          width: "150px",
          top: portal.top,
          left: portal.left,
        }}
      >
        {canRead ? (
          <Link
            to={`/events/${portal.entity.event_id}`}
            className="flex gap-2 items-center text-table-edit"
          >
            <PencilIcon className="w-5 h-5" />
            {canUpdate ? t("crud.update") : t("crud.read")}
          </Link>
        ) : null}
        {canDelete ? (
          <button
            onClick={() => handleModal(String(portal.entity?.event_id))}
            className="flex gap-2 items-center text-users-delete"
          >
            <TrashIcon className="w-5 h-5" />
            {t("crud.delete")}
          </button>
        ) : null}
        {canUpdate ? (
          <button
            className="flex gap-2 items-center text-users-edit"
            onClick={() => {
              dispatch(
                updateEventThunk({
                  eventId: String(portal.entity?.event_id),
                  event: toggleVisibility(portal.entity as Event),
                })
              )
                .then((res) => {
                  if (res.meta.requestStatus === "rejected") {
                    toast.error(
                      Message({
                        action: "update",
                        entity: t("events.event"),
                        error: t("genericError"),
                        lang,
                        gender: "male",
                      })
                    );
                  } else {
                    toast.success(
                      Message({
                        action: "update",
                        entity: t("events.event"),
                        lang,
                        gender: "male",
                      })
                    );
                  }
                  return res;
                })
                .then(() => getEvents(0));
            }}
          >
            {portal.entity.is_visible ? (
              <EyeOffIcon className="w-5 h-5" />
            ) : (
              <EyeIcon className="w-5 h-5" />
            )}
            {portal.entity.is_visible
              ? t("events.invisible")
              : t("events.visible")}
          </button>
        ) : null}
      </div>
    );
  }

  const columns: Array<Column<Record<string, any>>> = useMemo(
    () =>
      getEventsColumns(
        {
          allSelected,
          setAllSelected,
          includeEvents,
          setIncludeEvents,
          excludeEvents,
          setExcludeEvents,
        },
        lang
      ),
    [
      lang,
      allSelected,
      setAllSelected,
      includeEvents,
      setIncludeEvents,
      excludeEvents,
      setExcludeEvents,
    ]
  );

  function displayActions(event: Event, i: number) {
    const canRead = canAccess("events", "READ", event);
    const canUpdate = canAccess("events", "UPDATE", event);
    const canDelete = canAccess("events", "DELETE", event);
    if (!canRead && !canUpdate && !canDelete) {
      return (
        <div className="flex items-center h-full">
          <BanIcon className="w-5 h-5" />
        </div>
      );
    }
    return (
      <div className="relative flex items-center h-full">
        <button
          id={`portal-button-${i}`}
          type="button"
          onClick={() => {
            return setPortal((state) => {
              const thisButton = document.getElementById(`portal-button-${i}`);
              const position = thisButton?.getBoundingClientRect();
              return {
                open: !state.open,
                top: Number(position?.bottom),
                left: Number(
                  window.innerWidth - Number(position?.x) <= 150
                    ? Number(position?.left) - 150
                    : Number(position?.left)
                ),
                entity: event,
              };
            });
          }}
        >
          <DotsVerticalIcon className="w-5 h-5" />
        </button>
      </div>
    );
  }

  const data: Array<any> = useMemo(() => {
    return events.data.data.map((event, i) => {
      return {
        checkbox: (
          <div className="flex items-center h-full">
            <input
              type="checkbox"
              checked={(function () {
                if (allSelected) {
                  const index = excludeEvents.indexOf(event.event_id);
                  if (index === -1) {
                    return true;
                  } else {
                    return false;
                  }
                } else {
                  const index = includeEvents.indexOf(event.event_id);
                  if (index === -1) {
                    return false;
                  } else {
                    return true;
                  }
                }
              })()}
              onClick={() => {
                if (allSelected) {
                  const index = excludeEvents.indexOf(event.event_id);
                  if (index !== -1) {
                    setExcludeEvents((excludedEvents) => {
                      const temp = [...excludedEvents];
                      temp.splice(index, 1);
                      return temp;
                    });
                  } else {
                    setExcludeEvents((excludedEvents) => [
                      ...excludedEvents,
                      event.event_id,
                    ]);
                  }
                } else {
                  const index = includeEvents.indexOf(event.event_id);
                  if (index !== -1) {
                    setIncludeEvents((includedEvents) => {
                      const temp = [...includedEvents];
                      temp.splice(index, 1);
                      return temp;
                    });
                  } else {
                    setIncludeEvents((includedEvents) => [
                      ...includedEvents,
                      event.event_id,
                    ]);
                  }
                }
              }}
              className="text-primary"
            />
          </div>
        ),
        col1: (
          <div className="flex flex-col gap-2">
            <div className="truncate">{event.title}</div>
            <div>
              {event.tags.length === 0 ? (
                t("events.noTags")
              ) : (
                <div className="flex flex-nowrap gap-2">
                  {event.tags.map((tag, i) => (
                    <span
                      key={i}
                      className="p-1 text-white bg-events-tags rounded-sm truncate"
                    >
                      {tag.pk_tag}
                    </span>
                  ))}
                </div>
              )}
            </div>
          </div>
        ),
        col2: (
          <div className="flex items-center h-full truncate">
            {event.location?.region}
          </div>
        ),
        col3: (
          <div className="flex items-center h-full truncate">
            {event.start_date
              ? format(
                  new Date(event.start_date.slice(0, -1)),
                  "dd MMMM yyyy, hh:mm:ss aaa"
                )
              : null}
          </div>
        ),
        col4: (
          <div className="flex items-center h-full truncate">
            {event.end_date
              ? format(
                  new Date(event.end_date.slice(0, -1)),
                  "dd MMMM yyyy, hh:mm:ss aaa"
                )
              : null}
          </div>
        ),
        col5: (
          <div className="flex items-center h-full truncate">
            <span
              className={`rounded-full px-2 py-1 capitalize ${
                event.is_visible
                  ? "text-users-active bg-users-active-bg"
                  : "text-users-inactive bg-users-inactive-bg"
              }`}
            >
              {event.is_visible ? t("events.visible") : t("events.invisible")}
            </span>
          </div>
        ),
        col6: displayActions(event, i),
      };
    });
  }, [
    events.data,
    lang,
    allSelected,
    setAllSelected,
    includeEvents,
    setIncludeEvents,
    excludeEvents,
    setExcludeEvents,
  ]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: currentPageSize },
      pageCount: Math.ceil(events.data.total / currentPageSize),
      manualSortBy: true,
      manualPagination: true,
    },
    useSortBy,
    usePagination,
    useFlexLayout
  );

  const {
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, sortBy },
  } = tableInstance;

  useEffect(() => {
    dispatch(getTagsThunk()).then((res) => {
      if (res.meta.requestStatus === "rejected") {
        toast.error(
          Message({
            action: "read",
            entity: t("event.tags"),
            error: t("genericError"),
            lang,
            gender: "female",
          })
        );
      }
      return res;
    });
  }, []);
  useEffect(() => {
    const tagsMap = tags.data.tags.map((tag) => {
      return {
        value: tag.pk_tag,
        label: tag.pk_tag,
      };
    });
    setTagsOptions(tagsMap);
  }, [tags]);

  useEffect(() => {
    const regionsMap = lookups.lookupList.regions.map((region) => ({
      value: region.PK_REGION,
      label: region.PK_REGION,
    }));
    setRegionsOptions(regionsMap);
  }, [lookups.lookupList.regions]);

  function constructGetEventsThunkAction(pageIndex: number): any {
    return {
      from: dateToQuery(getValues("from")),
      to: dateToQuery(getValues("to")),
      region: regionToQuery(getValues("region")),
      tags: tagsToQuery(getValues("tags")),
      visible: getValues("visible").value,
      pageIndex: pageIndex,
      pageSize: getValues("pageSize"),
      searchString: debouncedSearchText,
      sortCol: sortBy[0] ? sortBy[0].id : "",
      sortOrder: sortBy[0] ? (sortBy[0].desc ? "DESC" : "ASC") : "",
    };
  }

  function getEvents(pageIndex: number) {
    return dispatch(
      getEventsThunk(constructGetEventsThunkAction(pageIndex))
    ).then((res) =>
      res.meta.requestStatus === "rejected"
        ? (toast.error(
            Message({
              action: "read",
              entity: t("events.events"),
              error: t("genericError"),
              lang,
              gender: "male",
            })
          ),
          res)
        : res
    );
  }

  useEffect(() => {
    getEvents(0);
  }, [debouncedSearchText, sortBy]);

  useEffect(() => {
    if (isDirty) getEvents(0);
  }, [
    watch("tags"),
    watch("region"),
    watch("visible"),
    watch("from"),
    watch("to"),
  ]);

  const handleDeleteEvent = (certificateToDelete: string) => {
    dispatch(
      deleteEventThunk({
        eventId: certificateToDelete,
      })
    )
      .then((res) => {
        if (res.meta.requestStatus === "rejected") {
          toast.error(
            Message({
              action: "delete",
              entity: t("event.event"),
              error: t("genericError"),
              lang,
              gender: "male",
            })
          );
        } else {
          toast.success(
            Message({
              action: "delete",
              entity: t("events.event"),
              lang,
              gender: "male",
            })
          );
        }
        return res;
      })
      .then(() => getEvents(0));
    setOpenModal(false);
  };

  const dispatchNextPage = () =>
    canNextPage
      ? getEvents(pageIndex + 1).then((res) =>
          res.meta.requestStatus === "fulfilled" ? nextPage() : undefined
        )
      : undefined;

  const dispatchPreviousPage = () =>
    canPreviousPage
      ? getEvents(pageIndex - 1).then((res) =>
          res.meta.requestStatus === "fulfilled" ? previousPage() : undefined
        )
      : undefined;

  const dispatchGotoPage = (pageIndex: number) =>
    getEvents(pageIndex).then((res) =>
      res.meta.requestStatus === "fulfilled" ? gotoPage(pageIndex) : undefined
    );

  function showExtraButtons() {
    if (allSelected && excludeEvents.length < events.data.total) {
      return true;
    }
    if (!allSelected && includeEvents.length) {
      return true;
    }
    return false;
  }

  return (
    <Overlay active={events.loading || updateLoading} spinner={<Spinner />}>
      <div className="flex flex-col gap-5 p-10 h-full">
        <div className="text-3xl font-bold">{t("events.events")}</div>
        <div className="flex flex-col gap-5">
          {filtersOpen ? (
            <div className="flex flex-wrap gap-5">
              <div className="w-72">
                <Controller
                  name="from"
                  control={control}
                  render={({ field }) => {
                    return (
                      <div>
                        <DatePicker
                          id="from"
                          selected={field.value}
                          onChange={(e) => field.onChange(e)}
                          showTimeSelect={true}
                          dateFormat="dd/MM/yyyy"
                          className={getInputStyles(false)}
                          placeholderText={t("events.dateFormat")}
                          showPopperArrow={false}
                          autoComplete="off"
                        />
                      </div>
                    );
                  }}
                />
              </div>
              <div className="w-72">
                <Controller
                  name="to"
                  control={control}
                  render={({ field }) => {
                    return (
                      <div>
                        <DatePicker
                          id="to"
                          selected={field.value}
                          onChange={(e) => field.onChange(e)}
                          showTimeSelect={true}
                          dateFormat="dd/MM/yyyy"
                          className={getInputStyles(false)}
                          placeholderText={t("events.dateFormat")}
                          showPopperArrow={false}
                          autoComplete="off"
                        />
                      </div>
                    );
                  }}
                />
              </div>
              <div className="w-72">
                <Controller
                  name="tags"
                  control={control}
                  render={({ field }) => {
                    return (
                      <Select
                        placeholder={t("events.tags")}
                        styles={getReactSelectStyles(false)}
                        isLoading={tags.loading}
                        inputId="tags"
                        isMulti={true}
                        options={tagsOptions}
                        value={field.value}
                        onChange={(e) => field.onChange(e)}
                        closeMenuOnSelect={false}
                        hideSelectedOptions={true}
                        controlShouldRenderValue={false}
                      />
                    );
                  }}
                />
              </div>
              <div className="w-72">
                <Controller
                  name="region"
                  control={control}
                  render={({ field }) => (
                    <Select
                      placeholder={t("events.region")}
                      styles={getReactSelectStyles(false)}
                      isLoading={lookups.loading}
                      inputId="region"
                      isClearable={true}
                      value={field.value}
                      options={regionsOptions}
                      onChange={(e) => field.onChange(e)}
                    />
                  )}
                />
              </div>
              <div className="w-72">
                <Controller
                  name="visible"
                  control={control}
                  render={({ field }) => (
                    <Select
                      placeholder={t("events.status")}
                      styles={getReactSelectStyles(false)}
                      inputId="visible"
                      options={getVisibilityFilterOptions(lang)}
                      value={field.value}
                      onChange={(e) => field.onChange(e)}
                      isSearchable={false}
                    />
                  )}
                />
              </div>
              <div>
                <button
                  type="button"
                  className="px-4 py-2 text-white bg-primary border border-primary"
                  onClick={() => {
                    setValue("from", null as any);
                    setValue("to", null as any);
                    setValue("tags", []);
                    setValue("region", null as any);
                    setValue("visible", { label: "All", value: "all" });
                  }}
                >
                  {t("events.clearAll")}
                </button>
              </div>
            </div>
          ) : null}
          <div className="flex flex-grow flex-wrap gap-5">
            {showExtraButtons() && canAccess("s", "UPDATE") ? (
              <Popover className="relative">
                <Popover.Button>
                  <button
                    type="button"
                    className="px-4 py-2 text-white bg-primary border border-primary rounded-sm"
                  >
                    {t("receipts.updateStatus")}
                  </button>
                </Popover.Button>
                <Popover.Panel
                  className="absolute left-0 top-0 flex flex-col gap-2 mt-16 p-2 w-full bg-white rounded-sm shadow-md"
                  style={{ zIndex: 9990 }}
                >
                  <button
                    onClick={() => {
                      updateEventsHelper(true);
                    }}
                  >
                    {t("events.visible")}
                  </button>
                  <button
                    onClick={() => {
                      updateEventsHelper(false);
                    }}
                  >
                    {t("events.invisible")}
                  </button>
                </Popover.Panel>
              </Popover>
            ) : null}
            {showExtraButtons() && canAccess("s", "DELETE") ? (
              <button
                className="px-4 py-2 text-white bg-red-500 border border-red-500 rounded-sm"
                onClick={() => {
                  handleDeleteModal();
                }}
              >
                {t("crud.delete")}
              </button>
            ) : null}
          </div>
          <div className="flex flex-wrap gap-5">
            <div className="flex flex-wrap gap-5">
              <button onClick={() => setFiltersOpen((state) => !state)}>
                <FilterIcon className="w-6 h-6" />
              </button>
            </div>
            <div className="flex flex-grow flex-wrap gap-5 justify-end">
              <div className="flex items-center w-72 border focus-within:border-global-input-focus border-gray-300">
                <div className="flex items-center justify-center p-2 h-full bg-white">
                  <SearchIcon className="w-5 h-5 text-gray-400" />
                </div>
                <input
                  type="text"
                  placeholder={`${t("events.search")}...`}
                  {...register("searchString")}
                  className="placeholder-gray-400 p-2 w-full border-0 focus:ring-0"
                />
              </div>
              {canAccess("events", "CREATE") && (
                <Link to="/events/create" className="h-full">
                  <button className="flex gap-2 items-center justify-center px-4 py-2 w-full text-white whitespace-nowrap bg-global-createEntity border border-global-createEntity">
                    <PlusIcon className="w-5 h-5" />
                    <span>
                      {t("events.create")} {t("events.event")}
                    </span>
                  </button>
                </Link>
              )}
            </div>
          </div>
          {watch("tags").length ? (
            <div className="flex flex-wrap gap-3">
              {watch("tags").map((tag, i) => (
                <button
                  key={i}
                  className="flex gap-2 items-center p-2 text-users-color hover:text-white hover:bg-users-role border border-users-role"
                  onClick={() => {
                    setValue(
                      "tags",
                      getValues("tags").filter((t) => t.label !== tag.label)
                    );
                  }}
                >
                  <span>{tag.value}</span>
                  <XIcon className="w-4 h-4" />
                </button>
              ))}
            </div>
          ) : null}
        </div>
        <Modal
          openStatus={openDeleteModal}
          setOpen={setOpenDeleteModal}
          icon={
            <ExclamationIcon
              className="w-6 h-6 text-red-600 bg-transparent"
              aria-hidden="true"
            />
          }
          header={
            <p>
              {t("deleteModal.delete")} {t("events.events")}
            </p>
          }
          title={
            <p>
              {includeEvents.length == 1 && (
                <span>
                  {t("deleteModal.sure")} {t("events.event")} ?{" "}
                  {t("deleteModal.undone")}
                </span>
              )}
              {includeEvents.length > 1 && (
                <span>
                  {t("deleteModal.sure_for_more")} {includeEvents.length}{" "}
                  {t("events.events")} ? {t("deleteModal.undone")}
                </span>
              )}
              {allSelected && (
                <span>
                  {t("deleteModal.sure_for_more")}{" "}
                  {events.data.total - excludeEvents.length}{" "}
                  {t("events.events")} ? {t("deleteModal.undone")}
                </span>
              )}
            </p>
          }
          footer={
            <div className="flex justify-end mt-5 sm:mt-4">
              <button
                type="button"
                className="inline-flex justify-center mt-3 px-4 py-1 w-full text-black text-base font-medium bg-white border border-black focus:outline-none shadow-sm focus:ring-0 sm:mt-0 sm:w-auto"
                onClick={() => setOpenDeleteModal(false)}
              >
                {t("deleteModal.cancel")}
              </button>
              <button
                type="button"
                className="inline-flex justify-center px-4 py-1 w-full text-white text-base font-medium bg-red-600 border border-transparent focus:outline-none shadow-sm focus:ring-0 sm:ml-3 sm:w-auto"
                onClick={() => deleteEventsHelper()}
              >
                {t("deleteModal.delete")}
              </button>
            </div>
          }
        />
        <Table {...tableInstance} />
        <Pagination
          rowsCount={events.data.total}
          pageIndex={pageIndex}
          pageSize={currentPageSize}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          pageOptions={pageOptions}
          pageCount={pageCount}
          gotoPage={dispatchGotoPage}
          nextPage={dispatchNextPage}
          previousPage={dispatchPreviousPage}
          setPageSize={setPageSize}
        />
        <TablePopup
          isOpen={portal.open}
          close={() => setPortal((state) => ({ ...state, open: false }))}
        >
          {portalContent()}
        </TablePopup>
        <Modal
          openStatus={openModal}
          setOpen={setOpenModal}
          icon={
            <ExclamationIcon
              className="w-6 h-6 text-red-600 bg-transparent"
              aria-hidden="true"
            />
          }
          header={
            <p>
              {t("deleteModal.delete")} {t("events.event")}
            </p>
          }
          title={
            <p>
              {t("deleteModal.sure")} {t("events.event")}?{" "}
              {t("deleteModal.undone")}
            </p>
          }
          footer={
            <div className="flex justify-end mt-5 sm:mt-4">
              <button
                type="button"
                className="inline-flex justify-center mt-3 px-4 py-1 w-full text-black text-base font-medium bg-white border border-black focus:outline-none shadow-sm focus:ring-0 sm:mt-0 sm:w-auto"
                onClick={() => setOpenModal(false)}
              >
                {t("deleteModal.cancel")}
              </button>
              <button
                type="button"
                className="inline-flex justify-center px-4 py-1 w-full text-white text-base font-medium bg-red-600 border border-transparent focus:outline-none shadow-sm focus:ring-0 sm:ml-3 sm:w-auto"
                onClick={() => handleDeleteEvent(eventToDelete)}
              >
                {t("deleteModal.delete")}
              </button>
            </div>
          }
        />
      </div>
    </Overlay>
  );
};
export default EventsPage;
