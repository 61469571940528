import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { CMS_BE_URL } from "api/urls";
import { selectMembershipStatuses } from "store/usersStore/usersReducer";
import { toast } from "react-toastify";
import useUser from "shared/hooks/useUser";
import DisplayInputError from "../UI/DisplayInputError";

const EditSub = ({
  subscription,
  fetchUser,
  cardNumber,
  ranksOptions,
  setLoading,
  setEdit,
  getSubData,
  getSubs,
  user,
  getMemberId,
  displaySubErrorMsg,
}: {
  setLoading: (value: boolean) => void;
  getSubData: any;
  setEdit: any;
  ranksOptions: any;
  getSubs: any;
  fetchUser: any;
  getMemberId: any;
  user: any;
  subscription: any;
  cardNumber: any;
  displaySubErrorMsg: any;
}) => {
  const { t } = useTranslation("common");
  const [memberCard, setMemberCard] = useState(cardNumber);
  const membershipStatuses = selectMembershipStatuses();
  const [status, setStatus] = useState(user.MEMBER_STATUS);
  const [rank, setRank] = useState(user.RANK);
  const [createdAt, setCreatedAt] = useState<any>(
    user.FIRST_SUB_DATE && new Date(user.FIRST_SUB_DATE)
  );
  const [startDate, setStartDate] = useState<any>(
    subscription?.start_date && new Date(subscription.start_date)
  );
  const [endDate, setEndDate] = useState<any>(
    subscription?.end_date && new Date(subscription.end_date)
  );
  const [paymentType, setPaymentType] = useState(subscription?.payment_type);

  const [type, setType] = useState(subscription?.type);

  const [sdError, setSdError] = useState(false);
  const [edError, setEdError] = useState(false);
  const [caError, setCaError] = useState(false);
  const [cnError, setCnError] = useState(false);

  const { editUser } = useUser();

  const updateSub = async () => {
    if (
      memberCard.length < 5 ||
      memberCard.length > 6 ||
      !startDate ||
      !endDate
    ) {
      if (memberCard.length < 5 || memberCard.length > 6) setCnError(true);
      if (!startDate) setSdError(true);
      if (!endDate) setEdError(true);
      return;
    }

    const data = {
      card_number: memberCard,
      payment_type: paymentType,
      type: type,
      start_date: startDate,
      end_date: endDate,
    };

    const userData = {
      memberStatus: status,
      rank: rank,
      firstSubDate: createdAt ? createdAt : null,
    };

    setLoading(true);

    try {
      await axios.put(`${CMS_BE_URL}/memberships/${user.PK_USER}`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
    } catch (err: any) {
      if (err.response?.data?.errors?.[0]?.message) {
        toast.error(err.response?.data?.errors?.[0]?.message);
      } else {
        toast.error(err.response?.data?.message);
      }
    }
    try {
      await axios.put(`${CMS_BE_URL}/teachers/${user.PK_USER}`, {
        member_id: memberCard,
      });

      const filesToDelete: any = [];
      await editUser(user.PK_USER, userData, filesToDelete);
      await getSubData();
      await getSubs();
      await fetchUser();

      setLoading(false);
      setEdit(false);
    } catch (err: any) {
      if (err.response?.data?.errors?.[0]?.message) {
        toast.error(err.response?.data?.errors?.[0]?.message);
      } else {
        toast.error(err.response?.data?.message);
      }
      setLoading(false);
    }
  };

  return (
    <>
      <div className="flex flex-wrap gap-4 items-center max-w-5xl">
        <label className="mb-2 text-lg font-medium">
          {t("users.status")}
          <br />
          <div className="flex-grow">
            <select
              defaultValue={user.MEMBER_STATUS.toUpperCase()}
              placeholder={t("user.membershipStatus")}
              onChange={(e) => setStatus(e.target.value)}
            >
              {membershipStatuses.map((st: string, i) => (
                <option key={i} value={st.toUpperCase()}>
                  {st.replace("_", " ")}
                </option>
              ))}
            </select>
          </div>
        </label>
        <label className="mb-2 text-lg font-medium">
          {t("user.cardNumber")}{" "}
          {cnError && (
            <span className="font-base text-red-400 text-sm">
              {t("subs.mess")}
            </span>
          )}{" "}
          <br />
          <input
            type="number"
            className={cnError ? "border-red-500" : ""}
            max={999999}
            minLength={6}
            onChange={(e) => {
              setCnError(false);
              setMemberCard(e.target.value);
            }}
            defaultValue={memberCard}
          />
        </label>

        <label className="mb-2 text-lg font-medium">
          {t("subs.payType")}
          <br />
          <select
            defaultValue={paymentType}
            onChange={(e) => setPaymentType(e.target.value)}
          >
            <option value="cash">{t("subs.cash")}</option>
            <option value="bank_transfer">{t("subs.bankTransfer")}</option>
            <option value="postal">{t("subs.postal")}</option>
            <option value="gift">{t("subs.gift")}</option>
          </select>
        </label>

        <label className="mb-2 text-lg font-medium">
          {t("subs.inscribed")} <br />
          <DatePicker
            id="inscribedBy"
            className={caError ? "border border-red-500" : ""}
            selected={createdAt}
            onChange={(e) => {
              setCaError(false);
              setCreatedAt(e);
            }}
            dateFormat="dd/MM/yyyy"
            placeholderText="Inscribed by (dd/mm/yyyy)"
            showPopperArrow={false}
            autoComplete="off"
          />
        </label>

        <label className="mb-2 text-lg font-medium">
          {t("subs.startDate")}
          <br />
          <DatePicker
            id="startDate"
            className={sdError ? "border border-red-500" : ""}
            selected={startDate}
            onChange={(e) => {
              setSdError(false);
              setStartDate(e);
            }}
            dateFormat="dd/MM/yyyy"
            placeholderText="dd/mm/yyyy"
            showPopperArrow={false}
            autoComplete="off"
          />
          {sdError && <DisplayInputError message={t("users.isRequired")} />}
        </label>
        <label className="mb-2 text-lg font-medium">
          {t("subs.endDate")} <br />
          <DatePicker
            id="endDate"
            className={edError ? "border border-red-500" : ""}
            selected={endDate}
            onChange={(e) => {
              setEdError(false);
              setEndDate(e);
            }}
            dateFormat="dd/MM/yyyy"
            placeholderText="dd/mm/yyyy"
            showPopperArrow={false}
            autoComplete="off"
          />
          {edError && <DisplayInputError message={t("users.isRequired")} />}
        </label>

        <label htmlFor="rank" className="mb-2 text-lg font-medium">
          {t("user.rank")}
          <br />

          <select defaultValue={rank} onChange={(e) => setRank(e.target.value)}>
            {ranksOptions.map((r: any, i: number) => (
              <option value={r.value} key={i}>
                {r.label}
              </option>
            ))}
          </select>
        </label>
        <label htmlFor="rank" className="mb-2 text-lg font-medium">
          Sub type
          <br />
          <select defaultValue={type} onChange={(e) => setType(e.target.value)}>
            <option value="teacher">Teacher</option>
            <option value="patreon">Patreon</option>
            <option value="normal_user">Normal user</option>
          </select>
        </label>
      </div>
      <button
        onClick={updateSub}
        type="button"
        className="my-4 px-4 py-2 text-white bg-users-create"
      >
        {t("new.save")}
      </button>
    </>
  );
};

export default EditSub;
