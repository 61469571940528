import React, { useEffect, useState, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "store/storeHooks";
import {
  Column,
  useFlexLayout,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import {
  EyeIcon,
  SearchIcon,
  PlusIcon,
  TrashIcon,
  ClipboardCopyIcon,
  PencilAltIcon,
} from "@heroicons/react/solid";
import { Link, useNavigate } from "react-router-dom";
import { useDebounce } from "shared/hooks/useDebounce";
import Pagination from "shared/components/Table/Pagination";
import Table from "shared/components/Table/Table";
import { useTranslation } from "react-i18next";
import { selectLoggedInUser } from "store/authStore/authReducer";
import Spinner from "shared/components/UI/Spinner";
import Overlay from "shared/components/UI/Overlay";
import { format } from "date-fns";
import { toast } from "react-toastify";
import Message from "../../shared/components/UI/Message";
import Modal from "../../shared/components/UI/Modal";
import TablePopup, { PortalType } from "shared/components/UI/TablePopup";
import { DotsVerticalIcon, ExclamationIcon } from "@heroicons/react/outline";
import {
  getNewsletterThunk,
  deleteNewsletterThunk,
} from "store/newsletterStore/newsletterReducer";
import { getNewsletterColumns } from "api/Newsletter/newsletter";
import { PermissionDomain } from "shared/interfaces/Permission.interface";
import { RolePermissionCrudAction } from "shared/interfaces/RolePermission.interface";
import { CMS_BE_URL } from "api/urls";
import axios from "axios";

const currentPageSize = 10;

const Newsletter: () => JSX.Element = () => {
  const { t } = useTranslation("common");
  const [searchText, setSearchText] = useState("");
  const debouncedSearchText = useDebounce(searchText, 300);
  const newsletter = useAppSelector((state) => state.newsletter);
  const dispatch = useAppDispatch();
  const loggedInUser = selectLoggedInUser();
  const lang = useAppSelector((state) => state.translation.language);
  const isSuperAdmin = loggedInUser && loggedInUser?.user?.ROLE === 4;

  //PORTAL
  const [portal, setPortal] = useState<PortalType<any>>({
    open: false,
    top: 0,
    left: 0,
    entity: null,
  });

  const handleSearchText = (e: React.ChangeEvent<HTMLInputElement>) =>
    setSearchText(e.target.value);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const handleDeleteNewsletter = (newsletter_id: string) => {
    dispatch(deleteNewsletterThunk(newsletter_id))
      .then((res) => {
        if (res.meta.requestStatus === "rejected") {
          toast.error(
            Message({
              action: "delete",
              entity: t("dashboard.newsletter"),
              error: t("genericError"),
              lang,
              gender: "male",
            })
          );
        } else {
          toast.success(
            Message({
              action: "delete",
              entity: t("dashboard.newsletter"),
              lang,
              gender: "male",
            })
          );
        }
      })
      .then(() =>
        dispatch(
          getNewsletterThunk({
            pageIndex: 0,
            pageSize: currentPageSize,
            searchString: debouncedSearchText,
            sortCol: sortBy[0] ? sortBy[0].id : "",
            sortOrder: sortBy[0] ? (sortBy[0].desc ? "DESC" : "ASC") : "",
          })
        )
      );
    setOpenDeleteModal(false);
  };

  function canAccess(
    domain: PermissionDomain,
    action: RolePermissionCrudAction,
    newsletter: any
  ): boolean {
    if (loggedInUser.permissions["*"]) {
      return true;
    } else {
      switch (loggedInUser.permissions[domain][action]) {
        case "NONE": {
          return false;
        }
        case "OWN": {
          return newsletter.creator_id === loggedInUser.user?.PK_USER;
        }
        case "ANY": {
          return true;
        }
        default: {
          return false;
        }
      }
    }
  }

  const columns: Array<Column<Record<string, any>>> = useMemo(
    () => getNewsletterColumns(lang),
    [lang]
  );

  const data: Array<any> = useMemo(() => {
    return newsletter.data?.data?.map((newsletter: any, i) => {
      return {
        col1: (
          <div className="flex items-center truncate">{newsletter.title}</div>
        ),
        col2: (
          <div className="flex items-center truncate">
            {newsletter.creator_id}
          </div>
        ),
        col3: (
          <div className="flex items-center truncate">
            {format(new Date(newsletter.created_at), "MMMM dd, yyyy")}
          </div>
        ),
        // col4: <div>{newsletter.status}</div>,
        col4: (
          <div>
            {newsletter.status.toLowerCase() === "sent"
              ? lang === "en"
                ? "Sent"
                : "Inviato"
              : null}
            {newsletter.status.toLowerCase() === "draft"
              ? lang === "en"
                ? "Draft"
                : "Bozza"
              : null}
            {newsletter.status.toLowerCase() === "pending"
              ? lang === "en"
                ? "Pending"
                : "Invio in corso"
              : null}
          </div>
        ),
        col5: (
          <div className="flex items-center truncate">
            {newsletter?.send_date
              ? format(new Date(newsletter?.send_date), "MMMM dd, yyyy")
              : null}
          </div>
        ),
        col6: (
          <div className="relative flex items-center">
            <button
              id={`portal-button-${i}`}
              type="button"
              onClick={(e) => {
                return setPortal((state: any) => {
                  const thisButton = document.getElementById(
                    `portal-button-${i}`
                  );
                  const position = thisButton?.getBoundingClientRect();
                  return {
                    open: !state.open,
                    top: Number(position?.bottom),
                    left: Number(position?.left),
                    entity: newsletter,
                  };
                });
              }}
            >
              <DotsVerticalIcon className="w-5 h-5" />
            </button>
          </div>
        ),
      };
    });
  }, [newsletter?.data?.data, lang]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: currentPageSize },
      pageCount: Math.ceil(newsletter?.data?.total / currentPageSize),
      manualSortBy: true,
      manualPagination: true,
    },
    useSortBy,
    usePagination,
    useFlexLayout
  );

  const {
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, sortBy },
  } = tableInstance;

  useEffect(() => {
    dispatch(
      getNewsletterThunk({
        pageIndex: 0,
        pageSize: currentPageSize,
        searchString: debouncedSearchText,
        sortCol: sortBy[0] ? sortBy[0].id : "",
        sortOrder: sortBy[0] ? (sortBy[0].desc ? "DESC" : "ASC") : "",
      })
    );
  }, [debouncedSearchText, sortBy]);

  function createGetNewsletterThunk(pageIndex: number) {
    return {
      pageIndex,
      pageSize: currentPageSize,
      searchString: debouncedSearchText,
      sortCol: sortBy[0] ? sortBy[0].id : "",
      sortOrder: sortBy[0] ? (sortBy[0].desc ? "DESC" : "ASC") : "",
    };
  }

  const dispatchNextPage = () => {
    if (canNextPage) {
      dispatch(getNewsletterThunk(createGetNewsletterThunk(pageIndex + 1)));
      nextPage();
    }
  };

  const dispatchPreviousPage = () => {
    if (canPreviousPage) {
      dispatch(getNewsletterThunk(createGetNewsletterThunk(pageIndex - 1)));
      previousPage();
    }
  };

  const dispatchGotoPage = (pageIndex: number) => {
    dispatch(getNewsletterThunk(createGetNewsletterThunk(pageIndex)));
    gotoPage(pageIndex);
  };

  const cloneNewsletter = async (id: string) => {
    try {
      await axios.post(`${CMS_BE_URL}/newsletter/clone/${id}`);
      toast.success(t("newsletter.clone"));
      dispatch(getNewsletterThunk(createGetNewsletterThunk(pageIndex)));
    } catch (err: any) {
      if (err.response?.data?.errors?.[0]?.message) {
        toast.error(err.response?.data?.errors?.[0]?.message);
      } else {
        toast.error(err.response?.data?.message);
      }
    }
  };

  function portalContent() {
    if (portal.entity === null) return;
    return (
      <div
        className="fixed z-50 flex flex-col gap-2 p-2 bg-white rounded-sm shadow-md"
        style={{
          top: portal.top,
          left: portal.left,
        }}
      >
        {canAccess("newsletter", "UPDATE", portal.entity) && (
          <>
            <Link
              to={`/newsletter/${portal.entity.newsletter_id}`}
              className="flex gap-2 items-center text-events-description"
            >
              <EyeIcon className="w-5 h-5" />
              {lang === "en" ? "View" : "Visualizza"}
            </Link>
            {portal.entity.status.toLowerCase() !== "draft" && (
              <Link
                to={`/newsletter/dashboard/${portal.entity.newsletter_id}`}
                className="flex gap-2 items-center text-black"
              >
                <PencilAltIcon className="w-5 h-5" />
                {lang === "en" ? "Dashboard" : "Statistiche"}
              </Link>
            )}
            <Link
              to={`/newsletter/edit/${portal.entity.newsletter_id}`}
              className="flex gap-2 items-center text-table-edit"
            >
              <PencilAltIcon className="w-5 h-5" />
              {lang === "en" ? "Edit" : "Modificare"}
            </Link>
            <button
              onClick={() => cloneNewsletter(portal.entity.newsletter_id)}
              className="flex gap-2 items-center text-users-create"
            >
              <ClipboardCopyIcon className="w-5 h-5" />
              Clone
            </button>
          </>
        )}
        {isSuperAdmin && portal.entity.status !== "DELETED" && (
          <button
            onClick={() => setOpenDeleteModal(true)}
            className="flex gap-2 items-center text-users-delete"
          >
            <TrashIcon className="w-5 h-5" />
            {lang === "en" ? "Delete" : "Elimina"}
          </button>
        )}
      </div>
    );
  }

  return (
    <Overlay active={newsletter.loading} spinner={<Spinner />}>
      <div className="flex flex-col gap-5 p-10 h-full">
        <h1 className="text-3xl font-bold">Newsletter</h1>
        <div className="flex gap-5 items-center justify-end">
          <div className="flex items-center border focus-within:border-global-input-focus border-gray-300">
            <div className="flex items-center justify-center p-2 h-full bg-white">
              <SearchIcon className="w-5 h-5 text-gray-400" />
            </div>
            <input
              type="text"
              value={searchText}
              placeholder={`${t("UserCRUD.searchPlaceholder")}`}
              onChange={handleSearchText}
              className="placeholder-gray-400 p-2 w-full border-0 focus:ring-0"
            />
          </div>
          {(loggedInUser.permissions["*"] ||
            loggedInUser.permissions?.newsletter?.CREATE === "ANY") && (
            <Link to="/newsletter/create" className="h-full">
              <button className="flex gap-2 items-center justify-center px-4 py-2 text-white bg-users-create">
                <PlusIcon className="w-5 h-5" />
                <span className="whitespace-nowrap">{t("newsletter.cre")}</span>
              </button>
            </Link>
          )}
        </div>
        <Modal
          openStatus={openDeleteModal}
          setOpen={setOpenDeleteModal}
          icon={
            <ExclamationIcon
              className="w-6 h-6 text-red-600 bg-transparent"
              aria-hidden="true"
            />
          }
          header={
            <p>
              {t("deleteModal.delete")} {t("dashboard.newsletter")}
            </p>
          }
          title={
            <p>
              {t("deleteModal.sure")} {t("dashboard.newsletter")} ?{" "}
              {t("deleteModal.undone")}
            </p>
          }
          footer={
            <div className="flex justify-end mt-5 sm:mt-4">
              <button
                type="button"
                className="inline-flex justify-center mt-3 px-4 py-1 w-full text-black text-base font-medium bg-white border border-black focus:outline-none shadow-sm focus:ring-0 sm:mt-0 sm:w-auto"
                onClick={() => setOpenDeleteModal(false)}
              >
                {t("deleteModal.cancel")}
              </button>
              <button
                type="button"
                className="inline-flex justify-center px-4 py-1 w-full text-white text-base font-medium bg-red-600 border border-transparent focus:outline-none shadow-sm focus:ring-0 sm:ml-3 sm:w-auto"
                onClick={() =>
                  handleDeleteNewsletter(portal.entity.newsletter_id)
                }
              >
                {t("deleteModal.delete")}
              </button>
            </div>
          }
        />
        <Table {...tableInstance} className="users__table" />
        <Pagination
          rowsCount={newsletter?.data?.total}
          pageIndex={pageIndex}
          pageSize={currentPageSize}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          pageOptions={pageOptions}
          pageCount={pageCount}
          gotoPage={dispatchGotoPage}
          nextPage={dispatchNextPage}
          previousPage={dispatchPreviousPage}
          setPageSize={setPageSize}
        />
        <TablePopup
          isOpen={portal.open}
          close={() => setPortal((state) => ({ ...state, open: false }))}
        >
          {portalContent()}
        </TablePopup>
      </div>
    </Overlay>
  );
};

export default Newsletter;
