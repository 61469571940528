import { SelectTagType } from "shared/interfaces/Tag.interface";
import { CSSObjectWithLabel } from "react-select";
import { SelectBadgeType, SelectCodeType } from "pages/Certificates/types";
import {
  SelectLocationResctiction,
  SelectRegion,
  SelectProvince,
} from "shared/interfaces/Location.interface";
import {
  RankSelect,
  User,
  UserLowercase,
  UserMembershipStatusSelect,
  UserRoleSelect,
  UserStatusSelect,
} from "shared/interfaces/User.interface";
import { format } from "date-fns";
import { SelectGender } from "shared/interfaces/Gender.interface";
import { CreateUser as POSTCreateUser } from "shared/interfaces/User.interface";
import { CreateUser } from "pages/Users/types";
import { Info } from "pages/Councils/types";
import { CreateAboutUs } from "shared/interfaces/AboutUs.interface";
import Resizer from "react-image-file-resizer";
import { BillingAddress, ShippingAddress } from "api/Orders/orders.api";
import {
  CouncilRank,
  PresidentialRank,
  UserRank,
} from "shared/interfaces/Rank.interface";

export function returnFileSize(number: number) {
  if (number < 1024) {
    return number + "bytes";
  } else if (number >= 1024 && number < 1048576) {
    return (number / 1024).toFixed(1) + "KB";
  } else if (number >= 1048576) {
    return (number / 1048576).toFixed(1) + "MB";
  }
}

export function statusToQuery(status: UserStatusSelect) {
  if (status && status.value) {
    return status.value;
  }
  return "";
}

export function membershipStatusToQuery(
  membershipStatus: UserMembershipStatusSelect
) {
  if (membershipStatus && membershipStatus.value) {
    return membershipStatus.value;
  }
  return "";
}

export function ranksToQuery(rank: RankSelect) {
  if (rank && rank.value) {
    return rank.value;
  }
  return "";
}

export function roleToQuery(roles: UserRoleSelect) {
  if (roles && roles.value) {
    return roles.value;
  }
  return "";
}

export function tagsToQuery(tags: SelectTagType[]) {
  return tags
    .map((tag) => {
      return encodeURIComponent(tag.value);
    })
    .join(",");
}

export function regionToQuery(region: SelectRegion) {
  if (region && region.value) {
    return region.value;
  }
  return "";
}

export function provinceToQuery(province: SelectProvince) {
  if (province && province.value) {
    return province.value;
  }
  return "";
}

export function badgeToQuery(badge: SelectBadgeType) {
  if (badge && badge.value) {
    return badge.value;
  }
  return "";
}

export function codeToQuery(code: SelectCodeType) {
  if (code && code.value) {
    return code.value;
  }
  return "";
}

export function genderToQuery(gender: SelectGender) {
  if (gender && gender.value) {
    return gender.value;
  }
  return "";
}

export function stringToFile(str: string) {
  const blob = new Blob([str], { type: "text/plain" });
  const file = new File([blob], "file.txt", { type: "text/plain" });
  return file;
}

export function getInputStyles(isError: boolean) {
  return `focus:ring-0 block w-full placeholder-gray-400 ${
    isError
      ? "border-global-input-error focus:border-global-input-error"
      : "focus:border-global-input-focus border-gray-300"
  }`;
}

export function getDisabledInputStyles() {
  return `focus:ring-0 block w-full text-disabledColor border border-disabledBorder bg-disabledBg`;
}

export function addStyles(...styles: string[]) {
  return styles.join(" ");
}

export function getMediaDropzoneStyles(isError: boolean) {
  return `w-full flex flex-col gap-5 items-center justify-center p-5 bg-white border-2 border-dashed cursor-pointer ${
    isError ? "border-global-input-error" : "border-gray-300"
  }`;
}

export function getUserCoverDropzoneStyles(isError: boolean) {
  return `w-full flex flex-col gap-5 items-center justify-center bg-white border-2 border-dashed cursor-pointer ${
    isError ? "border-global-input-error" : "border-gray-300"
  }`;
}

export function getEditorStyles(isError: boolean) {
  return `flex flex-col flex-grow ${isError ? "ql-error" : ""}`;
}

export function dateToQuery(date?: Date) {
  if (date) return new Date(date).toUTCString();
  // if (date) return toISOString(date);
  return "";
}

export function capitalizeFirst(str: string | null) {
  if (str) return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  return null;
}
export function dataURLtoFile(dataurl: string, filename: string) {
  const arr = dataurl.split(",");
  let mime, bstr, n, u8arr;
  if (arr) {
    const temp = arr[0].match(/:(.*?);/);
    if (temp) {
      mime = temp[1];
      bstr = atob(arr[1]);
      n = bstr.length;
      u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    }
  }
  return null;
}

export function constructCreateUser(data: CreateUser) {
  const user: POSTCreateUser = {} as POSTCreateUser;
  user.fileType = data.fileType;
  user.username = data.username;
  user.email = data.email;
  user.firstName = data.firstName;
  user.lastName = data.lastName;
  if (data.visiblePicture) user.visiblePicture = data.visiblePicture;
  if (data.isVisibleTeacher) user.isVisibleTeacher = data.isVisibleTeacher;
  if (data.role && data.role.value) user.role = data.role.value;
  if (data.birthDate) user.birthDate = data.birthDate;
  else user.birthDate = "";
  if (data.note) user.note = data.note;
  else user.note = "";
  if (data.promotionBody) user.promotionBody = data.promotionBody;
  else user.promotionBody = "";
  if (data.title) user.title = data.title;
  else user.title = "";
  if (data.rank && data.rank.value) user.rank = data.rank.value;
  else user.rank = "";
  if (data.birthPlace) user.birthPlace = data.birthPlace;
  else user.birthPlace = "";
  if (data.description) user.description = data.description;
  else user.description = "";
  if (data.school) user.school = data.school;
  else user.school = "";
  if (data.titles) user.titles = data.titles;
  else user.titles = "";
  if (data.fiscaleCode) user.fiscaleCode = data.fiscaleCode;
  else user.fiscaleCode = "";
  if (data.posta) user.posta = data.posta;
  else user.posta = "";
  if (data.votePrivilege) user.votePrivilege = data.votePrivilege;
  else user.votePrivilege = "0";
  if (data.gender && data.gender.value) user.gender = data.gender.value;
  else user.gender = "";
  if (data.visibleSite) user.visibleSite = data.visibleSite;
  else user.visibleSite = "";
  user.contactInfo = [];
  data.contactInfo.forEach((contact, i) => {
    if (user.contactInfo) {
      user.contactInfo[i] = contact;
    }
  });
  user.addressInfo = [];
  data.addressInfo.forEach((location, i) => {
    if (user.addressInfo && location.region && location.province) {
      user.addressInfo[i] = {
        ...location,
        region: location.region.value,
        province: location.province.value,
      };
    }
  });
  if (data.gallery) user.gallery = data.gallery;
  if (data.profilePic) {
    user.profilePic = data.profilePic;
  }
  if (data.newsletter) {
    user.newsletter = 1;
  } else {
    user.newsletter = 0;
  }
  if (data.bounced) {
    user.bounced = 1;
  } else {
    user.bounced = 0;
  }
  user.regionFilter = data.regionRestrictions.map((region) => region.value);
  return user;
}

export function constructLocationRestrictions(
  locationRestrictions?: SelectLocationResctiction[]
) {
  if (locationRestrictions) {
    return locationRestrictions.map(
      (locationRestriction) => locationRestriction.value
    );
  }
  return [];
}

const evenFiscalMap: { [k: string | number]: number } = {
  0: 0,
  1: 1,
  2: 2,
  3: 3,
  4: 4,
  5: 5,
  6: 6,
  7: 7,
  8: 8,
  9: 9,
  A: 0,
  B: 1,
  C: 2,
  D: 3,
  E: 4,
  F: 5,
  G: 6,
  H: 7,
  I: 8,
  J: 9,
  K: 10,
  L: 11,
  M: 12,
  N: 13,
  O: 14,
  P: 15,
  Q: 16,
  R: 17,
  S: 18,
  T: 19,
  U: 20,
  V: 21,
  W: 22,
  X: 23,
  Y: 24,
  Z: 25,
};

const oddFiscalMap: { [k: string | number]: number } = {
  0: 1,
  1: 0,
  2: 5,
  3: 7,
  4: 9,
  5: 13,
  6: 15,
  7: 17,
  8: 19,
  9: 21,
  A: 1,
  B: 0,
  C: 5,
  D: 7,
  E: 9,
  F: 13,
  G: 15,
  H: 17,
  I: 19,
  J: 21,
  K: 2,
  L: 4,
  M: 18,
  N: 20,
  O: 11,
  P: 3,
  Q: 6,
  R: 8,
  S: 12,
  T: 14,
  U: 16,
  V: 10,
  W: 22,
  X: 25,
  Y: 24,
  Z: 23,
};

const checkSumMap: { [k: number]: string } = {
  0: "A",
  1: "B",
  2: "C",
  3: "D",
  4: "E",
  5: "F",
  6: "G",
  7: "H",
  8: "I",
  9: "J",
  10: "K",
  11: "L",
  12: "M",
  13: "N",
  14: "O",
  15: "P",
  16: "Q",
  17: "R",
  18: "S",
  19: "T",
  20: "U",
  21: "V",
  22: "W",
  23: "X",
  24: "Y",
  25: "Z",
};

export function checkFiscal(str: string) {
  if (!str) return true;
  if (str.length !== 16) return "Fiscal Code must be 16 alphanumeric long.";
  let counter = 0;
  for (let i = 0; i < str.length - 1; i++)
    if ((i + 1) % 2 === 0) counter += Number(evenFiscalMap[str[i]]);
    else counter += Number(oddFiscalMap[str[i]]);
  if (str[15] === checkSumMap[counter % 26]) return true;
  else return "Fiscal Code incorrect.";
}

export function calculateDeletionDate(date: Date | undefined) {
  if (date) {
    return format(date.setDate(date.getDate() + 30), "dd-MM-yyyy");
  }
  return null;
}

export function getReactSelectStyles(isError: boolean): any {
  return {
    container: (provided: CSSObjectWithLabel) => ({
      ...provided,
      width: "100%",
    }),
    control: (provided: CSSObjectWithLabel, state: CSSObjectWithLabel) => ({
      ...provided,
      borderRadius: 0,
      boxShadow: "none",
      borderColor: isError
        ? "#F91B1B"
        : state.isFocused
        ? "#243973"
        : "#d1d5db",
      "&:hover": {
        boxShadow: "none",
      },
    }),
    input: (provided: CSSObjectWithLabel) => ({
      ...provided,
      padding: 0,
      margin: 0,
    }),
    valueContainer: (provided: CSSObjectWithLabel) => ({
      ...provided,
      padding: "0.5rem",
      margin: 0,
    }),
    menuList: (provided: CSSObjectWithLabel) => ({
      ...provided,
      padding: 0,
      "&::-webkit-scrollbar-thumb": {
        background: "#243973",
      },
      "&::-webkit-scrollbar-track": {
        background: "transparent",
      },
    }),
    placeholder: (provided: CSSObjectWithLabel) => ({
      ...provided,
      margin: 0,
      // textTransform: "capitalize",
      color: "#9ca3af",
    }),
    option: (provided: CSSObjectWithLabel, state: CSSObjectWithLabel) => ({
      ...provided,
      color: state.isFocused ? "white" : "black",
      backgroundColor: state.isFocused ? "#243973" : "white",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      // textTransform: "capitalize",
      "&:hover": {
        color: "white",
        backgroundColor: "#243973",
      },
    }),
    multiValue: (provided: CSSObjectWithLabel) => ({
      ...provided,
      borderRadius: 0,
    }),
    menu: (provided: CSSObjectWithLabel) => ({
      ...provided,
      borderRadius: 0,
      zIndex: 20,
    }),
  };
}

export function constructUpdateInfo(data: Info): CreateAboutUs {
  const info: CreateAboutUs = {};
  info.fileType = "about_pic";
  if (data?.history) info["history"] = data.history;
  else info["history"] = "";
  if (data?.membership) info["membership"] = data.membership;
  else info["membership"] = "";
  if (data?.milestones) info["milestones"] = data.milestones;
  else info["milestones"] = "";
  if (
    data?.historyImage &&
    (data.historyImage === "deleted" || typeof data.historyImage === "object")
  )
    info["historyImage"] = data.historyImage;
  if (
    data?.membershipImage &&
    (data.membershipImage === "deleted" ||
      typeof data.membershipImage === "object")
  )
    info["membershipImage"] = data.membershipImage;

  if (
    data?.milestonesImage &&
    (data.milestonesImage === "deleted" ||
      typeof data.milestonesImage === "object")
  )
    info["milestonesImage"] = data.milestonesImage;
  return info;
}

export function getSingleImageConstraintsConfig() {
  return {
    validate: {
      checkSize: (file: any) => {
        if (typeof file === "string") return true;
        if (file && file.size > 2 * 1048576)
          return "File size is greater than 2MB";
        return true;
      },
    },
  };
}

export function getImageDimensionsFromFile(
  file: File
): Promise<[number, number]> {
  return new Promise(function calcDimensions(resolve, reject) {
    if (file) {
      const image = new Image();
      const objectURL = URL.createObjectURL(file);
      image.onload = function onImageLoad() {
        const [width, height] = [image.width, image.height];
        URL.revokeObjectURL(objectURL);
        resolve([width, height]);
      };
      image.src = objectURL;
    } else {
      reject([0, 0]);
    }
  });
}

export function resizeFile(
  file: File,
  aspect: number,
  maxWidth = -1,
  maxHeight = -1
) {
  return new Promise(function (resolve) {
    if (maxHeight === -1) {
      if (aspect == 1) {
        maxWidth = 400;
        maxHeight = 400;
      } else if (aspect == 0) {
        maxWidth = 2500;
        maxHeight = 2500;
      } else {
        maxWidth = 1920;
        maxHeight = 1080;
      }
    }
    return Resizer.imageFileResizer(
      file,
      maxWidth,
      maxHeight,
      "JPEG",
      100,
      0,
      (uri: any) => {
        resolve(uri);
      },
      "file"
    );
  });
}

export const log = (val: any) => (console.log(val), val);

export function getLanguageFromLocalStorage() {
  const lang = localStorage.getItem("i18nextLng");
  if (lang) return lang;
  return false;
}

export function sanitizeUser(user: User & { PASSWORD?: string }) {
  const tempUser = { ...user };
  delete tempUser["PASSWORD"];
  return tempUser;
}

export function getCropBoxOffsets({
  containerWidth,
  containerHeight,
  isHeightGreater,
  aspect,
}: {
  containerWidth: number;
  containerHeight: number;
  isHeightGreater: boolean;
  aspect: number;
}): {
  top: number;
  left: number;
} {
  let top, left;
  if (aspect === 1) {
    if (isHeightGreater) {
      top = 10;
      left = 0;
      return {
        top: (containerHeight - containerWidth) / 2,
        left: 0,
      };
    } else {
      return {
        top: 0,
        left: (containerWidth - containerHeight) / 2,
      };
    }
  } else {
    top = (containerHeight - containerWidth * (9 / 16)) / 2;
    return {
      top: top > 0 ? top : 0,
      left: Math.round(
        top > 0 ? 0 : (containerWidth - (containerHeight * 16) / 9) / 4
      ),
    };
  }
}

export function toISOString(date: Date) {
  return new Date(
    date.getTime() - date.getTimezoneOffset() * 60000
  ).toISOString();
}

export function mapCategories(categories: any[]) {
  if (categories) {
    return categories.map((category) => String(category.value.id));
  }
  return [];
}

export function Round(num: number) {
  const m = Number((Math.abs(num) * 100).toPrecision(15));
  return (Math.round(m) / 100) * Math.sign(num);
}

export function findPhone(contactDetails: UserLowercase["contact_details"]) {
  if (contactDetails.length === 1) return contactDetails[0];
  return contactDetails.find((contactDetail) => contactDetail.type === "PHONE");
}

export function isCouncilRank(rank: any): rank is CouncilRank {
  return !!rank?.council_rank_eng;
}

export function isPresidentialRank(rank: any): rank is CouncilRank {
  return !!rank?.presidential_rank_eng;
}

export function fillRankLabel(
  rank: CouncilRank | PresidentialRank,
  lang: "en" | "it"
) {
  if (isCouncilRank(rank)) {
    return lang === "en" ? rank.council_rank_eng : rank.council_rank_it;
  } else if (isPresidentialRank(rank)) {
    return lang === "en"
      ? rank.presidential_rank_eng
      : rank.presidential_rank_it;
  } else {
    return "";
  }
}

export function validateEmail(email: string) {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
}

export function trimString(str: string, length: number) {
  if (str.length > length) return str.substring(0, length - 3) + "...";
  return str.substring(0, length);
}

type keyType = symbol | number | string;

export type ArrayElement<T extends readonly unknown[]> =
  T extends readonly (infer ElementType)[] ? ElementType : never;

type ToLowerCase<T> = T extends string ? Lowercase<T> : T;

export type LowercaseKeys<T> = {
  [k in keyof T as ToLowerCase<k>]: T[k] extends Record<keyType, any>
    ? LowercaseKeys<T[k]>
    : T[k] extends Array<Record<keyType, any>>
    ? LowercaseKeys<ArrayElement<T[k]>>
    : T[k];
};

export function lowercaseKeys(obj?: Record<keyType, any>) {
  if (!obj) return null;
  return Object.keys(obj).reduce((acc, key) => {
    if (typeof obj[key] === "object") {
      obj[key] = lowercaseKeys(obj[key]);
    }
    if (Array.isArray(obj[key])) {
      obj[key] = obj[key].map((el: any) => {
        if (typeof el === "object") {
          return lowercaseKeys(el);
        }
        return el;
      });
    }
    acc[key.toLowerCase()] = obj[key];
    return acc;
  }, {} as Record<keyType, any>);
}

export const b64toBlob = (
  base64: string,
  type = "application/octet-stream"
): Promise<Blob> =>
  fetch(`data:${type};base64,${base64}`).then((res) => res.blob());
