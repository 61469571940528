import React from "react";
import { CreatePermissionAccess } from "shared/interfaces/RolePermission.interface";
import { permissionsManagementActions } from "store/globalStore";
import { selectCreateRolePermissions } from "store/permissionsManagement/permissionsManagementReducer";
import { useAppDispatch } from "store/storeHooks";

type crud = "create" | "read" | "update" | "delete";

const PermissionOnlyCheckBox = ({
  permission,
  enabled,
  isItChild,
}: {
  permission?: CreatePermissionAccess;
  enabled: boolean;
  isItChild: boolean;
}) => {
  const dispatch = useAppDispatch();
  const rolePermissions = selectCreateRolePermissions();

  const onEnabledChangeHandler = (i: number, bool: boolean) => {
    const cruds: crud[] = ["create", "read", "update", "delete"];
    dispatch(
      permissionsManagementActions.changeCreateRolePermissionEnabledAccess({
        i,
        bool,
      })
    );
    cruds.map((crud) => {
      dispatch(
        permissionsManagementActions.changeCreateRolePermissionsPermissionsAccess(
          {
            i,
            crud,
            crudType: bool ? "ANY" : "NONE",
          }
        )
      );
    });
  };

  if (permission) {
    return (
      <tr key={permission.permissionName}>
        <td className="flex flex-row justify-start px-6 py-4 text-gray-900 whitespace-nowrap text-sm font-medium">
          <div className="flex flex-row gap-3 w-full">
            <span className={`w-3/4 truncate ${isItChild ? "pl-10" : "pl-5"}`}>
              {permission.title}
            </span>
          </div>
        </td>
        <td className="px-6 py-4 text-gray-500 whitespace-nowrap text-sm">
          <div className="flex gap-7 justify-center mt-2">
            <input
              type="checkbox"
              className="text-users-create"
              name="permissions-checkbox"
              id={`checkbox-${permission.permissionName}-1`}
              checked={
                rolePermissions.permissionsAccess[permission.index]?.enabled
              }
              disabled={!enabled}
              onChange={() =>
                onEnabledChangeHandler(
                  permission.index,
                  !rolePermissions.permissionsAccess[permission.index]?.enabled
                )
              }
            />
          </div>
        </td>
        <td className="px-6 py-4 text-gray-500 whitespace-nowrap text-sm">
          <div className="read-radio-container flex gap-7 justify-center">
            <input
              type="radio"
              name={`read-${permission.permissionName}`}
              value="ANY"
              id={`read-${permission.permissionName}-1`}
              disabled={true}
              className="invisible"
            />
            <input
              type="radio"
              name={`read-${permission.permissionName}`}
              value="own"
              id={`read-${permission.permissionName}-2`}
              disabled={true}
              className="invisible"
            />
          </div>
        </td>
        <td className="px-6 py-4 text-gray-500 whitespace-nowrap text-sm">
          <div className="create-radio-container flex gap-7 justify-center">
            <input
              type="radio"
              name={`create-${permission.permissionName}`}
              value="any"
              id={`create-${permission.permissionName}-1`}
              disabled={true}
              className="invisible"
            />
            <input
              type="radio"
              name={`create-${permission.permissionName}`}
              value="own"
              id={`create-${permission.permissionName}-2`}
              disabled={true}
              className="invisible"
            />
            <input
              type="radio"
              name={`create-${permission.permissionName}`}
              value="none"
              id={`create-${permission.permissionName}-3`}
              disabled={true}
              className="invisible"
            />
          </div>
        </td>
      </tr>
    );
  } else {
    return null;
  }
};

export default PermissionOnlyCheckBox;
