import React from "react";
import axios from "axios";
import { Lang } from "shared/components/UI/Message";
import {
  CreateReceiptDto,
  MassExportReceiptsDtoExclude,
  MassExportReceiptsDtoInclude,
  MassUpdateReceiptsDtoExclude,
  MassUpdateReceiptsDtoInclude,
  ReceiptsFiltersDto,
  UpdateReceiptDto,
} from "shared/interfaces/Receipt.interface";
import {
  createReceiptUrl,
  deleteReceiptUrl,
  exportReceiptUrl,
  getReceiptsUrl,
  getReceiptUrl,
  massExportReceiptsUrl,
  singleExportReceiptUrl,
  updateReceiptsUrl,
  updateReceiptUrl,
  updateStateReceiptUrl,
} from "./urls";

export const createReceipt = (receipt: CreateReceiptDto) =>
  axios.post(createReceiptUrl, receipt);

export const getReceipts = (filters: ReceiptsFiltersDto) => {
  return axios.get(
    `${getReceiptsUrl}?`.concat(
      Object.entries(filters)
        .filter(([_, value]) => !!value)
        .map(([key, value]) => `${key}=${value}`)
        .join("&")
    )
  );
};

export const getReceipt = (receiptId: string) =>
  axios.get(getReceiptUrl(receiptId));

export const exportReceipt = (receiptId: string) =>
  axios.get(exportReceiptUrl(receiptId));

export const updateReceipt = (receiptId: string, receipt: UpdateReceiptDto) =>
  axios.put(updateReceiptUrl(receiptId), receipt);

export const updateReceipts = (
  form: MassUpdateReceiptsDtoInclude | MassUpdateReceiptsDtoExclude
) => {
  Object.keys(form.filters).forEach(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    (key) => !form.filters[key] && delete form.filters[key]
  );
  return axios.put(updateReceiptsUrl, form);
};

export const updateStateReceipts = (
  receiptId: string,
  form: MassUpdateReceiptsDtoInclude | MassUpdateReceiptsDtoExclude
) => {
  Object.keys(form.filters).forEach(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    (key) => !form.filters[key] && delete form.filters[key]
  );
  return axios.put(updateStateReceiptUrl(receiptId), form);
};

export const deleteReceipt = (receiptId: string) =>
  axios.delete(deleteReceiptUrl(receiptId));

export const massExportReceipts = (
  data: MassExportReceiptsDtoExclude | MassExportReceiptsDtoInclude
) => {
  Object.keys(data.filters).forEach(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    (key) => !data.filters[key] && delete data.filters[key]
  );
  return axios.put(massExportReceiptsUrl(), data);
};

export const singleExportReceipt = (id: string) =>
  axios.put(singleExportReceiptUrl(id));

export const receiptsColumns = (
  {
    allSelected,
    setAllSelected,
    excludeReceipts,
    setExcludeReceipts,
    includeReceipts,
    setIncludeReceipts,
  }: any,
  lang: Lang
) => {
  // TODO
  return [
    {
      Header: (
        <input
          type="checkbox"
          checked={allSelected}
          onClick={() => {
            setAllSelected((state: any) => !state);
            setExcludeReceipts([]);
            setIncludeReceipts([]);
          }}
          className="text-primary"
        />
      ),
      accessor: "col1",
      width: "30",
      disableSortBy: true,
    },
    {
      Header: lang === "en" ? "year" : "anno",
      accessor: "col2",
      width: "150",
      id: "receipt_year",
    },
    {
      Header: lang === "en" ? "date" : "data",
      accessor: "col3",
      width: "150",
      id: "receipt_date",
    },
    {
      Header: lang === "en" ? "number" : "numero",
      accessor: "col4",
      width: "150",
      id: "progressive_id",
    },
    {
      Header: lang === "en" ? "user" : "utente",
      accessor: "col5",
      width: "200",
      id: "user_id",
    },
    {
      Header: lang === "en" ? "total amount" : "importo",
      accessor: "col6",
      width: "150",
      id: "total_amount",
    },
    {
      Header: lang === "en" ? "created from" : "creato da",
      accessor: "col7",
      width: "150",
      id: "creator",
    },
    {
      Header: lang === "en" ? "modified date" : "data modificata",
      accessor: "col8",
      width: "150",
      id: "updated_at",
    },
    {
      Header: lang === "en" ? "last modified" : "ultima modifica",
      accessor: "col9",
      width: "250",
      id: "last_modifier",
    },
    {
      Header: lang === "en" ? "state" : "stato",
      accessor: "col10",
      width: "150",
      id: "status",
    },
    {
      Header: lang === "en" ? "actions" : "azioni",
      accessor: "col11",
      width: "150",
      disableSortBy: true,
    },
  ];
};

export const receiptsDetailsColumns = (lang: Lang) => {
  return [
    {
      Header: "",
      accessor: "select",
      width: "200",
      disabledSortBy: true,
    },
    {
      Header: lang === "en" ? "title" : "titolo",
      accessor: "col1",
      width: "150",
      id: "product",
    },
    {
      Header: lang === "en" ? "description" : "descrizione",
      accessor: "col2",
      width: "150",
      id: "date",
    },
    {
      Header: lang === "en" ? "quantity" : "quantità",
      accessor: "col3",
      width: "150",
      id: "billing_user",
    },
    {
      Header: lang === "en" ? "unit price" : "prezzo unitario",
      accessor: "col4",
      width: "150",
      id: "total_amount",
    },
    {
      Header: lang === "en" ? "vat %" : "iva %",
      accessor: "col5",
      width: "150",
      id: "created_from",
    },
    {
      Header: lang === "en" ? "unit price + vat" : "prezzo unitario + vat",
      accessor: "col6",
      width: "150",
      id: "creation_date",
    },
    {
      Header: lang === "en" ? "total" : "totale",
      accessor: "col7",
      width: "200",
      id: "last_modified",
    },
    {
      Header: lang === "en" ? "Actions" : "Azioni",
      accessor: "col8",
      width: "150",
      disableSortBy: true,
    },
  ];
};
