import { AboutUs } from "shared/interfaces/AboutUs.interface";

export interface AboutUsState {
  data: {
    message: string;
    data: AboutUs | null;
  };
  loading: boolean;
}

export const aboutUsInitialState: AboutUsState = {
  data: {
    message: "",
    data: null,
  },
  loading: false,
};
