import React, { useEffect, useMemo, useState } from "react";
import { selectRegions } from "store/lookups/lookupsReducer";
import { useAppDispatch } from "store/storeHooks";
import {
  getUserRanksThunk,
  selectUserRanks,
} from "store/userRanksStore/userRanksReducer";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { selectTranslationLanguage } from "store/translationStore/translationReducer";
import Message from "shared/components/UI/Message";
import Select from "react-select";
import { selectMembershipStatuses } from "store/usersStore/usersReducer";
import GoBack from "shared/components/UI/GoBack";
import { CMS_BE_URL } from "api/urls";
import DatePicker from "react-datepicker";
import axios from "axios";
import NewValues from "shared/components/MassUpdate/NewValues";
import Overlay from "shared/components/UI/Overlay";
import Spinner from "shared/components/UI/Spinner";
import { Column } from "react-table";
import { getExportUsersColumns } from "api/Users/users.api";
import { User, UserLowercase } from "shared/interfaces/User.interface";
import { format } from "date-fns";
import * as XLSX from "xlsx";
import { getReactSelectStyles } from "utils/utils";

const MassUpdate = () => {
  const [loading, setLoading] = useState(false);
  const [regionsOptions, setRegionsOptions] = useState<any>([]);
  const [statusesOptions, setStatusesOptions] = useState<any>([]);
  const [ranksOptions, setRankOptions] = useState<any>([]);
  const [excludeCounselors, setExcludeCounselors] = useState(false);
  const [totalUsers, setTotalUsers] = useState<number>(0);
  const membershipStatuses = selectMembershipStatuses();

  //Date ranges
  const [startDate1, setStartDate1] = useState<Date | null>();
  const [startDate2, setStartDate2] = useState<Date | null>();
  const [endDate1, setEndDate1] = useState<Date | null>();
  const [endDate2, setEndDate2] = useState<Date | null>();

  const regions = selectRegions();
  const userRanks = selectUserRanks();
  const dispatch = useAppDispatch();
  const { t } = useTranslation("common");
  const lang = selectTranslationLanguage();

  //Set up the values on page load
  useEffect(() => {
    dispatch(getUserRanksThunk({ pageSize: 99 })).then((res) => {
      if (res.meta.requestStatus === "rejected") {
        toast.error(
          Message({
            action: "read",
            entity: t("user.rank"),
            error: t("genericError"),
            lang,
          })
        );
      }
      return res;
    });
  }, []);

  useEffect(() => {
    if (userRanks.data.userRanks)
      setRankOptions(
        userRanks.data.userRanks?.map((r: any) => ({
          value: r.id,
          label: lang === "en" ? r.rank_eng : r.rank_it,
        }))
      );
  }, [userRanks.data.userRanks]);

  useEffect(() => {
    const regionsMap = regions.map((region) => ({
      value: region.PK_REGION,
      label: region.PK_REGION,
    }));
    setRegionsOptions(regionsMap);
  }, [regions]);

  useEffect(() => {
    const memberShipStatusesMap = membershipStatuses.map((stat) => ({
      value: stat,
      label: stat.replace("_", " "),
    }));
    setStatusesOptions(memberShipStatusesMap);
  }, [membershipStatuses]);

  //USER SELECTED VALUES
  const [selectedRegions, setSelectedRegions] = useState<any>([]);
  const [selectedRanks, setSelectedRanks] = useState<any>([]);
  const [selectedStatuses, setSelectedStatuses] = useState<any>([]);

  const getURL = () => {
    let getUsersURL = `${CMS_BE_URL}/users`;
    let regionURL = "";
    let rankURL = "";
    let statusURL = "";
    let subStartDateFrom = null;
    let subStartDateTo = null;
    let subEndDateFrom = null;
    let subEndDateTo = null;

    //Check if user has selected any values then construct the URL
    if (selectedRegions.length > 0) {
      regionURL = "location=";
      selectedRegions.map(
        (region: any) => (regionURL = regionURL + `${region.value}%2C`)
      );
    }
    if (selectedRanks.length > 0) {
      rankURL = "rank=";
      selectedRanks.map(
        (rank: any) => (rankURL = rankURL + `${rank.value}%2C`)
      );
    }
    if (selectedStatuses.length > 0) {
      statusURL = "memberStatus=";
      selectedStatuses.map(
        (status: any) =>
          (statusURL = statusURL + `${status.value.toUpperCase()}%2C`)
      );
    }
    if (startDate1) {
      subStartDateFrom =
        startDate1.getFullYear() +
        "/" +
        (startDate1.getMonth() + 1) +
        "/" +
        (startDate1.getDate() + 1);
    }
    if (startDate2) {
      subStartDateTo =
        startDate2.getFullYear() +
        "/" +
        (startDate2.getMonth() + 1) +
        "/" +
        (startDate2.getDate() + 1);
    }
    if (endDate1) {
      subEndDateFrom =
        endDate1.getFullYear() +
        "/" +
        (endDate1.getMonth() + 1) +
        "/" +
        (endDate1.getDate() + 1);
    }
    if (endDate2) {
      subEndDateTo =
        endDate2.getFullYear() +
        "/" +
        (endDate2.getMonth() + 1) +
        "/" +
        (endDate2.getDate() + 1);
    }
    getUsersURL =
      getUsersURL +
      "?" +
      (regionURL !== "" ? regionURL + "&" : "") +
      (rankURL !== "" ? rankURL + "&" : "") +
      (statusURL !== "" ? statusURL + "&" : "") +
      (excludeCounselors
        ? "excludingCounselors=true&"
        : "excludingCounselors=false&") +
      (subStartDateFrom ? "subStartDateFrom=" + subStartDateFrom + "&" : "") +
      (subStartDateTo ? "subStartDateTo=" + subStartDateTo + "&" : "") +
      (subEndDateFrom ? "subEndDateFrom=" + subEndDateFrom + "&" : "") +
      (subEndDateTo ? "subEndDateTo=" + subEndDateTo + "&" : "");
    return getUsersURL;
  };

  const getUsers = async () => {
    setLoading(true);
    const getUsersURL = getURL();

    const res = await axios.get(getUsersURL);
    if (res.data.total <= 0)
      toast.error("There are no users matching the criteria.");
    setTotalUsers(res.data.total);
    setLoading(false);
  };

  const columns: Array<Column<Record<string, any>>> = useMemo(
    () => getExportUsersColumns(lang),
    [lang]
  );

  async function exportData() {
    setLoading(true);
    const getUsersURL = getURL();
    const res = await axios
      .get(getUsersURL + `&pageSize=${totalUsers}`)
      .then((res) => {
        const users = res.data.users;
        const header = columns.map((column) =>
          String(column.Header).toUpperCase()
        );
        const csvData: any = users.map((user: UserLowercase) => [
          `${user.name} ${user.surname}`,
          user.address_details[0]?.fk_region
            ? user.address_details[0]?.fk_region
            : "",
          user.rank.rank_it,
          user.status,
          user?.subscription?.end_date
            ? format(new Date(user.subscription.end_date), "dd/MM/yyyy")
            : "",
          user.role.role_name,
          user.member_status,
          user.email ? user.email : "",
          user?.birth_date?.slice(0, 10),
          user.card_number,
          user?.first_sub_date,
        ]);
        const data: any = {
          data: [header, ...csvData],
        };
        const worksheet = XLSX.utils.aoa_to_sheet(data.data);
        const new_workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(new_workbook, worksheet, "SheetJS");
        // const filters = getValues("filters");
        XLSX.writeFile(new_workbook, `AllUsers.xlsx`);
      })
      .catch((err: any) => {
        if (err.response?.data?.errors?.[0]?.message) {
          toast.error(err.response?.data?.errors?.[0]?.message);
        } else {
          toast.error(err.response?.data?.message);
        }
      })
      .finally(() => setLoading(false));
  }

  return (
    <Overlay active={loading} spinner={<Spinner />}>
      <div className="p-10 pt-0 w-full">
        {/* <GoBack uri="" /> */}
        <button type="button" onClick={exportData}></button>
        <h1 className="my-5 text-3xl font-bold">{t("massUpdate.h1")}</h1>
        <h2 className="mb-5 text-global-subHeader text-xl">
          {t("massUpdate.h2")}
        </h2>
        <div className="mb-2 font-semibold">
          {t("massUpdate.selectFilters")}
        </div>
        <div className="flex gap-10 mb-5">
          <div className="w-1/3">
            {t("massUpdate.selectStatus")}
            <Select
              styles={getReactSelectStyles(false)}
              closeMenuOnSelect={false}
              isMulti
              placeholder="Status"
              value={selectedStatuses}
              options={statusesOptions}
              onChange={(value: any) => setSelectedStatuses(value)}
            />
          </div>
          <div className="w-1/3">
            {t("massUpdate.selectRegions")}
            <Select
              styles={getReactSelectStyles(false)}
              closeMenuOnSelect={false}
              isMulti
              placeholder="Regions"
              value={selectedRegions}
              options={regionsOptions}
              onChange={(value: any) => setSelectedRegions(value)}
            />
          </div>
          <div className="w-1/3">
            {t("massUpdate.selectRanks")}
            <Select
              styles={getReactSelectStyles(false)}
              closeMenuOnSelect={false}
              isMulti
              placeholder="Ranks"
              value={selectedRanks}
              options={ranksOptions}
              onChange={(value: any) => setSelectedRanks(value)}
            />
          </div>
        </div>
        <div>
          <p className="mb-2 font-bold">{t("massUpdate.sd")}:</p>
          <div className="flex gap-10">
            <div>
              <p className="mb-2 font-medium">{t("subs.startDate")}</p>
              <p className="mb-1">{t("massUpdate.from")}: </p>
              <div>
                <DatePicker
                  selected={startDate1}
                  onChange={(e) => setStartDate1(e)}
                  dateFormat="dd/MM/yyyy"
                  className="border border-gray-300"
                  showPopperArrow={false}
                  autoComplete="off"
                />
              </div>
              <p className="my-1">{t("massUpdate.to")}: </p>
              <div>
                <DatePicker
                  selected={startDate2}
                  onChange={(e) => setStartDate2(e)}
                  dateFormat="dd/MM/yyyy"
                  className="border border-gray-300"
                  showPopperArrow={false}
                  autoComplete="off"
                />
              </div>
            </div>
            <div>
              <p className="mb-2 font-medium">{t("subs.endDate")}</p>
              <p className="mb-1">{t("massUpdate.from")}:</p>
              <div>
                <DatePicker
                  selected={endDate1}
                  onChange={(e) => setEndDate1(e)}
                  dateFormat="dd/MM/yyyy"
                  className="border border-gray-300"
                  showPopperArrow={false}
                  autoComplete="off"
                />
              </div>
              <p className="my-1">{t("massUpdate.to")}: </p>
              <div>
                <DatePicker
                  selected={endDate2}
                  onChange={(e) => setEndDate2(e)}
                  dateFormat="dd/MM/yyyy"
                  className="border border-gray-300"
                  showPopperArrow={false}
                  autoComplete="off"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mt-5">
          <label className="flex gap-2 items-center">
            {t("massUpdate.exclude")}
            <input
              type="checkbox"
              onChange={() => setExcludeCounselors(!excludeCounselors)}
            />
          </label>
        </div>
        <div className="mt-5">
          <button
            onClick={getUsers}
            className="px-4 py-2 text-white bg-users-create"
            type="button"
          >
            {t("massUpdate.getUsers")}
          </button>
        </div>
        {totalUsers > 0 && (
          <>
            <div className="mb-4 mt-10 px-2 py-1 bg-gray-200">
              {t("massUpdate.p1")}
              {totalUsers} {t("massUpdate.p2")}
            </div>
            <NewValues
              subStartDateFrom={startDate1}
              subStartDateTo={startDate2}
              subEndDateFrom={endDate1}
              subEndDateTo={endDate2}
              excludeCounselors={excludeCounselors}
              setLoading={setLoading}
              selectedRegions={selectedRegions}
              selectedRanks={selectedRanks}
              selectedStatuses={selectedStatuses}
              statusesOptions={statusesOptions}
              ranksOptions={ranksOptions}
            />
            <button
              className="absolute right-0 mr-10 mt-20 px-4 py-2 text-white bg-users-create"
              type="button"
              onClick={exportData}
            >
              {t("massUpdate.exportUsers")}
            </button>
          </>
        )}
      </div>
    </Overlay>
  );
};

export default MassUpdate;
