import { UploadIcon } from "@heroicons/react/solid";
import React, { useEffect, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import {
  UseFormRegister,
  UseFormSetError,
  UseFormSetValue,
  UseFormTrigger,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { getMediaDropzoneStyles } from "utils/utils";

export default function DocumentDropzone({
  trigger,
  setValue,
  errors,
  reactHookFormName,
  setError,
  register,
  required,
}: {
  trigger: UseFormTrigger<any>;
  setValue: UseFormSetValue<any>;
  errors?: any;
  reactHookFormName: string;
  setError: UseFormSetError<any>;
  register?: UseFormRegister<any>;
  required?: boolean;
}) {
  const { t } = useTranslation("common");
  const onDrop = useCallback((files) => {
    setValue(reactHookFormName, files[0]);
    trigger(reactHookFormName);
  }, []);

  const { getRootProps, getInputProps, isDragActive, fileRejections } =
    useDropzone({
      onDrop,
      multiple: false,
      accept: "application/pdf,application/msword,application/docx",
    });

  useEffect(() => {
    if (fileRejections.length) {
      if (setError) {
        setError(reactHookFormName, {
          type: "manual",
          message: t("documentDropzone.type"),
        });
      }
    }
  }, [fileRejections]);

  if (required) {
    useEffect(() => {
      if (register) {
        register(reactHookFormName, {
          required: {
            value: true,
            message: t("documentDropzone.required"),
          },
        });
      }
    }, []);
  }

  return (
    <div
      {...getRootProps()}
      className={getMediaDropzoneStyles(errors[reactHookFormName]?.message)}
    >
      <input id={reactHookFormName} {...getInputProps()} />
      <div className="font-xs text-center">
        {t("documentDropzone.maxSize")}: 50MB
      </div>
      <UploadIcon className="w-20 h-20" />
      {isDragActive ? (
        <p>{`${t("documentDropzone.drop")}...`}</p>
      ) : (
        <p>{t("documentDropzone.choose")}</p>
      )}
    </div>
  );
}
