import axios from "axios";
import { CreateAboutUs } from "shared/interfaces/AboutUs.interface";
import {
  createAboutUsURL,
  deleteAboutUsURL,
  getAboutUsURL,
  updateAboutUsURL,
} from "./urls";

export function createAboutUs({ data }: { data: CreateAboutUs }) {
  const formData = new FormData();
  Object.entries(data).map(([key, value]: any) => {
    formData.append(key, value);
  });
  return axios.post(createAboutUsURL(), formData);
}

export function deleteAboutUs({ aboutId }: { aboutId: string }) {
  return axios.delete(deleteAboutUsURL({ aboutId }));
}

export function getAboutUs({ aboutId }: { aboutId: string }) {
  return axios.get(getAboutUsURL({ aboutId }));
}

export function updateAboutUs({
  aboutId,
  data,
}: {
  aboutId: string;
  data: CreateAboutUs;
}) {
  const formData = new FormData();
  Object.entries(data).map(([key, value]: any) => {
    formData.append(key, value);
  });
  return axios.put(updateAboutUsURL({ aboutId }), formData);
}
