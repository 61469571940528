import axios from "axios";
import { Lang } from "shared/components/UI/Message";
import {
  CreateCouponDto,
  UpdateCouponDto,
} from "shared/interfaces/Coupon.interface";
import {
  createCouponURL,
  constructGetCouponsURL,
  constructGetCouponURL,
  constructUpdateCouponURL,
  constructDeleteCouponURL,
  GetCouponsFilters,
} from "./urls";

export const createCoupon = (createCouponDto: CreateCouponDto) =>
  axios.post(createCouponURL, createCouponDto);

export const getCoupons = (filters: GetCouponsFilters) =>
  axios.get(constructGetCouponsURL(filters));

export const getCoupon = (couponId: string) =>
  axios.get(constructGetCouponURL(couponId));

export const updateCoupon = (
  couponId: string,
  updateCouponDto: UpdateCouponDto
) => axios.put(constructUpdateCouponURL(couponId), updateCouponDto);

export const deleteCoupon = (couponId: string) =>
  axios.delete(constructDeleteCouponURL(couponId));

export const getCouponsColumns: (lang: Lang) => {
  Header: string;
  accessor: string;
  width: string;
  id: string;
  disableSortBy?: boolean;
}[] = (lang: Lang) => {
  return [
    {
      Header: lang === "en" ? "name" : "nome",
      accessor: "col1",
      width: "150",
      id: "code",
    },
    {
      Header: lang === "en" ? "value" : "valore",
      accessor: "col2",
      width: "200",
      id: "amount",
    },
    {
      Header: lang === "en" ? "amount" : "quantita",
      accessor: "col3",
      width: "200",
      id: "uses",
    },
    {
      Header: lang === "en" ? "expiry date" : "data di scadenza",
      accessor: "col4",
      width: "200",
      id: "expiry_date",
    },
    {
      Header: lang === "en" ? "actions" : "azioni",
      accessor: "col5",
      width: "150",
      id: "actions",
      disableSortBy: true,
    },
  ];
};
