export interface SponsorsState {
  data: {
    total: number;
    pageIndex: number;
    data: [];
  };
  filters: {
    roles: string[];
    regions: string[];
    searchString: "";
  };
  loading: boolean;
}

export const sponsorsInitialState: SponsorsState = {
  data: {
    total: 0,
    pageIndex: 0,
    data: [],
  },
  filters: {
    roles: [],
    regions: [],
    searchString: "",
  },
  loading: false,
};
