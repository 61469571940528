import { CMS_BE_URL } from "api/urls";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { selectTranslationLanguage } from "store/translationStore/translationReducer";
import { toast } from "react-toastify";
import Message from "shared/components/UI/Message";
import { ErrorMessage } from "@hookform/error-message";
import { getInputStyles } from "utils/utils";

type CardNumberForm = {
  cardNumber: string;
};

const CARD_NUMBER_API_URL = CMS_BE_URL + "/globalVariables/5";

const CardNumber = () => {
  const lang = selectTranslationLanguage();
  const { t } = useTranslation("common");
  const [loading, setLoading] = useState(false);

  const form = useForm<CardNumberForm>({
    mode: "onChange",
    defaultValues: {
      cardNumber: "",
    },
  });

  useEffect(() => {
    setLoading(true);
    const getData = async () => {
      try {
        const res = await axios.get(CARD_NUMBER_API_URL);
        if (res.status !== 200) return;
        form.setValue("cardNumber", res.data.variable.value, {
          shouldValidate: true,
        });
      } catch (err: any) {
        if (err.response?.data?.errors?.[0]?.message) {
          toast.error(err.response?.data?.errors?.[0]?.message);
        } else {
          toast.error(err.response?.data?.message);
        }
      }
    };
    getData();
    setLoading(false);
  }, []);

  const onSubmit = form.handleSubmit(async (data) => {
    try {
      const res = await axios.put(CARD_NUMBER_API_URL, {
        value: data.cardNumber,
      });
      if (res.status !== 200) return;
      toast.success(
        Message({
          action: "update",
          entity: t("fieldsDomain.cardNumber"),
          lang,
        })
      );
    } catch (err: any) {
      if (err.response?.data?.errors?.[0]?.message) {
        toast.error(err.response?.data?.errors?.[0]?.message);
      } else {
        toast.error(err.response?.data?.message);
      }
    }
  });

  return (
    <form className="flex flex-col gap-2" onSubmit={onSubmit}>
      <label htmlFor="facebookUrl" className="text-xl font-semibold">
        {t("fieldsDomain.cardNumber")}
      </label>
      <div className="flex flex-grow gap-5">
        <input
          id="cardNumber"
          type="text"
          {...form.register("cardNumber", { required: true, minLength: 6 })}
          placeholder={loading ? "Loading..." : t("fieldsDomain.cardNumber")}
          className={getInputStyles(!!form.formState.errors.cardNumber)}
        />
        <button
          type="submit"
          className="px-4 py-2 text-white bg-primary border border-primary rounded-sm"
        >
          {t("crud.update")}
        </button>
      </div>
      <ErrorMessage
        errors={form.formState.errors}
        name="cardNumber"
        render={() => (
          <p className="text-red-500 text-xs">
            {t("cardNumber.validationError")}
          </p>
        )}
      />
    </form>
  );
};

export default CardNumber;
