import { Popover, Transition } from "@headlessui/react";
import { UserCircleIcon } from "@heroicons/react/solid";
import { useTranslation } from "react-i18next";
import React from "react";
import Language from "./Language";
import { Link, useNavigate } from "react-router-dom";
import useAuth from "shared/hooks/useAuth";
import { useAppSelector } from "store/storeHooks";

export default function UserPopup() {
  const loggedInUser = useAppSelector((state) => state.auth.user);
  const { t } = useTranslation("common");
  const { logout } = useAuth();
  const navigate = useNavigate();
  const handleLogout = () => {
    logout();
    navigate("/");
  };
  return (
    <div className="absolute z-10">
      <Popover>
        {({ open, close }) => (
          <div className="relative flex items-center">
            <Popover.Button>
              {loggedInUser?.PROFILE_PIC_URL ? (
                <img
                  className="w-6 2xl:w-8 h-6 2xl:h-8 rounded-full object-cover"
                  src={loggedInUser?.PROFILE_PIC_URL}
                  alt=""
                />
              ) : (
                <UserCircleIcon className="w-6 2xl:w-8 h-6 2xl:h-8 text-white rounded-full" />
              )}
            </Popover.Button>
            <Transition
              as="div"
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="sidebar__popup absolute z-10 left-0 w-64 bg-sidebar-popup-bg border-0 rounded-sm">
                <div className="flex gap-2 items-center justify-between p-5">
                  <Language />
                  <button
                    onClick={handleLogout}
                    className="text-white whitespace-nowrap text-xs font-medium"
                  >
                    {t("sidebar.logOut")}
                  </button>
                </div>
              </Popover.Panel>
            </Transition>
          </div>
        )}
      </Popover>
    </div>
  );
}
