import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";

export type PortalType<T> = {
  open: boolean;
  top: number;
  left: number;
  entity: T | null;
};

export default function TablePopup({
  isOpen,
  close,
  children,
}: {
  isOpen: boolean;
  close: () => void;
  children: React.ReactNode;
}) {
  const wrapperRef: React.RefObject<HTMLDivElement> = useRef(null);
  useEffect(
    function useClickOutsideObserver() {
      function handleClickOutside(event: globalThis.MouseEvent) {
        if (
          wrapperRef.current &&
          !wrapperRef.current.contains(event.target as Node)
        ) {
          close();
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return function removeEventListenerOnUnmount() {
        return document.removeEventListener("mousedown", handleClickOutside);
      };
    },
    [wrapperRef]
  );
  if (!isOpen) return null;
  return ReactDOM.createPortal(
    <div ref={wrapperRef} onClick={() => close()}>
      {children}
    </div>,
    document.getElementById("portal") as HTMLElement
  );
}
