import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { useAppDispatch } from "store/storeHooks";
import { getTagsThunk, selectTags } from "store/tagsStore/tagsReducer";
import {
  getNewThunk,
  selectNews,
  updateNewThunk,
} from "store/newsStore/newsReducer";
import {
  CreateNew,
  featuredOptions,
  New,
} from "shared/interfaces/New.interface";
import { useParams } from "react-router";
import { CreateLocation } from "shared/interfaces/Location.interface";
import { visibilityOptions } from "shared/interfaces/Visibility.interface";
import {
  getInputStyles,
  getReactSelectStyles,
  stringToFile,
} from "utils/utils";
import LocationSearch from "shared/components/UI/LocationSearch";
import { PostFormType } from "./types";
import GoBack from "shared/components/UI/GoBack";
import DisplayInputError from "shared/components/UI/DisplayInputError";
import Spinner from "shared/components/UI/Spinner";
import Overlay from "shared/components/UI/Overlay";
import { toast } from "react-toastify";
import Message from "shared/components/UI/Message";
import EditImage from "shared/components/UI/EditImage";
import Creatable from "react-select/creatable";
import EmailEditor from "react-email-editor";
import axios from "axios";
import { CMS_BE_URL } from "api/urls";
import SimpleImageDropzone from "shared/components/UI/SimpleImageDropzone";
import { selectTranslationLanguage } from "store/translationStore/translationReducer";
import { PermissionDomain } from "shared/interfaces/Permission.interface";
import { RolePermissionCrudAction } from "shared/interfaces/RolePermission.interface";
import { selectLoggedInUser } from "store/authStore/authReducer";
import { filter } from "ramda";
import CropImage from "shared/components/UI/CropImage";

export default function CreateEventPage() {
  const lang = selectTranslationLanguage();
  const { t } = useTranslation("common");
  const { id } = useParams();
  const [tagsOptions, setTagsOptions] = useState<any[]>([]);
  const [editImageOn, setEditImageOn] = useState(false);
  const loggedInUser = selectLoggedInUser();

  const [richtext, setRichtext] = useState("");
  const [json, setJson] = useState<any>("");

  const form = useForm<PostFormType>({
    mode: "onChange",
    defaultValues: {
      is_visible: visibilityOptions[0],
      title: "",
      author: "",
      richtext: null,
      styles_json: "",
      tags: [],
      short_description: "",
      location: {
        country: undefined,
        city: undefined,
        province: undefined,
        region: undefined,
        postal_code: undefined,
        street_name: undefined,
        street_number: undefined,
        longitude: undefined,
        latitude: undefined,
      },
      featured: featuredOptions[0],
      fileType: "news_pic",
      thumbnail: null,
      media: null,
    },
  });
  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    setError,
    getValues,
    formState: { errors },
    control,
    watch,
    clearErrors,
  } = form;

  function constructCreateNew(data: PostFormType) {
    const post: CreateNew = {} as CreateNew;
    post.fileType = data.fileType;
    if (data.is_visible.value !== "") post.is_visible = data.is_visible.value;
    if (data.title !== "") post.title = data.title;
    if (data.short_description !== "")
      post.short_description = data.short_description;
    if (data.tags.length) {
      post.tags = JSON.stringify(data.tags.map((tag) => tag.value));
    }
    post.richtext = stringToFile(richtext);
    post.styles_json = JSON.stringify(json);
    const tempLocationObject: CreateLocation = {
      street_name: data.location.street_name,
      street_number: data.location.street_number,
      postal_code: data.location.postal_code,
      city: data.location.city,
      province: data.location.province,
      region: data.location.region,
      country: data.location.country,
    } as CreateLocation;
    if (data.location.longitude)
      tempLocationObject["longitude"] = data.location.longitude;
    if (data.location.latitude)
      tempLocationObject["latitude"] = data.location.latitude;
    post.location = JSON.stringify(tempLocationObject);
    if (data.featured.value !== "") post.featured = data.featured.value;
    if (data.thumbnail && typeof data.thumbnail === "object")
      post.thumbnail = data.thumbnail as File;
    if (data.media) post.media = data.media as File[];
    if (data.author) post.author = data.author;
    return post;
  }

  function canAccess(
    domain: PermissionDomain,
    action: RolePermissionCrudAction,
    post?: New | null
  ): boolean {
    if (loggedInUser.permissions["*"]) {
      return true;
    } else {
      switch (loggedInUser.permissions[domain][action]) {
        case "NONE": {
          return false;
        }
        case "OWN": {
          if (post && post.creator_id === loggedInUser.user?.PK_USER)
            return true;
          return false;
        }
        case "ANY": {
          return true;
        }
        default: {
          return false;
        }
      }
    }
  }

  const onSubmit: SubmitHandler<PostFormType> = function (data) {
    dispatch(
      updateNewThunk({
        postId: String(id),
        post: constructCreateNew(data),
      })
    ).then((res) => {
      if (res.meta.requestStatus === "rejected") {
        toast.error(
          Message({
            action: "update",
            entity: t("new.new"),
            error: t("genericError"),
            lang,
            gender: "female",
          })
        );
      } else {
        toast.success(
          Message({
            action: "update",
            entity: t("new.new"),
            lang,
            gender: "female",
          })
        );
      }
      return res;
    });
  };

  const dispatch = useAppDispatch();
  const tags = selectTags();
  useEffect(() => {
    dispatch(getTagsThunk()).then((res) => {
      if (res.meta.requestStatus === "rejected") {
        toast.error(
          Message({
            action: "read",
            entity: t("news.tags"),
            error: t("genericError"),
            lang,
            gender: "female",
          })
        );
      }
    });
  }, []);
  useEffect(() => {
    const tagsMap = tags.data.tags.map((tag) => {
      return {
        value: tag.pk_tag,
        label: tag.pk_tag,
      };
    });
    setTagsOptions(tagsMap);
  }, [tags]);

  const news = selectNews();
  useEffect(() => {
    dispatch(getNewThunk(String(id))).then((res) => {
      if (res.meta.requestStatus === "rejected") {
        toast.error(
          Message({
            action: "read",
            entity: t("new.new"),
            error: t("genericError"),
            lang,
            gender: "female",
          })
        );
      }
      return res;
    });
  }, []);
  useEffect(() => {
    if (news.data.post?.title) {
      setValue("title", news.data.post.title);
    }

    if (news.data.post) {
      setRichtext(news.data.post.richtext);
      setValue("richtext", news.data.post.richtext);
    }

    if (news.data.post?.short_description) {
      setValue("short_description", news.data.post.short_description);
    }
    if (news.data.post) {
      setValue("styles_json", news.data.post.styles_json);
      emailEditorRef.current.editor.loadDesign(
        JSON.parse(getValues("styles_json"))
      );
      setJson(JSON.parse(news.data.post.styles_json));
    }

    if (news.data.post?.featured) {
      if (news.data.post.featured)
        setValue("featured", { value: "true", label: "featured" });
      else setValue("featured", { value: "false", label: "normal" });
    }
    if (news.data.post?.is_visible) {
      setValue("is_visible", visibilityOptions[0]);
    } else {
      setValue("is_visible", visibilityOptions[1]);
    }
    const tempTags = news.data.post?.tags.map((tag) => ({
      value: tag.pk_tag,
      label: tag.pk_tag,
    }));
    if (tempTags && tempTags.length) {
      setValue("tags", tempTags);
    }
    if (news.data.post?.location.country) {
      setValue("location.country", news.data.post.location.country);
    }
    if (news.data.post?.location.region) {
      setValue("location.region", news.data.post.location.region);
    }
    if (news.data.post?.location.province) {
      setValue("location.province", news.data.post?.location.province);
    }
    if (news.data.post?.location.city) {
      setValue("location.city", news.data.post?.location.city);
    }
    if (news.data.post?.location.postal_code) {
      setValue(
        "location.postal_code",
        Number(news.data.post.location.postal_code)
      );
    }
    if (news.data.post?.location.street_name) {
      setValue("location.street_name", news.data.post?.location.street_name);
    }
    if (news.data.post?.location.street_number) {
      setValue(
        "location.street_number",
        Number(news.data.post.location.street_number)
      );
    }
    if (news.data.post?.location.longitude) {
      setValue(
        "location.longitude",
        Number(news.data.post?.location.longitude)
      );
    }
    if (news.data.post?.location.latitude) {
      setValue("location.latitude", Number(news.data.post.location.latitude));
    }
    if (news.data.post?.author) {
      setValue("author", news.data.post?.author);
    }
    if (news.data.post?.thumbnail) {
      setValue("thumbnail", news.data.post?.thumbnail as any);
    }
  }, [news.data.post]);

  //EMAIL EDITOR
  const emailEditorRef: any = useRef(null);

  const onLoad = useCallback(() => {
    const timer = setInterval(() => {
      if (
        emailEditorRef &&
        emailEditorRef.current &&
        emailEditorRef.current.editor
      ) {
        emailEditorRef.current.editor.loadDesign();
        emailEditorRef.current.editor.addEventListener(
          "design:updated",
          function () {
            // Design is updated by the user

            emailEditorRef.current.editor.exportHtml(function (data: any) {
              const json = data.design; // design json
              const html = data.html; // design html
              setRichtext(html);
              setJson(json);
              // Save the json, or html here
            });
          }
        );

        emailEditorRef.current.editor.registerCallback(
          "image",
          async (file: any, done: any) => {
            const formD = new FormData();
            formD.append("fileType", "newsletter_doc");
            formD.append("files", file.attachments[0]);

            const res = await axios.post(
              `${CMS_BE_URL}/files/uploadFile`,
              formD,
              {
                headers: {
                  "Content-Type":
                    "multipart/form-data; boundary=<calculated when request is sent>",
                },
              }
            );
            const url = res.data.urls[0];

            done({ progress: 100, url });
          }
        );

        clearInterval(timer);
      }
    }, 500);
  }, [emailEditorRef]);

  return (
    <Overlay active={news.loading} spinner={<Spinner />}>
      <FormProvider {...form}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col flex-grow gap-5 p-10 h-full bg-gray-100"
        >
          <GoBack uri="news" />
          <div className="flex items-center justify-between w-full">
            <div className="flex gap-5 items-center">
              <h1 className="text-3xl font-bold">{t("news.new")}</h1>
              <p className="text-global-subHeader text-xl"></p>
            </div>
            <div className="flex gap-5 items-center">
              <label
                htmlFor="featured"
                className="block whitespace-nowrap text-lg font-medium"
              >
                {t("new.featured")} *
              </label>
              <Controller
                name="featured"
                control={control}
                render={({ field }) => (
                  <Select
                    styles={getReactSelectStyles(false)}
                    inputId="featured"
                    options={featuredOptions}
                    value={field.value}
                    onChange={(e) => field.onChange(e)}
                    isSearchable={false}
                  />
                )}
              />
              <label
                htmlFor="is_visible"
                className="block whitespace-nowrap text-lg font-medium"
              >
                {t("new.visible")} *
              </label>
              <Controller
                name="is_visible"
                control={control}
                render={({ field }) => {
                  return (
                    <Select
                      styles={getReactSelectStyles(false)}
                      inputId="is_visible"
                      options={visibilityOptions}
                      value={field.value}
                      onChange={(e) => field.onChange(e)}
                      isSearchable={false}
                    />
                  );
                }}
              />
              <button
                type="submit"
                disabled={!canAccess("news", "UPDATE", news.data.post)}
                className={`px-4 py-2 text-white bg-global-save border rounded-sm ${
                  canAccess("news", "UPDATE", news.data.post)
                    ? ""
                    : "cursor-not-allowed"
                }`}
              >
                {t("new.save")}
              </button>
            </div>
          </div>
          <div className="grid flex-grow gap-5 grid-cols-2">
            <div className="flex flex-col flex-grow gap-2">
              <label htmlFor="title" className="block text-lg font-medium">
                {t("new.title")} *
              </label>
              <input
                id="title"
                type="text"
                placeholder={`${t("new.title")}`}
                {...register("title", {
                  required: `${t("new.post")} ${t("new.title")} ${t(
                    "new.required"
                  )}.`,
                  maxLength: {
                    value: 50,
                    message: `${t("new.post")} ${t("new.title")} ${t(
                      "new.shouldBe"
                    )} ${50} ${t("new.characters")}.`,
                  },
                })}
                className={getInputStyles(!!errors.title?.message)}
              />
              <DisplayInputError message={errors?.title?.message} />
              <label htmlFor="author" className="block text-lg font-medium">
                {t("new.author")} *
              </label>
              <input
                id="author"
                type="text"
                placeholder={t("new.author")}
                {...register("author", {
                  required: `${t("new.author")} ${t("new.required")}.`,
                  maxLength: {
                    value: 30,
                    message: `${t("new.author")} ${t("new.shouldBe")} ${30} ${t(
                      "new.characters"
                    )}.`,
                  },
                })}
                className={getInputStyles(!!errors.title?.message)}
              />
              <DisplayInputError message={errors.author?.message} />
              <label htmlFor="title" className="block text-lg font-medium">
                {t("new.shortDescription")} *
              </label>
              <input
                id="title"
                type="text"
                placeholder={t("new.shortDescription")}
                {...register("short_description", {
                  required: `${t("new.shortDescription")} ${t("new.required")}`,
                  maxLength: {
                    value: 250,
                    message: `${t("new.shortDescription")} ${t(
                      "new.shouldBe"
                    )} ${250} ${t("new.characters")}.`,
                  },
                })}
                className={getInputStyles(!!errors.short_description?.message)}
                autoComplete="off"
              />
              <DisplayInputError message={errors.short_description?.message} />
              <label htmlFor="tags" className="block text-lg font-medium">
                {t("new.tags")} *
              </label>
              <Controller
                name="tags"
                control={control}
                rules={{
                  required: true,
                  validate: {
                    maxLength(e) {
                      if (filter((tag) => tag.value.length > 50, e).length)
                        return `${t("new.tags")} ${t("new.shouldBe")} ${50} ${t(
                          "new.characters"
                        )}.`;
                      return true;
                    },
                  },
                }}
                render={({ field }) => (
                  <Creatable
                    placeholder="Select or create tags"
                    styles={getReactSelectStyles(!!errors.tags)}
                    isLoading={tags.loading}
                    inputId="tags"
                    isMulti={true}
                    value={field.value}
                    options={tagsOptions}
                    onChange={(e) => field.onChange(e)}
                  />
                )}
              />
              <DisplayInputError
                message={
                  errors.tags && `${t("news.tags")} ${t("news.required")}`
                }
              />
            </div>
            <div className="flex flex-col gap-2">
              <div className="flex flex-col gap-2">
                <label
                  htmlFor="thumbnail"
                  className="block text-lg font-medium"
                >
                  {t("new.coverPhoto")} *
                </label>
                <Controller
                  name="thumbnail"
                  control={control}
                  rules={{
                    required: news.data.post?.thumbnail
                      ? false
                      : `${t("new.coverPhoto")} ${t("new.required")}.`,
                    validate: {
                      checkSize: (file) => {
                        if (file && file.size > 2 * 1048576)
                          return `${t("new.size")} 2MB`;
                        return true;
                      },
                    },
                  }}
                  render={() => (
                    <SimpleImageDropzone
                      clearErrors={clearErrors}
                      trigger={trigger}
                      setError={setError}
                      setValue={setValue}
                      watch={watch}
                      reactHookFormName="thumbnail"
                      setEditImageOn={setEditImageOn}
                      aspect={16 / 9}
                    />
                  )}
                />
                {editImageOn ? (
                  <CropImage
                    editImageOn={editImageOn}
                    setEditImageOn={setEditImageOn}
                    reactHookFormName="thumbnail"
                    aspect={16 / 9}
                    canForce={true}
                    canClose={true}
                  />
                ) : null}
                <DisplayInputError message={errors.thumbnail?.message} />
              </div>
            </div>
          </div>
          <LocationSearch
            errors={errors}
            setValue={setValue}
            getValues={getValues}
            clearErrors={clearErrors}
            watch={watch}
            register={register}
            reactHookFormName="location"
            required={false}
            displayStar={true}
          />
          <div className="flex flex-col gap-2">
            <label className="block text-lg font-medium">
              {t("new.mainBody")}
            </label>
            <EmailEditor
              minHeight={1000}
              ref={emailEditorRef}
              onLoad={onLoad}
            />
          </div>
        </form>
      </FormProvider>
    </Overlay>
  );
}
