import { CouncilDetails } from "shared/interfaces/Council.interface";

export interface CouncilsState {
  data: {
    message: string;
    total?: number;
    pageIndex?: number;
    data: CouncilDetails | null;
  };
  loading: boolean;
}

export const councilsInitialState: CouncilsState = {
  data: {
    message: "",
    total: 0,
    pageIndex: 0,
    data: null,
  },
  loading: false,
};
