import { Document } from "shared/interfaces/Document.interface";

export interface DocumentsState {
  data: {
    total: number;
    pageIndex: number;
    data: Document[];
  };
  filters: {
    roles: string[];
    regions: string[];
    searchString: "";
  };
  loading: boolean;
}

export const documentsInitialState: DocumentsState = {
  data: {
    total: 0,
    pageIndex: 0,
    data: [],
  },
  filters: {
    roles: [],
    regions: [],
    searchString: "",
  },
  loading: false,
};
