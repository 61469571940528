import { PlusIcon, XIcon } from "@heroicons/react/outline";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Message from "shared/components/UI/Message";
import {
  CouncilMember,
  CreateCouncilDetails,
  CreateCouncilUser,
} from "shared/interfaces/Council.interface";
import { User, UserLowercase } from "shared/interfaces/User.interface";
import {
  getCouncilThunk,
  selectCouncils,
  updateCouncilThunk,
} from "store/councilsStore/councilsReducer";
import { useAppDispatch } from "store/storeHooks";
import SelectUser from "./SelectUser";
import UserModal from "../../shared/components/UI/ScreenModal";
import { selectTranslationLanguage } from "store/translationStore/translationReducer";
import { useTranslation } from "react-i18next";
import { CouncilRank } from "shared/interfaces/Rank.interface";
import { findPhone } from "utils/utils";

type NationalCouncil = {
  name: "nationalCouncil";
  usernames: CreateCouncilUser[];
};

export default function NationalCouncilPage() {
  const dispatch = useAppDispatch();
  const lang = selectTranslationLanguage();
  const [isOpen, setIsOpen] = useState(false);
  const [reactHookFormName, setReactHookFormName] = useState<string>("");
  const [onComplete, setOnComplete] = useState<any>(null);
  const [nationalCouncilMembers, setNationalCouncilMembers] = useState<any>([]);
  const [action, setAction] = useState("");
  const [areMembersFetched, setAreMemberFetched] = useState(false);
  const [defaultUser, setDefaultUser] = useState<CouncilMember | null>(null);
  const { t } = useTranslation("common");

  const { setValue, getValues, handleSubmit } = useForm<NationalCouncil>({
    mode: "onChange",
    defaultValues: {
      name: "nationalCouncil",
      usernames: [],
    },
  });

  useEffect(function getNationalCouncil() {
    dispatch(getCouncilThunk({ council: "nationalCouncil" })).then(
      function handleRequest(res: any) {
        if (res.meta.requestStatus === "rejected") {
          toast.error(
            Message({
              action: "read",
              entity: "National Council",
              error: res.error.message,
              lang,
            })
          );
        }
        setAreMemberFetched(true);
      }
    );
  }, []);

  const councils = selectCouncils();

  useEffect(
    function setNationalCouncil() {
      if (councils.data.data?.users && areMembersFetched) {
        councils.data.data.users.map(function setNationalCouncil(user, i) {
          setValue(`usernames.${i}.pk_user` as any, user.pk_user);
          setValue(`usernames.${i}.rank` as any, user.rank);
          setValue(`usernames.${i}.priority` as any, user.rank.priority);
        });
        setNationalCouncilMembers(councils.data.data.users);
        setAreMemberFetched(false);
      }
    },
    [areMembersFetched]
  );

  function constructUpdateCouncil(data: NationalCouncil) {
    const nationalCouncil: CreateCouncilDetails = {} as CreateCouncilDetails;
    nationalCouncil.name = data.name;
    nationalCouncil.usernames = JSON.stringify(
      data.usernames.map((username) => ({
        ...username,
        rank: (username.rank as CouncilRank).id,
      }))
    );
    return nationalCouncil;
  }

  const onSubmit = handleSubmit(function onSubmit(data) {
    dispatch(
      updateCouncilThunk({
        council: "nationalCouncil",
        data: constructUpdateCouncil(data),
      })
    ).then(function handleRequest(res: any) {
      if (res.meta.requestStatus === "fulfilled") {
        toast.success(
          Message({
            action: "update",
            entity: t("nationalCouncil.nationalCouncil"),
            lang,
          })
        );
        dispatch(getCouncilThunk({ council: "nationalCouncil" })).then(
          function handleRequest(res: any) {
            if (res.meta.requestStatus === "rejected") {
              toast.error(
                Message({
                  action: "read",
                  entity: t("nationalCouncil.nationalCouncil"),
                  error: t("genericError"),
                  lang,
                })
              );
            } else {
              setAreMemberFetched(true);
            }
          }
        );
      } else {
        toast.error(
          Message({
            action: "update",
            entity: t("nationalCouncil.nationalCouncil"),
            error: t("genericError"),
            lang,
          })
        );
      }
    });
  });

  function displayNationalCouncilMember(rank: string, index: number) {
    if (nationalCouncilMembers) {
      return (
        <div className="flex flex-col flex-grow gap-2 justify-between p-4 bg-white border">
          <h1 className="font-medium">
            {nationalCouncilMembers[index].name}{" "}
            {nationalCouncilMembers[index].surname}
          </h1>
          <p>
            {nationalCouncilMembers[index].addressname}{" "}
            {nationalCouncilMembers[index].addressnumber} -{" "}
            {nationalCouncilMembers[index].postalcode}
          </p>
          <p>{nationalCouncilMembers[index].province}</p>
          <p>{nationalCouncilMembers[index].phone}</p>
          <div className="flex gap-5 justify-between">
            <p>{nationalCouncilMembers[index].email}</p>
            <div className="flex gap-2">
              <button
                type="button"
                className="text-green-600"
                onClick={function onClick() {
                  setAction("Update");
                  setDefaultUser(() => nationalCouncilMembers[index]);
                  setReactHookFormName(function () {
                    return `usernames.${index}.`;
                  });
                  setOnComplete(function setOnComplete() {
                    return function (
                      a: UserLowercase,
                      rank: CouncilRank,
                      priority: number
                    ) {
                      setNationalCouncilMembers(
                        function setNationalCouncilMembers(
                          nationalCouncilMembers: any
                        ) {
                          const tempNationalCouncilMembers =
                            nationalCouncilMembers.slice();
                          tempNationalCouncilMembers[index] = {
                            pk_user: a.pk_user,
                            name: a.name,
                            surname: a.surname,
                            email: a.email,
                            addressname: a.address_details[0]?.address_name,
                            addressnumber: a.address_details[0]?.address_number,
                            postalcode: a.address_details[0]?.postal_code,
                            city: a.address_details[0]?.city,
                            province: a.address_details[0]?.province,
                            rank: rank,
                            priority,
                            phone: findPhone(a.contact_details)?.details,
                          };
                          return tempNationalCouncilMembers;
                        }
                      );
                      setDefaultUser(null);
                    };
                  });
                  setIsOpen(function (isOpen) {
                    return !isOpen;
                  });
                }}
              >
                {t("nationalCouncil.edit")}
              </button>
              <button
                type="button"
                onClick={function removeCouncelor() {
                  const tempUsernames = [...getValues("usernames")];
                  tempUsernames.splice(index, 1);
                  setValue("usernames", tempUsernames);
                  setNationalCouncilMembers(function setState(
                    nationalCouncilMembers: any
                  ) {
                    const tempNationalCouncilMembers =
                      nationalCouncilMembers.slice();
                    tempNationalCouncilMembers.splice(index, 1);
                    return tempNationalCouncilMembers;
                  });
                }}
                className="text-red-600"
              >
                {t("nationalCouncil.delete")}
              </button>
            </div>
          </div>
        </div>
      );
    }
  }

  return (
    <div className="flex flex-col h-full">
      <form onSubmit={onSubmit} className="flex flex-col flex-grow gap-5">
        <div className="flex gap-10 justify-between">
          <h1 className="text-2xl font-bold">{t("nationalCouncil.title")}</h1>
          <button type="submit" className="px-4 py-2 text-white bg-global-save">
            {t("nationalCouncil.save")}
          </button>
        </div>
        <UserModal isOpen={isOpen} setIsOpen={setIsOpen}>
          <div
            style={{ width: "50rem" }}
            className="flex flex-col gap-5 p-10 bg-white rounded-md"
          >
            <div className="flex gap-5 justify-between">
              <div className="text-2xl font-bold">
                {action === "Add"
                  ? t("nationalCouncil.create")
                  : t("nationalCouncil.update")}
              </div>
              <button
                type="button"
                onClick={() => {
                  setIsOpen(false);
                  setDefaultUser(null);
                }}
                className="p-0.5 text-white bg-red-600 rounded-md"
              >
                <XIcon className="w-8 h-8" />
              </button>
            </div>
            <SelectUser
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              reactHookFormName={reactHookFormName}
              propGetValues={getValues}
              propSetValue={setValue}
              onComplete={onComplete}
              excludeUsers={nationalCouncilMembers}
              defaultUser={defaultUser}
            />
          </div>
        </UserModal>
        <div className="grid gap-5 grid-cols-3">
          {nationalCouncilMembers?.map(function map(_: any, i: number) {
            return displayNationalCouncilMember(
              t("nationalCouncil.counselor"),
              i
            );
          })}
          <div>
            <button
              type="button"
              className="flex flex-col gap-4 items-center p-4 w-full bg-white border border-dashed"
              onClick={function onClick() {
                setAction("Add");
                setReactHookFormName(function () {
                  const last = nationalCouncilMembers.length;
                  return `usernames.${last}.`;
                });
                setOnComplete(function setOnComplete() {
                  return function (
                    user: UserLowercase,
                    rank: CouncilRank,
                    priority: number
                  ) {
                    setNationalCouncilMembers(
                      function setNationalCouncilMembers(
                        nationalCouncilMembers: any
                      ) {
                        const tempNationalCouncilMembers =
                          nationalCouncilMembers.slice();
                        tempNationalCouncilMembers[
                          nationalCouncilMembers.length
                        ] = {
                          pk_user: user.pk_user,
                          name: user.name,
                          surname: user.surname,
                          email: user.email,
                          addressname: user.address_details[0]?.address_name,
                          addressnumber:
                            user.address_details[0]?.address_number,
                          postalcode: user.address_details[0]?.postal_code,
                          city: user.address_details[0]?.city,
                          province: user.address_details[0]?.province,
                          rank: rank,
                          priority: priority,
                          phone: findPhone(user.contact_details)?.details,
                        };
                        return tempNationalCouncilMembers;
                      }
                    );
                  };
                });
                setIsOpen(function (isOpen) {
                  return !isOpen;
                });
              }}
            >
              <h1 className="text-lg font-medium">
                {t("nationalCouncil.counselor")}
              </h1>
              <PlusIcon className="w-10 h-10" />
              <p>{t("nationalCouncil.addCounselor")}</p>
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
