import React from "react";
import { UseFormRegister } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { getInputStyles } from "utils/utils";
import DisplayInputError from "./DisplayInputError";

export default function CustomButton({
  register,
  errors,
  index,
}: {
  register: UseFormRegister<any>;
  errors: any;
  index: string;
}) {
  const { t } = useTranslation("common");
  return (
    <div className="flex flex-col gap-2">
      <label htmlFor="buttons-title" className="text-lg font-medium">
        {t("buttons.buttonName")} {Number(index) + 1}
      </label>
      <input
        id="buttons-title"
        type="text"
        className={getInputStyles(false)}
        {...register(`buttons.${index}.title`, {
          maxLength: {
            value: 50,
            message: `${t("buttons.buttonName")} ${t(
              "buttons.shouldBe"
            )} ${50} ${t("buttons.characters")}.`,
          },
        })}
      />
      <DisplayInputError
        message={errors.buttons && errors.buttons[index]?.title?.message}
      />
      <label htmlFor="buttons-link" className="text-lg font-medium">
        {t("buttons.directedLink")} {Number(index) + 1}
      </label>
      <input
        id="buttons-link"
        type="text"
        className={getInputStyles(false)}
        {...register(`buttons.${index}.link`, {
          maxLength: {
            value: 250,
            message: `${t("buttons.directedLink")} ${t(
              "buttons.shouldBe"
            )} ${250} ${t("buttons.characters")}.`,
          },
        })}
      />
      <DisplayInputError
        message={errors.buttons && errors.buttons[index]?.link?.message}
      />
      <div className="text-lg font-medium">{t("buttons.visible")}</div>
      <div className="flex gap-5 items-center">
        <input
          id="button-visible"
          type="radio"
          value="1"
          {...register(`buttons.${index}.is_visible`, {})}
        />
        <label htmlFor="button-visible">{t("buttons.yes")}</label>
        <input
          id="button-invisible"
          type="radio"
          value="0"
          defaultChecked={true}
          {...register(`buttons.${index}.is_visible`)}
        />
        <label htmlFor="button-invisible">{t("buttons.no")}</label>
      </div>
    </div>
  );
}
