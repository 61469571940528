import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  CubeIcon,
  DotsVerticalIcon,
  PencilIcon,
  TrashIcon,
  XIcon,
} from "@heroicons/react/outline";
import ScreenModal from "shared/components/UI/ScreenModal";
import SelectProduct from "pages/Orders/SelectProduct";
import {
  Controller,
  FieldError,
  FormProvider,
  useForm,
  UseFormReturn,
} from "react-hook-form";
import GoBack from "shared/components/UI/GoBack";
import { BillingAddress, ShippingAddress } from "api/Orders/orders.api";
import {
  getDisabledInputStyles,
  getInputStyles,
  getReactSelectStyles,
  Round,
} from "utils/utils";
import { useAppDispatch } from "store/storeHooks";
import DisplayInputError from "shared/components/UI/DisplayInputError";
import Select from "react-select";
import Overlay from "shared/components/UI/Overlay";
import Spinner from "shared/components/UI/Spinner";
import { useFlexLayout, useTable } from "react-table";
import Table from "shared/components/Table/Table";
import TablePopup, { PortalType } from "shared/components/UI/TablePopup";
import { getOrderProductsColumns } from "api/Products/products.api";
import { selectTranslationLanguage } from "store/translationStore/translationReducer";
import { selectLoggedInUser } from "store/authStore/authReducer";
import { PermissionDomain } from "shared/interfaces/Permission.interface";
import { RolePermissionCrudAction } from "shared/interfaces/RolePermission.interface";
import {
  registerLocation,
  registerLocationRequired,
  unregisterLocation,
} from "./LocationSearch";
import { useTranslation } from "react-i18next";
import Location from "./Location";
import {
  getOrderThunk,
  updateOrderThunk,
} from "store/ordersStore/ordersReducer";
import { toast } from "react-toastify";
import Message from "shared/components/UI/Message";
import { useParams } from "react-router";
import { Link, useSearchParams } from "react-router-dom";
import {
  paymentStatusOptions,
  paymentTypeOptions,
} from "shared/interfaces/Payment.interface";
import { DevTool } from "@hookform/devtools";
import {
  OrderStatusEnum,
  // EnchancedProduct,
  // FetchedOrderProduct,
  // FetchedProduct,
  // IProduct,
  // OrderStatus,
  orderStatusOptions,
  ProductWithMedia,
} from "shared/interfaces/Orders.interface";
import {
  getPaymentThunk,
  updatePaymentThunk,
} from "store/paymentsStore/paymentsReducer";
import { getProductThunk } from "store/productsStore/productsReducer";
import { CMS_BE_URL } from "api/urls";
import axios from "axios";
import {
  calculateCouponDiscount,
  calculateProductsPrice,
  calculateStripeFees,
  calculateTotalCost,
  constructCreateOrderProducts,
  OrderProduct,
} from "./CreateOrder";
import { Coupon } from "shared/interfaces/Coupon.interface";
import { constructLocationObject } from "./utils";
import { EnchancedProduct } from "shared/interfaces/Product.new.interface";

export type EditOrder = {
  customer_id: string;
  billing_address: Omit<BillingAddress, "region" | "province"> & {
    region: { value: string; label: string };
    province: { value: string; label: string };
  };
  shipping_address: Omit<ShippingAddress, "region" | "province"> & {
    region: { value: string; label: string };
    province: { value: string; label: string };
  };
  products: OrderProduct[];
  coupon: string;
  order_id: string;
  payment_status: {
    value: string;
    label: string;
  };
  fees: number;
  amount: number;
  payment_type: {
    value: string;
    label: string;
  };
  payment_date: string;
  payment_location: string;
  shipping: string;
  status: {
    label: string;
    value: OrderStatusEnum;
  };
  payment_id: string;
  notes?: string;
};

function productsWithMediaToOrderProducts(
  products: EnchancedProduct[],
  orderProducts: ProductWithMedia[]
): OrderProduct[] {
  return products.map((product, i) => {
    return {
      ...product,
      selection: {
        ...orderProducts[i].selection,
        variant: orderProducts[i].variant,
        vat_percentage: orderProducts[i].selection.vat_percentage * 100,
      },
    } as any as OrderProduct;
  });
}

function setFetchedAddress(
  order: any,
  name: "billing_address" | "shipping_address",
  form: UseFormReturn<EditOrder>
) {
  form.setValue(`${name}.city`, order[name].city);
  form.setValue(`${name}.country`, "Italy");
  form.setValue(`${name}.latitude`, order[name].latitude);
  form.setValue(`${name}.longitude`, order[name].longitude);
  form.setValue(`${name}.postal_code`, order[name].postal_code);
  form.setValue(`${name}.province`, {
    label: order[name].province,
    value: order[name].province,
  });
  form.setValue(`${name}.region`, {
    label: order[name].region,
    value: order[name].region,
  });
  form.setValue(`${name}.street_name`, order[name].street_name);
  form.setValue(`${name}.street_number`, order[name].street_number);
}

export default function EditOrder() {
  const { t } = useTranslation("common");
  const lang = selectTranslationLanguage();
  const dispatch = useAppDispatch();
  const [selectProductModalOpen, setSelectProductModalOpen] = useState(false);
  const form = useForm<EditOrder>({
    mode: "onChange",
    defaultValues: {
      products: [],
    },
  });
  const [portal, setPortal] = useState<PortalType<OrderProduct>>({
    open: false,
    top: 0,
    left: 0,
    entity: null,
  });
  const loggedInUser = selectLoggedInUser();
  const [useSameAddresses, setUseSameAddresses] = useState(false);
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const userParam = searchParams.get("user"); // TODO
  const editProduct = useRef<null | OrderProduct>(null);
  const [forceShipping, setForceShipping] = useState(false);
  const [loadingOrder, setLoadingOrder] = useState(false);
  const [coupon, setCoupon] = useState<Coupon>();

  const columns = useMemo(() => getOrderProductsColumns(lang), [lang]);

  const data = useMemo(() => {
    return form.getValues("products").map((prod, i) => {
      return {
        col1: (
          <div className="flex gap-4 items-center">
            <div className="flex">
              {prod.thumbnail ? (
                <img
                  src={prod.thumbnail}
                  className="2xl:w-10 w-8 2xl:h-10 h-8 rounded-full object-cover"
                />
              ) : (
                <CubeIcon className="w-8 h-8" />
              )}
            </div>
            <div
              style={{ width: "calc(100% - 3rem)" }}
              className="flex flex-col"
            >
              <span className="truncate">{prod.selection.variant.title}</span>
              {prod.tags &&
                (prod?.tags?.length === 0 ? (
                  <div>{t("order.noTags")}</div>
                ) : (
                  <div className="flex flex-nowrap gap-2">
                    {prod.tags.map((t, i) => (
                      <span
                        key={i}
                        className="p-1 text-white bg-events-tags rounded-sm truncate"
                      >
                        {t.pk_tag}
                      </span>
                    ))}
                  </div>
                ))}
            </div>
          </div>
        ),
        col2: (
          <div className="flex items-center h-full">
            <span className="truncate">{prod.description}</span>
          </div>
        ),
        col3: (
          <div className="flex items-center h-full">
            <span className="truncate">{prod.selection.price}</span>
          </div>
        ),
        col4: (
          <div className="flex items-center h-full">
            <span className="truncate">{prod.selection.vat_percentage}</span>
          </div>
        ),
        col5: (
          <div className="flex items-center h-full">
            <span className="truncate">{prod.selection.quantity}</span>
          </div>
        ),
        col6: (
          <div className="relative flex items-center h-full">
            <button
              id={`portal-button-${i}`}
              type="button"
              onClick={() => {
                return setPortal((state) => {
                  const thisButton = document.getElementById(
                    `portal-button-${i}`
                  );
                  const position = thisButton?.getBoundingClientRect();
                  return {
                    open: !state.open,
                    top: Number(position?.bottom),
                    left: Number(
                      window.innerWidth - Number(position?.x) <= 150
                        ? Number(position?.left) - 150
                        : Number(position?.left)
                    ),
                    entity: prod,
                  };
                });
              }}
            >
              <DotsVerticalIcon className="w-5 h-5" />
            </button>
          </div>
        ),
      };
    });
  }, [form.watch("products"), lang]);

  const tableInstance = useTable<any>(
    {
      columns,
      data,
    },
    useFlexLayout
  );

  function portalContent() {
    if (portal.entity === null) return;
    return (
      <div
        className="fixed z-50 flex flex-col gap-2 p-2 bg-white rounded-sm shadow-md"
        style={{
          width: "150px",
          top: portal.top,
          left: portal.left,
        }}
      >
        {canAccess("s", "UPDATE", portal.entity) ? (
          <button
            type="button"
            onClick={() => {
              editProduct.current = portal.entity;
              setSelectProductModalOpen(true);
            }}
            className="flex gap-2 items-center text-table-edit"
          >
            <PencilIcon className="w-5 h-5" />
            {lang === "en" ? "Edit" : "Modificare"}
          </button>
        ) : null}
        {canAccess("s", "DELETE", portal.entity) ? (
          <button
            onClick={() => {
              if (portal.entity) {
                handleDeleteProduct(portal.entity.id);
              }
            }}
            className="flex gap-2 items-center text-users-delete"
          >
            <TrashIcon className="w-5 h-5" />
            {lang === "en" ? "Delete" : "Eliminare"}
          </button>
        ) : null}
      </div>
    );
  }

  function canAccess(
    domain: PermissionDomain,
    action: RolePermissionCrudAction,
    product?: OrderProduct
  ): boolean {
    if (loggedInUser.permissions["*"]) {
      return true;
    } else {
      switch (loggedInUser.permissions[domain][action]) {
        case "NONE": {
          return false;
        }
        case "OWN": {
          if (action === "CREATE") return true;
          if (product?.creator_id === loggedInUser.user?.PK_USER) return true;
          return false;
        }
        case "ANY": {
          return true;
        }
        default: {
          return false;
        }
      }
    }
  }

  function handleDeleteProduct(product: string) {
    const tempProducts = [...form.getValues("products")];
    const index = tempProducts.findIndex((p) => p.id === product);
    if (index === -1) return;
    tempProducts.splice(index, 1);
    form.setValue("products", tempProducts);
  }

  const onSubmit = form.handleSubmit((data) => {
    if (id) {
      const tempOrder: any = {
        orderId: id,
        updateOrderDto: {
          status: data.status.value,
          billing_address: constructLocationObject(data.billing_address),
          shipping_address: useSameAddresses
            ? constructLocationObject(data.billing_address)
            : constructLocationObject(data.shipping_address),
          products: constructCreateOrderProducts(data.products),
          shipping: parseFloat(form.getValues("shipping")),
          stripeFees: calculateStripeFees(
            data.products,
            data.payment_type.value,
            coupon
          ),
          requestType: "CMS",
          coupon: data.coupon,
          notes: data.notes,
        },
      };
      dispatch(updateOrderThunk(tempOrder)).then((res) => {
        if (res.meta.requestStatus === "rejected") {
          toast.error(
            Message({
              action: "update",
              entity: t("order.order"),
              error: t("genericError"),
              lang,
              gender: "male",
            })
          );
        } else {
          const payment_id = form.getValues("payment_id");
          if (payment_id) {
            dispatch(
              updatePaymentThunk({
                paymentId: payment_id,
                updatePaymentDto: {
                  order_id: res.payload.order.id,
                  status: data.payment_status.value,
                  payment_type: data.payment_type.value,
                  amount: res.payload.order.total_price,
                  fees: Round(
                    (calculateProductsPrice(form.watch("products")) -
                      calculateCouponDiscount(form.watch("products"), coupon)) *
                      0.014 +
                      0.25
                  ),
                },
              })
            ).then((r) => {
              if (r.meta.requestStatus === "rejected") {
                toast.error(
                  Message({
                    action: "update",
                    entity: "Payment",
                    error: t("genericError"),
                    lang,
                    gender: "male",
                  })
                );
              } else {
                toast.success(
                  Message({
                    action: "update",
                    entity: t("order.order"),
                    lang,
                    gender: "male",
                  })
                );
              }
            });
          }
        }
      });
    }
  });

  useEffect(() => {
    setLoadingOrder(true);
    if (id) {
      dispatch(getOrderThunk(id)).then((res: any) => {
        if (res.meta.requestStatus === "rejected") {
          toast.error(
            Message({
              action: "read",
              entity: t("order.order"),
              error: t("genericError"),
              lang,
              gender: "male",
            })
          );
        } else {
          Promise.all(
            res.payload.order.products.map((product: ProductWithMedia) =>
              dispatch(getProductThunk(product.id)).then(
                (res) => res.payload.product
              )
            )
          )
            .then((products) => {
              if (products.length) {
                // TODO
                form.setValue(
                  "products",
                  productsWithMediaToOrderProducts(
                    products as any,
                    res.payload.order.products
                  )
                );
              }
            })
            .catch((err: any) => {
              if (err.response?.data?.errors?.[0]?.message) {
                toast.error(err.response?.data?.errors?.[0]?.message);
              } else {
                toast.error(err.response?.data?.message);
              }
            });
          form.setValue("customer_id", res.payload.order.customer_id);
          form.setValue("status", {
            value: res.payload.order.status,
            label: res.payload.order.status,
          });
          setFetchedAddress(res.payload.order, "billing_address", form);
          if (
            res.payload.order.billing_address &&
            !res.payload.order.shipping_address
          ) {
            setUseSameAddresses(true);
          } else {
            setFetchedAddress(res.payload.order, "shipping_address", form);
          }
          form.setValue("shipping", res.payload.order.shipping);
          if (res.payload.order.coupon) {
            setCoupon(res.payload.order.coupon);
            form.setValue("coupon", res.payload.order.coupon.code);
          }
          if (res.payload.order.notes) {
            form.setValue("notes", res.payload.order.notes);
          }
          if (res.payload.order.payment?.id) {
            form.setValue("payment_id", res.payload.order.payment.id);
            dispatch(getPaymentThunk(res.payload.order.payment.id))
              .then((res) => {
                if (res.meta.requestStatus === "rejected") {
                  toast.error(
                    Message({
                      action: "read",
                      entity: "Payment",
                      error: t("genericError"),
                      lang,
                      gender: "male",
                    })
                  );
                } else {
                  if (res.payload.payment.payment_type) {
                    form.setValue("payment_type", {
                      value: res.payload.payment.payment_type,
                      label: res.payload.payment.payment_type,
                    });
                  } else {
                    form.setValue("payment_type", {
                      value: "cash",
                      label: "cash",
                    });
                  }
                  form.setValue("payment_status", {
                    value: res.payload.payment.status,
                    label: res.payload.payment.status,
                  });
                }
              })
              .finally(() => {
                setLoadingOrder(false);
              });
          } else {
            setLoadingOrder(false);
          }
        }
        return res;
      });
    }
  }, []);

  return (
    <Overlay active={loadingOrder} spinner={<Spinner />}>
      <div className="flex flex-col p-10 h-full overflow-y-auto">
        <form className="flex flex-col flex-grow gap-5" onSubmit={onSubmit}>
          <GoBack
            uri={
              searchParams.get("user")
                ? `users/${searchParams.get("user")}?current=orders`
                : "orders"
            }
          />
          <div className="flex flex-col gap-10">
            <div className="flex items-center justify-between">
              <h1 className="text-4xl font-bold">{t("order.updateOrder")}</h1>
              <button
                type="submit"
                className="px-4 py-2 text-white text-lg bg-users-create"
              >
                {t("order.save")}
              </button>
            </div>
            <div className="flex flex-col gap-5">
              <h2 className="text-2xl font-bold">{t("order.userDetails")}</h2>
              <div className="flex gap-10 w-full">
                <div className="flex flex-col gap-2 w-1/2">
                  <h2 className="text-lg font-medium">{t("order.user")}</h2>
                  <input
                    type="text"
                    disabled={true}
                    {...form.register("customer_id")}
                    className={getDisabledInputStyles()}
                  />
                  <DisplayInputError
                    message={form.formState.errors.customer_id?.message}
                  />
                </div>
                <div className="flex flex-col gap-2 w-1/2">
                  <h2 className="text-lg font-medium">{t("order.status")}</h2>
                  <Controller
                    name="status"
                    control={form.control}
                    rules={{
                      required: {
                        value: true,
                        message: t("order.orderStatusRequired"),
                      },
                    }}
                    render={({ field }) => (
                      <Select
                        styles={getReactSelectStyles(
                          !!form.formState.errors.status
                        )}
                        placeholder={t("order.orderStatusPlaceholder")}
                        inputId="order_status"
                        value={field.value}
                        options={orderStatusOptions(lang)}
                        onChange={(e) => field.onChange(e)}
                        isSearchable={false}
                      />
                    )}
                  />
                  <DisplayInputError
                    message={
                      (form.formState.errors.status as FieldError | undefined)
                        ?.message
                    }
                  />
                </div>
              </div>
              <div className="flex gap-10 w-full">
                <div className="flex flex-col gap-2 w-1/2">
                  <h2 className="text-lg font-medium">
                    {t("order.billingAddress")}
                  </h2>
                  <FormProvider {...form}>
                    <Location reactHookFormName={"billing_address"} />
                  </FormProvider>
                  <div className="flex gap-2 items-center">
                    <input
                      type="checkbox"
                      name=""
                      id="useSameAddresses"
                      checked={useSameAddresses}
                      onChange={() => {
                        if (useSameAddresses) {
                          unregisterLocation(
                            form.unregister,
                            "shipping_address"
                          );
                          registerLocation(form.register, "shipping_address");
                        } else {
                          unregisterLocation(
                            form.unregister,
                            "shipping_address"
                          );
                          registerLocationRequired(
                            form.register,
                            "shipping_address"
                          );
                        }
                        setUseSameAddresses(!useSameAddresses);
                      }}
                      className="text-primary"
                    />
                    <label htmlFor="useSameAddresses" className="select-none">
                      {t("order.sameAddress")}
                    </label>
                  </div>
                </div>
                <div className="flex flex-col gap-2 w-1/2">
                  {!useSameAddresses && (
                    <>
                      <h2 className="text-lg font-medium">
                        {t("order.shippingAddress")}
                      </h2>
                      <FormProvider {...form}>
                        <Location reactHookFormName={"shipping_address"} />
                      </FormProvider>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-5 w-full">
              <h2 className="text-2xl font-bold">{t("order.payment")}</h2>
              <div className="flex gap-10 w-full">
                <div className="flex flex-col gap-2 w-1/2">
                  <h2 className="text-lg font-medium">
                    {t("order.paymentType")}
                  </h2>
                  <Controller
                    name="payment_type"
                    control={form.control}
                    rules={{
                      required: {
                        value: true,
                        message: t("order.paymentTypeRequired"),
                      },
                    }}
                    render={({ field }) => (
                      <Select
                        styles={getReactSelectStyles(false)}
                        placeholder={t("order.paymentTypePlaceholder")}
                        inputId="payment_type"
                        value={field.value}
                        options={paymentTypeOptions(lang)}
                        onChange={(e) => field.onChange(e)}
                        isSearchable={false}
                        className={getInputStyles(
                          !!form.formState.errors.payment_type
                        )}
                      />
                    )}
                  />
                  <DisplayInputError
                    message={
                      (
                        form.formState.errors.payment_type as
                          | FieldError
                          | undefined
                      )?.message
                    }
                  />
                </div>
                <div className="flex flex-col gap-2 w-1/2">
                  <h2 className="text-lg font-medium">
                    {t("order.paymentStatus")}
                  </h2>
                  <Controller
                    name="payment_status"
                    control={form.control}
                    rules={{
                      required: {
                        value: true,
                        message: t("order.paymentStatusRequired"),
                      },
                    }}
                    render={({ field }) => (
                      <Select
                        styles={getReactSelectStyles(false)}
                        placeholder={t("order.paymentStatusPlaceholder")}
                        inputId="payment_status"
                        value={field.value}
                        options={paymentStatusOptions}
                        onChange={(e) => field.onChange(e)}
                        isSearchable={false}
                        className={getInputStyles(
                          !!form.formState.errors.payment_status
                        )}
                      />
                    )}
                  />
                  <DisplayInputError
                    message={
                      (
                        form.formState.errors.payment_status as
                          | FieldError
                          | undefined
                      )?.message
                    }
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-5">
              <label htmlFor="notes" className="text-lg font-bold">
                {t("order.notes")}
              </label>
              <textarea
                id="notes"
                cols={30}
                rows={3}
                {...form.register("notes")}
                className={getInputStyles(!!form.formState.errors.notes)}
              ></textarea>
              <DisplayInputError
                message={form.formState.errors.notes?.message}
              />
            </div>
            <div className="flex flex-col gap-5">
              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={() => {
                    setSelectProductModalOpen(true);
                  }}
                  className="px-4 py-2 text-white bg-primary"
                >
                  {t("order.addProduct")}
                </button>
              </div>
              <Table {...tableInstance} />
              <TablePopup
                isOpen={portal.open}
                close={() => setPortal((state) => ({ ...state, open: false }))}
              >
                {portalContent()}
              </TablePopup>
              <div className="flex gap-2 items-start justify-end">
                <div className="flex flex-col gap-2">
                  <label htmlFor="coupon" className="text-lg font-medium">
                    {t("order.coupon")}
                  </label>
                  <div className="flex">
                    <input
                      id="coupon"
                      type="text"
                      placeholder="NEWYEAR"
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                        }
                      }}
                      {...form.register("coupon")}
                      className={getInputStyles(false)}
                    />
                    {coupon && (
                      <button
                        type="button"
                        className="px-2 py-1 text-white bg-red-500"
                        onClick={() => {
                          form.setValue("coupon", "");
                          form.trigger("coupon");
                          setCoupon(undefined);
                        }}
                      >
                        <XIcon className="w-6 h-6" />
                      </button>
                    )}
                  </div>
                  <button
                    type="button"
                    onClick={() => {
                      axios
                        .get(
                          `${CMS_BE_URL}/s/coupons?code=${form.getValues(
                            "coupon"
                          )}`
                        )
                        .then((res) => {
                          const products = form.getValues("products");

                          if (products.length === 0) {
                            form.setError("coupon", {
                              type: "custom",
                              message: t("order.couponNoProductsError"),
                            });
                          } else if (res.data.data.length === 0) {
                            form.setError("coupon", {
                              type: "custom",
                              message: `${t("order.couponNotFound")}`,
                            });
                          } else if (new Date(res.data.data[0]) < new Date()) {
                            form.setError("coupon", {
                              type: "custom",
                              message: `coupon expired`,
                            });
                          } else if ((res.data.data[0] as Coupon).amount <= 0) {
                            form.setError("coupon", {
                              type: "custom",
                              message: `Coupon cannot be reused`,
                            });
                          } else {
                            let hasProduct = false;
                            let hasSubscription = false;
                            let hasExam = false;
                            let excluded = false;
                            let variantTitle = "";

                            products.forEach((product) => {
                              product.categories.forEach((c) => {
                                if (
                                  res.data.data[0].categories.findIndex(
                                    (coupon: any) => coupon.id === c.id
                                  ) !== -1
                                ) {
                                  excluded = true;
                                  variantTitle =
                                    product.selection.variant.title;
                                }
                              });
                              if (product.class === "product")
                                hasProduct = true;
                              else if (product.class === "subscription")
                                hasSubscription = true;
                              else if (product.class === "exam") hasExam = true;
                            });

                            if (excluded) {
                              form.setError("coupon", {
                                type: "custom",
                                message: `Coupon is excluded from ${variantTitle}`,
                              });
                            } else {
                              if (!hasProduct) {
                                form.setError("coupon", {
                                  type: "custom",
                                  message: t("order.couponSubError"),
                                });
                              } else if (hasSubscription || hasExam) {
                                form.setError("coupon", {
                                  type: "custom",
                                  message: t("order.couponSubError"),
                                });
                              } else {
                                form.setValue("coupon", res.data.data[0].code);
                                form.clearErrors("coupon");
                                setCoupon(res.data.data[0]);
                              }
                            }
                          }
                        })
                        .catch((err: any) => {
                          if (err.response?.data?.errors?.[0]?.message) {
                            toast.error(
                              err.response?.data?.errors?.[0]?.message
                            );
                          } else {
                            toast.error(err.response?.data?.message);
                          }
                        });
                    }}
                    className="px-4 py-2 text-white bg-primary border-primary"
                  >
                    {t("order.applyCoupon")}
                  </button>
                  <DisplayInputError
                    message={form.formState.errors.coupon?.message}
                  />
                </div>
                <div className="flex flex-col gap-2">
                  <label htmlFor="shippingCost" className="text-lg font-medium">
                    {t("order.shippingCost")}
                  </label>
                  <input
                    id="shippingCost"
                    type="text"
                    placeholder={t("order.shippingCost")}
                    {...form.register("shipping", {
                      validate: {
                        isNumber(i) {
                          if (isNaN(Number(i)))
                            return `${t("product.priceGTZero")}`;
                          return true;
                        },
                      },
                    })}
                    value={form.watch("shipping")}
                    onChange={(e) => {
                      form.setValue("shipping", e.target.value);
                      form.trigger("shipping");
                    }}
                    disabled={!forceShipping}
                    className={
                      forceShipping
                        ? getInputStyles(false)
                        : getDisabledInputStyles()
                    }
                  />
                  <DisplayInputError
                    message={form.formState.errors.shipping?.message}
                  />
                  <div className="flex gap-2 items-center">
                    <input
                      type="checkbox"
                      id="forceShipping"
                      checked={forceShipping}
                      onChange={() => setForceShipping(!forceShipping)}
                      className="text-primary"
                    />
                    <label htmlFor="forceShipping" className="select-none">
                      {t("order.forceShippingCost")}
                    </label>
                  </div>
                </div>
              </div>
              <div className="flex gap-10 justify-end w-full">
                <div className="flex flex-col gap-2">
                  {coupon && (
                    <div className="flex gap-5 justify-between">
                      <h2 className="text-lg font-bold">
                        {t("order.coupon")}:
                      </h2>
                      {calculateCouponDiscount(form.watch("products"), coupon)}
                      &euro;
                    </div>
                  )}
                  {form.watch("payment_type")?.value === "stripe" && (
                    <div className="flex gap-5 justify-between">
                      <h2 className="text-lg font-bold">{t("order.fees")}</h2>
                      {Round(
                        (calculateProductsPrice(form.watch("products")) -
                          calculateCouponDiscount(
                            form.watch("products"),
                            coupon
                          )) *
                          0.014 +
                          0.25
                      )}
                      &euro;
                    </div>
                  )}
                  <div className="flex gap-5 justify-between">
                    <h2 className="text-lg font-bold">{t("order.total")}</h2>
                    {calculateTotalCost(
                      form.watch("products"),
                      form.watch("shipping"),
                      form.watch("payment_type")?.value,
                      coupon
                    )}
                    &euro;
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
        <FormProvider {...form}>
          <ScreenModal
            isOpen={selectProductModalOpen}
            setIsOpen={setSelectProductModalOpen}
          >
            <div
              className="flex flex-col gap-5 p-10 bg-white rounded-md"
              style={{ width: "50rem" }}
            >
              <div className="flex items-center justify-between">
                <div className="flex gap-2 items-center">
                  <h2 className="text-2xl font-bold">
                    {t("order.selectProduct")}
                  </h2>
                  <Link to="/products/create">
                    <span className="text-primary text-xs font-bold">
                      {t("order.orCreate")}
                    </span>
                  </Link>
                </div>
                <button
                  type="button"
                  onClick={() => {
                    editProduct.current = null;
                    setSelectProductModalOpen(false);
                  }}
                >
                  <XIcon className="w-6 h-6" />
                </button>
              </div>
              <SelectProduct
                isOpen={selectProductModalOpen}
                setIsOpen={setSelectProductModalOpen}
                editProduct={editProduct.current}
              />
            </div>
          </ScreenModal>
        </FormProvider>
        <DevTool control={form.control} />
      </div>
    </Overlay>
  );
}
