import {
  BanIcon,
  DotsVerticalIcon,
  ExclamationIcon,
  PencilIcon,
  PlusIcon,
  SearchIcon,
  TrashIcon,
} from "@heroicons/react/outline";
import {
  deleteCoupon,
  getCoupons,
  getCouponsColumns,
} from "api/Coupons/coupons.api";
import { GetCouponsFilters } from "api/Coupons/urls";
import { format } from "date-fns";
import React, { useState, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  Column,
  useFlexLayout,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { toast } from "react-toastify";
import Message from "shared/components/UI/Message";
import TablePopup, { PortalType } from "shared/components/UI/TablePopup";
import { useDebounce } from "shared/hooks/useDebounce";
import { Coupon } from "shared/interfaces/Coupon.interface";
import { PermissionDomain } from "shared/interfaces/Permission.interface";
import { RolePermissionCrudAction } from "shared/interfaces/RolePermission.interface";
import { selectLoggedInUser } from "store/authStore/authReducer";
import { selectTranslationLanguage } from "store/translationStore/translationReducer";
import Overlay from "shared/components/UI/Overlay";
import Spinner from "../../shared/components/UI/Spinner";
import Table from "shared/components/Table/Table";
import Pagination from "shared/components/Table/Pagination";
import Modal from "shared/components/UI/Modal";

export default function Coupons() {
  const lang = selectTranslationLanguage();
  const { t } = useTranslation("common");

  const [coupons, setCoupons] = useState<Coupon[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [index, setIndex] = useState(0);
  const [total, setTotal] = useState(0);

  const form = useForm<GetCouponsFilters>({
    defaultValues: {
      pageSize: 10,
      pageIndex: 0,
      searchString: "",
    },
  });

  function constructGetCouponFilters(pageIndex: number): GetCouponsFilters {
    return {
      searchString: debouncedSearchText,
      pageIndex: pageIndex,
      pageSize: form.getValues("pageSize"),
      sortCol: sortBy[0] ? sortBy[0].id : "",
      sortOrder: sortBy[0] ? (sortBy[0].desc ? "DESC" : "ASC") : "",
    };
  }
  function getCouponsHelper(pageIndex: number) {
    return getCoupons(constructGetCouponFilters(pageIndex))
      .then((res) => {
        setCoupons(res.data.data);
        setIndex(res.data.pageIndex);
        setTotal(res.data.total);
      })
      .catch((err: any) => {
        if (err.response?.data?.errors?.[0]?.message) {
          toast.error(err.response?.data?.errors?.[0]?.message);
        } else {
          toast.error(err.response?.data?.message);
        }
      });
  }
  const loggedInUser = selectLoggedInUser();
  function canAccess(
    domain: PermissionDomain,
    action: RolePermissionCrudAction
  ): boolean {
    if (loggedInUser.permissions["*"]) {
      return true;
    } else {
      switch (loggedInUser.permissions[domain][action]) {
        case "NONE": {
          return false;
        }
        case "OWN": {
          if (action === "CREATE") return true;
          return false;
        }
        case "ANY": {
          return true;
        }
        default: {
          return false;
        }
      }
    }
  }

  const [portal, setPortal] = useState<PortalType<Coupon>>({
    open: false,
    top: 0,
    left: 0,
    entity: null,
  });

  function displayActions(coupon: Coupon, i: number) {
    const canRead = canAccess("s", "READ");
    const canUpdate = canAccess("s", "UPDATE");
    const canDelete = canAccess("s", "DELETE");
    if (!canRead && !canUpdate && !canDelete) {
      return (
        <div className="flex items-center h-full">
          <BanIcon className="w-5 h-5" />
        </div>
      );
    }
    return (
      <div className="relative flex items-center h-full">
        <button
          id={`portal-button-${i}`}
          type="button"
          onClick={() => {
            return setPortal((state) => {
              const thisButton = document.getElementById(`portal-button-${i}`);
              const position = thisButton?.getBoundingClientRect();
              return {
                open: !state.open,
                top: Number(position?.bottom),
                left: Number(
                  window.innerWidth - Number(position?.x) <= 150
                    ? Number(position?.left) - 150
                    : Number(position?.left)
                ),
                entity: coupon,
              };
            });
          }}
        >
          <DotsVerticalIcon className="w-5 h-5" />
        </button>
      </div>
    );
  }
  const [couponToDelete, setCouponToDelete] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const handleDeleteModal = (couponToDelete: string) => {
    setCouponToDelete(couponToDelete);
    setOpenDeleteModal(true);
  };
  function portalContent() {
    if (portal.entity === null) return null;
    const canRead = canAccess("s", "READ");
    const canUpdate = canAccess("s", "UPDATE");
    const canDelete = canAccess("s", "DELETE");
    return (
      <div
        className="fixed z-50 flex flex-col gap-2 p-2 bg-white rounded-sm shadow-md"
        style={{
          width: "150px",
          top: portal.top,
          left: portal.left,
        }}
      >
        {canRead ? (
          <Link
            to={`/coupons/${portal.entity.id}`}
            className="flex gap-2 items-center text-table-edit"
          >
            <PencilIcon className="w-5 h-5" />
            {canUpdate ? t("crud.update") : t("crud.read")}
          </Link>
        ) : null}
        {canDelete ? (
          <button
            onClick={() => handleDeleteModal(portal.entity?.id as string)}
            className="flex gap-2 items-center text-users-delete"
          >
            <TrashIcon className="w-5 h-5" />
            {t("crud.delete")}
          </button>
        ) : null}
      </div>
    );
  }
  const columns: Array<Column<Record<string, any>>> = useMemo(
    () => getCouponsColumns(lang),
    [lang]
  );

  const data: Array<any> = useMemo(
    () =>
      coupons.map((coupon, i) => ({
        col1: (
          <div className="flex items-center h-full">
            <span className="truncate">{coupon.code}</span>
          </div>
        ),
        col2: (
          <div className="flex items-center h-full">
            <span className="truncate">
              {coupon.percentage ? `${coupon.amount}%` : `${coupon.amount}€`}
            </span>
          </div>
        ),
        col3: (
          <div className="flex items-center h-full">
            <span className="truncate">{coupon.uses}</span>
          </div>
        ),
        col4: (
          <div className="flex items-center h-full">
            <span className="truncate">
              {format(new Date(coupon.expiry_date), "MMMM dd, yyyy")}
            </span>
          </div>
        ),
        col5: displayActions(coupon, i),
      })),
    [coupons, lang]
  );

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: form.getValues("pageSize") },
      pageCount: Math.ceil(total / form.getValues("pageSize")),
      manualSortBy: true,
      manualPagination: true,
    },
    useSortBy,
    usePagination,
    useFlexLayout
  );

  const {
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, sortBy },
  } = tableInstance;

  const dispatchNextPage = () => {
    if (canNextPage) {
      setLoading(true);
      getCouponsHelper(pageIndex + 1).then(() => setLoading(false));
    }
  };

  const dispatchPreviousPage = () => {
    if (canNextPage) {
      setLoading(true);
      getCouponsHelper(pageIndex - 1).then(() => setLoading(false));
    }
  };

  const dispatchGotoPage = (pageIndex: number) => {
    if (canNextPage) {
      setLoading(true);
      getCouponsHelper(pageIndex).then(() => setLoading(false));
    }
  };

  const debouncedSearchText = useDebounce(
    form.watch("searchString") as string,
    300
  );

  useEffect(() => {
    setLoading(true);
    getCouponsHelper(0).then(() => setLoading(false));
  }, [sortBy, debouncedSearchText]);

  const handleDeleteCoupon = (couponToDelete: string) => {
    deleteCoupon(couponToDelete)
      .then(() => {
        toast.success(
          Message({
            action: "delete",
            entity: t("coupons.coupon"),
            lang,
          })
        );
      })
      .catch(
        (err: any) => {
          if (err.response?.data?.errors?.[0]?.message) {
            toast.error(err.response?.data?.errors?.[0]?.message);
          } else {
            toast.error(err.response?.data?.message);
          }
        }
        // toast.error(
        //   Message({
        //     action: "delete",
        //     entity: t("coupons.coupon"),
        //     error: t("genericError"),
        //     lang,
        //   })
        // )
      )
      .then(() => getCouponsHelper(0));
    setOpenDeleteModal(false);
  };

  return (
    <Overlay active={loading} spinner={<Spinner />}>
      <div className="flex flex-col gap-5 p-10 h-full">
        <h1 className="text-3xl font-bold">{t("coupons.coupons")}</h1>
        <div className="flex flex-wrap gap-5">
          {/* <div className="flex flex-wrap gap-5">
            <button onClick={() => setFiltersOpen((state) => !state)}>
              <FilterIcon className="w-6 h-6" />
            </button>
          </div> */}
          <div className="flex flex-grow flex-wrap gap-5 justify-end">
            <div className="flex items-center w-72 border focus-within:border-global-input-focus border-gray-300">
              <div className="flex items-center justify-center p-2 h-full bg-white">
                <SearchIcon className="w-5 h-5 text-gray-400" />
              </div>
              <input
                type="text"
                placeholder={`${t("coupons.search")}...`}
                {...form.register("searchString")}
                className="placeholder-gray-400 p-2 w-full border-0 focus:ring-0"
              />
            </div>
            {canAccess("s", "CREATE") && (
              <Link to="/coupons/create" className="h-full">
                <button className="flex gap-2 items-center justify-center px-4 py-2 w-full text-white whitespace-nowrap bg-global-createEntity border border-global-createEntity">
                  <PlusIcon className="w-5 h-5" />
                  <span>{t("coupons.createCoupon")}</span>
                </button>
              </Link>
            )}
          </div>
        </div>
        <Table {...tableInstance} />
        <Pagination
          rowsCount={total} // TODO:
          pageIndex={pageIndex}
          pageSize={form.getValues("pageSize")}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          pageOptions={pageOptions}
          pageCount={pageCount}
          gotoPage={dispatchGotoPage}
          nextPage={dispatchNextPage}
          previousPage={dispatchPreviousPage}
          setPageSize={setPageSize}
        />
        <TablePopup
          isOpen={portal.open}
          close={() => setPortal((state: any) => ({ ...state, open: false }))}
        >
          {portalContent()}
        </TablePopup>
        <Modal
          openStatus={openDeleteModal}
          setOpen={setOpenDeleteModal}
          icon={
            <ExclamationIcon
              className="w-6 h-6 text-red-600 bg-transparent"
              aria-hidden="true"
            />
          }
          header={
            <p>
              {t("deleteModal.delete")} {t("coupons.coupon")}
            </p>
          }
          title={
            <p>
              {t("deleteModal.sure")} {t("coupons.coupon")} ?{" "}
              {t("deleteModal.undone")}
            </p>
          }
          footer={
            <div className="flex justify-end mt-5 sm:mt-4">
              <button
                type="button"
                className="inline-flex justify-center mt-3 px-4 py-1 w-full text-black text-base font-medium bg-white border border-black focus:outline-none shadow-sm focus:ring-0 sm:mt-0 sm:w-auto"
                onClick={() => setOpenDeleteModal(false)}
              >
                {t("deleteModal.cancel")}
              </button>
              <button
                type="button"
                className="inline-flex justify-center px-4 py-1 w-full text-white text-base font-medium bg-red-600 border border-transparent focus:outline-none shadow-sm focus:ring-0 sm:ml-3 sm:w-auto"
                onClick={() => handleDeleteCoupon(couponToDelete)}
              >
                {t("deleteModal.delete")}
              </button>
            </div>
          }
        />
      </div>
    </Overlay>
  );
}
