import {
  BanIcon,
  DotsVerticalIcon,
  ExclamationIcon,
  PencilIcon,
  PlusIcon,
  SearchIcon,
  TrashIcon,
} from "@heroicons/react/outline";
import {
  deleteOrders,
  exportOrder,
  exportOrders,
  getOrder,
  getOrdersColumns,
  updateOrders,
} from "api/Orders/orders.api";
import { ExportOrdersFilters, GetOrdersFilters } from "api/Orders/urls";
import React, { useEffect, useMemo, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  Column,
  useFlexLayout,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { toast } from "react-toastify";
import Overlay from "shared/components/UI/Overlay";
import { useDebounce } from "shared/hooks/useDebounce";
import { PermissionDomain } from "shared/interfaces/Permission.interface";
import { RolePermissionCrudAction } from "shared/interfaces/RolePermission.interface";
import { selectLoggedInUser } from "store/authStore/authReducer";
import {
  deleteOrderThunk,
  getOrdersThunk,
  selectOrders,
} from "store/ordersStore/ordersReducer";
import { useAppDispatch } from "store/storeHooks";
import { selectTranslationLanguage } from "store/translationStore/translationReducer";
import {
  b64toBlob,
  getInputStyles,
  getReactSelectStyles,
  Round,
} from "utils/utils";
import Pagination from "../../shared/components/Table/Pagination";
import Table from "../../shared/components/Table/Table";
import Message, { StrMessage } from "../../shared/components/UI/Message";
import Modal from "../../shared/components/UI/Modal";
import Spinner from "../../shared/components/UI/Spinner";
import TablePopup, { PortalType } from "../../shared/components/UI/TablePopup";
import {
  getUserRanksThunk,
  selectUserRanks,
} from "store/userRanksStore/userRanksReducer";
import { clone } from "ramda";
import Select from "react-select";
import { format } from "date-fns";
import { BsFilePdf } from "react-icons/bs";
import {
  EnchancedOrder,
  OrderAddress,
  orderOriginOptions,
  OrderStatusEnum,
  orderStatusOptions,
} from "shared/interfaces/Orders.interface";
import { paymentTypeOptions } from "shared/interfaces/Payment.interface";
import { Popover } from "@headlessui/react";
import { productsClassesOptions } from "shared/interfaces/Product.new.interface";
import saveAs from "file-saver";
import { generateOrdersPdfs } from "./generateOrdersPdfs";
import { generateOrderPdf } from "./generateOrderPdf";

export function displayAddress(address: OrderAddress | string) {
  if (!address) return "";
  if (typeof address === "string") return address;
  const str: string[] = [];
  if (address.street_name) str.push(address.street_name);
  if (address.street_number) str.push(String(address.street_number));
  if (address.city) str.push(address.city);
  if (address.region) str.push(address.region);
  return str.join(", ");
}

export default function Orders() {
  const lang = selectTranslationLanguage();
  const { t } = useTranslation("common");

  const dispatch = useAppDispatch();
  const orders = selectOrders();
  const [updateLoading, setUpdateLoading] = useState(false);
  const [allSelected, setAllSelected] = useState(false);
  const [excludeOrders, setExcludeOrders] = useState<string[]>([]);
  const [includeOrders, setIncludeOrders] = useState<string[]>([]);
  const [exportLoading, setExportLoading] = useState(false);
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [updateStatus, setUpdateStatus] = useState<null | OrderStatusEnum>(
    null
  );

  const form = useForm<GetOrdersFilters>({
    defaultValues: {
      include: "billingAddress,shippingAddress,products,payment,user",
      paymentDate: {
        between: [
          new Date(new Date().getFullYear(), 0, 1) as any,
          new Date(new Date().getFullYear(), 11, 31) as any,
        ] as any,
      },
      pageSize: 10,
      pageIndex: 0,
      searchString: "",
    },
  });

  function deleteOrdersHelper() {
    setUpdateModalOpen(false);
    setUpdateLoading(true);
    if (allSelected) {
      deleteOrders({
        filters: constructGetOrderFilters(0),
        exclude: excludeOrders,
      })
        .catch(
          (err: any) => {
            if (err.response?.data?.errors?.[0]?.message) {
              toast.error(err.response?.data?.errors?.[0]?.message);
            } else {
              toast.error(err.response?.data?.message);
            }
          }
          // toast.error(
          //   Message({
          //     action: "delete",
          //     entity: t("orders.orders"),
          //     error: t("genericError"),
          //     lang,
          //   })
          // )
        )
        .then(() => getOrders(0))
        .finally(() => {
          setUpdateLoading(false);
        });
    } else {
      deleteOrders({
        filters: constructGetOrderFilters(0),
        include: includeOrders,
      })
        .catch(
          (err: any) => {
            if (err.response?.data?.errors?.[0]?.message) {
              toast.error(err.response?.data?.errors?.[0]?.message);
            } else {
              toast.error(err.response?.data?.message);
            }
          }
          // toast.error(
          //   Message({
          //     action: "delete",
          //     entity: t("orders.orders"),
          //     error: t("genericError"),
          //     lang,
          //   })
          // )
        )
        .then(() => getOrders(0))
        .finally(() => {
          setUpdateLoading(false);
        });
    }
  }

  function updateOrdersHelper(status: OrderStatusEnum) {
    setUpdateModalOpen(false);
    setUpdateLoading(true);
    if (allSelected) {
      updateOrders({
        filters: constructGetOrderFilters(0),
        values: {
          status,
        },
        exclude: excludeOrders,
      })
        .catch(
          (err: any) => {
            if (err.response?.data?.errors?.[0]?.message) {
              toast.error(err.response?.data?.errors?.[0]?.message);
            } else {
              toast.error(err.response?.data?.message);
            }
          }
          // toast.error(
          //   Message({
          //     action: "update",
          //     entity: "Orders",
          //     error: t("genericError"),
          //     lang,
          //   })
          // )
        )
        .then(() => getOrders(0))
        .finally(() => {
          setUpdateLoading(false);
        });
    } else {
      updateOrders({
        filters: constructGetOrderFilters(0),
        values: {
          status,
        },
        include: includeOrders,
      })
        .catch(
          (err: any) => {
            if (err.response?.data?.errors?.[0]?.message) {
              toast.error(err.response?.data?.errors?.[0]?.message);
            } else {
              toast.error(err.response?.data?.message);
            }
          }
          // toast.error(
          //   Message({
          //     action: "update",
          //     entity: "Orders",
          //     error: t("genericError"),
          //     lang,
          //   })
          // )
        )
        .then(() => getOrders(0))
        .finally(() => {
          setUpdateLoading(false);
        });
    }
  }

  function constructExportOrdersFilters(): ExportOrdersFilters {
    return {
      customerId: debouncedSearchText,
      fullname: debouncedFullname,
      status: form.getValues("status.in")?.length
        ? {
            in: form
              .getValues("status.in")
              ?.map(
                (status: any /*as { value: UserRank; label: string }*/) =>
                  status.value
              ),
          }
        : undefined,
      // subtotal: "",
      include: form.getValues("include"),
      paymentDate:
        form.getValues("paymentDate.between.0") &&
        form.getValues("paymentDate.between.1")
          ? {
              between: [
                format(
                  new Date(form.getValues("paymentDate.between.0")),
                  "yyyy-MM-dd"
                ),
                format(
                  new Date(form.getValues("paymentDate.between.1")),
                  "yyyy-MM-dd"
                ),
              ],
            }
          : undefined,
      origin: form.getValues("origin.in")?.length
        ? {
            in: form
              .getValues("origin.in")
              ?.map(
                (origin: any /*as { value: UserRank; label: string }*/) =>
                  origin.value
              ),
          }
        : undefined,
      rank: form.getValues("rank.in")?.length
        ? {
            in: form
              .getValues("rank.in")
              ?.map(
                (rank: any /*as { value: UserRank; label: string }*/) =>
                  rank.value.id
              ),
          }
        : undefined,
      productClass: form.getValues("productClass.in")?.length
        ? {
            in: form
              .getValues("productClass.in")
              ?.map(
                (productClass: any /*as { value: UserRank; label: string }*/) =>
                  productClass.value
              ),
          }
        : undefined,
      paymentType: form.getValues("paymentType.in")?.length
        ? {
            in: form
              .getValues("paymentType.in")
              ?.map(
                (paymentType: any /*as { value: UserRank; label: string }*/) =>
                  paymentType.value
              ),
          }
        : undefined,
      yearlyId:
        form.getValues("yearlyId.between.0") &&
        form.getValues("yearlyId.between.1")
          ? {
              between: [
                form.getValues("yearlyId.between.0"),
                form.getValues("yearlyId.between.1"),
              ],
            }
          : undefined,
    };
  }

  function constructGetOrderFilters(pageIndex: number): GetOrdersFilters {
    return {
      customerId: debouncedSearchText,
      fullname: debouncedFullname,
      status: form.getValues("status.in")?.length
        ? {
            in: form
              .getValues("status.in")
              ?.map(
                (status: any /*as { value: UserRank; label: string }*/) =>
                  status.value
              ),
          }
        : undefined,
      // subtotal: "",
      include: form.getValues("include"),
      paymentDate:
        form.getValues("paymentDate.between.0") &&
        form.getValues("paymentDate.between.1")
          ? {
              between: [
                format(
                  new Date(form.getValues("paymentDate.between.0")),
                  "yyyy-MM-dd"
                ),
                format(
                  new Date(form.getValues("paymentDate.between.1")),
                  "yyyy-MM-dd"
                ),
              ],
            }
          : undefined,
      origin: form.getValues("origin.in")?.length
        ? {
            in: form
              .getValues("origin.in")
              ?.map(
                (origin: any /*as { value: UserRank; label: string }*/) =>
                  origin.value
              ),
          }
        : undefined,
      rank: form.getValues("rank.in")?.length
        ? {
            in: form
              .getValues("rank.in")
              ?.map(
                (rank: any /*as { value: UserRank; label: string }*/) =>
                  rank.value.id
              ),
          }
        : undefined,
      productClass: form.getValues("productClass.in")?.length
        ? {
            in: form
              .getValues("productClass.in")
              ?.map(
                (productClass: any /*as { value: UserRank; label: string }*/) =>
                  productClass.value
              ),
          }
        : undefined,
      paymentType: form.getValues("paymentType.in")?.length
        ? {
            in: form
              .getValues("paymentType.in")
              ?.map(
                (paymentType: any /*as { value: UserRank; label: string }*/) =>
                  paymentType.value
              ),
          }
        : undefined,
      yearlyId:
        form.getValues("yearlyId.between.0") &&
        form.getValues("yearlyId.between.1")
          ? {
              between: [
                form.getValues("yearlyId.between.0"),
                form.getValues("yearlyId.between.1"),
              ],
            }
          : undefined,
      pageIndex: pageIndex,
      pageSize: form.getValues("pageSize"),
      sortCol: sortBy[0] ? sortBy[0].id : "",
      sortOrder: sortBy[0] ? (sortBy[0].desc ? "DESC" : "ASC") : "",
    };
  }

  function getOrders(pageIndex: number) {
    return dispatch(getOrdersThunk(constructGetOrderFilters(pageIndex))).then(
      (res) => {
        if (res.meta.requestStatus === "rejected") {
          toast.error(
            Message({
              action: "read",
              entity: t("orders.orders"),
              error: t("genericError"),
              lang,
              gender: "male",
            })
          );
        }
        return res;
      }
    );
  }

  const loggedInUser = selectLoggedInUser();
  function canAccess(
    domain: PermissionDomain,
    action: RolePermissionCrudAction,
    order?: EnchancedOrder
  ): boolean {
    if (loggedInUser.permissions["*"]) {
      return true;
    } else {
      switch (loggedInUser.permissions[domain][action]) {
        case "NONE": {
          return false;
        }
        case "OWN": {
          if (action === "DELETE") return false;
          if (action === "CREATE") return true;
          if (order && order.customer_id === loggedInUser.user?.PK_USER)
            return true;
          return false;
        }
        case "ANY": {
          if (action === "DELETE") return false;
          return true;
        }
        default: {
          return false;
        }
      }
    }
  }

  const [portal, setPortal] = useState<PortalType<EnchancedOrder>>({
    open: false,
    top: 0,
    left: 0,
    entity: null,
  });
  function displayActions(order: EnchancedOrder, i: number) {
    const canRead = canAccess("s", "READ", order);
    const canUpdate = canAccess("s", "UPDATE", order);
    const canDelete = canAccess("s", "DELETE", order);
    if (!canRead && !canUpdate && !canDelete) {
      return (
        <div className="flex items-center h-full">
          <BanIcon className="w-5 h-5" />
        </div>
      );
    }
    return (
      <div className="relative flex items-center h-full">
        <button
          id={`portal-button-${i}`}
          type="button"
          onClick={() => {
            return setPortal((state) => {
              const thisButton = document.getElementById(`portal-button-${i}`);
              const position = thisButton?.getBoundingClientRect();
              return {
                open: !state.open,
                top: Number(position?.bottom),
                left: Number(
                  window.innerWidth - Number(position?.x) <= 150
                    ? Number(position?.left) - 150
                    : Number(position?.left)
                ),
                entity: order,
              };
            });
          }}
        >
          <DotsVerticalIcon className="w-5 h-5" />
        </button>
      </div>
    );
  }

  const [orderToDelete, setOrderToDelete] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const handleDeleteModal = (orderToDelete: string) => {
    setOrderToDelete(orderToDelete);
    setOpenDeleteModal(true);
  };

  function portalContent() {
    if (portal.entity === null) return null;
    const canRead = canAccess("s", "READ", portal.entity);
    const canUpdate = canAccess("s", "UPDATE", portal.entity);
    const canDelete = canAccess("s", "DELETE", portal.entity);
    return (
      <div
        className="fixed z-50 flex flex-col gap-2 p-2 bg-white rounded-sm shadow-md"
        style={{
          width: "150px",
          top: portal.top,
          left: portal.left,
        }}
      >
        {canRead ? (
          <Link
            to={`/orders/${portal.entity.id}`}
            className="flex gap-2 items-center text-table-edit"
          >
            <PencilIcon className="w-5 h-5" />
            {canUpdate ? t("crud.update") : t("crud.read")}
          </Link>
        ) : null}
        {canDelete ? (
          <button
            onClick={() => handleDeleteModal(portal.entity?.id as string)}
            className="flex gap-2 items-center text-users-delete"
          >
            <TrashIcon className="w-5 h-5" />
            {t("crud.delete")}
          </button>
        ) : null}
        {canRead && portal.entity.status !== "cart" ? (
          <button
            disabled={exportLoading}
            onClick={() => {
              setExportLoading(true);
              const exportOrderPromise = getOrder(String(portal.entity?.id))
                .then((res) => generateOrderPdf(res.data.order))
                .catch((err: any) => {
                  if (err.response?.data?.errors?.[0]?.message) {
                    toast.error(err.response?.data?.errors?.[0]?.message);
                  } else {
                    toast.error(err.response?.data?.message);
                  }
                })
                .finally(() => setExportLoading(false));
              toast.promise(exportOrderPromise, {
                pending: t("order.exporting"),
                success: t("order.exportFinished"),
                error: StrMessage({
                  action: "read",
                  entity: t("orders.order"),
                  error: t("genericError"),
                  lang,
                }),
              });
            }}
            style={{ color: "#8f130e" }}
            className="flex gap-2 items-center"
          >
            <BsFilePdf className="w-5 h-5" />
            {lang === "en" ? "Export" : "Esportare"}
          </button>
        ) : null}
      </div>
    );
  }

  const columns: Array<Column<Record<string, any>>> = useMemo(
    () =>
      getOrdersColumns(
        {
          allSelected,
          setAllSelected,
          includeOrders,
          setIncludeOrders,
          excludeOrders,
          setExcludeOrders,
        },
        lang
      ),
    [
      lang,
      allSelected,
      setAllSelected,
      includeOrders,
      setIncludeOrders,
      excludeOrders,
      setExcludeOrders,
    ]
  );

  const data: Array<any> = useMemo(
    () =>
      orders.data.data.map((order, i) => ({
        checkbox: (
          <div className="flex items-center h-full">
            <input
              type="checkbox"
              checked={(function () {
                if (allSelected) {
                  const index = excludeOrders.indexOf(order.id);
                  if (index === -1) {
                    return true;
                  } else {
                    return false;
                  }
                } else {
                  const index = includeOrders.indexOf(order.id);
                  if (index === -1) {
                    return false;
                  } else {
                    return true;
                  }
                }
              })()}
              onClick={() => {
                if (allSelected) {
                  const index = excludeOrders.indexOf(order.id);
                  if (index !== -1) {
                    setExcludeOrders((excludedOrders) => {
                      const temp = [...excludedOrders];
                      temp.splice(index, 1);
                      return temp;
                    });
                  } else {
                    setExcludeOrders((excludedOrders) => [
                      ...excludedOrders,
                      order.id,
                    ]);
                  }
                } else {
                  const index = includeOrders.indexOf(order.id);
                  if (index !== -1) {
                    setIncludeOrders((includedOrders) => {
                      const temp = [...includedOrders];
                      temp.splice(index, 1);
                      return temp;
                    });
                  } else {
                    setIncludeOrders((includedOrders) => [
                      ...includedOrders,
                      order.id,
                    ]);
                  }
                }
              }}
              className="text-primary"
            />
          </div>
        ),
        col1: (
          <div className="flex items-center h-full">
            <span className="truncate">
              {format(new Date(order.updated_at.slice(0, -1)), "dd/MM/yyyy")}
            </span>
          </div>
        ),
        col2: (
          <div className="flex items-center h-full">
            <span className="truncate">{order.payment?.yearly_id}</span>
          </div>
        ),
        col3: (
          <div className="flex items-center h-full">
            <span className="truncate">
              {order.user.name} {order.user.surname}
            </span>
          </div>
        ),
        col4: (
          <div className="flex items-center h-full">
            {Array.from(
              new Set(order.products.map((product) => product.class))
            ).map((productClass, i) => (
              <div key={i}>
                <span className="truncate">{productClass}</span>
              </div>
            ))}
          </div>
        ),
        col5: (
          <div className="flex items-center h-full">
            <span className="truncate">{order.status}</span>
          </div>
        ),
        col6: (
          <div className="flex items-center h-full">
            <span className="truncate">{Round(order.total_price)}</span>
          </div>
        ),
        col7: (
          <div className="flex items-center h-full">
            <span className="truncate">{order.payment?.payment_type}</span>
          </div>
        ),
        col8: (
          <div className="flex items-center h-full">
            <span className="truncate">
              {order.origin === "CMS"
                ? "CMS"
                : lang === "en"
                ? "Website"
                : "Sito web"}
            </span>
          </div>
        ),
        col9: displayActions(order, i),
      })),
    [
      orders.data,
      lang,
      allSelected,
      setAllSelected,
      includeOrders,
      setIncludeOrders,
      excludeOrders,
      setExcludeOrders,
    ]
  );

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: form.getValues("pageSize") },
      pageCount: Math.ceil(orders.data.total / form.getValues("pageSize")),
      manualSortBy: true,
      manualPagination: true,
    },
    useSortBy,
    usePagination,
    useFlexLayout
  );

  const {
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, sortBy },
  } = tableInstance;

  const dispatchNextPage = () =>
    canNextPage
      ? getOrders(pageIndex + 1).then((res) =>
          res.meta.requestStatus === "fulfilled" ? nextPage() : undefined
        )
      : undefined;

  const dispatchPreviousPage = () =>
    canPreviousPage
      ? getOrders(pageIndex - 1).then((res) =>
          res.meta.requestStatus === "fulfilled" ? previousPage() : undefined
        )
      : undefined;

  const dispatchGotoPage = (pageIndex: number) =>
    getOrders(pageIndex).then((res) =>
      res.meta.requestStatus === "fulfilled" ? gotoPage(pageIndex) : undefined
    );

  const debouncedSearchText = useDebounce(
    form.watch("searchString") as string,
    300
  );

  const debouncedFullname = useDebounce(form.watch("fullname") as string, 300);

  useEffect(() => {
    dispatch(getOrdersThunk(constructGetOrderFilters(0)));
  }, [sortBy, debouncedSearchText, debouncedFullname]);

  useEffect(() => {
    if (form.formState.isDirty) {
      dispatch(getOrdersThunk(constructGetOrderFilters(0)));
    }
  }, [
    form.watch("paymentDate.between.0"),
    form.watch("paymentDate.between.1"),
    form.watch("yearlyId.between.0"),
    form.watch("yearlyId.between.1"),
    form.watch("origin.in"),
    form.watch("rank.in"),
    form.watch("productClass.in"),
    form.watch("status.in"),
    form.watch("paymentType.in"),
  ]);

  const handleDeleteOrder = (orderToDelete: string) => {
    dispatch(deleteOrderThunk(orderToDelete))
      .then((res) => {
        if (res.meta.requestStatus === "rejected") {
          toast.error(
            Message({
              action: "delete",
              entity: t("orders.order"),
              error: t("genericError"),
              lang,
              gender: "male",
            })
          );
        } else {
          toast.success(
            Message({
              action: "delete",
              entity: t("orders.order"),
              lang,
              gender: "male",
            })
          );
        }
      })
      .then(() => getOrders(0));
    setOpenDeleteModal(false);
  };

  useEffect(() => {
    dispatch(getUserRanksThunk({ pageSize: 99 }));
  }, []);
  const reduxUserRanks = selectUserRanks();
  const userRanks = clone(reduxUserRanks.data.userRanks);
  const userRankOptions =
    userRanks?.map(function setOptions(rank) {
      return {
        value: rank,
        label: lang === "en" ? rank.rank_eng : rank.rank_it,
      };
    }) || [];

  function showExtraButtons() {
    if (allSelected && excludeOrders.length < orders.data.total) {
      return true;
    }
    if (!allSelected && includeOrders.length) {
      return true;
    }
    return false;
  }

  return (
    <Overlay active={orders.loading || updateLoading} spinner={<Spinner />}>
      <div className="flex flex-col gap-5 p-10 h-full">
        <h1 className="text-3xl font-bold">{t("orders.orders")}</h1>
        <div className="flex flex-wrap gap-5">
          <div className="flex flex-grow flex-wrap gap-5">
            <div className="w-72">
              <Controller
                name="paymentDate.between.0"
                control={form.control}
                render={({ field }) => {
                  return (
                    <ReactDatePicker
                      placeholderText={t("orders.startPaymentYear")}
                      selected={field.value as any}
                      onChange={(date) => field.onChange(date)}
                      showYearPicker
                      dateFormat="yyyy"
                      yearItemNumber={9}
                      showPopperArrow={false}
                      className={getInputStyles(false)}
                    />
                  );
                }}
              />
            </div>
            <div className="w-72">
              <Controller
                name="paymentDate.between.1"
                control={form.control}
                render={({ field }) => {
                  return (
                    <ReactDatePicker
                      placeholderText={t("orders.endPaymentYear")}
                      selected={field.value as any}
                      onChange={(date) => field.onChange(date)}
                      showYearPicker
                      dateFormat="yyyy"
                      yearItemNumber={9}
                      showPopperArrow={false}
                      className={getInputStyles(false)}
                    />
                  );
                }}
              />
            </div>
            <div className="w-72">
              <input
                type="text"
                {...form.register("yearlyId.between.0")}
                className={getInputStyles(false)}
                placeholder={t("orders.minOrderNumber")}
              />
            </div>
            <div className="w-72">
              <input
                type="text"
                {...form.register("yearlyId.between.1")}
                className={getInputStyles(false)}
                placeholder={t("orders.maxOrderNumber")}
              />
            </div>
            <div className="w-72">
              <input
                type="text"
                {...form.register("fullname")}
                className={getInputStyles(false)}
                placeholder={t("orders.userFullname")}
              />
            </div>
            <div className="w-72">
              <Controller
                name="origin.in"
                control={form.control}
                render={({ field }) => {
                  return (
                    <Select
                      placeholder={t(`orders.origin`)}
                      styles={getReactSelectStyles(false)}
                      isMulti={true}
                      isClearable={true}
                      onChange={function (e) {
                        if (e) {
                          field.onChange(e);
                        }
                      }}
                      options={orderOriginOptions(lang)}
                    />
                  );
                }}
              />
            </div>
            <div className="w-72">
              <Controller
                name="rank.in"
                control={form.control}
                render={({ field }) => {
                  return (
                    <Select
                      placeholder={t("selectUser.selectRank")}
                      styles={getReactSelectStyles(false)}
                      inputId="rank"
                      isMulti={true}
                      isClearable={true}
                      onChange={function (e) {
                        if (e) {
                          field.onChange(e);
                        }
                      }}
                      options={userRankOptions}
                      isLoading={reduxUserRanks.loading}
                    />
                  );
                }}
              />
            </div>
            <div className="w-72">
              <Controller
                name="productClass.in"
                control={form.control}
                render={({ field }) => {
                  return (
                    <Select
                      placeholder={t(`orders.productClass`)}
                      styles={getReactSelectStyles(false)}
                      isMulti={true}
                      isClearable={true}
                      onChange={function (e) {
                        if (e) {
                          field.onChange(e);
                        }
                      }}
                      options={productsClassesOptions(lang)}
                    />
                  );
                }}
              />
            </div>
            <div className="w-72">
              <Controller
                name="status.in"
                control={form.control}
                render={({ field }) => {
                  return (
                    <Select
                      placeholder={t("orders.status")}
                      styles={getReactSelectStyles(false)}
                      isMulti={true}
                      isClearable={true}
                      onChange={function (e) {
                        if (e) {
                          field.onChange(e);
                        }
                      }}
                      options={orderStatusOptions(lang)}
                    />
                  );
                }}
              />
            </div>
            <div className="w-72">
              <Controller
                name="paymentType.in"
                control={form.control}
                render={({ field }) => {
                  return (
                    <Select
                      placeholder={t("orders.paymentType")}
                      styles={getReactSelectStyles(false)}
                      isMulti={true}
                      isClearable={true}
                      onChange={function (e) {
                        if (e) {
                          field.onChange(e);
                        }
                      }}
                      options={paymentTypeOptions(lang)}
                    />
                  );
                }}
              />
            </div>
            <div className="flex w-72 border focus-within:border-global-input-focus border-gray-300">
              <div className="flex items-center justify-center p-2 bg-white">
                <SearchIcon className="w-5 h-5 text-gray-400" />
              </div>
              <input
                type="text"
                placeholder={t("orders.userId")}
                {...form.register("searchString")}
                className="placeholder-gray-400 p-2 w-full border-0 focus:ring-0"
              />
            </div>
            {showExtraButtons() && canAccess("s", "READ") ? (
              <div>
                <button
                  type="button"
                  className="px-4 py-2 text-white bg-primary border border-primary rounded-sm"
                  disabled={exportLoading}
                  onClick={() => {
                    let tempExportOrders;
                    setExportLoading(true);
                    if (allSelected) {
                      tempExportOrders = exportOrders({
                        filters: {
                          ...constructExportOrdersFilters(),
                        },
                        exclude: excludeOrders,
                      });
                    } else {
                      tempExportOrders = exportOrders({
                        filters: {
                          ...constructExportOrdersFilters(),
                        },
                        include: includeOrders,
                      });
                    }
                    tempExportOrders
                      .then((res) => {
                        generateOrdersPdfs(res.data.orders);
                      })
                      .catch((err: any) => {
                        if (err.response?.data?.errors?.[0]?.message) {
                          toast.error(err.response?.data?.errors?.[0]?.message);
                        } else {
                          toast.error(err.response?.data?.message);
                        }
                      })
                      .finally(() => setExportLoading(false));
                    toast.promise(tempExportOrders, {
                      pending: t("order.exporting"),
                      success: t("order.exportFinished"),
                      error: StrMessage({
                        action: "read",
                        entity: t("orders.orders"),
                        error: t("genericError"),
                        lang,
                      }),
                    });
                  }}
                >
                  {t("order.export")}
                </button>
              </div>
            ) : null}
            {showExtraButtons() && canAccess("s", "UPDATE") ? (
              <Popover className="relative">
                <Popover.Button>
                  <button
                    type="button"
                    className="px-4 py-2 text-white bg-primary border border-primary rounded-sm"
                  >
                    {t("receipts.updateStatus")}
                  </button>
                </Popover.Button>
                <Popover.Panel
                  className="absolute left-0 top-0 flex flex-col gap-2 mt-16 p-2 w-full bg-white rounded-sm shadow-md"
                  style={{ zIndex: 9990 }}
                >
                  <button
                    onClick={() => {
                      setUpdateStatus("cart");
                      setUpdateModalOpen(true);
                    }}
                  >
                    {t("orders.cart")}
                  </button>
                  <button
                    onClick={() => {
                      setUpdateStatus("to be shipped");
                      setUpdateModalOpen(true);
                    }}
                  >
                    {t("orders.toBeShipped")}
                  </button>
                  <button
                    onClick={() => {
                      setUpdateStatus("completed");
                      setUpdateModalOpen(true);
                    }}
                  >
                    {t("orders.completed")}
                  </button>
                </Popover.Panel>
              </Popover>
            ) : null}
            {showExtraButtons() && canAccess("s", "DELETE") ? (
              <button
                className="px-4 py-2 text-white bg-red-500 border border-red-500 rounded-sm"
                onClick={() => {
                  deleteOrdersHelper();
                }}
              >
                {t("crud.delete")}
              </button>
            ) : null}
          </div>

          <div className="flex flex-grow flex-wrap gap-5 justify-end">
            {canAccess("s", "CREATE") && (
              <Link to="/orders/create" className="h-full">
                <button className="flex gap-2 items-center justify-center px-4 py-2 w-full text-white whitespace-nowrap bg-global-createEntity border border-global-createEntity">
                  <PlusIcon className="w-5 h-5" />
                  <span>{t("orders.createOrder")}</span>
                </button>
              </Link>
            )}
          </div>
        </div>
        <Table {...tableInstance} />
        <Pagination
          rowsCount={orders.data.total}
          pageIndex={pageIndex}
          pageSize={form.getValues("pageSize")}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          pageOptions={pageOptions}
          pageCount={pageCount}
          gotoPage={dispatchGotoPage}
          nextPage={dispatchNextPage}
          previousPage={dispatchPreviousPage}
          setPageSize={setPageSize}
        />
        <TablePopup
          isOpen={portal.open}
          close={() => setPortal((state: any) => ({ ...state, open: false }))}
        >
          {portalContent()}
        </TablePopup>
        <Modal
          openStatus={openDeleteModal}
          setOpen={setOpenDeleteModal}
          icon={
            <ExclamationIcon
              className="w-6 h-6 text-red-600 bg-transparent"
              aria-hidden="true"
            />
          }
          header={
            <p>
              {t("deleteModal.delete")} {t("orders.order")}
            </p>
          }
          title={
            <p>
              {t("deleteModal.sure")} {t("orders.order")} ?{" "}
              {t("deleteModal.undone")}
            </p>
          }
          footer={
            <div className="flex justify-end mt-5 sm:mt-4">
              <button
                type="button"
                className="inline-flex justify-center mt-3 px-4 py-1 w-full text-black text-base font-medium bg-white border border-black focus:outline-none shadow-sm focus:ring-0 sm:mt-0 sm:w-auto"
                onClick={() => setOpenDeleteModal(false)}
              >
                {t("deleteModal.cancel")}
              </button>
              <button
                type="button"
                className="inline-flex justify-center px-4 py-1 w-full text-white text-base font-medium bg-red-600 border border-transparent focus:outline-none shadow-sm focus:ring-0 sm:ml-3 sm:w-auto"
                onClick={() => handleDeleteOrder(orderToDelete)}
              >
                {t("deleteModal.delete")}
              </button>
            </div>
          }
        />
        <Modal
          openStatus={updateModalOpen}
          setOpen={setUpdateModalOpen}
          icon={
            <ExclamationIcon
              className="w-6 h-6 text-primary bg-transparent"
              aria-hidden="true"
            />
          }
          header={
            <p>
              {t("crud.update")} {t("orders.orders")}
            </p>
          }
          title={
            <p>
              {t("updateModal.sure")} {t("orders.orders")} ?{" "}
              {t("updateModal.undone")}
            </p>
          }
          footer={
            <div className="flex justify-end mt-5 sm:mt-4">
              <button
                type="button"
                className="inline-flex justify-center mt-3 px-4 py-1 w-full text-black text-base font-medium bg-white border border-black focus:outline-none shadow-sm focus:ring-0 sm:mt-0 sm:w-auto"
                onClick={() => setUpdateModalOpen(false)}
              >
                {t("updateModal.cancel")}
              </button>
              <button
                type="button"
                className="inline-flex justify-center px-4 py-1 w-full text-white text-base font-medium bg-primary border border-transparent focus:outline-none shadow-sm focus:ring-0 sm:ml-3 sm:w-auto"
                onClick={() => {
                  if (updateStatus) {
                    updateOrdersHelper(updateStatus);
                  }
                }}
              >
                {t("updateModal.update")}
              </button>
            </div>
          }
        />
      </div>
    </Overlay>
  );
}
