import { Tag } from "shared/interfaces/Tag.interface";

export interface TagsState {
  message: string;
  data: {
    total: number;
    pageIndex: 0;
    tags: Tag[];
  };
  loading: boolean;
}

export const tagsInitialState: TagsState = {
  message: "",
  data: {
    total: 0,
    pageIndex: 0,
    tags: [],
  },
  loading: false,
};
