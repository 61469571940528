import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";

export default function ScreenModal({
  children,
  isOpen,
  setIsOpen,
}: {
  children: React.ReactNode;
  isOpen: boolean;
  setIsOpen?: (a: boolean) => void;
}) {
  function closeModal() {
    if (setIsOpen) setIsOpen(false);
  }
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 left-0 top-0 flex flex-col items-center justify-center"
          onClose={closeModal}
        >
          <div className="w-screen h-screen">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div
                style={{ background: "rgba(0, 0, 0, 0.5)" }}
                className="w-full h-full bg-transparent transform transition-all"
              >
                <div className="flex items-center justify-center w-full h-full rounded-sm overflow-auto">
                  {children}
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
