import React, { useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import Overlay from "shared/components/UI/Overlay";
import { useNavigate } from "react-router";
import Spinner from "shared/components/UI/Spinner";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { CMS_BE_URL } from "api/urls";
import axios from "axios";
import { useParams } from "react-router";
import { SponsorUpdateForm } from "./types";
import GoBack from "shared/components/UI/GoBack";
import { getInputStyles } from "utils/utils";
import DisplayInputError from "shared/components/UI/DisplayInputError";
import SimpleImageDropzone from "shared/components/UI/SimpleImageDropzone";
import EditImage from "shared/components/UI/EditImage";
import { selectTranslationLanguage } from "store/translationStore/translationReducer";
import CropImage from "shared/components/UI/CropImage";

const EditSponsor: () => JSX.Element = () => {
  const lang = selectTranslationLanguage();
  const { t } = useTranslation("common");
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const [editImageOn, setEditImageOn] = useState(false);

  useEffect(() => {
    function fetchSponsor() {
      setLoading(true);
      axios
        .get(`${CMS_BE_URL}/sponsors/${id}`)
        .then((res) => {
          setValue("name", res.data.data.name);
          setValue("url", res.data.data.url);
          setValue("logo", res.data.data.logo);
        })
        .catch((err) => {
          if (err.response?.data?.errors?.[0]?.message) {
            toast.error(err.response?.data?.errors?.[0]?.message);
          } else {
            toast.error(err.response?.data?.message);
          }

          setTimeout(() => {
            navigate("/sponsors");
          }, 2000);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    fetchSponsor();
  }, []);

  const form = useForm<SponsorUpdateForm>({
    mode: "onChange",
    defaultValues: {
      fileType: "sponsor_doc",
      name: "",
      url: "",
      logo: null,
    },
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    control,
    trigger,
    setError,
    watch,
    clearErrors,
  } = form;

  const sponsorCreatedErrorMsg = (msg: string) => (
    <div className="flex flex-col gap-1">
      <p className="text-xs font-semibold">{t("sponsors.error")}</p>
      <p className="text-xs">{msg}</p>
    </div>
  );

  const sponsorCreatedMsg = () => (
    <div className="flex flex-col gap-1">
      <p className="text-xs font-semibold">{t("sponsors.msgHeaderE")}</p>
      <p className="text-xs">{t("sponsors.sponsorUpdated")}</p>
    </div>
  );

  const displaySponsorCreatedMsg = () => {
    toast.success(sponsorCreatedMsg);
  };

  const displaySponsorCreatedErrorMsg = (msg: string) => {
    toast.error(sponsorCreatedErrorMsg(msg));
  };

  function constructSponsorData(data: SponsorUpdateForm) {
    if (data.logo && typeof data.logo === "object") {
      return data;
    }

    setLoading(true);

    return {
      fileType: "sponsor_doc",
      name: data.name,
      url: data.url,
    };
  }

  const onSubmit = handleSubmit((data) => {
    setLoading(true);
    data = { ...data };
    if (data.url !== "") {
      if (data.url.startsWith("HTTPS://")) {
        data.url = data.url.toLowerCase();
        setValue("url", data.url.toLowerCase());
      }
      if (data.url.startsWith("http://")) {
        data.url = data.url.replace("http://", "https://");
      }
      if (!data.url.startsWith("https://")) {
        data.url = "https://" + data.url;
        setValue("url", "https://" + data.url);
      }
    }

    data = constructSponsorData(data) as SponsorUpdateForm;
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]: any) => {
      if (Array.isArray(value)) {
        value = JSON.stringify(value);
      }
      formData.append(key, value);
    });

    axios
      .put(`${CMS_BE_URL}/sponsors/${id}`, formData)
      .then(() => {
        displaySponsorCreatedMsg();
        navigate("/sponsors");
      })
      .catch((err) => {
        displaySponsorCreatedErrorMsg(err?.response?.data?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  });

  return (
    <Overlay active={loading} spinner={<Spinner />}>
      <FormProvider {...form}>
        <form
          className="flex flex-col gap-5 p-10 w-full h-full bg-gray-100"
          onSubmit={onSubmit}
        >
          <GoBack uri="sponsors" />
          <div className="item-center flex gap-5 justify-between">
            <h1 className="text-3xl font-bold">{t("sponsors.sponsors")}</h1>
            {/* <p className="py-3 text-sm">{t("sponsors.p")}</p> */}
            <button
              type="submit"
              className="px-4 py-2 text-white bg-primary border border-primary"
            >
              {t("UserCRUD.publish")}
            </button>
          </div>
          <div className="flex flex-col gap-5">
            <div className="flex gap-5 w-full">
              <div className="flex flex-col gap-2 w-full">
                <label htmlFor="name" className="block text-lg font-medium">
                  {t("sponsors.name")} *
                </label>
                <input
                  id="name"
                  type="text"
                  placeholder={t("sponsors.name")}
                  className={getInputStyles(!!errors.name?.message)}
                  {...register("name", { required: `Name is required.` })}
                />
                <DisplayInputError message={errors.name?.message} />
              </div>
              <div className="flex flex-col gap-2 w-full">
                <label htmlFor="url" className="block text-lg font-medium">
                  URL
                </label>
                <input
                  id="url"
                  placeholder="https://..."
                  type="text"
                  className={getInputStyles(!!errors.url?.message)}
                  defaultValue=""
                  {...register("url")}
                />
                <DisplayInputError message={errors.url?.message} />
              </div>
            </div>
            <div
              className="flex flex-col gap-2 m-auto w-full"
              style={{ maxWidth: "400px" }}
            >
              <label htmlFor="logo" className="text-lg font-medium">
                Sponsor logo *
              </label>
              <Controller
                name="logo"
                control={control}
                rules={{
                  required: `Sponsor image is required.`,
                  validate: {
                    checkSize: (logo) => {
                      if (logo && logo.size > 2 * 1048576)
                        return `${t("new.size")} 2MB`;
                      return true;
                    },
                  },
                }}
                render={() => (
                  <SimpleImageDropzone
                    clearErrors={clearErrors}
                    trigger={trigger}
                    setError={setError}
                    setValue={setValue}
                    watch={watch}
                    reactHookFormName="logo"
                    setEditImageOn={setEditImageOn}
                    aspect={1}
                  />
                )}
              />
              {editImageOn ? (
                <CropImage
                  editImageOn={editImageOn}
                  setEditImageOn={setEditImageOn}
                  reactHookFormName="logo"
                  aspect={1}
                  canForce={true}
                  canClose={true}
                  maxHeight={500}
                  maxWidth={500}
                />
              ) : null}
              <DisplayInputError message={(errors.logo as any)?.message} />
            </div>
          </div>
        </form>
      </FormProvider>
    </Overlay>
  );
};

export default EditSponsor;
