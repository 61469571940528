import React, { Fragment, useEffect, useState } from "react";
import { useAppDispatch } from "store/storeHooks";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import Overlay from "shared/components/UI/Overlay";
import Spinner from "shared/components/UI/Spinner";
import GoBack from "shared/components/UI/GoBack";
import { selectTranslationLanguage } from "store/translationStore/translationReducer";
import { Switch } from "@headlessui/react";
import {
  Controller,
  SubmitHandler,
  useForm,
  useFieldArray,
  FormProvider,
} from "react-hook-form";
import { PageMod } from "shared/interfaces/PageMod.interface";
import { findAllByTestId } from "@testing-library/react";
import { deleteTagURL } from "api/Tags/urls";
import { toast } from "react-toastify";
import { CMS_BE_URL } from "api/urls";
import axios from "axios";

type crud = "create" | "read" | "update" | "delete";
type CrudType = "ANY" | "OWN" | "NONE";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

const EditRole: () => JSX.Element = () => {
  const lang = selectTranslationLanguage();
  const [t, i18n] = useTranslation("common");
  const [loading, setLoading] = useState(false);

  const getData = async () => {
    setLoading(true);
    try {
      const res = await axios.get(`${CMS_BE_URL}/pages`);
      res.data.data.map((v: any) => {
        setValue(v.page, v.visibility);
      });
    } catch (err: any) {
      if (err.response?.data?.errors?.[0]?.message) {
        toast.error(err.response?.data?.errors?.[0]?.message);
      } else {
        toast.error(err.response?.data?.message);
      }
    }
    setLoading(false);
  };

  //fetch the data
  useEffect(() => {
    getData();
  }, []);

  const form = useForm<PageMod>({
    mode: "onChange",
    // defaultValues: {
    //   documents: null,
    //   aboutUs: true,
    //   news: true,
    //   press: true,
    //   events: true,
    //   exams: true,
    //   shop: true,
    //   teachers: true,
    // },
  });

  const {
    register,
    handleSubmit,
    clearErrors,
    watch,
    getValues,
    control,
    formState: { errors },
    setValue,
    trigger,
    setError,
  } = form;

  const onSubmit = async (data: any) => {
    setLoading(true);
    let pages: any[] = [];

    for (const key in data) {
      pages = [...pages, { page: key, visibility: data[key] }];
    }
    try {
      const res = await axios.put(
        `${CMS_BE_URL}/pages`,
        { pages },
        {
          headers: {
            "Content-type": "application/json",
          },
        }
      );
      toast.success(t("pageMod.success"));
    } catch (err: any) {
      if (err.response?.data?.errors?.[0]?.message) {
        toast.error(err.response?.data?.errors?.[0]?.message);
      } else {
        toast.error(err.response?.data?.message);
      }
    }
    setLoading(false);
  };

  return (
    <Fragment>
      <Overlay spinner={<Spinner />} active={loading}>
        <div className="roles flex flex-col gap-5 h-full overflow-y-auto">
          <form onSubmit={handleSubmit(onSubmit)} className="">
            <div className="flex items-center justify-between mb-5">
              <div>
                <h1 className="mb-2 text-xl font-bold">{t("pageMod.h1")}</h1>

                <h2 className="text-roles-description text-lg">
                  {t("pageMod.h2")}
                </h2>
              </div>
              <div>
                <button
                  type="submit"
                  className="mt-10 px-4 py-2 text-white bg-users-create"
                >
                  {t("order.save")}
                </button>
              </div>
            </div>

            <div className="border-b border-gray-200 rounded-sm">
              <table className="min-w-full divide-gray-200 divide-y">
                <tbody className="bg-white divide-gray-200 divide-y">
                  <tr>
                    <td className="flex gap-10 justify-between pl-4 py-5 w-1/6">
                      <div className="text-lg">{t("sidebar.documents")}</div>
                      <Controller
                        name="documents"
                        control={control}
                        render={(props) => {
                          return (
                            <Switch
                              checked={getValues("documents")}
                              onChange={() => {
                                setValue("documents", !getValues("documents"));
                              }}
                              className={classNames(
                                getValues("documents")
                                  ? "bg-users-create"
                                  : "bg-gray-200",
                                "relative inline-flex flex-shrink-0 w-11 h-6 border-2 border-transparent rounded-full focus:outline-none cursor-pointer transition-colors duration-200 ease-in-out focus:ring-2 focus:ring-users-create focus:ring-offset-2"
                              )}
                            >
                              <span className="sr-only">Use setting</span>
                              <span
                                aria-hidden="true"
                                className={classNames(
                                  getValues("documents")
                                    ? "translate-x-5"
                                    : "translate-x-0",
                                  "inline-block w-5 h-5 bg-white rounded-full shadow pointer-events-none transform transition duration-200 ease-in-out ring-0"
                                )}
                              />
                            </Switch>
                          );
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="flex gap-10 justify-between pl-4 py-5 w-1/6">
                      <div className="text-lg">{t("sidebar.aboutUs")}</div>
                      <Controller
                        name="aboutUs"
                        control={control}
                        render={(props) => {
                          return (
                            <Switch
                              checked={getValues("aboutUs")}
                              onChange={() => {
                                setValue("aboutUs", !getValues("aboutUs"));
                              }}
                              className={classNames(
                                getValues("aboutUs")
                                  ? "bg-users-create"
                                  : "bg-gray-200",
                                "relative inline-flex flex-shrink-0 w-11 h-6 border-2 border-transparent rounded-full focus:outline-none cursor-pointer transition-colors duration-200 ease-in-out focus:ring-2 focus:ring-users-create focus:ring-offset-2"
                              )}
                            >
                              <span className="sr-only">Use setting</span>
                              <span
                                aria-hidden="true"
                                className={classNames(
                                  getValues("aboutUs")
                                    ? "translate-x-5"
                                    : "translate-x-0",
                                  "inline-block w-5 h-5 bg-white rounded-full shadow pointer-events-none transform transition duration-200 ease-in-out ring-0"
                                )}
                              />
                            </Switch>
                          );
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="flex gap-10 justify-between pl-4 py-5 w-1/6">
                      <div className="text-lg">{t("sidebar.news")}</div>
                      <Controller
                        name="news"
                        control={control}
                        render={(props) => {
                          return (
                            <Switch
                              checked={getValues("news")}
                              onChange={() => {
                                setValue("news", !getValues("news"));
                              }}
                              className={classNames(
                                getValues("news")
                                  ? "bg-users-create"
                                  : "bg-gray-200",
                                "relative inline-flex flex-shrink-0 w-11 h-6 border-2 border-transparent rounded-full focus:outline-none cursor-pointer transition-colors duration-200 ease-in-out focus:ring-2 focus:ring-users-create focus:ring-offset-2"
                              )}
                            >
                              <span className="sr-only">Use setting</span>
                              <span
                                aria-hidden="true"
                                className={classNames(
                                  getValues("news")
                                    ? "translate-x-5"
                                    : "translate-x-0",
                                  "inline-block w-5 h-5 bg-white rounded-full shadow pointer-events-none transform transition duration-200 ease-in-out ring-0"
                                )}
                              />
                            </Switch>
                          );
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="flex gap-10 justify-between pl-4 py-5 w-1/6">
                      <div className="text-lg">{t("sidebar.exams")}</div>
                      <Controller
                        name="exams"
                        control={control}
                        render={(props) => {
                          return (
                            <Switch
                              checked={getValues("exams")}
                              onChange={() => {
                                setValue("exams", !getValues("exams"));
                              }}
                              className={classNames(
                                getValues("exams")
                                  ? "bg-users-create"
                                  : "bg-gray-200",
                                "relative inline-flex flex-shrink-0 w-11 h-6 border-2 border-transparent rounded-full focus:outline-none cursor-pointer transition-colors duration-200 ease-in-out focus:ring-2 focus:ring-users-create focus:ring-offset-2"
                              )}
                            >
                              <span className="sr-only">Use setting</span>
                              <span
                                aria-hidden="true"
                                className={classNames(
                                  getValues("exams")
                                    ? "translate-x-5"
                                    : "translate-x-0",
                                  "inline-block w-5 h-5 bg-white rounded-full shadow pointer-events-none transform transition duration-200 ease-in-out ring-0"
                                )}
                              />
                            </Switch>
                          );
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="flex gap-10 justify-between pl-4 py-5 w-1/6">
                      <div className="text-lg">{t("sidebar.events")}</div>
                      <Controller
                        name="events"
                        control={control}
                        render={(props) => {
                          return (
                            <Switch
                              checked={getValues("events")}
                              onChange={() => {
                                setValue("events", !getValues("events"));
                              }}
                              className={classNames(
                                getValues("events")
                                  ? "bg-users-create"
                                  : "bg-gray-200",
                                "relative inline-flex flex-shrink-0 w-11 h-6 border-2 border-transparent rounded-full focus:outline-none cursor-pointer transition-colors duration-200 ease-in-out focus:ring-2 focus:ring-users-create focus:ring-offset-2"
                              )}
                            >
                              <span className="sr-only">Use setting</span>
                              <span
                                aria-hidden="true"
                                className={classNames(
                                  getValues("events")
                                    ? "translate-x-5"
                                    : "translate-x-0",
                                  "inline-block w-5 h-5 bg-white rounded-full shadow pointer-events-none transform transition duration-200 ease-in-out ring-0"
                                )}
                              />
                            </Switch>
                          );
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="flex gap-10 justify-between pl-4 py-5 w-1/6">
                      <div className="text-lg">{t("sidebar.press")}</div>
                      <Controller
                        name="press"
                        control={control}
                        render={(props) => {
                          return (
                            <Switch
                              checked={getValues("press")}
                              onChange={() => {
                                setValue("press", !getValues("press"));
                              }}
                              className={classNames(
                                getValues("press")
                                  ? "bg-users-create"
                                  : "bg-gray-200",
                                "relative inline-flex flex-shrink-0 w-11 h-6 border-2 border-transparent rounded-full focus:outline-none cursor-pointer transition-colors duration-200 ease-in-out focus:ring-2 focus:ring-users-create focus:ring-offset-2"
                              )}
                            >
                              <span className="sr-only">Use setting</span>
                              <span
                                aria-hidden="true"
                                className={classNames(
                                  getValues("press")
                                    ? "translate-x-5"
                                    : "translate-x-0",
                                  "inline-block w-5 h-5 bg-white rounded-full shadow pointer-events-none transform transition duration-200 ease-in-out ring-0"
                                )}
                              />
                            </Switch>
                          );
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="flex gap-10 justify-between pl-4 py-5 w-1/6">
                      <div className="text-lg">{t("pageMod.t")}</div>
                      <Controller
                        name="teachers"
                        control={control}
                        render={(props) => {
                          return (
                            <Switch
                              checked={getValues("teachers")}
                              onChange={() => {
                                setValue("teachers", !getValues("teachers"));
                              }}
                              className={classNames(
                                getValues("teachers")
                                  ? "bg-users-create"
                                  : "bg-gray-200",
                                "relative inline-flex flex-shrink-0 w-11 h-6 border-2 border-transparent rounded-full focus:outline-none cursor-pointer transition-colors duration-200 ease-in-out focus:ring-2 focus:ring-users-create focus:ring-offset-2"
                              )}
                            >
                              <span className="sr-only">Use setting</span>
                              <span
                                aria-hidden="true"
                                className={classNames(
                                  getValues("teachers")
                                    ? "translate-x-5"
                                    : "translate-x-0",
                                  "inline-block w-5 h-5 bg-white rounded-full shadow pointer-events-none transform transition duration-200 ease-in-out ring-0"
                                )}
                              />
                            </Switch>
                          );
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="flex gap-10 justify-between pl-4 py-5 w-1/6">
                      <div className="text-lg">Shop</div>
                      <Controller
                        name="shop"
                        control={control}
                        render={(props) => {
                          return (
                            <Switch
                              checked={getValues("shop")}
                              onChange={() => {
                                setValue("shop", !getValues("shop"));
                              }}
                              className={classNames(
                                getValues("shop")
                                  ? "bg-users-create"
                                  : "bg-gray-200",
                                "relative inline-flex flex-shrink-0 w-11 h-6 border-2 border-transparent rounded-full focus:outline-none cursor-pointer transition-colors duration-200 ease-in-out focus:ring-2 focus:ring-users-create focus:ring-offset-2"
                              )}
                            >
                              <span className="sr-only">Use setting</span>
                              <span
                                aria-hidden="true"
                                className={classNames(
                                  getValues("shop")
                                    ? "translate-x-5"
                                    : "translate-x-0",
                                  "inline-block w-5 h-5 bg-white rounded-full shadow pointer-events-none transform transition duration-200 ease-in-out ring-0"
                                )}
                              />
                            </Switch>
                          );
                        }}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </form>
        </div>
      </Overlay>
    </Fragment>
  );
};

export default EditRole;
