import React from "react";
import { ArrowNarrowLeftIcon } from "@heroicons/react/outline";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

export default function GoBack({ uri }: { uri: string }) {
  const navigate = useNavigate();
  const { t } = useTranslation("common");
  return (
    <div>
      <button
        type="button"
        className="flex gap-5 items-center text-global-goBack"
        onClick={() => {
          navigate(`/${uri}`);
        }}
      >
        <ArrowNarrowLeftIcon className="w-4 h-4" />{" "}
        <span>{t("goBack.goBack")}</span>
      </button>
    </div>
  );
}
