import axios from "axios";
import {
  CreateUserRank,
  UpdateUserRank,
} from "shared/interfaces/Rank.interface";
import {
  createUserRankURL,
  deleteUserRankURL,
  getUserRanksURL,
  getUserRankURL,
  updateUserRankURL,
} from "./urls";

export function createUserRank({ data }: { data: CreateUserRank }) {
  return axios.post(createUserRankURL(), data);
}

export function getUserRanks(filters: {
  pageSize?: number;
  pageIndex?: number;
  sortCol?: string;
  sortOrder?: string;
}) {
  return axios.get(getUserRanksURL(filters));
}

export function deleteUserRank({ rankId }: { rankId: string }) {
  return axios.delete(deleteUserRankURL({ rankId }));
}

export function getUserRank({ rankId }: { rankId: string }) {
  return axios.get(getUserRankURL({ rankId }));
}

export function updateUserRank({
  rankId,
  data,
}: {
  rankId: string;
  data: UpdateUserRank;
}) {
  return axios.put(updateUserRankURL({ rankId }), data);
}
