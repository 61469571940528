import React from "react";

export type Lang = "en" | "it";
type Translation = {
  en: {
    messages: { success: string; fail: string };
    crud: {
      create: string;
      read: string;
      update: string;
      delete: string;
      send: string;
    };
  };
  it: {
    messages: {
      success: string;
      fail: {
        article: string;
        adjective: string;
      };
    };
    male: {
      crud: {
        create: string;
        read: string;
        update: string;
        delete: string;
        send: string;
      };
    };
    female: {
      crud: {
        create: string;
        read: string;
        update: string;
        delete: string;
        send: string;
      };
    };
  };
};

const t: Translation = {
  en: {
    messages: {
      success: "has been successfully",
      fail: "has not been",
    },
    crud: {
      create: "created",
      read: "retrieved",
      update: "updated",
      delete: "deleted",
      send: "send",
    },
  },
  it: {
    messages: {
      success: "con successo",
      fail: {
        article: "non",
        adjective: "con successo",
      },
    },
    male: {
      crud: {
        create: "creato",
        read: "retrieved",
        update: "aggiornato",
        delete: "eliminato",
        send: "send",
      },
    },
    female: {
      crud: {
        create: "creata",
        read: "retrieved",
        update: "aggiornata",
        delete: "eliminata",
        send: "send",
      },
    },
  },
};

function displayMessage({
  action,
  entity,
  error,
  lang = "en",
  gender = "male",
}: {
  action: "create" | "update" | "delete" | "read" | "send";
  entity: string;
  error?: string;
  lang: Lang;
  gender: "male" | "female";
}) {
  if (lang === "en") {
    if (error) {
      return `${entity} ${t[lang].messages.fail} ${t[lang].crud[action]}.`;
    } else {
      return `${entity} ${t[lang].messages.success} ${t[lang].crud[action]}.`;
    }
  } else {
    if (error) {
      return `${entity} ${t[lang].messages.fail.article} ${t[lang][gender].crud[action]} ${t[lang].messages.fail.adjective}.`;
    } else {
      return `${entity} ${t[lang][gender].crud[action]} ${t[lang].messages.success}.`;
    }
  }
  // const messages = {
  //   create: {
  //     success: `${entity} ${t[lang].messages.success} ${t[lang].pastCrud.created}.`,
  //     fail: `${entity} ${t[lang].messages.fail} ${t[lang].pastCrud.created}.`,
  //   },
  //   update: {
  //     success: `${entity} ${t[lang].messages.success} ${t[lang].pastCrud.updated}.`,
  //     fail: `${entity} ${t[lang].messages.fail} ${t[lang].pastCrud.updated}.`,
  //   },
  //   delete: {
  //     success: `${entity} ${t[lang].messages.success} ${t[lang].pastCrud.deleted}.`,
  //     fail: `${entity} ${t[lang].messages.fail} ${t[lang].pastCrud.deleted}.`,
  //   },
  //   read: {
  //     success: `${entity} ${t[lang].messages.success} ${t[lang].pastCrud.retrieved}.`,
  //     fail: `${entity} ${t[lang].messages.fail} ${t[lang].pastCrud.retrieved}.`,
  //   },
  //   send: {
  //     success: `${entity} ${t[lang].messages.success} ${t[lang].pastCrud.send}.`,
  //     fail: `${entity} ${t[lang].messages.fail} ${t[lang].pastCrud.send}.`,
  //   },
  // };
}

export function StrMessage({
  action,
  entity,
  error,
  lang = "en",
  gender = "male",
}: {
  action: "create" | "update" | "delete" | "read" | "send";
  entity: string;
  error?: string;
  lang: Lang;
  gender?: "male" | "female";
}) {
  if (error) {
    return displayMessage({
      action,
      entity,
      error,
      lang,
      gender,
    });
  }
  return displayMessage({
    action,
    entity,
    error,
    lang,
    gender,
  });
}

export default function Message({
  action,
  entity,
  error,
  lang = "en",
  gender = "male",
}: {
  action: "create" | "update" | "delete" | "read" | "send";
  entity: string;
  error?: string;
  lang: Lang;
  gender?: "male" | "female";
}) {
  if (error) {
    return (
      <div className="flex flex-col gap-1">
        <p className="text-sm font-medium">
          {displayMessage({
            action,
            entity,
            error,
            lang,
            gender,
          })}
        </p>
        <p className="text-xs">{error}</p>
      </div>
    );
  }
  return (
    <div className="flex flex-col">
      <p className="text-sm font-medium">
        {displayMessage({
          action,
          entity,
          error,
          lang,
          gender,
        })}
      </p>
    </div>
  );
}
