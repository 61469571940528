import { GroupBase, OptionsOrGroups } from "react-select";
import { Lang } from "shared/components/UI/Message";
import { Coupon } from "./Coupon.interface";
import { ProductClassEnum } from "./Product.new.interface";

export type OrderOrigin = "FE" | "CMS";
export type OrderStatusEnum =
  | "cart"
  | "to be shipped"
  | "completed"
  | "canceled";
export type PaymentTypeEnum =
  | "stripe"
  | "cash"
  | "bank_transfer"
  | "postal"
  | "gift";
export type PaymentStatusEnum = "pending" | "paid" | "refunded";
export type MemberStatusEnum =
  | "NORMAL_USER"
  | "ACTIVE"
  | "EXPIRED"
  | "DiMISSIONARY";
export type UserStatusEnum =
  | "ACTIVE"
  | "RESET"
  | "DELETED"
  | "DISABLED"
  | "INACTIVE"
  | "LOCKED";

export type OrderBase = {
  total_price: number;
  id: string;
  anmb_id: number;
  customer_id: string;
  status: OrderStatusEnum;
  billing_address: string;
  shipping_address: string;
  subtotal: number;
  shipping: number;
  created_at: string;
  updated_at: string;
  coupon: null | Coupon;
  origin: OrderOrigin;
};

export type OrderAddress = {
  location_id: string;
  region: string;
  province: string;
  city: string;
  postal_code: string;
  street_name: string;
  street_number: string;
  longitude: null | number;
  latitude: null | number;
};

export type Product = {
  id: string;
  title: string;
  slug: string;
  short_description: string;
  description: string;
  sku: string;
  size: string;
  color: string;
  price: number;
  quantity: number;
  thumbnail: string | null;
  subscription_id: null;
  vat_percentage: number;
  created_at: string;
  updated_at: string;
  class: ProductClassEnum;
  last_modifier: string;
  creator_id: string;
  only_subs: false;
  published: false;
  variant: {
    id: string;
    title: string;
    sku: string;
    size?: string;
    color?: string;
    inventory_quantity: number;
    price: number;
    created_at: string;
    updated_at: string;
  };
  selection: {
    price: number;
    quantity: number;
    vat_percentage: number;
  };
};

export type ProductWithMedia = Omit<Product, "variant"> & {
  variant: Product["variant"] & { media: string[] };
};

export type Payment = {
  id: string;
  yearly_id: null | number;
  order_id: string;
  status: PaymentStatusEnum;
  fees: number;
  amount: number;
  payment_type: PaymentTypeEnum;
  payment_date: string;
  created_at: string;
  updated_at: string;
};

export type User = {
  pk_user: string;
  email: string;
  role: number;
  name: string;
  surname: string;
  rank: number;
  gender: string | null;
  card_number: null | string;
  birth_place: string | null;
  birth_date: null | string;
  fiscale_code: string | null;
  promotion_board: null | string;
  visible_site: boolean;
  vote_privilege: boolean;
  is_teacher: boolean;
  title: null | string;
  post: boolean;
  profile_pic: string;
  note: string;
  created_at: string;
  last_modifier: string;
  updated_at: string;
  login_tries: number;
  visible_picture: boolean;
  status: UserStatusEnum;
  member_status: MemberStatusEnum;
  lang: Lang;
  first_sub_date: null | string;
  description: null | string;
  school: null | string;
  titles: null | string;
  deleted_at: null | string;
};

export type EnchancedOrder = Omit<
  OrderBase,
  "billing_address" | "shipping_address"
> & {
  billing_address: OrderAddress | string;
  shipping_address: OrderAddress | string;
  payment: Payment;
  products: ProductWithMedia[];
  user: User;
};

export type SelectOrderStatusType = {
  readonly value: string;
  readonly label: string;
};

export const orderStatusOptions: (lang: Lang) =>
  | OptionsOrGroups<
      {
        label: string;
        value: OrderStatusEnum;
      },
      GroupBase<{
        label: string;
        value: OrderStatusEnum;
      }>
    >
  | undefined = (lang: Lang) => [
  { label: lang === "en" ? "Cart" : "Carrello", value: "cart" },
  {
    label: lang === "en" ? "To Be Shipped" : "Essere spedito",
    value: "to be shipped",
  },
  { label: lang === "en" ? "Completed" : "Completato", value: "completed" },
  { label: lang === "en" ? "Canceled" : "Annullata", value: "canceled" },
];

export const createOrderStatusOptions: (lang: Lang) =>
  | OptionsOrGroups<
      {
        label: string;
        value: OrderStatusEnum;
      },
      GroupBase<{
        label: string;
        value: OrderStatusEnum;
      }>
    >
  | undefined = (lang: Lang) => [
  {
    label: lang === "en" ? "To Be Shipped" : "Essere Spedito",
    value: "to be shipped",
  },
  { label: lang === "en" ? "Completed" : "Completo", value: "completed" },
];

export const orderOriginOptions: (lang: Lang) =>
  | OptionsOrGroups<
      {
        label: string;
        value: OrderOrigin;
      },
      GroupBase<{
        label: string;
        value: OrderOrigin;
      }>
    >
  | undefined = (lang: Lang) => [
  {
    label: lang === "en" ? "Website" : "Sito web",
    value: "FE",
  },
  { label: lang === "en" ? "CMS" : "CMS", value: "CMS" },
];
