import React, { useEffect, useState } from "react";
import {
  Controller,
  SubmitHandler,
  useForm,
  useFieldArray,
  FormProvider,
} from "react-hook-form";
import DisplayInputError from "shared/components/UI/DisplayInputError";
import GoBack from "shared/components/UI/GoBack";
import Overlay from "shared/components/UI/Overlay";
import Spinner from "shared/components/UI/Spinner";
import { Product } from "shared/interfaces/Product.interface";
import { getInputStyles, getReactSelectStyles } from "utils/utils";
import { useTranslation } from "react-i18next";
import SimpleImageDropzone from "shared/components/UI/SimpleImageDropzone";
import Creatable from "react-select/creatable";
import Select from "react-select";
import { getTagsThunk, selectTags } from "store/tagsStore/tagsReducer";
import { filter } from "ramda";
import { useAppDispatch } from "store/storeHooks";
import { toast } from "react-toastify";
import Message from "shared/components/UI/Message";
import { selectTranslationLanguage } from "store/translationStore/translationReducer";
import axios from "axios";
import { CMS_BE_URL } from "api/urls";
import { selectLookups } from "store/lookups/lookupsReducer";
import MultipleImageDropzone from "shared/components/UI/MultipleImageDropzone";
import { XIcon } from "@heroicons/react/solid";
import DisplayFiles from "shared/components/UI/DisplayFiles";
import { GithubPicker } from "react-color";
import CropImage from "shared/components/UI/CropImage";
import { useNavigate } from "react-router";
import { classOptionsInProducts } from "api/Products/products.api";

const CreateProduct = () => {
  const [loading, setLoading] = useState(false);
  const [editImageOn, setEditImageOn] = useState(false);
  const [tagsOptions, setTagsOptions] = useState<any>([]);
  const [categories, setCategories] = useState<any>([]);
  const [displayColorPicker, setDisplayColorPicker] = useState<boolean>(false);
  const [currID, setCurrID] = useState<number>();
  const lang = selectTranslationLanguage();
  const navigate = useNavigate();

  const form = useForm<Product>({
    mode: "onChange",
    defaultValues: {
      fileType: "product_image",
      slug: "",
      vat_percentage: 0,
      published: false,
      title: "",
      class: { label: "Product", value: "product" },
      only_subs: false,
      description: "",
      tags: [],
      categories: [],
      variants: [
        {
          title: "",
          sku: "",
          size: "",
          color: "#000",
          colorOpen: false,
          inventory_quantity: 0,
          price: 0,
          media: [],
          image: [],
          editOn: false,
        },
      ],
      short_description: "",
      thumbnail: undefined,
    },
  });

  const {
    register,
    handleSubmit,
    clearErrors,
    watch,
    getValues,
    control,
    formState: { errors },
    setValue,
    trigger,
    setError,
  } = form;

  const lookups = selectLookups();

  const tags = selectTags();
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getTagsThunk()).then((res) => {
      if (res.meta.requestStatus === "rejected") {
        toast.error(
          Message({
            action: "read",
            entity: t("news.tags"),
            error: t("genericError"),
            lang,
          })
        );
      }
    });
  }, []);

  useEffect(() => {
    const tagsMap = tags.data.tags.map((tag) => {
      return {
        value: tag.pk_tag,
        label: tag.pk_tag,
      };
    });

    const categoriesMap = lookups.lookupList.categories.map((cat: any) => {
      return {
        value: cat.id,
        label: cat.name,
      };
    });

    setCategories(categoriesMap);
    setTagsOptions(tagsMap);
  }, [tags]);

  const { t } = useTranslation("common");

  //variants handler

  const { fields, append, update, remove } = useFieldArray({
    control,
    name: "variants",
  });

  watch("variants");

  const onSubmit: SubmitHandler<Product> = async (data) => {
    setLoading(true);

    if (data.variants.length <= 0) {
      setLoading(false);
      toast.error(t("products.vError"));
      return;
    }

    //Custom way of handling the variant's images error.
    // let er = false;
    // let index = 0;
    // data.variants.map((v: any, i: number) => {
    //   if (!v.gallery) {
    //     er = true;
    //     index = i;
    //   }
    //   v.id = v.idd;
    // });

    // if (er) {
    //   setError(`variants.${index}.image`, {
    //     message: "Plesae provide variant images.",
    //   });
    //   setLoading(false);
    //   setError;
    //   toast.error("Please provide variant images.");
    //   return;
    // }
    //
    data.vat_percentage = data.vat_percentage / 100;
    data.class = data.class.value;
    data.tags = JSON.stringify(data.tags.map((tag: any) => tag.value));
    data.categories = JSON.stringify(
      data.categories.map((cat: any) => cat.label)
    );

    await Promise.all(
      data.variants.map(async (variant: any) => {
        if (variant.gallery) {
          await Promise.all(
            variant.gallery?.map(async (g: any) => {
              const formD = new FormData();
              formD.append("fileType", "document_doc");
              formD.append("files", g);
              const res = await axios.post(
                `${CMS_BE_URL}/files/uploadFile`,
                formD,
                {
                  headers: {
                    "Content-Type":
                      "multipart/form-data; boundary=<calculated when request is sent>",
                  },
                }
              );
              variant.media = [...variant.media, res.data.files[0].file_id];
            })
          );
        }
      })
    );

    const formData = new FormData();
    Object.entries(data).forEach(([key, value]: any) => {
      if (Array.isArray(value)) {
        value = JSON.stringify(value);
      }
      formData.append(key, value);
    });
    try {
      const res = await axios.post(`${CMS_BE_URL}/s/products`, formData);
      if (res.status === 200 && res.data?.product?.id) {
        navigate(`/products/${res.data.product.id}`);
      }
      toast.success(lang === "en" ? "Product created" : "Prodotto creato");
    } catch (err: any) {
      if (err.response?.data?.errors?.[0]?.message) {
        toast.error(err.response?.data?.errors?.[0]?.message);
      } else {
        toast.error(err.response?.data?.message);
      }
    }
    setLoading(false);
  };

  const onError = (data: any) => {
    console.error(data);
  };

  const [filesToDelete, setFilesToDelete] = useState<any>([]);

  const deleteFilesFromTeacher = (id: any) => {
    const vars = getValues(`variants`);
    vars.map((variant, i) => {
      variant.image = variant.image.filter((im) => im.id !== id);

      variant.media = variant.media.filter((m: any) => m !== id);
      update(i, variant);
    });
  };

  const handleColorpickerVisibility: (index: number) => void = (
    index: number
  ) => {
    setCurrID(index);
    setDisplayColorPicker((prevState) => !prevState);
  };

  const handleColorChange: (hex: string, index: number) => void = (
    hex: string,
    index: number
  ) => {
    setValue(`variants.${index}.color`, hex);
  };

  return (
    <Overlay active={loading} spinner={<Spinner />}>
      <div className="p-10">
        <GoBack uri="products" />
        <FormProvider {...form}>
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <div className="flex items-center justify-between my-5">
              <div>
                <h1 className="mb-2 text-3xl font-bold">
                  {t("products.cProduct")}
                </h1>
                <p className="text-global-subHeader text-xl">
                  {t("products.pProduct")}
                </p>
              </div>
              <button
                type="submit"
                className="mt-10 px-4 py-2 text-white bg-users-create"
              >
                {t("products.cProduct")}
              </button>
            </div>
            <div>
              <div className="flex gap-5">
                <div className="w-2/3">
                  <div className="flex gap-10 mb-5 w-full">
                    <div className="w-full">
                      <label className="block mb-2 text-lg font-medium">
                        {t("news.title")} *
                      </label>
                      <input
                        id="title"
                        type="text"
                        {...register("title", {
                          required: t("products.titleErrorMsg") as string,
                        })}
                        className={getInputStyles(!!errors.title?.message)}
                        autoComplete="off"
                      />
                      <DisplayInputError message={errors.title?.message} />
                    </div>
                    <div className="">
                      <label className="block mb-2 text-lg font-medium">
                        {t("products.slug")} *
                      </label>
                      <input
                        id="slug"
                        type="text"
                        {...register("slug", {
                          required: t("products.slugErrorMsg") as string,
                        })}
                        className={getInputStyles(!!errors.slug?.message)}
                        autoComplete="off"
                      />
                      <DisplayInputError message={errors.slug?.message} />
                    </div>
                  </div>
                  <div className="flex gap-10 mb-5 w-full">
                    <div className="w-full">
                      <label className="block mb-2 text-lg font-medium">
                        {t("RolesCRUD.descriptionHeader")} *
                      </label>
                      <textarea
                        id="description"
                        rows={5}
                        className={`${getInputStyles(
                          !!errors.description?.message
                        )}`}
                        {...register("description", {
                          required: t("products.descriptionErrorMsg") as string,
                        })}
                      />
                      <DisplayInputError
                        message={errors.description?.message}
                      />
                    </div>
                    <div className="flex flex-col justify-between w-full">
                      <div className="w-full">
                        <label className="block mb-2 text-lg font-medium">
                          {t("new.shortDescription")}
                        </label>
                        <input
                          id="short_description"
                          type="text"
                          {...register("short_description", {
                            maxLength: 100,
                          })}
                          className={getInputStyles(
                            !!errors.short_description?.message
                          )}
                          autoComplete="off"
                        />
                        <DisplayInputError
                          message={errors.short_description?.message}
                        />
                      </div>
                      <div className="mb-5">
                        <label
                          htmlFor="class"
                          className="block text-lg font-medium"
                        >
                          {t("products.class")} *
                        </label>
                        <Controller
                          name="class"
                          control={control}
                          // rules={{
                          //   required: t("products.classErrorMsg") as string,
                          // }}
                          render={({ field }) => (
                            <Select
                              defaultValue={{
                                label: "Product",
                                value: "product",
                              }}
                              placeholder={t("products.class")}
                              styles={getReactSelectStyles(!!errors.tags)}
                              inputId="class"
                              options={classOptionsInProducts}
                              onChange={(e) => field.onChange(e)}
                            />
                          )}
                        />
                        {/* <DisplayInputError
                          message={(errors.class as any)?.message}
                        /> */}
                      </div>
                    </div>
                  </div>
                  <div className="mb-5">
                    <label htmlFor="tags" className="block text-lg font-medium">
                      {t("new.tags")} *
                    </label>
                    <Controller
                      name="tags"
                      control={control}
                      rules={{
                        required: t("products.tagErrorMsg") as string,
                        validate: {
                          maxLength(e) {
                            if (
                              filter((tag: any) => tag.value.length > 50, e)
                                .length
                            )
                              return `${t("new.tags")} ${t(
                                "new.shouldBe"
                              )} ${50} ${t("new.characters")}.`;
                            return true;
                          },
                        },
                      }}
                      render={({ field }) => (
                        <Creatable
                          placeholder={t("new.tags")}
                          styles={getReactSelectStyles(!!errors.tags)}
                          inputId="tags"
                          isMulti={true}
                          options={tagsOptions}
                          onChange={(e) => field.onChange(e)}
                        />
                      )}
                    />
                    <DisplayInputError
                      message={(errors.tags as any)?.message}
                    />
                  </div>
                  <div>
                    <label htmlFor="tags" className="block text-lg font-medium">
                      {t("products.categories")} *
                    </label>
                    <Controller
                      name="categories"
                      control={control}
                      rules={{
                        required: t("products.catsErrorMsg") as string,
                        validate: {
                          maxLength(e) {
                            if (
                              filter((tag: any) => tag.value.length > 50, e)
                                .length
                            )
                              return `categories ${t("new.shouldBe")} ${50} ${t(
                                "new.characters"
                              )}.`;
                            return true;
                          },
                        },
                      }}
                      render={({ field }) => (
                        <Creatable
                          placeholder="Categories"
                          styles={getReactSelectStyles(!!errors.categories)}
                          inputId="categories"
                          isMulti={true}
                          options={categories}
                          value={field.value}
                          onChange={(e) => field.onChange(e)}
                        />
                      )}
                    />
                    <DisplayInputError
                      message={(errors.categories as any)?.message}
                    />
                  </div>
                  <div className="mt-5">
                    <label className="block mb-2 text-lg font-medium">
                      {t("products.variants")}
                    </label>
                    {fields.length === 0 && (
                      <button
                        type="button"
                        className="text-green-500 text-2xl font-bold"
                        onClick={() =>
                          append({
                            sku: "",
                            title: "",
                            size: "",
                            editOn: false,
                            color: "#000",
                            price: 0,
                            media: [],
                            image: [],
                            inventory_quantity: 0,
                          })
                        }
                      >
                        +
                      </button>
                    )}
                    {fields.map((item, index) => (
                      <li className="flex gap-5 mb-2" key={item.id}>
                        <div className="flex flex-1 flex-col gap-4">
                          <div className="flex gap-5">
                            <div className="flex-1">
                              <label className="mb-1">{t("user.title")}</label>
                              <input
                                type="text"
                                placeholder="Title"
                                className={getInputStyles(
                                  !!errors.variants?.[index]?.title
                                )}
                                {...register(`variants.${index}.title`)}
                              />
                              <DisplayInputError
                                message={
                                  errors.variants?.[index]?.title?.message
                                }
                              />
                            </div>
                            <div className="flex-1">
                              <label className="mb-1">
                                {t("products.price")}
                              </label>
                              <input
                                min={0}
                                step=".01"
                                placeholder="price"
                                type="number"
                                className={getInputStyles(
                                  !!errors.variants?.[index]?.price
                                )}
                                {...register(`variants.${index}.price`, {
                                  required: "price is required.",
                                })}
                                {...register(`variants.${index}.price`)}
                              />
                              <DisplayInputError
                                message={
                                  errors.variants?.[index]?.price?.message
                                }
                              />
                            </div>
                          </div>
                          {watch("class").value === "product" && (
                            <div className="flex gap-5">
                              <div className="flex-1">
                                <label className="mb-1">
                                  {t("products.size")}
                                </label>
                                <input
                                  type="text"
                                  placeholder="size"
                                  className={getInputStyles(
                                    !!errors.variants?.[index]?.size
                                  )}
                                  {...register(`variants.${index}.size`)}
                                />
                                <DisplayInputError
                                  message={
                                    errors.variants?.[index]?.size?.message
                                  }
                                />
                              </div>

                              <div className="flex-1">
                                <label className="mb-1">
                                  {t("products.quantity")}
                                </label>
                                <input
                                  placeholder="quantity"
                                  type="number"
                                  className={getInputStyles(
                                    !!errors.variants?.[index]
                                      ?.inventory_quantity
                                  )}
                                  {...register(
                                    `variants.${index}.inventory_quantity`
                                  )}
                                />
                                <DisplayInputError
                                  message={
                                    errors.variants?.[index]?.inventory_quantity
                                      ?.message
                                  }
                                />
                              </div>
                            </div>
                          )}

                          <div className="flex gap-5">
                            <div className="flex-1">
                              <label className="mb-1">SKU *</label>
                              <input
                                placeholder="sku"
                                type="text"
                                className={getInputStyles(
                                  !!errors.variants?.[index]?.sku
                                )}
                                {...register(`variants.${index}.sku`, {
                                  required: t("products.skuErrorMsg") as string,
                                })}
                              />
                              <DisplayInputError
                                message={errors.variants?.[index]?.sku?.message}
                              />
                            </div>
                            {watch("class").value === "product" && (
                              <div className="flex-1">
                                <label>{t("products.color")}</label>

                                <div>
                                  <button
                                    className="border border-gray-300"
                                    style={{
                                      width: "100%",
                                      height: "37px",
                                      backgroundColor: getValues(
                                        `variants.${index}.color`
                                      ),
                                    }}
                                    type="button"
                                    onClick={() =>
                                      handleColorpickerVisibility(index)
                                    }
                                  ></button>
                                  {displayColorPicker && currID === index ? (
                                    <div
                                      id="variants"
                                      style={{
                                        position: "absolute",
                                        zIndex: 2,
                                      }}
                                    >
                                      <div
                                        style={{
                                          position: "fixed",
                                          top: "0px",
                                          right: "0px",
                                          left: "0px",
                                          bottom: "0px",
                                        }}
                                        onClick={() =>
                                          setDisplayColorPicker(false)
                                        }
                                      />
                                      <GithubPicker
                                        key={`colorpicker-${item.id}`}
                                        className="colorpicker"
                                        colors={[
                                          "#D0021B",
                                          "#FF6900",
                                          "#F8E71C",
                                          "#8B572A",
                                          "#7ED321",
                                          "#008B02",
                                          "#F78DA7",
                                          "#9013FE",
                                          "#004DCF",
                                          "#DCE775",
                                          "#73D8FF",
                                          "#000000",
                                          "#FFFFFF",
                                          "#9B9B9B",
                                        ]}
                                        onChange={(color) =>
                                          handleColorChange(color.hex, index)
                                        }
                                      />
                                    </div>
                                  ) : null}
                                </div>
                                <DisplayInputError
                                  message={
                                    errors.variants?.[index]?.color?.message
                                  }
                                />
                              </div>
                            )}
                            {watch("class").value === "exam" && (
                              <div className="flex-1">
                                <label className="mb-1">
                                  {t("certificates.badge")}
                                </label>
                                <select
                                  placeholder="Badge"
                                  className={getInputStyles(false)}
                                  {...register(`variants.${index}.badge`)}
                                >
                                  <option value="single">Single</option>
                                  <option value="bronze">Bronze</option>
                                  <option value="silver">Silver</option>
                                  <option value="gold">Gold</option>
                                  <option value="platinum">Platinum</option>
                                </select>
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="flex-1">
                          <div className="flex flex-col flex-grow">
                            <label className="block text-lg font-medium">
                              Variant&apos;s photos
                            </label>
                            <Controller
                              name={`variants.${index}.image`}
                              control={control}
                              // rules={{ required: "Variant image is required." }}
                              render={() => (
                                <MultipleImageDropzone
                                  reactHookFormName={`variants.${index}.gallery`}
                                  aspect={16 / 9}
                                />
                              )}
                            />
                            <div className="flex flex-wrap gap-5 mt-5">
                              {item.image.map((i: any, y: number) => (
                                <div
                                  key={y}
                                  className="flex gap-0 items-center bg-red-500 rounded-sm"
                                >
                                  <div className="w-16 h-9">
                                    <img
                                      src={i.url}
                                      className="w-16 h-9 object-cover"
                                    />
                                  </div>
                                  <button
                                    type="button"
                                    className="p-1"
                                    onClick={() => {
                                      setFilesToDelete((state: any) => [
                                        ...state,
                                        i,
                                      ]);
                                      deleteFilesFromTeacher(i.id);
                                    }}
                                  >
                                    <XIcon className="w-6 h-6 text-white" />
                                  </button>
                                </div>
                              ))}
                            </div>
                            <div className="mt-5">
                              <DisplayFiles
                                reactHookFormName={`variants.${index}.gallery`}
                              />
                            </div>
                            <DisplayInputError
                              message={
                                (errors.variants?.[index]?.image as any)
                                  ?.message
                              }
                            />
                          </div>
                        </div>

                        <div className="flex gap-2 items-center">
                          <button
                            className="ml-2 text-red-500 text-5xl font-bold"
                            type="button"
                            onClick={() => remove(index)}
                          >
                            -
                          </button>
                          <button
                            type="button"
                            className={
                              index === fields.length - 1
                                ? "opacity-100 w-8 text-green-500 text-5xl font-bold"
                                : "opacity-0 w-8 "
                            }
                            onClick={() =>
                              append({
                                title: "",
                                sku: "",
                                size: "",
                                color: "#000",
                                colorOpen: false,
                                inventory_quantity: 0,
                                price: 0,
                                media: [],
                                image: [],
                                editOn: false,
                              })
                            }
                          >
                            +
                          </button>
                        </div>
                      </li>
                    ))}
                  </div>
                  <div className="flex gap-10 items-center">
                    <div>
                      <div className="flex gap-2 items-center mt-5">
                        <input
                          onChange={() =>
                            setValue("only_subs", !getValues("only_subs"))
                          }
                          type="checkbox"
                          name=""
                          id=""
                        />
                        <label>{t("products.subsOnly")}</label>
                      </div>
                      <div className="flex gap-2 items-center mt-5">
                        <input
                          onChange={() =>
                            setValue("published", !getValues("published"))
                          }
                          type="checkbox"
                        />
                        <label>{t("products.published")}</label>
                      </div>
                    </div>
                    <div>
                      <label className="block">VAT (%)</label>
                      <input
                        className={getInputStyles(!!errors.vat_percentage)}
                        type="number"
                        {...register("vat_percentage", {
                          min: 0,
                          max: 100,
                        })}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-2 w-1/3">
                  <label
                    htmlFor="thumbnail"
                    className="block text-lg font-medium"
                  >
                    {t("products.photo")}
                  </label>
                  <Controller
                    name="thumbnail"
                    control={control}
                    render={() => (
                      <SimpleImageDropzone
                        canDelete={true}
                        clearErrors={clearErrors}
                        trigger={trigger}
                        setValue={setValue}
                        watch={watch}
                        setError={setError}
                        reactHookFormName="thumbnail"
                        setEditImageOn={setEditImageOn}
                        aspect={16 / 9}
                      />
                    )}
                  />
                  {editImageOn ? (
                    <CropImage
                      editImageOn={editImageOn}
                      setEditImageOn={setEditImageOn}
                      reactHookFormName="thumbnail"
                      aspect={1}
                      canForce={true}
                      canClose={true}
                    />
                  ) : null}
                  <DisplayInputError message={errors.thumbnail?.message} />
                </div>
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
    </Overlay>
  );
};

export default CreateProduct;
