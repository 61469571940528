import { CMS_BE_URL } from "api/urls";
import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import Message from "shared/components/UI/Message";
import { selectTranslationLanguage } from "store/translationStore/translationReducer";
import { getInputStyles } from "utils/utils";
import { ErrorMessage } from "@hookform/error-message";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { GlobalVariable } from "shared/interfaces/GlobalVariable.interface";

type FacebookUrlForm = {
  facebookUrl: string;
};

const facebookUrlId = 2;

function FacebookUrl() {
  const lang = selectTranslationLanguage();
  const { t } = useTranslation("common");

  const schema = useMemo(
    () =>
      yup.object({
        facebookUrl: yup
          .string()
          .matches(
            /https?:\/\/www.facebook.com\w*/gm,
            t("facebookUrl.urlError")
          ),
      }),
    [t, lang]
  ).required();
  const form = useForm<FacebookUrlForm>({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      facebookUrl: "",
    },
  });
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    axios
      .get(CMS_BE_URL + "/globalVariables/" + facebookUrlId)
      .then((res) => {
        const variable: GlobalVariable<2> = res.data.variable;
        form.setValue("facebookUrl", variable.value, {
          shouldValidate: true,
        });
      })
      .catch((err: any) => {
        if (err.response?.data?.errors?.[0]?.message) {
          toast.error(err.response?.data?.errors?.[0]?.message);
        } else {
          toast.error(err.response?.data?.message);
        }
      })
      .finally(() => setLoading(false));
  }, []);
  const onSubmit = form.handleSubmit((data) => {
    setLoading(true);
    axios
      .put(CMS_BE_URL + "/globalVariables/" + facebookUrlId, {
        value: data.facebookUrl,
      })
      .then(() =>
        toast.success(
          Message({
            action: "update",
            entity: t("facebookUrl.facebookUrl"),
            lang,
          })
        )
      )
      .catch(
        (err: any) => {
          if (err.response?.data?.errors?.[0]?.message) {
            toast.error(err.response?.data?.errors?.[0]?.message);
          } else {
            toast.error(err.response?.data?.message);
          }
        }
        // toast.error(
        //   Message({
        //     action: "update",
        //     entity: t("facebookUrl.facebookUrl"),
        //     lang,
        //     error: t("genericError"),
        //   })
        // )
      )
      .finally(() => setLoading(false));
  });
  return (
    <form className="flex flex-col gap-2" onSubmit={onSubmit}>
      <label htmlFor="facebookUrl" className="text-xl font-semibold">
        {t("facebookUrl.facebookUrl")}
      </label>
      <div className="flex flex-grow gap-5">
        <input
          id="facebookUrl"
          type="text"
          {...form.register("facebookUrl")}
          placeholder={
            loading ? t("facebookUrl.loading") : t("facebookUrl.facebookUrl")
          }
          className={getInputStyles(!!form.formState.errors.facebookUrl)}
        />
        <button
          type="submit"
          className="px-4 py-2 text-white bg-primary border border-primary rounded-sm"
        >
          {t("crud.update")}
        </button>
      </div>
      <ErrorMessage
        errors={form.formState.errors}
        name="facebookUrl"
        render={({ message }) => (
          <p className="text-red-500 text-xs">{message}</p>
        )}
      />
    </form>
  );
}

export default FacebookUrl;
