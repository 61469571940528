import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import DisplayInputError from "shared/components/UI/DisplayInputError";
import Message from "shared/components/UI/Message";
import {
  getAboutUsThunk,
  selectAboutUs,
  updateAboutUsThunk,
} from "store/aboutUsStore/aboutUsReducer";
import { useAppDispatch } from "store/storeHooks";
import { selectTranslationLanguage } from "store/translationStore/translationReducer";
import { addStyles, getInputStyles } from "utils/utils";
import { CoreCommitments } from "./types";

export default function CoreCommitmentsPage() {
  const lang = selectTranslationLanguage();
  const { t } = useTranslation("common");
  const dispatch = useAppDispatch();
  const {
    trigger,
    watch,
    setValue,
    getValues,
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<CoreCommitments>({
    mode: "onChange",
  });

  useEffect(function getCoreCommitments() {
    dispatch(getAboutUsThunk({ aboutId: "1" })).then(function handleRequest(
      res: any
    ) {
      if (res.meta.requestStatus === "rejected") {
        toast.error(
          Message({
            action: "read",
            entity: t("coreCommitments.coreCommitments"),
            error: t("genericError"),
            lang,
          })
        );
      }
    });
  }, []);

  const aboutUs = selectAboutUs();

  useEffect(
    function setCoreCommitments() {
      if (aboutUs.data.data?.mission)
        setValue("mission", aboutUs.data.data.mission);
      if (aboutUs.data.data?.values)
        setValue("values", aboutUs.data.data.values);
      if (aboutUs.data.data?.vision)
        setValue("vision", aboutUs.data.data.vision);
    },
    [aboutUs.data.data]
  );

  const onSubmit = handleSubmit(function submit(data) {
    dispatch(updateAboutUsThunk({ aboutId: "1", data })).then(
      function handleRequest(res) {
        if (res.meta.requestStatus === "fulfilled") {
          toast.success(
            Message({
              action: "update",
              entity: t("coreCommitments.coreCommitments"),
              lang,
            })
          );
        } else {
          toast.error(
            Message({
              action: "update",
              entity: t("coreCommitments.coreCommitments"),
              lang,
            })
          );
        }
      }
    );
  });
  return (
    <div className="flex flex-col flex-grow h-full">
      <form onSubmit={onSubmit} className="flex flex-col flex-grow gap-5">
        <div className="flex gap-10 justify-between">
          <h1 className="text-2xl font-bold">{t("coreCommitments.title")}</h1>
          <button type="submit" className="px-4 py-2 text-white bg-global-save">
            {t("coreCommitments.save")}
          </button>
        </div>
        <div className="flex flex-col flex-grow gap-5">
          <div className="flex flex-col flex-grow gap-2">
            <label
              htmlFor="missions"
              className="whitespace-nowrap text-lg font-medium"
            >
              {t("coreCommitments.missionLabel")}
            </label>
            <textarea
              id="mission"
              {...register("mission", {
                validate: {
                  positive: (s) => {
                    if (s.length >= 1300) {
                      return t("coreCommitments.missionLength") as string;
                    }
                    return true;
                  },
                },
              })}
              placeholder={t("coreCommitments.mission")}
              className={addStyles(getInputStyles(false), "flex-grow")}
            />
            <DisplayInputError message={errors.mission?.message} />
          </div>
          <div className="flex flex-col flex-grow gap-2">
            <label htmlFor="values" className="text-lg font-medium">
              {t("coreCommitments.valuesLabel")}
            </label>
            <textarea
              {...register("values", {
                validate: {
                  positive: (s) =>
                    s.length <= 1300 ||
                    (t("coreCommitments.valuesLength") as string),
                },
              })}
              placeholder={t("coreCommitments.values")}
              className={addStyles(getInputStyles(false), "flex-grow")}
            />
            <DisplayInputError message={errors.values?.message} />
          </div>
          <div className="flex flex-col flex-grow gap-2">
            <label htmlFor="vision" className="text-lg font-medium">
              {t("coreCommitments.visionLabel")}
            </label>
            <textarea
              {...register("vision", {
                validate: {
                  positive: (s) =>
                    s.length <= 1000 ||
                    (t("coreCommitments.visionLength") as string),
                },
              })}
              placeholder={t("coreCommitments.vision")}
              className={addStyles(
                getInputStyles(!!errors.vision),
                "flex-grow"
              )}
            />
            <DisplayInputError message={errors.vision?.message} />
          </div>
        </div>
      </form>
    </div>
  );
}
