import { User, UserLowercase } from "shared/interfaces/User.interface";

export interface UsersState {
  data: {
    total: number;
    pageIndex: number;
    users: UserLowercase[];
    user: UserLowercase | null;
  };
  ranks: string[];
  statuses: string[];
  membershipStatuses: string[];
  loading: boolean;
}

export const usersInitialState: UsersState = {
  data: {
    total: 0,
    pageIndex: 0,
    users: [],
    user: null,
  },
  ranks: [
    "National President",
    "Vicar Vice President",
    "General Secretary",
    "National Counselor",
    "Regional President",
    "Partner",
    "Adviser",
    "Secretary",
    "Appointed Vice President",
    "President",
    "Honorary Member",
    "Honorary President",
    "Partner Amateur",
    "Partner Non Amateur",
    "Regional Vice President",
    "Regional Counsellor",
    "Teacher",
  ],
  statuses: ["Active", "Reset", "Deleted", "Disabled"],
  membershipStatuses: ["Normal_user", "Active", "Dimissionary", "Expired"],
  loading: false,
};
