import React from "react";
import { useTranslation } from "react-i18next";
import { ReceiptProductDto } from "shared/interfaces/Receipt.interface";
import { Round } from "utils/utils";
import { calcTotalNetPrice, calcTotalVatPrice } from "./CreateReceipt";

function Pricing({
  products,
  excludeVat,
}: {
  products: ReceiptProductDto[];
  excludeVat: boolean;
}) {
  const totalNetPrice = calcTotalNetPrice(products);
  const totalVatPrice = calcTotalVatPrice(products);
  const totalPrice = excludeVat
    ? totalNetPrice
    : Round(totalNetPrice + totalVatPrice);
  const { t } = useTranslation("common");
  return (
    <div className="flex flex-col gap-5">
      <h3 className="text-xl font-semibold">{t("receipt.pricing")}</h3>
      <div className="flex flex-col gap-2">
        <div>
          {t("receipt.totalAmount")}: {totalNetPrice}&euro;
        </div>
        <div>
          {t("receipt.vatAmount")}: {totalVatPrice}&euro;
        </div>
        <div>
          {t("receipt.finalAmount")}: {totalPrice}&euro;
        </div>
      </div>
    </div>
  );
}

export default Pricing;
