import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { documentsInitialState } from "./documentsState";
import { CMS_BE_URL } from "api/urls";
import axios from "axios";

export const getDocumentsThunk = createAsyncThunk(
  "documentsStore/getDocuments",
  async (q: {
    pageIndex: number;
    pageSize: number;
    sortOrder: string;
    sortCol?: string;
    searchString: string;
  }) => {
    const response = await axios.get(
      `${CMS_BE_URL}/documents?pageSize=${q.pageSize}&pageIndex=${q.pageIndex}&searchString=${q.searchString}&visible=all&sortCol=${q.sortCol}&sortOrder=${q.sortOrder}`
    );
    return response.data;
  }
);

const documentSlice = createSlice({
  name: "usersStore",
  initialState: documentsInitialState,
  reducers: {
    toggleFiltersRoles(state, action: { payload: { filterName: string } }) {
      const activeFilters = [...state.filters.roles];
      const indexExists = activeFilters.indexOf(action.payload.filterName);
      if (indexExists === -1) {
        activeFilters.push(action.payload.filterName);
      } else {
        activeFilters.splice(indexExists, 1);
      }
      state.filters.roles = activeFilters;
    },
    clearFiltersRoles(state) {
      state.filters.roles = [];
    },
    toggleFiltersRegions(state, action: { payload: { filterName: string } }) {
      const activeFilters = [...state.filters.regions];
      const indexExists = activeFilters.indexOf(action.payload.filterName);
      if (indexExists === -1) {
        activeFilters.push(action.payload.filterName);
      } else {
        activeFilters.splice(indexExists, 1);
      }
      state.filters.regions = activeFilters;
    },
    clearFiltersRegions(state) {
      state.filters.regions = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDocumentsThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getDocumentsThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(getDocumentsThunk.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default documentSlice;
