import React, { Fragment } from "react";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from "@heroicons/react/solid";
import { useTranslation } from "react-i18next";

export default function Pagination({
  rowsCount,
  pageIndex,
  canPreviousPage,
  canNextPage,
  pageCount,
  gotoPage,
  nextPage,
  previousPage,
}: // setPageSize,
{
  rowsCount: number;
  pageIndex: number;
  pageSize: number;
  canPreviousPage: boolean;
  canNextPage: boolean;
  pageOptions: number[];
  pageCount: number;
  gotoPage: (e: number) => void;
  nextPage: () => void;
  previousPage: () => void;
  setPageSize: (e: number) => void;
}): JSX.Element {
  const { t } = useTranslation("common");

  return (
    <Fragment>
      <div className="flex items-center justify-between">
        <div className="flex flex-1 justify-between sm:hidden">
          <button
            onClick={previousPage}
            className="relative inline-flex items-center px-4 py-2 text-gray-700 text-sm font-medium hover:bg-gray-100"
          >
            {t("Pagination.previousButton")}
          </button>
          <button
            onClick={nextPage}
            className="relative inline-flex items-center ml-3 px-4 py-2 text-gray-700 text-sm font-medium hover:bg-gray-100"
          >
            {t("Pagination.nextButton")}
          </button>
        </div>
        <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
          <div>
            <p className="text-gray-700 text-sm">
              {t("pagination.page")}{" "}
              <span className="font-medium">{pageIndex + 1}</span>{" "}
              {t("pagination.of")}{" "}
              <span className="font-medium">{pageCount}</span>{" "}
              {` - ${t("pagination.showing")} `}
              {pageIndex * 10 + 1}
              {" - "}
              {pageIndex * 10 + 10 <= rowsCount
                ? pageIndex * 10 + 10
                : rowsCount}
              {` ${t("pagination.of")} `}
              <span className="font-medium">{rowsCount}</span>
            </p>
          </div>
          <div>
            <nav
              className="relative z-0 inline-flex -space-x-px"
              // aria-label='Pagination'
            >
              <button
                onClick={() => gotoPage(0)}
                disabled={pageIndex === 0}
                className="relative inline-flex items-center px-2 py-2 text-gray-500 text-sm font-medium hover:bg-gray-100"
              >
                <ChevronDoubleLeftIcon className="w-5 h-5" aria-hidden="true" />
              </button>
              <button
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
                className="relative inline-flex items-center px-2 py-2 text-gray-500 text-sm font-medium hover:bg-gray-100"
              >
                <ChevronLeftIcon className="w-5 h-5" aria-hidden="true" />
              </button>
              <button
                onClick={() => nextPage()}
                disabled={!canNextPage}
                className="relative inline-flex items-center px-2 py-2 text-gray-500 text-sm font-medium hover:bg-gray-100"
              >
                <ChevronRightIcon className="w-5 h-5" aria-hidden="true" />
              </button>
              <button
                onClick={() => gotoPage(pageCount - 1)}
                disabled={pageCount - 1 === pageIndex}
                className="relative inline-flex items-center px-2 py-2 text-gray-500 text-sm font-medium hover:bg-gray-100"
              >
                <ChevronDoubleRightIcon
                  className="w-5 h-5"
                  aria-hidden="true"
                />
              </button>
            </nav>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
