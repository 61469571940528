import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createPresidentialRank,
  deletePresidentialRank,
  getPresidentialRank,
  getPresidentialRanks,
  updatePresidentialRank,
} from "api/PresidentialRanks/presidentialRanks.api";
import {
  CreatePresidentialRank,
  UpdatePresidentialRank,
} from "shared/interfaces/Rank.interface";
import { useAppSelector } from "store/storeHooks";
import { presidentialRanksInitialState } from "./presidentialRanksState";

export const createPresidentialRankThunk = createAsyncThunk(
  "presidentialRanks/createPresidentialRank",
  async ({ data }: { data: CreatePresidentialRank }) => {
    await createPresidentialRank({ data });
  }
);

export const getPresidentialRanksThunk = createAsyncThunk(
  "presidentialRanks/getPresidentialRanks",
  async (filters: {
    pageSize?: number;
    pageIndex?: number;
    sortCol?: string;
    sortOrder?: string;
    searchString?: string;
  }) => {
    const res = await getPresidentialRanks(filters);
    return res.data;
  }
);

export const deletePresidentialRankThunk = createAsyncThunk(
  "presidentialRanks/deletePresidentialRank",
  async ({ rankId }: { rankId: string }) => {
    const res = await deletePresidentialRank({ rankId });
    return res.data;
  }
);

export const getPresidentialRankThunk = createAsyncThunk(
  "presidentialRanks/getPresidentialRank",
  async ({ rankId }: { rankId: string }) => {
    const res = await getPresidentialRank({ rankId });
    return res.data;
  }
);

export const updatePresidentialRankThunk = createAsyncThunk(
  "presidentialRanks/updatePresidentialRank",
  async ({
    rankId,
    data,
  }: {
    rankId: string;
    data: UpdatePresidentialRank;
  }) => {
    await updatePresidentialRank({
      rankId,
      data,
    });
  }
);

const presidentialRanksSlice = createSlice({
  name: "presidentialRanks",
  initialState: presidentialRanksInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createPresidentialRankThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(createPresidentialRankThunk.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(createPresidentialRankThunk.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getPresidentialRanksThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPresidentialRanksThunk.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(getPresidentialRanksThunk.rejected, (state) => {
        state.loading = false;
      })
      .addCase(deletePresidentialRankThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(deletePresidentialRankThunk.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(deletePresidentialRankThunk.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getPresidentialRankThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPresidentialRankThunk.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(getPresidentialRankThunk.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updatePresidentialRankThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(updatePresidentialRankThunk.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updatePresidentialRankThunk.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const selectPresidentialRanks = () =>
  useAppSelector((state) => state.presidentialRanks);

export default presidentialRanksSlice;
