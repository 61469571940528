import React, { ChangeEvent, Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getRolePermissionsThunk,
  getRolesThunk,
  selectPermissionsManagementLoading,
  selectRolePermissions,
  selectRoles,
  selectUpdateRolePermissions,
  updateRolePermissionsThunk,
} from "store/permissionsManagement/permissionsManagementReducer";
import { useAppDispatch } from "store/storeHooks";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { CreateRolePermission } from "shared/interfaces/RolePermission.interface";
import { permissionsManagementActions } from "store/globalStore";
import { Role } from "shared/interfaces/Role.interface";
import Overlay from "shared/components/UI/Overlay";
import Spinner from "shared/components/UI/Spinner";
import GoBack from "shared/components/UI/GoBack";
import Message from "shared/components/UI/Message";
import UpdatePermissionOnlyCheckBox from "shared/components/Roles/UpdatePermissionOnlyCheckbox";
import UpdatePermission from "shared/components/Roles/UpdatePermission";
import UpdatePermissionParent from "shared/components/Roles/UpdatePermissionParent";
import { selectTranslationLanguage } from "store/translationStore/translationReducer";
import { getLookupsThunk } from "store/lookups/lookupsReducer";

type crud = "create" | "read" | "update" | "delete";
type CrudType = "ANY" | "OWN" | "NONE";

type CheckAllHandlerState = {
  [key in crud]: CrudType | null;
};

const checkAllHandlerInitialState: CheckAllHandlerState = {
  create: null,
  read: null,
  update: null,
  delete: null,
};

const EditRole: () => JSX.Element = () => {
  const isLoading: boolean = selectPermissionsManagementLoading();
  const lang = selectTranslationLanguage();
  const [t, i18n] = useTranslation("common");
  const { roleName = "" } = useParams();
  const [checkAllHandlers, setCheckAllHandlers] =
    useState<CheckAllHandlerState>(checkAllHandlerInitialState);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loadingEditRole, setLoadingEditRole] = useState(true);
  const roles = selectRoles();
  const rolesPermissions = selectRolePermissions();
  const updateRolePermissions = selectUpdateRolePermissions();

  const roleID = parseInt(roleName);
  const role = roles.find((role) => role.ID === roleID) as Role;

  const createPermissionAccess: any = () => {
    const tempPerms = rolesPermissions
      .filter(function (perm) {
        return (
          perm.PERMISSION_NAME !== "teachers" &&
          perm.PERMISSION_NAME !== "councils" &&
          perm.PERMISSION_NAME !== "buttons" &&
          perm.PERMISSION_NAME !== "ranks" &&
          perm.PERMISSION_NAME !== "s" &&
          perm.PERMISSION_NAME !== "payments" &&
          perm.PERMISSION_NAME !== "coupons"
        );
      })
      .map((rolePermission, i) => ({
        permissionName: rolePermission.PERMISSION_NAME,
        title: t(`RolesPermissions.${rolePermission.PERMISSION_NAME}`),
        create: rolePermission.CREATE,
        read: rolePermission.READ,
        update: rolePermission.UPDATE,
        delete: rolePermission.DELETE,
        enabled: rolePermission.ENABLED,
        index: i,
      }));

    return tempPerms;
  };

  useEffect(() => {
    dispatch(getRolesThunk());
    dispatch(getRolePermissionsThunk(roleID));
  }, [roleID]);

  useEffect(() => {
    setLoadingEditRole(true);
    if (rolesPermissions.length && roles.length) {
      dispatch(
        permissionsManagementActions.changeUpdateRolePermissionsRoleName({
          roleName: role?.ROLE_NAME,
        })
      );
      dispatch(
        permissionsManagementActions.changeUpdateRolePermissionsDescription({
          description: role?.DESCRIPTION,
        })
      );
      dispatch(
        permissionsManagementActions.changeUpdateRolePermissionsPermissionsAccess(
          { permissionsAccess: createPermissionAccess() }
        )
      );
    }
    setLoadingEditRole(false);
  }, [rolesPermissions, roles, i18n?.language]);

  const saveRolePermissionHandler = () => {
    const rn = updateRolePermissions.roleName;
    const description = updateRolePermissions.description;
    const permissionsAccess = updateRolePermissions.permissionsAccess;
    const data: CreateRolePermission = {
      roleName: rn,
      description,
      permissionsAccess,
    };
    dispatch(updateRolePermissionsThunk({ roleID, data })).then((res: any) => {
      if (res.meta.requestStatus === "rejected") {
        toast.error(
          Message({
            action: "update",
            entity: "role",
            error: res.error.message,
            lang,
            gender: "male",
          })
        );
        return;
      }
      dispatch(getLookupsThunk());
      toast.success(
        Message({
          action: "update",
          entity: "role",
          lang,
          gender: "male",
        })
      );
      navigate("/roles");
    });
  };

  return (
    <Fragment>
      {!loadingEditRole && (
        <Overlay
          spinner={<Spinner />}
          active={
            isLoading ||
            roles.length <= 0 ||
            updateRolePermissions.permissionsAccess.length < 0
          }
        >
          <div className="roles flex flex-col gap-5 p-10 h-full overflow-y-auto">
            <GoBack uri="roles" />
            <div className="flex gap-5 items-center">
              <div className="text-3xl font-bold">
                {t("RolesCRUD.rolesHeader")}
              </div>
              <div className="text-roles-description text-lg">
                {t("RolesCRUD.editRolesHeaderMessage")}
              </div>
            </div>
            <div className="flex flex-row gap-5">
              <div className="flex flex-row gap-5 w-2/3">
                <div className="flex flex-col gap-2 w-1/3">
                  <div className="text-xl font-bold">
                    {t("RolesCRUD.roleHeader")}
                  </div>
                  <input
                    id="create-role-role"
                    value={updateRolePermissions.roleName}
                    type="text"
                    placeholder="e.g. Admin"
                    className="text-sm focus:border-global-input-focus focus:ring-0"
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      dispatch(
                        permissionsManagementActions.changeUpdateRolePermissionsRoleName(
                          {
                            roleName: e.target.value,
                          }
                        )
                      )
                    }
                  />
                </div>
                <div className="flex flex-col gap-2 w-2/3">
                  <div className="text-xl font-bold">
                    {t("RolesCRUD.descriptionHeader")}
                  </div>
                  <input
                    id="create-role-description"
                    value={updateRolePermissions.description}
                    type="text"
                    placeholder={`${t("RolesCRUD.descriptionPlaceholder")}`}
                    className="text-sm focus:border-global-input-focus focus:ring-0"
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      dispatch(
                        permissionsManagementActions.changeUpdateRolePermissionsDescription(
                          {
                            description: e.target.value,
                          }
                        )
                      )
                    }
                  />
                </div>
              </div>
              <div className="flex flex-row gap-5 items-end justify-end w-1/3">
                <div className="flex flex-col gap-2 w-1/4">
                  <button
                    className="items-center p-2 w-full h-9 text-users-create text-sm border border-users-create"
                    onClick={() => {
                      setCheckAllHandlers((state) => {
                        const tempState = { ...state };
                        tempState.create = null;
                        tempState.read = null;
                        tempState.update = null;
                        tempState.delete = null;
                        return tempState;
                      });
                      dispatch(getRolePermissionsThunk(roleID));
                    }}
                    id="create-role-reset"
                  >
                    {t("RolesCRUD.resetButton")}
                  </button>
                </div>
                <div className="flex flex-col gap-2 w-1/4">
                  <button
                    className="items-center p-2 w-full h-9 text-white text-sm bg-users-create"
                    onClick={saveRolePermissionHandler}
                    id="create-role-save"
                  >
                    {t("RolesCRUD.saveButton")}
                  </button>
                </div>
              </div>
            </div>
            <div className="">
              <div className="border-b border-gray-200 rounded-sm">
                <table className="min-w-full divide-gray-200 divide-y">
                  <thead className="bg-gray-100">
                    <tr className="bg-gray-100">
                      <th
                        scope="col"
                        className="px-6 py-3 w-1/6 text-gray-500 text-xs font-medium tracking-wider uppercase"
                      ></th>
                      <th
                        scope="col"
                        className="px-6 py-3 w-1/6 text-center text-sm font-semibold tracking-wider"
                      >
                        <div className="single-row-container">
                          <div className="flex flex-col items-center">
                            <div className="flex flex-row gap-1 justify-center mb-1">
                              {t("RolesCRUD.checkboxTitle")}
                            </div>
                            <div className="own-any-container flex gap-4 justify-center text-gray-600 text-xs">
                              <span>{t("RolesCRUD.checkboxDescription")}</span>
                            </div>
                          </div>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 w-1/6 text-center text-sm font-semibold tracking-wider"
                      >
                        <div className="single-row-container">
                          <div className="flex flex-col items-center">
                            <div className="flex flex-row gap-1 justify-center mb-1">
                              {t("RolesCRUD.readCrud")}
                            </div>
                            <div className="own-any-container flex gap-4 justify-center text-gray-600 text-xs">
                              <span>Any</span>
                              <span>Own</span>
                            </div>
                          </div>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 w-1/6 text-center text-sm font-semibold tracking-wider"
                      >
                        <div className="single-row-container">
                          <div className="flex flex-col items-center">
                            <div className="flex flex-row gap-1 justify-center mb-1">
                              {t("RolesCRUD.cud")}
                            </div>
                            <div className="own-any-container flex gap-4 justify-center text-gray-600 text-xs">
                              <span>Any</span>
                              <span>Own</span>
                              <span>None</span>
                            </div>
                          </div>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-gray-200 divide-y">
                    {/* DYNAMIC INPUT OF PERMISSIONS AS TABLE ROWS */}
                    {!!updateRolePermissions.permissionsAccess.find(
                      (role) => role.permissionName === "accessCMS"
                    ) && (
                      <UpdatePermissionOnlyCheckBox
                        permission={updateRolePermissions.permissionsAccess.find(
                          (role) => role.permissionName === "accessCMS"
                        )}
                        enabled={true}
                        isItChild={false}
                      />
                    )}
                    {!!updateRolePermissions.permissionsAccess.find(
                      (role) => role.permissionName === "news"
                    ) && (
                      <UpdatePermission
                        permission={updateRolePermissions.permissionsAccess.find(
                          (role) => role.permissionName === "news"
                        )}
                      />
                    )}
                    {!!updateRolePermissions.permissionsAccess.find(
                      (role) => role.permissionName === "events"
                    ) && (
                      <UpdatePermission
                        permission={updateRolePermissions.permissionsAccess.find(
                          (role) => role.permissionName === "events"
                        )}
                      />
                    )}
                    {!!updateRolePermissions.permissionsAccess.find(
                      (role) => role.permissionName === "exams"
                    ) && (
                      <UpdatePermission
                        permission={updateRolePermissions.permissionsAccess.find(
                          (role) => role.permissionName === "exams"
                        )}
                      />
                    )}
                    {!!updateRolePermissions.permissionsAccess.find(
                      (role) => role.permissionName === "users"
                    ) && (
                      <UpdatePermissionParent
                        permission={updateRolePermissions.permissionsAccess.find(
                          (role) => role.permissionName === "users"
                        )}
                        childrenRowsWithButtons={[
                          updateRolePermissions.permissionsAccess.find(
                            (role) =>
                              role.permissionName === "assignCertificates"
                          ),
                          updateRolePermissions.permissionsAccess.find(
                            (role) => role.permissionName === "subscriptions"
                          ),
                        ].filter((elem) => {
                          return !!elem;
                        })}
                        childrenRowsSimple={[
                          updateRolePermissions.permissionsAccess.find(
                            (role) => role.permissionName === "permissions"
                          ),
                          updateRolePermissions.permissionsAccess.find(
                            (role) => role.permissionName === "exportUserData"
                          ),
                        ].filter((elem) => {
                          return !!elem;
                        })}
                        buttonsParent={true}
                      />
                    )}
                    {!!updateRolePermissions.permissionsAccess.find(
                      (role) => role.permissionName === "documents"
                    ) && (
                      <UpdatePermission
                        permission={updateRolePermissions.permissionsAccess.find(
                          (role) => role.permissionName === "documents"
                        )}
                      />
                    )}
                    {!!updateRolePermissions.permissionsAccess.find(
                      (role) => role.permissionName === "press"
                    ) && (
                      <UpdatePermission
                        permission={updateRolePermissions.permissionsAccess.find(
                          (role) => role.permissionName === "press"
                        )}
                      />
                    )}
                    {!!updateRolePermissions.permissionsAccess.find(
                      (role) => role.permissionName === "newsletter"
                    ) && (
                      <UpdatePermissionParent
                        permission={updateRolePermissions.permissionsAccess.find(
                          (role) => role.permissionName === "newsletter"
                        )}
                        childrenRowsWithButtons={[
                          updateRolePermissions.permissionsAccess.find(
                            (role) =>
                              role.permissionName === "newsletterActiveMembers"
                          ),
                          updateRolePermissions.permissionsAccess.find(
                            (role) =>
                              role.permissionName ===
                              "newsletterResignedMembers"
                          ),
                          updateRolePermissions.permissionsAccess.find(
                            (role) =>
                              role.permissionName === "newsletterSimpleUsers"
                          ),
                        ].filter((elem) => {
                          return !!elem;
                        })}
                        buttonsParent={true}
                      />
                    )}
                    {!!updateRolePermissions.permissionsAccess.find(
                      (role) => role.permissionName === "orders"
                    ) && (
                      <UpdatePermission
                        permission={updateRolePermissions.permissionsAccess.find(
                          (role) => role.permissionName === "orders"
                        )}
                      />
                    )}
                    {/* {!!updateRolePermissions.permissionsAccess.find(
                      (role) => role.permissionName === "orders"
                    ) && (
                      <UpdatePermissionParent
                        permission={updateRolePermissions.permissionsAccess.find(
                          (role) => role.permissionName === "orders"
                        )}
                        childrenRowsSimple={[
                          updateRolePermissions.permissionsAccess.find(
                            (role) => role.permissionName === "payments"
                          ),
                          updateRolePermissions.permissionsAccess.find(
                            (role) => role.permissionName === "coupons"
                          ),
                        ].filter((elem) => {
                          return !!elem;
                        })}
                        buttonsParent={true}
                      />
                    )} */}
                    {!!updateRolePermissions.permissionsAccess.find(
                      (role) => role.permissionName === "receipts"
                    ) && (
                      <UpdatePermission
                        permission={updateRolePermissions.permissionsAccess.find(
                          (role) => role.permissionName === "receipts"
                        )}
                      />
                    )}
                    {!!updateRolePermissions.permissionsAccess.find(
                      (role) => role.permissionName === "administrative"
                    ) && (
                      <UpdatePermissionParent
                        permission={updateRolePermissions.permissionsAccess.find(
                          (role) => role.permissionName === "administrative"
                        )}
                        childrenRowsWithButtons={[
                          updateRolePermissions.permissionsAccess.find(
                            (role) => role.permissionName === "certificates"
                          ),
                          updateRolePermissions.permissionsAccess.find(
                            (role) => role.permissionName === "products"
                          ),
                          updateRolePermissions.permissionsAccess.find(
                            (role) =>
                              role.permissionName === "subscriptionPlans"
                          ),
                        ].filter((elem) => {
                          return !!elem;
                        })}
                        childrenRowsSimple={[
                          updateRolePermissions.permissionsAccess.find(
                            (role) => role.permissionName === "aboutus"
                          ),
                          updateRolePermissions.permissionsAccess.find(
                            (role) => role.permissionName === "contactus"
                          ),
                          updateRolePermissions.permissionsAccess.find(
                            (role) =>
                              role.permissionName === "permissionsManagement"
                          ),
                          updateRolePermissions.permissionsAccess.find(
                            (role) => role.permissionName === "fieldsDomain"
                          ),
                          updateRolePermissions.permissionsAccess.find(
                            (role) => role.permissionName === "sponsors"
                          ),
                          updateRolePermissions.permissionsAccess.find(
                            (role) => role.permissionName === "exportData"
                          ),
                          updateRolePermissions.permissionsAccess.find(
                            (role) => role.permissionName === "massUpdate"
                          ),
                        ].filter((elem) => {
                          return !!elem;
                        })}
                        buttonsParent={false}
                      />
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Overlay>
      )}
    </Fragment>
  );
};

export default EditRole;
