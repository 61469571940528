import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Overlay from "shared/components/UI/Overlay";
import { useNavigate, useParams } from "react-router";
import Spinner from "shared/components/UI/Spinner";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "store/storeHooks";
import {
  getCertificateThunk,
  updateCertificateThunk,
} from "store/certificatesStore/certificationsReducer";
import {
  capitalizeFirst,
  getInputStyles,
  getReactSelectStyles,
} from "utils/utils";
import Select from "react-select";
import { CertificateFormType } from "./types";
import { CreateCertificate } from "shared/interfaces/Certificate.interface";
import { toast } from "react-toastify";
import Message from "shared/components/UI/Message";
import DisplayInputError from "shared/components/UI/DisplayInputError";
import GoBack from "shared/components/UI/GoBack";
import Creatable from "react-select/creatable";
import BadgeIcon from "shared/components/Certificates/BadgeIcon";
import { selectTranslationLanguage } from "store/translationStore/translationReducer";
import { PermissionDomain } from "shared/interfaces/Permission.interface";
import { RolePermissionCrudAction } from "shared/interfaces/RolePermission.interface";
import { selectLoggedInUser } from "store/authStore/authReducer";

const EditCertificate: (props: any) => JSX.Element = (props) => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const lang = selectTranslationLanguage();
  const { t } = useTranslation("common");
  const certificates = useAppSelector((state) => state.certificates);
  const loggedInUser = selectLoggedInUser();

  const [error, setError] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<CertificateFormType>({
    defaultValues: {
      title: "",
      description: "",
      variations: [],
    },
  });

  function canAccess(
    domain: PermissionDomain,
    action: RolePermissionCrudAction
  ): boolean {
    if (loggedInUser.permissions["*"]) {
      return true;
    } else {
      switch (loggedInUser.permissions[domain][action]) {
        case "NONE": {
          return false;
        }
        case "OWN": {
          return true;
        }
        case "ANY": {
          return true;
        }
        default: {
          return false;
        }
      }
    }
  }

  useEffect(() => {
    dispatch(getCertificateThunk(id as string));
  }, [id]);

  useEffect(() => {
    if (certificates.data.certificate) {
      const c = [...badgeOptions];
      certificates.data.certificate.variations.map((m: any) => {
        c.map((n) => {
          if (m.badge == n.badge) {
            n.code = m.code;
            n.selected = true;
          }
        });
      });
      setBadgeOptions(c);
      setValue("title", certificates.data.certificate.title);
      setValue("description", certificates.data.certificate.description);
    }
  }, [certificates.data.certificate]);

  function constructCertificate(data: CertificateFormType, options: []) {
    const certificate: CreateCertificate = {} as CreateCertificate;
    if (data.title) certificate.title = data.title;
    if (data.description) certificate.description = data.description;
    certificate.variations = options;

    return certificate;
  }

  const [badgeOptions, setBadgeOptions] = useState([
    { badge: "single", code: "", selected: false },
    { badge: "bronze", code: "", selected: false },
    { badge: "silver", code: "", selected: false },
    { badge: "gold", code: "", selected: false },
    { badge: "platinum", code: "", selected: false },
  ]);

  const onSubmit = async (data: CertificateFormType) => {
    let options: any = [...badgeOptions].filter((a) => a.selected === true);

    options = options.map((s: any) => ({ badge: s.badge, code: s.code }));
    if (options.length === 0) {
      toast.error(
        Message({
          action: "create",
          entity: "certificate",
          error: t("certificate.badgeError"),
          lang,
        })
      );
      return;
    } else if (
      options
        .map((option: any) => {
          return options.filter((o: any) => o.code === option.code).length > 1;
        })
        .some((v: boolean) => v)
    ) {
      setError(t("certificate.everyCodeMustBeUnique"));
      return;
    } else {
      setError("");
    }

    //first map over the options object to detect false values
    for (const option of options) {
      if (option.code === "") {
        toast.error(
          Message({
            action: "create",
            entity: t("certificate.certificate"),
            error: t("certificate.codeError"),
            lang,
          })
        );
        return;
      }
    }
    dispatch(
      updateCertificateThunk({
        certificateId: id as string,
        certificate: constructCertificate(data, options),
      })
    ).then((res: any) => {
      if (res.meta.requestStatus === "rejected") {
        toast.error(
          Message({
            action: "update",
            entity: t("certificate.certificate"),
            error: t("genericError"),
            lang,
          })
        );
        return;
      }
      toast.success(
        Message({
          action: "update",
          entity: t("certificate.certificate"),
          lang,
        })
      );
      navigate("/certificates");
    });
  };

  return (
    <Overlay active={certificates.loading} spinner={<Spinner />}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col flex-grow gap-5 p-10"
      >
        <GoBack uri="certificates" />
        <div className="flex items-center justify-between w-full">
          <div className="flex gap-5 items-center">
            <h1 className="text-3xl font-bold">
              {t("CertificateCRUD.hCreate")}
            </h1>
            {/* <h3 className="text-gray-300 text-xl">Update</h3> */}
          </div>
          {canAccess("certificates", "UPDATE") && (
            <button
              type="submit"
              className="px-4 py-2 text-white bg-users-create"
            >
              {t("certificate.save")}
            </button>
          )}
        </div>
        <div className="flex flex-col flex-grow">
          <div className="flex flex-col gap-5">
            <div className="flex flex-col gap-5 w-full">
              <div className="flex flex-col gap-2">
                <label
                  htmlFor="title"
                  className="block text-gray-700 text-lg font-medium"
                >
                  {t("CertificateCRUD.title")} *
                </label>
                <input
                  type="text"
                  placeholder={t("CertificateCRUD.title")}
                  className={getInputStyles(!!errors.title)}
                  autoComplete="off"
                  {...register("title", {
                    required: t("certificate.titleRequired") as string,
                  })}
                />
                <DisplayInputError message={errors.title?.message} />
              </div>
              <div className="flex flex-col gap-2">
                <label
                  htmlFor="description"
                  className="block text-gray-700 text-lg font-medium"
                >
                  {t("CertificateCRUD.description")} *
                </label>
                <input
                  type="text"
                  placeholder={t("CertificateCRUD.description")}
                  className={getInputStyles(!!errors.description)}
                  autoComplete="off"
                  {...register("description", {
                    required: t("certificate.descriptionRequired") as string,
                  })}
                />
                <DisplayInputError message={errors.description?.message} />
              </div>
            </div>

            <div className="">
              <div className="flex flex-col gap-2">
                <label
                  htmlFor="badge"
                  className="block text-gray-700 text-lg font-medium"
                >
                  <span className="mb-2"> {t("CertificateCRUD.badge")} *</span>
                  {badgeOptions.map((m: any, i) => (
                    <div
                      className="flex gap-10 mb-1 h-10 overflow-hidden"
                      key={i}
                    >
                      <label className="flex items-center justify-between w-1/4 font-normal">
                        <div className="flex gap-2 items-center">
                          {capitalizeFirst(m.badge)}
                          <BadgeIcon badge={m.badge} />
                        </div>

                        <input
                          onChange={() => {
                            const newArr = [...badgeOptions];
                            newArr[i].selected = !newArr[i].selected;
                            setBadgeOptions(newArr);
                          }}
                          type="checkbox"
                          checked={m.selected}
                          id="scales"
                          name="scales"
                          className="text-primary"
                        />
                      </label>
                      <div className={m.selected ? "block pt-1 " : "hidden"}>
                        <label>
                          <input
                            onChange={(e) => {
                              const newArr = [...badgeOptions];
                              newArr[i].code = e.target.value;
                              setBadgeOptions(newArr);
                            }}
                            value={m.code}
                            maxLength={5}
                            className="h-8 focus:border-global-input-focus border-gray-300"
                            placeholder={t("certificate.insertCode")}
                            type="text"
                          />
                        </label>
                      </div>
                    </div>
                  ))}
                </label>
                {error ? (
                  <div className="text-global-message-error text-xs">
                    {error}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </form>
    </Overlay>
  );
};

export default EditCertificate;
