import React, { useMemo, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { selectLoggedInUser } from "store/authStore/authReducer";
import { useAppDispatch, useAppSelector } from "store/storeHooks";
import { getDocumentsThunk } from "store/documentsStore/documentsReducer";
import { Document } from "shared/interfaces/Document.interface";
import { getDocumentsColumns } from "api/Documents/documents.api";
import axios from "axios";
import {
  SearchIcon,
  PlusIcon,
  DotsVerticalIcon,
  PencilIcon,
  TrashIcon,
  EyeIcon,
  EyeOffIcon,
} from "@heroicons/react/solid";
import { ExclamationIcon } from "@heroicons/react/outline";
import Modal from "shared/components/UI/Modal";
import { Link } from "react-router-dom";
import {
  Column,
  useFlexLayout,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import Pagination from "shared/components/Table/Pagination";
import Table from "shared/components/Table/Table";
import { useDebounce } from "shared/hooks/useDebounce";
import { CMS_BE_URL } from "api/urls";
import TablePopup, { PortalType } from "shared/components/UI/TablePopup";
import Spinner from "shared/components/UI/Spinner";
import Overlay from "shared/components/UI/Overlay";
import { PermissionDomain } from "shared/interfaces/Permission.interface";
import { RolePermissionCrudAction } from "shared/interfaces/RolePermission.interface";
import { toast } from "react-toastify";
import Message from "shared/components/UI/Message";

export default function News(): JSX.Element {
  const lang = useAppSelector((state) => state.translation.language);
  const loggedInUser = selectLoggedInUser();
  const { t } = useTranslation("common");
  const [openModal, setOpenModal] = useState(false);
  const [currentPageSize, setCurrentPageSize] = useState(10);
  const documents = useAppSelector((state) => state.documents);
  const [searchText, setSearchText] = useState("");
  const dispatch = useAppDispatch();
  const debouncedSearchText = useDebounce(searchText, 300);
  const [documentDelete, setDocumentDelete] = useState("");

  const [portal, setPortal] = useState<PortalType<any>>({
    open: false,
    top: 0,
    left: 0,
    entity: null,
  });

  function canAccess(
    domain: PermissionDomain,
    action: RolePermissionCrudAction,
    document?: Document
  ): boolean {
    if (loggedInUser.permissions["*"]) {
      return true;
    } else {
      switch (loggedInUser.permissions[domain][action]) {
        case "NONE": {
          return false;
        }
        case "OWN": {
          if (action === "CREATE") return true;
          if (document && document.owner.id === loggedInUser.user?.PK_USER)
            return true;
          return false;
        }
        case "ANY": {
          return true;
        }
        default: {
          return false;
        }
      }
    }
  }

  const handleModal = (documentToDelete: string) => {
    setOpenModal(true);
    setDocumentDelete(documentToDelete);
  };

  const columns: Array<Column<Record<string, any>>> = useMemo(
    () => getDocumentsColumns(lang),
    [lang]
  );

  function getDocuments(pageIndex: number) {
    return dispatch(
      getDocumentsThunk({
        pageIndex: pageIndex,
        pageSize: currentPageSize,
        searchString: debouncedSearchText,
        sortCol: sortBy[0] ? sortBy[0].id : "",
        sortOrder: sortBy[0] ? (sortBy[0].desc ? "DESC" : "ASC") : "",
      })
    ).then((res) => {
      if (res.meta.requestStatus === "rejected") {
        toast.error(
          Message({
            action: "read",
            entity: t("Documents.document"),
            error: t("genericError"),
            lang,
            gender: "male",
          })
        );
      }
      return res;
    });
  }

  const handleDeleteDocument = async (doc: string) => {
    try {
      await axios.delete(`${CMS_BE_URL}/documents/${doc}`);
      getDocuments(0);
      setOpenModal(false);
    } catch (err: any) {
      if (err.response?.data?.errors?.[0]?.message) {
        toast.error(err.response?.data?.errors?.[0]?.message);
      } else {
        toast.error(err.response?.data?.message);
      }
    }
  };

  const data: Array<any> = useMemo(() => {
    return documents.data.data.map((doc, i) => {
      const filename = doc.file?.name;
      return {
        col1: (
          <div className="flex flex-col gap-1">
            <div className="truncate">{doc.title}</div>
            <div>
              {doc.tags.length === 0 ? (
                "No Tags Associated"
              ) : (
                <div className="flex flex-nowrap gap-2">
                  {doc.tags.map((t: any, i: any) => (
                    <span
                      key={i}
                      className="p-1 text-white bg-events-tags rounded-sm truncate"
                    >
                      {t.pk_tag}
                    </span>
                  ))}
                </div>
              )}
            </div>
          </div>
        ),
        col2: (
          <div className="flex items-center h-full">
            <span className="truncate">{filename}</span>
          </div>
        ),

        col3: (
          <div className="flex items-center h-full">
            <span className="truncate">{doc?.owner.id}</span>
          </div>
        ),
        col4: (
          <div className="flex items-center h-full">
            <span className="truncate">{doc?.created_at?.slice(0, 10)}</span>{" "}
          </div>
        ),

        col5: (
          <div className="flex items-center h-full truncate">
            <span
              className={`rounded-full px-2 py-1 capitalize ${
                doc.is_visible
                  ? "text-users-active bg-users-active-bg"
                  : "text-users-inactive bg-users-inactive-bg"
              }`}
            >
              {doc.is_visible ? "visible" : "non visible"}
            </span>
          </div>
        ),
        col6: (
          <div className="relative flex items-center h-full">
            <button
              id={`portal-button-${i}`}
              type="button"
              onClick={(e) => {
                return setPortal((state: any) => {
                  const thisButton = document.getElementById(
                    `portal-button-${i}`
                  );
                  const position = thisButton?.getBoundingClientRect();
                  return {
                    open: !state.open,
                    top: Number(position?.bottom),
                    left: Number(
                      window.innerWidth - Number(position?.x) <= 150
                        ? Number(position?.left) - 150
                        : Number(position?.left)
                    ),
                    entity: doc,
                  };
                });
              }}
            >
              <DotsVerticalIcon className="w-5 h-5" />
            </button>
          </div>
        ),
      };
    });
  }, [documents.data.data, lang]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
      pageCount: Math.ceil(documents.data.total / currentPageSize),
      manualSortBy: true,
      manualPagination: true,
    },
    useSortBy,
    usePagination,
    useFlexLayout
  );

  const {
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, sortBy },
  } = tableInstance;

  useEffect(() => {
    getDocuments(0);
  }, [sortBy, debouncedSearchText]);

  const dispatchNextPage = () => {
    if (canNextPage) {
      getDocuments(pageIndex + 1).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          nextPage();
        }
      });
    }
  };

  const dispatchPreviousPage = () => {
    if (canPreviousPage) {
      getDocuments(pageIndex - 1).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          previousPage();
        }
      });
    }
  };

  const dispatchGotoPage = (pageIndex: number) => {
    getDocuments(pageIndex).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        gotoPage(pageIndex);
      }
    });
  };

  const editVisibility = async () => {
    const v = !portal.entity.is_visible ? 1 : 0;
    const data = {
      is_visible: v,
    };
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]: any) => {
      if (Array.isArray(value)) {
        value = JSON.stringify(value);
      }
      formData.append(key, value);
    });
    try {
      await axios.put(
        `${CMS_BE_URL}/documents/${portal.entity.pk_document}`,
        formData
      );
      getDocuments(0);
    } catch (err: any) {
      if (err.response?.data?.errors?.[0]?.message) {
        toast.error(err.response?.data?.errors?.[0]?.message);
      } else {
        toast.error(err.response?.data?.message);
      }
    }
  };

  function portalContent() {
    if (portal.entity === null) return;
    return (
      <div
        className="fixed z-50 flex flex-col gap-2 p-2 bg-white rounded-sm shadow-md"
        style={{
          width: "150px",
          top: portal.top,
          left: portal.left,
        }}
      >
        {canAccess("documents", "UPDATE", portal.entity) ? (
          <Link
            to={`/documents/${portal.entity.pk_document}`}
            className="flex gap-2 items-center text-table-edit"
          >
            <PencilIcon className="w-5 h-5" />
            {lang === "en" ? "Edit" : "Modificare"}
          </Link>
        ) : null}
        {canAccess("documents", "DELETE", portal.entity) ? (
          <button
            onClick={() => handleModal(String(portal.entity?.pk_document))}
            className="flex gap-2 items-center text-users-delete"
          >
            <TrashIcon className="w-5 h-5" />
            {lang === "en" ? "Delete" : "Eliminare"}
          </button>
        ) : null}
        {canAccess("documents", "UPDATE", portal.entity) ? (
          <button
            className="flex gap-2 items-center text-users-edit"
            onClick={editVisibility}
          >
            {portal.entity.is_visible ? (
              <EyeOffIcon className="w-5 h-5" />
            ) : (
              <EyeIcon className="w-5 h-5" />
            )}
            {lang === "en"
              ? portal.entity.is_visible
                ? "Invisible"
                : "Visible"
              : portal.entity.is_visible
              ? "Invisible"
              : "Visible"}
          </button>
        ) : null}
      </div>
    );
  }

  return (
    <Overlay active={documents.loading} spinner={<Spinner />}>
      <div className="flex flex-col gap-5 p-10 h-full">
        <h1 className="text-3xl font-bold">{t("Documents.header")}</h1>
        <div className="flex gap-5 items-center justify-end">
          <div className="flex items-center border focus-within:border-global-input-focus border-gray-300">
            <div className="flex items-center justify-center p-2 h-full bg-white">
              <SearchIcon className="w-5 h-5 text-gray-400" />
            </div>
            <input
              type="text"
              value={searchText}
              placeholder={`${t("UserCRUD.searchPlaceholder")}`}
              onChange={(e) => setSearchText(e.target.value)}
              className="placeholder-gray-400 p-2 w-full border-0 focus:ring-0"
            />
          </div>
          {(loggedInUser.permissions["*"] ||
            loggedInUser.permissions?.documents?.CREATE === "ANY") && (
            <Link to="/documents/create">
              <button className="flex gap-2 items-center justify-center px-4 py-2 text-white bg-global-createEntity border border-global-createEntity">
                <PlusIcon className="w-5 h-5" />
                <span className="whitespace-nowrap">
                  {t("Documents.createDocButton")}
                </span>
              </button>
            </Link>
          )}
        </div>
        <Table {...tableInstance} />
        <Pagination
          rowsCount={documents.data.total}
          pageIndex={pageIndex}
          pageSize={currentPageSize}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          pageOptions={pageOptions}
          pageCount={pageCount}
          gotoPage={dispatchGotoPage}
          nextPage={dispatchNextPage}
          previousPage={dispatchPreviousPage}
          setPageSize={setPageSize}
        />
        <TablePopup
          isOpen={portal.open}
          close={() => setPortal((state) => ({ ...state, open: false }))}
        >
          {portalContent()}
        </TablePopup>
        <Modal
          openStatus={openModal}
          setOpen={setOpenModal}
          className=""
          icon={
            <ExclamationIcon
              className="w-6 h-6 text-red-600 bg-transparent"
              aria-hidden="true"
            />
          }
          header={<p>{t("Documents.deleteDocumentModalHeader")}</p>}
          title={<p>{t("Documents.deleteDocumentModalMessage")}</p>}
          footer={
            <div className="mt-5 sm:flex sm:flex-row-reverse sm:mt-4">
              <button
                type="button"
                className="inline-flex justify-center px-4 py-1 w-full text-white text-base font-medium bg-red-600 hover:bg-red-700 border border-transparent focus:outline-none shadow-sm focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                onClick={async () => await handleDeleteDocument(documentDelete)}
              >
                {t("UserCRUD.deleteButton")}
              </button>
              <button
                type="button"
                className="inline-flex justify-center mt-3 px-4 py-1 w-full hover:text-gray-500 text-gray-700 text-base font-medium bg-white border border-gray-300 focus:outline-none shadow-sm focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                onClick={() => setOpenModal(false)}
              >
                {t("UserCRUD.cancelButton")}
              </button>
            </div>
          }
        />
      </div>
    </Overlay>
  );
}
