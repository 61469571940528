import { Product } from "shared/interfaces/Product.new.interface";

export interface ProductsState {
  data: {
    message: string;
    total: number;
    pageIndex: number;
    data: Product[];
    product?: Product;
  };
  loading: boolean;
}

export const ProductsInitialState: ProductsState = {
  data: {
    message: "",
    total: 0,
    pageIndex: 0,
    data: [],
    product: undefined,
  },
  loading: false,
};
