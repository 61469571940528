import React, { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { map, prop, equals, compose, filter } from "ramda";
import CouncilRanks from "./CouncilRanks";
import UserRanks from "./UserRanks";
import Spinner from "shared/components/UI/Spinner";
import Overlay from "shared/components/UI/Overlay";
import { selectTags } from "store/tagsStore/tagsReducer";
import { selectUserRanks } from "store/userRanksStore/userRanksReducer";
import { selectLoggedInUser } from "store/authStore/authReducer";
import ShippingCost from "./ShippingCost";
import PresidentialRanks from "./PresidentialRank";
import PageMod from "./PageMod";
import FacebookUrl from "./FacebookUrl";
import VatNumber from "./VatNumber";
import FiscalCode from "./FiscalCode";
import CardNumber from "./CardNumber";

type Tab = {
  name: string;
  href: string;
  component: any;
  domain: string;
};

export default function FieldsDomain() {
  const { t } = useTranslation("common");
  const loggedInUser = selectLoggedInUser();
  const tags = selectTags();
  const userRanks = selectUserRanks();
  const tabs = useMemo<Tab[]>(
    () => [
      {
        name: t("fieldsDomain.userRanks"),
        href: "userRanks",
        component: UserRanks,
        domain: "ranks",
      },
      {
        name: t("fieldsDomain.councilRanks"),
        href: "councilRanks",
        component: CouncilRanks,
        domain: "ranks",
      },
      {
        name: t("fieldsDomain.presidentialRanks"),
        href: "presidentialRanks",
        component: PresidentialRanks,
        domain: "ranks",
      },
      {
        name: t("fieldsDomain.shippingCost"),
        href: "shippingCost",
        component: ShippingCost,
        domain: "fieldsDomain",
      },
      {
        name: t("sidebar.pages"),
        href: "pages",
        component: PageMod,
        domain: "fieldsDomain",
      },
      {
        name: t("fieldsDomain.facebookUrl"),
        href: "facebookUrl",
        component: FacebookUrl,
        domain: "fieldsDomain",
      },
      {
        name: t("fieldsDomain.vatNumber"),
        href: "vatNumber",
        component: VatNumber,
        domain: "fieldsDomain",
      },
      {
        name: t("fieldsDomain.fiscalCode"),
        href: "fiscalCode",
        component: FiscalCode,
        domain: "fieldsDomain",
      },
      {
        name: t("fieldsDomain.cardNumber"),
        href: "cardNumber",
        component: CardNumber,
        domain: "cardNumber",
      },
    ],
    [t]
  );
  const [activeTab, setActiveTab] = useState<Tab>(tabs[0]);
  const getCurrentTabStyles = useMemo(
    () => (activeTab: Tab, tab: Tab) => {
      const name = prop("name");
      return " pb-1 ".concat(
        equals(name(activeTab), name(tab))
          ? "border-b-2 border-global-stepper border-global-stepperActive"
          : ""
      );
    },
    []
  );
  const canAccess = useMemo(
    () => (tab: Tab) => {
      if (loggedInUser.permissions["*"]) return true;
      const isEnalbed = loggedInUser.permissions[tab.domain]?.["ENABLED"];
      if (isEnalbed) {
        const perm = loggedInUser.permissions[tab.domain]?.["READ"];
        if (perm === "OWN" || perm === "ANY") return true;
      }
      return false;
    },
    []
  );
  const displayTabs = useMemo(
    () =>
      compose(
        map((tab: Tab) => (
          <button
            key={tab.name}
            type="button"
            onClick={() => setActiveTab(tab)}
            className={getCurrentTabStyles(activeTab, tab)}
          >
            {tab.name}
          </button>
        )),
        filter((tab: Tab) => canAccess(tab))
      ),
    [activeTab]
  );

  return (
    <Overlay active={tags.loading || userRanks.loading} spinner={<Spinner />}>
      <div className="flex flex-col gap-10 p-10">
        <h1 className="text-3xl font-bold">{t("fieldsDomain.fieldsDomain")}</h1>
        <div className="flex gap-10">{displayTabs(tabs)}</div>
        <activeTab.component />
      </div>
    </Overlay>
  );
}
