import React from "react";
import { useTranslation } from "react-i18next";
import { User } from "shared/interfaces/User.interface";
import { LowercaseKeys } from "utils/utils";

function ShortUserDetails({ user }: { user: LowercaseKeys<User> }) {
  const { t } = useTranslation("common");
  if (!user) return null;
  return (
    <div className="flex flex-col gap-2">
      {user?.name && user?.surname && (
        <div>
          {user.name} {user.surname}
        </div>
      )}
      {user?.card_number && (
        <div>
          {t("receipt.card")} No. {user.card_number}
        </div>
      )}
      {user.address_details &&
        user.address_details[0]?.address_name &&
        user.address_details[0]?.address_number && (
          <div>
            {user.address_details[0]?.address_name}{" "}
            {user.address_details[0]?.address_number}
          </div>
        )}
      {user.address_details &&
        user.address_details[0]?.postal_code &&
        user.address_details[0]?.fk_region && (
          <div>
            {user.address_details[0]?.postal_code}
            {", "}
            {user.address_details[0]?.fk_region}
          </div>
        )}
      {user.contact_details && user.contact_details[0]?.contact_details && (
        <div>{user.contact_details[0]?.contact_details}</div>
      )}
    </div>
  );
}

export default ShortUserDetails;
