import { CMS_BE_URL } from "api/urls";
import {
  MassUpdateOrdersDtoExclude,
  MassUpdateOrdersDtoInclude,
} from "./orders.api";

export const createOrderURL = `${CMS_BE_URL}/s/orders`;

type LogicalQueryParameter = Partial<{
  in: string[];
  notIn: string[];
}>;

type NumericQueryParameter = Partial<{
  gt: string;
  gte: string;
  lt: string;
  lte: string;
  between: string[];
  notBetween: string[];
}>;

function handleLogicalQueryParameter(
  name: string,
  filters: LogicalQueryParameter
) {
  if (filters["in"]) return `${name}[in]=${JSON.stringify(filters["in"])}`;
  else if (filters["notIn"])
    return `${name}[notIn]=${JSON.stringify(filters["notIn"])}`;
  else return "";
}

function handleNumericQueryParameter(
  name: string,
  filters: NumericQueryParameter
) {
  if (filters)
    return Object.entries(filters)
      .map(([key, value]) => `${name}[${key}]=${JSON.stringify(value)}`)
      .join("&");
  return "";
}

function isStatusQueryParameter(par: string) {
  return par === "status";
}

function isSubtotalQueryParameter(par: string) {
  return par === "subtotal";
}

function isPaymentDateQueryParameter(par: string) {
  return par === "paymentDate";
}

function isOriginQueryParamter(par: string) {
  return par === "origin";
}

function isRankQueryParameter(par: string) {
  return par === "rank";
}

function isProductClassQueryParameter(par: string) {
  return par === "productClass";
}

function isPaymentTypeQueryParameter(par: string) {
  return par === "paymentType";
}

function isYearlyIdQueryParameter(par: string) {
  return par === "yearlyId";
}

export type UpdateOrdersFilters = Omit<
  GetOrdersFilters,
  "pageIndex" | "pageSize" | "sortCol" | "sortOrder"
>;

export type ExportOrdersFilters = Omit<
  GetOrdersFilters,
  "pageIndex" | "pageSize" | "sortCol" | "sortOrder"
>;

export type GetOrdersFilters = {
  customerId?: string;
  fullname?: string;
  origin?: LogicalQueryParameter;
  status?: LogicalQueryParameter;
  subtotal?: NumericQueryParameter;
  include?: string;
  paymentDate?: NumericQueryParameter;
  rank?: LogicalQueryParameter;
  productClass?: LogicalQueryParameter;
  paymentType?: LogicalQueryParameter;
  yearlyId?: NumericQueryParameter;
  pageSize: number;
  pageIndex?: number;
  sortCol?: string;
  sortOrder?: "ASC" | "DESC" | "";
  searchString?: string;
};

function constructOrdersEnchancedQuery(
  filters: UpdateOrdersFilters | ExportOrdersFilters | GetOrdersFilters
) {
  return Object.entries(filters)
    .filter(([, value]) => !!value)
    .map(([key, value]) => {
      if (isStatusQueryParameter(key))
        return handleLogicalQueryParameter("status", value as any);
      else if (isSubtotalQueryParameter(key))
        return handleNumericQueryParameter("subtotal", value as any);
      else if (isPaymentDateQueryParameter(key)) {
        return handleNumericQueryParameter("paymentDate", value as any);
      } else if (isOriginQueryParamter(key)) {
        return handleLogicalQueryParameter("origin", value as any);
      } else if (isRankQueryParameter(key)) {
        return handleLogicalQueryParameter("rank", value as any);
      } else if (isProductClassQueryParameter(key)) {
        return handleLogicalQueryParameter("productClass", value as any);
      } else if (isPaymentTypeQueryParameter(key)) {
        return handleLogicalQueryParameter("paymentType", value as any);
      } else if (isYearlyIdQueryParameter(key)) {
        return handleNumericQueryParameter("yearlyId", value as any);
      } else {
        if (value) return `${key}=${value}`;
      }
    })
    .join("&");
}

export const constructGetOrdersURL = (filters: GetOrdersFilters) => {
  return `${CMS_BE_URL}/s/orders?${constructOrdersEnchancedQuery(filters)}`;
};

export const constructGetOrderURL = (orderId: string) =>
  `${CMS_BE_URL}/s/orders/${orderId}?include=billingAddress,shippingAddress,products,payment,user`;

export const constructExportOrderUrl = (orderId: string) =>
  `${CMS_BE_URL}/s/orders/${orderId}/export?include=billingAddress,shippingAddress,products,payment,user`;

export const constructUpdateOrderURL = (orderId: string) =>
  `${CMS_BE_URL}/s/orders/${orderId}`;

export const constructUpdateOrdersURL = (
  filters:
    | MassUpdateOrdersDtoInclude["filters"]
    | MassUpdateOrdersDtoExclude["filters"]
) => {
  return `${CMS_BE_URL}/s/orders?${constructOrdersEnchancedQuery(filters)}`;
};

export const constructDeleteOrderURL = (orderId: string) =>
  `${CMS_BE_URL}/s/orders/${orderId}`;

export const constructExportOrdersURL = (
  filters:
    | MassUpdateOrdersDtoInclude["filters"]
    | MassUpdateOrdersDtoExclude["filters"]
) => {
  return `${CMS_BE_URL}/s/orders/export?${constructOrdersEnchancedQuery(
    filters
  )}`;
};

export const constructDeleteOrdersURL = (
  filters:
    | MassUpdateOrdersDtoInclude["filters"]
    | MassUpdateOrdersDtoExclude["filters"]
) => {
  return `${CMS_BE_URL}/s/orders/massDelete?${constructOrdersEnchancedQuery(
    filters
  )}`;
};
