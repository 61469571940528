import axios from "axios";
import {
  activateAccountURL,
  getUserPermissionsURL,
  loginURL,
  resetPasswordURL,
} from "./urls";

export function loginUser(data: { username: string; password: string }) {
  return axios.post(loginURL(), { ...data, type: "CMS" });
}

export function getUserPermissions({ userId }: { userId: string }) {
  return axios.get(getUserPermissionsURL({ userId }));
}

export function forgotPassword(data: { email: string }): Promise<Response> {
  return fetch(resetPasswordURL(), {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
}

export function activateAccount(data: {
  username: string;
  password: string;
  token: string;
}): Promise<Response> {
  return fetch(activateAccountURL(), {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${data.token}`,
    },
    body: JSON.stringify({
      username: data.username,
      new_password: data.password,
    }),
  });
}
