import React from "react";
import { GlobeIcon } from "@heroicons/react/solid";
import { useAppSelector, useAppDispatch } from "store/storeHooks";
import { translationActions } from "store/globalStore";

function LanguageButton({
  onClick,
  language,
}: {
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
  language: string;
}) {
  return (
    <button
      type="button"
      onClick={onClick}
      className="flex gap-1 justify-center px-1 text-global-changeLanguage bg-white border border-white rounded-sm"
    >
      <GlobeIcon className="w-6 h-6" />
      <div>{language}</div>
    </button>
  );
}

export default function Language() {
  const dispatch = useAppDispatch();
  const language = useAppSelector((state) => state.translation.language);
  const toEnglish = () =>
    dispatch(translationActions.changeLanguage({ language: "en" }));
  const toItalian = () =>
    dispatch(translationActions.changeLanguage({ language: "it" }));
  if (language === "it")
    return <LanguageButton onClick={toEnglish} language={`EN`} />;
  else if (language === "en")
    return <LanguageButton onClick={toItalian} language={`IT`} />;
  else return null;
}
