import { CMS_BE_URL } from "api/urls";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import DisplayInputError from "shared/components/UI/DisplayInputError";
import Message from "shared/components/UI/Message";
import { selectLoggedInUser } from "store/authStore/authReducer";
import { useAppDispatch } from "store/storeHooks";
import { selectTranslationLanguage } from "store/translationStore/translationReducer";
import { getInputStyles } from "utils/utils";

export default function ShippingCost() {
  const { t } = useTranslation("common");
  const lang = selectTranslationLanguage();
  const loggedInUser = selectLoggedInUser();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);

  const {
    watch,
    register,
    getValues,
    formState: { errors },
    setValue,
    handleSubmit,
  } = useForm<{ cost: number }>({
    mode: "onChange",
    defaultValues: {
      cost: 0,
    },
  });

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${CMS_BE_URL}/globalVariables/${1}`)
      .then((res) => setValue("cost", Number(res.data.variable.value)))
      .catch((err: any) => {
        if (err.response?.data?.errors?.[0]?.message) {
          toast.error(err.response?.data?.errors?.[0]?.message);
        } else {
          toast.error(err.response?.data?.message);
        }
      })
      .finally(() => setLoading(false));
  }, []);

  const onSubmit = handleSubmit((data) => {
    setLoading(true);
    axios
      .put(`${CMS_BE_URL}/globalVariables/${1}`, { value: data.cost })
      .then(() =>
        toast.success(
          Message({
            action: "update",
            entity: t("shippingCost.cost"),
            lang,
          })
        )
      )
      .catch(
        (err: any) => {
          if (err.response?.data?.errors?.[0]?.message) {
            toast.error(err.response?.data?.errors?.[0]?.message);
          } else {
            toast.error(err.response?.data?.message);
          }
        }
        // toast.error(
        //   Message({
        //     action: "update",
        //     entity: t("shippingCost.cost"),
        //     error: t("genericError"),
        //     lang,
        //   })
        // )
      )
      .finally(() => setLoading(false));
  });

  return (
    <form className="flex flex-col gap-5" onSubmit={onSubmit}>
      <div className="flex gap-2">
        <div className="flex flex-col gap-2">
          <label className="flex flex-col gap-2">
            <span className="text-lg font-semibold">
              {t("shippingCost.cost")}
            </span>
          </label>
          <div className="flex gap-2">
            <input
              type="text"
              placeholder={`${t("shippingCost.cost")}`}
              {...register("cost", {
                validate: {
                  isNumber(e) {
                    if (isNaN(Number(e)))
                      return t("shippingCost.NaN") as string;
                    return true;
                  },
                },
              })}
              className={getInputStyles(!!errors.cost?.message)}
            />
            <button
              type="submit"
              className="px-4 py-2 text-white bg-primary border border-primary rounded-sm"
            >
              {t("shippingCost.update")}
            </button>
          </div>
          <DisplayInputError message={errors.cost?.message} />
        </div>
      </div>
    </form>
  );
}
