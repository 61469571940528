import React from "react";
export default function ANMB(): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 613.39 267"
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="70.72"
          y1="148.96"
          x2="588.35"
          y2="148.96"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#006838" stopOpacity="0" />
          <stop offset="0.05" stopColor="#006838" stopOpacity="0.03" />
          <stop offset="0.09" stopColor="#006838" stopOpacity="0.11" />
          <stop offset="0.12" stopColor="#006838" stopOpacity="0.26" />
          <stop offset="0.15" stopColor="#006838" stopOpacity="0.46" />
          <stop offset="0.17" stopColor="#006838" stopOpacity="0.73" />
          <stop offset="0.18" stopColor="#006838" stopOpacity="0.9" />
          <stop offset="0.3" stopColor="#006838" stopOpacity="0.92" />
          <stop offset="0.36" stopColor="#006838" />
          <stop offset="0.37" stopColor="#006838" />
          <stop offset="0.57" stopColor="#fff" />
          <stop offset="0.89" stopColor="#ed1c24" />
          <stop offset="1" stopColor="#ed1c24" />
        </linearGradient>
      </defs>
      <g id="rettangolo_nero" data-name="rettangolo nero">
        <path
          d="M616.39,257.81a11.86,11.86,0,0,1-12,11.75H15A11.86,11.86,0,0,1,3,257.81V14.31A11.86,11.86,0,0,1,15,2.56H604.43a11.86,11.86,0,0,1,12,11.75Z"
          transform="translate(-3 -2.56)"
          fill="#231f20"
        />
      </g>
      <g id="filetto_bianco_rettangolo" data-name="filetto bianco rettangolo">
        <path
          d="M606.72,249.21a12.24,12.24,0,0,1-12.12,12.35H25.84a12.24,12.24,0,0,1-12.12-12.35V26.91A12.24,12.24,0,0,1,25.84,14.56H594.6a12.24,12.24,0,0,1,12.12,12.35Z"
          transform="translate(-3 -2.56)"
          fill="none"
          stroke="#fff"
          strokeMiterlimit="10"
          strokeWidth="5"
        />
      </g>
      <g id="baffo_tricolore" data-name="baffo tricolore">
        <path
          d="M530.39,55.56s69.33,36,56.33,78.67-38.67,54-70.67,70.66-128.66,39.34-198.66,37.34-160.17-4.39-246.67-67c0,0,25-8.79,28.5-30.22s8.36,52.63,216.5,54.25c0,0,106,7.33,196-44.67,0,0,21.5-11,34-31.5,0,0,18-24.17-3.33-53.83l-8.33-10Z"
          transform="translate(-3 -2.56)"
          fill="url(#linear-gradient)"
        />
      </g>
      <g id="testi">
        <path
          d="M130,125.28,89.43,60.69,48.24,125.28H31.72L84,45.08H94.79l51.91,80.2Z"
          transform="translate(-3 -2.56)"
          fill="#fff"
        />
        <path
          d="M250.74,125.28,166.91,62.85v62.43H154V45.08h12.83l78.26,59.58V45.08H258v80.2Z"
          transform="translate(-3 -2.56)"
          fill="#fff"
        />
        <path
          d="M378.45,125.28V69.12l-40,56.16H326.32l-40.08-56v56.05H272.51V45.08h13.84L333,110.59l46.44-65.51h12.73v80.2Z"
          transform="translate(-3 -2.56)"
          fill="#fff"
        />
        <path
          d="M406.69,125.28V45.08h64a44.85,44.85,0,0,1,11.11,1.2,20.27,20.27,0,0,1,7.48,3.42,13.28,13.28,0,0,1,4.18,5.46,18.9,18.9,0,0,1,1.34,7.24v7.4a15,15,0,0,1-2.51,8.83,10.77,10.77,0,0,1-6.76,4.5,16,16,0,0,1,7.37,4.27q2.91,3,2.91,8.83V108a18.58,18.58,0,0,1-1.18,6.55,13.17,13.17,0,0,1-4,5.52,20.93,20.93,0,0,1-7.64,3.82,43.13,43.13,0,0,1-12.06,1.42ZM479.92,63.2c0-2.21-.67-3.8-2-4.79s-3.75-1.48-7.25-1.48H421.54V77.78h49.12c3.5,0,5.91-.5,7.25-1.48s2-2.58,2-4.79Zm1,33.26c0-2.51-.93-4.27-2.79-5.3a15,15,0,0,0-7.26-1.53H421.54v23.81h49.34a13.74,13.74,0,0,0,7.26-1.71q2.79-1.71,2.79-6.61Z"
          transform="translate(-3 -2.56)"
          fill="#fff"
        />
        <path
          d="M57.74,145l-8.26-12.85L41.09,145H37.72l10.65-16h2.2l10.58,16Z"
          transform="translate(-3 -2.56)"
          fill="#fff"
        />
        <path
          d="M61.86,145v-2.35H75.78c1.33,0,2-.49,2-1.48v-2c0-1-.67-1.48-2-1.48H66.36a8.23,8.23,0,0,1-2.32-.28,4.1,4.1,0,0,1-1.56-.8,2.89,2.89,0,0,1-.87-1.21,4.13,4.13,0,0,1-.28-1.56v-.93a4.14,4.14,0,0,1,.28-1.57,3,3,0,0,1,.87-1.21,4.24,4.24,0,0,1,1.56-.79,8.2,8.2,0,0,1,2.32-.29H79.92v2.36H66.36c-1.34,0-2,.49-2,1.48v1c0,1,.66,1.47,2,1.47h9.42a8.2,8.2,0,0,1,2.32.29,4.4,4.4,0,0,1,1.56.79,3,3,0,0,1,.87,1.21,4.35,4.35,0,0,1,.27,1.57v2a4.25,4.25,0,0,1-.27,1.56,3,3,0,0,1-.87,1.22,4.4,4.4,0,0,1-1.56.79,8.23,8.23,0,0,1-2.32.28Z"
          transform="translate(-3 -2.56)"
          fill="#fff"
        />
        <path
          d="M83.49,145v-2.35H97.41c1.33,0,2-.49,2-1.48v-2c0-1-.67-1.48-2-1.48H88a8.23,8.23,0,0,1-2.32-.28,4.18,4.18,0,0,1-1.56-.8,2.89,2.89,0,0,1-.87-1.21,4.33,4.33,0,0,1-.28-1.56v-.93a4.35,4.35,0,0,1,.28-1.57,3,3,0,0,1,.87-1.21,4.32,4.32,0,0,1,1.56-.79A8.2,8.2,0,0,1,88,129h13.56v2.36H88c-1.33,0-2,.49-2,1.48v1c0,1,.67,1.47,2,1.47h9.42a8.2,8.2,0,0,1,2.32.29,4.24,4.24,0,0,1,1.56.79,3,3,0,0,1,.87,1.21,4.14,4.14,0,0,1,.28,1.57v2a4.05,4.05,0,0,1-.28,1.56,3,3,0,0,1-.87,1.22,4.24,4.24,0,0,1-1.56.79,8.23,8.23,0,0,1-2.32.28Z"
          transform="translate(-3 -2.56)"
          fill="#fff"
        />
        <path
          d="M109.83,145a8.18,8.18,0,0,1-2.32-.28,4.24,4.24,0,0,1-1.56-.79,3.11,3.11,0,0,1-.88-1.22,4.25,4.25,0,0,1-.27-1.56v-8.25a4.35,4.35,0,0,1,.27-1.57,3.15,3.15,0,0,1,.88-1.21,4.24,4.24,0,0,1,1.56-.79,8.15,8.15,0,0,1,2.32-.29h10.58a8.2,8.2,0,0,1,2.32.29,4.27,4.27,0,0,1,1.55.79,3,3,0,0,1,.88,1.21,4.35,4.35,0,0,1,.27,1.57v8.25a4.25,4.25,0,0,1-.27,1.56,2.92,2.92,0,0,1-.88,1.22,4.27,4.27,0,0,1-1.55.79,8.23,8.23,0,0,1-2.32.28Zm12.58-12.12c0-1-.67-1.48-2-1.48H109.83c-1.34,0-2,.49-2,1.48v8.29c0,1,.66,1.48,2,1.48h10.58c1.33,0,2-.49,2-1.48Z"
          transform="translate(-3 -2.56)"
          fill="#fff"
        />
        <path
          d="M133.19,145a8.23,8.23,0,0,1-2.32-.28,4.24,4.24,0,0,1-1.56-.79,3,3,0,0,1-.87-1.22,4.05,4.05,0,0,1-.28-1.56v-8.25a4.14,4.14,0,0,1,.28-1.57,3,3,0,0,1,.87-1.21,4.24,4.24,0,0,1,1.56-.79,8.2,8.2,0,0,1,2.32-.29h12.9v2.36h-12.9c-1.34,0-2,.49-2,1.48v8.29c0,1,.66,1.48,2,1.48h12.9V145Z"
          transform="translate(-3 -2.56)"
          fill="#fff"
        />
        <path
          d="M148.75,145V129h3v16Z"
          transform="translate(-3 -2.56)"
          fill="#fff"
        />
        <path
          d="M173.38,145l-8.25-12.85L156.73,145h-3.36L164,129h2.21l10.57,16Z"
          transform="translate(-3 -2.56)"
          fill="#fff"
        />
        <path
          d="M177.89,145v-2l16.19-11.33H177.89V129h21.52v2L183.14,142.4h16.27V145Z"
          transform="translate(-3 -2.56)"
          fill="#fff"
        />
        <path
          d="M201.84,145V129h3v16Z"
          transform="translate(-3 -2.56)"
          fill="#fff"
        />
        <path
          d="M212.85,145a8.23,8.23,0,0,1-2.32-.28,4.24,4.24,0,0,1-1.56-.79,3.11,3.11,0,0,1-.88-1.22,4.25,4.25,0,0,1-.27-1.56v-8.25a4.35,4.35,0,0,1,.27-1.57,3.15,3.15,0,0,1,.88-1.21,4.24,4.24,0,0,1,1.56-.79,8.2,8.2,0,0,1,2.32-.29h10.58a8.2,8.2,0,0,1,2.32.29,4.27,4.27,0,0,1,1.55.79,3,3,0,0,1,.88,1.21,4.35,4.35,0,0,1,.27,1.57v8.25a4.25,4.25,0,0,1-.27,1.56,2.92,2.92,0,0,1-.88,1.22,4.27,4.27,0,0,1-1.55.79,8.23,8.23,0,0,1-2.32.28Zm12.58-12.12c0-1-.67-1.48-2-1.48H212.85c-1.34,0-2,.49-2,1.48v8.29c0,1,.66,1.48,2,1.48h10.58c1.33,0,2-.49,2-1.48Z"
          transform="translate(-3 -2.56)"
          fill="#fff"
        />
        <path
          d="M251,145l-17.08-12.42V145H231.3V129h2.61l16,11.86V129h2.64v16Z"
          transform="translate(-3 -2.56)"
          fill="#fff"
        />
        <path
          d="M255.45,145V129h16.47v2.36H258.48v4h12.76v2.36H258.48v4.9h13.44V145Z"
          transform="translate(-3 -2.56)"
          fill="#fff"
        />
        <path
          d="M305.59,145,288.5,132.56V145h-2.63V129h2.61l15.95,11.86V129h2.64v16Z"
          transform="translate(-3 -2.56)"
          fill="#fff"
        />
        <path
          d="M328.56,145l-8.26-12.85L311.91,145h-3.37l10.65-16h2.21L332,145Z"
          transform="translate(-3 -2.56)"
          fill="#fff"
        />
        <path
          d="M333.07,145v-2l16.19-11.33H333.07V129h21.51v2L338.32,142.4h16.26V145Z"
          transform="translate(-3 -2.56)"
          fill="#fff"
        />
        <path
          d="M357,145V129h3v16Z"
          transform="translate(-3 -2.56)"
          fill="#fff"
        />
        <path
          d="M368,145a8.18,8.18,0,0,1-2.32-.28,4.24,4.24,0,0,1-1.56-.79,3.11,3.11,0,0,1-.88-1.22,4.25,4.25,0,0,1-.27-1.56v-8.25a4.35,4.35,0,0,1,.27-1.57,3.15,3.15,0,0,1,.88-1.21,4.24,4.24,0,0,1,1.56-.79A8.15,8.15,0,0,1,368,129h10.58a8.12,8.12,0,0,1,2.31.29,4.24,4.24,0,0,1,1.56.79,3,3,0,0,1,.88,1.21,4.35,4.35,0,0,1,.27,1.57v8.25a4.25,4.25,0,0,1-.27,1.56,3,3,0,0,1-.88,1.22,4.24,4.24,0,0,1-1.56.79,8.15,8.15,0,0,1-2.31.28Zm12.58-12.12c0-1-.67-1.48-2-1.48H368c-1.34,0-2,.49-2,1.48v8.29c0,1,.66,1.48,2,1.48h10.58c1.33,0,2-.49,2-1.48Z"
          transform="translate(-3 -2.56)"
          fill="#fff"
        />
        <path
          d="M406.2,145l-17.09-12.42V145h-2.63V129h2.61l16,11.86V129h2.64v16Z"
          transform="translate(-3 -2.56)"
          fill="#fff"
        />
        <path
          d="M429.17,145l-8.26-12.85L412.52,145h-3.37l10.65-16H422l10.57,16Z"
          transform="translate(-3 -2.56)"
          fill="#fff"
        />
        <path
          d="M434.06,145V129h3.05v13.61h13V145Z"
          transform="translate(-3 -2.56)"
          fill="#fff"
        />
        <path
          d="M452.21,145V129h16.47v2.36H455.24v4H468v2.36H455.24v4.9h13.44V145Z"
          transform="translate(-3 -2.56)"
          fill="#fff"
        />
        <path
          d="M129,172.19V161l-8.15,11.18H118.4L110.24,161v11.16h-2.8v-16h2.82l9.51,13,9.46-13h2.6v16Z"
          transform="translate(-3 -2.56)"
          fill="#fff"
        />
        <path
          d="M153.32,172.19l-8.26-12.86-8.39,12.86H133.3l10.65-16h2.21l10.57,16Z"
          transform="translate(-3 -2.56)"
          fill="#fff"
        />
        <path
          d="M158.21,172.19v-16h16.47v2.36H161.24v4H174v2.35H161.24v4.9h13.44v2.36Z"
          transform="translate(-3 -2.56)"
          fill="#fff"
        />
        <path
          d="M177.46,172.19v-2.36h13.92c1.33,0,2-.49,2-1.47v-2c0-1-.67-1.47-2-1.47H182a8.2,8.2,0,0,1-2.32-.29,4.24,4.24,0,0,1-1.56-.79,3,3,0,0,1-.87-1.21,4.14,4.14,0,0,1-.28-1.57v-.93a4.13,4.13,0,0,1,.28-1.56,3,3,0,0,1,.87-1.21,4.25,4.25,0,0,1,1.56-.8,8.23,8.23,0,0,1,2.32-.28h13.56v2.36H182c-1.34,0-2,.49-2,1.47v1c0,1,.66,1.48,2,1.48h9.42a8.23,8.23,0,0,1,2.32.28,4.57,4.57,0,0,1,1.56.79,3.05,3.05,0,0,1,.87,1.22,4.33,4.33,0,0,1,.27,1.56v2a4.35,4.35,0,0,1-.27,1.57,3,3,0,0,1-.87,1.21,4.41,4.41,0,0,1-1.56.8,8.61,8.61,0,0,1-2.32.28Z"
          transform="translate(-3 -2.56)"
          fill="#fff"
        />
        <path
          d="M205.8,172.19v-13.6h-8.4v-2.36H217v2.36h-8.17v13.6Z"
          transform="translate(-3 -2.56)"
          fill="#fff"
        />
        <path
          d="M234.23,172.19l-3.75-7.12H222v7.12h-3v-16h13.61a8.23,8.23,0,0,1,2.32.28,4.41,4.41,0,0,1,1.56.8,3,3,0,0,1,.87,1.21,4.33,4.33,0,0,1,.27,1.56v1.14a3.74,3.74,0,0,1-.91,2.57,4.43,4.43,0,0,1-3,1.23l3.77,7.17Zm.39-12.13c0-1-.67-1.47-2-1.47H222v4.12h10.58c1.33,0,2-.49,2-1.47Z"
          transform="translate(-3 -2.56)"
          fill="#fff"
        />
        <path
          d="M240.19,172.19v-16h3v16Z"
          transform="translate(-3 -2.56)"
          fill="#fff"
        />
        <path
          d="M257.66,172.19v-16h14.67a12,12,0,0,1,2.85.29,4.5,4.5,0,0,1,1.82.89,3.2,3.2,0,0,1,1,1.47,6.74,6.74,0,0,1,.29,2.06v6.69a6.28,6.28,0,0,1-.29,2,3.13,3.13,0,0,1-1,1.41,4.6,4.6,0,0,1-1.82.86,11.41,11.41,0,0,1-2.85.3Zm17.56-11.25a4,4,0,0,0-.13-1.13,1.6,1.6,0,0,0-.47-.73,2.09,2.09,0,0,0-.89-.38,6.9,6.9,0,0,0-1.4-.11H260.69v11.24h11.64a7.71,7.71,0,0,0,1.4-.11,2.21,2.21,0,0,0,.89-.37,1.35,1.35,0,0,0,.47-.68,3.57,3.57,0,0,0,.13-1.06Z"
          transform="translate(-3 -2.56)"
          fill="#fff"
        />
        <path
          d="M281.2,172.19v-16h3v16Z"
          transform="translate(-3 -2.56)"
          fill="#fff"
        />
        <path
          d="M298.67,172.19v-16h13a9.26,9.26,0,0,1,2.26.24,4.17,4.17,0,0,1,1.53.68,2.63,2.63,0,0,1,.85,1.08,3.77,3.77,0,0,1,.27,1.44v1.48a3,3,0,0,1-.51,1.76,2.2,2.2,0,0,1-1.38.89,3.27,3.27,0,0,1,1.5.85,2.39,2.39,0,0,1,.6,1.76v2.33a3.62,3.62,0,0,1-.24,1.31,2.68,2.68,0,0,1-.82,1.1,4.54,4.54,0,0,1-1.56.76,9.36,9.36,0,0,1-2.46.28Zm14.93-12.36a1.11,1.11,0,0,0-.41-1,2.77,2.77,0,0,0-1.48-.29h-10v4.14h10a2.68,2.68,0,0,0,1.48-.29,1.1,1.1,0,0,0,.41-.95Zm.2,6.62a1.08,1.08,0,0,0-.57-1,3,3,0,0,0-1.48-.31H301.7v4.74h10.05a2.82,2.82,0,0,0,1.48-.34,1.4,1.4,0,0,0,.57-1.31Z"
          transform="translate(-3 -2.56)"
          fill="#fff"
        />
        <path
          d="M337.23,172.19,329,159.33l-8.39,12.86h-3.37l10.65-16h2.2l10.58,16Z"
          transform="translate(-3 -2.56)"
          fill="#fff"
        />
        <path
          d="M342.12,172.19v-16h3.05v13.6h13v2.36Z"
          transform="translate(-3 -2.56)"
          fill="#fff"
        />
        <path
          d="M360.27,172.19v-16h3.05v13.6h13v2.36Z"
          transform="translate(-3 -2.56)"
          fill="#fff"
        />
        <path
          d="M382.91,172.19a8.69,8.69,0,0,1-2.33-.28,4.28,4.28,0,0,1-1.55-.8,2.89,2.89,0,0,1-.88-1.21,4.35,4.35,0,0,1-.27-1.57v-8.25a4.33,4.33,0,0,1,.27-1.56,2.89,2.89,0,0,1,.88-1.21,4.28,4.28,0,0,1,1.55-.8,8.3,8.3,0,0,1,2.33-.28h10.57a8.18,8.18,0,0,1,2.32.28,4.25,4.25,0,0,1,1.56.8,3.08,3.08,0,0,1,.88,1.21,4.33,4.33,0,0,1,.27,1.56v8.25a4.35,4.35,0,0,1-.27,1.57,3.08,3.08,0,0,1-.88,1.21,4.25,4.25,0,0,1-1.56.8,8.56,8.56,0,0,1-2.32.28Zm12.57-12.13c0-1-.66-1.47-2-1.47H382.91q-2,0-2,1.47v8.3q0,1.47,2,1.47h10.57c1.34,0,2-.49,2-1.47Z"
          transform="translate(-3 -2.56)"
          fill="#fff"
        />
      </g>
    </svg>
  );
}
