import { PDFDocument, rgb } from "pdf-lib";
import fontkit from "@pdf-lib/fontkit";
import { EnchancedReceipt } from "shared/interfaces/Receipt.interface";
import { ArrayElement, Round, trimString } from "utils/utils";
import { format } from "date-fns";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import {
  calcTotalNetPrice,
  calcTotalPrice,
  calcTotalVatPrice,
  calcVatPriceQty,
} from "./generateReceiptPdf";
import { CMS_BE_URL } from "api/urls";

export async function generateReceiptsPdfs(receipts: EnchancedReceipt[]) {
  const zip = new JSZip();
  const folder = zip.folder("receipts") as JSZip;

  const assets = [];
  // fonts
  assets.push(
    fetch(`${CMS_BE_URL}/Montserrat-Regular.ttf`).then((res) =>
      res.arrayBuffer()
    )
  );
  assets.push(
    fetch(`${CMS_BE_URL}/Montserrat-Bold.ttf`).then((res) => res.arrayBuffer())
  );
  // logo
  assets.push(
    fetch(`${CMS_BE_URL}/anmbOldReceiptLogo.png`).then((res) =>
      res.arrayBuffer()
    )
  );

  const INFORMATION = [
    `Sede Legale: Via P.Ascani 44 - 41126 Modena (Mo) - Italy`,
    `Telefono +39 059 820855 Fax +39 059 820919`,
    `Partita IVA 02093050363 - Codice Fiscale 94010360363`,
    `www.anmb.org - www.anmb.net - anmb@anmb.net`,
    `Ente Professionale id categoria fondato il 14 novembre 1945`,
    `ERETTA IN ENTE MORALE CON DECRETO DEL`,
    `MINISTERO DELL'INTERO IL 19 NOVEMBRE 1998`,
    `Membro fondatore del World Dance Council (WDC)`,
    `Membro della Italian Dance Council (IDC)`,
    `Membro affiliato del Conseil International de la Danse - Unesco (CID-UNESCO)`,
  ];

  Promise.all(assets)
    .then(async (assets) => {
      for (let i = 0; i < receipts.length; ++i) {
        // PDF init
        const pdfDoc = await PDFDocument.create();
        let page = pdfDoc.addPage();
        // consts
        const startX = 30;
        const endX = page.getWidth() - 30;
        const startY = page.getHeight() - 30;
        const endY = 30;
        const logoWidth = 250;
        const logoHeight = 103;
        const bigTextSize = 14;
        const bigTextPadding = 3.5;
        const normalTextSize = 10;
        const normalTextPadding = 2.5;
        const smallTextSize = 8;
        const smallTextPadding = 2;
        const extraSmallTextSize = 7;
        const extraSmallPadding = 1.75;
        const charsPerLine = 30;

        if (!receipts[i]?.receipt_address) {
          receipts[i].receipt_address = {
            street_name: "",
            street_number: "",
            city: "",
            postal_code: "",
            province: "",
            region: "",
          };
        } else {
          if (!receipts[i].receipt_address?.street_name) {
            receipts[i].receipt_address.street_name = "";
          }
          if (!receipts[i].receipt_address?.street_number) {
            receipts[i].receipt_address.street_number = "";
          }
          if (!receipts[i].receipt_address?.city) {
            receipts[i].receipt_address.city = "";
          }
          if (!receipts[i].receipt_address?.postal_code) {
            receipts[i].receipt_address.postal_code = "";
          }
          if (!receipts[i].receipt_address?.province) {
            receipts[i].receipt_address.province = "";
          }
          if (!receipts[i].receipt_address?.region) {
            receipts[i].receipt_address.region = "";
          }
        }
        pdfDoc.registerFontkit(fontkit);
        const regularFontBytes = assets[0];
        const boldFontBytes = assets[1];
        const regularFont = await pdfDoc.embedFont(regularFontBytes);
        const boldFont = await pdfDoc.embedFont(boldFontBytes);

        // Logo
        const flagPdfBytes = assets[2];
        const logo = await pdfDoc.embedPng(flagPdfBytes);
        page.moveTo(startX, startY - logoHeight);
        page.drawImage(logo, {
          width: logoWidth,
          height: logoHeight,
        });
        page.moveTo(startX, startY);
        page.moveDown(smallTextSize + smallTextPadding);
        page.drawText(INFORMATION[0], {
          x: endX - boldFont.widthOfTextAtSize(INFORMATION[0], smallTextSize),
          size: smallTextSize,
          font: boldFont,
        });
        page.moveDown(smallTextPadding);
        page.moveDown(smallTextSize + smallTextPadding);
        page.drawText(INFORMATION[1], {
          x: endX - boldFont.widthOfTextAtSize(INFORMATION[1], smallTextSize),
          size: smallTextSize,
          font: boldFont,
        });
        page.moveDown(smallTextPadding);
        page.moveDown(smallTextSize + smallTextPadding);
        page.drawText(INFORMATION[2], {
          x: endX - boldFont.widthOfTextAtSize(INFORMATION[2], smallTextSize),
          size: smallTextSize,
          font: boldFont,
        });
        page.moveDown(smallTextPadding);
        page.moveDown(smallTextSize + smallTextPadding);
        page.drawText(INFORMATION[3], {
          x: endX - boldFont.widthOfTextAtSize(INFORMATION[3], smallTextSize),
          size: smallTextSize,
          font: boldFont,
        });
        page.moveDown(smallTextPadding);
        page.moveDown(smallTextSize + smallTextPadding + 4);
        page.moveDown(smallTextSize);
        page.drawText(INFORMATION[4], {
          x:
            endX - regularFont.widthOfTextAtSize(INFORMATION[4], smallTextSize),
          size: smallTextSize,
          font: regularFont,
        });
        page.moveDown(smallTextPadding);
        page.moveDown(smallTextSize + smallTextPadding);
        page.drawText(INFORMATION[5], {
          x:
            endX - regularFont.widthOfTextAtSize(INFORMATION[5], smallTextSize),
          size: smallTextSize,
          font: regularFont,
        });
        page.moveDown(smallTextPadding);
        page.moveDown(smallTextSize + smallTextPadding);
        page.drawText(INFORMATION[6], {
          x:
            endX - regularFont.widthOfTextAtSize(INFORMATION[6], smallTextSize),
          size: smallTextSize,
          font: regularFont,
        });
        page.moveDown(smallTextPadding);
        page.moveDown(smallTextSize + smallTextPadding);
        page.drawText(INFORMATION[7], {
          x:
            endX - regularFont.widthOfTextAtSize(INFORMATION[7], smallTextSize),
          size: smallTextSize,
          font: regularFont,
        });
        page.moveDown(smallTextPadding);
        page.moveDown(smallTextSize + smallTextPadding);
        page.drawText(INFORMATION[8], {
          x:
            endX - regularFont.widthOfTextAtSize(INFORMATION[8], smallTextSize),
          size: smallTextSize,
          font: regularFont,
        });
        page.moveDown(smallTextPadding);
        page.moveDown(smallTextSize + smallTextPadding);
        page.drawText(INFORMATION[9], {
          x:
            endX - regularFont.widthOfTextAtSize(INFORMATION[9], smallTextSize),
          size: smallTextSize,
          font: regularFont,
        });
        page.moveDown(smallTextPadding + 11);
        page.drawLine({
          start: { x: page.getX(), y: page.getY() },
          end: { x: page.getWidth() - endY, y: page.getY() },
          thickness: 1,
          color: rgb(0, 0, 0),
          opacity: 1,
        });
        page.moveDown(10);
        page.moveDown(normalTextSize + normalTextPadding);
        page.drawText(`Ricevuta numero ${receipts[i].progressive_id}`, {
          size: normalTextSize,
          font: regularFont,
        });
        page.drawText(
          `${receipts[i].receipt_address.region} ${format(
            new Date(receipts[i].created_at),
            "dd/MM/yyyy"
          )}`,
          {
            x:
              endX -
              regularFont.widthOfTextAtSize(
                `${receipts[i].receipt_address.region} ${format(
                  new Date(receipts[i].created_at),
                  "dd/MM/yyyy"
                )}`,
                normalTextSize
              ),
            size: normalTextSize,
            font: regularFont,
          }
        );
        page.drawText(
          format(new Date(receipts[i].receipt_date), "dd/MM/yyyy"),
          {
            x:
              endX -
              regularFont.widthOfTextAtSize(
                format(new Date(receipts[i].receipt_date), "dd/MM/yyyy"),
                normalTextSize
              ),
            size: normalTextSize,
            font: regularFont,
          }
        );
        page.moveDown(normalTextPadding + 11);
        page.drawLine({
          start: { x: page.getX(), y: page.getY() },
          end: { x: page.getWidth() - endX, y: page.getY() },
          thickness: 1,
          color: rgb(0.02, 0.247, 0.475),
          opacity: 1,
        });
        page.moveDown(normalTextPadding + 11);
        page.moveDown(normalTextSize + normalTextPadding);
        page.drawText(
          "L'Associazione Nazionale Maestri di Ballo (A.N.M.B.) riceve da:",
          {
            size: normalTextSize,
            font: regularFont,
          }
        );
        page.moveDown(normalTextPadding);
        page.moveDown(normalTextSize + normalTextPadding);
        page.drawText(`${receipts[i].name} ${receipts[i].surname}`, {
          size: normalTextSize,
          font: regularFont,
        });
        page.moveDown(normalTextPadding);
        page.moveDown(normalTextSize + normalTextPadding);
        page.drawText(
          `${receipts[i].receipt_address.street_name} ${receipts[i].receipt_address.street_number}`,
          {
            size: normalTextSize,
            font: regularFont,
          }
        );
        page.moveDown(normalTextPadding);
        page.moveDown(normalTextSize + normalTextPadding);
        page.drawText(
          `${receipts[i].receipt_address.postal_code} ${receipts[i].receipt_address.city} (${receipts[i].receipt_address.province})`,
          {
            size: normalTextSize,
            font: regularFont,
          }
        );
        page.moveDown(normalTextPadding);
        page.moveDown(normalTextSize + normalTextPadding);
        page.drawText(
          `n°.tess: ${receipts[i].card_number ? receipts[i].card_number : "-"}`,
          {
            size: normalTextSize,
            font: regularFont,
          }
        );
        page.moveDown(normalTextPadding);
        page.moveDown(normalTextSize + normalTextPadding);
        page.drawText(`luogo di ricezione: ${receipts[i].receipt_place}`, {
          size: normalTextSize,
          font: regularFont,
        });
        page.moveDown(normalTextPadding);
        page.moveDown(11);
        page.drawLine({
          start: { x: page.getX(), y: page.getY() },
          end: { x: page.getWidth() - endY, y: page.getY() },
          thickness: 1,
          color: rgb(0, 0, 0),
          opacity: 1,
        });
        page.moveDown(10);
        {
          const bigOffset = (page.getWidth() - 2 * startX) / 3;
          const offset = (page.getWidth() - 2 * startX - bigOffset - 40) / 3;
          const grid = [
            startX,
            bigOffset + 40,
            bigOffset + 80,
            bigOffset + 80 + offset,
            bigOffset + 50 + 2 * offset,
          ];
          page.moveDown(normalTextSize + normalTextPadding);
          page.drawText("Descrizione", {
            x: grid[0],
            size: normalTextSize,
            font: boldFont,
          });
          page.drawText("Qta", {
            x: grid[1],
            size: normalTextSize,
            font: boldFont,
          });
          page.drawText("Prezzo", {
            x: grid[2],
            size: normalTextSize,
            font: boldFont,
          });
          page.drawText("IVA %", {
            x: grid[3],
            size: normalTextSize,
            font: boldFont,
          });
          page.drawText("Totale", {
            x: grid[4],
            size: normalTextSize,
            font: boldFont,
          });
          page.moveDown(normalTextPadding);
          page.moveDown(normalTextSize + normalTextPadding);
          page.drawText("Unitario", {
            x: grid[2],
            size: normalTextSize,
            font: boldFont,
          });
          page.moveDown(normalTextPadding);
          for (let j = 0; j < receipts[i].details.length; ++j) {
            const titleRows = Math.ceil(
              receipts[i].details[j].title.length / charsPerLine
            );
            const descRows = Math.ceil(
              receipts[i].details[j].description.length / charsPerLine
            );
            if (
              page.getY() - 2 * (normalTextSize + 2 * normalTextPadding) <
              endY
            ) {
              page = pdfDoc.addPage();
              page.moveTo(startX, startY);
            }

            for (let row = 0; row < titleRows; row++) {
              if (
                page.getY() - 2 * (normalTextSize + 2 * normalTextPadding) <
                endY
              ) {
                page = pdfDoc.addPage();
                page.moveTo(startX, startY);
              }
              page.moveDown(normalTextSize + normalTextPadding);
              page.drawText(
                `${receipts[i]?.details[j]?.title.slice(
                  charsPerLine * row,
                  charsPerLine * row + charsPerLine
                )}`,
                {
                  x: grid[0],
                  size: normalTextSize,
                  font: regularFont,
                }
              );
            }
            page.moveUp((normalTextSize + normalTextPadding) * (titleRows - 1));

            page.drawText(`${receipts[i].details[j].quantity}`, {
              x: grid[1],
              size: normalTextSize,
              font: regularFont,
            });
            page.drawText(
              String(receipts[i].details[j].unit_price.toFixed(2)) + "€",
              {
                size: normalTextSize,
                font: regularFont,
                x: grid[2],
              }
            );
            page.drawText(`${receipts[i].details[j].vat_percentage * 100}%`, {
              x: grid[3],
              size: normalTextSize,
              font: regularFont,
            });
            page.drawText(`${receipts[i].details[j].total.toFixed(2)}€`, {
              x: grid[4],
              size: normalTextSize,
              font: regularFont,
            });

            page.moveDown(
              (normalTextPadding + normalTextSize) * (titleRows - 1) +
                normalTextPadding * 2
            );

            for (let row = 0; row < descRows; row++) {
              if (
                page.getY() - 2 * (normalTextSize + 2 * normalTextPadding) <
                endY
              ) {
                page = pdfDoc.addPage();
                page.moveTo(startX, startY);
              }
              page.moveDown(normalTextSize + normalTextPadding);
              page.drawText(
                `${receipts[i].details[j].description.slice(
                  charsPerLine * row,
                  charsPerLine * row + charsPerLine
                )}`,
                {
                  x: grid[0],
                  size: normalTextSize,
                  font: regularFont,
                }
              );
            }
            page.moveUp((normalTextSize + normalTextPadding) * (descRows - 1));

            page.drawText(
              `Di cui Iva: ${calcVatPriceQty(receipts[i].details[j])}€`,
              {
                x: grid[4],
                size: normalTextSize,
                font: regularFont,
              }
            );
            page.moveDown(
              (normalTextSize + normalTextPadding) * (descRows - 1) +
                normalTextPadding * 3
            );
          }
          page.moveDown(11);
          page.drawLine({
            start: { x: page.getX(), y: page.getY() },
            end: { x: page.getWidth() - endY, y: page.getY() },
            thickness: 1,
            color: rgb(0, 0, 0),
            opacity: 1,
          });
          page.moveDown(10);
          page.moveDown(normalTextSize + normalTextPadding);
          const totalNetPriceLabel = `Parziale: ${calcTotalNetPrice(
            receipts[i].details
          )}€`;
          page.drawText(totalNetPriceLabel, {
            x:
              endX -
              regularFont.widthOfTextAtSize(totalNetPriceLabel, normalTextSize),
            size: normalTextSize,
            font: regularFont,
          });
          page.moveDown(normalTextPadding);
          page.moveDown(normalTextPadding + normalTextSize);
          const totalVatPriceLabel = `Iva: ${calcTotalVatPrice(
            receipts[i].details
          )}€`;
          page.drawText(totalVatPriceLabel, {
            x:
              endX -
              regularFont.widthOfTextAtSize(totalVatPriceLabel, normalTextSize),
            size: normalTextSize,
            font: regularFont,
          });
          page.moveDown(normalTextPadding);
          page.moveDown(normalTextPadding + normalTextSize);
          const totalPriceLabel = `Totale complessivo: ${calcTotalPrice(
            receipts[i].details
          )}€`;
          page.drawText(totalPriceLabel, {
            size: normalTextSize,
            font: regularFont,
            x:
              endX -
              regularFont.widthOfTextAtSize(totalPriceLabel, normalTextSize),
          });
          page.moveDown(normalTextPadding);
          page.moveDown(11);
          page.drawLine({
            start: { x: page.getX(), y: page.getY() },
            end: { x: page.getWidth() - endY, y: page.getY() },
            thickness: 1,
            color: rgb(0, 0, 0),
            opacity: 1,
          });
          page.moveDown(10);
        }
        if (receipts[i].printable_notes) {
          if (
            page.getY() - 2 * (normalTextSize + 2 * normalTextPadding) <
            endY
          ) {
            page = pdfDoc.addPage();
            page.moveTo(startX, startY);
          }
          page.moveDown(normalTextSize + normalTextPadding);
          page.drawText("Note Ricevuta", {
            size: normalTextSize,
            font: regularFont,
          });
          page.moveDown(normalTextPadding);
          page.moveDown(normalTextSize + normalTextPadding);
          page.drawText(`${receipts[i].printable_notes.substring(0, 100)}`, {
            size: normalTextSize,
            font: regularFont,
          });
          page.moveDown(normalTextPadding);
          page.moveDown(normalTextSize + normalTextPadding);
          page.drawText(`${receipts[i].printable_notes.substring(101, 200)}`, {
            size: normalTextSize,
            font: regularFont,
          });
          page.moveDown(normalTextPadding);
        }
        const vatLegislation =
          "Documento non soggetto ad I.V.A. ai sensi dell'Art 4, 4° comma D.P.R. 633/72";
        if (receipts[i].exclude_vat) {
          if (page.getY() + 2 * normalTextPadding + normalTextSize < endY) {
            page = pdfDoc.addPage();
            page.moveTo(startX, startY);
          }
          page.moveDown(normalTextSize);
          const vatLegislationWidth = regularFont.widthOfTextAtSize(
            vatLegislation,
            normalTextSize
          );
          page.drawText(vatLegislation, {
            x: (page.getWidth() - vatLegislationWidth) / 2,
            y: endY,
            size: normalTextSize,
            font: regularFont,
          });
        }
        const pageCount = pdfDoc.getPageCount();
        for (let p = 0; p < pageCount; ++p) {
          const tempPage = pdfDoc.getPage(p);
          const pagination = p + 1 + "/" + pageCount;
          tempPage.drawText(pagination, {
            x:
              page.getWidth() -
              endY -
              regularFont.widthOfTextAtSize(pagination, normalTextSize),
            y: endY,
            size: normalTextSize,
            font: regularFont,
          });
        }
        folder.file(
          `(${i + 1})-${receipts[i].progressive_id}-${receipts[i].name}-${
            receipts[i].surname
          }-${format(new Date(receipts[i].updated_at), "dd-MM-yyyy")}.pdf`,
          pdfDoc.save()
        );
      }
    })
    .then(
      () =>
        new Promise((resolve, reject) => {
          if (!folder) {
            reject(new Error(undefined));
          } else {
            folder
              .generateAsync({ type: "blob" })
              .then((blob) => {
                saveAs(blob, "receipts");
                resolve(undefined);
              })
              .catch((err) => reject(err));
          }
        })
    );
}
