import { ExclamationIcon, PencilIcon, XIcon } from "@heroicons/react/outline";
import { ifElse, when, equals, clone } from "ramda";
import React, { useState, useEffect, useMemo, useRef } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import DisplayInputError from "shared/components/UI/DisplayInputError";
import Modal from "shared/components/UI/Modal";
import ScreenModal from "shared/components/UI/ScreenModal";
import Message from "shared/components/UI/Message";
import {
  CouncilRank,
  CreateCouncilRank,
  UpdateCouncilRank,
} from "shared/interfaces/Rank.interface";
import { RolePermissionCrudAction } from "shared/interfaces/RolePermission.interface";
import { selectLoggedInUser } from "store/authStore/authReducer";
import {
  createCouncilRankThunk,
  deleteCouncilRankThunk,
  getCouncilRanksThunk,
  selectCouncilRanks,
  updateCouncilRankThunk,
} from "store/councilRanks/councilRanksReducer";
import { useAppDispatch } from "store/storeHooks";
import { selectTranslationLanguage } from "store/translationStore/translationReducer";

export default function CouncilRanks() {
  const { t } = useTranslation("common");
  const lang = selectTranslationLanguage();
  const loggedInUser = selectLoggedInUser();
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getCouncilRanksThunk({}));
  }, []);
  const reduxCouncilRanks = selectCouncilRanks();
  const councilRanks = clone(reduxCouncilRanks.data.councilRanks);
  const form = useForm<CreateCouncilRank>({
    mode: "onChange",
    defaultValues: {
      council_rank_eng: "",
      council_rank_it: "",
      priority: 1,
    },
  });
  const onAdd = form.handleSubmit((data) =>
    dispatch(
      createCouncilRankThunk({
        data: {
          council_rank_eng: data.council_rank_eng,
          council_rank_it: data.council_rank_it,
          priority: data.priority,
        },
      })
    ).then((res) => {
      if (equals(res.meta.requestStatus, "fulfilled")) {
        toast.success(
          Message({
            action: "create",
            entity: t("councilRanks.councilRank"),
            lang,
          })
        );
        dispatch(getCouncilRanksThunk({})).then((res) =>
          when(
            () => equals(res.meta.requestStatus, "fulfilled"),
            () =>
              toast.error({
                action: "create",
                entity: t("councilRanks.councilRank"),
                error: t("genericError"),
                lang,
              })
          )
        );
      } else {
        toast.error(
          Message({
            action: "create",
            entity: t("councilRanks.councilRank"),
            error: t("genericError"),
            lang,
          })
        );
      }
    })
  );
  const canAccess = useMemo(
    () => (crud: RolePermissionCrudAction) => {
      if (loggedInUser.permissions["*"]) return true;
      const isEnalbed = loggedInUser.permissions["ranks"]["ENABLED"];
      if (isEnalbed) {
        const perm = loggedInUser.permissions["ranks"][crud];
        if (perm === "OWN" || perm === "ANY") return true;
      }
      return false;
    },
    []
  );
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [rankToDelete, setRankToDelete] = useState("");
  const handleDeleteRank = useMemo(
    () => (rank: string) => {
      dispatch(deleteCouncilRankThunk({ rankId: rank })).then((res: any) => {
        if (res.meta.requestStatus === "rejected") {
          toast.error(
            Message({
              action: "delete",
              entity: t("councilRanks.councilRank"),
              error: t("councilRanks.deleteError"),
              lang,
              gender: "male",
            })
          );
        } else {
          toast.success(
            Message({
              action: "delete",
              entity: t("councilRanks.councilRank"),
              lang,
              gender: "male",
            })
          );
          dispatch(getCouncilRanksThunk({})).then((res) =>
            when(
              () => equals(res.meta.requestStatus, "fulfilled"),
              () =>
                toast.error({
                  action: "create",
                  entity: t("councilRanks.councilRank"),
                  error: t("genericError"),
                  lang,
                  gender: "male",
                })
            )
          );
        }
      });
      setOpenDeleteModal(false);
    },
    []
  );
  const updateForm = useForm<UpdateCouncilRank>({
    mode: "onChange",
    defaultValues: {
      council_rank_eng: "",
      council_rank_it: "",
      priority: undefined,
    },
  });
  const [openEditModal, setOpenEditModal] = useState(false);
  const rankRef = useRef<CouncilRank | null>(null);
  const onUpdate = updateForm.handleSubmit((data) =>
    dispatch(
      updateCouncilRankThunk({
        rankId: String(rankRef.current?.id),
        data: {
          council_rank_eng: data.council_rank_eng,
          council_rank_it: data.council_rank_it,
          priority: data.priority,
        },
      })
    )
      .then((res) =>
        ifElse(
          () => equals(res.meta.requestStatus, "rejected"),
          () =>
            toast.error(
              Message({
                action: "update",
                entity: t("councilRanks.councilRank"),
                error: t("genericError"),
                lang,
                gender: "male",
              })
            ),
          () => (
            toast.success(
              Message({
                action: "update",
                entity: t("councilRanks.councilRank"),
                lang,
                gender: "male",
              })
            ),
            dispatch(getCouncilRanksThunk({})).then((res) =>
              when(
                () => equals(res.meta.requestStatus, "rejected"),
                () =>
                  toast.error(
                    Message({
                      action: "read",
                      entity: t("councilRanks.councilRank"),
                      error: t("genericError"),
                      lang,
                      gender: "male",
                    })
                  )
              )(null)
            )
          )
        )()
      )
      .then(() => {
        rankRef.current = null;
        setOpenEditModal(false);
      })
  );

  return (
    <div className="flex flex-col gap-5">
      <form className="flex gap-2" onSubmit={onAdd}>
        <label className="flex flex-col gap-2">
          <span className="text-lg font-semibold">
            {t("councilRanks.priority")}
          </span>
          <input
            type="text"
            placeholder={`${t("councilRanks.rankPriority")}`}
            {...form.register("priority", {
              required: `${t("councilRanks.priorityRequired")}`,
              validate: {
                isNumber(e) {
                  if (isNaN(Number(e)))
                    return t("councilRanks.priorityError") as string;
                  return true;
                },
              },
            })}
            className="text-gray-600 bg-white focus:border-global-input-focus border-gray-600 rounded-sm focus:ring-0"
          />
          <DisplayInputError
            message={form.formState.errors.priority?.message}
          />
        </label>
        <label className="flex flex-col gap-2">
          <span className="text-lg font-semibold">
            {t("councilRanks.rank")} ({t("languages.english")})
          </span>
          <input
            type="text"
            {...form.register("council_rank_eng", {
              required: `${t("councilRanks.englishCouncilRankRequired")}`,
            })}
            placeholder={`${t("councilRanks.councilRank")}`}
            className="text-gray-600 bg-white focus:border-global-input-focus border-gray-600 rounded-sm focus:ring-0"
            autoComplete="off"
          />
          <DisplayInputError
            message={form.formState.errors.council_rank_eng?.message}
          />
        </label>
        <label className="flex flex-col gap-2">
          <span className="text-lg font-semibold">
            {t("councilRanks.rank")} ({t("languages.italian")})
          </span>
          <input
            type="text"
            {...form.register("council_rank_it", {
              required: `${t("councilRanks.italianCouncilRankRequired")}`,
            })}
            placeholder={`${t("councilRanks.councilRank")}`}
            className="text-gray-600 bg-white focus:border-global-input-focus border-gray-600 rounded-sm focus:ring-0"
            autoComplete="off"
          />
          <DisplayInputError
            message={form.formState.errors.council_rank_it?.message}
          />
        </label>
        <div className="flex flex-col gap-2 justify-end">
          <button
            type="submit"
            className={
              "px-4 py-2 border border-primary bg-primary text-white rounded-sm"
            }
          >
            {t("councilRanks.add")}
          </button>
          {Object.keys(form.formState.errors).length > 0 ? (
            <div className="h-4"></div>
          ) : null}
        </div>
      </form>
      <div className="flex flex-wrap gap-2">
        {councilRanks?.map((councilRank) => (
          <div key={councilRank.id} className="relative flex">
            <div className="flex gap-2 items-center p-2 w-full h-full max-h-full text-gray-600 bg-gray-100 border border-gray-600 rounded-sm">
              <span className="">{councilRank.priority}</span>
              <span className="flex w-full bg-gray-100 border-0 focus:ring-0">
                {lang === "en"
                  ? councilRank.council_rank_eng
                  : councilRank.council_rank_it}
              </span>
              {canAccess("UPDATE") ? (
                <button
                  type="button"
                  onClick={() => {
                    rankRef.current = councilRank;
                    setOpenEditModal(true);
                  }}
                  className="flex items-center h-full text-black"
                >
                  <PencilIcon className="w-4 h-4" />
                </button>
              ) : null}
              {canAccess("DELETE") ? (
                <button
                  type="button"
                  onClick={() => {
                    setRankToDelete(String(councilRank.id));
                    setOpenDeleteModal(true);
                  }}
                  className="flex items-center h-full text-black"
                >
                  <XIcon className="w-4 h-4" />
                </button>
              ) : null}
            </div>
          </div>
        ))}
      </div>
      <ScreenModal isOpen={openEditModal} setIsOpen={setOpenEditModal}>
        <div className="flex flex-col gap-10 p-10 bg-white rounded-md">
          <div className="flex justify-between">
            <h1 className="text-2xl font-bold">{t("councilRanks.update")}</h1>
            <button
              type="button"
              className="text-white bg-red-500 rounded-md"
              onClick={() => {
                rankRef.current = null;
                updateForm.clearErrors();
                setOpenEditModal(false);
              }}
            >
              <XIcon className="w-8 h-8" />
            </button>
          </div>
          <div className="flex flex-col gap-5">
            <label className="flex flex-col gap-2">
              <span className="text-lg font-semibold">
                {t("councilRanks.priority")}
              </span>
              <input
                type="text"
                {...updateForm.register("priority", {
                  required: `${t("councilRanks.priorityRequired")}`,
                  validate: {
                    isNumber(e) {
                      if (isNaN(Number(e)))
                        return t("councilRanks.priorityError") as string;
                      return true;
                    },
                  },
                })}
                placeholder={String(rankRef.current?.priority)}
                className="text-gray-600 bg-white focus:border-global-input-focus border-gray-600 rounded-sm focus:ring-0"
              />
              <DisplayInputError
                message={updateForm.formState.errors.priority?.message}
              />
            </label>
            <label className="flex flex-col gap-2">
              <span className="text-lg font-semibold">
                {t("councilRanks.rank")} ({t("languages.english")})
              </span>
              <input
                type="text"
                {...updateForm.register("council_rank_eng", {
                  required: `${t("councilRanks.englishCouncilRankRequired")}`,
                })}
                placeholder={rankRef.current?.council_rank_eng}
                className="text-gray-600 bg-white focus:border-global-input-focus border-gray-600 rounded-sm focus:ring-0"
                autoComplete="off"
              />
              <DisplayInputError
                message={updateForm.formState.errors.council_rank_eng?.message}
              />
            </label>
            <label className="flex flex-col gap-2">
              <span className="text-lg font-semibold">
                {t("councilRanks.rank")} ({t("languages.italian")})
              </span>
              <input
                type="text"
                {...updateForm.register("council_rank_it", {
                  required: `${t("councilRanks.italianCouncilRankRequired")}`,
                })}
                placeholder={rankRef.current?.council_rank_it}
                className="text-gray-600 bg-white focus:border-global-input-focus border-gray-600 rounded-sm focus:ring-0"
                autoComplete="off"
              />
              <DisplayInputError
                message={updateForm.formState.errors.council_rank_it?.message}
              />
            </label>
            <div className="flex justify-end">
              <button
                type="button"
                onClick={onUpdate}
                className={
                  "px-4 py-2 border border-primary bg-primary text-white rounded-sm"
                }
              >
                {t("councilRanks.done")}
              </button>
            </div>
          </div>
        </div>
      </ScreenModal>
      <Modal
        openStatus={openDeleteModal}
        setOpen={setOpenDeleteModal}
        className=""
        icon={
          <ExclamationIcon
            className="w-6 h-6 text-red-600 bg-transparent"
            aria-hidden="true"
          />
        }
        header={
          <p>
            {t("deleteModal.delete")} {t("councilRanks.councilRank")}
          </p>
        }
        title={
          <p>
            {t("deleteModal.sure")} {t("councilRanks.councilRank")}?{" "}
            {t("deleteModal.undone")}
          </p>
        }
        footer={
          <div className="mt-5 sm:flex sm:flex-row-reverse sm:mt-4">
            <button
              type="button"
              className="inline-flex justify-center px-4 py-1 w-full text-white text-base font-medium bg-red-600 hover:bg-red-700 border border-transparent focus:outline-none shadow-sm focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={async () => await handleDeleteRank(rankToDelete)}
            >
              {t("deleteModal.delete")}
            </button>
            <button
              type="button"
              className="inline-flex justify-center mt-3 px-4 py-1 w-full hover:text-gray-500 text-gray-700 text-base font-medium bg-white border border-gray-300 focus:outline-none shadow-sm focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
              onClick={() => setOpenDeleteModal(false)}
            >
              {t("deleteModal.cancel")}
            </button>
          </div>
        }
      />
    </div>
  );
}
