import React, {
  useEffect,
  useState,
  useMemo,
  SetStateAction,
  Dispatch,
} from "react";
import { useAppDispatch, useAppSelector } from "store/storeHooks";
import {
  Column,
  useFlexLayout,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import {
  SearchIcon,
  PlusIcon,
  UserCircleIcon,
  DotsVerticalIcon,
  PencilIcon,
  LockOpenIcon,
  TrashIcon,
  UserRemoveIcon,
  FilterIcon,
  RefreshIcon,
  ExclamationIcon,
} from "@heroicons/react/outline";
import { Link } from "react-router-dom";
import { useDebounce } from "shared/hooks/useDebounce";
import Pagination from "shared/components/Table/Pagination";
import Table from "shared/components/Table/Table";
import {
  deleteUserThunk,
  getUsersThunk,
  resendEmailCreationThunk,
  resendEmailRegisterThunk,
  resetPasswordThunk,
  selectMembershipStatuses,
  selectStatuses,
  selectUsers,
  usersBulkUpdateThunk,
} from "store/usersStore/usersReducer";
import { User, UserLowercase } from "shared/interfaces/User.interface";
import Modal from "shared/components/UI/Modal";
import { useTranslation } from "react-i18next";
import { selectLoggedInUser } from "store/authStore/authReducer";
import { getSubscriptionEndDate, getUsersColumns } from "api/Users/users.api";
import Spinner from "shared/components/UI/Spinner";
import Overlay from "shared/components/UI/Overlay";
import { format } from "date-fns";
import TablePopup, { PortalType } from "shared/components/UI/TablePopup";
import { Controller, useForm } from "react-hook-form";
import { UsersFilters } from "./types";
import { PermissionDomain } from "shared/interfaces/Permission.interface";
import { RolePermissionCrudAction } from "shared/interfaces/RolePermission.interface";
import { selectLookups } from "store/lookups/lookupsReducer";
import Select from "react-select";
import {
  calculateDeletionDate,
  capitalizeFirst,
  dateToQuery,
  getInputStyles,
  getReactSelectStyles,
  membershipStatusToQuery,
  ranksToQuery,
  regionToQuery,
  roleToQuery,
  statusToQuery,
} from "utils/utils";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "shared/styles/datePicker.css";
import {
  getUserRanksThunk,
  selectUserRanks,
} from "store/userRanksStore/userRanksReducer";
import { toast } from "react-toastify";
import Message from "shared/components/UI/Message";
import useUser from "shared/hooks/useUser";
import { CMS_BE_URL } from "api/urls";
import axios from "axios";
import ExportUsers from "shared/components/Users/ExportUsers";
import { setTimeout } from "timers";

enum MassUserActionType {
  ban,
  unban,
  resetPass,
  delete,
  permaDelete,
  addSubs,
}

const UsersPage: () => JSX.Element = () => {
  const {
    register,
    getValues,
    formState: { errors, isDirty },
    control,
    watch,
    setValue,
  } = useForm<UsersFilters>({
    defaultValues: {
      location: undefined,
      role: undefined,
      rank: undefined,
      status: undefined,
      memberStatus: undefined,
      to: undefined,
      pageSize: 10,
      pageIndex: 0,
      searchString: "",
    },
  });
  const { t } = useTranslation("common");

  const [loading, setLoading] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const debouncedSearchText = useDebounce(watch("searchString"), 300);
  const users = selectUsers();
  const dispatch = useAppDispatch();
  const loggedInUser = selectLoggedInUser();

  const [userDeleteOpen, setUserDeleteOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState("");

  const [activationOpen, setActivationOpen] = useState(false);
  const [userToActivate, setUserToActivate] = useState<any>({});

  const [resetOpen, setResetOpen] = useState(false);
  const [emailToReset, setEmailToReset] = useState("");

  const [cancelDeletionOpen, setCancelDeletionOpen] = useState(false);
  const [userToCancelDeletion, setUserToCancelDeletion] = useState("");
  const [isHardDelete, setIsHardDelete] = useState(false);

  const isSuperAdmin = loggedInUser && loggedInUser?.user?.ROLE === 4;

  const { updateUserStatus } = useUser();
  const lang = useAppSelector((state) => state.translation.language);
  const [rolesOptions, setRolesOptions] = useState<any>([]);
  const [regionsOptions, setRegionsOptions] = useState<any>([]);
  const [ranksOptions, setRankOptions] = useState<any>([]);
  const [statusesOptions, setStatusesOptions] = useState<any>([]);
  const [membershipStatusesOptions, setMembershipStatusesOptions] =
    useState<any>([]);

  const [portal, setPortal] = useState<PortalType<any>>({
    open: false,
    top: 0,
    left: 0,
    entity: null,
  });

  function canAccess(
    domain: PermissionDomain,
    action: RolePermissionCrudAction,
    user: User
  ): boolean {
    if (loggedInUser.permissions["*"]) {
      return true;
    } else {
      switch (loggedInUser.permissions[domain][action]) {
        case "NONE": {
          return false;
        }
        case "OWN": {
          if (
            action === "DELETE" &&
            user.PK_USER === loggedInUser.user?.PK_USER
          )
            return false;
          return user.PK_USER === loggedInUser.user?.PK_USER;
        }
        case "ANY": {
          if (
            action === "DELETE" &&
            user.PK_USER === loggedInUser.user?.PK_USER
          )
            return false;
          return true;
        }
        default: {
          return false;
        }
      }
    }
  }

  // Checkbox States For Multiple Users Actions
  const [allSelected, setAllSelected] = useState(false);
  const [excludeUsers, setExcludeUsers] = useState<string[]>([]);
  const [includeUsers, setIncludeUsers] = useState<string[]>([]);

  const columns: Array<Column<Record<string, any>>> = useMemo(
    () =>
      getUsersColumns(
        {
          allSelected,
          setAllSelected,
          setIncludeUsers,
          setExcludeUsers,
        },
        lang
      ),
    [lang, allSelected, setAllSelected, setIncludeUsers, setExcludeUsers]
  );

  const data: Array<any> = useMemo(() => {
    return users.data.users.map((user: UserLowercase, i) => {
      return {
        checkbox: (
          <div className="flex items-center h-full">
            <input
              type="checkbox"
              checked={(function () {
                if (allSelected) {
                  const index = excludeUsers.indexOf(user.pk_user);
                  if (index === -1) {
                    return true;
                  } else {
                    return false;
                  }
                } else {
                  const index = includeUsers.indexOf(user.pk_user);
                  if (index === -1) {
                    return false;
                  } else {
                    return true;
                  }
                }
              })()}
              onClick={() => {
                if (allSelected) {
                  const index = excludeUsers.indexOf(user.pk_user);
                  if (index !== -1) {
                    setExcludeUsers((excludedUsers) => {
                      const temp = [...excludedUsers];
                      temp.splice(index, 1);
                      return temp;
                    });
                  } else {
                    setExcludeUsers((excludedUsers) => [
                      ...excludedUsers,
                      user.pk_user,
                    ]);
                  }
                } else {
                  const index = includeUsers.indexOf(user.pk_user);
                  if (index !== -1) {
                    setIncludeUsers((includedUsers) => {
                      const temp = [...includedUsers];
                      temp.splice(index, 1);
                      return temp;
                    });
                  } else {
                    setIncludeUsers((includedUsers) => [
                      ...includedUsers,
                      user.pk_user,
                    ]);
                  }
                }
              }}
              className="text-primary"
            />
          </div>
        ),
        col1: (
          <div className="flex items-center">
            <div className="w-6 2xl:w-8 h-6 2xl:h-8 rounded-full">
              {user.profile_pic_url ? (
                <img
                  src={user.profile_pic_url}
                  className="w-6 2xl:w-8 h-6 2xl:h-8 rounded-full object-cover"
                />
              ) : (
                <UserCircleIcon className="w-6 2xl:w-8 2xl:h-8 text-users-icon rounded-full" />
              )}
            </div>
            <div
              className="flex flex-col pl-4 text-xs"
              style={{ width: "calc(100% - 3rem)" }}
            >
              <span className="truncate">
                {user.name} {user.surname}
              </span>
              {user.email && <span className="truncate">{user.email}</span>}
            </div>
          </div>
        ),
        col2: (
          <div className="flex items-center h-full">
            {user.address_details?.map(
              (det: any) => det.address_type === "HOME" && det.fk_region
            )}
          </div>
        ),
        col3: (
          <div className="flex items-center h-full">
            <span className="truncate">
              {lang === "en" ? user.rank.rank_eng : user.rank.rank_it}
            </span>
          </div>
        ),
        col4: (
          <div className="flex items-center h-full">
            <span
              className={`truncate rounded-full px-2 py-1 ${
                user.status?.toUpperCase() === "ACTIVE"
                  ? "text-users-active bg-users-active-bg"
                  : "text-users-inactive bg-users-inactive-bg"
              }`}
            >
              {user.status?.toUpperCase() === "DELETED"
                ? `Deletion at ${calculateDeletionDate(
                    new Date(user.deleted_at)
                  )}`
                : capitalizeFirst(user.status)}
            </span>
          </div>
        ),
        col5: (
          <div className="flex items-center h-full">
            {user.member_status && (
              <span
                className={`truncate rounded-full px-2 py-1 ${
                  user.member_status === "ACTIVE" && "text-blue-800 bg-blue-100"
                } ${
                  user.member_status === "NORMAL_USER" &&
                  "text-gray-800 bg-gray-100"
                }  ${
                  user.member_status === "DIMISSIONARY" &&
                  "text-yellow-800 bg-yellow-100"
                } ${
                  user.member_status === "DIMISSIONARY" &&
                  "text-yellow-800 bg-yellow-100"
                }  ${
                  user.member_status === "EXPIRED" &&
                  "text-pink-800 bg-pink-100"
                }
                }`}
              >
                {capitalizeFirst(user.member_status)?.replace("_", " ")}
              </span>
            )}
          </div>
        ),
        col6: (
          <div className="flex items-center h-full">
            {user.membership === null || Array.isArray(user.membership) ? (
              <span className="truncate">No active subscription</span>
            ) : (
              <span className="truncate">
                {format(
                  new Date(user.membership.end_date.split(" ")[0]),
                  "dd MMMM yyyy"
                )}
              </span>
            )}
          </div>
        ),
        col7: (
          <div className="flex items-center h-full">
            <span className="truncate">{user.role.role_name}</span>
          </div>
        ),
        col8: (
          <div className="flex items-center h-full">
            <span className="truncate">
              {user.last_login_fe
                ? format(
                    new Date(user.last_login_fe.replace(" ", "T")),
                    "dd MMMM yyyy"
                  )
                : "Unkwown"}
            </span>
          </div>
        ),
        col9: (
          <div className="relative flex items-center justify-center h-full">
            <button
              id={`portal-button-${i}`}
              type="button"
              onClick={(e) => {
                return setPortal((state: any) => {
                  const thisButton = document.getElementById(
                    `portal-button-${i}`
                  );
                  const position = thisButton?.getBoundingClientRect();
                  return {
                    open: !state.open,
                    top: Number(position?.bottom),
                    left: Number(
                      window.innerWidth - Number(position?.x) <= 200
                        ? Number(position?.left) - 200
                        : Number(position?.left)
                    ),
                    entity: user,
                  };
                });
              }}
            >
              <DotsVerticalIcon className="w-5 h-5" />
            </button>
          </div>
        ),
      };
    });
  }, [
    users.data.users,
    lang,
    allSelected,
    setAllSelected,
    includeUsers,
    setIncludeUsers,
    excludeUsers,
    setExcludeUsers,
  ]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: getValues("pageSize") },
      pageCount: Math.ceil(users.data.total / getValues("pageSize")),
      manualSortBy: true,
      manualPagination: true,
    },
    useSortBy,
    usePagination,
    useFlexLayout
  );

  const {
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, sortBy },
  } = tableInstance;

  function constructGetUsersThunkAction(index: number) {
    return {
      role: roleToQuery(getValues("role")),
      location: regionToQuery(getValues("location")),
      rank: ranksToQuery(getValues("rank")),
      status: statusToQuery(getValues("status")),
      memberStatus: membershipStatusToQuery(getValues("memberStatus")),
      subEndDateFrom: dateToQuery(getValues("to")),
      pageIndex: index,
      pageSize: getValues("pageSize"),
      searchString: debouncedSearchText,
      sortCol: sortBy[0] ? sortBy[0].id : "",
      sortOrder: sortBy[0] ? (sortBy[0].desc ? "DESC" : "ASC") : "",
    };
  }

  function getUsers(pageIndex: number) {
    return dispatch(
      getUsersThunk(constructGetUsersThunkAction(pageIndex))
    ).then((res) => {
      if (res.meta.requestStatus === "rejected") {
        toast.error(
          Message({
            action: "update",
            entity: t("users.users"),
            error: t("genericError"),
            lang,
            gender: "male",
          })
        );
      }
      return res;
    });
  }

  useEffect(() => {
    dispatch(getUserRanksThunk({ pageSize: 99 })).then((res) => {
      if (res.meta.requestStatus === "rejected") {
        toast.error(
          Message({
            action: "read",
            entity: t("users.rank"),
            error: t("genericError"),
            lang,
            gender: "male",
          })
        );
      }
      return res;
    });
  }, []);

  useEffect(() => {
    if (isDirty) getUsers(0);
  }, [
    watch("role"),
    watch("location"),
    watch("rank"),
    watch("status"),
    watch("memberStatus"),
    watch("to"),
  ]);

  useEffect(() => {
    getUsers(0);
  }, [debouncedSearchText, sortBy]);

  const handleActivationEmail = ({
    email,
    status,
  }: {
    email: string;
    status: string;
  }) => {
    switch (status) {
      case "RESET": {
        dispatch(resendEmailCreationThunk({ email })).then((res) => {
          if (res.meta.requestStatus === "rejected") {
            toast.error({
              action: "send",
              entity: t("users.activationEmailError"),
              error: t("genericError"),
              lang,
              gender: "female",
            });
          } else {
            toast.success({
              action: "send",
              entity: t("users.activationEmail"),
              lang,
              gender: "female",
            });
          }
          return res;
        });
        break;
      }
      case "INACTIVE": {
        dispatch(resendEmailRegisterThunk({ email })).then((res) => {
          if (res.meta.requestStatus === "rejected") {
            toast.error({
              action: "send",
              entity: t("users.activationEmailError"),
              error: t("genericError"),
              lang,
              gender: "female",
            });
          } else {
            toast.success({
              action: "send",
              entity: t("users.activationEmail"),
              lang,
              gender: "female",
            });
          }
          return res;
        });
        break;
      }
      default: {
        break;
      }
    }
    setActivationOpen(false);
  };

  const handleResetPasswordEmail = (email: string) => {
    dispatch(resetPasswordThunk({ email }))
      .then((res) => {
        if (res.meta.requestStatus === "rejected") {
          toast.error(
            Message({
              action: "send",
              entity: t("users.resetEmailError"),
              error: t("genericError"),
              lang,
              gender: "female",
            })
          );
        } else {
          toast.success(
            Message({
              action: "create",
              entity: t("users.resetEmail"),
              lang,
              gender: "female",
            })
          );
        }
        return res;
      })
      .then(() => getUsers(0));
    setResetOpen(false);
  };

  const handleDeleteUser = (userToDelete: string, isHard: boolean) => {
    dispatch(deleteUserThunk({ username: userToDelete, isHard: isHard }))
      .then((res) => {
        if (res.meta.requestStatus === "rejected") {
          toast.error(
            Message({
              action: "delete",
              entity: t("users.user"),
              error: t("genericError"),
              lang,
              gender: "male",
            })
          );
        } else {
          toast.success(
            Message({
              action: "delete",
              entity: t("users.user"),
              lang,
              gender: "male",
            })
          );
        }
        return res;
      })
      .then(() => getUsers(0));
    setUserDeleteOpen(false);
  };

  const handleDeleteUserModal = (userToDelete: string, isHard: boolean) => {
    setUserToDelete(userToDelete);
    setIsHardDelete(isHard);

    setUserDeleteOpen(true);
  };

  const [changePassModal, setChangePassModal] = useState(false);
  const [userToChange, setUserToChange] = useState("");
  const [pass, setPass] = useState("");

  const handleChangePassword = (userToChange: string) => {
    setUserToChange(userToChange);
    setChangePassModal(true);
  };

  const handleChangePasswordFunc = async () => {
    try {
      const formData = new FormData();
      formData.append("password", pass);
      await axios.put(
        `${CMS_BE_URL}/users/${userToChange}`,

        formData
      );
      setChangePassModal(false);
      toast.success(t("UserCRUD.changedPass"));
    } catch (err: any) {
      if (err.response?.data?.errors?.[0]?.message) {
        toast.error(err.response?.data?.errors?.[0]?.message);
      } else {
        toast.error(err.response?.data?.message);
      }
    }
  };

  const handleCancelUserDeletion = (userToCancelDeletion: string) => {
    updateUserStatus(userToCancelDeletion, "INACTIVE")
      .then(() => {
        toast.success(
          Message({
            action: "update",
            entity: t("user.status"),
            lang,
            gender: "male",
          })
        );
      })
      .catch((err: any) => {
        if (err.response?.data?.errors?.[0]?.message) {
          toast.error(err.response?.data?.errors?.[0]?.message);
        } else {
          toast.error(err.response?.data?.message);
        }
      })
      .then(() => getUsers(0));
    setCancelDeletionOpen(false);
  };

  const activateAccountHandler = async (pk_user: string, unlock: boolean) => {
    setLoading(true);
    try {
      if (unlock) {
        await axios.put(
          `${CMS_BE_URL}/users/unlockAccountAdminAction/${pk_user}`
        );
        toast.success(t("users.unlockAccountMsg"));
      }
      if (!unlock) {
        await axios.put(
          `${CMS_BE_URL}/users/activateAccountAdminAction/${pk_user}`
        );
        toast.success(t("users.activateAccountMsg"));
      }
      getUsers(0);
    } catch (err: any) {
      if (err.response?.data?.errors?.[0]?.message) {
        toast.error(err.response?.data?.errors?.[0]?.message);
      } else {
        toast.error(err.response?.data?.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const branchActionsOnUserType = ({
    pk_user,
    email,
    status,
  }: {
    pk_user: string;
    email: string;
    status: string;
  }) => {
    switch (status) {
      case "RESET":
      case "INACTIVE": {
        setUserToActivate({
          email,
          status,
        });
        setActivationOpen(true);
        break;
      }
      case "LOCKED":
      case "ACTIVE": {
        setEmailToReset(email);
        setResetOpen(true);
        break;
      }
      case "DELETED": {
        setUserToCancelDeletion(pk_user);
        setCancelDeletionOpen(true);
        break;
      }

      default: {
        break;
      }
    }
  };

  const dispatchNextPage = () => {
    if (canNextPage) {
      getUsers(pageIndex + 1).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          nextPage();
        }
      });
    }
  };

  const dispatchPreviousPage = () => {
    if (canPreviousPage) {
      getUsers(pageIndex - 1).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          previousPage();
        }
      });
    }
  };

  const dispatchGotoPage = (pageIndex: number) => {
    getUsers(pageIndex).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        gotoPage(pageIndex);
      }
    });
  };

  function portalContent() {
    if (portal.entity === null) return;
    const canRead = canAccess("users", "READ", portal.entity);
    const canUpdate = canAccess("users", "UPDATE", portal.entity);
    const canDelete = canAccess("users", "DELETE", portal.entity);

    return (
      <div
        className="fixed z-50 flex flex-col gap-2 p-2 bg-white rounded-sm shadow-md"
        style={{
          width: "370px",
          top: portal.top,
          left: portal.left,
        }}
      >
        {canRead ? (
          <>
            <Link
              to={`/users/${portal.entity.pk_user}`}
              className="flex gap-2 items-center text-users-create"
            >
              <RefreshIcon className="w-5 h-5" />
              {canUpdate ? t("crud.update") : t("crud.read")}
            </Link>
          </>
        ) : null}
        {isSuperAdmin && (
          <>
            {" "}
            <button
              onClick={() =>
                handleChangePassword(String(portal.entity?.pk_user))
              }
              className="flex gap-2 items-center text-yellow-500"
            >
              <PencilIcon className="w-5 h-5" />
              {lang === "en" ? "Change password" : "Cambia la password"}
            </button>
          </>
        )}
        {canUpdate && portal.entity.status !== "DELETED" && (
          <>
            {portal.entity.email && (
              <button
                onClick={() =>
                  branchActionsOnUserType({
                    pk_user: portal.entity.pk_user,
                    email: portal.entity.email,
                    status: portal.entity.status,
                  })
                }
                className="flex gap-2 items-center text-primaryAlternative"
              >
                <LockOpenIcon className="w-5 h-5" />
                <span className="truncate">
                  {portal.entity.status === "ACTIVE" ||
                  portal.entity.status === "LOCKED"
                    ? t("users.resetEmail")
                    : portal.entity.status === "INACTIVE" ||
                      portal.entity.status === "RESET"
                    ? t("users.activationEmail")
                    : t("users.cancelDeletion")}
                </span>
              </button>
            )}
            {(portal.entity.status === "INACTIVE" ||
              portal.entity.status === "RESET") && (
              <button
                onClick={() =>
                  activateAccountHandler(portal.entity.pk_user, false)
                }
                className="flex gap-2 items-center text-cyan-500"
              >
                <LockOpenIcon className="w-5 h-5" />
                <span className="truncate">{t("users.activateAccount")}</span>
              </button>
            )}
            {portal.entity.status === "LOCKED" && (
              <button
                onClick={() =>
                  activateAccountHandler(portal.entity.pk_user, true)
                }
                className="flex gap-2 items-center text-table-edit"
              >
                <LockOpenIcon className="w-5 h-5" />
                <span className="truncate">{t("users.unlockAccount")}</span>
              </button>
            )}
          </>
        )}
        {canDelete && portal.entity.status !== "DELETED" ? (
          <button
            onClick={() =>
              handleDeleteUserModal(String(portal.entity?.pk_user), false)
            }
            className="flex gap-2 items-center text-users-delete"
          >
            <TrashIcon className="w-5 h-5" />
            {lang === "en" ? "Delete" : "Elimina"}
          </button>
        ) : null}
        {isSuperAdmin ? (
          <button
            onClick={() =>
              handleDeleteUserModal(String(portal.entity?.pk_user), true)
            }
            style={{
              color: "#9A3412",
            }}
            className="flex gap-2 items-center"
          >
            <UserRemoveIcon className="w-5 h-5" />
            {lang === "en" ? "Hard Delete" : "Elimina definitiva"}
          </button>
        ) : null}
      </div>
    );
  }

  const lookups = selectLookups();
  useEffect(() => {
    const rolesOptions = lookups.lookupList.roles.map((role) => ({
      value: role.ID,
      label: role.ROLE_NAME,
    }));
    setRolesOptions(rolesOptions);
  }, [lookups.lookupList.roles]);
  useEffect(() => {
    const regionsMap = lookups.lookupList.regions.map((region) => ({
      value: region.PK_REGION,
      label: region.PK_REGION,
    }));
    setRegionsOptions(regionsMap);
  }, [lookups.lookupList.regions]);
  const ranks = selectUserRanks();
  useEffect(() => {
    const ranksOptions = ranks.data.userRanks?.map((rank) => ({
      value: rank.id,
      label: lang === "en" ? rank.rank_eng : rank.rank_it,
    }));
    setRankOptions(ranksOptions);
  }, [ranks.data.userRanks]);
  const statuses = selectStatuses();
  useEffect(() => {
    const statusesOptions = statuses.map((status) => ({
      value: status.toUpperCase(),
      label: status,
    }));
    setStatusesOptions(statusesOptions);
  }, [statuses]);
  const membershipStatuses = selectMembershipStatuses();
  useEffect(() => {
    const membershipStatusesOptions = membershipStatuses.map(
      (membershipStatus) => ({
        value: membershipStatus.toUpperCase(),
        label: membershipStatus,
      })
    );
    setMembershipStatusesOptions(membershipStatusesOptions);
  }, [membershipStatuses]);

  // MULTIPLE USER ACTIONS PERMISSIONS

  const canManageSubscriptions = loggedInUser?.user
    ? canAccess("subscriptions", "CREATE", loggedInUser.user)
    : false;

  const canUpdateUsers = loggedInUser?.user
    ? canAccess("users", "UPDATE", loggedInUser.user)
    : false;

  const canDelete = loggedInUser?.user?.PK_USER
    ? canUpdateUsers &&
      ((!allSelected && !includeUsers.includes(loggedInUser.user.PK_USER)) ||
        (allSelected &&
          (excludeUsers.includes(loggedInUser.user.PK_USER) ||
            loggedInUser.user.PK_USER === "admin")))
    : false;

  // MULTIPLE USER ACTION MODAL STATES

  const [showBanUserModal, setShowBanUserModal] = useState(false);
  const [showUnbanUserModal, setShowUnbanUserModal] = useState(false);
  const [showResetPasswordUsersModal, setShowResetPasswordUsersModal] =
    useState(false);
  const [showDeleteUsersModal, setShowDeleteUsersModal] = useState(false);
  const [showDeleteUsersPermaModal, setShowDeleteUsersPermaModal] =
    useState(false);
  const [showAddSubUsersModal, setShowAddSubUsersModal] = useState(false);
  const [securityPopup, setSecurityPopup] = useState(false);
  const [activeAction, setActiveAction] = useState<MassUserActionType>(
    MassUserActionType.ban
  );

  // STATES FOR MULTIPLE SUBSCRIPTION ACTION
  const [method, setMethod] = useState<string>("cash");
  const [type, setType] = useState<string>("teacher");
  const [planId, setPlanId] = useState<number>(0);
  const [price, setPrice] = useState<number>(0);
  const [subPaymentDate, setSubPaymentDate] = useState<any>();

  // getting the plans
  const [plans, setPlans] = useState<any>([]);
  const [tPlans, setTPlans] = useState<any>([]);
  const [pPlans, setPPlans] = useState<any>([]);

  useEffect(() => {
    const getPlans = async () => {
      try {
        const res = await axios.get(`${CMS_BE_URL}/subscriptionPlans`);
        //sort plans by ID;
        const sortedPlans = res.data.data.sort((a: any, b: any) => a.id - b.id);
        const teachers = sortedPlans.slice(0, 3);
        const patreons = sortedPlans.slice(3, 7);
        if (sortedPlans?.length > 0) {
          setPlanId(sortedPlans[0].id);
          setPrice(sortedPlans[0].price);
        }
        setPlans(sortedPlans);
        setTPlans(teachers);
        setPPlans(patreons);
      } catch (err: any) {
        if (err.response?.data?.errors?.[0]?.message) {
          toast.error(err.response?.data?.errors?.[0]?.message);
        } else {
          toast.error(err.response?.data?.message);
        }
      }
    };
    getPlans();
  }, []);

  // PLAN SELECTION HANDLERS FOR THE SUB MODAL
  const handleTypeSelection = (e: any) => {
    setType(e);
    if (e === "teacher" && tPlans?.length > 0) {
      setPlanId(tPlans[0].id);
      setPrice(tPlans[0].price);
    }
    if (e === "patreon" && pPlans?.length > 0) {
      setPlanId(pPlans[0].id);
      setPrice(pPlans[0].price);
    }
  };

  const handlePlanSelection = (e: any) => {
    const plan = plans.find((pl: any) => Number(pl.id) === Number(e));
    setPlanId(plan.id);
    setPrice(plan.price);
  };

  // MULTIPLE USERS ACTION HANDLERS

  const handleMassUpdate = (
    setFn: Dispatch<SetStateAction<boolean>>,
    values: Record<string, unknown>
  ) => {
    setFn(false);
    const payloadObj =
      includeUsers.length > 0
        ? { include: includeUsers, values }
        : { exclude: excludeUsers, values };
    dispatch(usersBulkUpdateThunk(payloadObj))
      .then(() =>
        getUsers(pageIndex).then(() =>
          toast.success(t("users.massUpdateSuccess"))
        )
      )
      .catch((err: any) => {
        if (err.response?.data?.errors?.[0]?.message) {
          toast.error(err.response?.data?.errors?.[0]?.message);
        } else {
          toast.error(err.response?.data?.message);
        }
      });
  };

  const handleAddSubscriptionUsersModal = () => {
    const sub_data = {
      subscribe: {
        price: price,
        type: type,
        status: "active",
        planId,
        order: false,
        payment_type: method,
        start_date: Date.now(),
        end_date: getSubscriptionEndDate(),
        payment_date: subPaymentDate ?? Date.now(),
      },
    };
    handleMassUpdate(setShowAddSubUsersModal, sub_data);
  };

  const handleCloseSubscriptionUsersModal = () => {
    setShowAddSubUsersModal(false);
    /*setTimeout(() => {
      setMethod("cash");
      setType("teacher");
      if (tPlans?.length > 0) {
        setPlanId(tPlans[0].id);
        setPrice(tPlans[0].price);
      }
    }, 100);*/
  };

  const handleSecurityPopup = () => {
    switch (activeAction) {
      case MassUserActionType.ban:
        handleMassUpdate(setShowBanUserModal, {
          status: "DISABLED",
        });
        break;
      case MassUserActionType.unban:
        handleMassUpdate(setShowUnbanUserModal, {
          status: "ACTIVE",
        });
        break;
      case MassUserActionType.resetPass:
        handleMassUpdate(setShowResetPasswordUsersModal, { reset: true });
        break;
      case MassUserActionType.delete:
        handleMassUpdate(setShowDeleteUsersModal, {
          delete: "soft",
        });
        break;
      case MassUserActionType.permaDelete:
        handleMassUpdate(setShowDeleteUsersPermaModal, {
          delete: "hard",
        });
        break;
      case MassUserActionType.addSubs:
        handleAddSubscriptionUsersModal();
        break;
      default:
        break;
    }
    setSecurityPopup(false);
  };

  const getUsersThatAreUpdatedNumber = () => {
    const { total } = users.data;
    if (includeUsers.length === 0 && excludeUsers.length === 0)
      return total.toString();
    if (excludeUsers.length > 0)
      return (total - excludeUsers.length).toString();
    if (includeUsers.length > 0) return includeUsers.length.toString();
  };

  return (
    <Overlay active={users.loading || loading} spinner={<Spinner />}>
      <div className="flex flex-col gap-5 p-10 h-full">
        <div className="text-3xl font-bold">{t("users.users")}</div>
        <div className="flex flex-col gap-5">
          {filterOpen ? (
            <div className="flex flex-wrap gap-5">
              <div className="w-72">
                <Controller
                  name="role"
                  control={control}
                  render={({ field }) => (
                    <Select
                      placeholder={t("users.role")}
                      styles={getReactSelectStyles(false)}
                      isLoading={lookups.loading}
                      inputId="role"
                      isClearable={true}
                      value={field.value}
                      options={rolesOptions}
                      onChange={(e) => field.onChange(e)}
                    />
                  )}
                />
              </div>
              <div className="w-72">
                <Controller
                  name="location"
                  control={control}
                  render={({ field }) => (
                    <Select
                      placeholder={t("users.region")}
                      styles={getReactSelectStyles(false)}
                      isLoading={lookups.loading}
                      inputId="region"
                      isClearable={true}
                      value={field.value}
                      options={regionsOptions}
                      onChange={(e) => field.onChange(e)}
                    />
                  )}
                />
              </div>
              <div className="w-72">
                <Controller
                  name="rank"
                  control={control}
                  render={({ field }) => (
                    <Select
                      placeholder={t("users.rank")}
                      styles={getReactSelectStyles(false)}
                      inputId="rank"
                      // isLoading={ranks.loading}
                      isClearable={true}
                      value={field.value}
                      options={ranksOptions}
                      onChange={(e) => field.onChange(e)}
                    />
                  )}
                />
              </div>
              <div className="w-72">
                <Controller
                  name="status"
                  control={control}
                  render={({ field }) => (
                    <Select
                      placeholder={t("users.status")}
                      styles={getReactSelectStyles(false)}
                      inputId="status"
                      isClearable={true}
                      value={field.value}
                      options={statusesOptions}
                      onChange={(e) => field.onChange(e)}
                    />
                  )}
                />
              </div>
              <div className="w-72">
                <Controller
                  name="memberStatus"
                  control={control}
                  render={({ field }) => (
                    <Select
                      placeholder={t("users.membershipStatus")}
                      styles={getReactSelectStyles(false)}
                      inputId="memberStatus"
                      isClearable={true}
                      value={field.value}
                      options={membershipStatusesOptions}
                      onChange={(e) => field.onChange(e)}
                    />
                  )}
                />
              </div>
              <div className="w-72">
                <Controller
                  name="to"
                  control={control}
                  render={({ field }) => {
                    return (
                      <div>
                        <DatePicker
                          id="to"
                          selected={field.value}
                          onChange={(e) => field.onChange(e)}
                          showTimeSelect={true}
                          dateFormat="dd/MM/yyyy"
                          className={getInputStyles(false)}
                          placeholderText={t("users.endSub")}
                          showPopperArrow={false}
                          autoComplete="off"
                        />
                      </div>
                    );
                  }}
                />
              </div>
              <div>
                <button
                  type="button"
                  className="px-4 py-2 text-white bg-primary border border-primary"
                  onClick={() => {
                    setValue("role", null as any);
                    setValue("location", null as any);
                    setValue("rank", null as any);
                    setValue("status", null as any);
                    setValue("memberStatus", null as any);
                    setValue("to", null as any);
                  }}
                >
                  {t("users.clearAll")}
                </button>
              </div>
            </div>
          ) : null}

          {/* MULTIPLE USERS ACTION BUTTONS */}
          {(allSelected || includeUsers?.length > 0) && canUpdateUsers && (
            <div className="flex gap-5">
              <button
                className="px-4 py-2 text-white bg-users-create border border-users-create rounded-sm"
                onClick={() => setShowBanUserModal(true)}
              >
                {t("users.banUsers")}
              </button>
              <button
                className="px-4 py-2 text-white bg-users-create border border-users-create rounded-sm"
                onClick={() => setShowUnbanUserModal(true)}
              >
                {t("users.unbanUsers")}
              </button>
              <button
                className="px-4 py-2 text-white bg-users-create border border-users-create rounded-sm"
                onClick={() => setShowResetPasswordUsersModal(true)}
              >
                {t("users.resetPasswords")}
              </button>
              {canDelete && (
                <button
                  className="px-4 py-2 text-white bg-red-500 border border-red-500 rounded-sm"
                  onClick={() => setShowDeleteUsersModal(true)}
                >
                  {t("users.dlt")}
                </button>
              )}
              {isSuperAdmin && (
                <button
                  className="px-4 py-2 text-white bg-red-500 border border-red-500 rounded-sm"
                  onClick={() => setShowDeleteUsersPermaModal(true)}
                >
                  {t("users.permaDlt")}
                </button>
              )}
              {canManageSubscriptions && (
                <button
                  className="px-4 py-2 text-white bg-users-create border border-users-create rounded-sm"
                  onClick={() => setShowAddSubUsersModal(true)}
                >
                  {t("users.addSubscription")}
                </button>
              )}
            </div>
          )}

          <div className="flex flex-wrap gap-5">
            <button onClick={() => setFilterOpen(!filterOpen)}>
              <FilterIcon className="w-6 h-6" />
            </button>
            <div className="flex flex-grow flex-wrap gap-5 justify-end">
              <div className="flex items-center justify-start w-72 border focus-within:border-global-input-focus border-gray-300">
                <div className="flex items-center justify-center pl-2 h-full bg-white">
                  <SearchIcon className="w-5 h-5 text-gray-400" />
                </div>
                <input
                  type="text"
                  placeholder={`${t("users.search")}...`}
                  {...register("searchString")}
                  className="placeholder-gray-400 w-full border-0 focus:ring-0"
                />
              </div>
              {(loggedInUser.permissions["*"] ||
                loggedInUser.permissions?.users?.CREATE === "ANY") && (
                <Link to="/users/create" className="h-full">
                  <button className="flex gap-2 items-center justify-center px-4 py-2 text-white bg-global-createEntity border border-global-createEntity">
                    <PlusIcon className="w-5 h-5" />
                    <span className="whitespace-nowrap">
                      {t("users.create")} {t("users.user")}
                    </span>
                  </button>
                </Link>
              )}
            </div>
          </div>
        </div>
        <Table {...tableInstance} />
        <Pagination
          rowsCount={users.data.total}
          pageIndex={pageIndex}
          pageSize={getValues("pageSize")}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          pageOptions={pageOptions}
          pageCount={pageCount}
          gotoPage={dispatchGotoPage}
          nextPage={dispatchNextPage}
          previousPage={dispatchPreviousPage}
          setPageSize={setPageSize}
        />
        <TablePopup
          isOpen={portal.open}
          close={() => setPortal((state) => ({ ...state, open: false }))}
        >
          {portalContent()}
        </TablePopup>
        <Modal
          openStatus={activationOpen}
          setOpen={setActivationOpen}
          icon={
            <ExclamationIcon
              className="w-6 h-6 text-primary"
              aria-hidden="true"
            />
          }
          header={<p>{t("users.activationEmail")}</p>}
          title={<p>{t("users.activationMessage")}</p>}
          footer={
            <div className="flex justify-end mt-5 sm:mt-4">
              <button
                type="button"
                className="inline-flex justify-center mt-3 px-4 py-1 w-full text-black text-base font-medium bg-white border border-black focus:outline-none shadow-sm focus:ring-0 sm:mt-0 sm:w-auto"
                onClick={() => setActivationOpen(false)}
              >
                {t("users.cancel")}
              </button>
              <button
                type="button"
                className="inline-flex justify-center px-4 py-1 w-full text-white text-base font-medium bg-primary border border-transparent focus:outline-none shadow-sm focus:ring-0 sm:ml-3 sm:w-auto"
                onClick={() => handleActivationEmail(userToActivate)}
              >
                {t("users.send")}
              </button>
            </div>
          }
        />
        <Modal
          openStatus={resetOpen}
          setOpen={setResetOpen}
          className=""
          icon={
            <ExclamationIcon
              className="w-6 h-6 text-primary"
              aria-hidden="true"
            />
          }
          header={<p>{t("users.reset")}</p>}
          title={<p>{t("users.resetMessage")}</p>}
          footer={
            <div className="flex justify-end mt-5 sm:mt-4">
              <button
                type="button"
                className="inline-flex justify-center mt-3 px-4 py-1 w-full text-black text-base font-medium bg-white border border-black focus:outline-none shadow-sm focus:ring-0 sm:mt-0 sm:w-auto"
                onClick={() => setResetOpen(false)}
              >
                {t("users.cancel")}
              </button>
              <button
                type="button"
                className="inline-flex justify-center px-4 py-1 w-full text-white text-base font-medium bg-primary border border-transparent focus:outline-none shadow-sm focus:ring-0 sm:ml-3 sm:w-auto"
                onClick={() => handleResetPasswordEmail(emailToReset)}
              >
                {t("users.send")}
              </button>
            </div>
          }
        />
        <Modal
          openStatus={cancelDeletionOpen}
          setOpen={setCancelDeletionOpen}
          className=""
          icon={
            <ExclamationIcon
              className="w-6 h-6 text-primary"
              aria-hidden="true"
            />
          }
          header={<p>{t("users.cancelDeletion")}</p>}
          title={<p>{t("users.cancelDeletionMessage")}</p>}
          footer={
            <div className="flex justify-end mt-5 sm:mt-4">
              <button
                type="button"
                className="inline-flex justify-center mt-3 px-4 py-1 w-full text-black text-base font-medium bg-white border border-black focus:outline-none shadow-sm focus:ring-0 sm:mt-0 sm:w-auto"
                onClick={() => setCancelDeletionOpen(false)}
              >
                {t("users.cancel")}
              </button>
              <button
                type="button"
                className="inline-flex justify-center px-4 py-1 w-full text-white text-base font-medium bg-primary border border-transparent focus:outline-none shadow-sm focus:ring-0 sm:ml-3 sm:w-auto"
                onClick={() => handleCancelUserDeletion(userToCancelDeletion)}
              >
                {t("users.send")}
              </button>
            </div>
          }
        />
        <Modal
          openStatus={userDeleteOpen}
          setOpen={setUserDeleteOpen}
          className=""
          icon={
            <ExclamationIcon
              className="w-6 h-6 text-red-600 bg-transparent"
              aria-hidden="true"
            />
          }
          header={
            <p>
              {t("deleteModal.delete")} {t("users.user")}
            </p>
          }
          title={
            <p>
              {t("deleteModal.sure")} {t("users.user")}?{" "}
              {t("deleteModal.undone")}
            </p>
          }
          footer={
            <div className="flex justify-end mt-5 sm:mt-4">
              <button
                type="button"
                className="inline-flex justify-center mt-3 px-4 py-1 w-full text-black text-base font-medium bg-white border border-black focus:outline-none shadow-sm focus:ring-0 sm:mt-0 sm:w-auto"
                onClick={() => setUserDeleteOpen(false)}
              >
                {t("deleteModal.cancel")}
              </button>
              <button
                type="button"
                className="inline-flex justify-center px-4 py-1 w-full text-white text-base font-medium bg-red-600 border border-transparent focus:outline-none shadow-sm focus:ring-0 sm:ml-3 sm:w-auto"
                onClick={() => handleDeleteUser(userToDelete, isHardDelete)}
              >
                {t("deleteModal.delete")}
              </button>
            </div>
          }
        />
        <Modal
          openStatus={changePassModal}
          setOpen={setChangePassModal}
          className=""
          icon={
            <ExclamationIcon
              className="w-6 h-6 bg-transparent"
              aria-hidden="true"
            />
          }
          header={<p className="mb-4">{t("users.changePass")}</p>}
          title={
            <input
              className="block mb-4 border border-gray-300"
              value={pass}
              onChange={(e) => setPass(e.target.value)}
              type="text"
            />
          }
          footer={
            <>
              <div className="flex justify-end mt-5 sm:mt-4">
                <button
                  type="button"
                  className="inline-flex justify-center mt-3 px-4 py-1 w-full text-black text-base font-medium bg-white border border-black focus:outline-none shadow-sm focus:ring-0 sm:mt-0 sm:w-auto"
                  onClick={() => setChangePassModal(false)}
                >
                  {t("deleteModal.cancel")}
                </button>
                <button
                  type="button"
                  className="inline-flex justify-center px-4 py-1 w-full text-white text-base font-medium bg-users-create border border-transparent focus:outline-none shadow-sm focus:ring-0 sm:ml-3 sm:w-auto"
                  onClick={() => handleChangePasswordFunc()}
                >
                  {t("users.changePass")}
                </button>
              </div>
            </>
          }
        />

        {/* MULTIPLE USER ACTIONS MODALS  */}

        <Modal
          openStatus={showBanUserModal}
          setOpen={setShowBanUserModal}
          className=""
          icon={
            <ExclamationIcon
              className="w-6 h-6 bg-transparent"
              aria-hidden="true"
            />
          }
          header={<p className="mb-4">{t("users.banUsers")}</p>}
          title={<div>{t("users.banUsersPrompt")}</div>}
          footer={
            <>
              <div className="flex justify-end mt-5 sm:mt-4">
                <button
                  type="button"
                  className="inline-flex justify-center mt-3 px-4 py-1 w-full text-black text-base font-medium bg-white border border-black focus:outline-none shadow-sm focus:ring-0 sm:mt-0 sm:w-auto"
                  onClick={() => setShowBanUserModal(false)}
                >
                  {t("deleteModal.cancel")}
                </button>
                <button
                  type="button"
                  className="inline-flex justify-center px-4 py-1 w-full text-white text-base font-medium bg-users-create border border-transparent focus:outline-none shadow-sm focus:ring-0 sm:ml-3 sm:w-auto"
                  onClick={() => {
                    setActiveAction(MassUserActionType.ban);
                    setSecurityPopup(true);
                  }}
                >
                  {t("users.banUsers")}
                </button>
              </div>
            </>
          }
        />
        <Modal
          openStatus={showUnbanUserModal}
          setOpen={setShowUnbanUserModal}
          className=""
          icon={
            <ExclamationIcon
              className="w-6 h-6 bg-transparent"
              aria-hidden="true"
            />
          }
          header={<p className="mb-4">{t("users.unbanUsers")}</p>}
          title={<div>{t("users.unbanUsersPrompt")}</div>}
          footer={
            <>
              <div className="flex justify-end mt-5 sm:mt-4">
                <button
                  type="button"
                  className="inline-flex justify-center mt-3 px-4 py-1 w-full text-black text-base font-medium bg-white border border-black focus:outline-none shadow-sm focus:ring-0 sm:mt-0 sm:w-auto"
                  onClick={() => setShowUnbanUserModal(false)}
                >
                  {t("deleteModal.cancel")}
                </button>
                <button
                  type="button"
                  className="inline-flex justify-center px-4 py-1 w-full text-white text-base font-medium bg-users-create border border-transparent focus:outline-none shadow-sm focus:ring-0 sm:ml-3 sm:w-auto"
                  onClick={() => {
                    setActiveAction(MassUserActionType.unban);
                    setSecurityPopup(true);
                  }}
                >
                  {t("users.unbanUsers")}
                </button>
              </div>
            </>
          }
        />
        <Modal
          openStatus={showResetPasswordUsersModal}
          setOpen={setShowResetPasswordUsersModal}
          className=""
          icon={
            <ExclamationIcon
              className="w-6 h-6 bg-transparent"
              aria-hidden="true"
            />
          }
          header={<p className="mb-4">{t("users.resetPasswords")}</p>}
          title={<div>{t("users.resetPasswordsPrompt")}</div>}
          footer={
            <>
              <div className="flex justify-end mt-5 sm:mt-4">
                <button
                  type="button"
                  className="inline-flex justify-center mt-3 px-4 py-1 w-full text-black text-base font-medium bg-white border border-black focus:outline-none shadow-sm focus:ring-0 sm:mt-0 sm:w-auto"
                  onClick={() => setShowResetPasswordUsersModal(false)}
                >
                  {t("deleteModal.cancel")}
                </button>
                <button
                  type="button"
                  className="inline-flex justify-center px-4 py-1 w-full text-white text-base font-medium bg-users-create border border-transparent focus:outline-none shadow-sm focus:ring-0 sm:ml-3 sm:w-auto"
                  onClick={() => {
                    setActiveAction(MassUserActionType.resetPass);
                    setSecurityPopup(true);
                  }}
                >
                  {t("users.resetPasswords")}
                </button>
              </div>
            </>
          }
        />
        <Modal
          openStatus={showDeleteUsersModal}
          setOpen={setShowDeleteUsersModal}
          className=""
          icon={
            <ExclamationIcon
              className="w-6 h-6 bg-transparent"
              aria-hidden="true"
            />
          }
          header={<p className="mb-4">{t("users.deleteUsers")}</p>}
          title={<div>{t("users.deleteUsersPrompt")}</div>}
          footer={
            <>
              <div className="flex justify-end mt-5 sm:mt-4">
                <button
                  type="button"
                  className="inline-flex justify-center mt-3 px-4 py-1 w-full text-black text-base font-medium bg-white border border-black focus:outline-none shadow-sm focus:ring-0 sm:mt-0 sm:w-auto"
                  onClick={() => setShowDeleteUsersModal(false)}
                >
                  {t("deleteModal.cancel")}
                </button>
                <button
                  type="button"
                  className="inline-flex justify-center px-4 py-1 w-full text-white text-base font-medium bg-red-500 border border-transparent focus:outline-none shadow-sm focus:ring-0 sm:ml-3 sm:w-auto"
                  onClick={() => {
                    setActiveAction(MassUserActionType.delete);
                    setSecurityPopup(true);
                  }}
                >
                  {t("users.deleteUsers")}
                </button>
              </div>
            </>
          }
        />
        <Modal
          openStatus={showDeleteUsersPermaModal}
          setOpen={setShowDeleteUsersPermaModal}
          className=""
          icon={
            <ExclamationIcon
              className="w-6 h-6 bg-transparent"
              aria-hidden="true"
            />
          }
          header={<p className="mb-4">{t("users.permaDeleteUsers")}</p>}
          title={<div>{t("users.permaDeleteUsersPrompt")}</div>}
          footer={
            <>
              <div className="flex justify-end mt-5 sm:mt-4">
                <button
                  type="button"
                  className="inline-flex justify-center mt-3 px-4 py-1 w-full text-black text-base font-medium bg-white border border-black focus:outline-none shadow-sm focus:ring-0 sm:mt-0 sm:w-auto"
                  onClick={() => setShowDeleteUsersPermaModal(false)}
                >
                  {t("deleteModal.cancel")}
                </button>
                <button
                  type="button"
                  className="inline-flex justify-center px-4 py-1 w-full text-white text-base font-medium bg-red-500 border border-transparent focus:outline-none shadow-sm focus:ring-0 sm:ml-3 sm:w-auto"
                  onClick={() => {
                    setActiveAction(MassUserActionType.permaDelete);
                    setSecurityPopup(true);
                  }}
                >
                  {t("users.deleteUsers")}
                </button>
              </div>
            </>
          }
        />
        <Modal
          openStatus={showAddSubUsersModal}
          setOpen={handleCloseSubscriptionUsersModal}
          className=""
          header={
            <p className="text-xl">
              <span>{t("subs.sUser")}</span>
            </p>
          }
          title={
            <div>
              {t("user.subscribeDescription")}
              <div className="flex flex-col gap-3 mt-3">
                <label className="w-full">
                  <span className="text-gray-900 text-base font-medium">
                    {" "}
                    {t("user.paymentMethod")}
                  </span>{" "}
                  <br />
                  <select
                    className="mt-1 py-2 w-full text-gray-500 border focus:border-global-input-focus border-gray-300 focus:ring-0"
                    onChange={(e) => setMethod(e.target.value)}
                    defaultValue={method}
                  >
                    <option value="cash">{t("subs.cash")}</option>
                    <option value="bank_transfer">
                      {t("user.bankTransfer")}
                    </option>
                    <option value="postal">{t("user.postal")}</option>
                    <option value="gift">{t("user.gift")}</option>
                  </select>
                </label>
                <label>
                  <span className="text-gray-900 text-base font-medium">
                    {" "}
                    {t("subs.type")}
                  </span>{" "}
                  <br />
                  <select
                    defaultValue={type}
                    className="mt-1 py-2 w-full text-gray-500 border focus:border-global-input-focus border-gray-300 focus:ring-0"
                    onChange={(e) => handleTypeSelection(e.target.value)}
                  >
                    <option value="teacher">Teacher</option>

                    <option value="patreon">Patreon</option>
                  </select>
                </label>
                {type == "patreon" && (
                  <label>
                    <span className="text-gray-900 text-base font-medium">
                      {" "}
                      Plans
                    </span>{" "}
                    <br />
                    <select
                      className="mt-1 py-2 w-full text-gray-500 border focus:border-global-input-focus border-gray-300 focus:ring-0"
                      onChange={(e) => handlePlanSelection(e.target.value)}
                      defaultValue={4}
                    >
                      {pPlans?.map((p: any) => (
                        <option key={p.id} value={p.id}>
                          {p.title}
                        </option>
                      ))}
                    </select>
                  </label>
                )}
                {type == "teacher" && (
                  <div className="w-full">
                    <label>
                      <span className="text-gray-900 text-base font-medium">
                        Plans
                      </span>{" "}
                      <br />
                      <select
                        className="mt-1 py-2 w-full text-gray-500 border focus:border-global-input-focus border-gray-300 focus:ring-0"
                        onChange={(e) => handlePlanSelection(e.target.value)}
                        defaultValue={1}
                      >
                        {tPlans?.map((p: any) => (
                          <option className="w-full" key={p.id} value={p.id}>
                            {p.title}
                          </option>
                        ))}
                      </select>
                    </label>
                  </div>
                )}
                <div className="flex flex-col gap-4">
                  <label>
                    <span className="text-gray-900 text-base font-medium">
                      {t("products.price")}
                    </span>{" "}
                    <br />
                    <input
                      className="mt-1 py-2 w-full text-gray-500 border focus:border-global-input-focus border-gray-300 focus:ring-0"
                      value={price}
                      defaultValue={price}
                      min={0}
                      onChange={(e) => setPrice(Number(e.target.value))}
                      type="number"
                    />
                  </label>
                </div>
                <div className="flex flex-col gap-4">
                  <label>
                    <span className="text-gray-900 text-base font-medium">
                      {t("UserCRUD.subPaymentDate")}
                    </span>{" "}
                    <br />
                    <DatePicker
                      id="user_sub_payment_date"
                      selected={subPaymentDate ?? Date.now()}
                      onChange={(e) => setSubPaymentDate(e)}
                      dateFormat="dd/MM/yyyy"
                      className={getInputStyles(false)}
                      placeholderText={t("UserCRUD.subPaymentDate")}
                      showPopperArrow={false}
                      autoComplete="off"
                    />
                  </label>
                </div>
              </div>
            </div>
          }
          footer={
            <div className="mt-5 sm:flex sm:flex-row-reverse sm:mt-4">
              <button
                type="button"
                className="inline-flex justify-center px-4 py-1 w-full text-white text-base font-medium bg-users-create hover:bg-users-create border border-transparent focus:outline-none shadow-sm focus:ring-2 focus:ring-users-create focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                onClick={() => {
                  setActiveAction(MassUserActionType.addSubs);
                  setSecurityPopup(true);
                }}
              >
                {t("subs.proceed")}
              </button>
              <button
                type="button"
                className="inline-flex justify-center mt-3 px-4 py-1 w-full hover:text-gray-500 text-gray-700 text-base font-medium bg-white border border-gray-300 focus:outline-none shadow-sm focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                onClick={() => handleCloseSubscriptionUsersModal()}
              >
                {t("user.cancel")}
              </button>
            </div>
          }
        />
        <Modal
          openStatus={securityPopup}
          setOpen={setSecurityPopup}
          className=""
          icon={
            <ExclamationIcon
              className="w-6 h-6 text-primary"
              aria-hidden="true"
            />
          }
          header={<p>{t("users.securityHeader")}</p>}
          title={
            <p>
              {t("users.securityPopUp1")}
              {getUsersThatAreUpdatedNumber()}
              {t("users.securityPopUp2")}
            </p>
          }
          footer={
            <div className="flex justify-end mt-5 sm:mt-4">
              <button
                type="button"
                className="inline-flex justify-center mt-3 px-4 py-1 w-full text-black text-base font-medium bg-white border border-black focus:outline-none shadow-sm focus:ring-0 sm:mt-0 sm:w-auto"
                onClick={() => setSecurityPopup(false)}
              >
                {t("users.cancel")}
              </button>
              <button
                type="button"
                className="inline-flex justify-center px-4 py-1 w-full text-white text-base font-medium bg-primary border border-transparent focus:outline-none shadow-sm focus:ring-0 sm:ml-3 sm:w-auto"
                onClick={() => handleSecurityPopup()}
              >
                {t("users.send")}
              </button>
            </div>
          }
        />

        {loggedInUser.user &&
        canAccess("exportUserData", "READ", loggedInUser.user) ? (
          <ExportUsers
            location={getValues("location.value")}
            rank={getValues("rank.value")}
            status={getValues("status.value")}
            memberStatus={getValues("memberStatus.value")}
            role={getValues("role.value")}
            total={users.data.total}
            loading={loading}
            setLoading={setLoading}
          />
        ) : null}
      </div>
    </Overlay>
  );
};

export default UsersPage;
