import { UploadIcon } from "@heroicons/react/solid";
import React, { useEffect, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { useFormContext } from "react-hook-form";
import { getMediaDropzoneStyles, resizeFile } from "utils/utils";
import { useTranslation } from "react-i18next";

export default function MultipleImageDropzone({
  reactHookFormName,
  aspect,
}: {
  reactHookFormName: string;
  aspect: number;
}) {
  const {
    setValue,
    getValues,
    formState: { errors },
    setError,
    clearErrors,
  } = useFormContext();
  const { t } = useTranslation("common");
  const onDrop = useCallback(async (files) => {
    clearErrors(reactHookFormName);
    files = await Promise.all(
      files.map((file: File) => resizeFile(file, aspect))
    );

    const oldFiles = getValues(reactHookFormName);
    if (oldFiles) {
      setValue(reactHookFormName, [...oldFiles, ...files]);
    } else {
      setValue(reactHookFormName, files);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive, fileRejections } =
    useDropzone({
      onDrop,
      multiple: true,
      accept: "image/*",
    });

  useEffect(() => {
    if (fileRejections.length) {
      if (setError) {
        setError(reactHookFormName, {
          type: "manual",
          message: t("multipleDropzone.type"),
        });
      }
    }
  }, [fileRejections]);

  return (
    <div
      {...getRootProps()}
      className={getMediaDropzoneStyles(errors[reactHookFormName]?.message)}
    >
      <input id={reactHookFormName} {...getInputProps()} />
      <div className="font-xs text-center">
        {t("multipleDropzone.maxSize")}:{" "}
        <span className="font-semibold">10MB</span>
      </div>
      <UploadIcon className="w-20 h-20" />
      {isDragActive ? (
        <p>{`${t("multipleDropzone.drop")}...`}</p>
      ) : (
        <p>{t("multipleDropzone.choose")}</p>
      )}
    </div>
  );
}
