import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import {
  DocumentIcon,
  NewspaperIcon,
  CollectionIcon,
  CalendarIcon,
  UsersIcon,
  BookOpenIcon,
  ChevronDoubleRightIcon,
  IdentificationIcon,
  BadgeCheckIcon,
  MailOpenIcon,
  AdjustmentsIcon,
  MailIcon,
  MapIcon,
  ClipboardListIcon,
  TagIcon,
  ClipboardIcon,
  DocumentTextIcon,
} from "@heroicons/react/solid";
import { Transition } from "@headlessui/react";
import { RiDashboardFill } from "react-icons/ri";
import { useTranslation } from "react-i18next";
import ANMBLogo from "shared/assets/images/ANMBLogo";
import UserPopup from "./UserPopup";
import { selectLoggedInUser } from "store/authStore/authReducer";
import {
  DocumentDownloadIcon,
  InformationCircleIcon,
} from "@heroicons/react/outline";
import { convertNodeToElement } from "react-html-parser";

interface NavigationItem {
  name: string;
  href: string;
  icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  domain: any;
}

export default function DashboardSidebar(): JSX.Element {
  const loggedInUser = selectLoggedInUser();
  const { t } = useTranslation("common");
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [richNavigationOpen, setRichNavigationOpen] = useState(false);
  const toggleNavbar = (): void => {
    return setSidebarOpen(!sidebarOpen);
  };

  function canAccess(item: NavigationItem) {
    if (loggedInUser.permissions["*"]) return true;
    const isEnalbed = loggedInUser.permissions[item.domain]["ENABLED"];
    if (isEnalbed) {
      const perm = loggedInUser.permissions[item.domain]["READ"];
      if (perm === "OWN" || perm === "ANY") return true;
    }
    return false;
  }

  const dashboard = {
    name: `${t("sidebar.dashboard")}`,
    href: "/",
    icon: RiDashboardFill,
  };

  const administration: NavigationItem = {
    name: `${t("sidebar.administration")}`,
    href: "/",
    icon: AdjustmentsIcon,
    domain: "administrative",
  };

  function createSidebarNavigationItems(): Array<NavigationItem> {
    const items: NavigationItem[] = [
      {
        name: `${t("sidebar.news")}`,
        href: "/news",
        icon: NewspaperIcon,
        domain: "news",
      },
      {
        name: `${t("sidebar.events")}`,
        href: "/events",
        icon: CalendarIcon,
        domain: "events",
      },
      {
        name: `${t("sidebar.exams")}`,
        href: "/exams",
        icon: BookOpenIcon,
        domain: "exams",
      },
      {
        name: `${t("sidebar.users")}`,
        href: "/users",
        icon: UsersIcon,
        domain: "users",
      },
      {
        name: `${t("sidebar.documents")}`,
        href: "/documents",
        icon: DocumentIcon,
        domain: "documents",
      },
      {
        name: `${t("sidebar.press")}`,
        href: "/press",
        icon: ClipboardIcon,
        domain: "press",
      },
      {
        name: `${t("sidebar.newsletter")}`,
        href: "/newsletter",
        icon: MailOpenIcon,
        domain: "newsletter",
      },
      {
        name: t("sidebar.orders"),
        href: "/orders",
        icon: ClipboardListIcon,
        domain: "orders",
      },
      {
        name: `${t("sidebar.receipts")}`,
        href: "/receipts",
        icon: DocumentTextIcon,
        domain: "receipts",
      },
    ];

    return items.filter((item) => canAccess(item));
  }

  function createSidebarRichItems() {
    const items = [
      {
        name: `${t("sidebar.certificates")}`,
        href: "/certificates",
        icon: BadgeCheckIcon,
        domain: "certificates",
      },
      {
        name: `${t("sidebar.plansAndProducts")}`,
        href: "/products",
        icon: CollectionIcon,
        domain: "s",
      },
      {
        name: `${t("sidebar.plans")}`,
        href: "/plans",
        icon: CollectionIcon,
        domain: "subscriptions",
      },
      {
        name: `${t("sidebar.aboutUs")}`,
        href: "/about-us",
        icon: MapIcon,
        domain: "councils",
      },
      {
        name: `${t("sidebar.contactUs")}`,
        href: "/contact-us",
        icon: MailIcon,
        domain: "contactus",
      },

      {
        name: `${t("sidebar.roles")}`,
        href: "/roles",
        icon: IdentificationIcon,
        domain: "permissionsManagement",
      },
      {
        name: `${t("sidebar.fieldsDomain")}`,
        href: "/fieldsDomain",
        icon: InformationCircleIcon,
        domain: "tags",
      },
      {
        name: `${t("sidebar.sponsors")}`,
        href: "/sponsors",
        icon: DocumentDownloadIcon,
        domain: "sponsors",
      },

      {
        name: `${t("sidebar.exportData")}`,
        href: "/exportData",
        icon: DocumentDownloadIcon,
        domain: "users",
      },
      {
        name: `${t("sidebar.massUpdate")}`,
        href: "/massUpdate",
        icon: DocumentDownloadIcon,
        domain: "massUpdate",
      },
      {
        name: `${t("sidebar.coupons")}`,
        href: "/coupons",
        icon: TagIcon,
        domain: "s",
      },
    ];
    return items.filter((item) => canAccess(item));
  }

  const navigation: Array<NavigationItem> = createSidebarNavigationItems();
  const richNavigation = createSidebarRichItems();

  return (
    <>
      {loggedInUser && (
        <div className="sidebar h-screen">
          <div
            className={`flex flex-col h-full transition-width ease-in-out duration-300 ${
              sidebarOpen ? "w-72" : "w-14 2xl:w-16"
            }`}
          >
            <div className="flex flex-col flex-grow w-full bg-sidebar-bg">
              <nav className="flex flex-col flex-grow justify-between">
                <button
                  className="flex items-center justify-between p-4 h-14 2xl:h-16 bg-sidebar-bg-logo"
                  onClick={toggleNavbar}
                >
                  <Transition
                    show={sidebarOpen}
                    enter="transition-width duration-1000 linear"
                    enterFrom="width-0"
                    enterTo="w-40"
                    entered="w-40"
                    leave="transition-width duration-1000 linear"
                    leaveFrom="w-40"
                    leaveTo="w-0"
                  >
                    <ANMBLogo />
                  </Transition>
                  <ChevronDoubleRightIcon
                    className={`flex-shrink-0 text-white w-6 h-6 2xl:w-8 2xl:h-8 logo-arrow ${
                      sidebarOpen ? "logo-arrow--rotate" : ""
                    }`}
                  />
                </button>
                <div className="sidebar__content px-2 overflow-x-hidden overflow-y-auto">
                  <NavLink
                    to={dashboard.href}
                    // Close the sidebar after clicking the element
                    // onClick={() => {
                    // if (sidebarOpen) setSidebarOpen(false);
                    // }}
                    className={({ isActive }) => {
                      let val =
                        "group flex px-2 py-2 text-sm leading-6 font-medium rounded-md my-2 2xl:mt-8 sidebar__item";
                      if (isActive && sidebarOpen) {
                        val = val.concat(
                          " text-white sidebar__item--open--active"
                        );
                      } else if (isActive && !sidebarOpen) {
                        val = val.concat(" text-white sidebar__item--active");
                      } else if (!isActive && sidebarOpen) {
                        val = val.concat(
                          " text-sidebar-color hover:text-white sidebar__item--open"
                        );
                      } else if (!isActive && !sidebarOpen) {
                        val = val.concat(
                          " text-sidebar-color sidebar__item--idle"
                        );
                      }
                      return val;
                    }}
                    aria-current={
                      dashboard.href === window.location.pathname
                        ? "page"
                        : undefined
                    }
                  >
                    <dashboard.icon
                      className="flex-shrink-0 w-6 2xl:w-8 h-6 2xl:h-8"
                      aria-hidden="true"
                    />
                    <Transition
                      show={sidebarOpen}
                      enter="transition-opacity duration-300 linear"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      entered="opacity-100"
                      leave="transition-opacity duration-300 linear"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                      className="flex items-center ml-4 2xl:text-lg"
                    >
                      {dashboard.name}
                    </Transition>
                  </NavLink>
                  <div className="space-y-2">
                    {navigation.map((item) => (
                      <NavLink
                        // Close the sidebar after clicking the element
                        // onClick={() => {
                        // if (sidebarOpen) setSidebarOpen(false);
                        // }}
                        key={item.name}
                        to={item.href}
                        className={({ isActive }) => {
                          let val =
                            "group flex px-2 py-2 text-sm leading-6 font-medium rounded-md sidebar__item";
                          if (isActive && sidebarOpen) {
                            val = val.concat(
                              " text-white sidebar__item--open--active"
                            );
                          } else if (isActive && !sidebarOpen) {
                            val = val.concat(
                              " text-white sidebar__item--active"
                            );
                          } else if (!isActive && sidebarOpen) {
                            val = val.concat(
                              " text-sidebar-color hover:text-white sidebar__item--open"
                            );
                          } else if (!isActive && !sidebarOpen) {
                            val = val.concat(
                              " text-sidebar-color sidebar__item--idle"
                            );
                          }
                          return val;
                        }}
                        aria-current={
                          item.href === window.location.pathname
                            ? "page"
                            : undefined
                        }
                      >
                        <item.icon
                          className="flex-shrink-0 w-6 2xl:w-8 h-6 2xl:h-8"
                          aria-hidden="true"
                        />
                        <Transition
                          show={sidebarOpen}
                          enter="transition-opacity duration-300 linear"
                          enterFrom="opacity-0"
                          enterTo="opacity-100"
                          entered="opacity-100"
                          leave="transition-opacity duration-300 linear"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                          className="flex items-center ml-4 whitespace-nowrap 2xl:text-lg"
                        >
                          {item.name}
                        </Transition>
                      </NavLink>
                    ))}
                    {canAccess(administration) ? (
                      <div className="pb-2 space-y-2">
                        <button
                          type="button"
                          onClick={() => {
                            if (!sidebarOpen) {
                              setSidebarOpen(true);
                              setRichNavigationOpen(true);
                            } else {
                              setRichNavigationOpen(!richNavigationOpen);
                            }
                          }}
                          className={(function () {
                            let val =
                              "group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md sidebar__item w-full";
                            if (sidebarOpen) {
                              val = val.concat(
                                " text-sidebar-color hover:text-white sidebar__item--open"
                              );
                            } else {
                              val = val.concat(
                                " text-sidebar-color sidebar__item--idle"
                              );
                            }
                            return val;
                          })()}
                        >
                          <AdjustmentsIcon
                            className="flex-shrink-0 w-6 2xl:w-8 h-6 2xl:h-8"
                            aria-hidden="true"
                          />
                          <Transition
                            show={sidebarOpen}
                            enter="transition-opacity duration-300 linear"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            entered="opacity-100"
                            leave="transition-opacity duration-300 linear"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                            className="flex items-center ml-4 whitespace-nowrap 2xl:text-lg"
                          >
                            {administration.name}
                          </Transition>
                        </button>
                        {richNavigationOpen ? (
                          <div className={`space-y-2`}>
                            {richNavigation.map((item) => (
                              <NavLink
                                // Close the sidebar after clicking the element
                                // onClick={() => {
                                //   if (sidebarOpen) setSidebarOpen(false);
                                // }}
                                key={item.name}
                                to={item.href}
                                className={({ isActive }) => {
                                  let val =
                                    "group flex px-2 py-2 text-sm leading-6 font-medium rounded-md sidebar__item";
                                  if (isActive && sidebarOpen) {
                                    val = val.concat(
                                      " text-white sidebar__item--open--active"
                                    );
                                  } else if (isActive && !sidebarOpen) {
                                    val = val.concat(" hidden");
                                  } else if (!isActive && sidebarOpen) {
                                    val = val.concat(
                                      " text-sidebar-color hover:text-white sidebar__item--open"
                                    );
                                  } else if (!isActive && !sidebarOpen) {
                                    val = val.concat(" hidden");
                                  }
                                  return val;
                                }}
                                aria-current={
                                  item.href === window.location.pathname
                                    ? "page"
                                    : undefined
                                }
                              >
                                <Transition
                                  show={sidebarOpen}
                                  enter="transition-opacity duration-300 linear"
                                  enterFrom="opacity-0"
                                  enterTo="opacity-100"
                                  entered="opacity-100"
                                  leave="transition-opacity duration-300 linear"
                                  leaveFrom="opacity-100"
                                  leaveTo="opacity-0"
                                  className="flex items-center pl-10 2xl:pl-12 w-full whitespace-nowrap 2xl:text-lg"
                                >
                                  <span className="truncate">{item.name}</span>
                                </Transition>
                              </NavLink>
                            ))}
                          </div>
                        ) : null}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="no-wrap flex items-center justify-start 2xl:mt-auto p-4 w-full h-16 2xl:h-20 bg-sidebar-bg-profile">
                  <div className="flex flex-shrink-0 items-center">
                    <UserPopup />
                  </div>
                  <Transition
                    show={sidebarOpen}
                    enter="transition-opacity duration-300 linear"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    entered="opacity-100"
                    leave="transition-opacity duration-300 linear"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    className="ml-10 2xl:ml-12"
                  >
                    <p className="text-white text-xs font-medium truncate">
                      {loggedInUser.user?.SURNAME} {loggedInUser.user?.NAME}
                    </p>
                  </Transition>
                </div>
              </nav>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
