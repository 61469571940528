import React, { useMemo, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Table from "shared/components/Table/Table";
import { useAppDispatch } from "store/storeHooks";
import { getRoleColumns } from "../../api/PermissionsManagement/roles.api";
import { Column, useFlexLayout, useTable } from "react-table";
import { ExclamationIcon, PlusIcon } from "@heroicons/react/solid";
import { useTranslation } from "react-i18next";
import Modal from "shared/components/UI/Modal";
import {
  deleteRoleThunk,
  getRolesThunk,
  selectPermissionsManagementLoading,
  selectRoles,
} from "store/permissionsManagement/permissionsManagementReducer";
import { Role } from "shared/interfaces/Role.interface";
import Spinner from "shared/components/UI/Spinner";
import Overlay from "shared/components/UI/Overlay";
import TablePopup, { PortalType } from "shared/components/UI/TablePopup";
import {
  TrashIcon,
  PencilIcon,
  BanIcon,
  DotsVerticalIcon,
} from "@heroicons/react/outline";
import { selectLoggedInUser } from "store/authStore/authReducer";
import { toast } from "react-toastify";
import Message from "shared/components/UI/Message";
import { selectTranslationLanguage } from "store/translationStore/translationReducer";

const Roles: () => JSX.Element = () => {
  const lang = selectTranslationLanguage();
  const { t } = useTranslation("common");
  const [openModal, setOpenModal] = useState(false);
  const [activeRole, setActiveRole] = useState(0);
  const dispatch = useAppDispatch();
  const roles = selectRoles();
  const loading = selectPermissionsManagementLoading();
  const navigate = useNavigate();
  const [portal, setPortal] = useState<PortalType<Role>>({
    open: false,
    top: 0,
    left: 0,
    entity: null,
  });
  const loggedInUser = selectLoggedInUser();

  useEffect(() => {
    dispatch(getRolesThunk());
  }, []);

  const handleModal = (thisRoleID: number) => {
    setActiveRole(thisRoleID);
    setOpenModal(true);
  };

  const deleteRoleHandler = (thisRole: number) => {
    dispatch(deleteRoleThunk(thisRole)).then((res: any) => {
      if (res.meta.requestStatus === "rejected") {
        toast.error(
          Message({
            action: "delete",
            entity: "role",
            error: t("genericError"),
            lang,
            gender: "male",
          })
        );
        return;
      }
      dispatch(getRolesThunk());
      toast.success(
        Message({
          action: "delete",
          entity: "role",
          lang,
          gender: "male",
        })
      );
    });
    setOpenModal(false);
  };

  const createRoleHandler = () => {
    navigate("/roles/create");
  };

  const columns: Array<Column<Record<string, string | number | JSX.Element>>> =
    useMemo(() => getRoleColumns(lang), [lang]);

  const data = useMemo(
    () =>
      roles.map((role: Role, i) => ({
        col1: role.ROLE_NAME,
        col2: <div className="truncate">{role.DESCRIPTION}</div>,
        col3: role.COUNT,
        col4: displayActions(role, i),
      })),
    [roles, lang]
  );

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useFlexLayout
  );

  function displayActions(role: Role, i: number) {
    const canUpdate = canAccess();
    const canDelete = canAccess();
    if (!canUpdate && !canDelete) {
      return (
        <div className="flex items-center h-full">
          <BanIcon className="w-5 h-5" />
        </div>
      );
    }
    return (
      <div className="relative flex items-center h-full">
        <button
          id={`portal-button-${i}`}
          type="button"
          onClick={() => {
            return setPortal((state) => {
              const thisButton = document.getElementById(`portal-button-${i}`);
              const position = thisButton?.getBoundingClientRect();
              return {
                open: !state.open,
                top: Number(position?.bottom),
                left: Number(position?.left),
                entity: role,
              };
            });
          }}
        >
          <DotsVerticalIcon className="w-5 h-5" />
        </button>
      </div>
    );
  }

  function canAccess(): boolean {
    if (loggedInUser.permissions["*"]) return true;
    else if (loggedInUser.permissions["permissionsManagement"]["ENABLED"])
      return true;
    else return false;
  }

  function portalContent() {
    if (portal.entity === null) return;
    const canUpdate = canAccess();
    const canDelete = canAccess();
    return (
      <div
        className="fixed z-50 flex flex-col gap-2 p-2 bg-white rounded-sm shadow-md"
        style={{
          top: portal.top,
          left: portal.left,
        }}
      >
        {canUpdate ? (
          <Link
            to={`/roles/${portal.entity.ID}`}
            className="flex gap-2 items-center text-table-edit"
          >
            <PencilIcon className="w-5 h-5" />
            {lang === "en" ? "Edit" : "Modificare"}
          </Link>
        ) : null}
        {canDelete ? (
          <button
            onClick={() => handleModal(portal.entity?.ID as number)}
            className="flex gap-2 items-center text-users-delete"
          >
            <TrashIcon className="w-5 h-5" />
            {lang === "en" ? "Delete" : "Eliminare"}
          </button>
        ) : null}
      </div>
    );
  }

  return (
    <Overlay spinner={<Spinner />} active={loading}>
      <div className="flex flex-col gap-5 p-10 h-full">
        <div className="text-3xl font-bold">{t("RolesCRUD.rolesHeader")}</div>
        <div className="flex gap-2 justify-end w-full text-users-color overflow-hidden">
          <button
            className="flex items-center justify-center px-4 py-2 text-white whitespace-nowrap text-sm bg-global-createEntity"
            onClick={createRoleHandler}
          >
            <PlusIcon className="w-4 h-4" />
            <span className="ml-2">{t("RolesCRUD.createRoleButton")}</span>
          </button>
        </div>
        <Table {...tableInstance} className="roles__table" />
        <TablePopup
          isOpen={portal.open}
          close={() => setPortal((state) => ({ ...state, open: false }))}
        >
          {portalContent()}
        </TablePopup>
        <Modal
          openStatus={openModal}
          setOpen={setOpenModal}
          className=""
          icon={
            <ExclamationIcon
              className="w-6 h-6 text-red-600 bg-transparent"
              aria-hidden="true"
            />
          }
          header={<p>{t("RolesCRUD.deleteRoleModalHeader")}</p>}
          title={<p>{t("RolesCRUD.deleteRoleModalMessage")}</p>}
          footer={
            <div className="mt-5 sm:flex sm:flex-row-reverse sm:mt-4">
              <button
                type="button"
                className="inline-flex justify-center px-4 py-1 w-full text-white text-base font-medium bg-red-600 hover:bg-red-700 border border-transparent focus:outline-none shadow-sm focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                onClick={() => deleteRoleHandler(activeRole)}
              >
                {t("RolesCRUD.deleteButton")}
              </button>
              <button
                type="button"
                className="inline-flex justify-center mt-3 px-4 py-1 w-full hover:text-gray-500 text-gray-700 text-base font-medium bg-white border border-gray-300 focus:outline-none shadow-sm focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                onClick={() => setOpenModal(false)}
              >
                {t("RolesCRUD.cancelButton")}
              </button>
            </div>
          }
        />
      </div>
    </Overlay>
  );
};

export default Roles;
