import React from "react";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import {
  HeaderGroup,
  TableInstance,
  ColumnInstance,
  Row,
  Cell,
} from "react-table";

function Table(props: TableInstance): JSX.Element {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    rows,
    prepareRow,
    className,
    centerColumns,
  } = props;

  function renderRow(row: Row) {
    prepareRow(row);
    const { key, ...restRowProps } = row.getRowProps();
    return (
      <tr key={key} {...restRowProps}>
        {row.cells.map((cell: Cell) => {
          const { key, ...restCellProps } = cell.getCellProps();
          return (
            <td
              key={key}
              {...restCellProps}
              className="px-4 py-4 text-left text-table-color whitespace-nowrap text-sm font-normal"
            >
              {cell.render("Cell")}
            </td>
          );
        })}
      </tr>
    );
  }

  return (
    <div className="relative overflow-x-auto">
      <table
        {...getTableProps()}
        className={`border border-table-border w-full`}
      >
        <thead className="sticky z-10 left-0 top-0 bg-gray-100">
          {headerGroups.map((headerGroup: HeaderGroup) => {
            const { key, ...restHeaderGroupProps } =
              headerGroup.getHeaderGroupProps();
            return (
              <tr key={key} {...restHeaderGroupProps}>
                {headerGroup.headers.map((column: ColumnInstance) => {
                  const { key, ...restHeaderProps } = column.getHeaderProps();
                  let sortByToggleProps;
                  if (column.getSortByToggleProps) {
                    sortByToggleProps = column.getSortByToggleProps();
                  } else {
                    sortByToggleProps = [];
                  }
                  return (
                    <th
                      key={key}
                      {...sortByToggleProps}
                      {...restHeaderProps}
                      className="sticky top-0 px-4 py-2 text-left text-table-color whitespace-nowrap text-sm font-medium tracking-wider border-0 border-black uppercase"
                    >
                      <div
                        className={`flex items-center select-none ${
                          column.canSort ? "cursor-pointer" : ""
                        }`}
                      >
                        {column.render("Header")}
                        <span className="inline-block pl-2">
                          {column.canSort ? (
                            column.isSorted ? (
                              column.isSortedDesc ? (
                                <AiFillCaretDown />
                              ) : (
                                <AiFillCaretUp />
                              )
                            ) : null
                          ) : null}
                        </span>
                      </div>
                    </th>
                  );
                })}
              </tr>
            );
          })}
        </thead>
        <tbody
          {...getTableBodyProps()}
          className={`bg-white divide-y divide-table-border ${className} block`}
        >
          {rows
            ? rows.map((row: Row) => renderRow(row))
            : page.map((row: Row) => renderRow(row))}
        </tbody>
      </table>
    </div>
  );
}

export default Table;
