import {
  BanIcon,
  DotsVerticalIcon,
  ExclamationIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/outline";
import {
  exportOrder,
  exportOrders,
  getOrder,
  getOrders as getOrdersUtil,
  getOrdersColumns,
} from "api/Orders/orders.api";
import { GetOrdersFilters } from "api/Orders/urls";
import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import {
  Column,
  useFlexLayout,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { toast } from "react-toastify";
import { PermissionDomain } from "shared/interfaces/Permission.interface";
import { RolePermissionCrudAction } from "shared/interfaces/RolePermission.interface";
import { selectLoggedInUser } from "store/authStore/authReducer";
import {
  deleteOrderThunk,
  getOrdersThunk,
  selectOrders,
} from "store/ordersStore/ordersReducer";
import { useAppDispatch } from "store/storeHooks";
import { selectTranslationLanguage } from "store/translationStore/translationReducer";
import Pagination from "../Table/Pagination";
import Table from "../Table/Table";
import Message, { StrMessage } from "../UI/Message";
import Modal from "../UI/Modal";
import TablePopup, { PortalType } from "../UI/TablePopup";
import { BsFilePdf } from "react-icons/bs";
import { EnchancedOrder } from "shared/interfaces/Orders.interface";
import { format } from "date-fns";
import { b64toBlob, Round } from "utils/utils";
import saveAs from "file-saver";
import { generateOrderPdf } from "pages/Orders/generateOrderPdf";
import { generateOrdersPdfs } from "pages/Orders/generateOrdersPdfs";

export default function Orders() {
  const { id } = useParams();
  const lang = selectTranslationLanguage();
  const { t } = useTranslation("common");

  const dispatch = useAppDispatch();
  const orders = selectOrders();
  const [allSelected, setAllSelected] = useState(false);
  const [excludeOrders, setExcludeOrders] = useState<string[]>([]);
  const [includeOrders, setIncludeOrders] = useState<string[]>([]);

  const [exportLoading, setExportLoading] = useState(false);
  const form = useForm<GetOrdersFilters>({
    defaultValues: {
      include: "billingAddress,shippingAddress,products,payment,user",
      pageSize: 10,
      pageIndex: 0,
      searchString: "",
    },
  });

  function constructGetOrderFilters(pageIndex: number): GetOrdersFilters {
    return {
      customerId: id as string,
      include: form.getValues("include"),
      pageIndex: pageIndex,
      pageSize: form.getValues("pageSize"),
      sortCol: sortBy[0] ? sortBy[0].id : "",
      sortOrder: sortBy[0] ? (sortBy[0].desc ? "DESC" : "ASC") : "",
    };
  }

  function getOrders(pageIndex: number) {
    return dispatch(getOrdersThunk(constructGetOrderFilters(pageIndex))).then(
      (res) => {
        if (res.meta.requestStatus === "rejected") {
          toast.error(
            Message({
              action: "read",
              entity: t("orders.orders"),
              error: t("genericError"),
              lang,
            })
          );
        }
        return res;
      }
    );
  }

  const loggedInUser = selectLoggedInUser();
  function canAccess(
    domain: PermissionDomain,
    action: RolePermissionCrudAction,
    order?: EnchancedOrder
  ): boolean {
    if (loggedInUser.permissions["*"]) {
      return true;
    } else {
      switch (loggedInUser.permissions[domain][action]) {
        case "NONE": {
          return false;
        }
        case "OWN": {
          if (action === "DELETE") return false;
          if (action === "CREATE") return true;
          if (order && order.customer_id === loggedInUser.user?.PK_USER)
            return true;
          return false;
        }
        case "ANY": {
          if (action === "DELETE") return false;
          return true;
        }
        default: {
          return false;
        }
      }
    }
  }

  const [portal, setPortal] = useState<PortalType<EnchancedOrder>>({
    open: false,
    top: 0,
    left: 0,
    entity: null,
  });
  function displayActions(order: EnchancedOrder, i: number) {
    const canRead = canAccess("s", "READ", order);
    const canUpdate = canAccess("s", "UPDATE", order);
    const canDelete = canAccess("s", "DELETE", order);
    if (!canRead && !canUpdate && !canDelete) {
      return (
        <div className="flex items-center h-full">
          <BanIcon className="w-5 h-5" />
        </div>
      );
    }
    return (
      <div className="relative flex items-center h-full">
        <button
          id={`portal-button-${i}`}
          type="button"
          onClick={() => {
            return setPortal((state) => {
              const thisButton = document.getElementById(`portal-button-${i}`);
              const position = thisButton?.getBoundingClientRect();
              return {
                open: !state.open,
                top: Number(position?.bottom),
                left: Number(
                  window.innerWidth - Number(position?.x) <= 150
                    ? Number(position?.left) - 150
                    : Number(position?.left)
                ),
                entity: order,
              };
            });
          }}
        >
          <DotsVerticalIcon className="w-5 h-5" />
        </button>
      </div>
    );
  }

  const [orderToDelete, setOrderToDelete] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const handleDeleteModal = (orderToDelete: string) => {
    setOrderToDelete(orderToDelete);
    setOpenDeleteModal(true);
  };

  function portalContent() {
    if (portal.entity === null) return null;
    const canRead = canAccess("s", "READ", portal.entity);
    const canUpdate = canAccess("s", "UPDATE", portal.entity);
    const canDelete = canAccess("s", "DELETE", portal.entity);
    return (
      <div
        className="fixed z-50 flex flex-col gap-2 p-2 bg-white rounded-sm shadow-md"
        style={{
          width: "150px",
          top: portal.top,
          left: portal.left,
        }}
      >
        {canRead ? (
          <Link
            to={`/orders/${portal.entity.id}?user=${id as string}`}
            className="flex gap-2 items-center text-table-edit"
          >
            <PencilIcon className="w-5 h-5" />
            {canUpdate ? t("crud.update") : t("crud.read")}
          </Link>
        ) : null}
        {canDelete ? (
          <button
            onClick={() => handleDeleteModal(portal.entity?.id as string)}
            className="flex gap-2 items-center text-users-delete"
          >
            <TrashIcon className="w-5 h-5" />
            {t("crud.delete")}
          </button>
        ) : null}
        {canRead && portal.entity.status !== "cart" ? (
          <button
            disabled={exportLoading}
            onClick={() => {
              setExportLoading(true);
              const exportOrderPromise = getOrder(String(portal.entity?.id))
                .then((res) => generateOrderPdf(res.data.order))
                .catch((err: any) => {
                  if (err.response?.data?.errors?.[0]?.message) {
                    toast.error(err.response?.data?.errors?.[0]?.message);
                  } else {
                    toast.error(err.response?.data?.message);
                  }
                })
                .finally(() => setExportLoading(false));
              toast.promise(exportOrderPromise, {
                pending: t("order.exporting"),
                success: t("order.exportFinished"),
                error: StrMessage({
                  action: "read",
                  entity: t("orders.order"),
                  error: t("genericError"),
                  lang,
                }),
              });
            }}
            style={{ color: "#8f130e" }}
            className="flex gap-2 items-center"
          >
            <BsFilePdf className="w-5 h-5" />
            {lang === "en" ? "Export" : "Esportare"}
          </button>
        ) : null}
      </div>
    );
  }

  function showExtraButtons() {
    if (allSelected && excludeOrders.length < orders.data.total) {
      return true;
    }
    if (!allSelected && includeOrders.length) {
      return true;
    }
    return false;
  }

  const columns: Array<Column<Record<string, any>>> = useMemo(
    () =>
      getOrdersColumns(
        {
          allSelected,
          setAllSelected,
          includeOrders,
          setIncludeOrders,
          excludeOrders,
          setExcludeOrders,
        },
        lang
      ),
    [
      lang,
      allSelected,
      setAllSelected,
      includeOrders,
      setIncludeOrders,
      excludeOrders,
      setExcludeOrders,
    ]
  );

  const data: Array<any> = useMemo(
    () =>
      orders.data.data.map((order, i) => ({
        checkbox: (
          <div className="flex items-center h-full">
            <input
              type="checkbox"
              checked={(function () {
                if (allSelected) {
                  const index = excludeOrders.indexOf(order.id);
                  if (index === -1) {
                    return true;
                  } else {
                    return false;
                  }
                } else {
                  const index = includeOrders.indexOf(order.id);
                  if (index === -1) {
                    return false;
                  } else {
                    return true;
                  }
                }
              })()}
              onClick={() => {
                if (allSelected) {
                  const index = excludeOrders.indexOf(order.id);
                  if (index !== -1) {
                    setExcludeOrders((excludedOrders) => {
                      const temp = [...excludedOrders];
                      temp.splice(index, 1);
                      return temp;
                    });
                  } else {
                    setExcludeOrders((excludedOrders) => [
                      ...excludedOrders,
                      order.id,
                    ]);
                  }
                } else {
                  const index = includeOrders.indexOf(order.id);
                  if (index !== -1) {
                    setIncludeOrders((includedOrders) => {
                      const temp = [...includedOrders];
                      temp.splice(index, 1);
                      return temp;
                    });
                  } else {
                    setIncludeOrders((includedOrders) => [
                      ...includedOrders,
                      order.id,
                    ]);
                  }
                }
              }}
              className="text-primary"
            />
          </div>
        ),
        col1: (
          <div className="flex items-center h-full">
            {format(new Date(order.updated_at.slice(0, -1)), "dd/MM/yyyy")}
          </div>
        ),
        col2: (
          <div className="flex items-center h-full">
            <span className="truncate">{order.payment?.yearly_id}</span>
          </div>
        ),
        col3: (
          <div className="flex items-center h-full">
            <span className="truncate">
              {order.user.name} {order.user.surname}
            </span>
          </div>
        ),
        col4: (
          <div className="flex items-center h-full">
            {order.products.map((product) => (
              <span key={product.id} className="truncate">
                {product.class}
              </span>
            ))}
          </div>
        ),
        col5: (
          <div className="flex items-center h-full">
            <span className="truncate">{order.status}</span>
          </div>
        ),
        col6: (
          <div className="flex items-center h-full">
            <span className="truncate">{Round(order.total_price)}</span>
          </div>
        ),
        col7: (
          <div className="flex items-center h-full">
            <span className="truncate">{order.payment?.payment_type}</span>
          </div>
        ),
        col8: (
          <div className="flex items-center h-full">
            <span className="truncate">
              {order.origin === "CMS"
                ? "CMS"
                : lang === "en"
                ? "Website"
                : "Sito web"}
            </span>
          </div>
        ),
        col9: displayActions(order, i),
      })),
    [
      orders.data,
      lang,
      allSelected,
      setAllSelected,
      includeOrders,
      setIncludeOrders,
      excludeOrders,
      setExcludeOrders,
    ]
  );

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: form.getValues("pageSize") },
      pageCount: Math.ceil(orders.data.total / form.getValues("pageSize")),
      manualSortBy: true,
      manualPagination: true,
    },
    useSortBy,
    usePagination,
    useFlexLayout
  );

  const {
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, sortBy },
  } = tableInstance;

  const dispatchNextPage = () =>
    canNextPage
      ? getOrders(pageIndex + 1).then((res) =>
          res.meta.requestStatus === "fulfilled" ? nextPage() : undefined
        )
      : undefined;

  const dispatchPreviousPage = () =>
    canPreviousPage
      ? getOrders(pageIndex - 1).then((res) =>
          res.meta.requestStatus === "fulfilled" ? previousPage() : undefined
        )
      : undefined;

  const dispatchGotoPage = (pageIndex: number) =>
    getOrders(pageIndex).then((res) =>
      res.meta.requestStatus === "fulfilled" ? gotoPage(pageIndex) : undefined
    );

  useEffect(() => {
    dispatch(getOrdersThunk(constructGetOrderFilters(0)));
  }, [sortBy]);

  const handleDeleteOrder = (orderToDelete: string) => {
    dispatch(deleteOrderThunk(orderToDelete))
      .then((res) => {
        if (res.meta.requestStatus === "rejected") {
          toast.error(
            Message({
              action: "delete",
              entity: t("order.order"),
              error: t("genericError"),
              lang,
            })
          );
        } else {
          toast.success(
            Message({
              action: "delete",
              entity: t("order.order"),
              lang,
            })
          );
        }
      })
      .then(() => getOrders(0));
    setOpenDeleteModal(false);
  };

  return (
    <div className="flex flex-col gap-5 h-full">
      <div className="flex gap-5 justify-end w-full">
        {showExtraButtons() && canAccess("s", "READ") ? (
          <button
            type="button"
            className="px-4 py-2 text-white bg-primary border border-primary rounded-sm"
            disabled={exportLoading}
            onClick={() => {
              setExportLoading(true);
              let exportOrdersPromise;
              setExportLoading(true);
              if (allSelected) {
                exportOrdersPromise = exportOrders({
                  filters: {
                    customerId: id,
                  },
                  exclude: excludeOrders,
                });
              } else {
                exportOrdersPromise = exportOrders({
                  filters: {
                    customerId: id,
                  },
                  include: includeOrders,
                });
              }
              exportOrdersPromise
                .then((res) => {
                  generateOrdersPdfs(res.data.orders);
                })
                .catch((err: any) => {
                  if (err.response?.data?.errors?.[0]?.message) {
                    toast.error(err.response?.data?.errors?.[0]?.message);
                  } else {
                    toast.error(err.response?.data?.message);
                  }
                })
                .finally(() => setExportLoading(false));
              toast.promise(exportOrdersPromise, {
                pending: t("order.exporting"),
                success: t("order.exportFinished"),
                error: StrMessage({
                  action: "read",
                  entity: t("orders.orders"),
                  error: t("genericError"),
                  lang,
                }),
              });
            }}
          >
            {t("order.export")}
          </button>
        ) : null}
        <Link
          to={`/orders/create?user=${id as string}`}
          className="block px-4 py-2 text-white text-lg bg-users-create"
        >
          {t("order.createOrder")}
        </Link>
      </div>
      <Table {...tableInstance} />
      <Pagination
        rowsCount={orders.data.total}
        pageIndex={pageIndex}
        pageSize={form.getValues("pageSize")}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        pageOptions={pageOptions}
        pageCount={pageCount}
        gotoPage={dispatchGotoPage}
        nextPage={dispatchNextPage}
        previousPage={dispatchPreviousPage}
        setPageSize={setPageSize}
      />
      <TablePopup
        isOpen={portal.open}
        close={() => setPortal((state) => ({ ...state, open: false }))}
      >
        {portalContent()}
      </TablePopup>
      <Modal
        openStatus={openDeleteModal}
        setOpen={setOpenDeleteModal}
        icon={
          <ExclamationIcon
            className="w-6 h-6 text-red-600 bg-transparent"
            aria-hidden="true"
          />
        }
        header={
          <p>
            {t("deleteModal.delete")} {t("order.order")}
          </p>
        }
        title={
          <p>
            {t("deleteModal.sure")} {t("order.order")}?{" "}
            {t("deleteModal.undone")}
          </p>
        }
        footer={
          <div className="flex justify-end mt-5 sm:mt-4">
            <button
              type="button"
              className="inline-flex justify-center mt-3 px-4 py-1 w-full text-black text-base font-medium bg-white border border-black focus:outline-none shadow-sm focus:ring-0 sm:mt-0 sm:w-auto"
              onClick={() => setOpenDeleteModal(false)}
            >
              {t("deleteModal.cancel")}
            </button>
            <button
              type="button"
              className="inline-flex justify-center px-4 py-1 w-full text-white text-base font-medium bg-red-600 border border-transparent focus:outline-none shadow-sm focus:ring-0 sm:ml-3 sm:w-auto"
              onClick={() => handleDeleteOrder(orderToDelete)}
            >
              {t("deleteModal.delete")}
            </button>
          </div>
        }
      />
    </div>
  );
}
