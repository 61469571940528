import React, { useEffect, useState, useMemo } from "react";
import { useForm, Controller, FormProvider } from "react-hook-form";
import Overlay from "shared/components/UI/Overlay";
import useUser from "shared/hooks/useUser";
import Spinner from "shared/components/UI/Spinner";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { selectLookups } from "store/lookups/lookupsReducer";
import DatePicker from "react-datepicker";
import GoBack from "shared/components/UI/GoBack";
import {
  checkFiscal,
  getInputStyles,
  getReactSelectStyles,
  constructLocationRestrictions,
} from "utils/utils";
import DisplayInputError from "shared/components/UI/DisplayInputError";
import InformationTab from "shared/components/Users/InformationTab";
import Select from "react-select";
import { getGenderOptions } from "shared/interfaces/Gender.interface";
import LocationSearch from "shared/components/UI/LocationSearch";
import { CreateUser } from "./types";
import Message from "shared/components/UI/Message";
import { constructCreateUser } from "utils/utils";
import SimpleImageDropzone from "shared/components/UI/SimpleImageDropzone";
import { selectTranslationLanguage } from "store/translationStore/translationReducer";
import {
  getUserRanksThunk,
  selectUserRanks,
} from "store/userRanksStore/userRanksReducer";
import { useAppDispatch } from "store/storeHooks";
import { useNavigate } from "react-router";
import { selectLoggedInUser } from "store/authStore/authReducer";
import CropImage from "shared/components/UI/CropImage";
import RegionRestrictions, {
  regionRestrictionsOptions,
} from "./RegionRestrictions";
import { regionRestrictionsSelect } from "shared/interfaces/User.interface";

const CreateUserPage: () => JSX.Element = () => {
  const navigate = useNavigate();
  const lang = selectTranslationLanguage();
  const { t } = useTranslation("common");
  const dispatch = useAppDispatch();
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState<boolean>(false);
  const { createUser } = useUser();
  const lookups = selectLookups();
  const [rolesOptions, setRolesOptions] = useState<any>([]);
  const [regionsOptions, setRegionsOptions] = useState<any>([]);
  const [provincesOptions, setProvincesOptions] = useState<any>([]);
  const [editImageOn, setEditImageOn] = useState(false);
  useEffect(() => {
    const rolesOptions = lookups.lookupList.roles.map((role) => ({
      value: role.ID,
      label: role.ROLE_NAME,
    }));
    setRolesOptions(rolesOptions);
  }, [lookups.lookupList.roles]);
  useEffect(() => {
    const regionsMap = lookups.lookupList.regions.map((region) => ({
      value: region.PK_REGION,
      label: region.PK_REGION,
    }));
    setRegionsOptions(regionsMap);
  }, [lookups.lookupList.regions]);
  useEffect(() => {
    const provinceOptions = lookups.lookupList.provinces.map((province) => ({
      value: province.PK_PROVINCE,
      label: province.NAME,
    }));
    setProvincesOptions(provinceOptions);
  }, [lookups.lookupList.provinces]);

  const loggedInUser = selectLoggedInUser();

  const [ranksOptions, setRankOptions] = useState([]);
  const userRanks = selectUserRanks();
  useEffect(() => {
    if (userRanks.data.userRanks)
      setRankOptions(
        userRanks.data.userRanks?.map((r: any) => ({
          value: r.id,
          label: lang === "en" ? r.rank_eng : r.rank_it,
        })) as any
      );
  }, [userRanks.data.userRanks]);

  useEffect(() => {
    dispatch(getUserRanksThunk({ pageSize: 99 })).then((res) => {
      if (res.meta.requestStatus === "rejected") {
        toast.error(
          Message({
            action: "read",
            entity: t("user.rank"),
            error: t("genericError"),
            lang,
            gender: "male",
          })
        );
      }
      return res;
    });
  }, []);

  const [locationRestrictions, setLocationRestrictions] = useState<any>([]);

  const form = useForm<CreateUser>({
    mode: "onChange",
    defaultValues: {
      fileType: "profile_pic",
      username: "",
      email: undefined,
      firstName: "",
      title: "",
      role: undefined,
      lastName: "",
      rank: undefined,
      promotionBody: "",
      birthPlace: "",
      description: "",
      school: "",
      titles: "",
      birthDate: null,
      fiscaleCode: "",
      password: "",
      note: "",
      posta: "1",
      votePrivilege: "1",
      gender: undefined,
      visibleSite: "0",
      contactInfo: [
        { type: "PHONE", detail: "" },
        { type: "HOME", detail: "" },
      ],
      addressInfo: [
        {
          roadName: "",
          homeNumber: "",
          type: "HOME",
          city: "",
          postalCode: "",
          region: undefined,
          province: undefined,
          country: "",
          lon: "",
          lat: "",
        },
        {
          roadName: "",
          homeNumber: "",
          type: "OFFICE",
          city: "",
          postalCode: "",
          region: undefined,
          province: undefined,
          country: "",
          lon: "",
          lat: "",
        },
        {
          roadName: "",
          homeNumber: "",
          type: "ALTERNATIVE",
          city: "",
          postalCode: "",
          region: undefined,
          province: undefined,
          country: "",
          lon: "",
          lat: "",
        },
      ],
      profilePic: null,
      regionRestrictions: regionRestrictionsOptions,
    },
  });

  const {
    register,
    handleSubmit,
    watch,
    setError,
    control,
    formState: { errors },
    setValue,
    getValues,
    clearErrors,
    trigger,
  } = form;

  const increaseStep = async () => {
    switch (step) {
      case 1: {
        let canContinue = await trigger([
          "username",
          "email",
          "firstName",
          "birthDate",
          "lastName",
          "profilePic",
        ]);
        if (getValues("fiscaleCode") !== "") {
          canContinue = canContinue && (await trigger("fiscaleCode"));
        }
        if (!canContinue) {
          return;
        }
        break;
      }
      case 2: {
        if (step == 2) {
          let canContinue = true;
          if (getValues("titles") !== "") {
            canContinue = canContinue && (await trigger("titles"));
          }
          if (getValues("description") !== "") {
            canContinue = canContinue && (await trigger("description"));
          }
          if (getValues("school") !== "") {
            canContinue = canContinue && (await trigger("school"));
          }
          if (!canContinue) {
            return;
          }
        }
        break;
      }
      case 3: {
        if (step == 3) {
          const canContinue = await trigger([
            `addressInfo.${0}.roadName`,
            `addressInfo.${0}.homeNumber`,
            `addressInfo.${0}.postalCode`,
            `addressInfo.${0}.city`,
            `addressInfo.${0}.region`,
            `addressInfo.${0}.province`,
            `addressInfo.${0}.country`,
          ]);
          if (!canContinue) return;
        }
        break;
      }
      default: {
        return;
      }
    }
    clearErrors();
    setStep((step) => step + 1);
  };

  const decreaseStep = () => {
    setStep((step) => step - 1);
  };

  const steps = useMemo(() => {
    if (
      loggedInUser &&
      ((loggedInUser?.permissions["*"] &&
        loggedInUser?.permissions["*"].CREATE) ||
        (loggedInUser?.permissions?.permissions?.ENABLED &&
          loggedInUser?.permissions?.permissions?.READ === "ANY"))
    ) {
      return [
        {
          name: `${t("user.profileDetails")}`,
          href: "#",
          status: step === 1 ? "current" : "complete",
        },
        {
          name: `${t("user.information")}`,
          href: "#",
          status: step > 1 ? "current" : "upcoming",
        },
        {
          name: `${t("user.addressDetails")}`,
          href: "#",
          status: step > 2 ? "current" : "upcoming",
        },
        {
          name: `${t("user.permissions")}`,
          href: "#",
          status: step > 3 ? "current" : "upcoming",
        },
      ];
    } else {
      return [
        {
          name: `${t("user.profileDetails")}`,
          href: "#",
          status: step === 1 ? "current" : "complete",
        },
        {
          name: `${t("user.information")}`,
          href: "#",
          status: step > 1 ? "current" : "upcoming",
        },
        {
          name: `${t("user.addressDetails")}`,
          href: "#",
          status: step > 2 ? "current" : "upcoming",
        },
      ];
    }
  }, [t, lang, step]);

  const onSubmit = handleSubmit(async (data) => {
    setLoading(true);
    await createUser(
      constructCreateUser(data),
      constructLocationRestrictions(locationRestrictions)
    )
      .then(() => {
        toast.success(
          Message({
            action: "create",
            entity: t("user.user"),
            lang,
            gender: "male",
          })
        );
        navigate("/users");
      })
      .catch((err: any) => {
        if (err.response?.data?.errors?.[0]?.message) {
          toast.error(err.response?.data?.errors?.[0]?.message);
        } else {
          toast.error(err.response?.data?.message);
        }
      })
      .finally(() => setLoading(false));
  });

  return (
    <Overlay active={loading} spinner={<Spinner />}>
      <div className="flex flex-col gap-5 p-10 h-full bg-gray-100 overflow-y-auto">
        <GoBack uri="users" />
        <div className="flex flex-col gap-2">
          <h1 className="text-3xl font-bold">{t("user.users")}</h1>
          <p className="text-global-subHeader text-lg"></p>
        </div>

        <FormProvider {...form}>
          <form onSubmit={onSubmit} className="flex flex-col flex-grow gap-10">
            <div className="flex gap-10 w-full">
              <nav className="flex flex-grow" aria-label="Progress">
                <ol role="list" className="flex flex-grow gap-10">
                  {steps.map((step) => (
                    <li key={step.name} className="flex flex-grow">
                      {step.status === "complete" ? (
                        <div className="flex-grow border-t-4 border-global-stepperComplete">
                          <div className="pt-2 whitespace-nowrap text-xl font-medium">
                            {step.name}
                          </div>
                        </div>
                      ) : step.status === "current" ? (
                        <div className="flex-grow border-t-4 border-global-stepperActive">
                          <div className="pt-2 whitespace-nowrap text-xl font-medium">
                            {step.name}
                          </div>
                        </div>
                      ) : (
                        <div className="flex-grow border-t-4 border-global-stepper">
                          <div className="pt-2 whitespace-nowrap text-xl font-medium">
                            {step.name}
                          </div>
                        </div>
                      )}
                    </li>
                  ))}
                </ol>
              </nav>
              <div className="flex gap-10">
                {step > 1 && (
                  <button
                    type="button"
                    onClick={decreaseStep}
                    className="px-4 py-2 text-users-create bg-white border border-users-create"
                  >
                    {t("user.back")}
                  </button>
                )}
                {step < steps?.length && (
                  <button
                    type="button"
                    onClick={increaseStep}
                    className="px-4 py-2 text-white bg-users-create border border-users-create"
                  >
                    {t("user.next")}
                  </button>
                )}
              </div>
              <button
                type="submit"
                className={`px-4 py-2 bg-users-create border-users-create text-white ${
                  step === steps?.length
                    ? "cursor-pointer"
                    : "cursor-not-allowed"
                }`}
                disabled={step !== steps?.length}
              >
                {t("user.save")}
              </button>
            </div>

            <div className="flex flex-col flex-grow">
              {step === 1 && (
                <div className="grid gap-5 grid-cols-3">
                  <div className="flex flex-col gap-5 col-span-2">
                    <div className="flex gap-5 w-full">
                      <div className="flex flex-col gap-2 w-full">
                        <label
                          htmlFor="firstName"
                          className="block whitespace-nowrap text-lg font-medium"
                        >
                          {t("user.firstName")} *
                        </label>
                        <input
                          type="text"
                          className={getInputStyles(!!errors.firstName)}
                          defaultValue=""
                          placeholder={t("user.firstName")}
                          {...register("firstName", {
                            required: `${t("user.firstName")} ${t(
                              "user.required"
                            )}.`,
                            maxLength: {
                              value: 50,
                              message: `${t("user.firstName")} ${t(
                                "user.shouldBe"
                              )} ${50} ${t("user.characters")}.`,
                            },
                          })}
                        />
                        <DisplayInputError
                          message={errors.firstName?.message}
                        />
                      </div>
                      <div className="flex flex-col gap-2 w-full">
                        <label
                          htmlFor="lastName"
                          className="block whitespace-nowrap text-lg font-medium"
                        >
                          {t("user.lastName")} *
                        </label>
                        <input
                          type="text"
                          className={getInputStyles(!!errors.lastName)}
                          defaultValue=""
                          placeholder={t("user.lastName")}
                          {...register("lastName", {
                            required: `${t("user.lastName")} ${t(
                              "user.required"
                            )}.`,
                            maxLength: {
                              value: 50,
                              message: `${t("user.lastName")} ${t(
                                "user.shouldBe"
                              )} ${50} ${t("user.characters")}.`,
                            },
                          })}
                        />
                        <DisplayInputError message={errors.lastName?.message} />
                      </div>
                    </div>

                    <div className="flex gap-5 w-full">
                      <div className="flex flex-col gap-2 w-full">
                        <label
                          htmlFor="username"
                          className="block whitespace-nowrap text-lg font-medium"
                        >
                          {t("user.username")} *
                        </label>
                        <input
                          type="text"
                          placeholder={t("user.username")}
                          className={getInputStyles(!!errors.username)}
                          {...register("username", {
                            required: `${t("user.username")} ${t(
                              "user.required"
                            )}.`,
                            maxLength: {
                              value: 50,
                              message: `${t("user.username")} ${t(
                                "user.shouldBe"
                              )} ${50} ${t("user.characters")}.`,
                            },
                          })}
                        />
                        <DisplayInputError message={errors.username?.message} />
                      </div>
                      <div className="flex flex-col gap-2 w-full">
                        <label
                          htmlFor="email"
                          className="block whitespace-nowrap text-lg font-medium"
                        >
                          {t("user.email")}
                        </label>
                        <input
                          type="email"
                          className={getInputStyles(!!errors.email)}
                          placeholder={t("user.email")}
                          {...register(`email`, {
                            pattern: {
                              value:
                                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                              message: t("UserCRUD.invalidEmail"),
                            },
                            maxLength: {
                              value: 50,
                              message: `${t("user.email")} ${t(
                                "user.shouldBe"
                              )} ${50} ${t("user.characters")}.`,
                            },
                          })}
                        />
                        <DisplayInputError message={errors.email?.message} />
                      </div>
                    </div>
                    <div className="grid gap-5 grid-cols-2 w-full">
                      <div className="flex flex-col gap-2">
                        <label
                          htmlFor="rank"
                          className="block w-full text-lg font-medium"
                        >
                          {t("user.rank")}
                        </label>

                        <Controller
                          name="rank"
                          control={control}
                          render={({ field }) => (
                            <Select
                              placeholder={t("user.rank")}
                              styles={getReactSelectStyles(false)}
                              inputId="rank"
                              options={ranksOptions}
                              onChange={(e) => field.onChange(e)}
                              value={field.value}
                              isSearchable={false}
                              isClearable={true}
                            />
                          )}
                        />
                      </div>
                      <div className="flex flex-col gap-2">
                        <label
                          htmlFor="promotionBody"
                          className="block w-full text-lg font-medium"
                        >
                          {t("user.promotionBoard")}
                        </label>

                        <input
                          type="text"
                          placeholder={t("user.promotionBoard")}
                          className={getInputStyles(false)}
                          defaultValue=""
                          {...register("promotionBody", {
                            maxLength: {
                              value: 50,
                              message: `${t("user.promotionBoard")} ${t(
                                "user.shouldBe"
                              )} ${50} ${t("user.characters")}.`,
                            },
                          })}
                        />
                        <DisplayInputError
                          message={errors.promotionBody?.message}
                        />
                      </div>
                      <div className="flex flex-col gap-2 w-full">
                        <label
                          htmlFor="title"
                          className="block w-full text-lg font-medium"
                        >
                          {t("user.title")}
                        </label>
                        <input
                          type="text"
                          placeholder={t("user.title")}
                          className={getInputStyles(false)}
                          defaultValue=""
                          {...register("title", {
                            maxLength: {
                              value: 50,
                              message: `${t("user.title")} ${t(
                                "user.shouldBe"
                              )} ${50} ${t("user.characters")}.`,
                            },
                          })}
                        />
                        <DisplayInputError message={errors.title?.message} />
                      </div>
                    </div>

                    <div className="grid gap-5 grid-cols-2 w-full">
                      <div className="flex flex-col gap-2">
                        <label
                          htmlFor="birthPlace"
                          className="block w-full text-lg font-medium"
                        >
                          {t("user.birthPlace")}
                        </label>
                        <input
                          placeholder={t("user.birthPlace")}
                          className={getInputStyles(false)}
                          type="text"
                          defaultValue=""
                          {...register("birthPlace", {
                            maxLength: {
                              value: 50,
                              message: `${t("user.birthPlace")} ${t(
                                "user.shouldBe"
                              )} ${50} ${t("user.characters")}.`,
                            },
                          })}
                        />
                        <DisplayInputError
                          message={errors.birthPlace?.message}
                        />
                      </div>
                      <div className="flex flex-col gap-2">
                        <label
                          className="block w-full whitespace-nowrap text-lg font-medium"
                          htmlFor="birthDate"
                        >
                          {t("user.birthDate")} *
                        </label>
                        <Controller
                          name="birthDate"
                          control={control}
                          rules={{
                            required: true,
                          }}
                          render={({ field }) => {
                            return (
                              <div>
                                <DatePicker
                                  id="birthDate"
                                  selected={field.value}
                                  onChange={(e) => field.onChange(e)}
                                  dateFormat="dd/MM/yyyy"
                                  className={getInputStyles(!!errors.birthDate)}
                                  placeholderText={t("user.dateFormat")}
                                  showPopperArrow={false}
                                  autoComplete="off"
                                />
                              </div>
                            );
                          }}
                        />
                        <DisplayInputError
                          message={
                            errors.birthDate &&
                            `${t("user.birthDate")} ${t("user.required")}.`
                          }
                        />
                      </div>
                    </div>
                    <div className="grid gap-5 grid-cols-2 items-center justify-between">
                      <div className="grid gap-5 grid-cols-2">
                        <div className="flex flex-col gap-2">
                          <span className="flex text-lg font-medium">
                            {t("user.voting")}
                          </span>
                          <div className="flex gap-2 items-center">
                            <label
                              htmlFor="voting"
                              className="flex text-lg font-medium"
                            >
                              {t("user.yes")}
                            </label>
                            <input
                              type="radio"
                              className="border-gray-300 focus:border-users-create focus:ring-users-create"
                              value="1"
                              {...register("votePrivilege")}
                            />
                            <label
                              htmlFor="voting"
                              className="flex text-lg font-medium"
                            >
                              {t("user.no")}
                            </label>
                            <input
                              type="radio"
                              className="border-gray-300 focus:border-users-create focus:ring-users-create"
                              value="0"
                              {...register("votePrivilege")}
                            />
                          </div>
                        </div>
                        <div className="flex flex-col gap-2">
                          <span className="flex text-lg font-medium">
                            {t("user.mail")}
                          </span>
                          <div className="flex gap-2 items-center">
                            <label
                              htmlFor="posta"
                              className="flex text-lg font-medium"
                            >
                              {t("user.yes")}
                            </label>
                            <input
                              type="radio"
                              className="border-gray-300 focus:border-users-create focus:ring-users-create"
                              value="1"
                              {...register("posta")}
                            />
                            <label
                              htmlFor="posta"
                              className="flex text-lg font-medium"
                            >
                              {t("user.no")}
                            </label>
                            <input
                              type="radio"
                              className="border-gray-300 focus:border-users-create focus:ring-users-create"
                              value="0"
                              {...register("posta")}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col gap-2">
                        <label
                          htmlFor="fiscalCode"
                          className="block text-lg font-medium"
                        >
                          {t("user.fiscal")}
                        </label>
                        <input
                          id="fiscalCode"
                          type="text"
                          className={getInputStyles(!!errors.fiscaleCode)}
                          placeholder={t("user.fiscal")}
                          {...register(`fiscaleCode`, {
                            validate: {
                              checkFiscal,
                            },
                          })}
                        />
                        <DisplayInputError
                          message={errors.fiscaleCode?.message}
                        />
                      </div>
                    </div>

                    <div className="flex gap-5 w-full">
                      <div className="flex flex-col gap-2 w-full">
                        <label
                          htmlFor="home-number"
                          className="block text-lg font-medium"
                        >
                          {t("user.homeNumber")}
                        </label>
                        <input
                          type="tel"
                          className={getInputStyles(false)}
                          placeholder={t("user.homeNumber")}
                          {...register(`contactInfo.${1}.detail`, {
                            maxLength: {
                              value: 15,
                              message: `${t("user.post")} ${t(
                                "user.homeNumber"
                              )} ${t("user.shouldBe")} ${15} ${t(
                                "user.characters"
                              )}.`,
                            },
                          })}
                        />
                      </div>
                    </div>

                    <div className="grid gap-5 grid-cols-2 w-full">
                      <div className="flex flex-col gap-2">
                        <label
                          htmlFor="gender"
                          className="block text-lg font-medium"
                        >
                          {t("user.gender")}
                        </label>
                        <Controller
                          name="gender"
                          control={control}
                          render={({ field }) => (
                            <Select
                              placeholder={t("user.gender")}
                              styles={getReactSelectStyles(false)}
                              inputId="gender"
                              options={getGenderOptions(lang)}
                              isClearable={true}
                              value={field.value}
                              onChange={(e) => field.onChange(e)}
                            />
                          )}
                        />
                      </div>
                      <div className="flex flex-col gap-2">
                        <label
                          htmlFor="phone-number"
                          className="block text-lg font-medium"
                        >
                          {t("user.phoneNumber")}
                        </label>
                        <input
                          type="tel"
                          className={getInputStyles(false)}
                          placeholder={t("user.phoneNumber")}
                          {...register(`contactInfo.${0}.detail`)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col gap-2 w-full">
                    <label
                      htmlFor="profilePic"
                      className="block text-lg font-medium"
                    >
                      {t("user.coverPhoto")}
                    </label>
                    <SimpleImageDropzone
                      clearErrors={clearErrors}
                      trigger={trigger}
                      setValue={setValue}
                      watch={watch}
                      reactHookFormName="profilePic"
                      setError={setError}
                      setEditImageOn={setEditImageOn}
                      aspect={1}
                      required={false}
                      register={register}
                      canDelete={true}
                    />
                    {editImageOn ? (
                      <CropImage
                        reactHookFormName="profilePic"
                        canForce={true}
                        canClose={true}
                        editImageOn={editImageOn}
                        setEditImageOn={setEditImageOn}
                        aspect={1}
                      />
                    ) : null}
                    <DisplayInputError message={errors.profilePic?.message} />
                  </div>
                </div>
              )}

              {step === 2 && <InformationTab />}

              {step === 3 && (
                <div className="flex flex-col gap-5">
                  <div className="flex flex-col gap-5">
                    <div className="flex flex-col gap-5 w-full">
                      <h1 className="text-2xl font-bold">
                        {t("user.homeAddress")} *
                      </h1>
                      <LocationSearch
                        getValues={getValues}
                        register={register}
                        setValue={setValue}
                        clearErrors={clearErrors}
                        watch={watch}
                        errors={errors}
                        reactHookFormName="addressInfo.0"
                        isUserAddress={true}
                        displayMap={false}
                        displayDetails={false}
                        required={false}
                      />
                    </div>
                    <div className="grid gap-5 grid-cols-3">
                      <div className="flex flex-col gap-2">
                        <label className="text-lg font-medium">
                          {t("user.address")} *
                        </label>
                        <input
                          type="text"
                          placeholder={t("user.address")}
                          className={getInputStyles(
                            !!errors.addressInfo?.[0]?.roadName
                          )}
                          {...register(`addressInfo.${0}.roadName`, {
                            required: `${t("user.address")} ${t(
                              "user.required"
                            )}.`,
                            maxLength: {
                              value: 50,
                              message: `${t("user.address")} ${t(
                                "user.shouldBe"
                              )} ${50} ${t("user.characters")}.`,
                            },
                          })}
                        />
                        <DisplayInputError
                          message={errors.addressInfo?.[0]?.roadName?.message}
                        />
                      </div>
                      <div className="flex flex-col gap-2">
                        <label className="text-lg font-medium">
                          {t("user.streetNumber")} *
                        </label>
                        <input
                          type="text"
                          placeholder={t("user.streetNumber")}
                          className={getInputStyles(
                            !!errors.addressInfo?.[0]?.homeNumber
                          )}
                          {...register(`addressInfo.${0}.homeNumber`, {
                            required: `${t("user.streetNumber")} ${t(
                              "user.required"
                            )}.`,
                            maxLength: {
                              value: 50,
                              message: `${t("user.streetNumber")} ${t(
                                "user.shouldBe"
                              )} ${50} ${t("user.characters")}.`,
                            },
                          })}
                        />
                        <DisplayInputError
                          message={errors.addressInfo?.[0]?.homeNumber?.message}
                        />
                      </div>
                      <div className="flex flex-col gap-2">
                        <label className="text-lg font-medium">
                          {t("user.postalCode")} *
                        </label>
                        <input
                          type="text"
                          placeholder={t("user.postalCode")}
                          className={getInputStyles(
                            !!errors.addressInfo?.[0]?.postalCode
                          )}
                          {...register(`addressInfo.${0}.postalCode`, {
                            required: `${t("user.postalCode")} ${t(
                              "user.required"
                            )}.`,
                            maxLength: {
                              value: 50,
                              message: `${t("user.postalCode")} ${t(
                                "user.shouldBe"
                              )} ${50} ${t("user.characters")}.`,
                            },
                          })}
                        />
                        <DisplayInputError
                          message={errors.addressInfo?.[0]?.postalCode?.message}
                        />
                      </div>
                      <div className="flex flex-col gap-2">
                        <label className="text-lg font-medium">
                          {t("user.city")} *
                        </label>
                        <input
                          type="text"
                          placeholder={t("user.city")}
                          className={getInputStyles(
                            !!errors.addressInfo?.[0]?.city
                          )}
                          {...register(`addressInfo.${0}.city`, {
                            required: `${t("user.city")} ${t("user.required")}`,
                            maxLength: {
                              value: 50,
                              message: `${t("user.city")} ${t(
                                "user.shouldBe"
                              )} ${50} ${t("user.characters")}.`,
                            },
                          })}
                        />
                        <DisplayInputError
                          message={errors.addressInfo?.[0]?.city?.message}
                        />
                      </div>
                      <div className="flex flex-col gap-2">
                        <label className="text-lg font-medium">
                          {t("user.province")} *
                        </label>
                        <Controller
                          name={`addressInfo.${0}.province`}
                          control={control}
                          rules={{
                            required: true,
                          }}
                          render={({ field }) => (
                            <Select
                              placeholder={t("user.province")}
                              styles={getReactSelectStyles(
                                !!errors.addressInfo?.[0]?.province
                              )}
                              isLoading={lookups.loading}
                              inputId="province"
                              isMulti={false}
                              isClearable={true}
                              options={provincesOptions}
                              value={field.value}
                              onChange={(e) => field.onChange(e)}
                            />
                          )}
                        />
                        <DisplayInputError
                          message={
                            errors.addressInfo?.[0]?.province &&
                            `${t("user.province")} ${t("user.required")}.`
                          }
                        />
                      </div>
                      <div className="flex flex-col gap-2">
                        <label className="text-lg font-medium">
                          {t("user.region")} *
                        </label>
                        <Controller
                          name={`addressInfo.${0}.region`}
                          control={control}
                          rules={{
                            required: true,
                          }}
                          render={({ field }) => (
                            <Select
                              placeholder={t("user.region")}
                              styles={getReactSelectStyles(
                                !!errors.addressInfo?.[0]?.region
                              )}
                              isLoading={lookups.loading}
                              inputId="region"
                              isMulti={false}
                              isClearable={true}
                              options={regionsOptions}
                              value={field.value}
                              onChange={(e) => field.onChange(e)}
                            />
                          )}
                        />
                        <DisplayInputError
                          message={
                            errors.addressInfo?.[0]?.region &&
                            `${t("user.region")} ${t("user.required")}i.`
                          }
                        />
                      </div>
                      <div className="flex flex-col gap-2">
                        <label className="text-lg font-medium">
                          {t("user.country")} *
                        </label>
                        <input
                          type="text"
                          placeholder={t("user.country")}
                          className={getInputStyles(
                            !!errors.addressInfo?.[0]?.country
                          )}
                          {...register(`addressInfo.${0}.country`, {
                            required: `${t("user.country")} ${t(
                              "user.required"
                            )}.`,
                            maxLength: {
                              value: 50,
                              message: `${t("user.country")} ${t(
                                "user.shouldBe"
                              )} ${50} ${t("user.characters")}.`,
                            },
                          })}
                        />
                        <DisplayInputError
                          message={errors.addressInfo?.[0]?.country?.message}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col gap-5">
                    <div className="flex flex-col gap-5">
                      <p className="text-2xl font-bold">
                        {t("user.officeAddress")}
                      </p>
                      <LocationSearch
                        getValues={getValues}
                        register={register}
                        setValue={setValue}
                        clearErrors={clearErrors}
                        watch={watch}
                        errors={errors}
                        reactHookFormName="addressInfo.1"
                        isUserAddress={true}
                        displayMap={false}
                        displayDetails={false}
                        required={false}
                      />
                    </div>
                    <div className="grid gap-5 grid-cols-3">
                      <div className="flex flex-col gap-2">
                        <label className="text-lg font-medium">
                          {t("user.address")}
                        </label>
                        <input
                          type="text"
                          placeholder={t("user.address")}
                          className={getInputStyles(false)}
                          {...register(`addressInfo.${1}.roadName`, {
                            maxLength: {
                              value: 50,
                              message: `${t("user.address")} ${t(
                                "user.shouldBe"
                              )} ${50} ${t("user.characters")}.`,
                            },
                          })}
                        />
                      </div>
                      <div className="flex flex-col gap-2">
                        <label className="text-lg font-medium">
                          {t("user.streetNumber")}
                        </label>
                        <input
                          type="text"
                          placeholder={t("user.streetNumber")}
                          className={getInputStyles(false)}
                          {...register(`addressInfo.${1}.homeNumber`, {
                            maxLength: {
                              value: 50,
                              message: `${t("user.streetNumber")} ${t(
                                "user.shouldBe"
                              )} ${50} ${t("user.characters")}.`,
                            },
                          })}
                        />
                      </div>
                      <div className="flex flex-col gap-2">
                        <label className="text-lg font-medium">
                          {t("user.postalCode")}
                        </label>
                        <input
                          type="text"
                          placeholder={t("user.postalCode")}
                          className={getInputStyles(false)}
                          {...register(`addressInfo.${1}.postalCode`, {
                            maxLength: {
                              value: 50,
                              message: `${t("user.postalCode")} ${t(
                                "user.shouldBe"
                              )} ${50} ${t("user.characters")}.`,
                            },
                          })}
                        />
                      </div>
                      <div className="flex flex-col gap-2">
                        <label className="text-lg font-medium">
                          {t("user.city")}
                        </label>
                        <input
                          type="text"
                          placeholder={t("user.city")}
                          className={getInputStyles(false)}
                          {...register(`addressInfo.${1}.city`, {
                            maxLength: {
                              value: 50,
                              message: `${t("user.city")} ${t(
                                "user.shouldBe"
                              )} ${50} ${t("user.characters")}.`,
                            },
                          })}
                        />
                      </div>
                      <div className="flex flex-col gap-2">
                        <label className="text-lg font-medium">
                          {t("user.province")}
                        </label>
                        <Controller
                          name={`addressInfo.${1}.province`}
                          control={control}
                          render={({ field }) => (
                            <Select
                              placeholder={t("user.province")}
                              styles={getReactSelectStyles(false)}
                              isLoading={lookups.loading}
                              inputId="province"
                              isMulti={false}
                              isClearable={true}
                              options={provincesOptions}
                              value={field.value}
                              onChange={(e) => field.onChange(e)}
                            />
                          )}
                        />
                      </div>
                      <div className="flex flex-col gap-2">
                        <label className="text-lg font-medium">
                          {t("user.region")}
                        </label>
                        <Controller
                          name={`addressInfo.${1}.region`}
                          control={control}
                          render={({ field }) => (
                            <Select
                              placeholder={t("user.region")}
                              styles={getReactSelectStyles(false)}
                              isLoading={lookups.loading}
                              inputId="region"
                              isMulti={false}
                              isClearable={true}
                              options={regionsOptions}
                              value={field.value}
                              onChange={(e) => field.onChange(e)}
                            />
                          )}
                        />
                      </div>
                      <div className="flex flex-col gap-2">
                        <label className="text-lg font-medium">
                          {t("user.country")}
                        </label>
                        <input
                          type="text"
                          placeholder={t("user.country")}
                          className={getInputStyles(false)}
                          {...register(`addressInfo.${1}.country`)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col gap-5">
                    <div className="flex flex-col gap-5">
                      <h1 className="text-2xl font-bold">
                        {t("user.altAddress")}
                      </h1>
                      <LocationSearch
                        getValues={getValues}
                        register={register}
                        setValue={setValue}
                        clearErrors={clearErrors}
                        watch={watch}
                        errors={errors}
                        reactHookFormName="addressInfo.2"
                        isUserAddress={true}
                        displayMap={false}
                        displayDetails={false}
                        required={false}
                      />
                    </div>
                    <div className="grid gap-5 grid-cols-3">
                      <div className="flex flex-col gap-2">
                        <label className="text-lg font-medium">
                          {t("user.address")}
                        </label>
                        <input
                          type="text"
                          placeholder={t("user.address")}
                          className={getInputStyles(false)}
                          {...register(`addressInfo.${2}.roadName`, {
                            maxLength: {
                              value: 50,
                              message: `${t("user.address")} ${t(
                                "user.shouldBe"
                              )} ${50} ${t("user.characters")}.`,
                            },
                          })}
                        />
                      </div>
                      <div className="flex flex-col gap-2">
                        <label className="text-lg font-medium">
                          {t("user.streetNumber")}
                        </label>
                        <input
                          type="text"
                          placeholder={t("user.streetNumber")}
                          className={getInputStyles(false)}
                          {...register(`addressInfo.${2}.homeNumber`, {
                            maxLength: {
                              value: 50,
                              message: `${t("user.streetNumber")} ${t(
                                "user.shouldBe"
                              )} ${50} ${t("user.characters")}.`,
                            },
                          })}
                        />
                      </div>
                      <div className="flex flex-col gap-2">
                        <label className="text-lg font-medium">
                          {t("user.postalCode")}
                        </label>
                        <input
                          type="text"
                          placeholder={t("user.postalCode")}
                          className={getInputStyles(false)}
                          {...register(`addressInfo.${2}.postalCode`, {
                            maxLength: {
                              value: 50,
                              message: `${t("user.postalCode")} ${t(
                                "user.shouldBe"
                              )} ${50} ${t("user.characters")}.`,
                            },
                          })}
                        />
                      </div>
                      <div className="flex flex-col gap-2">
                        <label className="text-lg font-medium">
                          {t("user.city")}
                        </label>
                        <input
                          type="text"
                          placeholder={t("user.city")}
                          className={getInputStyles(false)}
                          {...register(`addressInfo.${2}.city`, {
                            maxLength: {
                              value: 50,
                              message: `${t("user.city")} ${t(
                                "user.shouldBe"
                              )} ${50} ${t("user.characters")}.`,
                            },
                          })}
                        />
                      </div>
                      <div className="flex flex-col gap-2">
                        <label className="text-lg font-medium">
                          {t("user.province")}
                        </label>
                        <Controller
                          name={`addressInfo.${2}.province`}
                          control={control}
                          render={({ field }) => (
                            <Select
                              placeholder={t("user.province")}
                              styles={getReactSelectStyles(false)}
                              isLoading={lookups.loading}
                              inputId="province"
                              isMulti={false}
                              isClearable={true}
                              options={provincesOptions}
                              value={field.value}
                              onChange={(e) => field.onChange(e)}
                            />
                          )}
                        />
                      </div>
                      <div className="flex flex-col gap-2">
                        <label className="text-lg font-medium">
                          {t("user.region")}
                        </label>
                        <Controller
                          name={`addressInfo.${2}.region`}
                          control={control}
                          render={({ field }) => (
                            <Select
                              placeholder={t("user.region")}
                              styles={getReactSelectStyles(false)}
                              isLoading={lookups.loading}
                              inputId="region"
                              isMulti={false}
                              isClearable={true}
                              options={regionsOptions}
                              value={field.value}
                              onChange={(e) => field.onChange(e)}
                            />
                          )}
                        />
                      </div>
                      <div className="flex flex-col gap-2">
                        <label className="text-lg font-medium">
                          {t("user.country")}
                        </label>
                        <input
                          type="text"
                          placeholder={t("user.country")}
                          className={getInputStyles(false)}
                          {...register(`addressInfo.${2}.country`, {
                            maxLength: {
                              value: 50,
                              message: `${t("user.country")} ${t(
                                "user.shouldBe"
                              )} ${50} ${t("user.characters")}.`,
                            },
                          })}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {step === 4 &&
                loggedInUser &&
                ((loggedInUser?.permissions["*"] &&
                  loggedInUser?.permissions["*"].CREATE) ||
                  (loggedInUser?.permissions?.permissions?.ENABLED &&
                    loggedInUser?.permissions?.permissions?.READ ===
                      "ANY")) && (
                  <div className="flex flex-col gap-5">
                    <div className="flex flex-col gap-2">
                      <label htmlFor="role" className="text-lg font-medium">
                        {t("user.role")} *
                      </label>
                      <Controller
                        name="role"
                        control={control}
                        rules={{
                          required: true,
                        }}
                        render={({ field }) => (
                          <Select
                            placeholder={t("user.role")}
                            styles={getReactSelectStyles(!!errors.role)}
                            isLoading={lookups.loading}
                            inputId="role"
                            isClearable={true}
                            options={rolesOptions}
                            value={field.value}
                            onChange={(e) => field.onChange(e)}
                          />
                        )}
                      />
                      <DisplayInputError
                        message={
                          errors.role &&
                          `${t("user.role")} ${t("user.required")}.`
                        }
                      />
                    </div>
                    <RegionRestrictions
                      regionRestrictions={form.watch("regionRestrictions")}
                      setRegionRestrictions={(
                        regions: regionRestrictionsSelect
                      ) => form.setValue("regionRestrictions", regions)}
                    />
                  </div>
                )}
            </div>
          </form>
        </FormProvider>
      </div>
    </Overlay>
  );
};

export default CreateUserPage;
