import { getProducts } from "api/Products/products.api";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Controller,
  useForm,
  useFormContext,
  UseFormReturn,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { OptionsOrGroups } from "react-select";
import AsyncSelect from "react-select/async";
import DisplayInputError from "shared/components/UI/DisplayInputError";
import Select from "react-select";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ReceiptProductDto } from "shared/interfaces/Receipt.interface";
import { getReactSelectStyles, Round } from "utils/utils";

export type Product = {
  id: string;
  slug: string;
  title: string;
  short_description: string;
  description: string;
  thumbnail: string;
  vat_percentage: number;
  creator_id: string;
  last_modifier: string;
  only_subs: boolean;
  published: boolean;
  created_at: string;
  updated_at: string;
  tags: {
    pk_tag: string;
    last_modifier: string;
    total_views: number;
    created_at: string;
    updated_at: string;
  }[];
  categories: {
    id: number;
    name: string;
    slug: string;
    description: string;
    created_at: string;
    updated_at: string;
  }[];
};

export type Variant = {
  id: string;
  product_id: string;
  title: string;
  sku: string;
  size: string;
  color: string;
  inventory_quantity: number;
  price: number;
  created_at: string;
  updated_at: string;
};

// Use: SelectProduct Modal
export type EnchancedProduct = Product & {
  variants: Variant[];
};

export function productLabel(product: EnchancedProduct) {
  const temp = [];
  if (!product) return "";
  if (product.title) {
    temp.push(product.title);
  }
  if (product.short_description) {
    temp.push(product.short_description);
  }
  if (product.slug) {
    temp.push(product.slug);
  }
  return temp.join(" - ");
}

export function variantLabel(variant: Variant) {
  const temp = [];
  if (!variant) return "";
  if (variant.title) {
    temp.push(variant.title);
  }
  if (variant.inventory_quantity) {
    temp.push(variant.inventory_quantity + " in stock");
  }
  if (variant.price) {
    temp.push(variant.price + "€");
  }
  return temp.join(" - ");
}

export function productToReceiptFormat({
  product,
  variant,
}: {
  product: EnchancedProduct;
  variant: Variant;
}): ReceiptProductDto {
  return {
    title: product.title,
    description: product.description,
    quantity: 1,
    unit_price: variant.price,
    vat_percentage: product.vat_percentage * 100,
    is_vat_included: false,
    total: Round(variant.price + variant.price * product.vat_percentage),
  };
}

export type SelectProductForm = {
  product: EnchancedProduct;
  variant: {
    label: string;
    value: Variant;
  };
};

export function productVatPercentageToInteger(product: EnchancedProduct) {
  return {
    ...product,
    vat_percentage: product.vat_percentage * 100,
  };
}

function SelectProduct({
  close,
  reactHookFormName,
}: {
  close: () => void;
  reactHookFormName: string;
}) {
  const { t } = useTranslation("common");
  const [variantsOptions, setVariantsOptions] =
    useState<{ label: string; value: Variant }[]>();
  const receiptForm = useFormContext<any>();
  const formRef = useRef<null | UseFormReturn<SelectProductForm>>(null);
  const schema = yup.object({
    product: yup.object().required("Product is required"),
    variant: yup.object().required("Variant is required."),
  });
  const form = useForm<SelectProductForm>({
    mode: "onChange",
    resolver: yupResolver(schema),
  });
  formRef.current = form;

  const onSubmit = form.handleSubmit((data) => {
    const product = productToReceiptFormat({
      product: data.product,
      variant: data.variant.value,
    });
    receiptForm.setValue(reactHookFormName, product);
    receiptForm.trigger("receiptDetails");
    close();
  });

  const _loadOptions = (
    e: string,
    callback: (options: OptionsOrGroups<any, any>) => void
  ) => {
    getProducts({
      include: "variants",
      searchString: e,
    })
      .then((res) => {
        const productOptions =
          res.data.data.map((product: EnchancedProduct) => {
            // product = productVatPercentageToInteger(product);
            return {
              label: productLabel(product),
              value: product,
            };
          }) || [];
        callback(productOptions);
      })
      .catch(() => callback([]));
  };

  const loadOptions = useCallback(debounce(_loadOptions, 400), []);

  useEffect(() => {
    const product = form.getValues("product");
    const tempVariantsOptions = product?.variants.map((variant: Variant) => {
      return {
        value: variant,
        label: variantLabel(variant),
      };
    });
    if (tempVariantsOptions) {
      setVariantsOptions(tempVariantsOptions);
    }
  }, [form.watch("product")]);

  return (
    <div
      className="flex flex-col flex-grow gap-5 p-10 bg-white rounded-sm"
      style={{ width: "60rem" }}
    >
      <div className="flex flex-grow gap-5 justify-between">
        <form
          className="flex flex-col flex-grow gap-5 items-center justify-between w-full"
          onSubmit={onSubmit}
        >
          <div
            className="flex flex-col gap-2 w-full"
            onClick={(e) => e.stopPropagation()}
          >
            <label htmlFor="searchProduct" className="text-lg font-medium">
              {t("receipt.product")}
            </label>
            <AsyncSelect
              cacheOptions
              defaultOptions
              id="searchProduct"
              placeholder={t("receipt.product")}
              styles={getReactSelectStyles(false)}
              inputId="searchUser"
              isClearable={true}
              loadOptions={loadOptions}
              onChange={(e) => {
                if (e) {
                  form.setValue("product", e.value);
                  form.trigger("product");
                }
              }}
            />
            <DisplayInputError
              message={(form.formState.errors.product as any)?.message}
            />
          </div>
          <div
            className="flex flex-col gap-2 w-full"
            onClick={(e) => e.stopPropagation()}
          >
            <label htmlFor="variant" className="text-lg font-medium">
              {t("receipt.variant")}
            </label>
            <Controller
              name="variant"
              control={form.control}
              render={({ field }) => (
                <Select
                  placeholder={t("receipt.variant")}
                  styles={getReactSelectStyles(false)}
                  inputId="variant"
                  isMulti={false}
                  isClearable={true}
                  options={variantsOptions}
                  value={form.watch("variant")}
                  onChange={(e) => field.onChange(e)}
                />
              )}
            />
            <DisplayInputError
              message={(form.formState.errors.variant as any)?.message}
            />
          </div>
          <div className="flex gap-5 items-center justify-start w-full">
            <div className="flex flex-col flex-grow justify-end h-full">
              <div className="self-end">
                <button
                  type="submit"
                  className="px-4 py-2 text-white bg-global-save rounded-sm"
                >
                  {t("receipt.done")}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default SelectProduct;
