import React from "react";

export function DisplayIf({
  condition,
  children,
}: {
  condition: boolean;
  children: React.ReactElement;
}): React.ReactElement | null {
  if (condition) return children;
  return null;
}
