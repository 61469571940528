import { CMS_BE_URL } from "api/urls";
import { EventsFiltersType } from "pages/Events/types";
import {
  MassUpdateEventsDtoExclude,
  MassUpdateEventsDtoInclude,
} from "./events.api";

export type UpdateEventsFilters = Omit<
  EventsFiltersType,
  "pageIndex" | "pageSize" | "sortCol" | "sortOrder"
>;

type LogicalQueryParameter = Partial<{
  in: string[];
  notIn: string[];
}>;

function handleLogicalQueryParameter(
  name: string,
  filters: LogicalQueryParameter
) {
  if (filters["in"]) return `${name}[in]=${JSON.stringify(filters["in"])}`;
  else if (filters["notIn"])
    return `${name}[notIn]=${JSON.stringify(filters["notIn"])}`;
  else return "";
}

function constructEventsEnchancedQuery(
  filters: UpdateEventsFilters | EventsFiltersType
) {
  return Object.entries(filters)
    .filter(([, value]) => !!value)
    .map(([key, value]) => {
      if (key == "from")
        return handleLogicalQueryParameter("from", value as any);
      else if (key == "to")
        return handleLogicalQueryParameter("to", value as any);
      else if (value) return `${key}=${value}`;
    })
    .join("&");
}

export const createEventURL = (): string => `${CMS_BE_URL}/events`;
export const getEventsURL = ({
  from,
  to,
  region,
  tags,
  visible,
  pageSize,
  pageIndex = 0,
  sortCol,
  sortOrder,
  searchString,
}: {
  from: string;
  to: string;
  region: string;
  tags: string;
  visible: string;
  pageSize: number;
  pageIndex: number;
  sortCol: string;
  sortOrder: string;
  searchString: string;
}): string => {
  const q = [];
  if (from) q.push(`from=${from}`);
  if (to) q.push(`to=${to}`);
  if (region) q.push(`region=${region}`);
  if (tags) q.push(`tags=${tags}`);
  if (visible) q.push(`visible=${visible}`);
  if (pageSize) q.push(`pageSize=${pageSize}`);
  if (pageIndex) q.push(`pageIndex=${pageIndex}`);
  if (sortCol) q.push(`sortCol=${sortCol}`);
  if (sortOrder) q.push(`sortOrder=${sortOrder}`);
  if (searchString) q.push(`searchString=${searchString}`);
  return `${CMS_BE_URL}/events?`.concat(q.join("&"));
};
export const updateEventURL = (eventId: string): string =>
  `${CMS_BE_URL}/events/${eventId}`;
export const deleteEventURL = (eventId: string): string =>
  `${CMS_BE_URL}/events/${eventId}`;
export const getEventURL = (eventId: string): string =>
  `${CMS_BE_URL}/events/${eventId}`;

export const constructUpdateEventsURL = (
  filters:
    | MassUpdateEventsDtoInclude["filters"]
    | MassUpdateEventsDtoExclude["filters"]
) => {
  return `${CMS_BE_URL}/events?${constructEventsEnchancedQuery(filters)}`;
};

export const constructDeleteEventsURL = (
  filters:
    | MassUpdateEventsDtoInclude["filters"]
    | MassUpdateEventsDtoExclude["filters"]
) => {
  return `${CMS_BE_URL}/events/massDelete?${constructEventsEnchancedQuery(
    filters
  )}`;
};
