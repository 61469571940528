import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import { Provider } from "react-redux";
import globalStore, { authActions } from "./store/globalStore";
import { BrowserRouter } from "react-router-dom";
import axios from "axios";
import { useAppDispatch } from "./store/storeHooks";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import en from "./shared/assets/i18n/en.json";
import it from "./shared/assets/i18n/it.json";
import LanguageDetector from "i18next-browser-languagedetector";
import { CMS_FE_URL } from "api/urls";
import { GoogleOAuthProvider } from "@react-oauth/google";

axios.interceptors.request.use((request: any) => {
  //Add JWT to request. If no JWT, logout and redirect to signin page.
  const login = request.url.includes("/users/login");
  if (!login) {
    const remember = localStorage.getItem("remember");
    let token;
    if (remember && JSON.parse(remember)) {
      token = localStorage.getItem("token");
    } else {
      token = sessionStorage.getItem("token");
    }

    if (token) {
      token = JSON.parse(token);
      request.headers.Authorization = `Bearer ${token}`;
      return request;
    } else {
      const dispatch = useAppDispatch();
      dispatch(authActions.logout());
      window.location.href = `${CMS_FE_URL}/login`;
    }
  }
  return request;
});

function getFallbackLanguage() {
  if (navigator.languages[0] === "it" || navigator.languages[0] === "it-IT") {
    return "it";
  }
  return "en";
}

i18next.use(LanguageDetector).init({
  resources: {
    en: {
      common: en, // 'common' is our custom namespace
    },
    it: {
      common: it,
    },
  },
  fallbackLng: getFallbackLanguage(),
  interpolation: { escapeValue: false }, // React already does escaping
  detection: {
    order: ["localStorage"],
    lookupLocalStorage: "i18nextLng",
    caches: ["localStorage"],
  },
});

ReactDOM.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId="642386664197-0jqu6nn5e01lo9k3ms1mg0g6argckaor.apps.googleusercontent.com">
      <I18nextProvider i18n={i18next}>
        <Provider store={globalStore}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </Provider>
      </I18nextProvider>
    </GoogleOAuthProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
