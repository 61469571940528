import axios, { AxiosResponse } from "axios";
import { CreateRolePermission } from "shared/interfaces/RolePermission.interface";
import {
  createRolePermissionURL,
  getRolePermissionsURL,
  updateRolePermissionURL,
  deleteRolePermissionURL,
  getRolesPermissionsURL,
  deleteRolePermissionsURL,
} from "./urls";

export const createRolePermission: (
  data: CreateRolePermission
) => Promise<AxiosResponse<any, any>> = (data: CreateRolePermission) => {
  return axios.post(createRolePermissionURL(), data);
};

export const getRolePermissions: (
  roleID: number
) => Promise<AxiosResponse<any, any>> = (roleID: number) => {
  return axios.get(getRolePermissionsURL(roleID));
};

export const updateRolePermission: (
  roleID: number,
  data: CreateRolePermission
) => Promise<AxiosResponse<any, any>> = (
  roleID: number,
  data: CreateRolePermission
) => {
  return axios.put(updateRolePermissionURL(roleID), data);
};

export const deleteRolePermission: (
  roleName: string,
  permissionName: string
) => Promise<AxiosResponse<any, any>> = (
  roleName: string,
  permissionName: string
) => {
  return axios.delete(deleteRolePermissionURL(roleName, permissionName));
};

export const getRolesPermissions: () => Promise<
  AxiosResponse<any, any>
> = () => {
  return axios.get(getRolesPermissionsURL());
};

export const deleteRolePermissions: (
  roleID: number
) => Promise<AxiosResponse<any, any>> = (roleID: number) => {
  return axios.get(deleteRolePermissionsURL(roleID));
};
