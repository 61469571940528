import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import CreateModal from "../UI/CreateModal";
import Select from "react-select";
import DatePicker from "react-datepicker";

import { format } from "date-fns";
import axios from "axios";
import { CMS_BE_URL } from "api/urls";
import { toast } from "react-toastify";
import { capitalize } from "lodash";

const SubsHistory = ({
  subsHistory,
  fetchUser,
  getSubs,
  plans,
}: {
  subsHistory: any;
  plans: any[];
  getSubs: any;
  fetchUser: any;
}) => {
  const [editSub, setEditSub] = useState("");
  const [openEditModal, setOpenEditModal] = useState(false);
  const [price, setPrice] = useState<any>();
  const [openDelete, setOpenDelete] = useState(false);
  const [payDate, setPayDate] = useState<Date | null>(new Date());
  const [startDate, setStartDate] = useState<Date | null>();
  const [endDate, setEndDate] = useState<Date | null>();
  const [paymentType, setPaymentType] = useState("");
  const [planType, setPlanType] = useState("");
  const [subType, setSubType] = useState("");
  const { t } = useTranslation("common");

  const deleteSubscription = async () => {
    try {
      await axios.delete(`${CMS_BE_URL}/subscriptions/${editSub}`);
    } catch (err: any) {
      if (err.response?.data?.errors?.[0]?.message) {
        toast.error(err.response?.data?.errors?.[0]?.message);
      } else {
        toast.error(err.response?.data?.message);
      }
    }

    getSubs();
    await fetchUser();
    setOpenDelete(false);
  };

  const editSubscription = async () => {
    const data = {
      // status: status,
      created_at: payDate,
      price: price,
      planType: planType,
      type: subType,
      payment_type: paymentType,
      start_date: startDate,
      end_date: endDate,
      payment_date: payDate ?? Date.now(),
    };

    try {
      await axios.put(`${CMS_BE_URL}/subscriptions/${editSub}`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      setOpenEditModal(false);
      getSubs();
    } catch (err: any) {
      if (err.response?.data?.errors?.[0]?.message) {
        toast.error(err.response?.data?.errors?.[0]?.message);
      } else {
        toast.error(err.response?.data?.message);
      }
      // setLoading(false);
    }
  };
  return (
    <div>
      <div>
        <div className="flex flex-col mt-8">
          <h3 className="mb-2 font-bold">{t("UserCRUD.sHistory")}</h3>
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block align-middle py-2 min-w-full md:px-6 lg:px-8">
              <div className="shadow overflow-hidden ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full divide-gray-300 divide-y">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="pl-4 pr-3 py-3.5 text-left text-gray-900 font-semibold sm:pl-6"
                      >
                        {t("subs.startDate")}
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-gray-900 font-semibold"
                      >
                        {t("subs.endDate")}
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-gray-900 font-semibold"
                      >
                        {t("subs.payDate")}
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-gray-900 font-semibold"
                      >
                        {t("subs.payType")}
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-gray-900 font-semibold"
                      >
                        {t("subs.type")}
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-gray-900 font-semibold"
                      >
                        {t("product.price")}
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-gray-900 font-semibold"
                      >
                        {t("subs.pt")}
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-gray-900 font-semibold"
                      >
                        {t("news.actions")}
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-gray-200 divide-y">
                    {subsHistory.map((s: any, i: number) => (
                      <tr key={s.id}>
                        <td className="pl-4 pr-3 py-4 text-gray-900 whitespace-nowrap font-medium sm:pl-6">
                          {format(new Date(s.start_date), "dd/MM/yyyy")}
                        </td>
                        <td className="px-3 py-4 text-gray-500 whitespace-nowrap">
                          {format(new Date(s.end_date), "dd/MM/yyyy")}
                        </td>
                        <td className="px-3 py-4 text-gray-500 whitespace-nowrap">
                          {format(new Date(s.payment_date), "dd/MM/yyyy")}
                        </td>
                        <td className="px-3 py-4 text-gray-500 whitespace-nowrap">
                          {capitalize(s.payment_type?.replace("_", " "))}
                        </td>
                        <td className="px-3 py-4 text-gray-500 whitespace-nowrap">
                          {capitalize(s.type)}
                        </td>
                        <td className="px-3 py-4 text-gray-500 whitespace-nowrap">
                          {s.price && <>{s.price}&euro;</>}
                        </td>
                        <td className="px-3 py-4 text-gray-500 whitespace-nowrap">
                          {s.plantype}
                        </td>
                        <td>
                          <div className="flex gap-2 cursor-pointer">
                            <div
                              className="text-green-500"
                              onClick={() => {
                                setOpenEditModal(true);
                                setEditSub(s.id);
                                setPrice(s.price);
                                setPaymentType(s.payment_type);
                                setPlanType(s.plantype);
                                setStartDate(new Date(s.start_date));
                                setPayDate(new Date(s.payment_date));
                                setEndDate(new Date(s.end_date));
                                setSubType(s.type);
                              }}
                            >
                              {t("UserCRUD.editButton")}
                            </div>

                            <div
                              className="text-red-500 cursor-pointer"
                              onClick={() => {
                                setOpenDelete(true);
                                setEditSub(s.id);
                              }}
                            >
                              {t("crud.delete")}
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CreateModal
        openStatus={openEditModal}
        setOpen={setOpenEditModal}
        className=""
        header={<p className="mt-4 text-xl">{t("subs.ms")}</p>}
        title={
          <div className="flex flex-col gap-4">
            <div className="flex gap-4">
              <div className="w-1/2">
                <label
                  className="text-gray-900 text-base font-medium"
                  // className={
                  //   certificateError ? "block text-red-500" : "block"
                  // }
                >
                  {t("subs.startDate")}*
                </label>
                <DatePicker
                  selected={startDate}
                  onChange={(date: Date) => setStartDate(date)}
                  dateFormat="dd/MM/yyyy"
                  className="mt-1 py-2 w-full text-gray-500 border focus:border-global-input-focus border-gray-300 focus:ring-0"
                  placeholderText="Obtained at.."
                  showPopperArrow={false}
                  autoComplete="off"
                />
              </div>
              <div className="w-1/2">
                <label className="text-gray-900 text-base font-medium">
                  {t("subs.endDate")}*
                </label>
                <DatePicker
                  selected={endDate}
                  onChange={(date: Date) => setEndDate(date)}
                  dateFormat="dd/MM/yyyy"
                  className="mt-1 py-2 w-full text-gray-500 border focus:border-global-input-focus border-gray-300 focus:ring-0"
                  placeholderText="Obtained at.."
                  showPopperArrow={false}
                  autoComplete="off"
                />
              </div>
            </div>
            <div className="flex gap-4">
              <div className="w-1/2">
                <label className="block text-gray-900 text-base font-medium">
                  {t("products.price")}
                </label>
                <input
                  type="number"
                  className="mt-1 py-2 w-full text-gray-500 border focus:border-global-input-focus border-gray-300 focus:ring-0"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                />
              </div>
              <div className="w-1/2">
                <label
                  className="text-gray-900 text-base font-medium"
                  // className={
                  //   certificateError ? "block text-red-500" : "block"
                  // }
                >
                  {t("subs.payDate")}*
                </label>
                <DatePicker
                  selected={payDate}
                  onChange={(date: Date) => setPayDate(date)}
                  dateFormat="dd/MM/yyyy"
                  className="mt-1 py-2 w-full text-gray-500 border focus:border-global-input-focus border-gray-300 focus:ring-0"
                  placeholderText="Obtained at.."
                  showPopperArrow={false}
                  autoComplete="off"
                />
              </div>
            </div>

            <div className="w-full">
              <label className="mb-2">
                <span className="mb-1 text-gray-900 text-base font-medium">
                  {t("subs.paymentType")}
                </span>
                <br />
                <select
                  className="mt-1 py-2 w-full text-gray-500 border focus:border-global-input-focus border-gray-300 focus:ring-0"
                  defaultValue={paymentType}
                  onChange={(e) => setPaymentType(e.target.value)}
                >
                  <option value="cash">{t("subs.cash")}</option>
                  <option value="bank_transfer">
                    {t("subs.bankTransfer")}
                  </option>
                  <option value="postal">{t("subs.postal")}</option>
                  <option value="gift">{t("subs.gift")}</option>
                </select>
              </label>
            </div>
            <div className="w-full">
              <label className="mb-2 text-gray-900 text-base font-medium">
                Sub Type
                <br />
                <select
                  className="mt-1 py-2 w-full text-gray-500 border focus:border-global-input-focus border-gray-300 focus:ring-0"
                  defaultValue={subType}
                  onChange={(e) => setSubType(e.target.value)}
                >
                  <option value="teacher">Teacher</option>
                  <option value="patreon">Patreon</option>
                </select>
              </label>
            </div>
            <div className="w-full">
              <label className="mb-2 text-gray-900 text-base font-medium">
                Plan Type
                <br />
                <select
                  className="mt-1 py-2 w-full text-gray-500 border focus:border-global-input-focus border-gray-300 focus:ring-0"
                  defaultValue={planType}
                  onChange={(e) => setPlanType(e.target.value)}
                >
                  {plans.map((plan) => (
                    <option key={plan.id} value={plan.title}>
                      {plan.title}
                    </option>
                  ))}
                </select>
              </label>
            </div>
          </div>
        }
        footer={
          <div className="mt-5 sm:flex sm:flex-row-reverse sm:mt-4">
            <button
              type="button"
              className="sm: inline-flex justify-center px-4 py-1 w-full text-white text-base font-medium bg-users-create border border-transparent focus:outline-none shadow-sm focus:ring-2 focus:ring-users-create focus:ring-offset-2 sm:ml-3 sm:w-auto"
              onClick={editSubscription}
            >
              {t("subs.ms")}
            </button>
            <button
              type="button"
              className="sm: inline-flex justify-center mt-3 px-4 py-1 w-full hover:text-gray-500 text-gray-700 text-base font-medium bg-white border border-gray-300 focus:outline-none shadow-sm focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto"
              onClick={() => {
                setOpenEditModal(false);
                // setCertificateError(false);
                // setRegionError(false);
                // setDateError(false);
              }}
            >
              {t("UserCRUD.cancelButton")}
            </button>
          </div>
        }
      />
      <CreateModal
        openStatus={openDelete}
        setOpen={setOpenDelete}
        className=""
        header={<p className="mt-4">{t("subs.sd")}</p>}
        title={<div className="flex flex-col gap-4">{t("subs.dp")}</div>}
        footer={
          <div className="mt-5 sm:flex sm:flex-row-reverse sm:mt-4">
            <button
              type="button"
              className="sm: inline-flex justify-center px-4 py-1 w-full text-white text-base font-medium bg-red-600 focus:bg-red-600 border border-transparent focus:outline-none shadow-sm focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto"
              onClick={deleteSubscription}
            >
              {t("subs.sd")}
            </button>
            <button
              type="button"
              className="sm: inline-flex justify-center mt-3 px-4 py-1 w-full hover:text-gray-500 text-gray-700 text-base font-medium bg-white border border-gray-300 focus:outline-none shadow-sm focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto"
              onClick={() => {
                setOpenDelete(false);
                // setCertificateError(false);
                // setRegionError(false);
                // setDateError(false);
              }}
            >
              {t("UserCRUD.cancelButton")}
            </button>
          </div>
        }
      />
    </div>
  );
};

export default SubsHistory;
