import { CMS_BE_URL } from "api/urls";
import {
  MassUpdateExamsDtoExclude,
  MassUpdateExamsDtoInclude,
} from "./exams.api";
import { ExamsFiltersType } from "../../pages/Exams/types";

export type UpdateExamsFilters = Omit<
  ExamsFiltersType,
  "pageIndex" | "pageSize" | "sortCol" | "sortOrder"
>;

type LogicalQueryParameter = Partial<{
  in: string[];
  notIn: string[];
}>;

type NumericQueryParameter = Partial<{
  gt: string;
  gte: string;
  lt: string;
  lte: string;
  between: string[];
  notBetween: string[];
}>;

function handleLogicalQueryParameter(
  name: string,
  filters: LogicalQueryParameter
) {
  if (filters["in"]) return `${name}[in]=${JSON.stringify(filters["in"])}`;
  else if (filters["notIn"])
    return `${name}[notIn]=${JSON.stringify(filters["notIn"])}`;
  else return "";
}

function handleNumericQueryParameter(
  name: string,
  filters: NumericQueryParameter
) {
  if (filters)
    return Object.entries(filters)
      .map(([key, value]) => `${name}[${key}]=${JSON.stringify(value)}`)
      .join("&");
  return "";
}

function constructExamsEnchancedQuery(
  filters: UpdateExamsFilters | ExamsFiltersType
) {
  return Object.entries(filters)
    .filter(([, value]) => !!value)
    .map(([key, value]) => {
      if (key == "from")
        return handleLogicalQueryParameter("from", value as any);
      else if (key == "to")
        return handleLogicalQueryParameter("to", value as any);
      else if (value) return `${key}=${value}`;
    })
    .join("&");
}

export const createExamURL = (): string => `${CMS_BE_URL}/exams`;
export function getExamsURL(filters: {
  from?: string;
  to?: string;
  tags?: string;
  visible?: string;
  pageSize?: number;
  pageIndex?: number;
  sortCol?: string;
  sortOrder?: string;
  searchString?: string;
}): string {
  const q: string[] = [];
  Object.entries(filters).forEach(([key, value]) => {
    if (value) q.push(`${key}=${value}`);
  });
  return `${CMS_BE_URL}/exams?`.concat(q.join("&"));
}
export const updateExamURL = (examId: string): string =>
  `${CMS_BE_URL}/exams/${examId}`;
export const deleteExamURL = (examId: string): string =>
  `${CMS_BE_URL}/exams/${examId}`;
export const getExamURL = (examId: string): string =>
  `${CMS_BE_URL}/exams/${examId}`;

export const constructUpdateExamsURL = (
  filters:
    | MassUpdateExamsDtoInclude["filters"]
    | MassUpdateExamsDtoExclude["filters"]
) => {
  return `${CMS_BE_URL}/exams?${constructExamsEnchancedQuery(filters)}`;
};

export const constructDeleteExamsURL = (
  filters:
    | MassUpdateExamsDtoInclude["filters"]
    | MassUpdateExamsDtoExclude["filters"]
) => {
  return `${CMS_BE_URL}/exams/massDelete?${constructExamsEnchancedQuery(
    filters
  )}`;
};
