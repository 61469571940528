import React, { useEffect, useMemo } from "react";
import { useMap, MapContainer, TileLayer, Marker } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { LatLngExpression } from "leaflet";
import L from "leaflet";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";

const DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
});

L.Marker.prototype.options.icon = DefaultIcon;

export function FlyView({ position }: { position: LatLngExpression }) {
  const map = useMap();
  map.flyTo(position, 13, { duration: 1 });
  return null;
}

function Map({ position }: { position: any }) {
  position = position.map((position: any) => {
    if (position) return position;
    return 0;
  });
  return (
    <MapContainer
      center={position}
      zoom={13}
      scrollWheelZoom={false}
      className="z-0 w-full h-full"
    >
      <FlyView position={position} />
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker position={position} />
    </MapContainer>
  );
}

export default React.memo(Map);
