import {
  getReceipt,
  receiptsDetailsColumns,
  updateReceipt,
} from "api/Receipts/receipts.api";
import { format } from "date-fns";
import React, { useEffect, useMemo, useRef, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import Select from "react-select";
import { toast } from "react-toastify";
import DraggableTable from "shared/components/Table/DraggableTable";
import GoBack from "shared/components/UI/GoBack";
import Message from "shared/components/UI/Message";
import Overlay from "shared/components/UI/Overlay";
import Spinner from "shared/components/UI/Spinner";
import {
  EnchancedReceipt,
  ReceiptAddress,
  UpdateReceiptDto,
} from "shared/interfaces/Receipt.interface";
import { User } from "shared/interfaces/User.interface";
import { selectLookups } from "store/lookups/lookupsReducer";
import { selectTranslationLanguage } from "store/translationStore/translationReducer";
import {
  capitalizeFirst,
  getInputStyles,
  getReactSelectStyles,
  lowercaseKeys,
  LowercaseKeys,
  Round,
  toISOString,
} from "utils/utils";
import Information from "./Information";
import Pricing from "./Pricing";
import SelectUser from "./SelectUser";
import SoftOverlay from "./SoftOverlay";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import DisplayInputError from "shared/components/UI/DisplayInputError";
import { getUser } from "api/Users/users.api";
import {
  calcPrice,
  calcPriceQty,
  calcTotalNetPrice,
  calcTotalPrice,
  calcVatPriceQty,
  changeVatPercentageFormat,
} from "./CreateReceipt";
import { ErrorMessage } from "@hookform/error-message";
import { useSearchParams } from "react-router-dom";
import SelectProduct from "./SelectProduct";
import ShortUserReceiptDetails from "./ShortUserReceiptDetails";

export type UpdateReceiptForm = Omit<
  UpdateReceiptDto,
  "status" | "receipt_date" | "user_id"
> & {
  status: {
    label: string;
    value: string;
  };
  receipt_date: Date;
  user_id: LowercaseKeys<User>;
  user_address: ReceiptAddress | null;
  name: string;
  surname: string;
  card_number: string | null;
};

export function enchancedReceiptToUpdateForm(
  receipt: EnchancedReceipt,
  user: LowercaseKeys<User>
): UpdateReceiptForm {
  const temp: UpdateReceiptForm = {
    receipt_year: Number(receipt.receipt_year),
    status: {
      label: capitalizeFirst(receipt.status) as string,
      value: receipt.status,
    },
    exclude_vat: receipt.exclude_vat,
    total_amount: Number(receipt.total_amount),
    receipt_date: new Date(receipt.receipt_date.slice(0, -1)),
    receipt_place: receipt.receipt_place,
    user_id: user,
    internal_notes: receipt.internal_notes,
    printable_notes: receipt.printable_notes,
    last_modifier: receipt.last_modifier,
    creator: receipt.creator,
    last_print_date: receipt.last_print_date,
    created_at: receipt.created_at,
    updated_at: receipt.updated_at,
    receiptDetails: changeVatPercentageFormat(receipt.details as any, true),
    user_address: receipt.receipt_address,
    name: receipt.name,
    surname: receipt.surname,
    card_number: receipt.card_number,
  };
  return temp;
}

const UpdateReceipt = () => {
  const lang = selectTranslationLanguage();
  const { t } = useTranslation("common");

  const receiptsStateOptions = useMemo(
    () => [
      {
        label: t("receipt.draft"),
        value: "draft",
      },
      {
        label: t("receipt.final"),
        value: "final",
      },
    ],
    [t]
  );
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const from = searchParams.get("from");

  const productReference = useRef<null | string>(null);
  const [productSelectOpen, setProductSelectOpen] = useState(false);
  const [number, setNumber] = useState(0);
  const lookups = selectLookups();
  const regionsOptions =
    lookups.lookupList.regions.map((region) => ({
      value: region.PK_REGION,
      label: region.PK_REGION,
    })) || [];
  const [loading, setLoading] = useState(false);
  const [userSelectOpen, setUserSelectOpen] = useState(false);
  const schema = yup
    .object({
      user_id: yup.object().required(t("receipt.userRequired")),
      status: yup.object().required(t("receipt.statusRequired")),
      receipt_date: yup.date().required(t("receipt.receiptDateRequired")),
      receipt_place: yup.string().required(t("receipt.receiptPlaceRequired")),
      receiptDetails: yup
        .array()
        .of(
          yup.object().shape({
            // ordering: yup.number(),
            title: yup.string(),
            description: yup.string(),
            quantity: yup
              .number()
              .typeError(t("receipt.quantityInteger"))
              .integer(t("receipt.quantityInteger"))
              .min(1, t("quantityLtOne")),
            unit_price: yup
              .number()
              .typeError(t("receipt.priceGtZero"))
              .min(0, t("receipt.priceGtZero")),
            vat_percentage: yup
              .number()
              .typeError(t("receipt.taxInteger"))
              .integer(t("receipt.taxInteger"))
              .min(0, t("receipt.taxGteZero"))
              .max(100, t("receipt.taxLte")),
            is_vat_included: yup.boolean(),
            total: yup.number().typeError(t("receipt.totalInteger")),
          })
        )
        .min(1, t("receipt.receiptDetailsRequired")),
    })
    .required();
  const form = useForm<UpdateReceiptForm>({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      receipt_date: new Date(),
      receiptDetails: [],
    },
  });

  const columns = useMemo(() => receiptsDetailsColumns(lang), [lang]);
  const data = useMemo(() => {
    return form.watch("receiptDetails").map((product, i) => ({
      id: "row" + i,
      select: (
        <div>
          <button
            type="button"
            onClick={() => {
              productReference.current = `receiptDetails.${i}`;
              setProductSelectOpen(true);
            }}
            className="px-4 py-2 text-white bg-primary border border-primary"
          >
            {t("receipt.select")}
          </button>
        </div>
      ),
      col1: (
        <div className="flex items-center h-full">
          <input
            type="text"
            className="w-full border-b border-l-0 border-r-0 border-t-0 focus:border-primary border-primary"
            {...form.register(`receiptDetails.${i}.title`)}
          />
        </div>
      ),
      col2: (
        <div className="flex items-center h-full">
          <input
            type="text"
            className="w-full border-b border-l-0 border-r-0 border-t-0 focus:border-primary border-primary"
            {...form.register(`receiptDetails.${i}.description`)}
          />
        </div>
      ),
      col3: (
        <div className="flex flex-col gap-1 items-center">
          <input
            type="text"
            className="w-full border-b border-l-0 border-r-0 border-t-0 focus:border-primary border-primary"
            {...form.register(`receiptDetails.${i}.quantity`, {
              onChange: (e) => {
                form.setValue(
                  `receiptDetails.${i}.total`,
                  calcPrice({
                    ...product,
                    quantity: Number(e.target.value),
                  })
                );
              },
            })}
          />
          <ErrorMessage
            errors={form.formState.errors}
            name={`receiptDetails.${i}.quantity`}
            render={({ message }) => (
              <p className="text-red-500 text-xs">{message}</p>
            )}
          />
        </div>
      ),
      col4: (
        <div className="flex flex-col gap-1 items-center">
          <input
            type="text"
            className={`w-full border-b border-l-0 border-r-0 border-t-0 focus:border-primary border-primary ${
              form.watch(`receiptDetails.${i}.is_vat_included`)
                ? "text-gray-400"
                : ""
            }`}
            {...form.register(`receiptDetails.${i}.unit_price`, {
              onChange: (e) => {
                form.setValue(
                  `receiptDetails.${i}.total`,
                  calcPrice({
                    ...product,
                    unit_price: Number(e.target.value),
                  })
                );
              },
            })}
            disabled={form.watch(`receiptDetails.${i}.is_vat_included`)}
          />
          <ErrorMessage
            errors={form.formState.errors}
            name={`receiptDetails.${i}.unit_price`}
            render={({ message }) => (
              <p className="text-red-500 text-xs">{message}</p>
            )}
          />
        </div>
      ),
      col5: (
        <div className="flex flex-col gap-1 items-center h-full">
          <input
            type="text"
            className="w-full border-b border-l-0 border-r-0 border-t-0 focus:border-primary border-primary"
            {...form.register(`receiptDetails.${i}.vat_percentage`, {
              onChange: (e) => {
                if (form.getValues(`receiptDetails.${i}.is_vat_included`)) {
                  form.setValue(
                    `receiptDetails.${i}.unit_price`,
                    Round(
                      form.getValues(`receiptDetails.${i}.total`) /
                        (1 + Number(e.target.value) / 100)
                    )
                  );
                } else {
                  form.setValue(
                    `receiptDetails.${i}.total`,
                    calcPrice({
                      ...product,
                      vat_percentage: Number(e.target.value),
                    })
                  );
                }
              },
            })}
          />
          <ErrorMessage
            errors={form.formState.errors}
            name={`receiptDetails.${i}.vat_percentage`}
            render={({ message }) => (
              <p className="text-red-500 text-xs">{message}</p>
            )}
          />
        </div>
      ),
      col6: (
        <div className="flex flex-col gap-1 items-center h-full">
          <input
            type="text"
            className={`w-full border-b border-l-0 border-r-0 border-t-0 focus:border-primary border-primary ${
              form.watch(`receiptDetails.${i}.is_vat_included`)
                ? ""
                : "text-gray-400"
            }`}
            {...form.register(`receiptDetails.${i}.total`, {
              onChange: (e) => {
                form.setValue(
                  `receiptDetails.${i}.unit_price`,
                  Round(
                    Number(e.target.value) /
                      (1.0 +
                        form.getValues(`receiptDetails.${i}.vat_percentage`) /
                          100)
                  )
                );
              },
            })}
            disabled={!form.watch(`receiptDetails.${i}.is_vat_included`)}
          />
          <div className="flex gap-2 items-center">
            <input
              id={`isVatIncluded${i}`}
              type="checkbox"
              {...form.register(`receiptDetails.${i}.is_vat_included`)}
              className="text-primary"
            />
            <label htmlFor={`isVatIncluded${i}`} className="text-xs">
              {t("receipt.calcVat")}
            </label>
          </div>
          <ErrorMessage
            errors={form.formState.errors}
            name={`receiptDetails.${i}.total`}
            render={({ message }) => (
              <p className="text-red-500 text-xs">{message}</p>
            )}
          />
        </div>
      ),
      col7: (
        <div className="flex items-center h-full">
          <div className="flex flex-col gap-1">
            <div>{calcPriceQty(product)}</div>
            <div className="text-xs">Which Vat: {calcVatPriceQty(product)}</div>
          </div>
        </div>
      ),
      col8: (
        <div className="flex items-center h-full">
          <button
            type="button"
            onClick={(e) => {
              const receiptDetails = [...form.getValues(`receiptDetails`)];
              receiptDetails.splice(i, 1);
              form.setValue("receiptDetails", receiptDetails, {
                shouldValidate: true,
              });
            }}
            className="text-red-500"
          >
            {t("crud.delete")}
          </button>
        </div>
      ),
    }));
  }, [form.watch("receiptDetails"), form.formState]);

  const onSubmit = form.handleSubmit((data) => {
    if (id) {
      const temp: UpdateReceiptDto = {
        ...data,
        receipt_year: Number(format(form.getValues("receipt_date"), "yyyy")),
        total_amount: data.exclude_vat
          ? calcTotalNetPrice(data.receiptDetails)
          : calcTotalPrice(data.receiptDetails),
        receipt_place: data.receipt_place,
        status: data.status.value as "draft" | "final",
        receipt_date: toISOString(data.receipt_date),
        user_id: data.user_id.pk_user,
        receiptDetails: changeVatPercentageFormat(data.receiptDetails, false),
      };
      setLoading(false);
      updateReceipt(id, temp)
        .then(() => {
          toast.success(
            Message({
              entity: "Receipt",
              action: "update",
              lang,
            })
          );
        })
        .catch(
          (err: any) => {
            if (err.response?.data?.errors?.[0]?.message) {
              toast.error(err.response?.data?.errors?.[0]?.message);
            } else {
              toast.error(err.response?.data?.message);
            }
          }
          // toast.error(
          //   Message({
          //     entity: "Receipt",
          //     action: "update",
          //     error: t("genericError"),
          //     lang,
          //   })
          // )
        )
        .finally(() => setLoading(false));
    }
  });

  useEffect(() => {
    if (id) {
      setLoading(true);
      getReceipt(id)
        .then((res) => {
          return res.data.receipt as EnchancedReceipt;
        })
        .then((receipt) =>
          getUser(receipt.user_id).then((res) => ({
            receipt,
            user: lowercaseKeys(res.data) as LowercaseKeys<User>,
          }))
        )
        .then((data) => {
          setNumber(data.receipt.progressive_id);
          form.reset(enchancedReceiptToUpdateForm(data.receipt, data.user));
        })
        .catch((err: any) => {
          if (err.response?.data?.errors?.[0]?.message) {
            toast.error(err.response?.data?.errors?.[0]?.message);
          } else {
            toast.error(err.response?.data?.message);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  return (
    <Overlay active={loading} spinner={<Spinner />}>
      <>
        <div className="flex flex-col p-10 h-full overflow-y-auto">
          <form className="flex flex-col flex-grow gap-5" onSubmit={onSubmit}>
            <GoBack
              uri={from ? `users/${from}?current=receipts` : "receipts"}
            />
            <div className="flex gap-10 items-center justify-between">
              <h1 className="text-3xl font-bold">
                {t("receipt.updateReceipt")}
              </h1>
              <button
                type="submit"
                className="px-4 py-2 text-white bg-primary border border-primary"
              >
                {t("receipt.save")}
              </button>
            </div>
            <h2 className="text-2xl font-bold capitalize">
              {t("receipt.information")}
            </h2>
            <div className="grid gap-5 grid-cols-4">
              <div className="flex flex-col gap-5">
                <div className="flex flex-wrap gap-5 justify-between">
                  <h3 className="text-xl font-semibold">
                    {t("receipt.billingUser")}
                  </h3>
                  <button
                    type="button"
                    className="px-4 py-2 text-white bg-primary border border-primary"
                    onClick={() => setUserSelectOpen(true)}
                  >
                    {t("crud.update")}
                  </button>
                </div>
                <FormProvider {...form}>
                  <>
                    {form.watch("user_id") ? (
                      <ShortUserReceiptDetails />
                    ) : (
                      <div className="flex flex-col gap-5">
                        <div>{t("receipt.noUserSelected")}</div>
                        <DisplayInputError
                          message={
                            (form.formState.errors.user_id as any)?.message
                          }
                        />
                      </div>
                    )}
                  </>
                </FormProvider>
              </div>
              <div className="flex flex-col gap-5">
                <h3 className="text-xl font-semibold">{t("receipt.status")}</h3>
                <div className="flex flex-col gap-2 w-72">
                  <Controller
                    name="status"
                    control={form.control}
                    render={({ field }) => {
                      return (
                        <Select
                          value={field.value}
                          placeholder={t("receipt.status")}
                          styles={getReactSelectStyles(false)}
                          isClearable={true}
                          options={receiptsStateOptions}
                          onChange={(e) => field.onChange(e)}
                        />
                      );
                    }}
                  />
                  <DisplayInputError
                    message={(form.formState.errors.status as any)?.message}
                  />
                </div>
                <div className="flex flex-col gap-2 w-72">
                  <Controller
                    name="receipt_date"
                    control={form.control}
                    render={({ field }) => {
                      return (
                        <div>
                          <ReactDatePicker
                            selected={field.value}
                            onChange={(e) => field.onChange(e)}
                            showTimeSelect={true}
                            dateFormat="dd/MM/yyyy h:mm aa"
                            className={getInputStyles(
                              !!form.formState.errors.receipt_date
                            )}
                            placeholderText={t("receipt.receiptDate")}
                            showPopperArrow={false}
                            autoComplete="off"
                          />
                        </div>
                      );
                    }}
                  />
                  <DisplayInputError
                    message={form.formState.errors.receipt_date?.message}
                  />
                </div>
                <div className="flex flex-col gap-2 w-72">
                  <input
                    type="text"
                    {...form.register("receipt_place")}
                    className={getInputStyles(
                      !!form.formState.errors.receipt_place
                    )}
                    placeholder={t("users.region")}
                  />
                  <DisplayInputError
                    message={
                      (form.formState.errors.receipt_place as any)?.message
                    }
                  />
                </div>
                <div>
                  {t("receipt.year")}:{" "}
                  {format(form.watch("receipt_date"), "yyyy")}
                </div>
                <div>No: {number}</div>
                <div className="flex gap-2 items-center w-72">
                  <input
                    id="excludeVat"
                    type="checkbox"
                    {...form.register("exclude_vat")}
                    className="text-primary"
                  />
                  <label htmlFor="excludeVat" className="whitespace-nowrap">
                    {t("receipt.excludeVat")}
                  </label>
                </div>
              </div>
              <div>
                <Pricing
                  products={form.watch("receiptDetails")}
                  excludeVat={form.watch("exclude_vat")}
                />
              </div>
              <div>
                <FormProvider {...form}>
                  <Information isCreate={false} />
                </FormProvider>
              </div>
            </div>
            <label htmlFor="internalNotes" className="text-lg font-semibold">
              {t("receipt.internalNotes")}
            </label>
            <textarea
              id="internalNotes"
              cols={30}
              rows={2}
              {...form.register("internal_notes")}
              className={getInputStyles(false)}
            ></textarea>
            <label htmlFor="receiptNotes" className="text-lg font-semibold">
              {t("receipt.receiptNotes")}
            </label>
            <textarea
              id="receiptsNotes"
              cols={30}
              rows={2}
              {...form.register("printable_notes")}
              className={getInputStyles(false)}
            ></textarea>
            <div className="flex gap-5 justify-between">
              <h2 className="text-2xl font-bold capitalize">Receipt Details</h2>
              <button
                type="button"
                onClick={() => {
                  const receiptDetails = form.getValues("receiptDetails");
                  form.setValue(
                    "receiptDetails",
                    [
                      ...receiptDetails,
                      {
                        title: "",
                        description: "",
                        notes: "",
                        quantity: 1,
                        unit_price: 0,
                        vat_percentage: 0,
                        vat_costs: 0,
                        is_vat_included: false,
                        total: 0,
                      } as any,
                    ] as any,
                    {
                      shouldValidate: true,
                    }
                  );
                }}
                className="px-4 py-2 text-white bg-primary border border-primary rounded-sm"
              >
                {t("receipt.addLine")}
              </button>
            </div>
            <DraggableTable
              columns={columns}
              data={data}
              state={form.watch("receiptDetails")}
              setState={(state: any) =>
                form.setValue("receiptDetails", state, {
                  shouldValidate: true,
                })
              }
            />
            <DisplayInputError
              message={(form.formState.errors.receiptDetails as any)?.message}
            />
          </form>
        </div>
        <FormProvider {...form}>
          {userSelectOpen && (
            <SoftOverlay close={() => setUserSelectOpen(false)}>
              <SelectUser
                close={() => setUserSelectOpen(false)}
                isEdit={true}
              />
            </SoftOverlay>
          )}
          {productSelectOpen && (
            <SoftOverlay close={() => setProductSelectOpen(false)}>
              {productReference.current && (
                <SelectProduct
                  close={() => setProductSelectOpen(false)}
                  reactHookFormName={productReference.current}
                />
              )}
            </SoftOverlay>
          )}
        </FormProvider>
      </>
    </Overlay>
  );
};

export default UpdateReceipt;
