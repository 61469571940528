import React, { useEffect, useState, useMemo } from "react";
import { useAppDispatch } from "store/storeHooks";
import {
  Column,
  useFlexLayout,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { SearchIcon, PlusIcon } from "@heroicons/react/solid";
import {
  EyeIcon,
  ExclamationIcon,
  FilterIcon,
  PencilIcon,
  TrashIcon,
  XIcon,
  DotsVerticalIcon,
  EyeOffIcon,
  BanIcon,
} from "@heroicons/react/outline";
import { Link } from "react-router-dom";
import { useDebounce } from "shared/hooks/useDebounce";
import Pagination from "shared/components/Table/Pagination";
import Table from "shared/components/Table/Table";
import Modal from "shared/components/UI/Modal";
import { useTranslation } from "react-i18next";
import { selectLoggedInUser } from "store/authStore/authReducer";
import {
  deleteExamThunk,
  getExamsThunk,
  selectExams,
  updateExamThunk,
} from "store/examsStore/examsReducer";
import { format } from "date-fns";
import Spinner from "shared/components/UI/Spinner";
import Overlay from "shared/components/UI/Overlay";
import {
  deleteExams,
  getExam,
  getExamsColumns,
  updateExams,
} from "api/Exams/exams.api";
import { CreateExam, Exam } from "shared/interfaces/Exam.interface";
import {
  getVisibilityFilterOptions,
  visibilityFilterOptions,
} from "shared/interfaces/Visibility.interface";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { getTagsThunk, selectTags } from "store/tagsStore/tagsReducer";
import TablePopup, { PortalType } from "shared/components/UI/TablePopup";
import {
  addStyles,
  dateToQuery,
  getInputStyles,
  getReactSelectStyles,
  regionToQuery,
  tagsToQuery,
} from "utils/utils";
import { Popover } from "@headlessui/react";
import { ExamsFiltersType } from "./types";
import { toast } from "react-toastify";
import Message from "shared/components/UI/Message";
import { selectLookups } from "store/lookups/lookupsReducer";
import { PermissionDomain } from "shared/interfaces/Permission.interface";
import { RolePermissionCrudAction } from "shared/interfaces/RolePermission.interface";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "shared/styles/datePicker.css";
import { selectTranslationLanguage } from "store/translationStore/translationReducer";
import { RiFileExcel2Fill } from "react-icons/ri";
import * as XLSX from "xlsx";

const ExamsPage: () => JSX.Element = () => {
  const lang = selectTranslationLanguage();
  const { t } = useTranslation("common");
  const tags = selectTags();
  const {
    getValues,
    register,
    setValue,
    formState: { errors, isDirty },
    control,
    watch,
  } = useForm<ExamsFiltersType>({
    defaultValues: {
      from: undefined,
      to: undefined,
      tags: [],
      region: undefined,
      visible: { value: "all", label: lang === "en" ? "All " : "Tutti " },
      pageSize: 10,
      pageIndex: 0,
      searchString: "",
    },
  });
  const [portal, setPortal] = useState<PortalType<Exam>>({
    open: false,
    top: 0,
    left: 0,
    entity: null,
  });
  const debouncedSearchText = useDebounce(watch("searchString"), 300);
  const exams = selectExams();
  const dispatch = useAppDispatch();
  const loggedInUser = selectLoggedInUser();
  const [openModal, setOpenModal] = useState(false);
  const [allSelected, setAllSelected] = useState(false);
  const [excludeExams, setExcludeExams] = useState<string[]>([]);
  const [includeExams, setIncludeExams] = useState<string[]>([]);
  const [examToDelete, setExamToDelete] = useState("");
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [tagsOptions, setTagsOptions] = useState<any>([]);
  const [regionsOptions, setRegionsOptions] = useState<any>([]);
  const [updateLoading, setUpdateLoading] = useState(false);
  const lookups = selectLookups();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const handleDeleteModal = () => {
    setOpenDeleteModal(true);
  };

  const handleModal = (examToDelete: string) => {
    setExamToDelete(examToDelete);
    setOpenModal(true);
  };

  function deleteExamsHelper() {
    setUpdateLoading(true);
    if (allSelected) {
      deleteExams({
        filters: constructGetExamsThunkAction(0),
        exclude: excludeExams,
      })
        .catch(
          (err: any) => {
            if (err.response?.data?.errors?.[0]?.message) {
              toast.error(err.response?.data?.errors?.[0]?.message);
            } else {
              toast.error(err.response?.data?.message);
            }
          }
          // toast.error(
          //   Message({
          //     action: "delete",
          //     entity: t("exams.exam"),
          //     error: t("genericError"),
          //     lang,
          //   })
          // )
        )
        .then((res: any) => {
          if (res["request"].status === 200) {
            toast.success(
              Message({
                action: "delete",
                entity: t("exam.exams"),
                lang,
              })
            );
          }
        })
        .then(() => getExams(0))
        .finally(() => {
          setUpdateLoading(false);
        });
    } else {
      deleteExams({
        filters: constructGetExamsThunkAction(0),
        include: includeExams,
      })
        .catch(
          (err: any) => {
            if (err.response?.data?.errors?.[0]?.message) {
              toast.error(err.response?.data?.errors?.[0]?.message);
            } else {
              toast.error(err.response?.data?.message);
            }
          }
          // toast.error(
          //   Message({
          //     action: "delete",
          //     entity: t("exams.exam"),
          //     error: t("genericError"),
          //     lang,
          //   })
          // )
        )
        .then((res: any) => {
          if (res["request"].status === 200) {
            toast.success(
              Message({
                action: "delete",
                entity: t("exam.exams"),
                lang,
              })
            );
          }
        })
        .then(() => getExams(0))
        .finally(() => {
          setUpdateLoading(false);
        });
    }
    setAllSelected(false);
    setIncludeExams([]);
    setExcludeExams([]);

    setOpenDeleteModal(false);
  }

  function updateExamsHelper(visible: boolean) {
    setUpdateLoading(true);
    if (allSelected) {
      updateExams({
        filters: constructGetExamsThunkAction(0),
        values: {
          visible,
        },
        exclude: excludeExams,
      })
        .catch(
          (err: any) => {
            if (err.response?.data?.errors?.[0]?.message) {
              toast.error(err.response?.data?.errors?.[0]?.message);
            } else {
              toast.error(err.response?.data?.message);
            }
          }
          // toast.error(
          //   Message({
          //     action: "update",
          //     entity: "Exams",
          //     error: t("genericError"),
          //     lang,
          //   })
          // )
        )
        .then((res: any) => {
          if (res["request"].status === 200) {
            toast.success(
              Message({
                action: "update",
                entity: t("exam.exams"),
                lang,
              })
            );
          }
        })
        .then(() => getExams(0))
        .finally(() => {
          setUpdateLoading(false);
        });
    } else {
      updateExams({
        filters: constructGetExamsThunkAction(0),
        values: {
          visible,
        },
        include: includeExams,
      })
        .catch(
          (err: any) => {
            if (err.response?.data?.errors?.[0]?.message) {
              toast.error(err.response?.data?.errors?.[0]?.message);
            } else {
              toast.error(err.response?.data?.message);
            }
          }
          // toast.error(
          //   Message({
          //     action: "update",
          //     entity: "Exams",
          //     error: t("genericError"),
          //     lang,
          //   })
          // )
        )
        .then((res: any) => {
          if (res["request"].status === 200) {
            toast.success(
              Message({
                action: "update",
                entity: t("exam.exams"),
                lang,
              })
            );
          }
        })
        .then(() => getExams(0))
        .finally(() => {
          setUpdateLoading(false);
        });
    }
    setAllSelected(false);
    setIncludeExams([]);
    setExcludeExams([]);
  }

  function canAccess(
    domain: PermissionDomain,
    action: RolePermissionCrudAction,
    exams?: Exam
  ): boolean {
    if (loggedInUser.permissions["*"]) {
      return true;
    } else {
      switch (loggedInUser.permissions[domain][action]) {
        case "NONE": {
          return false;
        }
        case "OWN": {
          if (action === "CREATE") return true;
          if (exams && exams.creator_id === loggedInUser.user?.PK_USER)
            return true;
          return false;
        }
        case "ANY": {
          return true;
        }
        default: {
          return false;
        }
      }
    }
  }

  function displayActions(exam: Exam, i: number) {
    const canRead = canAccess("exams", "READ", exam);
    const canUpdate = canAccess("exams", "UPDATE", exam);
    const canDelete = canAccess("exams", "DELETE", exam);
    if (!canRead && !canUpdate && !canDelete) {
      return (
        <div className="flex items-center h-full">
          <BanIcon className="w-5 h-5" />
        </div>
      );
    }
    return (
      <div className="relative flex items-center h-full">
        <button
          id={`portal-button-${i}`}
          type="button"
          onClick={() => {
            return setPortal((state) => {
              const thisButton = document.getElementById(`portal-button-${i}`);
              const position = thisButton?.getBoundingClientRect();
              return {
                open: !state.open,
                top: Number(position?.bottom),
                left: Number(
                  window.innerWidth - Number(position?.x) <= 200
                    ? Number(position?.left) - 200
                    : Number(position?.left)
                ),
                entity: exam,
              };
            });
          }}
        >
          <DotsVerticalIcon className="w-5 h-5" />
        </button>
      </div>
    );
  }

  const columns: Array<Column<Record<string, any>>> = useMemo(
    () =>
      getExamsColumns(
        {
          allSelected,
          setAllSelected,
          includeExams,
          setIncludeExams,
          excludeExams,
          setExcludeExams,
        },
        lang
      ),
    [
      lang,
      allSelected,
      setAllSelected,
      includeExams,
      setIncludeExams,
      excludeExams,
      setExcludeExams,
    ]
  );

  const data: Array<any> = useMemo(
    () =>
      exams.data.data.map((exam, i) => ({
        checkbox: (
          <div className="flex items-center h-full">
            <input
              type="checkbox"
              checked={(function () {
                if (allSelected) {
                  const index = excludeExams.indexOf(exam.event_id);
                  if (index === -1) {
                    return true;
                  } else {
                    return false;
                  }
                } else {
                  const index = includeExams.indexOf(exam.event_id);
                  if (index === -1) {
                    return false;
                  } else {
                    return true;
                  }
                }
              })()}
              onClick={() => {
                if (allSelected) {
                  const index = excludeExams.indexOf(exam.event_id);
                  if (index !== -1) {
                    setExcludeExams((excludedExams) => {
                      const temp = [...excludedExams];
                      temp.splice(index, 1);
                      return temp;
                    });
                  } else {
                    setExcludeExams((excludedExams) => [
                      ...excludedExams,
                      exam.event_id,
                    ]);
                  }
                } else {
                  const index = includeExams.indexOf(exam.event_id);
                  if (index !== -1) {
                    setIncludeExams((includedExams) => {
                      const temp = [...includedExams];
                      temp.splice(index, 1);
                      return temp;
                    });
                  } else {
                    setIncludeExams((includedExams) => [
                      ...includedExams,
                      exam.event_id,
                    ]);
                  }
                }
              }}
              className="text-primary"
            />
          </div>
        ),
        col1: (
          <div className="flex flex-col gap-3">
            <div className="truncate">{exam.title}</div>
            <div>
              {exam.tags.length === 0 ? (
                t("exams.noTags")
              ) : (
                <div className="flex flex-nowrap gap-2">
                  {exam.tags.map((tag, i) => (
                    <span
                      key={i}
                      className="p-1 text-white bg-events-tags rounded-sm truncate"
                    >
                      {tag.pk_tag}
                    </span>
                  ))}
                </div>
              )}
            </div>
          </div>
        ),
        col2: (
          <div className="flex items-center h-full truncate">
            {exam.location?.region}
          </div>
        ),
        col3: (
          <div className="flex items-center h-full truncate">
            {exam.start_date
              ? format(
                  new Date(exam.start_date.slice(0, -1)),
                  "dd MMMM yyyy, hh:mm:ss aaa"
                )
              : null}
          </div>
        ),
        col4: (
          <div className="flex items-center h-full truncate">
            {exam.end_date
              ? format(
                  new Date(exam.end_date.slice(0, -1)),
                  "dd MMMM yyyy, hh:mm:ss aaa"
                )
              : null}
          </div>
        ),
        col5: (
          <div className="flex items-center h-full truncate">
            <span
              className={`rounded-full px-2 py-1 capitalize ${
                exam.is_visible
                  ? "text-users-active bg-users-active-bg"
                  : "text-users-inactive bg-users-inactive-bg"
              }`}
            >
              {exam.is_visible ? t("exams.visible") : t("exams.invisible")}
            </span>
          </div>
        ),
        col6: displayActions(exam, i),
      })),
    [
      exams.data,
      lang,
      allSelected,
      setAllSelected,
      includeExams,
      setIncludeExams,
      excludeExams,
      setExcludeExams,
    ]
  );

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: getValues("pageSize") },
      pageCount: Math.ceil(exams.data.total / getValues("pageSize")),
      manualSortBy: true,
      manualPagination: true,
    },
    useSortBy,
    usePagination,
    useFlexLayout
  );

  const {
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, sortBy },
  } = tableInstance;

  useEffect(() => {
    dispatch(getTagsThunk()).then((res) => {
      if (res.meta.requestStatus === "rejected") {
        toast.error(
          Message({
            action: "read",
            entity: t("new.tags"),
            error: t("genericError"),
            lang,
            gender: "female",
          })
        );
      }
    });
  }, []);
  useEffect(() => {
    const tagsMap = tags.data.tags.map((tag) => {
      return {
        value: tag.pk_tag,
        label: tag.pk_tag,
      };
    });
    setTagsOptions(tagsMap);
  }, [tags]);

  useEffect(() => {
    const regionsMap = lookups.lookupList.regions.map((region) => ({
      value: region.PK_REGION,
      label: region.PK_REGION,
    }));
    setRegionsOptions(regionsMap);
  }, [lookups.lookupList.regions]);

  function constructGetExamsThunkAction(pageIndex: number): any {
    return {
      from: dateToQuery(getValues("from")),
      to: dateToQuery(getValues("to")),
      tags: tagsToQuery(getValues("tags")),
      region: regionToQuery(getValues("region")),
      visible: getValues("visible").value,
      pageIndex: pageIndex,
      pageSize: getValues("pageSize"),
      searchString: debouncedSearchText,
      sortCol: sortBy[0] ? sortBy[0].id : "",
      sortOrder: sortBy[0] ? (sortBy[0].desc ? "DESC" : "ASC") : "",
    };
  }

  function getExams(pageIndex: number) {
    return dispatch(
      getExamsThunk(constructGetExamsThunkAction(pageIndex))
    ).then((res) => {
      if (res.meta.requestStatus === "rejected") {
        toast.error(
          Message({
            action: "read",
            entity: t("exams.exam"),
            error: t("genericError"),
            lang,
            gender: "male",
          })
        );
      }
      return res;
    });
  }

  useEffect(() => {
    if (isDirty) getExams(0);
  }, [
    watch("tags"),
    watch("region"),
    watch("visible"),
    watch("from"),
    watch("to"),
  ]);

  useEffect(() => {
    getExams(0);
  }, [debouncedSearchText, sortBy]);

  const handleDeleteExam = (examToDelete: string) => {
    dispatch(
      deleteExamThunk({
        examId: examToDelete,
      })
    )
      .then((res) => {
        if (res.meta.requestStatus === "rejected") {
          toast.error(
            Message({
              action: "delete",
              entity: t("exam.exam"),
              error: t("genericError"),
              lang,
              gender: "male",
            })
          );
        } else {
          toast.success(
            Message({
              action: "delete",
              entity: t("exam.exam"),
              lang,
              gender: "male",
            })
          );
        }
      })
      .then(() => getExams(0));
    setOpenModal(false);
  };

  function toggleVisibility(exam: Exam): CreateExam {
    const temp: CreateExam = {
      is_visible: exam.is_visible ? "false" : "true",
    };
    return temp;
  }

  function portalContent() {
    if (portal.entity === null) return;
    const canRead = canAccess("exams", "READ", portal.entity);
    const canUpdate = canAccess("exams", "UPDATE", portal.entity);
    const canDelete = canAccess("exams", "DELETE", portal.entity);
    return (
      <div
        className="fixed z-50 flex flex-col gap-2 p-2 bg-white rounded-sm shadow-md"
        style={{
          width: "200px",
          top: portal.top,
          left: portal.left,
        }}
      >
        {canRead ? (
          <Link
            to={`/exams/${portal.entity.event_id}`}
            className="flex gap-2 items-center text-table-edit"
          >
            <PencilIcon className="w-5 h-5" />
            {canUpdate ? t("crud.update") : t("crud.read")}
          </Link>
        ) : null}
        {canDelete ? (
          <button
            onClick={() => handleModal(String(portal.entity?.event_id))}
            className="flex gap-2 items-center text-users-delete"
          >
            <TrashIcon className="w-5 h-5" />
            {t("crud.delete")}
          </button>
        ) : null}
        {canUpdate ? (
          <button
            className="flex gap-2 items-center text-users-edit"
            onClick={() => {
              dispatch(
                updateExamThunk({
                  examId: String(portal.entity?.event_id),
                  exam: toggleVisibility(portal.entity as Exam),
                })
              )
                .then((res) => {
                  if (res.meta.requestStatus === "rejected") {
                    toast.error(
                      Message({
                        action: "update",
                        entity: t("exam.exam"),
                        error: t("genericError"),
                        lang,
                        gender: "male",
                      })
                    );
                  } else {
                    toast.success(
                      Message({
                        action: "update",
                        entity: t("exam.exam"),
                        lang,
                        gender: "male",
                      })
                    );
                  }
                  return res;
                })
                .then(() => getExams(0));
            }}
          >
            {portal.entity.is_visible ? (
              <EyeOffIcon className="w-5 h-5" />
            ) : (
              <EyeIcon className="w-5 h-5" />
            )}
            {portal.entity.is_visible
              ? t("events.invisible")
              : t("events.visible")}
          </button>
        ) : null}
        {canRead ? (
          <button
            className="flex gap-2 items-center text-table-edit"
            onClick={() => {
              if (!portal.entity?.event_id) return;
              getExam(portal.entity?.event_id).then((res) => {
                const header = ["name", "surname", "email", "tel"];
                const csvData: any = res.data.data.users_going.map(
                  (user: {
                    name: string;
                    surname: string;
                    email: string;
                    contactdetails: {
                      contact_type: string;
                      contact_details: string;
                    }[];
                  }) => [
                    user.name ? user.name : "",
                    user.surname ? user.surname : "",
                    user.email ? user.email : "",
                    user.contactdetails
                      ? `${user.contactdetails[0].contact_details}`
                      : "",
                  ]
                );
                const data: any = {
                  data: [header, ...csvData],
                };
                const worksheet = XLSX.utils.aoa_to_sheet(data.data);
                const new_workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(
                  new_workbook,
                  worksheet,
                  "SheetJS"
                );
                XLSX.writeFile(
                  new_workbook,
                  `${format(Date.now(), "dd/MM/yyyy")}.xlsx`
                );
              });
            }}
          >
            <RiFileExcel2Fill className="w-5 h-5" />
            <span className="whitespace-nowrap">
              {t("exam.registeredUsers")}
            </span>
          </button>
        ) : null}
      </div>
    );
  }

  const dispatchNextPage = () => {
    if (canNextPage) {
      dispatch(getExamsThunk(constructGetExamsThunkAction(pageIndex + 1)));
      nextPage();
    }
  };

  const dispatchPreviousPage = () => {
    if (canPreviousPage) {
      dispatch(getExamsThunk(constructGetExamsThunkAction(pageIndex - 1)));
      previousPage();
    }
  };

  const dispatchGotoPage = (pageIndex: number) => {
    dispatch(getExamsThunk(constructGetExamsThunkAction(pageIndex)));
    gotoPage(pageIndex);
  };

  function showExtraButtons() {
    if (allSelected && excludeExams.length < exams.data.total) {
      return true;
    }
    if (!allSelected && includeExams.length) {
      return true;
    }
    return false;
  }

  return (
    <Overlay active={exams.loading || updateLoading} spinner={<Spinner />}>
      <div className="flex flex-col gap-5 p-10 h-full">
        <div className="text-3xl font-bold">{t("exams.exams")}</div>
        <div className="flex flex-col gap-5">
          {filtersOpen ? (
            <div className="flex flex-wrap gap-5">
              <div className="w-72">
                <Controller
                  name="from"
                  control={control}
                  render={({ field }) => {
                    return (
                      <div>
                        <DatePicker
                          id="from"
                          selected={field.value}
                          onChange={(e) => field.onChange(e)}
                          showTimeSelect={true}
                          dateFormat="dd/MM/yyyy"
                          className={addStyles(getInputStyles(false), "w-72")}
                          placeholderText={t("exams.dateFormat")}
                          showPopperArrow={false}
                          autoComplete="off"
                        />
                      </div>
                    );
                  }}
                />
              </div>
              <div className="w-72">
                <Controller
                  name="to"
                  control={control}
                  render={({ field }) => {
                    return (
                      <div>
                        <DatePicker
                          id="to"
                          selected={field.value}
                          onChange={(e) => field.onChange(e)}
                          showTimeSelect={true}
                          dateFormat="dd/MM/yyyy"
                          className={addStyles(getInputStyles(false), "w-72")}
                          placeholderText={t("exams.dateFormat")}
                          showPopperArrow={false}
                          autoComplete="off"
                        />
                      </div>
                    );
                  }}
                />
              </div>
              <div className="w-72">
                <Controller
                  name="tags"
                  control={control}
                  render={({ field }) => {
                    return (
                      <Select
                        placeholder={t("exams.tags")}
                        styles={getReactSelectStyles(false)}
                        isLoading={tags.loading}
                        inputId="tags"
                        isMulti={true}
                        options={tagsOptions}
                        value={field.value}
                        onChange={(e) => field.onChange(e)}
                        closeMenuOnSelect={false}
                        hideSelectedOptions={true}
                        controlShouldRenderValue={false}
                      />
                    );
                  }}
                />
              </div>
              <div className="w-72">
                <Controller
                  name="region"
                  control={control}
                  render={({ field }) => (
                    <Select
                      placeholder={t("exams.region")}
                      styles={getReactSelectStyles(false)}
                      isLoading={lookups.loading}
                      inputId="region"
                      isClearable={true}
                      value={field.value}
                      options={regionsOptions}
                      onChange={(e) => field.onChange(e)}
                    />
                  )}
                />
              </div>
              <div className="w-72">
                <Controller
                  name="visible"
                  control={control}
                  render={({ field }) => (
                    <Select
                      placeholder={t("exams.visible")}
                      styles={getReactSelectStyles(false)}
                      inputId="visible"
                      value={field.value}
                      options={getVisibilityFilterOptions(lang)}
                      onChange={(e) => field.onChange(e)}
                      isSearchable={false}
                    />
                  )}
                />
              </div>
              <div>
                <button
                  type="button"
                  className="px-4 py-2 text-white bg-primary border border-primary"
                  onClick={() => {
                    setValue("from", null as any);
                    setValue("to", null as any);
                    setValue("tags", []);
                    setValue("region", null as any);
                    setValue("visible", { label: "All", value: "all" });
                  }}
                >
                  {t("exams.clearAll")}
                </button>
              </div>
            </div>
          ) : null}
          <div className="flex flex-grow flex-wrap gap-5">
            {showExtraButtons() && canAccess("s", "UPDATE") ? (
              <Popover className="relative">
                <Popover.Button>
                  <button
                    type="button"
                    className="px-4 py-2 text-white bg-primary border border-primary rounded-sm"
                  >
                    {t("receipts.updateStatus")}
                  </button>
                </Popover.Button>
                <Popover.Panel
                  className="absolute left-0 top-0 flex flex-col gap-2 mt-16 p-2 w-full bg-white rounded-sm shadow-md"
                  style={{ zIndex: 9990 }}
                >
                  <button
                    onClick={() => {
                      updateExamsHelper(true);
                    }}
                  >
                    {t("exams.visible")}
                  </button>
                  <button
                    onClick={() => {
                      updateExamsHelper(false);
                    }}
                  >
                    {t("exams.invisible")}
                  </button>
                </Popover.Panel>
              </Popover>
            ) : null}
            {showExtraButtons() && canAccess("s", "DELETE") ? (
              <button
                className="px-4 py-2 text-white bg-red-500 border border-red-500 rounded-sm"
                onClick={() => {
                  handleDeleteModal();
                }}
              >
                {t("crud.delete")}
              </button>
            ) : null}
          </div>
          <div className="flex flex-wrap gap-5">
            <button onClick={() => setFiltersOpen((state) => !state)}>
              <FilterIcon className="w-6 h-6" />
            </button>
            <div className="flex flex-grow flex-wrap gap-5 justify-end">
              <div className="flex items-center border focus-within:border-global-input-focus border-gray-300">
                <div className="flex items-center justify-center p-2 h-full bg-white">
                  <SearchIcon className="w-5 h-5 text-gray-400" />
                </div>
                <input
                  type="text"
                  placeholder={t("exams.search")}
                  {...register("searchString")}
                  className="placeholder-gray-400 p-2 w-full border-0 focus:ring-0"
                />
              </div>
              {canAccess("exams", "CREATE") && (
                <Link to="/exams/create" className="h-full">
                  <button className="flex gap-2 items-center justify-center px-4 py-2 text-white whitespace-nowrap bg-global-createEntity border border-global-createEntity">
                    <PlusIcon className="w-5 h-5" />
                    <span className="whitespace-nowrap">
                      {t("exams.create")} {t("exams.exam")}
                    </span>
                  </button>
                </Link>
              )}
            </div>
          </div>
          {watch("tags").length ? (
            <div className="flex flex-wrap gap-3">
              {watch("tags").map((tag, i) => (
                <button
                  key={i}
                  className="flex gap-2 items-center p-2 hover:text-white hover:bg-global-filters-tag border border-global-filters-tag"
                  onClick={() => {
                    setValue(
                      "tags",
                      getValues("tags").filter((t) => t.label !== tag.label)
                    );
                  }}
                >
                  <span>{tag.value}</span>
                  <XIcon className="w-4 h-4" />
                </button>
              ))}
            </div>
          ) : null}
        </div>
        <Modal
          openStatus={openDeleteModal}
          setOpen={setOpenDeleteModal}
          icon={
            <ExclamationIcon
              className="w-6 h-6 text-red-600 bg-transparent"
              aria-hidden="true"
            />
          }
          header={
            <p>
              {t("deleteModal.delete")} {t("exams.exams")}
            </p>
          }
          title={
            <p>
              {includeExams.length == 1 && (
                <span>
                  {t("deleteModal.sure")} {t("exams.exam")} ?{" "}
                  {t("deleteModal.undone")}
                </span>
              )}
              {includeExams.length > 1 && (
                <span>
                  {t("deleteModal.sure_for_more")} {includeExams.length}{" "}
                  {t("exams.exams")} ? {t("deleteModal.undone")}
                </span>
              )}
              {allSelected && (
                <span>
                  {t("deleteModal.sure_for_more")}{" "}
                  {exams.data.total - excludeExams.length} {t("exams.exams")} ?{" "}
                  {t("deleteModal.undone")}
                </span>
              )}
            </p>
          }
          footer={
            <div className="flex justify-end mt-5 sm:mt-4">
              <button
                type="button"
                className="inline-flex justify-center mt-3 px-4 py-1 w-full text-black text-base font-medium bg-white border border-black focus:outline-none shadow-sm focus:ring-0 sm:mt-0 sm:w-auto"
                onClick={() => setOpenDeleteModal(false)}
              >
                {t("deleteModal.cancel")}
              </button>
              <button
                type="button"
                className="inline-flex justify-center px-4 py-1 w-full text-white text-base font-medium bg-red-600 border border-transparent focus:outline-none shadow-sm focus:ring-0 sm:ml-3 sm:w-auto"
                onClick={() => deleteExamsHelper()}
              >
                {t("deleteModal.delete")}
              </button>
            </div>
          }
        />
        <Table {...tableInstance} />
        <Pagination
          rowsCount={exams.data.total}
          pageIndex={pageIndex}
          pageSize={getValues("pageSize")}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          pageOptions={pageOptions}
          pageCount={pageCount}
          gotoPage={dispatchGotoPage}
          nextPage={dispatchNextPage}
          previousPage={dispatchPreviousPage}
          setPageSize={setPageSize}
        />
        <TablePopup
          isOpen={portal.open}
          close={() => setPortal((state) => ({ ...state, open: false }))}
        >
          {portalContent()}
        </TablePopup>
        <Modal
          openStatus={openModal}
          setOpen={setOpenModal}
          icon={
            <ExclamationIcon
              className="w-6 h-6 text-red-600 bg-transparent"
              aria-hidden="true"
            />
          }
          header={
            <p>
              {t("deleteModal.delete")} {t("exams.exam")}
            </p>
          }
          title={
            <p>
              {t("deleteModal.sure")} {t("exams.exam")}?{" "}
              {t("deleteModal.undone")}
            </p>
          }
          footer={
            <div className="flex justify-end mt-5 sm:mt-4">
              <button
                type="button"
                className="inline-flex justify-center mt-3 px-4 py-1 w-full text-black text-base font-medium bg-white border border-black focus:outline-none shadow-sm focus:ring-0 sm:mt-0 sm:w-auto"
                onClick={() => setOpenModal(false)}
              >
                {t("deleteModal.cancel")}
              </button>
              <button
                type="button"
                className="inline-flex justify-center px-4 py-1 w-full text-white text-base font-medium bg-red-600 border border-transparent focus:outline-none shadow-sm focus:ring-0 sm:ml-3 sm:w-auto"
                onClick={() => handleDeleteExam(examToDelete)}
              >
                {t("deleteModal.delete")}
              </button>
            </div>
          }
        />
      </div>
    </Overlay>
  );
};
export default ExamsPage;
