export interface NewsletterState {
  data: {
    total: number;
    pageIndex: number;
    data: [];
    users: number;
  };
  loading: boolean;
}

export const newsletterInitialState: NewsletterState = {
  data: {
    total: 0,
    pageIndex: 0,
    data: [],
    users: 0,
  },
  loading: false,
};
