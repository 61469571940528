import { Event } from "shared/interfaces/Event.interface";

export interface EventsState {
  data: {
    event: Event | null;
    message: string;
    eventTags: string[];
    total: number;
    pageIndex: number;
    data: Event[];
  };
  loading: boolean;
}

export const eventsInitialState: EventsState = {
  data: {
    event: null,
    message: "",
    eventTags: [],
    total: 0,
    pageIndex: 0,
    data: [],
  },
  loading: false,
};
