import { CMS_BE_URL } from "api/urls";

export const createTagURL = (): string => `${CMS_BE_URL}/tags`;

export const getTagsURL = (): string => {
  return `${CMS_BE_URL}/tags`;
};

export const getTagURL = (tagId: string): string => {
  return `${CMS_BE_URL}/tags/${encodeURIComponent(tagId)}`;
};

export const updateTagURL = (tagId: string): string =>
  `${CMS_BE_URL}/tags/${encodeURIComponent(tagId)}`;

export const deleteTagURL = (tagId: string): string =>
  `${CMS_BE_URL}/tags/${encodeURIComponent(tagId)}`;
