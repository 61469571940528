import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createOrder,
  CreateOrderDto,
  deleteOrder,
  getOrder,
  getOrders,
  updateOrder,
  UpdateOrderDto,
} from "api/Orders/orders.api";
import { GetOrdersFilters } from "api/Orders/urls";
import { useAppSelector } from "store/storeHooks";
import { OrderInitialState } from "./ordersState";

export const getOrdersThunk = createAsyncThunk(
  "ordersStore/getOrders",
  async (filters: GetOrdersFilters) => {
    const res = await getOrders(filters);
    return res.data;
  }
);

export const getOrderThunk = createAsyncThunk(
  "ordersStore/getOrder",
  async (orderId: string) => {
    const res = await getOrder(orderId);
    return res.data;
  }
);

export const createOrderThunk = createAsyncThunk(
  "ordersStore/createOrder",
  async (createOrderDto: CreateOrderDto) => {
    const res = await createOrder(createOrderDto);
    return res.data;
  }
);

export const updateOrderThunk = createAsyncThunk(
  "ordersStore/updateOrder",
  async ({
    orderId,
    updateOrderDto,
  }: {
    orderId: string;
    updateOrderDto: UpdateOrderDto;
  }) => {
    const res = await updateOrder(orderId, updateOrderDto);
    return res.data;
  }
);

export const deleteOrderThunk = createAsyncThunk(
  "ordersStore/deleteOrder",
  async (orderId: string) => {
    const res = await deleteOrder(orderId);
    return res.data;
  }
);

const ordersSlice = createSlice({
  name: "ordersStore",
  initialState: OrderInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createOrderThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createOrderThunk.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createOrderThunk.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getOrdersThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getOrdersThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(getOrdersThunk.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getOrderThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getOrderThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.data.order = action.payload;
    });
    builder.addCase(getOrderThunk.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(updateOrderThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateOrderThunk.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(updateOrderThunk.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(deleteOrderThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteOrderThunk.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteOrderThunk.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const selectOrders = () => useAppSelector((state) => state.orders);

export default ordersSlice;
