import axios, { AxiosResponse } from "axios";
import { createUserURL, editUserURL } from "api/Auth/urls";
import { CMS_BE_URL } from "api/urls";
import { CreateUser } from "shared/interfaces/User.interface";
import { deleteFile } from "api/Files/files.api";

const useUser: () => {
  createUser: (data: CreateUser, selectedRegions: string[]) => Promise<any>;
  editUser: (
    id: string,
    data: Record<string, unknown>,
    filesToDelete: any
  ) => Promise<any>;
  updateUserStatus: (
    id: string,
    status: string
  ) => Promise<AxiosResponse<any, any>>;
} = () => {
  const createUser = async (data: CreateUser) => {
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]: any) => {
      if (Array.isArray(value)) {
        value = JSON.stringify(value);
      }
      formData.append(key, value);
    });
    return axios.post(createUserURL(), formData);
  };

  const editUser = async (id: string, data: any, filesToDelete: any) => {
    if (filesToDelete) {
      await Promise.all(
        filesToDelete.map((file: any) =>
          deleteFile({
            fileId: file.FILE_ID,
            location: file.URL,
          })
        )
      );
    }
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]: any) => {
      if (key === "gallery" && data.gallery) {
        data.gallery.forEach((file: any) => {
          formData.append(key, file);
        });
      }
      if (Array.isArray(value) && key !== "gallery") {
        value = JSON.stringify(value);
      }
      formData.append(key, value);
    });
    return axios.put(`${editUserURL()}${id}`, formData);
  };

  const updateUserStatus = async (id: string, status: string) => {
    const formData = new FormData();
    formData.append("status", status);
    return axios.put(`${editUserURL()}${id}`, formData);
  };

  return {
    createUser,
    editUser,
    updateUserStatus,
  };
};

export default useUser;
