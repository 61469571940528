import { getUsers } from "api/Users/users.api";
import { debounce } from "lodash";
import React, { useCallback } from "react";
import { useForm, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import AsyncSelect from "react-select/async";
import { toast } from "react-toastify";
import Message from "shared/components/UI/Message";
import { User } from "shared/interfaces/User.interface";
import { selectTranslationLanguage } from "store/translationStore/translationReducer";
import { getReactSelectStyles, LowercaseKeys } from "utils/utils";
import { userAddressToReceipt } from "./CreateReceipt";
import ShortUserDetails from "./ShortUserDetails";

function SelectUser({
  close,
  isEdit,
}: {
  close: () => void;
  isEdit?: boolean;
}) {
  const lang = selectTranslationLanguage();
  const { t } = useTranslation("common");
  const form = useForm<{
    user: LowercaseKeys<User>;
  }>({
    mode: "onChange",
  });
  const receiptsContext = useFormContext(); // TODO add type

  const _loadOptions = (e: string, callback: any): any => {
    if (e === "") return callback([]);
    getUsers({
      searchString: e,
    })
      .then((res) => {
        const usersOptions = res.data.users.map((user: LowercaseKeys<User>) => {
          return {
            value: user,
            label: `${user.name} - ${user.surname} - ${user.email}${
              user.address_details && user.address_details[0]
                ? ` - ${user.address_details[0]?.fk_region}`
                : ""
            }`,
          };
        });
        callback(usersOptions);
      })
      .catch((err: any) => {
        if (err.response?.data?.errors?.[0]?.message) {
          toast.error(err.response?.data?.errors?.[0]?.message);
        } else {
          toast.error(err.response?.data?.message);
        }
        callback([]);
      });
  };
  const loadOptions = useCallback(debounce(_loadOptions, 400), []);

  return (
    <div className="flex flex-col gap-5 p-10 bg-white border rounded-md">
      <label htmlFor="selectUser" className="text-lg font-semibold">
        {t("receipt.selectUser")}
      </label>
      <div className="flex flex-col gap-10">
        <div
          style={{ width: "40rem" }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <AsyncSelect
            id="selectUser"
            cacheOptions
            defaultOptions
            value={
              form.watch("user")
                ? {
                    value: form.watch("user"),
                    label: `${form.watch("user")?.name} - ${
                      form.watch("user")?.surname
                    } - ${form.watch("user")?.email}${
                      form.watch("user")?.address_details &&
                      form.watch("user")?.address_details[0]
                        ? ` - ${
                            form.watch("user")?.address_details[0]?.fk_region
                          }`
                        : ""
                    }`,
                  }
                : undefined
            }
            placeholder={t("receipt.user")}
            styles={getReactSelectStyles(false)}
            inputId="searchUser"
            isClearable={true}
            loadOptions={loadOptions}
            onChange={(e) => {
              if (e) {
                form.setValue("user", e.value);
                form.trigger("user");
              }
            }}
          />
        </div>
        {form.watch("user") && (
          <div className="self-center text-gray-500">
            <ShortUserDetails user={form.watch("user")} />
          </div>
        )}
      </div>
      <div className="flex justify-end">
        <button
          type="button"
          onClick={() => {
            close();
            receiptsContext.setValue("user_id", form.getValues("user"), {
              shouldValidate: true,
            });
            if (isEdit) {
              if (
                form.getValues("user").address_details &&
                form.getValues("user").address_details[0]
              ) {
                receiptsContext.setValue(
                  "user_address",
                  userAddressToReceipt(
                    form.getValues("user").address_details[0]
                  )
                );
              } else {
                receiptsContext.setValue("user_address", null);
              }
              receiptsContext.setValue("name", form.getValues("user").name, {});
              receiptsContext.setValue(
                "surname",
                form.getValues("user").surname
              );
              receiptsContext.setValue(
                "card_number",
                form.getValues("user").card_number
              );
            }
          }}
          className="px-4 py-2 text-white bg-primary border border-primary rounded-sm"
        >
          {t("receipt.done")}
        </button>
      </div>
    </div>
  );
}

export default SelectUser;
