import React, { useEffect, useState, useMemo } from "react";
import Select from "react-select";
import { useAppDispatch } from "store/storeHooks";
import {
  Column,
  useFlexLayout,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { SearchIcon, PlusIcon, XIcon } from "@heroicons/react/solid";
import {
  ExclamationIcon,
  FilterIcon,
  DotsVerticalIcon,
  PencilIcon,
  TrashIcon,
  EyeIcon,
  EyeOffIcon,
  BanIcon,
} from "@heroicons/react/outline";
import { Link } from "react-router-dom";
import { useDebounce } from "shared/hooks/useDebounce";
import Pagination from "shared/components/Table/Pagination";
import Table from "shared/components/Table/Table";
import Modal from "shared/components/UI/Modal";
import { useTranslation } from "react-i18next";
import { selectLoggedInUser } from "store/authStore/authReducer";
import {
  deleteNewThunk,
  getNewsThunk,
  selectNews,
  updateNewThunk,
} from "store/newsStore/newsReducer";
import { format } from "date-fns";
import Spinner from "shared/components/UI/Spinner";
import Overlay from "shared/components/UI/Overlay";
import { deleteNews, getNewsColumns, updateNews } from "api/News/news.api";
import {
  CreateNew,
  featuredOptions,
  getFeaturedOptions,
  New,
} from "shared/interfaces/New.interface";
import { Popover } from "@headlessui/react";
import { Controller, useForm } from "react-hook-form";
import { getTagsThunk, selectTags } from "store/tagsStore/tagsReducer";
import { selectLookups } from "store/lookups/lookupsReducer";
import {
  getVisibilityFilterOptions,
  visibilityFilterOptions,
} from "shared/interfaces/Visibility.interface";
import TablePopup, { PortalType } from "shared/components/UI/TablePopup";
import {
  getInputStyles,
  getReactSelectStyles,
  regionToQuery,
  tagsToQuery,
} from "utils/utils";
import { NewsFiltersType } from "./types";
import { toast } from "react-toastify";
import Message from "shared/components/UI/Message";
import { PermissionDomain } from "shared/interfaces/Permission.interface";
import { RolePermissionCrudAction } from "shared/interfaces/RolePermission.interface";
import { selectTranslationLanguage } from "store/translationStore/translationReducer";
import { DevTool } from "@hookform/devtools";

const NewsPage: () => JSX.Element = () => {
  const lang = selectTranslationLanguage();
  const { t } = useTranslation("common");
  const lookups = selectLookups();
  const {
    register,
    setValue,
    getValues,
    formState: { errors, isDirty },
    control,
    watch,
  } = useForm<NewsFiltersType>({
    defaultValues: {
      user: "",
      featured: { label: lang === "en" ? "All " : "Tutti ", value: "" },
      tags: [],
      visible: { label: lang === "en" ? "All " : "Tutti ", value: "all" },
      region: undefined,
      pageSize: 10,
      pageIndex: 0,
      searchString: "",
    },
  });
  const tags = selectTags();
  const debouncedSearchText = useDebounce(watch("searchString"), 300);
  const debouncedUserText = useDebounce(watch("user"), 300);
  const news = selectNews();
  const dispatch = useAppDispatch();
  const loggedInUser = selectLoggedInUser();
  const [openModal, setOpenModal] = useState(false);
  const [newToDelete, setNewToDelete] = useState("");
  const [tagsOptions, setTagsOptions] = useState<any>([]);
  const [regionsOptions, setRegionsOptions] = useState<any>([]);
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [allSelected, setAllSelected] = useState(false);
  const [excludeNews, setExcludeNews] = useState<string[]>([]);
  const [includeNews, setIncludeNews] = useState<string[]>([]);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const handleDeleteModal = () => {
    setOpenDeleteModal(true);
  };
  const handleModal = (newToDelete: string) => {
    setNewToDelete(newToDelete);
    setOpenModal(true);
  };

  function deleteNewsHelper() {
    setUpdateLoading(true);
    if (allSelected) {
      deleteNews({
        filters: constructGetNewsThunkAction(0),
        exclude: excludeNews,
      })
        .catch(
          (err: any) => {
            if (err.response?.data?.errors?.[0]?.message) {
              toast.error(err.response?.data?.errors?.[0]?.message);
            } else {
              toast.error(err.response?.data?.message);
            }
          }
          // toast.error(
          //   Message({
          //     action: "delete",
          //     entity: t("news.new"),
          //     error: t("genericError"),
          //     lang,
          //   })
          // )
        )
        .then((res: any) => {
          if (res["request"].status === 200) {
            toast.success(
              Message({
                action: "delete",
                entity: t("news.news"),
                lang,
              })
            );
          }
        })
        .then(() => getNews(0))
        .finally(() => {
          setUpdateLoading(false);
        });
    } else {
      deleteNews({
        filters: constructGetNewsThunkAction(0),
        include: includeNews,
      })
        .catch(
          (err: any) => {
            if (err.response?.data?.errors?.[0]?.message) {
              toast.error(err.response?.data?.errors?.[0]?.message);
            } else {
              toast.error(err.response?.data?.message);
            }
          }
          // toast.error(
          //   Message({
          //     action: "delete",
          //     entity: t("news.new"),
          //     error: t("genericError"),
          //     lang,
          //   })
          // )
        )
        .then((res: any) => {
          if (res["request"].status === 200) {
            toast.success(
              Message({
                action: "delete",
                entity: t("new.news"),
                lang,
              })
            );
          }
        })
        .then(() => getNews(0))
        .finally(() => {
          setUpdateLoading(false);
        });
    }
    setAllSelected(false);
    setIncludeNews([]);
    setExcludeNews([]);

    setOpenDeleteModal(false);
  }

  function updateNewsHelper(visible: boolean) {
    setUpdateLoading(true);
    if (allSelected) {
      updateNews({
        filters: constructGetNewsThunkAction(0),
        values: {
          visible,
        },
        exclude: excludeNews,
      })
        .catch(
          (err: any) => {
            if (err.response?.data?.errors?.[0]?.message) {
              toast.error(err.response?.data?.errors?.[0]?.message);
            } else {
              toast.error(err.response?.data?.message);
            }
          }
          // toast.error(
          //   Message({
          //     action: "update",
          //     entity: "News",
          //     error: t("genericError"),
          //     lang,
          //   })
          // )
        )
        .then((res: any) => {
          if (res["request"].status === 200) {
            toast.success(
              Message({
                action: "update",
                entity: t("new.news"),
                lang,
              })
            );
          }
        })
        .then(() => getNews(0))
        .finally(() => {
          setUpdateLoading(false);
        });
    } else {
      updateNews({
        filters: constructGetNewsThunkAction(0),
        values: {
          visible,
        },
        include: includeNews,
      })
        .catch(
          (err: any) => {
            if (err.response?.data?.errors?.[0]?.message) {
              toast.error(err.response?.data?.errors?.[0]?.message);
            } else {
              toast.error(err.response?.data?.message);
            }
          }
          // toast.error(
          //   Message({
          //     action: "update",
          //     entity: "News",
          //     error: t("genericError"),
          //     lang,
          //   })
          // )
        )
        .then((res: any) => {
          if (res["request"].status === 200) {
            toast.success(
              Message({
                action: "update",
                entity: t("new.news"),
                lang,
              })
            );
          }
        })
        .then(() => getNews(0))
        .finally(() => {
          setUpdateLoading(false);
        });
    }
    setAllSelected(false);
    setIncludeNews([]);
    setExcludeNews([]);
  }

  const [portal, setPortal] = useState<PortalType<New>>({
    open: false,
    top: 0,
    left: 0,
    entity: null,
  });

  function displayActions(post: New, i: number) {
    const canRead = canAccess("news", "READ", post);
    const canUpdate = canAccess("news", "UPDATE", post);
    const canDelete = canAccess("news", "DELETE", post);
    if (!canRead && !canUpdate && !canDelete) {
      return (
        <div className="flex items-center justify-center h-full">
          <BanIcon className="w-5 h-5" />
        </div>
      );
    }
    return (
      <div className="relative flex items-center h-full">
        <button
          id={`portal-button-${i}`}
          type="button"
          onClick={() => {
            return setPortal((state) => {
              const thisButton = document.getElementById(`portal-button-${i}`);
              const position = thisButton?.getBoundingClientRect();
              return {
                open: !state.open,
                top: Number(position?.bottom),
                left: Number(
                  window.innerWidth - Number(position?.x) <= 150
                    ? Number(position?.left) - 150
                    : Number(position?.left)
                ),
                entity: post,
              };
            });
          }}
        >
          <DotsVerticalIcon className="w-5 h-5" />
        </button>
      </div>
    );
  }

  function canAccess(
    domain: PermissionDomain,
    action: RolePermissionCrudAction,
    post?: New
  ): boolean {
    if (loggedInUser.permissions["*"]) {
      return true;
    } else {
      switch (loggedInUser.permissions[domain][action]) {
        case "NONE": {
          return false;
        }
        case "OWN": {
          if (action === "CREATE") return true;
          if (post && post.creator_id === loggedInUser.user?.PK_USER)
            return true;
          return false;
        }
        case "ANY": {
          return true;
        }
        default: {
          return false;
        }
      }
    }
  }

  useEffect(() => {
    dispatch(getTagsThunk()).then((res) => {
      if (res.meta.requestStatus === "rejected") {
        toast.error(
          Message({
            action: "read",
            entity: t("news.tags"),
            error: t("genericError"),
            lang,
            gender: "female",
          })
        );
      }
    });
  }, []);
  useEffect(() => {
    const tagsMap = tags.data.tags.map((tag) => {
      return {
        value: tag.pk_tag,
        label: tag.pk_tag,
      };
    });
    setTagsOptions(tagsMap);
  }, [tags]);

  useEffect(() => {
    const regionsMap = lookups.lookupList.regions.map((region) => ({
      value: region.PK_REGION,
      label: region.PK_REGION,
    }));
    setRegionsOptions(regionsMap);
  }, [lookups.lookupList.regions]);

  const columns: Array<Column<Record<string, any>>> = useMemo(
    () =>
      getNewsColumns(
        {
          allSelected,
          setAllSelected,
          includeNews,
          setIncludeNews,
          excludeNews,
          setExcludeNews,
        },
        lang
      ),
    [
      lang,
      allSelected,
      setAllSelected,
      includeNews,
      setIncludeNews,
      excludeNews,
      setExcludeNews,
    ]
  );

  const data: Array<any> = useMemo(() => {
    return news.data.data.map((post, i) => {
      return {
        checkbox: (
          <div className="flex items-center h-full">
            <input
              type="checkbox"
              checked={(function () {
                if (allSelected) {
                  const index = excludeNews.indexOf(post.news_id);
                  if (index === -1) {
                    return true;
                  } else {
                    return false;
                  }
                } else {
                  const index = includeNews.indexOf(post.news_id);
                  if (index === -1) {
                    return false;
                  } else {
                    return true;
                  }
                }
              })()}
              onClick={() => {
                if (allSelected) {
                  const index = excludeNews.indexOf(post.news_id);
                  if (index !== -1) {
                    setExcludeNews((excludedNews) => {
                      const temp = [...excludedNews];
                      temp.splice(index, 1);
                      return temp;
                    });
                  } else {
                    setExcludeNews((excludedNews) => [
                      ...excludedNews,
                      post.news_id,
                    ]);
                  }
                } else {
                  const index = includeNews.indexOf(post.news_id);
                  if (index !== -1) {
                    setIncludeNews((includedNews) => {
                      const temp = [...includedNews];
                      temp.splice(index, 1);
                      return temp;
                    });
                  } else {
                    setIncludeNews((includedNews) => [
                      ...includedNews,
                      post.news_id,
                    ]);
                  }
                }
              }}
              className="text-primary"
            />
          </div>
        ),
        col1: (
          <div className="flex flex-col gap-2">
            <div className="truncate">{post.title}</div>
            <div>
              {post.tags.length === 0 ? (
                t("news.noTags")
              ) : (
                <div className="flex flex-nowrap gap-2">
                  {post.tags.map((tag, i) => (
                    <span
                      key={i}
                      className="p-1 text-white bg-events-tags rounded-sm truncate"
                    >
                      {tag.pk_tag}
                    </span>
                  ))}
                </div>
              )}
            </div>
          </div>
        ),
        col2: (
          <div className="flex items-center h-full truncate">
            {post.location?.region}
          </div>
        ),
        col3: (
          <div className="flex items-center h-full truncate">
            {post.creator_id}
          </div>
        ),
        col4: (
          <div className="flex items-center h-full truncate">
            {post.created_at
              ? format(new Date(post.created_at), "dd MMMM yyyy, hh:mm:ss aaa")
              : null}
          </div>
        ),
        col5: (
          <div className="flex items-center h-full truncate">
            <span
              className={`rounded-full px-2 py-1 capitalize ${
                post.is_visible
                  ? "text-users-active bg-users-active-bg"
                  : "text-users-inactive bg-users-inactive-bg"
              }`}
            >
              {post.is_visible ? t("news.visible") : t("news.invisible")}
            </span>
          </div>
        ),
        col6: displayActions(post, i),
      };
    });
  }, [
    news.data,
    lang,
    allSelected,
    setAllSelected,
    includeNews,
    setIncludeNews,
    excludeNews,
    setExcludeNews,
  ]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: getValues("pageSize") },
      pageCount: Math.ceil(news.data.total / getValues("pageSize")),
      manualSortBy: true,
      manualPagination: true,
    },
    useSortBy,
    usePagination,
    useFlexLayout
  );

  const {
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, sortBy },
  } = tableInstance;

  function constructGetNewsThunkAction(pageIndex: number): any {
    return {
      user: debouncedUserText,
      featured: getValues("featured").value,
      tags: tagsToQuery(getValues("tags")),
      region: regionToQuery(getValues("region")),
      visible: getValues("visible").value,
      pageSize: getValues("pageSize"),
      pageIndex: pageIndex,
      sortCol: sortBy[0] ? sortBy[0].id : "",
      searchString: debouncedSearchText,
      sortOrder: sortBy[0] ? (sortBy[0].desc ? "DESC" : "ASC") : "",
    };
  }

  function getNews(pageIndex: number) {
    return dispatch(getNewsThunk(constructGetNewsThunkAction(pageIndex))).then(
      (res) => {
        if (res.meta.requestStatus === "rejected") {
          toast.error(
            Message({
              action: "read",
              entity: t("news.news"),
              error: t("genericError"),
              lang,
              gender: "female",
            })
          );
        }
        return res;
      }
    );
  }

  useEffect(() => {
    if (isDirty) getNews(0);
  }, [watch("region"), watch("tags"), watch("featured"), watch("visible")]);

  useEffect(() => {
    getNews(0);
  }, [debouncedSearchText, debouncedUserText, sortBy]);

  const handleDeleteNew = (postId: string) => {
    dispatch(
      deleteNewThunk({
        postId: postId,
      })
    )
      .then((res) => {
        if (res.meta.requestStatus === "rejected") {
          toast.error(
            Message({
              action: "delete",
              entity: t("news.new"),
              error: t("genericError"),
              lang,
              gender: "female",
            })
          );
        } else {
          toast.success(
            Message({
              action: "delete",
              entity: t("news.new"),
              lang,
              gender: "female",
            })
          );
        }
      })
      .then(() => getNews(0));
    setOpenModal(false);
  };

  const dispatchNextPage = () => {
    if (canNextPage) {
      getNews(pageIndex + 1).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          nextPage();
        }
      });
    }
  };

  const dispatchPreviousPage = () => {
    if (canPreviousPage) {
      getNews(pageIndex - 1).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          previousPage();
        }
      });
    }
  };

  const dispatchGotoPage = (pageIndex: number) => {
    getNews(pageIndex).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        gotoPage(pageIndex);
      }
    });
  };

  function toggleVisibility(post: New): CreateNew {
    const temp: CreateNew = {
      is_visible: post.is_visible ? "false" : "true",
    };
    return temp;
  }

  function portalContent(portal: PortalType<New>) {
    if (portal.entity === null) return;
    const canUpdate = canAccess("news", "UPDATE", portal.entity);
    const canRead = canAccess("news", "READ", portal.entity);
    const canDelete = canAccess("news", "DELETE", portal.entity);
    return (
      <div
        className="fixed z-50 flex flex-col gap-2 p-2 bg-white rounded-sm shadow-md"
        style={{
          width: "150px",
          top: portal.top,
          left: portal.left,
        }}
      >
        {canRead ? (
          <Link
            to={`/news/${portal.entity.news_id}`}
            className="flex gap-2 items-center text-table-edit"
          >
            <PencilIcon className="w-5 h-5" />
            {canUpdate ? t("crud.update") : t("crud.read")}
          </Link>
        ) : null}
        {canDelete ? (
          <button
            onClick={() => handleModal(String(portal.entity?.news_id))}
            className="flex gap-2 items-center text-users-delete"
          >
            <TrashIcon className="w-5 h-5" />
            {t("crud.delete")}
          </button>
        ) : null}
        {canUpdate ? (
          <button
            className="flex gap-2 items-center text-users-edit"
            onClick={() => {
              dispatch(
                updateNewThunk({
                  postId: String(portal.entity?.news_id),
                  post: toggleVisibility(portal.entity as New),
                })
              )
                .then((res) => {
                  if (res.meta.requestStatus === "rejected") {
                    toast.error(
                      Message({
                        action: "update",
                        entity: t("news.new"),
                        error: t("genericError"),
                        lang,
                        gender: "female",
                      })
                    );
                  } else {
                    toast.success(
                      Message({
                        action: "update",
                        entity: t("news.new"),
                        lang,
                        gender: "female",
                      })
                    );
                  }
                  return res;
                })
                .then(() => getNews(0));
            }}
          >
            {portal.entity.is_visible ? (
              <EyeOffIcon className="w-5 h-5" />
            ) : (
              <EyeIcon className="w-5 h-5" />
            )}
            {portal.entity.is_visible ? t("news.invisible") : t("news.visible")}
          </button>
        ) : null}
      </div>
    );
  }

  function showExtraButtons() {
    if (allSelected && excludeNews.length < news.data.total) {
      return true;
    }
    if (!allSelected && includeNews.length) {
      return true;
    }
    return false;
  }

  return (
    <Overlay active={news.loading || updateLoading} spinner={<Spinner />}>
      <div className="flex flex-col gap-5 p-10 h-full">
        <div className="text-3xl font-bold">{t("news.news")}</div>
        <div className="flex flex-col gap-5">
          {filtersOpen ? (
            <div className="flex flex-wrap gap-5">
              <div className="w-72">
                <label htmlFor="tags" className="sr-only">
                  {t("news.tags")}
                </label>
                <Controller
                  name="tags"
                  control={control}
                  render={({ field }) => {
                    return (
                      <Select
                        placeholder={t("news.tags")}
                        styles={getReactSelectStyles(false)}
                        isLoading={tags.loading}
                        inputId="tags"
                        isMulti={true}
                        options={tagsOptions}
                        value={field.value}
                        onChange={(e) => field.onChange(e)}
                        closeMenuOnSelect={false}
                        hideSelectedOptions={true}
                        controlShouldRenderValue={false}
                      />
                    );
                  }}
                />
              </div>
              <div className="w-72">
                <label htmlFor="region" className="sr-only">
                  {t("news.region")}
                </label>
                <Controller
                  name="region"
                  control={control}
                  render={({ field }) => (
                    <Select
                      placeholder={t("news.region")}
                      styles={getReactSelectStyles(false)}
                      isLoading={lookups.loading}
                      inputId="region"
                      isClearable={true}
                      value={field.value}
                      options={regionsOptions}
                      onChange={(e) => field.onChange(e)}
                    />
                  )}
                />
              </div>
              <div className="w-72">
                <label htmlFor="featured" className="sr-only">
                  {t("news.featured")}
                </label>
                <Controller
                  name="featured"
                  control={control}
                  render={({ field }) => (
                    <Select
                      styles={getReactSelectStyles(false)}
                      placeholder={t("news.featured")}
                      inputId="featured"
                      value={field.value}
                      options={getFeaturedOptions(lang)}
                      onChange={(e) => {
                        field.onChange(e);
                      }}
                      isSearchable={false}
                    />
                  )}
                />
              </div>
              <div className="w-72">
                <label htmlFor="visible" className="sr-only">
                  {t("news.visibility")}
                </label>
                <Controller
                  name="visible"
                  control={control}
                  render={({ field }) => (
                    <Select
                      placeholder={t("news.visibility")}
                      styles={getReactSelectStyles(false)}
                      inputId="visible"
                      options={getVisibilityFilterOptions(lang)}
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(e);
                      }}
                      isSearchable={false}
                    />
                  )}
                />
              </div>
              <div className="w-72">
                <label htmlFor="user" className="sr-only">
                  {t("news.author")}
                </label>
                <input
                  placeholder={t("news.author")}
                  id="user"
                  type="text"
                  {...register("user")}
                  className={getInputStyles(false)}
                />
              </div>
              <div>
                <button
                  type="button"
                  className="px-4 py-2 text-white bg-primary border border-primary"
                  onClick={() => {
                    setValue("tags", []);
                    setValue("region", null as any);
                    setValue("featured", { label: "All", value: "" });
                    setValue("visible", { label: "All", value: "all" });
                    setValue("user", "");
                  }}
                >
                  {t("news.clearAll")}
                </button>
              </div>
            </div>
          ) : null}
          <div className="flex flex-grow flex-wrap gap-5">
            {showExtraButtons() && canAccess("s", "UPDATE") ? (
              <Popover className="relative">
                <Popover.Button>
                  <button
                    type="button"
                    className="px-4 py-2 text-white bg-primary border border-primary rounded-sm"
                  >
                    {t("receipts.updateStatus")}
                  </button>
                </Popover.Button>
                <Popover.Panel
                  className="absolute left-0 top-0 flex flex-col gap-2 mt-16 p-2 w-full bg-white rounded-sm shadow-md"
                  style={{ zIndex: 9990 }}
                >
                  <button
                    onClick={() => {
                      updateNewsHelper(true);
                    }}
                  >
                    {t("news.visible")}
                  </button>
                  <button
                    onClick={() => {
                      updateNewsHelper(false);
                    }}
                  >
                    {t("news.invisible")}
                  </button>
                </Popover.Panel>
              </Popover>
            ) : null}
            {showExtraButtons() && canAccess("s", "DELETE") ? (
              <button
                className="px-4 py-2 text-white bg-red-500 border border-red-500 rounded-sm"
                onClick={() => {
                  handleDeleteModal();
                }}
              >
                {t("crud.delete")}
              </button>
            ) : null}
          </div>
          <div className="flex flex-wrap gap-5">
            <div className="flex flex-wrap gap-5">
              <button onClick={() => setFiltersOpen((state) => !state)}>
                <FilterIcon className="w-6 h-6" />
              </button>
            </div>
            <div className="flex flex-grow flex-wrap gap-5 justify-end">
              <div className="flex items-center w-72 border focus-within:border-global-input-focus border-gray-300">
                <div className="flex items-center justify-center p-2 h-full bg-white">
                  <SearchIcon className="w-5 h-5 text-gray-400" />
                </div>
                <input
                  type="text"
                  placeholder={`${t("news.search")}...`}
                  {...register("searchString")}
                  className="placeholder-gray-400 p-2 w-full border-0 focus:ring-0"
                />
              </div>
              {canAccess("news", "CREATE") && (
                <Link to="/news/create">
                  <button className="flex gap-2 items-center justify-center px-4 py-2 text-white bg-global-createEntity border border-global-createEntity">
                    <PlusIcon className="w-4 h-4" />
                    <span>
                      {t("news.create")} {t("news.post")}
                    </span>
                  </button>
                </Link>
              )}
            </div>
          </div>
          {watch("tags").length ? (
            <div className="flex flex-wrap gap-5">
              {watch("tags").map((tag, i) => (
                <button
                  key={i}
                  className="flex gap-2 items-center p-2 text-users-color hover:text-white hover:bg-users-role border border-users-role"
                  onClick={() => {
                    setValue(
                      "tags",
                      getValues("tags").filter((t) => t.label !== tag.label)
                    );
                  }}
                >
                  <span>{tag.value}</span>
                  <XIcon className="w-4 h-4" />
                </button>
              ))}
            </div>
          ) : null}
        </div>
        <Modal
          openStatus={openDeleteModal}
          setOpen={setOpenDeleteModal}
          icon={
            <ExclamationIcon
              className="w-6 h-6 text-red-600 bg-transparent"
              aria-hidden="true"
            />
          }
          header={
            <p>
              {t("deleteModal.delete")} {t("news.news")}
            </p>
          }
          title={
            <p>
              {includeNews.length == 1 && (
                <span>
                  {t("deleteModal.sure2")} {t("news.new")} ?{" "}
                  {t("deleteModal.undone")}
                </span>
              )}
              {includeNews.length > 1 && (
                <span>
                  {t("deleteModal.sure_for_more2")} {includeNews.length}{" "}
                  {t("news.news")} ? {t("deleteModal.undone")}
                </span>
              )}
              {allSelected && (
                <span>
                  {t("deleteModal.sure_for_more2")}{" "}
                  {news.data.total - excludeNews.length} {t("news.news")} ?{" "}
                  {t("deleteModal.undone")}
                </span>
              )}
            </p>
          }
          footer={
            <div className="flex justify-end mt-5 sm:mt-4">
              <button
                type="button"
                className="inline-flex justify-center mt-3 px-4 py-1 w-full text-black text-base font-medium bg-white border border-black focus:outline-none shadow-sm focus:ring-0 sm:mt-0 sm:w-auto"
                onClick={() => setOpenDeleteModal(false)}
              >
                {t("deleteModal.cancel")}
              </button>
              <button
                type="button"
                className="inline-flex justify-center px-4 py-1 w-full text-white text-base font-medium bg-red-600 border border-transparent focus:outline-none shadow-sm focus:ring-0 sm:ml-3 sm:w-auto"
                onClick={() => deleteNewsHelper()}
              >
                {t("deleteModal.delete")}
              </button>
            </div>
          }
        />
        <Table {...tableInstance} />
        <Pagination
          rowsCount={news.data.total}
          pageIndex={pageIndex}
          pageSize={getValues("pageSize")}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          pageOptions={pageOptions}
          pageCount={pageCount}
          gotoPage={dispatchGotoPage}
          nextPage={dispatchNextPage}
          previousPage={dispatchPreviousPage}
          setPageSize={setPageSize}
        />
        <TablePopup
          isOpen={portal.open}
          close={() => setPortal((state) => ({ ...state, open: false }))}
        >
          {portalContent(portal)}
        </TablePopup>
        <Modal
          openStatus={openModal}
          setOpen={setOpenModal}
          icon={
            <ExclamationIcon
              className="w-6 h-6 text-red-600 bg-transparent"
              aria-hidden="true"
            />
          }
          header={
            <p>
              {t("deleteModal.delete")} {t("news.post")}
            </p>
          }
          title={
            <p>
              {t("deleteModal.sure")} {t("news.post")}?{" "}
              {t("deleteModal.undone")}
            </p>
          }
          footer={
            <div className="flex justify-end mt-5 sm:mt-4">
              <button
                type="button"
                className="inline-flex justify-center mt-3 px-4 py-1 w-full text-black text-base font-medium bg-white border border-black focus:outline-none shadow-sm focus:ring-0 sm:mt-0 sm:w-auto"
                onClick={() => setOpenModal(false)}
              >
                {t("deleteModal.cancel")}
              </button>
              <button
                type="button"
                className="inline-flex justify-center px-4 py-1 w-full text-white text-base font-medium bg-red-600 border border-transparent focus:outline-none shadow-sm focus:ring-0 sm:ml-3 sm:w-auto"
                onClick={() => handleDeleteNew(newToDelete)}
              >
                {t("deleteModal.delete")}
              </button>
            </div>
          }
        />
        <DevTool control={control} />
      </div>
    </Overlay>
  );
};
export default NewsPage;
