import { EnchancedOrder } from "shared/interfaces/Orders.interface";

export interface OrderState {
  data: {
    message: string;
    total: number;
    pageIndex: number;
    data: EnchancedOrder[];
    order?: EnchancedOrder;
  };
  loading: boolean;
}

export const OrderInitialState: OrderState = {
  data: {
    message: "",
    total: 0,
    pageIndex: 0,
    data: [],
    order: undefined,
  },
  loading: false,
};
