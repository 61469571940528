import { BillingAddress, ShippingAddress } from "api/Orders/orders.api";

export function constructLocationObject(
  location: (
    | Omit<BillingAddress, "region" | "province">
    | Omit<ShippingAddress, "region" | "province">
  ) & {
    region: { value: string; label: string };
    province: { value: string; label: string };
  }
) {
  return {
    ...location,
    region: location.region.value,
    province: location.province.value,
  };
}
