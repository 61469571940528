import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import PatreonPlans from "shared/components/Plans+Products/PatreonPlans";
import Message from "shared/components/UI/Message";
import Overlay from "shared/components/UI/Overlay";
import Spinner from "shared/components/UI/Spinner";
import { useAppDispatch, useAppSelector } from "store/storeHooks";
import { selectTranslationLanguage } from "store/translationStore/translationReducer";
import {
  selectUserRanks,
  getUserRanksThunk,
} from "store/userRanksStore/userRanksReducer";
import TeacherPlans from "shared/components/Plans+Products/TeacherPlans";
import { getPlansThunk } from "store/plansStore/plansReducer";

const Plans = () => {
  const [loader, setLoader] = useState(true);
  const dispatch = useAppDispatch();
  const lang = selectTranslationLanguage();
  const { t } = useTranslation("common");

  const { plans, loading } = useAppSelector((state) => state.plans);
  const teacherPlans = plans.teacherPlans;
  const patreonPlans = plans.patreonPlans;

  useEffect(() => {
    dispatch(getPlansThunk()).then(() => setLoader(false));
  }, []);

  useEffect(() => {
    dispatch(getUserRanksThunk({ pageSize: 99 })).then((res) => {
      if (res.meta.requestStatus === "rejected") {
        toast.error(
          Message({
            action: "read",
            entity: t("user.rank"),
            error: t("genericError"),
            lang,
          })
        );
      }
      return res;
    });
  }, []);
  const [ranksOptions, setRankOptions] = useState([]);
  const userRanks = selectUserRanks();
  useEffect(() => {
    if (userRanks.data.userRanks)
      setRankOptions(
        userRanks.data.userRanks?.map((r: any) => ({
          value: r.id,
          label: lang === "en" ? r.rank_eng : r.rank_it,
        })) as any
      );
  }, [userRanks.data.userRanks]);

  return (
    <>
      {!loader && (
        <Overlay spinner={<Spinner />} active={loading}>
          <div className="p-10 h-full bg-lightGray-bg">
            <div className="mb-4 text-3xl font-bold">{t("plans.plans")}</div>
            {!loading && (
              <>
                <TeacherPlans
                  userRanks={ranksOptions}
                  teachers={teacherPlans}
                />
                <div className="my-20"></div>
                <PatreonPlans
                  userRanks={ranksOptions}
                  patreons={patreonPlans}
                />
              </>
            )}
            <div className="pb-20"></div>
          </div>
        </Overlay>
      )}
    </>
  );
};

export default Plans;
