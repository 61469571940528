import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createAboutUs,
  deleteAboutUs,
  getAboutUs,
  updateAboutUs,
} from "api/AboutUs/aboutUs.api";
import { CreateAboutUs } from "shared/interfaces/AboutUs.interface";
import { useAppSelector } from "store/storeHooks";
import { aboutUsInitialState } from "./aboutUsState";

export const createAboutUsThunk = createAsyncThunk(
  "aboutUsStore/createAboutUs",
  async ({ data }: { data: CreateAboutUs }) => {
    await createAboutUs({ data });
  }
);

export const getAboutUsThunk = createAsyncThunk(
  "aboutUsStore/getAboutUs",
  async ({ aboutId }: { aboutId: string }) => {
    const res = await getAboutUs({ aboutId });
    return res.data;
  }
);

export const deleteAboutUsThunk = createAsyncThunk(
  "aboutUsStore/deleteAboutUs",
  async ({ aboutId }: { aboutId: string }) => {
    await deleteAboutUs({ aboutId });
  }
);

export const updateAboutUsThunk = createAsyncThunk(
  "aboutUsStore/updateAboutUs",
  async ({ aboutId, data }: { aboutId: string; data: CreateAboutUs }) => {
    const res = await updateAboutUs({ aboutId, data });
    return res.data;
  }
);

const aboutUsSlice = createSlice({
  name: "aboutUsStore",
  initialState: aboutUsInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createAboutUsThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(createAboutUsThunk.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(createAboutUsThunk.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getAboutUsThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAboutUsThunk.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(getAboutUsThunk.rejected, (state) => {
        state.loading = false;
      })
      .addCase(deleteAboutUsThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteAboutUsThunk.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(deleteAboutUsThunk.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateAboutUsThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateAboutUsThunk.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(updateAboutUsThunk.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const selectAboutUs = () => useAppSelector((state) => state.aboutUs);

export default aboutUsSlice;
