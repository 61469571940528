import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import {
  createRolePermissionThunk,
  getPermissionsThunk,
  getRolesThunk,
  selectCreateRolePermissions,
  selectPermissionsManagementLoading,
} from "store/permissionsManagement/permissionsManagementReducer";
import { useAppDispatch } from "store/storeHooks";
import {
  selectPermissions,
  selectRoles,
} from "store/permissionsManagement/permissionsManagementReducer";
import { Role } from "shared/interfaces/Role.interface";
import Overlay from "shared/components/UI/Overlay";
import Spinner from "shared/components/UI/Spinner";
import { permissionsManagementActions } from "store/globalStore";
import {
  CreatePermissionAccess,
  CreateRolePermission,
} from "shared/interfaces/RolePermission.interface";
import "simplebar/dist/simplebar.min.css";
import GoBack from "shared/components/UI/GoBack";
import Message from "shared/components/UI/Message";
import { getLookupsThunk } from "store/lookups/lookupsReducer";
import PermissionOnlyCheckBox from "shared/components/Roles/PermissionOnlyCheckBox";
import Permission from "shared/components/Roles/Permission";
import PermissionParent from "shared/components/Roles/PermissionParent";
import { selectTranslationLanguage } from "store/translationStore/translationReducer";

const CreateRole: () => JSX.Element = () => {
  const lang = selectTranslationLanguage();
  const [t, i18n] = useTranslation("common");
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const permissions = selectPermissions();
  const roles = selectRoles();
  const loading = selectPermissionsManagementLoading();
  const rolePermissions = selectCreateRolePermissions();
  const [loadingCreateRole, setLoadingCreateRole] = useState(true);

  // ** type definitions **
  type crud = "create" | "read" | "update" | "delete";
  type strOrNull = string | null;

  const createPermissionAccess = () => {
    const tempPerms = permissions
      .filter(function (perm) {
        return (
          perm.TITLE !== "" &&
          perm.TITLE !== null &&
          perm.TITLE !== "Ranks" &&
          perm.TITLE !== "OrdersFE" &&
          perm.TITLE !== "Coupons" &&
          perm.TITLE !== "Payments" &&
          perm.TITLE !== "Shop"
        );
      })
      .map((permission, i) => ({
        permissionName: permission.PERMISSION_NAME,
        create: "NONE",
        read: "NONE",
        update: "NONE",
        delete: "NONE",
        enabled: false,
        title: t(`RolesPermissions.${permission.PERMISSION_NAME}`),
        index: i,
      }));

    return tempPerms;
  };

  // ** DATA FETCHING AND MANIPULATION **
  useEffect(() => {
    setLoadingCreateRole(true);
    dispatch(getPermissionsThunk());
    dispatch(getRolesThunk());
    setLoadingCreateRole(false);
  }, []);

  useEffect(() => {
    dispatch(
      permissionsManagementActions.createRolePermissions({
        roleName: "",
        description: "",
        permissionsAccess: createPermissionAccess() as CreatePermissionAccess[],
      })
    );
  }, [permissions, i18n?.language]);

  // ** TOAST MESSAGES IMPLEMENTATION **
  const roleEmptyMsg = () => (
    <div className="flex flex-col gap-1">
      <p className="text-xs font-semibold">
        {t("RolesCRUD.emptyRoleMsgHeader")}
      </p>
      <p className="text-xs">{t("RolesCRUD.emptyRoleMsg")}</p>
    </div>
  );

  const duplicateRoleMsg = () => (
    <div className="flex flex-col gap-1">
      <p className="text-xs font-semibold">
        {t("RolesCRUD.duplicateRoleMsgHeader")}
      </p>
      <p className="text-xs">{t("RolesCRUD.duplicateRoleMsg")}</p>
    </div>
  );

  const roleCreatedMsg = () => (
    <div className="flex flex-col">
      <p className="text-sm font-medium">{t("RolesCRUD.roleCreated")}</p>
    </div>
  );

  // ** EVENT LISTENERS  **
  const onRolenameChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      permissionsManagementActions.changeCreateRolePermissionsRolename({
        roleName: e.target.value,
      })
    );
  };

  const onDescriptionChangeHandler = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(
      permissionsManagementActions.changeCreateRolePermissionsDescription({
        description: e.target.value,
      })
    );
  };

  // ** create ROLE FUNCTION **
  const createRoleHandler = () => {
    const roleName: string = rolePermissions.roleName;
    const description: strOrNull = rolePermissions.description;
    const permissionsAccess: CreatePermissionAccess[] =
      rolePermissions.permissionsAccess;

    if (roleName.trim().length === 0) {
      toast.error(roleEmptyMsg);
      return;
    }

    roles.map((role: Role) => {
      if (role.ROLE_NAME.toLowerCase() === roleName.trim().toLowerCase()) {
        toast.error(duplicateRoleMsg);
        return;
      }
    });

    const data: CreateRolePermission = {
      roleName,
      description,
      permissionsAccess,
    };
    dispatch(createRolePermissionThunk(data)).then((res: any) => {
      if (res.meta.requestStatus === "rejected") {
        toast.error(
          Message({
            action: "create",
            entity: t("RolesCRUD.role"),
            error: t("genericError"),
            lang,
            gender: "male",
          })
        );
        return;
      }
      dispatch(getLookupsThunk());
      toast.success(roleCreatedMsg);
      navigate("/roles");
    });
  };

  // **  RESET BUTTON HANDLER **
  const resetButtonHandler = () => {
    const cruds: crud[] = ["create", "read", "update", "delete"];
    dispatch(
      permissionsManagementActions.changeCreateRolePermissionsRolename({
        roleName: "",
      })
    );
    dispatch(
      permissionsManagementActions.changeCreateRolePermissionsDescription({
        description: "",
      })
    );
    rolePermissions.permissionsAccess.map(
      (row) =>
        dispatch(
          permissionsManagementActions.changeCreateRolePermissionEnabledAccess({
            i: row.index,
            bool: false,
          })
        ),
      cruds.map((crud) =>
        dispatch(
          permissionsManagementActions.changeCreateAllRolePermissions({
            crud: crud,
            crudType: "NONE",
          })
        )
      )
    );
  };

  return (
    <Fragment>
      {!loadingCreateRole && (
        <Overlay spinner={<Spinner />} active={loading}>
          <div className="flex flex-col gap-5 p-10 h-full overflow-y-auto">
            <GoBack uri="roles" />
            <div className="flex gap-5 items-center">
              <div className="text-3xl font-bold">
                {t("RolesCRUD.rolesHeader")}
              </div>
              <div className="text-roles-description text-lg">
                {t("RolesCRUD.createRolesHeaderMessage")}
              </div>
            </div>
            <div className="flex flex-row gap-5">
              <div className="flex flex-row gap-5 w-2/3">
                <div className="flex flex-col gap-2 w-1/3">
                  <div className="h-1/2 text-xl font-bold">
                    {t("RolesCRUD.roleHeader")}
                  </div>
                  <input
                    id="create-role-role"
                    type="text"
                    placeholder="e.g. Editor"
                    className="text focus:border-global-input-focus focus:ring-0"
                    value={rolePermissions.roleName}
                    onChange={onRolenameChangeHandler}
                    autoComplete={"off"}
                  />
                </div>
                <div className="flex flex-col gap-2 w-2/3">
                  <div className="h-1/2 text-xl font-bold">
                    {t("RolesCRUD.descriptionHeader")}
                  </div>
                  <input
                    id="create-role-description"
                    type="text"
                    placeholder={`${t("RolesCRUD.descriptionPlaceholder")}`}
                    className="text focus:border-global-input-focus focus:ring-0"
                    value={String(rolePermissions.description)}
                    onChange={onDescriptionChangeHandler}
                    autoComplete={"off"}
                  />
                </div>
              </div>
              <div className="flex flex-row gap-5 items-end justify-end w-1/3">
                <div className="flex flex-col gap-2 w-1/4">
                  <button
                    className="flex items-center justify-center p-2 w-full h-9 text-users-create text-sm bg-white border border-users-create"
                    id="create-role-reset"
                    onClick={resetButtonHandler}
                  >
                    {t("RolesCRUD.resetButton")}
                  </button>
                </div>
                <div className="flex flex-col gap-2 w-1/4">
                  <button
                    className="flex items-center justify-center p-2 w-full h-9 text-white text-sm bg-users-create border"
                    onClick={createRoleHandler}
                    id="create-role-save"
                  >
                    {t("RolesCRUD.saveButton")}
                  </button>
                </div>
              </div>
            </div>
            <div className="">
              <div className="border-b border-gray-200 rounded-sm">
                <table className="min-w-full divide-gray-200 divide-y">
                  <thead className="bg-gray-100">
                    <tr className="bg-gray-100">
                      <th
                        scope="col"
                        className="px-6 py-3 w-1/6 text-gray-500 text-xs font-medium tracking-wider uppercase"
                      ></th>
                      <th
                        scope="col"
                        className="px-6 py-3 w-1/6 text-center text-sm font-semibold tracking-wider"
                      >
                        <div className="single-row-container">
                          <div className="flex flex-col items-center">
                            <div className="flex flex-row gap-1 justify-center mb-1">
                              {t("RolesCRUD.checkboxTitle")}
                            </div>
                            <div className="own-any-container flex gap-4 justify-center text-gray-600 text-xs">
                              <span>{t("RolesCRUD.checkboxDescription")}</span>
                            </div>
                          </div>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 w-1/6 text-center text-sm font-semibold tracking-wider"
                      >
                        <div className="single-row-container">
                          <div className="flex flex-col items-center">
                            <div className="flex flex-row gap-1 justify-center mb-1">
                              {t("RolesCRUD.readCrud")}
                            </div>
                            <div className="own-any-container flex gap-4 justify-center text-gray-600 text-xs">
                              <span>Any</span>
                              <span>Own</span>
                            </div>
                          </div>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 w-1/6 text-center text-sm font-semibold tracking-wider"
                      >
                        <div className="single-row-container">
                          <div className="flex flex-col items-center">
                            <div className="flex flex-row gap-1 justify-center mb-1">
                              {t("RolesCRUD.cud")}
                            </div>
                            <div className="own-any-container flex gap-4 justify-center text-gray-600 text-xs">
                              <span>Any</span>
                              <span>Own</span>
                              <span>None</span>
                            </div>
                          </div>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  {/* INPUT OF PERMISSIONS AS TABLE ROWS */}
                  <tbody className="bg-white divide-gray-200 divide-y">
                    {!!rolePermissions.permissionsAccess.find(
                      (role) => role.permissionName === "accessCMS"
                    ) && (
                      <PermissionOnlyCheckBox
                        permission={rolePermissions.permissionsAccess.find(
                          (role) => role.permissionName === "accessCMS"
                        )}
                        enabled={true}
                        isItChild={false}
                      />
                    )}
                    {!!rolePermissions.permissionsAccess.find(
                      (role) => role.permissionName === "news"
                    ) && (
                      <Permission
                        permission={rolePermissions.permissionsAccess.find(
                          (role) => role.permissionName === "news"
                        )}
                      />
                    )}
                    {!!rolePermissions.permissionsAccess.find(
                      (role) => role.permissionName === "events"
                    ) && (
                      <Permission
                        permission={rolePermissions.permissionsAccess.find(
                          (role) => role.permissionName === "events"
                        )}
                      />
                    )}
                    {!!rolePermissions.permissionsAccess.find(
                      (role) => role.permissionName === "exams"
                    ) && (
                      <Permission
                        permission={rolePermissions.permissionsAccess.find(
                          (role) => role.permissionName === "exams"
                        )}
                      />
                    )}
                    {!!rolePermissions.permissionsAccess.find(
                      (role) => role.permissionName === "users"
                    ) && (
                      <PermissionParent
                        permission={rolePermissions.permissionsAccess.find(
                          (role) => role.permissionName === "users"
                        )}
                        childrenRowsWithButtons={[
                          rolePermissions.permissionsAccess.find(
                            (role) =>
                              role.permissionName === "assignCertificates"
                          ),
                          rolePermissions.permissionsAccess.find(
                            (role) => role.permissionName === "subscriptions"
                          ),
                        ].filter((elem) => {
                          return !!elem;
                        })}
                        childrenRowsSimple={[
                          rolePermissions.permissionsAccess.find(
                            (role) => role.permissionName === "permissions"
                          ),
                          rolePermissions.permissionsAccess.find(
                            (role) => role.permissionName === "exportUserData"
                          ),
                        ].filter((elem) => {
                          return !!elem;
                        })}
                        buttonsParent={true}
                      />
                    )}
                    {!!rolePermissions.permissionsAccess.find(
                      (role) => role.permissionName === "documents"
                    ) && (
                      <Permission
                        permission={rolePermissions.permissionsAccess.find(
                          (role) => role.permissionName === "documents"
                        )}
                      />
                    )}
                    {!!rolePermissions.permissionsAccess.find(
                      (role) => role.permissionName === "press"
                    ) && (
                      <Permission
                        permission={rolePermissions.permissionsAccess.find(
                          (role) => role.permissionName === "press"
                        )}
                      />
                    )}
                    {!!rolePermissions.permissionsAccess.find(
                      (role) => role.permissionName === "newsletter"
                    ) && (
                      <PermissionParent
                        permission={rolePermissions.permissionsAccess.find(
                          (role) => role.permissionName === "newsletter"
                        )}
                        childrenRowsWithButtons={[
                          rolePermissions.permissionsAccess.find(
                            (role) =>
                              role.permissionName === "newsletterActiveMembers"
                          ),
                          rolePermissions.permissionsAccess.find(
                            (role) =>
                              role.permissionName ===
                              "newsletterResignedMembers"
                          ),
                          rolePermissions.permissionsAccess.find(
                            (role) =>
                              role.permissionName === "newsletterSimpleUsers"
                          ),
                        ].filter((elem) => {
                          return !!elem;
                        })}
                        buttonsParent={true}
                      />
                    )}
                    {/* {!!rolePermissions.permissionsAccess.find(
                      (role) => role.permissionName === "orders"
                    ) && (
                      <PermissionParent
                        permission={rolePermissions.permissionsAccess.find(
                          (role) => role.permissionName === "orders"
                        )}
                        childrenRowsSimple={[
                          rolePermissions.permissionsAccess.find(
                            (role) => role.permissionName === "payments"
                          ),
                          rolePermissions.permissionsAccess.find(
                            (role) => role.permissionName === "coupons"
                          ),
                        ].filter((elem) => {
                          return !!elem;
                        })}
                        buttonsParent={true}
                      />
                    )} */}

                    {!!rolePermissions.permissionsAccess.find(
                      (role) => role.permissionName === "orders"
                    ) && (
                      <Permission
                        permission={rolePermissions.permissionsAccess.find(
                          (role) => role.permissionName === "orders"
                        )}
                      />
                    )}

                    {!!rolePermissions.permissionsAccess.find(
                      (role) => role.permissionName === "receipts"
                    ) && (
                      <Permission
                        permission={rolePermissions.permissionsAccess.find(
                          (role) => role.permissionName === "receipts"
                        )}
                      />
                    )}
                    {!!rolePermissions.permissionsAccess.find(
                      (role) => role.permissionName === "administrative"
                    ) && (
                      <PermissionParent
                        permission={rolePermissions.permissionsAccess.find(
                          (role) => role.permissionName === "administrative"
                        )}
                        childrenRowsWithButtons={[
                          rolePermissions.permissionsAccess.find(
                            (role) => role.permissionName === "certificates"
                          ),
                          rolePermissions.permissionsAccess.find(
                            (role) => role.permissionName === "products"
                          ),
                          rolePermissions.permissionsAccess.find(
                            (role) =>
                              role.permissionName === "subscriptionPlans"
                          ),
                        ].filter((elem) => {
                          return !!elem;
                        })}
                        childrenRowsSimple={[
                          rolePermissions.permissionsAccess.find(
                            (role) => role.permissionName === "aboutus"
                          ),
                          rolePermissions.permissionsAccess.find(
                            (role) => role.permissionName === "contactus"
                          ),
                          rolePermissions.permissionsAccess.find(
                            (role) =>
                              role.permissionName === "permissionsManagement"
                          ),
                          rolePermissions.permissionsAccess.find(
                            (role) => role.permissionName === "fieldsDomain"
                          ),
                          rolePermissions.permissionsAccess.find(
                            (role) => role.permissionName === "sponsors"
                          ),
                          rolePermissions.permissionsAccess.find(
                            (role) => role.permissionName === "exportData"
                          ),
                          rolePermissions.permissionsAccess.find(
                            (role) => role.permissionName === "massUpdate"
                          ),
                        ].filter((elem) => {
                          return !!elem;
                        })}
                        buttonsParent={false}
                      />
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Overlay>
      )}
    </Fragment>
  );
};

export default CreateRole;
