import React from "react";
import { useTranslation } from "react-i18next";
import Spinner from "shared/components/UI/Spinner";
import Overlay from "shared/components/UI/Overlay";
import ProductsDisplay from "shared/components/Plans+Products/ProductsDisplay";

const Products = () => {
  const { t } = useTranslation("common");

  return (
    <Overlay spinner={<Spinner />} active={false}>
      <div className="gap-5 p-10 h-full bg-lightGray-bg">
        <div className="mb-4 text-3xl font-bold">{t("pnp.products")}</div>
        <ProductsDisplay />
      </div>
    </Overlay>
  );
};

export default Products;
