import React, { useEffect, useState } from "react";

function SoftOverlay({
  close,
  children,
}: {
  close?: () => void;
  children: React.ReactNode;
}) {
  const [contentReference, setContentReference] = useState<any>(null);
  useEffect(() => {
    if (close) {
      const outsideClickListener = (event: any) => {
        if (contentReference && !contentReference.contains(event.target)) {
          close();
        }
      };
      document.addEventListener("click", outsideClickListener);
      return () => {
        if (outsideClickListener) {
          document.removeEventListener("click", outsideClickListener);
        }
      };
    }
  }, [contentReference]);
  return (
    <div
      className="fixed left-0 top-0 flex items-center justify-center w-screen h-screen"
      style={{
        backgroundColor: "rgba(0,0,0,0.75)",
        zIndex: 9999,
      }}
    >
      <div ref={setContentReference}>{children}</div>
    </div>
  );
}

export default SoftOverlay;
