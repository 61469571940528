import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { authInitialState, AuthState } from "./authState";
import { useAppSelector } from "store/storeHooks";
import {
  activateAccount,
  forgotPassword,
  getUserPermissions,
  loginUser,
} from "api/Auth/auth.api";
import { toast } from "react-toastify";

export const loginUserThunk = createAsyncThunk(
  "auth/loginUser",
  async function ({
    username,
    password,
  }: {
    username: string;
    password: string;
  }) {
    const res = await loginUser({ username, password });
    return res.data;
  }
);

export const getUserPermissionsThunk = createAsyncThunk(
  "auth/getUserPermissions",
  async ({ userId }: { userId: string }) => {
    try {
      const res = await getUserPermissions({
        userId,
      });
      return res.data;
    } catch (err: any) {
      if (err.response?.data?.errors?.[0]?.message) {
        toast.error(err.response?.data?.errors?.[0]?.message);
      } else {
        toast.error(err.response?.data?.message);
      }
    }
  }
);

export const forgotPasswordThunk = createAsyncThunk(
  "auth/forgotPassword",
  async ({ email }: { email: string }) => {
    try {
      const res = await forgotPassword({
        email: email,
      }).then((data) => data.json());
      return res;
    } catch (err: any) {
      if (err.response?.data?.errors?.[0]?.message) {
        toast.error(err.response?.data?.errors?.[0]?.message);
      } else {
        toast.error(err.response?.data?.message);
      }
    }
  }
);

export const changePasswordThunk = createAsyncThunk(
  "auth/changePassword",
  async ({
    username,
    password,
    token,
  }: {
    username: string;
    password: string;
    token: string;
  }) => {
    try {
      const passwordReset = await activateAccount({
        username,
        password,
        token,
      }).then((data) => data.json());
      return passwordReset;
    } catch (err: any) {
      if (err.response?.data?.errors?.[0]?.message) {
        toast.error(err.response?.data?.errors?.[0]?.message);
      } else {
        toast.error(err.response?.data?.message);
      }
    }
  }
);

const authSlice = createSlice({
  name: "authState",
  initialState: authInitialState,
  reducers: {
    logout: (state) => {
      localStorage.removeItem("token");
      sessionStorage.removeItem("token");
      state.user = null;
      localStorage.removeItem("user");
      sessionStorage.removeItem("user");
      // state.permissions = {};
      // localStorage.removeItem("permissions");
      // sessionStorage.removeItem("permissions");
    },
    setUser: (state, { payload: { user } }: { payload: { user: any } }) => {
      state.user = user;
    },
    setPermissions: (
      state,
      { payload: { permissions } }: { payload: { permissions: any } }
    ) => {
      state.permissions = permissions;
    },
    setRemember: (
      state,
      { payload: { remember } }: { payload: { remember: boolean } }
    ) => {
      state.remember = remember;
    },
    changeRemember(
      state,
      { payload: { remember } }: { payload: { remember: boolean } }
    ) {
      state.remember = remember;
      localStorage.setItem("remember", JSON.stringify(remember));
    },
    changeforgotPasswordUsername: (
      state,
      { payload: { username } }: { payload: { username: string } }
    ) => {
      state.forgotPassword.username = username;
    },
    changeforgotPasswordEmail: (
      state,
      { payload: { email } }: { payload: { email: string } }
    ) => {
      state.forgotPassword.email = email;
    },
    changeNewPassword(
      state,
      { payload: { password } }: { payload: { password: string } }
    ) {
      state.newPassword.newPassword = password;
    },
    changeConfirmNewPassword(
      state,
      { payload: { password } }: { payload: { password: string } }
    ) {
      state.newPassword.confirmNewPassword = password;
    },
  },
  extraReducers: (builder) => {
    // login
    builder.addCase(loginUserThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(loginUserThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.user = action.payload.user;
      state.permissions = action.payload.permissionsAccess;
    });
    builder.addCase(loginUserThunk.rejected, (state) => {
      state.loading = false;
    });

    // Get User Permissions
    builder.addCase(getUserPermissionsThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUserPermissionsThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.permissions = action.payload.permissions;
    });
    builder.addCase(getUserPermissionsThunk.rejected, (state) => {
      state.loading = false;
    });

    // forgotPassword
    builder.addCase(forgotPasswordThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(forgotPasswordThunk.fulfilled, (state) => {
      // state.forgotPassword.;
      state.loading = false;
    });
    builder.addCase(forgotPasswordThunk.rejected, (state) => {
      state.loading = false;
    });

    // changePassword
    builder.addCase(changePasswordThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(changePasswordThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.newPassword.newPassword = "";
      state.newPassword.confirmNewPassword = "";
    });
    builder.addCase(changePasswordThunk.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const selectLoggedInUser: () => AuthState = () =>
  useAppSelector((state) => state.auth);
export const selectForgotPassword: () => {
  username: string;
  email: string;
} = () => useAppSelector((state) => state.auth.forgotPassword);
export const selectNewPassword: () => {
  newPassword: string;
  confirmNewPassword: string;
} = () => useAppSelector((state) => state.auth.newPassword);
export const selectRemember: () => boolean = () =>
  useAppSelector((state) => state.auth.remember);
export default authSlice;
