import React from "react";
import axios from "axios";
import { ExamsFiltersType } from "../../pages/Exams/types";
import { Lang } from "shared/components/UI/Message";
import { CreateExam, Exam } from "shared/interfaces/Exam.interface";
import {
  createExamURL,
  getExamsURL,
  updateExamURL,
  deleteExamURL,
  getExamURL,
  constructDeleteExamsURL,
  UpdateExamsFilters,
  constructUpdateExamsURL,
} from "./urls";

export type MassUpdateExamsDtoBase = {
  filters: ExamsFiltersType;
  values: { visible: boolean };
};

export type MassUpdateExamsDtoInclude = MassUpdateExamsDtoBase & {
  include: string[];
};

export type MassUpdateExamsDtoExclude = MassUpdateExamsDtoBase & {
  exclude: string[];
};

export type MassExamsFiltersDto = {
  filters: UpdateExamsFilters;
};

export type MassExamsFiltersDtoInclude = MassExamsFiltersDto & {
  include: string[];
};

export type MassExamsFiltersDtoExclude = MassExamsFiltersDto & {
  exclude: string[];
};

function isMassUpdateExamsDtoInclude(
  data: any
): data is MassUpdateExamsDtoInclude {
  return !!data.include;
}

function isMassUpdateExamsDtoExclude(
  data: any
): data is MassUpdateExamsDtoExclude {
  return !!data.exclude;
}

export const createExam = (exam: CreateExam) => {
  const formData = new FormData();
  Object.entries(exam).map(([key, value]: [any, any]) => {
    if (key === "media" && exam.media) {
      exam.media.forEach((file) => {
        formData.append(key, file);
      });
    } else formData.append(key, value);
  });
  return axios.post(createExamURL(), formData);
};

export const getExams = (filters: {
  from?: string;
  to?: string;
  tags?: string;
  visible?: string;
  region?: string;
  pageSize?: number;
  pageIndex?: number;
  sortCol?: string;
  sortOrder?: string;
  searchString?: string;
}) => {
  return axios.get(getExamsURL(filters));
};

export const updateExam = (examId: string, exam: CreateExam) => {
  const formData = new FormData();
  Object.entries(exam).map(([key, value]: [any, any]) => {
    if (key === "media" && exam.media) {
      exam.media.forEach((file) => {
        formData.append(key, file);
      });
    } else formData.append(key, value);
  });
  return axios.put(updateExamURL(examId), formData);
};

export const deleteExam = (examId: string) => {
  return axios.delete(deleteExamURL(examId));
};

export const updateExams = (
  data: MassUpdateExamsDtoInclude | MassUpdateExamsDtoExclude
) => {
  if (isMassUpdateExamsDtoInclude(data)) {
    return axios.put(constructUpdateExamsURL(data.filters), {
      values: data.values,
      include: data.include,
    });
  } else {
    return axios.put(constructUpdateExamsURL(data.filters), {
      values: data.values,
      exclude: data.exclude,
    });
  }
};

export const deleteExams = (
  data: MassExamsFiltersDtoInclude | MassExamsFiltersDtoExclude
) => {
  if (isMassUpdateExamsDtoInclude(data)) {
    return axios.put(constructDeleteExamsURL(data.filters), {
      include: data.include,
    } as any);
  }
  if (isMassUpdateExamsDtoExclude(data)) {
    return axios.put(constructDeleteExamsURL(data.filters), {
      exclude: data.exclude,
    } as any);
  }
  return Promise.reject(undefined);
};

export const getExam = (examId: string) => {
  return axios.get(getExamURL(examId));
};

export const getExamsColumns = (
  {
    allSelected,
    setAllSelected,
    excludeExams,
    setExcludeExams,
    includeExams,
    setIncludeExams,
  }: any,
  lang: Lang
) => {
  return [
    {
      Header: (
        <input
          type="checkbox"
          checked={allSelected}
          onClick={() => {
            setAllSelected((state: any) => !state);
            setExcludeExams([]);
            setIncludeExams([]);
          }}
          className="text-primary"
        />
      ),
      accessor: "checkbox",
      width: "30",
      disableSortBy: true,
    },
    {
      Header: lang === "en" ? "title" : "titolo",
      accessor: "col1",
      width: "250",
      id: "title",
    },
    {
      Header: lang === "en" ? "region" : "regione",
      accessor: "col2",
      width: "150",
      id: "location", // FIXME
    },
    {
      Header: lang === "en" ? "start date/time" : "data d'inizio",
      accessor: "col3",
      width: "250",
      id: "start_date",
    },
    {
      Header: lang === "en" ? "end date/time" : "data/ora di fine",
      accessor: "col4",
      width: "250",
      id: "end_date",
    },
    {
      Header: lang === "en" ? "status" : "stato",
      accessor: "col5",
      width: "150",
      id: "status", // FIXME
    },
    {
      Header: lang === "en" ? "actions" : "azioni",
      accessor: "col6",
      width: "150",
      disableSortBy: true,
    },
  ];
};
