import {
  DotsVerticalIcon,
  PencilIcon,
  TrashIcon,
  UserCircleIcon,
  XIcon,
} from "@heroicons/react/outline";
import React, { useState, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { Column, useFlexLayout, useTable } from "react-table";
import Table from "shared/components/Table/Table";
import GoBack from "shared/components/UI/GoBack";
import Overlay from "shared/components/UI/Overlay";
import Spinner from "shared/components/UI/Spinner";
import {
  getCouncilThunk,
  selectCouncils,
  updateCouncilThunk,
} from "store/councilsStore/councilsReducer";
import { useAppDispatch } from "store/storeHooks";
import UserModal from "../../shared/components/UI/ScreenModal";
import { addStyles, findPhone, getInputStyles } from "utils/utils";
import SelectUser from "./SelectUser";
import {
  CouncilMember,
  CreateCouncilDetails,
  CreateCouncilUser,
} from "shared/interfaces/Council.interface";
import { useForm } from "react-hook-form";
import { getCouncilsColumns } from "api/Councils/counsils.api";
import SimpleImageDropzone from "shared/components/UI/SimpleImageDropzone";
import { DisplayIf } from "shared/components/UI/DisplayError";
import { getSingleImageConstraintsConfig } from "utils/utils";
import TablePopup, { PortalType } from "shared/components/UI/TablePopup";
import { toast } from "react-toastify";
import Message from "shared/components/UI/Message";
import { User, UserLowercase } from "shared/interfaces/User.interface";
import EditImage from "shared/components/UI/EditImage";
import { selectTranslationLanguage } from "store/translationStore/translationReducer";
import { CouncilRank } from "shared/interfaces/Rank.interface";

type RegionalCouncil = {
  name: string;
  usernames: CreateCouncilUser[];
  fileType: "council_pic";
  notes: string;
  image: null | File | "deleted" | string;
};

function RegionalCouncilPage() {
  const { region } = useParams();
  const dispatch = useAppDispatch();
  const { t } = useTranslation("common");
  const lang = selectTranslationLanguage();
  const [isOpen, setIsOpen] = useState(false);
  const [onComplete, setOnComplete] = useState<any>(null);
  const [reactHookFormName, setReactHookFormName] = useState<string>("");
  const [action, setAction] = useState("");
  const [councilMembers, setCouncilMembers] = useState<CouncilMember[] | null>(
    []
  );
  const [editImageOn, setEditImageOn] = useState(false);
  const form = useForm<RegionalCouncil>({
    defaultValues: {
      fileType: "council_pic",
      usernames: [],
      image: null,
      notes: "",
    },
  });
  const {
    register,
    setValue,
    getValues,
    control,
    watch,
    handleSubmit,
    trigger,
    formState: { errors },
    setError,
    clearErrors,
  } = form;
  const [portal, setPortal] = useState<PortalType<number>>({
    open: false,
    top: 0,
    left: 0,
    entity: 0,
  });
  const [areMembersFetched, setAreMemberFetched] = useState(false);
  const [defaultUser, setDefaultUser] = useState<CouncilMember | null>(null);

  function portalContent() {
    if (portal.entity === null) return;
    return (
      <div
        className="fixed z-50 flex flex-col gap-2 p-2 bg-white rounded-sm shadow-md"
        style={{
          top: portal.top,
          left: portal.left,
        }}
      >
        <button
          type="button"
          onClick={() => {
            setAction("Update");
            if (councilMembers && councilMembers[portal.entity as number]) {
              setDefaultUser(() => councilMembers[portal.entity as number]);
            }
            setReactHookFormName(function () {
              return `usernames.${portal.entity}.`;
            });
            setOnComplete(function setOnComplete() {
              return function (
                user: UserLowercase,
                rank: CouncilRank,
                priority: number
              ) {
                setCouncilMembers(function setCouncilMembers(council: any) {
                  const tempCouncilMembers = council.slice();
                  tempCouncilMembers[Number(portal.entity)] = {
                    pk_user: user.pk_user,
                    name: user.name,
                    surname: user.surname,
                    email: user.email,
                    addressname: user.address_details[0]?.address_name,
                    addressnumber: user.address_details[0]?.address_number,
                    postalcode: user.address_details[0]?.postal_code,
                    city: user.address_details[0]?.city,
                    province: user.address_details[0]?.province,
                    rank: rank,
                    priority,
                    phone: findPhone(user.contact_details)?.details,
                    profile_pic: user.profile_pic_url,
                  };
                  return tempCouncilMembers;
                });
                setDefaultUser(null);
              };
            });
            setIsOpen(true);
          }}
          className="flex gap-2 items-center text-table-edit"
        >
          <PencilIcon className="w-5 h-5" />
          {lang === "en" ? "Edit" : "Modifica"}
        </button>
        <button
          type="button"
          onClick={(e) => {
            const tempUsernames = [...getValues("usernames")];
            tempUsernames.splice(Number(portal.entity), 1);
            setValue("usernames", tempUsernames);
            setCouncilMembers((nationalCouncilMembers: any) => {
              const tempCouncilMembers = [...nationalCouncilMembers];
              tempCouncilMembers.splice(Number(portal.entity), 1);
              return tempCouncilMembers;
            });
          }}
          className="flex gap-2 items-center text-users-delete"
        >
          <TrashIcon className="w-5 h-5" />
          {lang === "en" ? "Delete" : "Eliminare"}
        </button>
      </div>
    );
  }

  const columns: Array<Column<Record<string, any>>> = useMemo(
    () => getCouncilsColumns(lang),
    [lang]
  );

  const data: Array<any> = useMemo(() => {
    if (councilMembers) {
      return councilMembers.map((user, i) => {
        return {
          col1: (
            <div className="flex items-center h-full">
              {lang === "en"
                ? (user.rank as CouncilRank).council_rank_eng
                : (user.rank as CouncilRank).council_rank_it}
            </div>
          ),
          col2: (
            <div className="flex items-center">
              <div className="w-6 2xl:w-8 h-6 2xl:h-8 rounded-full">
                {user.profile_pic ? (
                  <img
                    src={user.profile_pic}
                    alt=""
                    className="w-6 2xl:w-8 h-6 2xl:h-8 rounded-full object-cover"
                  />
                ) : (
                  <UserCircleIcon className="w-6 2xl:w-8 2xl:h-8 text-users-icon rounded-full" />
                )}
              </div>
              <div className="pl-4 text-xs">
                <div>
                  {user.name} {user.surname}
                </div>
                <div>{user.email}</div>
              </div>
            </div>
          ),
          col3: <div className="flex items-center h-full">{user.phone}</div>,
          col4: (
            <div className="relative flex items-center h-full">
              <button
                id={`portal-button-${i}`}
                type="button"
                onClick={(e) => {
                  return setPortal((state: any) => {
                    const thisButton = document.getElementById(
                      `portal-button-${i}`
                    );
                    const position = thisButton?.getBoundingClientRect();
                    return {
                      open: !state.open,
                      top: Number(position?.bottom),
                      left: Number(position?.left),
                      entity: i,
                    };
                  });
                }}
              >
                <DotsVerticalIcon className="w-5 h-5" />
              </button>
            </div>
          ),
        };
      });
    }
    return [];
  }, [councilMembers]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useFlexLayout
  );

  useEffect(() => {
    if (region) {
      register("image", getSingleImageConstraintsConfig());
      dispatch(getCouncilThunk({ council: region })).then(
        function handleRequest(res: any) {
          if (res.meta.requestStatus === "rejected") {
            toast.error(
              Message({
                action: "read",
                entity: `${t("regionalCouncil.entity")} ${region}`,
                error: t("genericError"),
                lang,
              })
            );
          }
          setAreMemberFetched(true);
        }
      );
    }
  }, []);

  const councils = selectCouncils();

  useEffect(() => {
    if (councils.data.data?.users && areMembersFetched) {
      councils.data.data.users.map((user, i) => {
        setValue(`usernames.${i}.pk_user` as any, user.pk_user);
        setValue(`usernames.${i}.rank` as any, user.rank);
        setValue(`usernames.${i}.priority` as any, user.rank.priority);
      });
      setValue(`image`, councils.data.data.image as any);
      setValue("notes", councils.data.data.notes);
      setCouncilMembers(councils.data.data.users);
      setAreMemberFetched(false);
    }
  }, [areMembersFetched]);

  function constructUpdateRegionalCouncil(data: RegionalCouncil) {
    const regionalCouncil: CreateCouncilDetails = {} as CreateCouncilDetails;
    regionalCouncil.fileType = data.fileType;
    if (region) {
      regionalCouncil.name = region;
    }
    regionalCouncil.usernames = JSON.stringify(
      data.usernames.map((username) => ({
        ...username,
        rank: (username.rank as CouncilRank).id,
      }))
    );
    if (data.image) {
      regionalCouncil.image = data.image as any;
    }
    regionalCouncil.notes = data.notes;
    return regionalCouncil;
  }

  const onSubmit = handleSubmit((data) => {
    if (region)
      dispatch(
        updateCouncilThunk({
          council: region,
          data: constructUpdateRegionalCouncil(data),
        })
      ).then(function handleRequest(res: any) {
        if (res.meta.requestStatus === "fulfilled") {
          toast.success(
            Message({
              action: "update",
              entity: `${t("regionalCouncil.entity")} ${region}`,
              lang,
            })
          );
          dispatch(getCouncilThunk({ council: region })).then(
            function handleRequest(res: any) {
              if (res.meta.requestStatus === "rejected") {
                toast.error(
                  Message({
                    action: "read",
                    entity: `${t("regionalCouncil.entity")} ${region}`,
                    error: t("genericError"),
                    lang,
                  })
                );
              } else {
                setAreMemberFetched(true);
              }
            }
          );
        } else {
          toast.error(
            Message({
              action: "update",
              entity: `${t("regionalCouncil.entity")} ${region}`,
              error: t("genericError"),
              lang,
            })
          );
        }
      });
  });

  return (
    <Overlay active={councils.loading} spinner={<Spinner />}>
      <div className="flex flex-col flex-grow gap-5 p-10 h-full">
        <GoBack uri="about-us?redirect=regionalCouncil" />
        <form onSubmit={onSubmit} className="flex gap-5 justify-between">
          <h1 className="text-3xl font-bold">
            {t("regionalCouncil.regionalCouncil")}
          </h1>
          <button type="submit" className="px-4 py-2 text-white bg-global-save">
            {t("regionalCouncil.save")}
          </button>
        </form>
        <div className="grid gap-5 grid-cols-2 h-full">
          <div className="flex flex-col gap-5">
            <div className="flex items-center justify-between">
              <h1 className="text-2xl font-bold">{region}</h1>
              <button
                type="button"
                onClick={() => {
                  if (councilMembers) {
                    setAction("Add");
                    setReactHookFormName(function () {
                      return `usernames.${councilMembers.length}.`;
                    });
                    setOnComplete(function setOnComplete() {
                      return function (
                        user: UserLowercase,
                        rank: CouncilRank,
                        priority: number
                      ) {
                        setCouncilMembers(function setCouncilMembers(
                          council: any
                        ) {
                          const tempCouncilMembers = council.slice();
                          tempCouncilMembers[councilMembers.length] = {
                            pk_user: user.pk_user,
                            name: user.name,
                            surname: user.surname,
                            email: user.email,
                            addressname: user.address_details[0]?.address_name,
                            addressnumber:
                              user.address_details[0]?.address_number,
                            postalcode: user.address_details[0]?.postal_code,
                            city: user.address_details[0]?.city,
                            province: user.address_details[0]?.province,
                            rank: rank,
                            priority: priority,
                            phone: findPhone(user.contact_details)?.details,
                            profile_pic: user.profile_pic_url,
                          };
                          return tempCouncilMembers;
                        });
                      };
                    });
                    setIsOpen(true);
                  }
                }}
                className="px-4 py-2 text-white bg-global-save border rounded-sm"
              >
                {t("regionalCouncil.addUser")}
              </button>{" "}
            </div>
            <Table {...tableInstance} />
            <div className="flex flex-col flex-grow gap-2">
              <label htmlFor="notes" className="text-lg font-medium">
                {t("regionalCouncil.notes")}
              </label>
              <textarea
                id="notes"
                {...register("notes")}
                placeholder={t("regionalCouncil.notesPlaceholder")}
                className={addStyles(
                  getInputStyles(false),
                  "flex flex-col flex-grow"
                )}
              />
            </div>
            <UserModal isOpen={isOpen} setIsOpen={setIsOpen}>
              <div
                className="flex flex-col gap-5 p-10 bg-white rounded-md"
                style={{ width: "50rem" }}
              >
                <div className="flex justify-between">
                  <div className="text-2xl font-bold">
                    {action === "Add"
                      ? t("regionalCouncil.create")
                      : t("regionalCouncil.update")}
                  </div>
                  <button
                    type="button"
                    onClick={() => {
                      setDefaultUser(null);
                      setIsOpen(false);
                    }}
                  >
                    <XIcon className="w-6 h-6" />
                  </button>
                </div>
                <SelectUser
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  reactHookFormName={reactHookFormName}
                  propGetValues={getValues}
                  propSetValue={setValue}
                  onComplete={onComplete}
                  excludeUsers={councilMembers}
                  defaultUser={defaultUser}
                />
              </div>
            </UserModal>
          </div>
          <div className="flex flex-col gap-5">
            <h1 className="py-1.5 text-2xl font-bold">
              {t("regionalCouncil.photo")}
            </h1>
            <SimpleImageDropzone
              clearErrors={clearErrors}
              setError={setError}
              trigger={trigger}
              setValue={setValue}
              watch={watch}
              reactHookFormName="image"
              aspect={0}
              resize={true}
              canDelete={true}
            />
            <DisplayIf condition={!!errors?.image}>
              <div className="text-center text-red-500">
                {errors?.image?.message}
              </div>
            </DisplayIf>
          </div>
        </div>
        <TablePopup
          isOpen={portal.open}
          close={() => setPortal((state) => ({ ...state, open: false }))}
        >
          {portalContent()}
        </TablePopup>
      </div>
    </Overlay>
  );
}

export default RegionalCouncilPage;
