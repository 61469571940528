import { Plan } from "api/Plans/plans.api";
import React from "react";
import { useTranslation } from "react-i18next";

const SubscriptionCard = ({ plan }: { plan: Plan }) => {
  const { t } = useTranslation("common");
  return (
    <>
      <div className="px-5 py-4 w-60 h-full bg-roles-bg">
        <p className="mb-3 text-lg font-semibold">{plan.title}</p>
        <p>
          <span className="font-semibold">{plan.price}€</span>/{t("plans.year")}{" "}
          {plan.including_fees === false ? `+ ${t("plans.fees")}` : ""}
        </p>
      </div>
    </>
  );
};

export default SubscriptionCard;
