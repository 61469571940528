import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { useAppDispatch } from "store/storeHooks";
import { getTagsThunk, selectTags } from "store/tagsStore/tagsReducer";
import { createNewThunk, selectNews } from "store/newsStore/newsReducer";
import { CreateNew, featuredOptions } from "shared/interfaces/New.interface";
import { CreateLocation } from "shared/interfaces/Location.interface";
import { visibilityOptions } from "shared/interfaces/Visibility.interface";
import { PostFormType } from "./types";
import LocationSearch from "shared/components/UI/LocationSearch";
import {
  getInputStyles,
  getReactSelectStyles,
  stringToFile,
} from "utils/utils";
import GoBack from "shared/components/UI/GoBack";
import DisplayInputError from "shared/components/UI/DisplayInputError";
import { toast } from "react-toastify";
import Message from "shared/components/UI/Message";
import Spinner from "shared/components/UI/Spinner";
import Overlay from "shared/components/UI/Overlay";
import { locationActions } from "store/globalStore";
import EditImage from "shared/components/UI/EditImage";
import Creatable from "react-select/creatable";
import EmailEditor from "react-email-editor";
import axios from "axios";
import { CMS_BE_URL } from "api/urls";
import sample from "shared/assets/sample/sample.json";
import SimpleImageDropzone from "shared/components/UI/SimpleImageDropzone";
import { selectTranslationLanguage } from "store/translationStore/translationReducer";
import { filter } from "ramda";
import { useNavigate } from "react-router";
import CropImage from "shared/components/UI/CropImage";

export default function CreateEventPage() {
  const nagivate = useNavigate();
  const { t } = useTranslation("common");
  const lang = selectTranslationLanguage();
  const [tagsOptions, setTagsOptions] = useState<any>([]);
  const [editImageOn, setEditImageOn] = useState(false);
  const [richtext, setRichtext] = useState("");
  const [json, setJson] = useState<any>("");
  const form = useForm<PostFormType>({
    mode: "onChange",
    defaultValues: {
      is_visible: visibilityOptions[0],
      title: "",
      author: "",
      richtext: "",
      styles_json: "",
      tags: [],
      short_description: "",
      location: {
        country: undefined,
        city: undefined,
        province: undefined,
        region: undefined,
        postal_code: undefined,
        street_name: undefined,
        street_number: undefined,
        longitude: undefined,
        latitude: undefined,
      },
      featured: featuredOptions[0],
      fileType: "news_pic",
      thumbnail: null,
      media: null,
    },
  });
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    setError,
    formState: { errors },
    control,
    watch,
    clearErrors,
    trigger,
  } = form;

  function constructCreateNew(data: PostFormType) {
    const post: CreateNew = {} as CreateNew;
    post.fileType = data.fileType;
    if (data.is_visible.value !== "") post.is_visible = data.is_visible.value;
    if (data.title !== "") post.title = data.title;
    if (data.short_description !== "")
      post.short_description = data.short_description;
    if (json.body) {
      json.body.values.backgroundColor = "#fff";
      json.body.values.textColor = "#000000";
    }
    post.richtext = stringToFile(richtext);
    post.styles_json = JSON.stringify(json);
    if (data.tags.length) {
      post.tags = JSON.stringify(data.tags.map((tag) => tag.value));
    }
    const tempLocationObject: CreateLocation = {
      street_name: data.location.street_name,
      street_number: data.location.street_number,
      postal_code: data.location.postal_code,
      city: data.location.city,
      province: data.location.province,
      region: data.location.region,
      country: data.location.country,
    } as CreateLocation;
    if (data.location.longitude)
      tempLocationObject["longitude"] = data.location.longitude;
    if (data.location.latitude)
      tempLocationObject["latitude"] = data.location.latitude;
    post.location = JSON.stringify(tempLocationObject);
    if (data.featured.value !== "") post.featured = data.featured.value;
    if (data.thumbnail) post.thumbnail = data.thumbnail as File;
    if (data.author) post.author = data.author;
    return post;
  }

  const onSubmit: SubmitHandler<PostFormType> = function (data) {
    dispatch(createNewThunk(constructCreateNew(data))).then((res) => {
      if (res.meta.requestStatus === "rejected") {
        toast.error(
          Message({
            action: "create",
            entity: t("new.new"),
            error: t("genericError"),
            lang,
            gender: "female",
          })
        );
      } else {
        dispatch(locationActions.clearPlaceDetails());
        toast.success(
          Message({
            action: "create",
            entity: t("new.new"),
            lang,
            gender: "female",
          })
        );
        nagivate("/news");
      }
    });
  };

  //EMAIL EDITOR
  const emailEditorRef: any = useRef(null);

  const onLoad = useCallback(() => {
    const timer = setInterval(() => {
      if (
        emailEditorRef &&
        emailEditorRef.current &&
        emailEditorRef.current.editor
      ) {
        emailEditorRef.current.editor.loadDesign(sample);
        // emailEditorRef.current.editor.loadBlank({
        //   backgroundColor: "#fff",
        // });

        emailEditorRef.current.editor.addEventListener(
          "design:updated",
          function () {
            // Design is updated by the user

            emailEditorRef.current.editor.exportHtml(function (data: any) {
              const json = data.design; // design json
              const html = data.html; // design html
              setRichtext(html);
              setJson(json);

              // Save the json, or html here
            });
          }
        );
        emailEditorRef.current.editor.registerCallback(
          "image",
          async (file: any, done: any) => {
            const formD = new FormData();
            formD.append("fileType", "document_doc");
            formD.append("files", file.attachments[0]);
            const res = await axios.post(
              `${CMS_BE_URL}/files/uploadFile`,
              formD,
              {
                headers: {
                  "Content-Type":
                    "multipart/form-data; boundary=<calculated when request is sent>",
                },
              }
            );
            const url = res.data.urls[0];

            done({ progress: 100, url });
          }
        );

        clearInterval(timer);
      }
    }, 500);
  }, [emailEditorRef]);

  const news = selectNews();
  const dispatch = useAppDispatch();
  const tags = selectTags();
  useEffect(() => {
    dispatch(getTagsThunk()).then((res) => {
      if (res.meta.requestStatus === "rejected") {
        toast.error(
          Message({
            action: "read",
            entity: t("news.tags"),
            error: t("genericError"),
            lang,
            gender: "female",
          })
        );
      }
    });
  }, []);
  useEffect(() => {
    const tagsMap = tags.data.tags.map((tag) => {
      return {
        value: tag.pk_tag,
        label: tag.pk_tag,
      };
    });
    setTagsOptions(tagsMap);
  }, [tags]);

  return (
    <Overlay active={news.loading} spinner={<Spinner />}>
      <FormProvider {...form}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col flex-grow gap-5 p-10 h-full bg-gray-100"
        >
          <GoBack uri="news" />
          <div className="flex items-center justify-between w-full">
            <div className="flex gap-5 items-center">
              <h1 className="text-3xl font-bold">{t("new.new")}</h1>
              <p className="text-global-subHeader text-xl"></p>
            </div>
            <div className="flex gap-5 items-center">
              <label
                htmlFor="featured"
                className="block whitespace-nowrap text-lg font-medium"
              >
                {t("new.featured")} *
              </label>
              <Controller
                name="featured"
                control={control}
                render={({ field }) => (
                  <Select
                    defaultValue={featuredOptions[0]}
                    styles={getReactSelectStyles(false)}
                    inputId="featured"
                    options={featuredOptions}
                    value={field.value}
                    onChange={(e) => field.onChange(e)}
                    isSearchable={false}
                  />
                )}
              />
              <label
                htmlFor="is_visible"
                className="block whitespace-nowrap text-lg font-medium"
              >
                {t("new.visible")} *
              </label>
              <Controller
                name="is_visible"
                control={control}
                render={({ field }) => (
                  <Select
                    defaultValue={visibilityOptions[0]}
                    styles={getReactSelectStyles(false)}
                    inputId="is_visible"
                    options={visibilityOptions}
                    value={field.value}
                    onChange={(e) => field.onChange(e)}
                    isSearchable={false}
                  />
                )}
              />
              <button
                type="submit"
                className="px-4 py-2 text-white bg-global-save border rounded-sm"
              >
                {t("new.save")}
              </button>
            </div>
          </div>
          <div className="grid flex-grow gap-5 grid-cols-2">
            <div className="flex flex-col flex-grow gap-2">
              <label htmlFor="title" className="block text-lg font-medium">
                {t("new.title")} *
              </label>
              <input
                id="title"
                type="text"
                placeholder={`${t("new.post")} ${t("new.title")}`}
                {...register("title", {
                  required: `${t("new.post")} ${t("new.title")} ${t(
                    "new.required"
                  )}.`,
                  maxLength: {
                    value: 50,
                    message: `${t("new.post")} ${t("new.title")} ${t(
                      "new.shouldBe"
                    )} ${50} ${t("new.characters")}.`,
                  },
                })}
                className={getInputStyles(!!errors.title?.message)}
                autoComplete="off"
              />
              <DisplayInputError message={errors.title?.message} />
              <label htmlFor="author" className="block text-lg font-medium">
                {t("new.author")} *
              </label>
              <input
                id="author"
                type="text"
                placeholder={t("new.author")}
                {...register("author", {
                  required: `${t("new.author")} ${t("new.required")}.`,
                  maxLength: {
                    value: 30,
                    message: `${t("new.author")} ${t("new.shouldBe")} ${30} ${t(
                      "new.characters"
                    )}.`,
                  },
                })}
                className={getInputStyles(!!errors.author?.message)}
                autoComplete="off"
              />
              <DisplayInputError message={errors.author?.message} />
              <label htmlFor="title" className="block text-lg font-medium">
                {t("new.shortDescription")} *
              </label>
              <input
                id="title"
                type="text"
                placeholder={t("new.shortDescription")}
                {...register("short_description", {
                  required: `${t("new.shortDescription")} ${t("new.required")}`,
                  maxLength: {
                    value: 250,
                    message: `${t("new.shortDescription")} ${t(
                      "new.shouldBe"
                    )} ${250} ${t("new.characters")}.`,
                  },
                })}
                className={getInputStyles(!!errors.short_description?.message)}
                autoComplete="off"
              />
              <DisplayInputError message={errors.short_description?.message} />
              <label htmlFor="tags" className="block text-lg font-medium">
                {t("new.tags")} *
              </label>
              <Controller
                name="tags"
                control={control}
                rules={{
                  required: `${t("new.tags")} ${t("new.areRequired")}.`,
                  validate: {
                    maxLength(e) {
                      if (filter((tag) => tag.value.length > 50, e).length)
                        return `${t("new.tags")} ${t("new.shouldBe")} ${50} ${t(
                          "new.characters"
                        )}.`;
                      return true;
                    },
                  },
                }}
                render={({ field }) => (
                  <Creatable
                    placeholder={t("new.tags")}
                    styles={getReactSelectStyles(!!errors.tags)}
                    isLoading={tags.loading}
                    inputId="tags"
                    isMulti={true}
                    options={tagsOptions}
                    onChange={(e) => field.onChange(e)}
                  />
                )}
              />
              <DisplayInputError message={(errors.tags as any)?.message} />
            </div>
            <div className="flex flex-col gap-2">
              <label htmlFor="thumbnail" className="block text-lg font-medium">
                {t("new.coverPhoto")} *
              </label>
              <Controller
                name="thumbnail"
                control={control}
                rules={{
                  required: `${t("new.coverPhoto")} ${t("new.required")}.`,
                  validate: {
                    checkSize: (file) => {
                      if (file && file.size > 2 * 1048576)
                        return `${t("singleDropzone.size")} 2MB`;
                      return true;
                    },
                  },
                }}
                render={() => (
                  <SimpleImageDropzone
                    clearErrors={clearErrors}
                    trigger={trigger}
                    setValue={setValue}
                    watch={watch}
                    setError={setError}
                    reactHookFormName="thumbnail"
                    setEditImageOn={setEditImageOn}
                    aspect={16 / 9}
                  />
                )}
              />
              {editImageOn ? (
                <CropImage
                  canClose={true}
                  canForce={true}
                  aspect={16 / 9}
                  reactHookFormName="thumbnail"
                  editImageOn={editImageOn}
                  setEditImageOn={setEditImageOn}
                />
              ) : null}
              <DisplayInputError message={errors.thumbnail?.message} />
            </div>
          </div>
          <LocationSearch
            errors={errors}
            watch={watch}
            clearErrors={clearErrors}
            setValue={setValue}
            register={register}
            getValues={getValues}
            reactHookFormName="location"
            required={false}
            displayStar={true}
          />
          <div className="flex flex-col gap-2">
            <label className="block text-lg font-medium">
              {t("new.mainBody")}
            </label>
            <EmailEditor
              minHeight={1000}
              ref={emailEditorRef}
              onLoad={onLoad}
            />
          </div>
        </form>
      </FormProvider>
    </Overlay>
  );
}
