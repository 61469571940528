import { XIcon, PencilIcon } from "@heroicons/react/outline";
import React, { useEffect, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import {
  UseFormClearErrors,
  UseFormRegister,
  UseFormSetError,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { resizeFile } from "utils/utils";

export default function SimpleImageDropzone({
  trigger,
  setValue,
  watch,
  reactHookFormName,
  errors,
  setError,
  setEditImageOn,
  clearErrors,
  aspect,
  resize = false,
  onComplete,
  register,
  required = false,
  canDelete = false,
}: {
  trigger: UseFormTrigger<any>;
  setValue: UseFormSetValue<any>;
  watch: UseFormWatch<any>;
  reactHookFormName: string;
  setError: UseFormSetError<any>;
  errors?: any;
  clearErrors: UseFormClearErrors<any>;
  setEditImageOn?: (a: boolean) => void;
  aspect: number;
  resize?: boolean;
  onComplete?: any;
  register?: UseFormRegister<any>;
  required?: boolean;
  canDelete?: boolean;
}) {
  const { t } = useTranslation("common");
  const onDrop = useCallback(async (files) => {
    let file;
    if (resize) {
      file = (await resizeFile(files[0], aspect)) as File;
    } else {
      file = files[0];
    }
    clearErrors(reactHookFormName);
    setValue(reactHookFormName, file);
    await trigger([reactHookFormName]);
    if (onComplete) onComplete(reactHookFormName);
    if (setEditImageOn) setEditImageOn(true);
  }, []);

  const { getRootProps, getInputProps, isDragActive, fileRejections } =
    useDropzone({
      onDrop,
      multiple: false,
      accept: "image/*",
    });

  const image = watch(reactHookFormName);

  useEffect(() => {
    if (fileRejections.length) {
      if (setError) {
        setError(reactHookFormName, {
          type: "manual",
          message: t("singleDropzone.type"),
        });
      }
    }
  }, [fileRejections]);

  if (required) {
    useEffect(() => {
      if (register) {
        register(reactHookFormName, {
          required: `${t("singleDropzone.image")} ${t(
            "singleDropzone.required"
          )}.`,
        });
      }
    }, []);
  }

  return (
    <div {...getRootProps()} className="cursor-pointer">
      {image && image !== "deleted" ? (
        <div className="group flex items-center justify-center w-full h-full">
          <div className="relative flex w-full h-full">
            <img
              src={
                typeof image === "string" ? image : URL.createObjectURL(image)
              }
              alt={typeof image === "string" ? "" : image.name}
              className="block w-full object-cover"
            />
            <div className="absolute right-0 top-0 group-hover:flex hidden gap-2 p-2">
              {setEditImageOn && typeof image === "object" ? (
                <button
                  type="button"
                  className="p-0.5 bg-green-500 rounded-md"
                  onClick={(e) => {
                    e.stopPropagation();
                    setEditImageOn(true);
                  }}
                >
                  <PencilIcon className="w-6 h-6 text-white" />
                </button>
              ) : null}
              {canDelete && (
                <button
                  type="button"
                  className="p-0.5 bg-red-500 rounded-md"
                  onClick={(e) => {
                    e.stopPropagation();
                    clearErrors(reactHookFormName);
                    setValue(reactHookFormName, "deleted");
                    trigger(reactHookFormName);
                  }}
                >
                  <XIcon className="w-6 h-6 text-white" />
                </button>
              )}
            </div>
            <div
              style={{
                background: "rgba(0, 0, 0, 0.5)",
              }}
              className="absolute bottom-0 right-0 group-hover:flex hidden items-center justify-center p-2 w-full text-white font-medium"
            >
              {t("singleDropzone.changeImage")}
            </div>
          </div>
        </div>
      ) : null}
      <div
        className={`flex-col gap-2 cursor-pointer ${
          image && image !== "deleted" ? "hidden" : "flex"
        }`}
      >
        <div className="px-4 py-2 text-center bg-gray-300">
          {isDragActive ? t("singleDropzone.drop") : t("singleDropzone.choose")}
        </div>
        <input {...getInputProps()} />
        <div className="text-center text-xs">
          {t("singleDropzone.maxSize")}:{" "}
          <span className="font-semibold">2MB</span>
        </div>
      </div>
    </div>
  );
}
