import React from "react";
import { Link } from "react-router-dom";
import { authActions } from "store/globalStore";
import { useAppDispatch } from "store/storeHooks";
import {
  loginUserThunk,
  selectLoggedInUser,
  selectRemember,
} from "store/authStore/authReducer";
import ANMB from "shared/assets/images/ANMB";
import { useTranslation } from "react-i18next";
import { SubmitHandler, useForm } from "react-hook-form";
import { LoginForm } from "./types";
import { toast } from "react-toastify";
import Language from "shared/components/Dashboard/Language";
import { getLookupsThunk } from "store/lookups/lookupsReducer";
import Message from "shared/components/UI/Message";
import { selectTranslationLanguage } from "store/translationStore/translationReducer";
import { sanitizeUser } from "utils/utils";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { CMS_BE_URL } from "api/urls";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

export default function LogIn() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginForm>();
  const dispatch = useAppDispatch();
  const remember = selectRemember();
  const { t } = useTranslation("common");
  const lang = selectTranslationLanguage();
  const loggedInUser = selectLoggedInUser();

  const onSubmit: SubmitHandler<LoginForm> = function (data) {
    const { username, password } = data;
    dispatch(loginUserThunk({ username, password })).then((res: any) => {
      if (res.meta.requestStatus === "fulfilled") {
        if (
          (res.payload.permissionsAccess &&
            !res.payload.permissionsAccess["*"] &&
            res.payload.user &&
            res.payload.user.ROLE === 5) ||
          (res.payload.permissionsAccess &&
            !res.payload.permissionsAccess.accessCMS?.ENABLED &&
            !res.payload.permissionsAccess["*"])
        ) {
          toast.error(() => (
            <div className="flex flex-col gap-1">
              <p className="text-lg">{t("login.failed")}</p>
              <p>{t("login.noPermissions")}</p>
            </div>
          ));
          dispatch(authActions.logout());
        } else {
          const remember = localStorage.getItem("remember");
          if (remember && JSON.parse(remember)) {
            localStorage.setItem("token", JSON.stringify(res.payload.token));
            localStorage.setItem(
              "user",
              JSON.stringify(sanitizeUser(res.payload.user))
            );
          } else {
            localStorage.setItem("remember", JSON.stringify(false));
            sessionStorage.setItem("token", JSON.stringify(res.payload.token));
            sessionStorage.setItem(
              "user",
              JSON.stringify(sanitizeUser(res.payload.user))
            );
          }
          dispatch(getLookupsThunk()).then((res) => {
            if (res.meta.requestStatus === "rejected") {
              toast.error(
                Message({
                  action: "read",
                  entity: t("lookups.lookups"),
                  error: t("genericError"),
                  lang,
                })
              );
            }
          });
        }
      } else {
        toast.error(() => (
          <div className="flex flex-col gap-1">
            <p className="text-lg">{t("login.failed")}</p>
            <p>{t("login.incorrect")}</p>
          </div>
        ));
      }
    });
  };

  const submitSSO = async (data: any) => {
    try {
      const res = await axios.post(`${CMS_BE_URL}/users/loginSSO`, data);
      if (
        (res.data.permissionsAccess &&
          !res.data.permissionsAccess["*"] &&
          res.data.user &&
          res.data.user.ROLE === 5) ||
        (res.data.permissionsAccess &&
          !res.data.permissionsAccess.accessCMS?.ENABLED &&
          !res.data.permissionsAccess["*"])
      ) {
        toast.error(() => (
          <div className="flex flex-col gap-1">
            <p className="text-lg">{t("login.failed")}</p>
            <p>{t("login.noPermissions")}</p>
          </div>
        ));
        dispatch(authActions.logout());
      } else {
        const remember = localStorage.getItem("remember");
        if (remember && JSON.parse(remember)) {
          localStorage.setItem("token", JSON.stringify(res.data.token));
          localStorage.setItem(
            "user",
            JSON.stringify(sanitizeUser(res.data.user))
          );
        } else {
          localStorage.setItem("remember", JSON.stringify(false));
          sessionStorage.setItem("token", JSON.stringify(res.data.token));
          sessionStorage.setItem(
            "user",
            JSON.stringify(sanitizeUser(res.data.user))
          );
        }
        dispatch(getLookupsThunk()).then((res) => {
          if (res.meta.requestStatus === "rejected") {
            toast.error(
              Message({
                action: "read",
                entity: t("lookups.lookups"),
                error: t("genericError"),
                lang,
              })
            );
          }
        });
      }
      dispatch(authActions.setUser({ user: res.data.user }));
      dispatch(
        authActions.setPermissions({ permissions: res.data.permissionsAccess })
      );
    } catch (err) {
      toast.error(() => (
        <div className="flex flex-col gap-1">
          <p className="text-lg">{t("login.failed")}</p>
          <p>{t("login.incorrect")}</p>
        </div>
      ));
    }
  };

  const googleFlow = async (a: string) => {
    const res = await fetch(
      `https://www.googleapis.com/oauth2/v3/userinfo?access_token=${a}`,
      {
        method: "GET",
      }
    );
    const gData = await res.json();

    const data = {
      provider: "google",
      accessToken: a,
      id: gData.sub,
      name: gData.name,
      email: gData.email,
    };

    await submitSSO(data);
  };

  const googleLogin = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      googleFlow(codeResponse.access_token);
    },
  });

  const responseFacebook = async (response: any) => {
    const data = {
      accessToken: response.accessToken,
      provider: "facebook",
      id: response.id,
      name: response.name,
      email: response.email,
    };
    await submitSSO(data);
  };

  return (
    <div className="flex min-h-screen md:grid md:grid-cols-2">
      <div className="flex flex-1 flex-col justify-center sm:px-6 lg:flex-none lg:px-20 xl:px-20">
        <div className="flex flex-col gap-5 mx-auto w-full max-w-sm lg:w-96">
          <div className="flex flex-col gap-2">
            <div className="w-1/2">
              <ANMB />
            </div>
            <h2 className="text-gray-900 text-3xl font-semibold">
              {t("login.loginMessage")}
            </h2>
            {errors.username ? (
              <p className="text-red-400 text-sm">{errors.username?.message}</p>
            ) : null}
            {errors.password ? (
              <p className="text-red-400 text-sm">{errors.password?.message}</p>
            ) : null}
          </div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col gap-5"
          >
            <div className="flex flex-col gap-2">
              <label
                htmlFor="username"
                className="block text-gray-700 text-sm font-semibold"
              >
                {t("login.username")}
              </label>
              <input
                id="username"
                type="text"
                className="placeholder-gray-400 block w-full border border-gray-300 focus:border-gray-300 rounded-sm focus:outline-none shadow-sm appearance-none focus:ring-0 sm:text-sm"
                {...register("username", {
                  required: `${t("login.username")} ${t("login.required")}.`,
                })}
              />
            </div>

            <div className="flex flex-col gap-2">
              <label
                htmlFor="password"
                className="block text-gray-700 text-sm font-semibold"
              >
                {t("login.password")}
              </label>
              <input
                id="password"
                type="password"
                className="placeholder-gray-400 block w-full border border-gray-300 focus:border-gray-300 rounded-sm focus:outline-none shadow-sm appearance-none focus:ring-0 sm:text-sm"
                {...register("password", {
                  required: `${t("login.password")} ${t("login.required")}.`,
                })}
              />
            </div>

            <div className="flex items-center justify-between">
              <div className="flex gap-2 items-center">
                <input
                  id="remember-me"
                  name="remember-me"
                  type="checkbox"
                  checked={remember}
                  className="w-4 h-4 text-login-color border border-login-color rounded-sm cursor-pointer focus:ring-0 focus:ring-white"
                  onChange={() =>
                    dispatch(
                      authActions.changeRemember({ remember: !remember })
                    )
                  }
                />
                <label
                  htmlFor="remember-me"
                  className="block text-gray-900 text-sm cursor-pointer select-none"
                >
                  {t("login.remember")}
                </label>
              </div>

              <div className="text-sm">
                <Link
                  to="/forgotPassword"
                  className="text-auth-forgot font-medium"
                >
                  {t("login.forgotPassword")}
                </Link>
              </div>
            </div>

            <div>
              <button
                disabled={loggedInUser.loading}
                type="submit"
                className="flex justify-center p-2 w-full text-white text-sm font-medium bg-login-color border border-transparent rounded-sm focus:outline-none shadow-sm focus:ring-0"
              >
                {t("login.login")}
              </button>
            </div>
          </form>
          <button
            className="px-4 py-2 text-sm border border-gray-300 rounded-sm shadow-sm"
            onClick={() => googleLogin()}
            type="button"
          >
            <div className="flex gap-5 items-center justify-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                className="w-5 h-5"
                viewBox="0 0 30 30"
                fill="#053F79"
              >
                {" "}
                <path d="M 15.003906 3 C 8.3749062 3 3 8.373 3 15 C 3 21.627 8.3749062 27 15.003906 27 C 25.013906 27 27.269078 17.707 26.330078 13 L 25 13 L 22.732422 13 L 15 13 L 15 17 L 22.738281 17 C 21.848702 20.448251 18.725955 23 15 23 C 10.582 23 7 19.418 7 15 C 7 10.582 10.582 7 15 7 C 17.009 7 18.839141 7.74575 20.244141 8.96875 L 23.085938 6.1289062 C 20.951937 4.1849063 18.116906 3 15.003906 3 z"></path>
              </svg>
              Google login
            </div>
          </button>
          <FacebookLogin
            appId="260622796227828"
            autoLoad={false}
            fields="name,email,picture"
            callback={responseFacebook}
            render={(renderProps) => (
              <button
                type="button"
                onClick={renderProps.onClick}
                className="px-4 py-2 text-sm border border-gray-300 rounded-sm shadow-sm"
              >
                <div className="flex gap-5 items-center justify-center">
                  <svg
                    className="w-5 h-5"
                    aria-hidden="true"
                    fill="#053F79"
                    viewBox="0 0 20 20"
                  >
                    <path
                      fillRule="evenodd"
                      d="M20 10c0-5.523-4.477-10-10-10S0 4.477 0 10c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V10h2.54V7.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V10h2.773l-.443 2.89h-2.33v6.988C16.343 19.128 20 14.991 20 10z"
                      clipRule="evenodd"
                    />
                  </svg>{" "}
                  Facebook login
                </div>
              </button>
            )}
          />
        </div>
      </div>
      <div className="relative hidden md:block">
        <img
          className="w-full h-full object-cover"
          src="/images/login.png"
          alt={t("login.imageAlt")}
        />
        <div className="absolute bottom-0 right-0 p-4">
          <Language />
        </div>
      </div>
    </div>
  );
}
