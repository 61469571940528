export const getDocumentsColumns: (lang: string) => (
  | {
      Header: string;
      accessor: string;
      width: string;
      id: string;
      disableSortBy?: boolean;
    }
  | {
      Header: string;
      accessor: string;
      width: string;
      disableSortBy: boolean;
      id?: undefined;
    }
)[] = (lang: string) => {
  return [
    {
      Header: lang === "en" ? "Title" : "Titolo",
      accessor: "col1",
      width: "150",
      id: "title",
    },
    {
      Header: lang === "en" ? "File" : "File",
      accessor: "col2",
      disableSortBy: true,
      width: "150",
      id: "file",
    },
    {
      Header: lang === "en" ? "Author" : "Autore",
      accessor: "col3",
      width: "150",
      id: "owner.id",
    },

    {
      Header: lang === "en" ? "Created at" : "Creato il",
      accessor: "col4",
      width: "150",
      id: "created_at",
    },
    {
      Header: lang === "en" ? "Visible" : "Visibile",
      accessor: "col5",
      width: "150",
      id: "is_visible",
    },
    {
      Header: lang === "en" ? "Actions" : "Azioni",
      accessor: "col6",
      width: "150",
      id: "ACTIONS",
    },
  ];
};
