import { CMS_BE_URL } from "api/urls";

export const getUsersURL = (filters: {
  role?: string;
  location?: string;
  rank?: string;
  status?: string;
  pageSize?: number;
  pageIndex?: number;
  searchString?: string;
  sortCol?: string;
  sortOrder?: string;
  including?: string;
}): string => {
  const q = Object.entries(filters)
    .filter(([, value]) => !!value)
    .map(([key, value]) => `${key}=${value}`);
  return `${CMS_BE_URL}/users?`.concat(q.join("&"));
};

export const getUserURL = (username: string): string => {
  return `${CMS_BE_URL}/users/${username}`;
};

export const deleteUserURL = (username: string, isHard: boolean): string =>
  `${CMS_BE_URL}/users/${username}${isHard ? "?type=delete" : ""}`;

export const resendEmailCreationURL = (username: string) =>
  `${CMS_BE_URL}/users/resendEmailCreation/${username}`;

export const resendEmailRegisterURL = (username: string) =>
  `${CMS_BE_URL}/resendEmailRegister/${username}`;

export const resetPasswordURL = () => `${CMS_BE_URL}/users/resetPassword`;

export const usersBulkUpdateURL = () =>
  `${CMS_BE_URL}/users/massUpdateUsersCheckbox`;
