import React from "react";
import { CreatePermissionAccess } from "shared/interfaces/RolePermission.interface";
import { permissionsManagementActions } from "store/globalStore";
import { selectUpdateRolePermissions } from "store/permissionsManagement/permissionsManagementReducer";
import { useAppDispatch } from "store/storeHooks";

// ** type definitions **
type crud = "create" | "read" | "update" | "delete";
type CrudType = "ANY" | "OWN" | "NONE";

const UpdatePermissionChild = ({
  permission,
  enabled,
}: {
  permission: CreatePermissionAccess;
  enabled: boolean;
}) => {
  const dispatch = useAppDispatch();
  const rolePermissions = selectUpdateRolePermissions();

  function isChecked(i: number, crud: crud, crudType: CrudType): boolean {
    if (rolePermissions.permissionsAccess[i][crud] === crudType) return true;
    return false;
  }

  const onEnabledChangeHandler = (i: number, bool: boolean) => {
    const cuds: crud[] = ["create", "update", "delete"];
    dispatch(
      permissionsManagementActions.changeUpdateRolePermissionEnabledAccess({
        i,
        bool,
      })
    );
    dispatch(
      permissionsManagementActions.changeUpdateRolePermissionsPermissionAccess({
        i,
        crud: "read",
        crudType: bool ? "ANY" : "NONE",
      })
    );
    if (bool === false) {
      cuds.map((cud) => {
        dispatch(
          permissionsManagementActions.changeUpdateRolePermissionsPermissionAccess(
            {
              i,
              crud: cud,
              crudType: "NONE",
            }
          )
        );
      });
    }
  };

  const onPermissionsCudChangeHandler = (i: number, crudType: CrudType) => {
    const cuds: crud[] = ["create", "update", "delete"];
    cuds.map((cud) => {
      dispatch(
        permissionsManagementActions.changeUpdateRolePermissionsPermissionAccess(
          {
            i,
            crud: cud,
            crudType,
          }
        )
      );
    });
  };

  if (permission) {
    return (
      <tr key={permission.permissionName}>
        <td className="flex flex-row justify-start px-6 py-4 text-gray-900 whitespace-nowrap text-sm font-medium">
          <div className="flex flex-row gap-3 w-full">
            <span className="pl-10 w-3/4 truncate">{permission.title}</span>
          </div>
        </td>
        <td className="px-6 py-4 text-gray-500 whitespace-nowrap text-sm">
          <div className="flex gap-7 justify-center mt-2">
            <input
              type="checkbox"
              className="text-users-create"
              name="permissions-checkbox"
              id={`checkbox-${permission.permissionName}-1`}
              disabled={enabled ? false : true}
              checked={
                rolePermissions.permissionsAccess[permission.index]?.enabled
              }
              onChange={() =>
                onEnabledChangeHandler(
                  permission.index,
                  !rolePermissions.permissionsAccess[permission.index]?.enabled
                )
              }
            />
          </div>
        </td>
        <td className="px-6 py-4 text-gray-500 whitespace-nowrap text-sm">
          <div
            className={`read-radio-container flex gap-7 justify-center ${
              rolePermissions.permissionsAccess[permission.index]?.enabled
                ? "flex"
                : "hidden"
            }`}
          >
            <input
              type="radio"
              name={`read-${permission.permissionName}`}
              value="ANY"
              id={`read-${permission.permissionName}-1`}
              disabled={true}
              className="invisible"
            />
            <input
              type="radio"
              name={`read-${permission.permissionName}`}
              value="own"
              id={`read-${permission.permissionName}-2`}
              disabled={true}
              className="invisible"
            />
          </div>
        </td>
        <td className="px-6 py-4 text-gray-500 whitespace-nowrap text-sm">
          <div
            className={`create-radio-container flex gap-7 justify-center ${
              rolePermissions.permissionsAccess[permission.index]?.enabled
                ? "flex"
                : "hidden"
            }`}
          >
            <input
              type="radio"
              name={`create-${permission.permissionName}`}
              value="any"
              id={`create-${permission.permissionName}-1`}
              checked={isChecked(permission.index, "create", "ANY")}
              disabled={
                enabled
                  ? !rolePermissions.permissionsAccess[permission.index]
                      ?.enabled
                  : true
              }
              onChange={() =>
                onPermissionsCudChangeHandler(permission.index, "ANY")
              }
            />
            <input
              type="radio"
              name={`create-${permission.permissionName}`}
              value="own"
              id={`create-${permission.permissionName}-2`}
              disabled={true}
              className="invisible"
            />
            <input
              type="radio"
              name={`create-${permission.permissionName}`}
              value="none"
              id={`create-${permission.permissionName}-3`}
              checked={isChecked(permission.index, "create", "NONE")}
              disabled={
                !rolePermissions.permissionsAccess[permission.index]?.enabled
              }
              onChange={() =>
                onPermissionsCudChangeHandler(permission.index, "NONE")
              }
            />
          </div>
        </td>
      </tr>
    );
  } else {
    return null;
  }
};

export default UpdatePermissionChild;
