import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { Controller, useForm } from "react-hook-form";
import Overlay from "shared/components/UI/Overlay";
import { useNavigate } from "react-router";
import Spinner from "shared/components/UI/Spinner";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { CMS_BE_URL } from "api/urls";
import axios from "axios";
import EmailEditor from "react-email-editor";
import ReactHtmlParser from "react-html-parser";
import RecipientsTab from "shared/components/Newsletter/RecipientsTab";
import GoBack from "shared/components/UI/GoBack";
import sample from "shared/assets/sample/sample.json";
import { getInputStyles, getReactSelectStyles } from "utils/utils";
import DisplayInputError from "shared/components/UI/DisplayInputError";
import { useAppDispatch } from "store/storeHooks";
import { getTagsThunk, selectTags } from "store/tagsStore/tagsReducer";
import Message from "shared/components/UI/Message";
import { selectTranslationLanguage } from "store/translationStore/translationReducer";
import { filter } from "ramda";
import Creatable from "react-select/creatable";
import { getNewsletterUsersThunk } from "store/newsletterStore/newsletterReducer";
import {
  getExportNewsletterUsersColumns,
  SelectOption,
  setCustomMailRecipients,
} from "api/Newsletter/newsletter";
import { Column } from "react-table";
import { NewsletterExportUser } from "shared/interfaces/User.interface";
import { format } from "date-fns";
import * as XLSX from "xlsx";

const CreateNewsletter: () => JSX.Element = () => {
  const lang = selectTranslationLanguage();
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [mailList, setMailList] = useState([]);
  const navigate = useNavigate();

  //recipient tabs values
  const [entitiesReg, setEntitiesReg] = useState<SelectOption[]>([]);
  const [selectReg, setSelectReg] = useState<SelectOption[]>([]);
  const [customMail, setCustomMail] = useState<SelectOption[]>([]);
  const [excludeMail, setExcludeMail] = useState<SelectOption[]>([]);
  const [bouncedNr, setBouncedNr] = useState<number | null>(null);

  const [richtext, setRichtext] = useState("");
  const [json, setJson] = useState<any>("");

  //EMAIL EDITOR
  const emailEditorRef: any = useRef(null);

  const exportHtml = () => {
    emailEditorRef.current.editor.exportHtml((data: any) => {
      const { design, html } = data;
      setRichtext(html);
      setJson(design);
    });
  };

  const onLoad = useCallback(() => {
    const timer = setInterval(() => {
      if (
        emailEditorRef &&
        emailEditorRef.current &&
        emailEditorRef.current.editor
      ) {
        emailEditorRef.current.editor.loadDesign(sample);
        emailEditorRef.current.editor.registerCallback(
          "image",
          async (file: any, done: any) => {
            const formD = new FormData();
            formD.append("fileType", "newsletter_doc");
            formD.append("files", file.attachments[0]);

            const res = await axios.post(
              `${CMS_BE_URL}/files/uploadFile`,
              formD,
              {
                headers: {
                  "Content-Type":
                    "multipart/form-data; boundary=<calculated when request is sent>",
                },
              }
            );
            const url = res.data.urls[0];

            done({ progress: 100, url });
          }
        );

        clearInterval(timer);
      }
    }, 500);
  }, [emailEditorRef]);

  const [tagsOptions, setTagsOptions] = useState<any>([]);
  const dispatch = useAppDispatch();
  const tags = selectTags();
  useEffect(() => {
    dispatch(getTagsThunk()).then((res) => {
      if (res.meta.requestStatus === "rejected") {
        toast.error(
          Message({
            action: "read",
            entity: t("news.tags"),
            error: t("genericError"),
            lang,
          })
        );
      }
    });
  }, []);
  useEffect(() => {
    const tagsMap = tags.data.tags.map((tag) => {
      return {
        value: tag.pk_tag,
        label: tag.pk_tag,
      };
    });
    setTagsOptions(tagsMap);
  }, [tags]);

  // MAILING LIST OBJECT TO PASS TO RECIPIENTS TAB COMPONENT
  const [object, setObject] = useState<any>({
    entityOptions: {
      teachers: false,
      users: false,
      patreons: false,
      all: false,
      resignedTeachers: false,
      nonActiveTeachers: false,
      recipients: [],
      excludes: [],
    },
    filteringOptions: {
      region: [],
    },
  });

  const { t } = useTranslation("common");

  const {
    register,
    control,
    formState: { errors },
    getValues,
    clearErrors,
    trigger,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      title: "",
      subject: "",
      fileType: "newsletter_doc",
      emailContent: null,
      tags: [],
    },
  });

  const increaseStep = async () => {
    switch (step) {
      case 1: {
        exportHtml();
        const canContinue = await trigger(["title", "subject"]);
        if (!canContinue) {
          return;
        }
        break;
      }
      default: {
        break;
      }
    }
    clearErrors();
    setStep((step) => step + 1);
  };

  const decreaseStep = () => {
    setStep(step - 1);
  };

  const steps = [
    {
      name: t("newsletter.cre"),
      href: "#",
      status: step == 1 ? "current" : "complete",
    }, //complete
    {
      name: t("newsletter.preview"),
      href: "#",
      status: step > 1 ? "current" : "upcoming",
    },
    {
      name: t("newsletter.addR"),
      href: "#",
      status: step > 2 ? "current" : "upcoming",
    },
    {
      name: t("newsletter.send"),
      href: "#",
      status: step > 3 ? "current" : "upcoming",
    },
  ];

  const newsletterSent = (mode = "send") => (
    <div className="flex flex-col gap-1">
      <p className="text-xs font-semibold">{t("newsletter.success")}!</p>
      <p className="text-xs">
        {mode === "save"
          ? t("newsletter.successSaved")
          : t("newsletter.successM")}
      </p>
    </div>
  );

  const onSubmitFn = async (status: string) => {
    setLoading(true);
    if (mailList.length === 0 && status === "sent")
      toast.error(t("newsletter.emptyMailist"));
    const tags = getValues("tags");
    const data = {
      emailContent: richtext,
      styles_json: JSON.stringify(json),
      fileType: "newsletter_doc",
      subject: getValues("subject"),
      title: getValues("title"),
      tags: tags.length
        ? JSON.stringify(tags.map((tag: any) => tag.value))
        : [],
      recipients: mailList,
      customEmails: setCustomMailRecipients(customMail),
      status,
      recipients_filters: JSON.stringify({ ...object, customMail }),
    };
    const formData = new FormData();
    Object.entries(data).map(([key, value]: [string, any]) => {
      if (Array.isArray(value)) {
        value = JSON.stringify(value);
      }
      formData.append(key, value);
    });

    try {
      await axios.post(`${CMS_BE_URL}/newsletter/send`, formData);
      if (status === "draft") toast.success(newsletterSent("save"));
      else toast.success(newsletterSent);
      setLoading(false);
      navigate("/newsletter");
    } catch (err: any) {
      setLoading(false);
      if (err.response?.data?.errors?.[0]?.message) {
        toast.error(err.response?.data?.errors?.[0]?.message);
      } else {
        toast.error(err.response?.data?.message);
      }
    }
  };

  const columns: Array<Column<Record<string, any>>> = useMemo(
    () => getExportNewsletterUsersColumns(lang),
    [lang]
  );

  const onDownloadUsers = () => {
    setLoading(true);
    dispatch(getNewsletterUsersThunk(object))
      .then((res) => {
        const users = res.payload.users;
        const header = [
          ...columns.map((column) => String(column.Header).toUpperCase()),
          "BOUNCED",
        ];
        const csvData: any = users.map((user: NewsletterExportUser) => [
          user.name,
          user.surname,
          user.email,
          user.region,
          user.phone,
          user.bounced,
        ]);
        const data: any = {
          data: [header, ...csvData],
        };
        const worksheet = XLSX.utils.aoa_to_sheet(data.data);
        const new_workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(new_workbook, worksheet, "SheetJS");
        XLSX.writeFile(
          new_workbook,
          `Newsletter-Users-${format(Date.now(), "dd/MM/yyyy")}.xlsx`
        );
      })
      .catch((err: any) => {
        if (err.response?.data?.errors?.[0]?.message) {
          toast.error(err.response?.data?.errors?.[0]?.message);
        } else {
          toast.error(err.response?.data?.message);
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <Overlay active={loading} spinner={<Spinner />}>
      <form className="flex flex-col gap-5 p-10 h-full">
        <GoBack uri="newsletter" />
        <h1 className="text-3xl font-bold">Newsletter</h1>
        <p className="text-global-subHeader text-xl">{t("newsletter.np")}</p>
        <div className="flex gap-10 items-center justify-between w-full">
          <nav className="flex flex-grow" aria-label="Progress">
            <ol role="list" className="flex flex-grow gap-5">
              {steps.map((step) => (
                <li key={step.name} className="block flex-grow">
                  {step.status === "complete" ? (
                    <span className="group flex flex-col pt-2 border-t-4 border-sidebar-bg">
                      <span className="text-xl font-medium">{step.name}</span>
                    </span>
                  ) : step.status === "current" ? (
                    <span
                      className="flex flex-col pt-2 border-t-4 border-sidebar-bg"
                      aria-current="step"
                    >
                      <span className="text-xl font-medium">{step.name}</span>
                    </span>
                  ) : (
                    <span className="group flex flex-col pt-2 border-t-4 border-gray-200 hover:border-gray-300">
                      <span className="text-xl font-medium">{step.name}</span>
                    </span>
                  )}
                </li>
              ))}
            </ol>
          </nav>
          <div className="flex gap-5">
            {step > 1 && (
              <button
                type="button"
                onClick={decreaseStep}
                className="px-4 py-2 text-users-create bg-white border border-users-create"
              >
                {t("UserCRUD.back")}
              </button>
            )}
            {step < 4 && (
              <button
                type="button"
                onClick={increaseStep}
                className="px-4 py-2 text-white bg-users-create border border-users-create"
              >
                {t("UserCRUD.next")}
              </button>
            )}
          </div>
        </div>

        {/* MAIN FORM */}

        {/* PROFILE DETAILS STEP 1 */}

        <div className={step == 1 ? `flex flex-col gap-2` : "hidden"}>
          <div className="flex gap-5 w-full">
            <div className="flex flex-col gap-2 w-full">
              <label htmlFor="title" className="block text-lg font-medium">
                {t("news.title")} *
              </label>
              <input
                type="text"
                placeholder={"Title"}
                className={getInputStyles(!!errors.title)}
                {...register("title", {
                  required: {
                    value: true,
                    message: "Title is required.",
                  },
                })}
              />
              <DisplayInputError message={errors.title?.message} />
            </div>
            <div className="flex flex-col gap-2 w-full">
              <label htmlFor="subject" className="block text-lg font-medium">
                {t("newsletter.subject")} *
              </label>
              <input
                type="text"
                placeholder={"Subject"}
                className={getInputStyles(!!errors.subject?.message)}
                {...register("subject", { required: "Subject is required." })}
              />
              <DisplayInputError message={errors.subject?.message} />
            </div>
          </div>
          <div className="flex flex-col gap-2">
            {/* <label className="text-lg font-medium">Tags</label>
            <Tags
              className="bg-white"
              settings={{ maxTags: 4 }}
              placeholder={t("UserCRUD.tagPlaceholder")}
              tagifyRef={tagifyRef}
              onChange={onChange}
            /> */}
            <label htmlFor="tags" className="block text-lg font-medium">
              {t("new.tags")}
            </label>
            <Controller
              name="tags"
              control={control}
              rules={{
                // required: `${t("new.tags")} ${t("new.areRequired")}.`,
                validate: {
                  maxLength(e) {
                    if (filter((tag: any) => tag.value.length > 50, e).length)
                      return `${t("new.tags")} ${t("new.shouldBe")} ${50} ${t(
                        "new.characters"
                      )}.`;
                    return true;
                  },
                },
              }}
              render={({ field }) => (
                <Creatable
                  placeholder={t("new.tags")}
                  styles={getReactSelectStyles(!!errors.tags)}
                  isLoading={tags.loading}
                  inputId="tags"
                  isMulti={true}
                  value={field.value}
                  options={tagsOptions}
                  onChange={(e) => field.onChange(e)}
                />
              )}
            />
            <DisplayInputError message={(errors.tags as any)?.message} />
          </div>
          <div className="flex flex-col gap-2 pb-10">
            <label htmlFor="title" className="block text-lg font-medium">
              Rich text *
            </label>
            <div className={errors.emailContent ? "border border-red-500" : ""}>
              <EmailEditor
                minHeight={1000}
                ref={emailEditorRef}
                onLoad={onLoad}
              />
            </div>
          </div>
        </div>

        {step === 2 && (
          <div className="p-5 bg-white border border-gray-300">
            {ReactHtmlParser(richtext)}
          </div>
        )}
        {/*PERMISSIONS*/}
        {step === 3 && (
          <RecipientsTab
            loading={loading}
            setLoading={setLoading}
            entitiesReg={entitiesReg}
            selectReg={selectReg}
            setEntitiesReg={setEntitiesReg}
            setSelectReg={setSelectReg}
            object={object}
            setObject={setObject}
            customMail={customMail}
            excludeMail={excludeMail}
            setCustomMail={setCustomMail}
            setExcludeMail={setExcludeMail}
            setMailList={setMailList}
            setBouncedNr={setBouncedNr}
          />
        )}
        {step === 4 && (
          <div className="flex flex-col gap-2">
            <p>
              {t("newsletter.endp1")}
              <strong>{mailList.length}</strong>.
            </p>
            <p>
              {t("newsletter.bouncedBlock1")} <strong>{bouncedNr}</strong>{" "}
              {t("newsletter.bouncedBlock2")}
            </p>
            <p>
              {t("newsletter.sentBlock")}
              <strong>
                {bouncedNr ? mailList.length - bouncedNr : mailList.length}
              </strong>{" "}
              {t("newsletter.endp2")}
            </p>
            {mailList.length ? (
              <div className="line-clamp-1">
                {mailList.map((l, i) => (
                  <span key={i}>
                    <b>{l}</b>
                    {i + 1 < mailList.length && ", "}
                  </span>
                ))}
              </div>
            ) : null}
            {mailList?.length > 0 ? (
              <div>
                <button
                  className="px-4 py-2 text-users-create bg-white border border-users-create"
                  type="button"
                  onClick={() => onDownloadUsers()}
                >
                  {t("massUpdate.exportUsers")}
                </button>
              </div>
            ) : null}
            <p>Subject: {getValues("subject")}</p>
            <div className="flex flex-col gap-5 my-5">
              <button
                className="px-4 py-2 w-1/6 text-white bg-users-create border border-users-create"
                type="button"
                onClick={() => onSubmitFn("draft")}
                disabled={loading}
              >
                {t("newsletter.saveDraft")}
              </button>
              <button
                className="px-4 py-2 w-1/6 text-white bg-users-create border border-users-create"
                type="button"
                onClick={() => onSubmitFn("sent")}
                disabled={loading}
              >
                {t("newsletter.sendN")}
              </button>
            </div>
          </div>
        )}
      </form>
    </Overlay>
  );
};

export default CreateNewsletter;
