import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { locationInitialState } from "./locationState";
import { useAppSelector } from "store/storeHooks";
import { placeAutocomplete, placeDetails } from "api/Location/location.api";

export const placeAutocompleteThunk = createAsyncThunk(
  "location/placeAutocomplete",
  async ({ input, types }: { input: string; types?: string }) => {
    const res = await placeAutocomplete(input, types).then((r) => r.data);
    return res;
  }
);

export const placeDetailsThunk = createAsyncThunk(
  "location/placeDetails",
  async ({ placeid }: { placeid: string }) => {
    const res = await placeDetails(placeid).then((r) => r.data);
    return res;
  }
);

const locationSlice = createSlice({
  name: "location",
  initialState: locationInitialState,
  reducers: {
    clearPlaceAutocomplete(state) {
      state.data.placeAutocomplete = null;
    },
    clearPlaceDetails(state) {
      state.data.placeDetails = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(placeAutocompleteThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(placeAutocompleteThunk.fulfilled, (state, action) => {
      state.data.placeAutocomplete = action.payload;
      state.loading = false;
    });
    builder.addCase(placeAutocompleteThunk.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(placeDetailsThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(placeDetailsThunk.fulfilled, (state, action) => {
      state.data.placeDetails = action.payload;
      state.loading = false;
    });
    builder.addCase(placeDetailsThunk.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const selectLocation = () => useAppSelector((state) => state.lookups);
export const selectPlaceAutocomplete = () =>
  useAppSelector((state) => state.location.data.placeAutocomplete);
export const selectPlaceDetails = () =>
  useAppSelector((state) => state.location.data.placeDetails);

export default locationSlice;
