import React, { useState, useEffect, useRef, useCallback } from "react";
import { useForm, Controller } from "react-hook-form";
import Overlay from "shared/components/UI/Overlay";
import { useAppDispatch } from "../../store/storeHooks";
import Spinner from "shared/components/UI/Spinner";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useParams, useNavigate } from "react-router";
import { CMS_BE_URL } from "api/urls";
import { DownloadIcon } from "@heroicons/react/solid";
import { toast } from "react-toastify";
import { getTagsThunk, selectTags } from "store/tagsStore/tagsReducer";
import Creatable from "react-select/creatable";
import EmailEditor from "react-email-editor";
import GoBack from "shared/components/UI/GoBack";
import {
  getInputStyles,
  getReactSelectStyles,
  stringToFile,
} from "utils/utils";
import { PermissionDomain } from "shared/interfaces/Permission.interface";
import { selectLoggedInUser } from "store/authStore/authReducer";
import { RolePermissionCrudAction } from "shared/interfaces/RolePermission.interface";
import DisplayInputError from "shared/components/UI/DisplayInputError";
import { selectTranslationLanguage } from "store/translationStore/translationReducer";
import DocumentDropzone from "shared/components/UI/DocumentDropzone";
import DisplayDocument from "shared/components/UI/DisplayDocument";

const EditDocument = () => {
  const lang = selectTranslationLanguage();
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation("common");
  const [richtext, setRichtext] = useState("");
  const [json, setJson] = useState<any>("");
  const [fileName, setFileName] = useState("");
  const { id } = useParams();
  const [fileURL, setFileURL] = useState("");

  const loggedInUser = selectLoggedInUser();

  function getDocument() {
    return axios.get(`${CMS_BE_URL}/documents/${id}`).then((res) => {
      const data = res.data.data;
      setValue("title", data.title);
      setValue("is_visible", data.is_visible ? 1 : 0);
      setValue("description", data.description);
      setValue("richtext", data.richtext);
      setRichtext(data.richtext);
      setValue("styles_json", data.styles_json);
      emailEditorRef.current.editor.loadDesign(
        JSON.parse(getValues("styles_json"))
      );
      setJson(JSON.parse(data.styles_json));
      const tempTags = data.tags.map((tag: any) => ({
        value: tag.pk_tag,
        label: tag.pk_tag,
      }));
      if (tempTags && tempTags.length) {
        setValue("tags", tempTags);
      }
      setFileURL(data.file.url);
      setFileName(data.file.name);
      return res;
    });
  }

  useEffect(() => {
    setLoading(true);
    getDocument().then((res) => {
      setLoading(false);
      return res;
    });
  }, []);

  //EMAIL EDITOR
  const emailEditorRef: any = useRef(null);

  const onLoad = useCallback(() => {
    const timer = setInterval(() => {
      if (
        emailEditorRef &&
        emailEditorRef.current &&
        emailEditorRef.current.editor
      ) {
        emailEditorRef.current.editor.loadDesign(json);
        emailEditorRef.current.editor.addEventListener(
          "design:updated",
          function () {
            // Design is updated by the user

            emailEditorRef.current.editor.exportHtml(function (data: any) {
              const json = data.design; // design json
              const html = data.html; // design html
              setRichtext(html);
              setJson(json);
              // Save the json, or html here
            });
          }
        );

        emailEditorRef.current.editor.registerCallback(
          "image",
          async (file: any, done: any) => {
            const formD = new FormData();
            formD.append("fileType", "newsletter_doc");
            formD.append("files", file.attachments[0]);

            const res = await axios.post(
              `${CMS_BE_URL}/files/uploadFile`,
              formD,
              {
                headers: {
                  "Content-Type":
                    "multipart/form-data; boundary=<calculated when request is sent>",
                },
              }
            );
            const url = res.data.urls[0];

            done({ progress: 100, url });
          }
        );

        clearInterval(timer);
      }
    }, 500);
  }, [emailEditorRef]);

  const {
    register,
    handleSubmit,
    getValues,
    control,
    formState: { errors },
    setError,
    setValue,
    watch,
    trigger,
  } = useForm<{
    title: string;
    description: string;
    documentFile: any;
    tags: any;
    styles_json: string;
    richtext: any;
    fileType: string;
    is_visible: number;
  }>({
    defaultValues: {
      fileType: "document_doc",
      styles_json: "",
      title: "",
      description: "",
      richtext: null,
      documentFile: null,
      tags: null,
      is_visible: 1,
    },
  });

  function canAccess(
    domain: PermissionDomain,
    action: RolePermissionCrudAction
  ): boolean {
    if (loggedInUser.permissions["*"]) {
      return true;
    } else {
      switch (loggedInUser.permissions[domain][action]) {
        case "NONE": {
          return false;
        }
        case "OWN": {
          if (action === "CREATE") return true;
          return false;
        }
        case "ANY": {
          return true;
        }
        default: {
          return false;
        }
      }
    }
  }

  //GET TAGS
  const [tagsOptions, setTagsOptions] = useState<any>([]);
  const tags = selectTags();
  useEffect(() => {
    dispatch(getTagsThunk());
  }, []);
  useEffect(() => {
    const tagsMap = tags.data.tags.map((tag) => {
      return {
        value: tag.pk_tag,
        label: tag.pk_tag,
      };
    });
    setTagsOptions(tagsMap);
  }, [tags]);

  //

  //TOASTIFY
  const DocumentEditedMsg = () => (
    <div className="flex flex-col gap-1">
      <p className="text-xs font-semibold">{t("Documents.msgHeader")}</p>
      <p className="text-xs">{t("Documents.documentEdited")}</p>
    </div>
  );

  const displayDocumentEditedMsg = () => {
    toast.success(DocumentEditedMsg);
  };

  const DocumentErrorMsg = () => (
    <div className="flex flex-col gap-1">
      <p className="text-xs font-semibold">{t("Documents.msgHeaderError")}</p>
      <p className="text-xs">{t("Documents.documentCreatedError")}</p>
    </div>
  );

  const displayDocumentErrorMsg = () => {
    toast.error(DocumentErrorMsg);
  };

  const onSubmit = async (data: any) => {
    setLoading(true);
    if (data.documentFile === null) {
      delete data.documentFile;
    }
    if (data.tags.length) {
      data.tags = JSON.stringify(data.tags.map((tag: any) => tag.value));
    }

    data.richtext = stringToFile(richtext);
    data.styles_json = JSON.stringify(json);
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]: any) => {
      if (Array.isArray(value)) {
        value = JSON.stringify(value);
      }
      formData.append(key, value);
    });

    axios
      .put(`${CMS_BE_URL}/documents/${id}`, formData)
      .then((res) => {
        displayDocumentEditedMsg();
        return res;
      })
      .then(() => setValue("documentFile", null))
      .then(getDocument)
      .then(() => {
        setLoading(false);
      })
      .catch((err: Error) => {
        displayDocumentErrorMsg();
        return err;
      });
  };

  return (
    <Overlay active={loading} spinner={<Spinner />}>
      <form
        className="flex flex-col flex-grow gap-5 p-10 h-full bg-gray-100"
        onSubmit={handleSubmit(onSubmit)}
      >
        <GoBack uri="documents" />
        <div className="flex gap-5 items-center justify-between w-full">
          <div className="flex flex-col gap-5">
            <h1 className="text-3xl font-bold">{t("UserCRUD.documents")}</h1>
            <p className="text-global-subHeader text-xl">
              {t("Documents.documentsE")}
            </p>
          </div>
          <div className="flex gap-5">
            <div className="flex gap-5 items-center">
              <span className="text-lg font-medium">
                {" "}
                {t("document.status")}:{" "}
              </span>
              <select {...register("is_visible")}>
                <option value={1}>Published</option>
                <option value={0}>Draft</option>
              </select>
            </div>
            {canAccess("documents", "UPDATE") && (
              <input
                className="px-4 py-2 text-white bg-users-create cursor-pointer"
                type="submit"
                value={"Save"}
              />
            )}
          </div>
        </div>

        {/* MAIN FORM */}
        <div className="flex flex-col gap-2 w-full">
          <div className="flex flex-col gap-2 w-full">
            <label htmlFor="title" className="block text-lg font-medium">
              {t("UserCRUD.title")} *
            </label>
            <input
              type="text"
              className={getInputStyles(!!errors.title)}
              defaultValue=""
              {...register("title", { required: "Title is required." })}
            />
            <DisplayInputError message={errors.title?.message} />
          </div>
          <div className="flex flex-col gap-2 w-full">
            <label
              htmlFor="shortDescription"
              className="block text-lg font-medium"
            >
              {t("UserCRUD.shortDescription")} *
            </label>
            <input
              type="text"
              className={getInputStyles(!!errors.description)}
              defaultValue=""
              {...register("description", {
                required: "Short description is required.",
                maxLength: 1000,
              })}
            />
            <DisplayInputError message={errors.description?.message} />
          </div>

          <label htmlFor="tags" className="block text-lg font-medium">
            Tags *
          </label>
          <Controller
            name="tags"
            control={control}
            rules={{
              required: "Tags are required.",
            }}
            render={({ field }) => (
              <Creatable
                placeholder={
                  lang === "en"
                    ? "Select or create tags"
                    : "Seleziona o crea tag"
                }
                styles={getReactSelectStyles(!!errors.tags)}
                isLoading={tags.loading}
                inputId="tags"
                isMulti={true}
                options={tagsOptions}
                value={field.value}
                onChange={(e) => field.onChange(e)}
              />
            )}
          />

          <div className="flex gap-2">
            <span>
              <span className="text-lg font-medium">File:</span> {fileName}
            </span>
            <a target="_blank" rel="noreferrer" href={fileURL} download>
              <DownloadIcon className="w-6 h-6 text-users-color bg-gray-100" />
            </a>
          </div>
          <DocumentDropzone
            trigger={trigger}
            setValue={setValue}
            errors={errors}
            reactHookFormName={"documentFile"}
            setError={setError}
            register={register}
            required={false}
          />
          <DisplayInputError message={errors.documentFile?.message} />
          <DisplayDocument
            setValue={setValue}
            watch={watch}
            reactHookFormName={"documentFile"}
          />

          {/* <label className="flex flex-col gap-2" htmlFor="file">
            <p className="text-lg font-medium">{t("Documents.upload")}</p>
            <input
              {...register("documentFile")}
              type="file"
              accept="application/pdf,application/msword, application/docx"
              onChange={handleFileChange}
            />
          </label> */}
          <div className="pb-10">
            <label
              htmlFor="richText"
              className="flex flex-col gap-2 text-lg font-medium"
            >
              {t("UserCRUD.richText")}
              <EmailEditor
                minHeight={1000}
                ref={emailEditorRef}
                onLoad={onLoad}
              />
            </label>
          </div>
        </div>
      </form>
    </Overlay>
  );
};

export default EditDocument;
