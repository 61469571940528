import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from "react";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { useAppDispatch } from "store/storeHooks";
import { getTagsThunk, selectTags } from "store/tagsStore/tagsReducer";
import {
  getEventThunk,
  selectEvents,
  updateEventThunk,
} from "store/eventsStore/eventsReducer";
import { useParams } from "react-router";
import { CreateEvent, Event } from "shared/interfaces/Event.interface";
import {
  addStyles,
  getInputStyles,
  getReactSelectStyles,
  stringToFile,
  toISOString,
} from "utils/utils";
import { visibilityOptions } from "shared/interfaces/Visibility.interface";
import { eventsActions } from "store/globalStore";
import DisplayFiles from "shared/components/UI/DisplayFiles";
import MultipleImageDropzone from "shared/components/UI/MultipleImageDropzone";
import { XIcon } from "@heroicons/react/outline";
import LocationSearch from "shared/components/UI/LocationSearch";
import { EventFormType } from "./types";
import CustomButton from "shared/components/UI/CustomButton";
import { FileAWS } from "shared/interfaces/File.interface";
import GoBack from "shared/components/UI/GoBack";
import Overlay from "shared/components/UI/Overlay";
import Spinner from "shared/components/UI/Spinner";
import DisplayInputError from "shared/components/UI/DisplayInputError";
import Message from "shared/components/UI/Message";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "shared/styles/datePicker.css";
import EditImage from "shared/components/UI/EditImage";
import Creatable from "react-select/creatable";
import { CreateButton } from "shared/interfaces/Button.interface";
import EmailEditor from "react-email-editor";
import axios from "axios";
import { CMS_BE_URL } from "api/urls";
import SimpleImageDropzone from "shared/components/UI/SimpleImageDropzone";
import { selectTranslationLanguage } from "store/translationStore/translationReducer";
import { PermissionDomain } from "shared/interfaces/Permission.interface";
import { RolePermissionCrudAction } from "shared/interfaces/RolePermission.interface";
import { selectLoggedInUser } from "store/authStore/authReducer";
import { filter } from "ramda";
import CropImage from "shared/components/UI/CropImage";

export default function EditEventPage() {
  const lang = selectTranslationLanguage();
  const { t } = useTranslation("common");
  const tabs: { name: string }[] = useMemo(
    () => [{ name: t("event.details") }, { name: t("event.other") }],
    [lang, t]
  );
  const { id } = useParams();
  const [tagsOptions, setTagsOptions] = useState<any>([]);
  const [filesToDelete, setFilesToDelete] = useState<FileAWS[]>([]);
  const [editImageOn, setEditImageOn] = useState(false);
  const [richtext, setRichtext] = useState("");
  const [json, setJson] = useState<any>("");
  const [selectedTab, setSelectedTab] = useState(0);
  const form = useForm<EventFormType>({
    defaultValues: {
      title: "",
      richtext: null,
      styles_json: "",
      start_date: undefined,
      end_date: undefined,
      tags: [],
      location: {
        country: undefined,
        city: undefined,
        province: undefined,
        region: undefined,
        postal_code: undefined,
        street_name: undefined,
        street_number: undefined,
        longitude: undefined,
        latitude: undefined,
      },
      info: "",
      schedule: "",
      faq: "",
      media: null,
      thumbnail: null,
      fileType: "event_doc",
      sponsors: null,
      is_visible: visibilityOptions[0],
      buttons: [],
    },
  });
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    control,
    watch,
    clearErrors,
    trigger,
    getValues,
    setError,
  } = form;
  function constructUpdateEvent(data: EventFormType) {
    const event: CreateEvent = {};
    event.fileType = data.fileType;
    if (data.title !== "") event.title = data.title;
    else event.title = "";
    if (data.description !== "") event.description = data.description;
    else event.description = "";
    if (data.start_date) {
      event.start_date = toISOString(data.start_date);
    } else event.start_date = "";
    if (data.end_date) event.end_date = toISOString(data.end_date);
    else event.end_date = "";
    if (data.tags.length) {
      event.tags = JSON.stringify(data.tags.map((tag) => tag.value));
    }
    event.richtext = stringToFile(richtext);
    event.styles_json = JSON.stringify(json);
    const tempLocationObject: any = {
      street_name: data.location.street_name,
      street_number: data.location.street_number,
      city: data.location.city,
      province: data.location.province,
      region: data.location.region,
      postal_code: data.location.postal_code,
      country: data.location.country,
    };
    if (data.location.longitude)
      tempLocationObject["longitude"] = data.location.longitude;
    if (data.location.latitude)
      tempLocationObject["latitude"] = data.location.latitude;
    event.location = JSON.stringify(tempLocationObject);
    if (data.info !== "") event.info = data.info;
    else event.info = "";
    if (data.schedule !== "") event.schedule = data.schedule;
    else event.schedule = "";
    if (data.faq !== "") event.faq = data.faq;
    else event.faq = "";
    if (data.media) event.media = data.media as File[];
    if (data.thumbnail && typeof data.thumbnail === "object")
      event.thumbnail = data.thumbnail as File;
    if (data.sponsors) {
      event.sponsors = data.sponsors as File[];
    }
    if (data.is_visible.value) {
      event.is_visible = data.is_visible.value;
    }
    if (data.buttons.length) {
      const tempButtons: CreateButton[] = [];
      data.buttons.forEach((button, i) => {
        tempButtons[i] = {} as CreateButton;
        if (button.id) tempButtons[i].id = button.id;
        if (button.title) tempButtons[i].title = button.title;
        else tempButtons[i].title = "";
        if (button.link) tempButtons[i].link = button.link;
        else tempButtons[i].link = "";
        if (button.is_visible) tempButtons[i].is_visible = button.is_visible;
        else tempButtons[i].is_visible = "0";
      });
      event.buttons = JSON.stringify(
        tempButtons.map((button) => ({ ...button }))
      );
    }
    return event;
  }

  const onSubmit: SubmitHandler<EventFormType> = function (data) {
    dispatch(
      updateEventThunk({
        eventId: String(id),
        event: constructUpdateEvent(data),
        filesToDelete: filesToDelete,
      })
    ).then((res: any) => {
      if (res.meta.requestStatus === "rejected") {
        toast.error(
          Message({
            action: "update",
            entity: t("event.event"),
            error: res.error.message,
            lang,
            gender: "male",
          })
        );
      } else {
        setValue("media", null);
        setValue("sponsors", null);
        getEvent();
        toast.success(
          Message({
            action: "update",
            entity: t("event.event"),
            lang,
            gender: "male",
          })
        );
      }
      return res;
    });
  };

  const dispatch = useAppDispatch();
  const tags = selectTags();
  useEffect(() => {
    dispatch(getTagsThunk()).then((res) => {
      if (res.meta.requestStatus === "rejected") {
        toast.error(
          Message({
            action: "read",
            entity: t("new.tags"),
            error: t("genericError"),
            lang,
            gender: "female",
          })
        );
      }
      return res;
    });
  }, []);
  useEffect(() => {
    const tagsMap = tags.data.tags.map((tag) => {
      return {
        value: tag.pk_tag,
        label: tag.pk_tag,
      };
    });
    setTagsOptions(tagsMap);
  }, [tags]);

  function getEvent() {
    return dispatch(getEventThunk(id as string)).then((res) => {
      if (res.meta.requestStatus === "rejected") {
        toast.error(
          Message({
            action: "read",
            entity: t("event.event"),
            error: t("genericError"),
            lang,
            gender: "male",
          })
        );
      }
      return res;
    });
  }

  const events = selectEvents();
  useEffect(() => {
    getEvent();
  }, []);

  useEffect(() => {
    if (events.data.event?.title) {
      setValue("title", events.data.event.title);
    }
    if (events.data.event?.description) {
      setValue("description", events.data.event.description);
    }
    if (events.data.event?.start_date) {
      setValue(
        "start_date",
        new Date(events.data.event.start_date.slice(0, -1))
      );
    }
    if (events.data.event) {
      setRichtext(events.data.event.richtext);
      setValue("richtext", events.data.event.richtext);
    }

    if (events.data.event?.end_date) {
      setValue("end_date", new Date(events.data.event.end_date.slice(0, -1)));
    }
    const tempTags = events.data.event?.tags.map((tag) => ({
      value: tag.pk_tag,
      label: tag.pk_tag,
    }));
    if (tempTags && tempTags.length) {
      setValue("tags", tempTags);
    }
    if (events.data.event?.location.country) {
      setValue("location.country", events.data.event.location.country);
    }
    if (events.data.event?.location.region) {
      setValue("location.region", events.data.event.location.region);
    }
    if (events.data.event?.location.province) {
      setValue("location.province", events.data.event?.location.province);
    }
    if (events.data.event?.location.city) {
      setValue("location.city", events.data.event?.location.city);
    }
    if (events.data.event?.location.postal_code) {
      setValue(
        "location.postal_code",
        Number(events.data.event.location.postal_code)
      );
    }
    if (events.data.event?.location.street_name) {
      setValue("location.street_name", events.data.event?.location.street_name);
    }
    if (events.data.event?.location.street_number) {
      setValue(
        "location.street_number",
        Number(events.data.event.location.street_number)
      );
    }
    if (events.data.event?.location.longitude) {
      setValue(
        "location.longitude",
        Number(events.data.event?.location.longitude) as number
      );
    }
    if (events.data.event?.location.latitude) {
      setValue(
        "location.latitude",
        Number(events.data.event.location.latitude)
      );
    }
    if (events.data.event?.info) {
      setValue("info", events.data.event?.info);
    }
    if (events.data.event?.schedule) {
      setValue("schedule", events.data.event?.schedule);
    }
    if (events.data.event?.faq) {
      setValue("faq", events.data.event?.faq);
    }
    if (events.data.event?.is_visible) {
      setValue("is_visible", visibilityOptions[0]);
    } else {
      setValue("is_visible", visibilityOptions[1]);
    }
    if (events.data.event) {
      setValue("styles_json", events.data.event.styles_json);
      emailEditorRef.current.editor.loadDesign(
        JSON.parse(getValues("styles_json"))
      );
      setJson(JSON.parse(events.data.event.styles_json));
    }
    if (events.data.event?.buttons) {
      setValue(
        "buttons",
        events.data.event.buttons.map((button) => {
          return {
            id: button.id,
            title: button.title,
            link: button.link,
            is_visible: button.is_visible ? "1" : "0",
          };
        })
      );
    }
    if (events.data.event?.thumbnail) {
      setValue("thumbnail", events.data.event.thumbnail as any);
    }
  }, [events.data.event]);

  //EMAIL EDITOR
  const emailEditorRef: any = useRef(null);

  const onLoad = useCallback(() => {
    const timer = setInterval(() => {
      if (
        emailEditorRef &&
        emailEditorRef.current &&
        emailEditorRef.current.editor
      ) {
        emailEditorRef.current.editor.loadDesign(json);
        emailEditorRef.current.editor.addEventListener(
          "design:updated",
          function () {
            // Design is updated by the user

            emailEditorRef.current.editor.exportHtml(function (data: any) {
              const json = data.design; // design json
              const html = data.html; // design html
              setRichtext(html);
              setJson(json);
              // Save the json, or html here
            });
          }
        );

        emailEditorRef.current.editor.registerCallback(
          "image",
          async (file: any, done: any) => {
            const formD = new FormData();
            formD.append("fileType", "newsletter_doc");
            formD.append("files", file.attachments[0]);

            const res = await axios.post(
              `${CMS_BE_URL}/files/uploadFile`,
              formD,
              {
                headers: {
                  "Content-Type":
                    "multipart/form-data; boundary=<calculated when request is sent>",
                },
              }
            );
            const url = res.data.urls[0];

            done({ progress: 100, url });
          }
        );

        clearInterval(timer);
      }
    }, 500);
  }, [emailEditorRef]);

  const loggedInUser = selectLoggedInUser();
  function canAccess(
    domain: PermissionDomain,
    action: RolePermissionCrudAction,
    event?: Event | null
  ): boolean {
    if (loggedInUser.permissions["*"]) {
      return true;
    } else {
      switch (loggedInUser.permissions[domain][action]) {
        case "NONE": {
          return false;
        }
        case "OWN": {
          if (event && event.creator_id === loggedInUser.user?.PK_USER)
            return true;
          return false;
        }
        case "ANY": {
          return true;
        }
        default: {
          return false;
        }
      }
    }
  }

  return (
    <Overlay active={events.loading} spinner={<Spinner />}>
      <FormProvider {...form}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col flex-grow gap-5 p-10 h-full bg-gray-100"
        >
          <GoBack uri="events" />
          <div className="flex gap-5 items-center justify-between w-full">
            <div className="flex gap-5 items-center">
              <h1 className="text-3xl font-bold">{t("event.events")}</h1>
              <p className="text-global-subHeader text-xl"></p>
            </div>
            <div className="flex gap-5 items-center">
              <label
                htmlFor="is_visible"
                className="block whitespace-nowrap text-lg font-medium"
              >
                {t("event.visible")} *
              </label>
              <Controller
                name="is_visible"
                control={control}
                render={({ field }) => (
                  <Select
                    styles={getReactSelectStyles(false)}
                    inputId="is_visible"
                    options={visibilityOptions}
                    value={field.value}
                    onChange={(e) => field.onChange(e)}
                    isSearchable={false}
                  />
                )}
              />
              <button
                type="submit"
                disabled={!canAccess("events", "UPDATE", events.data.event)}
                className={`px-4 py-2 text-white bg-global-save border rounded-sm ${
                  canAccess("events", "UPDATE", events.data.event)
                    ? ""
                    : "cursor-not-allowed"
                }`}
              >
                {t("event.save")}
              </button>
            </div>
          </div>
          <nav className="flex gap-5" aria-label="Tabs">
            {tabs.map((tab: any, i) => (
              <button
                type="button"
                onClick={() => setSelectedTab(i)}
                key={i}
                className={`${
                  i == selectedTab
                    ? "border-users-create text-users-create"
                    : "border-transparent text-gray-500 hover:border-gray-300"
                }
                px-1 py-2 whitespace-nowrap text-lg font-medium border-b-2`}
              >
                {tab.name}
              </button>
            ))}
          </nav>
          <div className="flex flex-col flex-grow">
            <div
              className={`flex flex-col gap-2 ${
                selectedTab === 0 ? "block" : "hidden"
              }`}
            >
              <div className="grid flex-grow gap-5 grid-cols-2">
                <div className="flex flex-col flex-grow gap-2">
                  <label htmlFor="title" className="block text-lg font-medium">
                    {t("event.event")} {t("event.title")} *
                  </label>
                  <input
                    id="title"
                    type="text"
                    placeholder="Event Title"
                    {...register("title", {
                      required: {
                        message: `${t("event.event")} ${t("event.title")} ${t(
                          "event.required"
                        )}`,
                        value: true,
                      },
                      maxLength: {
                        value: 50,
                        message: `${t("event.post")} ${t("event.title")} ${t(
                          "event.shouldBe"
                        )} ${50} ${t("event.characters")}.`,
                      },
                    })}
                    className={getInputStyles(!!errors.title)}
                  />
                  <DisplayInputError message={errors.title?.message} />
                  <label
                    htmlFor="start_date"
                    className="block text-lg font-medium"
                  >
                    {t("event.startDate")} *
                  </label>
                  <Controller
                    name="start_date"
                    control={control}
                    rules={{
                      required: `${t("event.startDate")} ${t(
                        "event.required"
                      )}`,
                    }}
                    render={({ field }) => {
                      return (
                        <div>
                          <DatePicker
                            id="start_date"
                            selected={field.value}
                            onChange={(e) => field.onChange(e)}
                            showTimeSelect={true}
                            dateFormat="dd/MM/yyyy h:mm aa"
                            className="w-full"
                            placeholderText={t("event.dateFormat")}
                            showPopperArrow={false}
                          />
                        </div>
                      );
                    }}
                  />
                  <DisplayInputError message={errors.start_date?.message} />
                  <label
                    htmlFor="end_date"
                    className="block text-lg font-medium"
                  >
                    {t("event.endDate")} *
                  </label>
                  <Controller
                    name="end_date"
                    control={control}
                    rules={{
                      required: `${t("event.endDate")} ${t("event.required")}.`,
                      validate: (value) => {
                        const start_date = getValues("start_date");
                        if (start_date && value && start_date > value) {
                          return String(t("event.dateError"));
                        }
                        return true;
                      },
                    }}
                    render={({ field }) => {
                      return (
                        <div>
                          <DatePicker
                            id="end_date"
                            selected={field.value}
                            onChange={(e) => field.onChange(e)}
                            showTimeSelect={true}
                            dateFormat="dd/MM/yyyy h:mm aa"
                            className="w-full"
                            placeholderText={t("event.dateFormat")}
                            showPopperArrow={false}
                          />
                        </div>
                      );
                    }}
                  />
                  <DisplayInputError message={errors.end_date?.message} />
                  <label htmlFor="tags" className="block text-lg font-medium">
                    {t("event.tags")} *
                  </label>
                  <Controller
                    name="tags"
                    control={control}
                    rules={{
                      required: `${t("event.tags")} ${t("event.required")}.`,
                      validate: {
                        maxLength(e) {
                          if (filter((tag) => tag.value.length > 50, e).length)
                            return `${t("new.tags")} ${t(
                              "new.shouldBe"
                            )} ${50} ${t("new.characters")}.`;
                          return true;
                        },
                      },
                    }}
                    render={({ field }) => (
                      <Creatable
                        placeholder={t("event.tags")}
                        styles={getReactSelectStyles(!!errors.tags)}
                        isLoading={tags.loading}
                        inputId="tags"
                        isMulti={true}
                        options={tagsOptions}
                        value={field.value}
                        onChange={(e) => field.onChange(e)}
                      />
                    )}
                  />
                  <DisplayInputError message={(errors.tags as any)?.message} />
                </div>
                <div className="flex flex-col gap-2">
                  <div className="flex flex-col gap-2">
                    <label htmlFor="info" className="block text-lg font-medium">
                      {t("event.otherInfo")}
                    </label>
                    <textarea
                      id="info"
                      placeholder={t("event.otherInfo")}
                      className={getInputStyles(false)}
                      {...register("info", {
                        maxLength: {
                          value: 1300,
                          message: `${t("event.otherInfo")} ${t(
                            "event.shouldBe"
                          )} ${1300} ${t("event.characters")}.`,
                        },
                      })}
                    ></textarea>
                    <DisplayInputError message={errors.info?.message} />
                  </div>
                  <div className="flex flex-col gap-2">
                    <div className="block text-lg font-medium">
                      {t("event.coverPhoto")} *
                    </div>
                    <div className="flex flex-col gap-2">
                      <Controller
                        name="thumbnail"
                        control={control}
                        rules={{
                          required: `${t("event.coverPhoto")} ${t(
                            "event.required"
                          )}`,
                          validate: {
                            checkSize: (file) => {
                              if (file && file.size > 2 * 1048576)
                                return `${t("singleDropzone.size")}: 2MB`;
                              return true;
                            },
                          },
                        }}
                        render={() => (
                          <SimpleImageDropzone
                            clearErrors={clearErrors}
                            setError={setError}
                            trigger={trigger}
                            setValue={setValue}
                            watch={watch}
                            reactHookFormName="thumbnail"
                            setEditImageOn={setEditImageOn}
                            aspect={16 / 9}
                          />
                        )}
                      />
                      {editImageOn ? (
                        <CropImage
                          editImageOn={editImageOn}
                          setEditImageOn={setEditImageOn}
                          reactHookFormName="thumbnail"
                          aspect={16 / 9}
                          canForce={true}
                          canClose={true}
                        />
                      ) : null}
                      <DisplayInputError message={errors.thumbnail?.message} />
                    </div>
                  </div>
                </div>
              </div>
              <LocationSearch
                getValues={getValues}
                setValue={setValue}
                errors={errors}
                watch={watch}
                clearErrors={clearErrors}
                register={register}
                reactHookFormName="location"
                required={false}
                displayStar={true}
              />
              <div className="flex flex-col gap-2">
                <label className="block text-lg font-medium">
                  {t("event.mainBody")} *
                </label>
                <EmailEditor
                  minHeight={1000}
                  ref={emailEditorRef}
                  onLoad={onLoad}
                />
              </div>
            </div>
            <div
              className={`flex flex-col gap-2 ${
                selectedTab === 1 ? "block" : "hidden"
              }`}
            >
              <div className="grid flex-grow gap-5 grid-cols-2">
                <div className="flex flex-col gap-2">
                  <label htmlFor="title" className="block text-lg font-medium">
                    {t("event.schedule")}
                  </label>
                  <textarea
                    id="schedule"
                    placeholder={t("event.schedule")}
                    className={addStyles(getInputStyles(false), "flex-grow")}
                    {...register("schedule", {
                      maxLength: {
                        value: 1300,
                        message: `${t("event.schedule")} ${t(
                          "event.shouldBe"
                        )} ${1300} ${t("event.characters")}.`,
                      },
                    })}
                  ></textarea>
                  <DisplayInputError message={errors.schedule?.message} />
                  <label htmlFor="faq" className="block text-lg font-medium">
                    {t("event.faq")}
                  </label>
                  <textarea
                    id="faq"
                    placeholder={t("event.faq")}
                    className={addStyles(getInputStyles(false), "flex-grow")}
                    {...register("faq", {
                      maxLength: {
                        value: 1300,
                        message: `${t("event.faq")} ${t(
                          "event.shouldBe"
                        )} ${1300} ${t("event.characters")}.`,
                      },
                    })}
                  ></textarea>
                  <DisplayInputError message={errors.faq?.message} />
                </div>
                <div className="flex flex-col gap-5">
                  <div className="flex flex-col gap-2">
                    <div className="grid gap-5 grid-cols-2">
                      <CustomButton
                        register={register}
                        errors={errors}
                        index="0"
                      />
                      <CustomButton
                        register={register}
                        errors={errors}
                        index="1"
                      />
                    </div>
                    <label
                      htmlFor="media"
                      className="block text-lg font-medium"
                    >
                      {t("event.media")}
                    </label>
                    <Controller
                      name="media"
                      control={control}
                      rules={{
                        validate: {
                          checkSize: (files) => {
                            if (files) {
                              const totalSize = files.reduce((acc, file) => {
                                return acc + file.size;
                              }, 0);
                              if (totalSize > 10 * 1048576) {
                                return `${t("multipleDropzone.size")}: 10MB`;
                              }
                            }
                            return true;
                          },
                        },
                      }}
                      render={() => (
                        <MultipleImageDropzone
                          reactHookFormName="media"
                          aspect={0}
                        />
                      )}
                    />
                    <div className="flex flex-wrap gap-5">
                      {events.data.event?.files.map((file, i) => (
                        <div
                          key={i}
                          className="flex gap-0 items-center bg-red-500 rounded-sm"
                        >
                          <div className="w-16 h-9">
                            <img
                              src={file.image_url}
                              alt={`${t("event.event")} ${t("event.image")}.`}
                              className="w-16 h-9 object-cover"
                            />
                          </div>
                          {/* <div>{returnFileSize(file.size)}</div> */}
                          <button
                            type="button"
                            onClick={() => {
                              setFilesToDelete((state) => [...state, file]);
                              dispatch(
                                eventsActions.removeFileFromEvent({
                                  fileId: file.file_id,
                                })
                              );
                            }}
                            className="p-1"
                          >
                            <XIcon className="w-6 h-6 text-white" />
                          </button>
                        </div>
                      ))}
                    </div>
                    <DisplayFiles reactHookFormName="media" />
                    <DisplayInputError
                      message={(errors.media as any)?.message}
                    />
                    <label
                      htmlFor="sponsors"
                      className="block text-lg font-medium"
                    >
                      {t("event.sponsors")}
                    </label>
                    <Controller
                      name="sponsors"
                      control={control}
                      rules={{
                        validate: {
                          checkSize: (files) => {
                            if (files) {
                              const totalSize = files.reduce((acc, file) => {
                                return acc + file.size;
                              }, 0);
                              if (totalSize > 10 * 1048576) {
                                return `${t("multipleDropzone.size")}: 10MB`;
                              }
                            }
                            return true;
                          },
                        },
                      }}
                      render={() => (
                        <MultipleImageDropzone
                          reactHookFormName="sponsors"
                          aspect={16 / 9}
                        />
                      )}
                    />
                    <div className="flex flex-wrap gap-5">
                      {events.data.event?.sponsors_logo?.map((sponsor, i) => (
                        <div
                          key={i}
                          className="flex items-center bg-red-500 rounded-sm"
                        >
                          <div className="w-16 h-9">
                            <img
                              src={sponsor.image_url}
                              alt={`${t("event.sponsor")} ${t("event.image")}`}
                              className="w-16 h-9 object-cover"
                            />
                          </div>
                          {/* <div>{returnFileSize(sponsor.size)}</div> */}
                          <button
                            type="button"
                            onClick={() => {
                              setFilesToDelete((state) => [...state, sponsor]);
                              dispatch(
                                eventsActions.removeSponsorFromEvent({
                                  fileId: sponsor.file_id,
                                })
                              );
                            }}
                            className="p-1 text-white"
                          >
                            <XIcon className="w-6 h-6" />
                          </button>
                        </div>
                      ))}
                    </div>
                    <DisplayFiles reactHookFormName="sponsors" />
                    <DisplayInputError
                      message={(errors.sponsors as any)?.message}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
    </Overlay>
  );
}
