import { UserRank } from "shared/interfaces/Rank.interface";

export interface UserRanksState {
  data: {
    message: string;
    total?: number;
    pageIndex?: number;
    userRanks: UserRank[] | null;
  };
  loading: boolean;
}

export const userRanksInitialState: UserRanksState = {
  data: {
    message: "",
    total: 0,
    pageIndex: 0,
    userRanks: null,
  },
  loading: false,
};
