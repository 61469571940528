import { CMS_BE_URL } from "api/urls";

export const createCouponURL = `${CMS_BE_URL}/s/coupons`;

export type GetCouponsFilters = {
  pageSize: number;
  pageIndex?: number;
  sortCol?: string;
  sortOrder?: "ASC" | "DESC" | "";
  searchString?: string;
};

export const constructGetCouponsURL = (filters: GetCouponsFilters) => {
  return `${CMS_BE_URL}/s/coupons?${Object.entries(filters)
    .filter(([, value]) => !!value)
    .map(([key, value]) => {
      if (value) return `${key}=${value}`;
      return "";
    })
    .join("&")}`;
};

export const constructGetCouponURL = (couponId: string) =>
  `${CMS_BE_URL}/s/coupons/${couponId}`;

export const constructUpdateCouponURL = (couponId: string) =>
  `${CMS_BE_URL}/s/coupons/${couponId}`;

export const constructDeleteCouponURL = (couponId: string) =>
  `${CMS_BE_URL}/s/coupons/${couponId}`;
