import axios from "axios";
import { CreateCertificate } from "shared/interfaces/Certificate.interface";
import {
  getCertificatesURL,
  getCertificateURL,
  deleteCertificateURL,
  createCertificateURL,
  updateCertificateURL,
} from "./urls";

export const getCertificates = (filters: {
  badge: string;
  code: string;
  pageSize: number;
  pageIndex: number;
  searchString: string;
  sortCol: string;
  sortOrder: string;
}) => {
  return axios.get(getCertificatesURL(filters));
};

export const getCertificate = (certificateId: string) => {
  return axios.get(getCertificateURL(certificateId));
};

export const deleteCertificate = (certificateId: string) => {
  return axios.delete(deleteCertificateURL(certificateId));
};

export const createCertificate = (certificate: CreateCertificate) => {
  return axios.post(createCertificateURL(), certificate);
};

export const updateCertificate = (
  certificateId: string,
  certificate: CreateCertificate
) => {
  return axios.put(updateCertificateURL(certificateId), certificate);
};

export const getCertificationsColumns = (
  lang: string
): {
  Header: string;
  accessor: string;
  width: string;
  id?: string;
  disableSortBy?: boolean;
}[] => {
  return [
    {
      Header: lang === "en" ? "title" : "titolo",
      accessor: "col1",
      width: "200",
      id: "title",
    },
    {
      Header: lang === "en" ? "description" : "descrizione",
      accessor: "col2",
      width: "550",
      id: "description",
    },
    {
      Header: lang === "en" ? "actions" : "azioni",
      accessor: "col3",
      width: "100",
      id: "actions",
      disableSortBy: true,
    },
  ];
};
