import React from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
  CheckIcon,
  XIcon,
  KeyIcon,
  ArrowLeftIcon,
} from "@heroicons/react/outline";
import {
  changePasswordThunk,
  selectNewPassword,
} from "store/authStore/authReducer";
import { authActions } from "store/globalStore";
import { useAppDispatch } from "store/storeHooks";
import zxcvbn from "zxcvbn";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import Timer from "shared/components/UI/Timer";

function PasswordStrength({ password }: { password: string }) {
  const passwordStrength = zxcvbn(password);
  function getStrengthColor(i: number, score: number) {
    if (i <= score) {
      switch (score) {
        case 0: {
          return "bg-gray-300";
        }
        case 1: {
          return "bg-red-300";
        }
        case 2: {
          return "bg-yellow-300";
        }
        case 3: {
          return "bg-green-300";
        }
        case 4: {
          return "bg-green-600";
        }
        default: {
          return "bg-gray-300";
        }
      }
    }
    return "bg-gray-300";
  }
  return (
    <div className={`grid grid-cols-4 gap-2`}>
      {new Array(4).fill(1).map((_, i) => (
        <div
          key={i}
          className={`h-0.5 rounded-sm ${getStrengthColor(
            i + 1,
            passwordStrength.score
          )}`}
        ></div>
      ))}
    </div>
  );
}

function SetNewPassword() {
  const dispatch = useAppDispatch();
  const newPassword = selectNewPassword();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { t } = useTranslation("common");

  type Check = {
    check: (password: string, confirmPassword?: string) => boolean;
    msg: string;
  };

  type Checks = {
    [key: string]: Check;
  };

  const checks: Checks = {
    length: {
      check(password) {
        if (password.length < 12) return false;
        return true;
      },
      msg: "Be at least 12 characters long",
    },
    numbers: {
      check(password) {
        const res = password.match(/([0-9])/g);
        if (res && res.join("").length >= 2) {
          return true;
        }
        return false;
      },
      msg: "Contain at least two numbers",
    },
    uppercase: {
      check(password) {
        const res = password.match(/([A-Z])/g);
        if (res && res.join("").length >= 1) {
          return true;
        }
        return false;
      },
      msg: "Contain at least one uppercase letter",
    },
    lowercase: {
      check(password) {
        const res = password.match(/([a-z])/g);
        if (res && res.join("").length >= 1) {
          return true;
        }
        return false;
      },
      msg: "Contain at least one lowercase letter",
    },
    special: {
      check(password) {
        const res = password.match(/[!@#$%&*()\-_=+^]/g);
        if (res && res.join("").length >= 1) {
          return true;
        }
        return false;
      },
      msg: "Contain at least one special character",
    },
    match: {
      check(password, confirmPassword) {
        if (password == confirmPassword) return true;
        return false;
      },
      msg: "Type and confirm password match",
    },
  };

  function canResetPassword() {
    const temp = Object.values(checks).every((check: Check) =>
      check.check(newPassword.newPassword, newPassword.confirmNewPassword)
    );
    return !temp;
  }

  const displayPasswordUpdated = () => {
    toast.success(passwordUpdatedMsg);
  };

  // TODO: Use translation
  const passwordUpdatedMsg = () => (
    <div className="flex flex-col gap-1">
      <p className="text-xs font-semibold">{t("LoginPage.passwordHeader")}</p>
      <p className="text-xs">{t("LoginPage.passwordUpdated")}</p>
    </div>
  );

  const newPasswordHandler = () => {
    dispatch(
      changePasswordThunk({
        username: String(searchParams.get("userId")),
        password: newPassword.newPassword,
        token: String(searchParams.get("token")),
      })
    );
    displayPasswordUpdated();
    navigate("/");
  };

  return (
    <div className="flex flex-1 flex-col items-center justify-center px-4 py-12 h-screen sm:px-6 lg:flex-none lg:px-20 xl:px-24">
      <div className="mx-auto w-full max-w-3xl text-center space-y-6">
        <div className="flex items-center justify-center p-4">
          <div className="flex items-center justify-center w-16 h-16 bg-auth-icon-bg rounded-full ring-10 ring-auth-icon-ring ring-offset-10 ring-offset-auth-icon-ring-offset">
            <div className="text-auth-icon-color">
              <KeyIcon className="p-4 w-full h-full" />
            </div>
          </div>
        </div>
        <Timer date={Number(searchParams.get("exp")) * 1000} />
        <h2 className="mt-6 text-gray-900 text-3xl font-semibold">
          Set new password
          <br />
        </h2>
        <p>Type and confirm a secure new password</p>
        <form className="space-y-6" onSubmit={newPasswordHandler}>
          <div className="grid gap-20 grid-cols-2">
            <div className="space-y-2">
              <label
                htmlFor="password"
                className="block text-left text-gray-700 text-sm font-semibold"
              >
                New Password
              </label>
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="off"
                placeholder="Enter your new password"
                required
                value={newPassword.newPassword}
                onChange={(e) =>
                  dispatch(
                    authActions.changeNewPassword({
                      password: e.target.value,
                    })
                  )
                }
                className="placeholder-gray-400 block px-3 py-3 w-full border border-gray-300 focus:border-gray-300 rounded-sm focus:outline-none shadow-sm appearance-none focus:ring-0 sm:text-sm"
              />
              <PasswordStrength password={newPassword.newPassword} />
              <label
                htmlFor="confirmPassword"
                className="block text-left text-gray-700 text-sm font-semibold"
              >
                Confirm New Password
              </label>
              <div className="mt-1">
                <input
                  id="confirmPassword"
                  name="confirmPassword"
                  type="password"
                  autoComplete="off"
                  placeholder="Confirm your new password"
                  required
                  value={newPassword.confirmNewPassword}
                  onChange={(e) =>
                    dispatch(
                      authActions.changeConfirmNewPassword({
                        password: e.target.value,
                      })
                    )
                  }
                  className="placeholder-gray-400 block px-3 py-3 w-full border border-gray-300 focus:border-gray-300 rounded-sm focus:outline-none shadow-sm appearance-none focus:ring-0 sm:text-sm"
                />
              </div>
            </div>

            <div className="flex flex-col items-start justify-center text-left">
              {Object.values(checks).map((check: Check, i) => (
                <p key={i} className="flex items-center justify-center">
                  {check.check(
                    newPassword.newPassword,
                    newPassword.confirmNewPassword
                  ) ? (
                    <CheckIcon className="h-4 text-green-300" />
                  ) : (
                    <XIcon className="h-4 text-red-300" />
                  )}
                  <span className="pl-2">{check.msg}</span>
                </p>
              ))}
            </div>
          </div>

          <div className="mx-auto w-1/2">
            <button
              type="submit"
              disabled={canResetPassword()}
              className="flex justify-center px-4 py-3 w-full text-white text-sm font-medium bg-login-color border border-transparent rounded-md focus:outline-none shadow-sm focus:ring-0"
            >
              Reset password
            </button>
          </div>
        </form>
        <div>
          <Link to="/" className="flex items-center justify-center">
            <ArrowLeftIcon className="h-4" />
            <span className="pl-2">Back to sign in</span>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default SetNewPassword;
