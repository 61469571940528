import React, { useEffect, useState, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "store/storeHooks";
import {
  Column,
  useFlexLayout,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import axios from "axios";
import { SearchIcon, PlusIcon } from "@heroicons/react/solid";
import { ExclamationIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";
import { useDebounce } from "shared/hooks/useDebounce";
import Pagination from "shared/components/Table/Pagination";
import Table from "shared/components/Table/Table";
import { getSponsorsThunk } from "store/sponsorsStore/sponsorsReducer";
import Modal from "shared/components/UI/Modal";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { selectLoggedInUser } from "store/authStore/authReducer";
import { getSponsorsColumns } from "api/Sponsors/sponsors";
import Spinner from "shared/components/UI/Spinner";
import Overlay from "shared/components/UI/Overlay";
import { format } from "date-fns";
import TablePopup, { PortalType } from "shared/components/UI/TablePopup";
import {
  DotsVerticalIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/outline";
import { CMS_BE_URL } from "api/urls";
import { PermissionDomain } from "shared/interfaces/Permission.interface";
import { RolePermissionCrudAction } from "shared/interfaces/RolePermission.interface";
import Message from "shared/components/UI/Message";
import { selectTranslationLanguage } from "store/translationStore/translationReducer";

const Sponsors: () => JSX.Element = () => {
  const lang = selectTranslationLanguage();
  const { t } = useTranslation("common");
  const [searchText, setSearchText] = useState("");
  const debouncedSearchText = useDebounce(searchText, 300);
  const sponsors = useAppSelector((state) => state.sponsors);
  const loading = useAppSelector((state) => state.users.loading);
  const [currentPageSize, setCurrentPageSize] = useState(10);
  const dispatch = useAppDispatch();

  const loggedInUser = selectLoggedInUser();

  const [openModal, setOpenModal] = useState(false);
  const [userToDelete, setUserToDelete] = useState("");

  const [portal, setPortal] = useState<PortalType<any>>({
    open: false,
    top: 0,
    left: 0,
    entity: null,
  });

  const handleSearchText = (e: React.ChangeEvent<HTMLInputElement>) =>
    setSearchText(e.target.value);

  const userDeletedMsg = () => (
    <div className="flex flex-col gap-1">
      <p className="text-xs font-semibold">{t("UserCRUD.deleteMsgHeader")}</p>
      <p className="text-xs">{t("sponsors.ds")}</p>
    </div>
  );

  const displayUserDeletedMsg = () => {
    toast.success(userDeletedMsg);
  };

  const handleModal = (userToDelete: string) => {
    setUserToDelete(userToDelete);
    setOpenModal(true);
  };

  function canAccess(
    domain: PermissionDomain,
    action: RolePermissionCrudAction
  ): boolean {
    if (loggedInUser.permissions["*"]) {
      return true;
    } else {
      switch (loggedInUser.permissions[domain][action]) {
        case "NONE": {
          return false;
        }
        case "OWN": {
          return true;
        }
        case "ANY": {
          return true;
        }
        default: {
          return false;
        }
      }
    }
  }

  const columns: Array<Column<Record<string, any>>> = useMemo(
    () => getSponsorsColumns(lang),
    [lang]
  );

  const data: Array<any> = useMemo(() => {
    return sponsors.data?.data?.map((sponsor: any, i) => {
      return {
        col1: (
          <div className="flex items-center h-full">
            <span className="truncate">{sponsor.name}</span>
          </div>
        ),
        col2: (
          <div className="flex items-center h-full">
            <span className="truncate">{sponsor.url}</span>
          </div>
        ),
        col3: (
          <div className="flex items-center h-full">
            <span className="truncate">
              {format(new Date(sponsor.created_at), "MMMM dd, yyyy")}
            </span>
          </div>
        ),
        col4: (
          <div className="relative flex items-center h-full">
            <button
              id={`portal-button-${i}`}
              type="button"
              onClick={(e) => {
                return setPortal((state: any) => {
                  const thisButton = document.getElementById(
                    `portal-button-${i}`
                  );
                  const position = thisButton?.getBoundingClientRect();
                  return {
                    open: !state.open,
                    top: Number(position?.bottom),
                    left: Number(position?.left),
                    entity: sponsor,
                  };
                });
              }}
            >
              <DotsVerticalIcon className="w-5 h-5" />
            </button>
          </div>
        ),
      };
    });
  }, [sponsors.data.data, lang]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: currentPageSize },
      pageCount: Math.ceil(sponsors.data.total / currentPageSize),
      manualSortBy: true,
      manualPagination: true,
    },
    useSortBy,
    usePagination,
    useFlexLayout
  );

  const {
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, sortBy },
  } = tableInstance;

  useEffect(() => {
    dispatch(
      getSponsorsThunk({
        pageIndex: 0,
        pageSize: currentPageSize,
        searchString: debouncedSearchText,
        sortCol: sortBy[0] ? sortBy[0].id : "",
        sortOrder: sortBy[0] ? (sortBy[0].desc ? "DESC" : "ASC") : "",
      })
    );
  }, [debouncedSearchText, sortBy]);

  const handleDeleteSponsor = (userToDelete: string) => {
    axios
      .delete(`${CMS_BE_URL}/sponsors/${userToDelete}`)
      .then(() => {
        dispatch(
          getSponsorsThunk({
            pageIndex: 0,
            pageSize: currentPageSize,
            searchString: debouncedSearchText,
            sortCol: sortBy[0] ? sortBy[0].id : "",
            sortOrder: sortBy[0] ? (sortBy[0].desc ? "DESC" : "ASC") : "",
          })
        );
        displayUserDeletedMsg();
        setOpenModal(false);
      })
      .catch((err: any) => {
        if (err.response?.data?.errors?.[0]?.message) {
          toast.error(err.response?.data?.errors?.[0]?.message);
        } else {
          toast.error(err.response?.data?.message);
        }
      });
  };

  function createGetSponsorsThunkAction(pageIndex: number) {
    return {
      pageIndex,
      pageSize: currentPageSize,
      searchString: debouncedSearchText,
      sortCol: sortBy[0] ? sortBy[0].id : "",
      sortOrder: sortBy[0] ? (sortBy[0].desc ? "DESC" : "ASC") : "",
    };
  }

  const dispatchNextPage = () => {
    if (canNextPage) {
      dispatch(getSponsorsThunk(createGetSponsorsThunkAction(pageIndex + 1)));
      nextPage();
    }
  };

  const dispatchPreviousPage = () => {
    if (canPreviousPage) {
      dispatch(getSponsorsThunk(createGetSponsorsThunkAction(pageIndex - 1)));
      previousPage();
    }
  };

  const dispatchGotoPage = (pageIndex: number) => {
    dispatch(getSponsorsThunk(createGetSponsorsThunkAction(pageIndex)));
    gotoPage(pageIndex);
  };

  function portalContent() {
    if (portal.entity === null) return;
    return (
      <div
        className="fixed z-50 flex flex-col gap-2 p-2 bg-white rounded-sm shadow-md"
        style={{
          top: portal.top,
          left: portal.left,
        }}
      >
        {canAccess("sponsors", "UPDATE") ? (
          <Link
            to={`/sponsors/${portal.entity.sponsor_id}`}
            className="flex gap-2 items-center text-table-edit"
          >
            <PencilIcon className="w-5 h-5" />
            {lang === "en" ? "Edit" : "Modificare"}
          </Link>
        ) : null}
        {canAccess("sponsors", "DELETE") ? (
          <button
            onClick={() => handleModal(String(portal.entity?.sponsor_id))}
            className="flex gap-2 items-center text-users-delete"
          >
            <TrashIcon className="w-5 h-5" />
            {lang === "en" ? "Delete" : "Eliminare"}
          </button>
        ) : null}
      </div>
    );
  }

  return (
    <Overlay active={loading} spinner={<Spinner />}>
      <div className="flex flex-col gap-5 p-10 h-full">
        <h1 className="text-3xl font-bold">Sponsors</h1>
        <div className="flex gap-5 justify-end">
          <div className="flex items-center justify-start border focus-within:border-global-input-focus border-gray-300">
            <div className="flex items-center justify-center p-2 h-full bg-white">
              <SearchIcon className="w-5 h-5 text-gray-400" />
            </div>
            <input
              type="text"
              value={searchText}
              placeholder={`${t("UserCRUD.searchPlaceholder")}`}
              onChange={handleSearchText}
              className="placeholder-gray-400 p-2 w-full border-0 focus:ring-0"
            />
          </div>
          {(loggedInUser.permissions["*"] ||
            loggedInUser.permissions?.sponsors?.CREATE === "ANY") && (
            <Link to="/sponsors/create" className="h-full">
              <button className="flex gap-2 items-center justify-center px-4 py-2 text-white bg-users-create">
                <PlusIcon className="w-4 h-4" />
                <span className="whitespace-nowrap">{t("sponsors.add")}</span>
              </button>
            </Link>
          )}
        </div>
        <Table {...tableInstance} className="users__table" />
        <Pagination
          rowsCount={sponsors.data.total}
          pageIndex={pageIndex}
          pageSize={currentPageSize}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          pageOptions={pageOptions}
          pageCount={pageCount}
          gotoPage={dispatchGotoPage}
          nextPage={dispatchNextPage}
          previousPage={dispatchPreviousPage}
          setPageSize={setPageSize}
        />
        <TablePopup
          isOpen={portal.open}
          close={() => setPortal((state) => ({ ...state, open: false }))}
        >
          {portalContent()}
        </TablePopup>
        <Modal
          openStatus={openModal}
          setOpen={setOpenModal}
          className=""
          icon={
            <ExclamationIcon
              className="w-6 h-6 text-red-600 bg-transparent"
              aria-hidden="true"
            />
          }
          header={<p>{t("sponsors.d")}</p>}
          title={<p>{t("sponsors.dp")}</p>}
          footer={
            <div className="mt-5 sm:flex sm:flex-row-reverse sm:mt-4">
              <button
                type="button"
                className="inline-flex justify-center px-4 py-1 w-full text-white text-base font-medium bg-red-600 hover:bg-red-700 border border-transparent focus:outline-none shadow-sm focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                onClick={() => handleDeleteSponsor(userToDelete)}
              >
                {t("UserCRUD.deleteButton")}
              </button>
              <button
                type="button"
                className="inline-flex justify-center mt-3 px-4 py-1 w-full hover:text-gray-500 text-gray-700 text-base font-medium bg-white border border-gray-300 focus:outline-none shadow-sm focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                onClick={() => setOpenModal(false)}
              >
                {t("UserCRUD.cancelButton")}
              </button>
            </div>
          }
        />
      </div>
    </Overlay>
  );
};

export default Sponsors;
