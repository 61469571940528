import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createCouncilRank,
  deleteCouncilRank,
  getCouncilRank,
  getCouncilRanks,
  updateCouncilRank,
} from "api/CouncilRanks/councilRanks.api";
import axios, { AxiosError } from "axios";
import {
  CreateCouncilRank,
  UpdateCouncilRank,
} from "shared/interfaces/Rank.interface";
import { useAppSelector } from "store/storeHooks";
import { councilRanksInitialState } from "./councilRanksState";

export const createCouncilRankThunk = createAsyncThunk(
  "councilRanks/createCouncilRank",
  async ({ data }: { data: CreateCouncilRank }) => {
    await createCouncilRank({ data });
  }
);

export const getCouncilRanksThunk = createAsyncThunk(
  "councilRanks/getCouncilRanks",
  async (filters: {
    pageSize?: number;
    pageIndex?: number;
    sortCol?: string;
    sortOrder?: string;
    searchString?: string;
  }) => {
    const res = await getCouncilRanks(filters);
    return res.data;
  }
);

export const deleteCouncilRankThunk = createAsyncThunk(
  "councilRanks/deleteCouncilRank",
  async ({ rankId }: { rankId: string }, { rejectWithValue }) => {
    try {
      const res = await deleteCouncilRank({ rankId });
      return res.data;
    } catch (err: unknown | AxiosError) {
      if (axios.isAxiosError(err)) {
        return rejectWithValue(err.response?.data.message);
      } else rejectWithValue("Something went wrong!");
    }
  }
);

export const getCouncilRankThunk = createAsyncThunk(
  "councilRanks/getCouncilRank",
  async ({ rankId }: { rankId: string }) => {
    const res = await getCouncilRank({ rankId });
    return res.data;
  }
);

export const updateCouncilRankThunk = createAsyncThunk(
  "councilRanks/updateCouncilRank",
  async ({ rankId, data }: { rankId: string; data: UpdateCouncilRank }) => {
    await updateCouncilRank({
      rankId,
      data,
    });
  }
);

const councilRanksSlice = createSlice({
  name: "councilRanks",
  initialState: councilRanksInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createCouncilRankThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(createCouncilRankThunk.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(createCouncilRankThunk.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getCouncilRanksThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCouncilRanksThunk.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(getCouncilRanksThunk.rejected, (state) => {
        state.loading = false;
      })
      .addCase(deleteCouncilRankThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteCouncilRankThunk.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(deleteCouncilRankThunk.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getCouncilRankThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCouncilRankThunk.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(getCouncilRankThunk.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateCouncilRankThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateCouncilRankThunk.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updateCouncilRankThunk.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const selectCouncilRanks = () =>
  useAppSelector((state) => state.councilRanks);

export default councilRanksSlice;
