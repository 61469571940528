import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { lookupsInitialState } from "./lookupsState";
import { getLookups } from "api/Lookups/lookups.api";
import { useAppSelector } from "store/storeHooks";
import { Region } from "shared/interfaces/Region.interface";
import { Role } from "shared/interfaces/Role.interface";

export const getLookupsThunk = createAsyncThunk(
  "lookupsStore/getLookups",
  async () => {
    const response = await getLookups();
    return response.data;
  }
);

const lookupsSlice = createSlice({
  name: "lookupsStore",
  initialState: lookupsInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getLookupsThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getLookupsThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.lookupList = action.payload.lookupList;
    });
    builder.addCase(getLookupsThunk.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const selectLookups = () => useAppSelector((state) => state.lookups);
export const selectRegions: () => Region[] = () =>
  useAppSelector((state) => state.lookups.lookupList.regions);
export const selectRoles: () => Role[] = () =>
  useAppSelector((state) => state.lookups.lookupList.roles);
export const selectCategories: () => any[] = () =>
  useAppSelector((state) => state.lookups.lookupList.categories);

export default lookupsSlice;
