import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import Modal from "shared/components/UI/Modal";
import { toast } from "react-toastify";
import { CMS_BE_URL } from "api/urls";
import EditSub from "./EditSub";
import { selectLoggedInUser } from "store/authStore/authReducer";
import { format } from "date-fns";
import Message from "../UI/Message";
import { selectTranslationLanguage } from "store/translationStore/translationReducer";
import SubsHistory from "./SubsHistory";
import { capitalize } from "lodash";
import { getSubscriptionEndDate } from "api/Users/users.api";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "shared/styles/datePicker.css";
import { getInputStyles } from "utils/utils";
import CreateModal from "../UI/CreateModal";

const SubscriptionTab = ({
  user,
  subscription,
  setLoading,
  fetchUser,
  setSubscription,
}: {
  user: any;
  subscription: any;
  fetchUser: any;
  setLoading: (value: boolean) => void;
  setSubscription: (value: any) => void;
}) => {
  const { t } = useTranslation("common");
  const lang = selectTranslationLanguage();
  const [memberId, setMemberId] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [openModal2, setOpenModal2] = useState(false);
  const [openDeleteMembershipModal, setOpenDeleteMembershipModal] =
    useState(false);
  const [edit, setEdit] = useState(false);
  const loggedInUser = selectLoggedInUser();
  const [subsHistory, setSubsHistory] = useState<any>([]);
  const [subPaymentDate, setSubPaymentDate] = useState<any>();

  const [membership, setMembership] = useState<any>();
  const [shouldCreateOrder, setShouldCreateOrder] = useState(false);

  const [ranksOptions, setRankOptions] = useState([]);
  const getRanks = async () => {
    const { data } = await axios.get(`${CMS_BE_URL}/ranks/ur?pageSize=99`);
    setRankOptions(
      data.userRanks?.map((r: any) => ({
        value: r.id,
        label: lang === "en" ? r.rank_eng : r.rank_it,
      })) as any
    );
  };

  // STATES FOR MULTIPLE SUBSCRIPTION ACTION
  const [method, setMethod] = useState<string>("cash");
  const [type, setType] = useState<string>("teacher");
  const [planId, setPlanId] = useState<number>(0);
  const [price, setPrice] = useState<number>(0);
  const [patreons, setPatreons] = useState<any>([]);
  const [teachers, setTeachers] = useState<any>([]);

  // getting the plans
  const [plans, setPlans] = useState<any>([]);

  const getPlans = async () => {
    try {
      const res = await axios.get(`${CMS_BE_URL}/subscriptionPlans`);
      //sort plans by ID;
      const sortedPlans = res.data.data.sort((a: any, b: any) => a.id - b.id);
      const teachers = sortedPlans.slice(0, 3);
      const patreons = sortedPlans.slice(3, 7);
      if (sortedPlans?.length > 0) {
        setPlanId(sortedPlans[0].id);
        setPrice(sortedPlans[0].price);
      }
      setPlans(sortedPlans);
      setTeachers(teachers);
      setPatreons(patreons);
    } catch (err: any) {
      if (err.response?.data?.errors?.[0]?.message) {
        toast.error(err.response?.data?.errors?.[0]?.message);
      } else {
        toast.error(err.response?.data?.message);
      }
    }
  };

  const handleTypeSelection = (e: any) => {
    setType(e);
    if (e === "teacher" && teachers?.length > 0) {
      setPlanId(teachers[0].id);
      setPrice(teachers[0].price);
    }
    if (e === "patreon" && patreons?.length > 0) {
      setPlanId(patreons[0].id);
      setPrice(patreons[0].price);
    }
  };

  const handlePlanSelection = (e: any) => {
    const plan = plans.find((pl: any) => Number(pl.id) === Number(e));
    setPlanId(plan.id);
    setPrice(plan.price);
  };

  const handleOpenModal = (membershipType: string) => {
    setOpenModal(true);
    if (membershipType === "teacher") {
      if (
        subscription?.resubStatus === "renewal" &&
        subscription.subscription.length > 0
      ) {
        setOpenModal2(true);
        setPlanId(2);
        setPrice(teachers?.[1].price);
      }
      if (subscription?.resubStatus === "first_membership") {
        setPlanId(1);
        setPrice(teachers?.[0].price);
      }
      if (
        !(
          subscription?.resubStatus === "renewal" &&
          subscription.subscription.length > 0
        ) &&
        !(subscription?.resubStatus === "first_membership")
      ) {
        setPlanId(3);
        setPrice(teachers?.[2].price);
      }
    } else {
      if (subscription?.patreonId) {
        setPlanId(subscription?.patreonId);
        setType("patreon");
        setPrice(patreons?.[subscription.patreonId - 4]?.price);
      }
      if (subscription?.resubStatus === "first_membership") {
        setPlanId(1);
        setPrice(teachers?.[0]?.price);
      }
    }
  };

  const getSubs = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_CMS_BE_URL}/users/${user.PK_USER}/subscription`
    );
    const { data } = await axios.get(
      `${process.env.REACT_APP_CMS_BE_URL}/users/${user.PK_USER}/subscription?type=patreon`
    );
    let subsArr = [...data.subscription, ...res.data.subscription];
    subsArr = subsArr.sort(
      (a, b) =>
        new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
    );
    setSubscription(res.data);
    setSubsHistory(subsArr);
  };

  useEffect(() => {
    getPlans();
    getSubs();
    getRanks();
  }, []);

  //IF SUBSCRIPTION EXISTS, GET MEMBER ID
  const getMemberId = async () => {
    const { data } = await axios.get(`${CMS_BE_URL}/teachers/${user.PK_USER}`);
    setMemberId(data.member_id);
  };

  const getSub = async () => {
    const { data } = await axios.get(
      `${CMS_BE_URL}/memberships/${user.PK_USER}`
    );
    setMembership(data.membership);
  };

  useEffect(() => {
    if (user) {
      getMemberId();
      getSub();
    }
  }, [user]);

  //TOASTIFY
  const displaySubUpdated = () => {
    toast.success(
      Message({
        action: "update",
        entity: t("user.subscription"),
        lang,
      })
    );
  };

  const displaySubErrorMsg = () => {
    toast.error(
      Message({
        action: "update",
        entity: t("user.subscription"),
        error: t("user.subscriptionError"),
        lang,
      })
    );
  };

  const getSubId = () => {
    if (type === "patreon" && planId == 4) return 4;
    if (type === "patreon" && planId == 5) return 5;
    if (type === "patreon" && planId == 6) return 6;
    if (type === "patreon" && planId == 7) return 7;
    if (subscription.resubStatus === "first_membership") return 1;
    if (subscription.resubStatus === "renewal") return 2;
    if (subscription.resubStatus === "readmission") return 3;
  };

  const handleSubscribe = async () => {
    setLoading(true);
    const sub_data = {
      price: price,
      type: type,
      status: "active",
      planId: getSubId(),
      payment_type: method,
      start_date: Date.now(),
      end_date: getSubscriptionEndDate(),
      payment_date: subPaymentDate ?? Date.now(),
    };
    try {
      await axios.post(
        `${CMS_BE_URL}/users/${user.PK_USER}/subscription`,
        sub_data
      );
      getSubs();
      getMemberId();
    } catch (err: any) {
      if (err.response?.data?.errors?.[0]?.message) {
        toast.error(err.response?.data?.errors?.[0]?.message);
      } else {
        toast.error(err.response?.data?.message);
      }
    }
    await fetchUser();
    setLoading(false);
    handleCloseModal(false);

    //IF SUBSCRIPTION IS SUCCESSFUL, FETCH THE NEW DATA AND DISPLAY THEM
  };
  const getSubData = async () => {
    const { data } = await axios.get(
      `${CMS_BE_URL}/users/${user.PK_USER}/subscription?active=true`
    );
    if (data) {
      getMemberId();
      displaySubUpdated();
      setSubscription(data);
      user.IS_TEACHER = true;
      user.MEMBER_STATUS = "ACTIVE";

      setLoading(false);
      setMethod("cash");
    } else {
      displaySubErrorMsg();
      setLoading(false);
      setMethod("cash");
    }
    setOpenModal(false);
  };

  const handleCloseModal = (val: boolean) => {
    setOpenModal(val);
    setOpenModal2(val);
  };

  // SUBMITTING THE CONFIRM MEMBERSHIP DELETE MODAL
  const handleConfirmDeleteMembership = async () => {
    setLoading(true);
    try {
      await axios.delete(`${CMS_BE_URL}/memberships/${user.PK_USER}`);
      toast.success(t("users.toastDeleteMembership"));
      setMembership(null);
    } catch {
      displaySubErrorMsg();
    } finally {
      await fetchUser();
      setOpenDeleteMembershipModal(false);
      setLoading(false);
    }
  };

  const handleRenewal = async () => {
    const start_date = new Date(membership.end_date).getTime();
    const date = new Date(membership.end_date);
    const new_end_year = date.getFullYear() + 1;
    const new_end_date = new Date(new_end_year, 0, 1).getTime();
    setLoading(true);

    const sub_data = {
      price: price,
      type: type,
      status: "active",
      planId,
      payment_type: method,
      start_date: start_date ?? Date.now(),
      end_date: new_end_date ?? Date.now(),
      order: shouldCreateOrder,
      payment_date: subPaymentDate ?? Date.now(),
    };
    try {
      await axios.post(
        `${CMS_BE_URL}/users/${user.PK_USER}/subscription`,
        sub_data
      );

      displaySubUpdated();
      await fetchUser();
      setMethod("cash");
      getSubs();
      getMemberId();
    } catch (err) {
      displaySubErrorMsg();
    }
    setLoading(false);
    handleCloseModal(false);
  };
  useEffect(() => {
    if (!openModal2 && shouldCreateOrder) {
      setShouldCreateOrder(false);
    }
  }, [openModal2]);

  const handleAddSub = () => {
    openModal2 ? handleRenewal() : handleSubscribe();
  };

  return (
    <>
      <div className="flex gap-5 items-center mb-10">
        <div className="text-2xl font-bold">{t("users.membership")}</div>
        {user &&
          loggedInUser.permissions.subscriptions?.UPDATE !== "NONE" &&
          membership && (
            <>
              <button
                onClick={() => setEdit(!edit)}
                className="px-4 py-2 h-10 text-white bg-users-create"
                type="button"
              >
                {edit ? t("deleteModal.cancel") : t("user.editInfo")}
              </button>
              {!edit && (
                <button
                  onClick={() => setOpenDeleteMembershipModal(true)}
                  className="px-4 py-2 text-white font-bold bg-red-500 border border-red-500 rounded-sm"
                  type="button"
                >
                  {t("users.dlt")}
                </button>
              )}
            </>
          )}
        <>
          <button
            type="button"
            className="ml-auto mt-4 px-5 py-2 h-10 text-white bg-global-input-focus"
            onClick={() => handleOpenModal(membership?.type)}
          >
            {t("subs.handle")} {t("user.subscription")}
          </button>
        </>
      </div>
      {membership ? (
        <>
          {user &&
          subscription?.resubStatus === "renewal" &&
          subscription.subscription.length > 0 ? (
            <div className="">
              {edit ? (
                <>
                  <EditSub
                    ranksOptions={ranksOptions}
                    fetchUser={fetchUser}
                    getMemberId={getMemberId}
                    getSubs={getSubs}
                    user={user}
                    setEdit={setEdit}
                    getSubData={getSubData}
                    setLoading={setLoading}
                    cardNumber={memberId}
                    subscription={membership}
                    displaySubErrorMsg={displaySubErrorMsg}
                  />
                </>
              ) : (
                <>
                  <div className="flex flex-col gap-14 mb-10 max-w-4xl">
                    <div className="flex justify-between">
                      <div className="flex-1">
                        <div className="justify-start mb-2 text-lg font-bold">
                          {t("user.status")}
                        </div>
                        {capitalize(user.MEMBER_STATUS?.replace("_", " "))}
                      </div>
                      <div className="flex-1">
                        <div className="justify-start mb-2 text-lg font-bold">
                          {t("user.cardNumber")}
                        </div>
                        {memberId}
                      </div>
                      <div className="flex-1">
                        <div className="justify-start mb-2 text-lg font-bold">
                          {t("user.region")}
                        </div>
                        {user?.ADDRESS_DETAILS?.[0]?.FK_REGION}
                      </div>
                      <div className="flex-1">
                        <div className="justify-start mb-2 text-lg font-bold">
                          {t("subs.payType")}
                        </div>

                        {capitalize(membership?.payment_type.replace("_", " "))}
                      </div>
                    </div>
                    <div className="flex justify-between">
                      <div className="flex-1">
                        <div className="justify-start mb-2 text-lg font-bold">
                          {t("user.inscribed")}
                        </div>
                        {user?.FIRST_SUB_DATE ? (
                          <>
                            {" "}
                            {format(
                              new Date(user?.FIRST_SUB_DATE),
                              "dd/MM/yyyy"
                            )}
                          </>
                        ) : (
                          <>-</>
                        )}
                      </div>
                      <div className="flex-1">
                        <div className="justify-start mb-2 text-lg font-bold">
                          {t("user.startDate")}
                        </div>
                        {membership?.start_date ? (
                          <>
                            {format(
                              new Date(membership?.start_date),
                              "dd/MM/yyyy"
                            )}
                          </>
                        ) : (
                          <>-</>
                        )}
                      </div>
                      <div className="flex-1">
                        <div className="justify-start mb-2 text-lg font-bold">
                          {t("user.endDate")}
                        </div>
                        {membership?.end_date ? (
                          <>
                            {format(
                              new Date(membership?.end_date),
                              "dd/MM/yyyy"
                            )}
                          </>
                        ) : (
                          <>-</>
                        )}
                      </div>
                      <div className="flex-1 mx-4">
                        <div className="justify-start mb-2 text-lg font-bold">
                          {t("user.rank")}
                        </div>
                        {lang === "en" ? user.rank.RANK_ENG : user.rank.RANK_IT}
                      </div>
                      <div className="flex-1">
                        <div className="justify-start mb-2 text-lg font-bold">
                          Sub type
                        </div>
                        {membership && membership?.type ? (
                          <>{membership.type.replace("_", " ")}</>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          ) : (
            <div className="gap-5 my-2">
              {edit ? (
                <>
                  <EditSub
                    fetchUser={fetchUser}
                    ranksOptions={ranksOptions}
                    getMemberId={getMemberId}
                    user={user}
                    getSubs={getSubs}
                    setEdit={setEdit}
                    getSubData={getSubData}
                    setLoading={setLoading}
                    cardNumber={memberId}
                    subscription={membership}
                    displaySubErrorMsg={displaySubErrorMsg}
                  />
                </>
              ) : (
                <div className="flex flex-col flex-wrap gap-10 max-w-4xl">
                  <div className="flex gap-10">
                    {memberId && (
                      <div className="">
                        <div className="justify-start mb-2 text-lg font-bold">
                          {t("user.cardNumber")}
                        </div>
                        {memberId}
                      </div>
                    )}
                    <div className="">
                      <div className="justify-start mb-2 text-lg font-bold">
                        {t("user.status")}
                      </div>
                      {capitalize(user.MEMBER_STATUS?.replace("_", " "))}
                    </div>
                    <div className="">
                      <div className="justify-start mb-2 text-lg font-bold">
                        {t("subs.payType")}
                      </div>
                      {capitalize(membership?.payment_type)}
                    </div>
                    <div className="flex-1">
                      <div className="justify-start mb-2 text-lg font-bold">
                        {t("user.region")}
                      </div>
                      {user?.ADDRESS_DETAILS?.[0]?.FK_REGION}
                    </div>
                  </div>

                  <div className="flex gap-10">
                    <div className="">
                      <div className="justify-start mb-2 text-lg font-bold">
                        {t("user.inscribed")}
                      </div>

                      <>
                        {format(new Date(user?.FIRST_SUB_DATE), "dd/MM/yyyy")}
                      </>
                    </div>
                    <div className="">
                      <div className="justify-start mb-2 text-lg font-bold">
                        {t("user.startDate")}
                      </div>
                      {membership && membership.start_date ? (
                        <>
                          {" "}
                          {format(
                            new Date(membership?.start_date),
                            "dd/MM/yyyy"
                          )}
                        </>
                      ) : (
                        <>-</>
                      )}
                    </div>

                    <div className="">
                      <div className="justify-start mb-2 text-lg font-bold">
                        {t("user.endDate")}
                      </div>
                      {membership && membership.end_date ? (
                        <>
                          {" "}
                          {format(new Date(membership?.end_date), "dd/MM/yyyy")}
                        </>
                      ) : (
                        <>-</>
                      )}
                    </div>
                    <div className="">
                      <div className="justify-start mb-2 text-lg font-bold">
                        Sub type
                      </div>

                      {membership && membership?.type ? (
                        <>{membership.type.replace("_", " ")}</>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="flex-1 mx-4">
                      <div className="justify-start mb-2 text-lg font-bold">
                        {t("user.rank")}
                      </div>
                      {lang === "en" ? user.rank.RANK_ENG : user.rank.RANK_IT}
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </>
      ) : (
        <div>{t("subs.noSub")}</div>
      )}
      <CreateModal
        openStatus={openDeleteMembershipModal}
        setOpen={setOpenDeleteMembershipModal}
        header={<p className="mt-4">{t("users.dltUserModalHeader")}</p>}
        title={
          <div className="flex flex-col gap-4">
            {t("users.dltUserModalTitle")}
          </div>
        }
        footer={
          <div className="mt-5 sm:flex sm:flex-row-reverse sm:mt-4">
            <button
              onClick={() => handleConfirmDeleteMembership()}
              className="sm: inline-flex justify-center px-4 py-1 w-full text-white text-base font-medium bg-red-600 focus:bg-red-600 border border-transparent focus:outline-none shadow-sm focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto"
              type="button"
            >
              {t("users.confirm")}
            </button>
            <button
              onClick={() => setOpenDeleteMembershipModal(false)}
              className="sm: inline-flex justify-center mt-3 px-4 py-1 w-full hover:text-gray-500 text-gray-700 text-base font-medium bg-white border border-gray-300 focus:outline-none shadow-sm focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto"
              type="button"
            >
              {t("users.cancel")}
            </button>
          </div>
        }
      />
      <Modal
        openStatus={openModal}
        setOpen={handleCloseModal}
        className=""
        header={
          openModal2 ? (
            <p className="w-full text-xl">
              {" "}
              {t("user.renew")} {t("user.subscription")}
            </p>
          ) : (
            <p className="text-xl">
              {subscription?.resubStatus === "first_membership" ? (
                <span>{t("subs.sUser")}</span>
              ) : (
                <span>{t("user.readmission")}</span>
              )}
            </p>
          )
        }
        title={
          <div>
            {t("user.subscribeDescription")}
            <div className="flex flex-col gap-3 mt-3">
              <label className="w-full">
                <span className="text-gray-900 text-base font-medium">
                  {" "}
                  {t("user.paymentMethod")}
                </span>{" "}
                <br />
                <select
                  className="mt-1 py-2 w-full text-gray-500 border focus:border-global-input-focus border-gray-300 focus:ring-0"
                  onChange={(e) => setMethod(e.target.value)}
                  defaultValue={method}
                >
                  <option value="cash">{t("subs.cash")}</option>
                  <option value="bank_transfer">
                    {t("user.bankTransfer")}
                  </option>
                  <option value="postal">{t("user.postal")}</option>
                  <option value="gift">{t("user.gift")}</option>
                </select>
              </label>
              <label>
                <span className="text-gray-900 text-base font-medium">
                  {" "}
                  {t("subs.type")}
                </span>{" "}
                <br />
                <select
                  defaultValue={type}
                  className="mt-1 py-2 w-full text-gray-500 border focus:border-global-input-focus border-gray-300 focus:ring-0"
                  onChange={(e) => handleTypeSelection(e.target.value)}
                >
                  <option value="teacher">Teacher</option>

                  <option value="patreon">Patreon</option>
                </select>
              </label>
              {type == "patreon" && (
                <label>
                  <span className="text-gray-900 text-base font-medium">
                    {" "}
                    Plans
                  </span>{" "}
                  <br />
                  <select
                    className="mt-1 py-2 w-full text-gray-500 border focus:border-global-input-focus border-gray-300 focus:ring-0"
                    onChange={(e) => handlePlanSelection(e.target.value)}
                  >
                    {patreons?.map((p: any) => (
                      <option key={p.id} value={p.id}>
                        {p.title}
                      </option>
                    ))}
                  </select>
                </label>
              )}
              {type == "teacher" && (
                <div className="w-full">
                  <label>
                    <span className="text-gray-900 text-base font-medium">
                      Plans
                    </span>{" "}
                    <br />
                    <select
                      className="mt-1 py-2 w-full text-gray-500 border focus:border-global-input-focus border-gray-300 focus:ring-0"
                      onChange={(e) => handlePlanSelection(e.target.value)}
                    >
                      {teachers?.map((p: any) => (
                        <option className="w-full" key={p.id} value={p.id}>
                          {p.title}
                        </option>
                      ))}
                    </select>
                  </label>
                </div>
              )}
              <div className="flex flex-col gap-4">
                <label>
                  <span className="text-gray-900 text-base font-medium">
                    {t("products.price")}
                  </span>{" "}
                  <br />
                  <input
                    className="mt-1 py-2 w-full text-gray-500 border focus:border-global-input-focus border-gray-300 focus:ring-0"
                    value={price}
                    defaultValue={0}
                    min={0}
                    onChange={(e) => setPrice(+e.target.value)}
                    type="number"
                  />
                </label>
              </div>
              <div className="flex flex-col gap-4">
                <label>
                  <span className="text-gray-900 text-base font-medium">
                    {t("UserCRUD.subPaymentDate")}
                  </span>{" "}
                  <br />
                  <DatePicker
                    id="user_sub_payment_date"
                    selected={subPaymentDate}
                    onChange={(e) => setSubPaymentDate(e)}
                    dateFormat="dd/MM/yyyy"
                    className={getInputStyles(false)}
                    placeholderText={t("UserCRUD.subPaymentDate")}
                    showPopperArrow={false}
                    autoComplete="off"
                  />
                </label>
              </div>
              {openModal2 && (
                <div>
                  <label className="flex gap-2 items-center">
                    <input
                      type="checkbox"
                      checked={shouldCreateOrder}
                      onChange={(e) => setShouldCreateOrder(e.target.checked)}
                      className="text-primary"
                    />
                    <span className="text-gray-900 text-base font-medium">
                      {t("user.subscriptionOrder")}
                    </span>{" "}
                  </label>
                </div>
              )}
            </div>
          </div>
        }
        footer={
          <div className="mt-5 sm:flex sm:flex-row-reverse sm:mt-4">
            <button
              type="button"
              className="inline-flex justify-center px-4 py-1 w-full text-white text-base font-medium bg-users-create hover:bg-users-create border border-transparent focus:outline-none shadow-sm focus:ring-2 focus:ring-users-create focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={() => handleAddSub()}
            >
              {t("subs.proceed")}
            </button>
            <button
              type="button"
              className="inline-flex justify-center mt-3 px-4 py-1 w-full hover:text-gray-500 text-gray-700 text-base font-medium bg-white border border-gray-300 focus:outline-none shadow-sm focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
              onClick={() => handleCloseModal(false)}
            >
              {t("user.cancel")}
            </button>
          </div>
        }
      />
      {subsHistory?.length > 0 && (
        <SubsHistory
          plans={plans}
          fetchUser={fetchUser}
          getSubs={getSubs}
          subsHistory={subsHistory}
        />
      )}
    </>
  );
};

export default SubscriptionTab;
