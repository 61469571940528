import React from "react";
import { Link } from "react-router-dom";
import { MailIcon, ArrowLeftIcon } from "@heroicons/react/outline";
import {
  forgotPasswordThunk,
  selectForgotPassword,
} from "store/authStore/authReducer";
import { useDispatch } from "react-redux";

function CheckEmail() {
  const dispatch = useDispatch();
  const forgotPassword = selectForgotPassword();

  function resendHandler() {
    dispatch(
      forgotPasswordThunk({
        email: forgotPassword.email,
      })
    );
  }
  return (
    <div className="flex flex-1 flex-col items-center justify-center px-4 py-12 h-screen sm:px-6 lg:flex-none lg:px-20 xl:px-24">
      <div className="mx-auto w-full max-w-xl text-center space-y-6">
        <div className="flex items-center justify-center p-4">
          <div className="flex items-center justify-center w-16 h-16 bg-auth-icon-bg rounded-full ring-10 ring-auth-icon-ring ring-offset-10 ring-offset-auth-icon-ring-offset">
            <div className="text-auth-icon-color">
              <MailIcon className="p-4 w-full h-full" />
            </div>
          </div>
        </div>
        <h2 className="mt-6 text-gray-900 text-3xl font-semibold">
          Check your e-mail
        </h2>
        <p>We sent you a temporary password to</p>
        <p className="font-bold">{forgotPassword.email}</p>
        <p>
          <span className="opacity-50">Didn&apos;t receive the e-mail? </span>
          <button className="text-black font-medium" onClick={resendHandler}>
            Click to resend
          </button>
        </p>
        <p className="text-auth-checkEmail-details">
          Check your e-mail inbox. We send you a temporary password. Use it to
          set up your new password and continue with your confirmation.
        </p>
        <div>
          <Link to="/" className="flex items-center justify-center">
            <ArrowLeftIcon className="h-4" />
            <span className="pl-2">Back to sign in</span>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default CheckEmail;
